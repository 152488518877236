import { gql } from '@apollo/client';

/**
 * Description - graphql query to create a sub theme for a theme
 */
export const INSERT_SUB_THEME_TEMPLATE_CHECKLIST = gql`
  mutation insert_qat_sub_theme_template_checklist_one(
    $object: qat_sub_theme_template_checklist_insert_input!
  ) {
    insert_qat_sub_theme_template_checklist_one(
      object: $object
    ) {
      id
      name_fr
      name_nl
      theme_template_checklist_id
      not_applicable_option
      index
    }
  }
`;



/**
 * Description - graphql query to update a sub theme
 */
export const UPDATE_SUB_THEME_TEMPLATE_CHECKLIST = gql`
  mutation update_qat_sub_theme_template_checklist_by_pk(
    $qat_sub_theme_template_checklist_pk_columns_input: qat_sub_theme_template_checklist_pk_columns_input!
    $qat_sub_theme_template_checklist_set_input: qat_sub_theme_template_checklist_set_input!
  ) {
    update_qat_sub_theme_template_checklist_by_pk(
      pk_columns: $qat_sub_theme_template_checklist_pk_columns_input
      _set: $qat_sub_theme_template_checklist_set_input
    ) {
      id
    }
  }
`;

export const UPDATE_INDEX_SUB_THEME_TEMPLATE_CHECKLIST = gql`
  mutation update_index_sub_theme_template_checklist(
    $objects: [qat_sub_theme_template_checklist_insert_input!]!
  ) {
    insert_qat_sub_theme_template_checklist(
      objects: $objects
      on_conflict: {
        constraint: sub_theme_template_checklist_pkey
        update_columns: [index]
      }
    ) {
      returning {
        id
      }
    }
  }
`;
