import EntityReadComponent, {
  EntityReadComponentProps,
  testIds,
} from './EntityReadComponent';

export default EntityReadComponent;

export { testIds };

export type { EntityReadComponentProps };
