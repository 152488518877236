import { useCallback, useEffect, useState } from 'react';
import { useGetEntityById } from '../../../../services/Entity/ReadService/EntityReadService';
import { useUpdateEntity } from '../../../../services/Entity/UpdateService/EntityUpdateService';
import { EntityFormData } from '../../EntityForm/EntityFormPresenter';

export interface EntityUpdateModelResult {
  data: EntityFormData | undefined;
  loading: boolean;
  onUpdate: (entityData: EntityFormData) => void;
}

export interface EntityUpdateModelProps {
  idToUpdate: number;
}

/**
 * Description - model service for Entity Update Component
 * - get entity by id to update and return form data,
 * - mutation to update entity
 * - manage loading state
 */
const useEntityUpdateModel = ({
  idToUpdate,
}: EntityUpdateModelProps): EntityUpdateModelResult => {
  const { getEntityById } = useGetEntityById();
  const [entityToUpdate, setEntityToUpdate] = useState<EntityFormData>();
  const [loading, setLoading] = useState<boolean>(true);
  const { onUpdate } = useUpdateEntity();

  useEffect(() => {
    if (idToUpdate !== 0) {
      setLoading(true);
      getEntityById(idToUpdate).then(data => {
        if (data) {
          setEntityToUpdate(data);
          setLoading(false);
        }
      });
    }
  }, [idToUpdate, getEntityById]);

  const update = useCallback(
    (entityData: EntityFormData) => {
      onUpdate(idToUpdate, entityData);
    },
    [onUpdate, idToUpdate],
  );

  return { data: entityToUpdate, loading, onUpdate: update };
};

export default useEntityUpdateModel;
