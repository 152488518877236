import { gql } from '@apollo/client';
import { theme_template_checklist_data_from_list } from '../ThemeReadService';
/**
 * Description - graphql query to create a theme for a template checklist
 */
export const INSERT_THEME_TEMPLATE_CHECKLIST = gql`
  mutation insert_qat_theme_template_checklist_one(
    $object: qat_theme_template_checklist_insert_input!
  ) {
    insert_qat_theme_template_checklist_one(
      object: $object
    ) {
      id
      long_name_fr
      long_name_nl
      short_name_fr
      short_name_nl
      duplicable
      not_applicable_option
      template_checklist_id
      index
    }
  }
`;

/**
 * Description - graphql query to update a theme
 */
export const UPDATE_THEME_TEMPLATE_CHECKLIST = gql`
  mutation update_qat_theme_template_checklist_by_pk(
    $qat_theme_template_checklist_pk_columns_input: qat_theme_template_checklist_pk_columns_input!
    $qat_theme_template_checklist_set_input: qat_theme_template_checklist_set_input!
  ) {
    update_qat_theme_template_checklist_by_pk(
      pk_columns: $qat_theme_template_checklist_pk_columns_input
      _set: $qat_theme_template_checklist_set_input
    ) {
      id
    }
  }
`;

export const ADD_THEME_FROM_ANOTHER_TEMPLATE = gql`
  mutation add_theme_theme_template_checklist_from_another_template_checklist(
    $themes: [qat_theme_template_checklist_insert_input!]!
    $sub_themes: [qat_theme_template_checklist_insert_input]!
  ) {
    insert_qat_theme_template_checklist(
      object: $themes
      on_conflict: {
        constraint: theme_template_checklist_short_name_fr_long_name_fr_short_name_
        update_columns: [deleted_date]
      }
    ) {
      affected_rows
    }
    insert_qat_sub_theme_template_checklist(
      object: $sub_themes
      on_conflict: {
        constraint: sub_theme_template_checklist_name_fr_name_nl_theme_template_key
        update_columns: [deleted_date]
      }
    ) {
      affected_rows
    }
  }
`;

export const UPDATE_THEMES_INDEX = gql`
  mutation update_themes_index(
    $objects: [qat_theme_template_checklist_insert_input!]!
  ) {
    insert_qat_theme_template_checklist(
      objects: $objects
      on_conflict: {
        constraint: theme_template_checklist_pkey
        update_columns: [index]
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export interface ADD_THEME_FROM_ANOTHER_TEMPLATE_ARGUMENT {
  themes: theme_template_checklist_data_from_list[];
}

export const ADD_THEME_FROM_ANOTHER_TEMPLATE_FUNCTION = ({
  themes,
}: ADD_THEME_FROM_ANOTHER_TEMPLATE_ARGUMENT) => {
  let query = `mutation add_theme_theme_template_checklist_from_another_template_checklist($templateChecklistId: Int!) {
    insert_qat_theme_template_checklist(objects:[`;

  themes.forEach((theme, i) => {
    query += `{
      long_name_fr: "${theme.long_name_fr}",
      long_name_nl: "${theme.long_name_nl}",
      short_name_fr: "${theme.short_name_fr}",
      short_name_nl: "${theme.short_name_nl}",
      duplicable: ${theme.duplicable},
      not_applicable_option: ${theme.not_applicable_option}
      index: ${theme.index}
      template_checklist_id: $templateChecklistId
    `;
    if (
      theme.sub_theme_template_checklists &&
      theme.sub_theme_template_checklists.length
    ) {
      query += `sub_theme_template_checklists: {data :[`;
      theme.sub_theme_template_checklists.forEach(subTheme => {
        query += `{
            name_fr: "${subTheme.name_fr}",
            name_nl: "${subTheme.name_nl}",
            index: ${subTheme.index}
          }`;
      });
      query +=
        '], on_conflict: { constraint:  sub_theme_template_checklist_name_fr_name_nl_theme_template_key, update_columns: [deleted_date]}}';
    }

    query += `}`;
    if (i !== themes.length - 1) {
      query += `,`;
    }
  });
  query += `], on_conflict: { constraint: theme_template_checklist_short_name_fr_long_name_fr_short_name_, update_columns: [deleted_date, index, not_applicable_option, duplicable, updated_at ]}  ) {
    affected_rows
  }}
  `;
  return gql`
    ${query}
  `;
};
