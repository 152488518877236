import gql from "graphql-tag";

export interface add_admin_document_variables {
    id: number;
    url: string;
    last_updated_date: Date;
}

export interface add_admin_document_response {
    insert_qat_documentmix_one: {
        id: string;
    }
}

export const ADD_ADMIN_DOCUMENT = gql`
    mutation add_admin_document(
        $id: Int!
        $last_updated_date: timestamptz!
        $url: String!
    ){
        update_qat_admin_document_by_pk(pk_columns:{id: $id}, _set:{url: $url, last_updated_date: $last_updated_date}){
            id
            last_updated_date
        }

    }
`