import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import { ApolloError } from '@apollo/client';
import { audit_data } from '../../../../../services/Audit/AuditDataModel';
import {
  OrderBy,
  OrderDirection,
} from '../../../../Entity/EntityRead/EntityReadComponent/EntityReadComponent';
import { useGetAllSortedDoneAudits } from '../../../../../services/Audit/ReadService/AuditReadService';
import AppContext from '../../../../../AppContext';
import moment from 'moment';
import { translate } from '../../../../../services/Translation/TranslationService';

export interface AuditReadAllPresenterProps {
  allAudits: Array<audit_data>;
  loading: boolean;
  error: ApolloError | undefined;
  onSort: (sortItem: OrderBy) => void;
  sortByItems: OrderBy[];
  filterPredicate: (item, search) => boolean;
  entityfilterPredicate: (item, search) => boolean;
  locationfilterPredicate: (item, search) => boolean;
  setFilteredAudits: Dispatch<SetStateAction<audit_data[]>>;
  filteredAudits: audit_data[];
}

/**
 * Description - Presenter of AuditReadComponent
 */
export const useAuditReadAllPresenter = (): AuditReadAllPresenterProps => {
  const { language } = useContext(AppContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<ApolloError | undefined>();
  const { orderAuditsBy } = useGetAllSortedDoneAudits();
  const [filteredAudits, setFilteredAudits] = useState<Array<audit_data>>([]);

  useEffect(() => {
    setLoading(true);
    orderAuditsBy({ last_updated_date: OrderDirection.DESC })
      .then(audits => {
        setFilteredAudits(audits);
        setLoading(false);
      })
      .catch(errorVar => {
        setLoading(false);
        setError(errorVar);
      });
  }, [orderAuditsBy]);

  const [sortByItems, setSortByItems] = useState<OrderBy[]>([
    { column: 'entity' },
    { column: 'category' },
    { column: 'location' },
    { column: 'auditDate' },
    { column: 'auditor' },
    { column: 'specification' },
  ]);

  const handleSort = sortItem => {
    const updatedSortingItems = sortByItems?.map(item => {
      if (item?.column === sortItem?.column) {
        item.direction =
          item?.direction === OrderDirection.ASC
            ? OrderDirection.DESC
            : OrderDirection.ASC;
        let orderBy;
        switch (item?.column) {
          case 'entity':
            orderBy = { entity: { label: item.direction } };
            break;

          case 'category':
            if (language === 'nl')
              orderBy = {
                location_entity: {
                  category_location: { name_nl: item.direction },
                },
              };
            else if (language === 'fr')
              orderBy = {
                location_entity: {
                  category_location: { name_fr: item.direction },
                },
              };
            break;

          case 'location':
            orderBy = { location_entity: { name: item.direction } };
            break;
          case 'specification':
            orderBy = { location_entity: { specification: item.direction } };
            break;

          case 'auditDate':
            orderBy = { audit_date: item.direction };
            break;

          case 'auditor':
            orderBy = { auditor_email: item.direction };
            break;

          default:
            break;
        }

        sortByColumn(orderBy);
      } else delete item.direction;
      return item;
    });

    setSortByItems(updatedSortingItems);
  };

  const sortByColumn = item => {
    setLoading(true);
    orderAuditsBy(item)
      .then(audits => {
        setFilteredAudits(audits);
        setLoading(false);
      })
      .catch(errorVar => {
        setLoading(false);
        setError(errorVar);
      });
  };

  const entityfilterPredicate = (item, search) => {
    return item?.entity?.label?.toLowerCase()?.includes(search);
  };

  const locationfilterPredicate = (item, search) => {
    return item?.location_entity?.name?.toLowerCase()?.includes(search)
  };

  const filterPredicate = (item, search) => {
    return (
      translate(item?.location_entity?.category_location, language)
        .toLowerCase()
        ?.includes(search) ||
      item?.location_entity?.name?.toLowerCase()?.includes(search) ||
      item?.entity?.label?.toLowerCase()?.includes(search) ||
      moment(item?.audit_date)?.format('DD/MM/YYYY').includes(search) ||
      item?.auditor_email?.toLowerCase()?.includes(search)   ||
      item?.location_entity?.specification?.toLowerCase()?.includes(search)
    );
  };

  return {
    allAudits: filteredAudits,
    filteredAudits,
    setFilteredAudits,
    loading,
    error,
    onSort: handleSort,
    sortByItems,
    filterPredicate,
    entityfilterPredicate,
    locationfilterPredicate,
  };
};
