import { CircularProgress } from '@material-ui/core';
import React, { Fragment } from 'react';
import EntityFormComponent from '../../EntityForm/EntityFormComponent';
import { EntityFormData } from '../../EntityForm/EntityFormPresenter';

export interface EntityUpdateViewProps {
  loading: boolean;
  onUpdate: (dataForm: EntityFormData) => void;
  data: EntityFormData | undefined;
}

const EntityUpdateView: React.FC<EntityUpdateViewProps> = ({
  loading,
  onUpdate,
  data,
}) =>
  loading ? (
    <CircularProgress />
  ) : (
    <Fragment>
      <EntityFormComponent
        onSubmit={onUpdate}
        entity={data}
        title="entity:updateEntity"
        submitButtonLabel="common:update"
      />
    </Fragment>
  );

export default EntityUpdateView;
