import React from 'react';
import { useUserMenuPresenter } from '../UserMenuPresenter/UserMenuPresenter';
import UserMenuView from '../UserMenuView/UserMenuView';

export interface UserMenuProps {
  classes: any;
  service: any;
  context: any;
}

/**
 * This component is responsible to display the username and role of the current logged in user
 * This component also contains a logout button to exit your current authentication session
 * The username and role are taken from the MainAppContext
 */
const UserMenuComponent = () => {
  const props = useUserMenuPresenter();
  return <UserMenuView {...props} />;
};

export default UserMenuComponent;
