import { useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { BuildingFormData } from '../../../components/Building/BuildingForm/BuildingFormPresenter';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { isContraintViolationError } from '../../../graphql/utils';
import { GET_BUILDING_BY_AUDIT } from '../ReadService/BuildingReadQuery';
import { useIsUniqueBuildingAuditIdAddressId } from '../ReadService/BuildingReadService';
import {
  UPDATE_BUILDING,
  UPDATE_BUILDING_FIELD_VALUE_BY_BUILDING,
} from './BuildingUpdateMutation';

interface BuildingUpdateFormData {
  address_id: number;
  sub_category_location_id: number;
}

/**
 * Description - service to update building
 */
export const useUpdateBuilding = ({ audit_id }) => {
  const { setNotification } = useContext(MainAppContext);
  const {
    isUniqueBuildingAuditIdAddressId,
  } = useIsUniqueBuildingAuditIdAddressId();

  const [updateBuilding] = useMutation(UPDATE_BUILDING, {
    refetchQueries: [{ query: GET_BUILDING_BY_AUDIT, variables: { audit_id } }],
    awaitRefetchQueries: true,
    onCompleted({ createdBuilding }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      if (isContraintViolationError(error)) {
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      } else {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      }
    },
  });

  const [updateBuildingFieldByPk] = useMutation(
    UPDATE_BUILDING_FIELD_VALUE_BY_BUILDING,
    {
      onError(error) {
        if (isContraintViolationError(error)) {
          setNotification({
            key: 'item:itemValidationError',
            type: NotificationType.WARNING,
          });
        } else {
          setNotification({
            key: 'common:generalError',
            type: NotificationType.ERROR,
          });
        }
      },
    },
  );

  const onUpdate = useCallback(
    (building: BuildingFormData) => {
      return new Promise(resolve => {
        if (building.id) {
          const address_id: number =
            typeof building.address_id === 'string'
              ? parseInt(building.address_id)
              : building.address_id;
          isUniqueBuildingAuditIdAddressId({
            audit_id,
            address_id,
            building_id: building.id,
          }).then(isUnique => {
            if (isUnique) {
              if (building.building_field_values) {
                building.building_field_values.forEach(building_field_value => {
                  const {
                    category_location_form_field_config_id,
                    value,
                  } = building_field_value;
                  updateBuildingFieldByPk({
                    variables: {
                      building_id: building.id,
                      category_location_form_field_config_id: category_location_form_field_config_id,
                      qat_building_field_values_set_input: {
                        category_location_form_field_config_id: category_location_form_field_config_id,
                        value: value,
                      },
                    },
                  });
                });
              }

              const qat_building_set_input = {
                last_updated_date: new Date(),
                address_id: building?.address_id,
                sub_category_location_id: building?.sub_category_location_id,
              };

              updateBuilding({
                variables: { id: building.id, qat_building_set_input },
              }).then(result => {
                resolve(result);
              });
            } else {
              setNotification({
                key: 'building:uniqueBuildingErrorMessage',
                type: NotificationType.WARNING,
              });
            }
          });
        } else {
          setNotification({
            key: 'common:generalError',
            type: NotificationType.ERROR,
          });
        }
      });
    },
    [
      audit_id,
      updateBuilding,
      updateBuildingFieldByPk,
      setNotification,
      isUniqueBuildingAuditIdAddressId,
    ],
  );

  return { onUpdate };
};
