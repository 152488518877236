import { useState } from 'react';
import { useCloseAudit } from '../../../services/Audit/CloseService/AuditCloseService';
import { useDeleteAudit } from '../../../services/Audit/DeleteService/AuditDeleteService';
import { AuditViewProps } from '../AuditView/AuditView';

/**
 * Description : service to manage the audits
 */
export const useAuditComponentService = (): AuditViewProps => {
  const [idToUpdate, setIdToUpdate] = useState<number>(0);
  const { myDelete } = useDeleteAudit();
  const { onCloseAudit } = useCloseAudit();

  return {
    onDelete: id => myDelete(id),
    onUpdate: id => setIdToUpdate(id),
    onClose: onCloseAudit,
    audit_id: idToUpdate,
  };
};
