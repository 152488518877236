import {
  Box,
  Button,
  Grid,
  Paper,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';
import React, { useState, useContext, useCallback } from 'react';
import { checklist_theme } from '../../../services/Checklist/ChecklistReadService';
import { translateShortName } from '../../../services/Translation/TranslationService';
import AppContext from '../../../AppContext';
import { useStyles } from '../ChecklistRemarkStyle';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '@material-ui/icons/Delete';
import { useUpdateChecklistTheme } from '../../../services/ChecklistTheme/ChecklistThemeWriteService';
import { debounce } from 'lodash';
import { NotificationType } from '../../Notification/NotificationComponent';
import { useBeforeunload } from 'react-beforeunload';
import AuthorizationComponent from '../../Authorization/AuthorizationComponent/AuthorizationComponent';
import { Rule } from '../../Authorization/AuthorizationComponent/Permissions';
export interface ChecklistThemeRemarkProps {
  theme: checklist_theme;
  checklistId: number;
  isReadOnlyMode: boolean;
}

const ChecklistThemeRemarkComponent: React.FC<ChecklistThemeRemarkProps> = ({
  theme,
  checklistId,
  isReadOnlyMode,
}) => {
  const { language } = useContext(AppContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const { email } = useContext(AppContext);
  
  const themeId = theme?.id;
  const { setNotification } = useContext(AppContext);
  const [comment, setComment] = useState(theme.comment);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { onUpdate: updateChecklistTheme } = useUpdateChecklistTheme(
    checklistId,
  );

  const handleChecklistThemeRemarkDelete = () => {
    handleSetState(true);
    const nextValue = '';
    setComment(nextValue);
    debouncedSave(nextValue, themeId);
  };
  const handleChangeChecklistThemeComment = event => {
    handleSetState(true);
    const { value: nextValue } = event.target;
    setComment(nextValue);
    debouncedSave(nextValue, themeId);
  };
  const debouncedSave = useCallback(
    debounce(
      (nextValue, themeIdVar) =>
        updateChecklistTheme(themeIdVar, { comment: nextValue })?.then(
          response => {
            handleSetState(false);
          },
        ),
      1000,
    ),
    [],
  );

  const handleSetState = useCallback(isSavingVar => {
    setIsSaving(isSavingVar);
  }, []);

  useBeforeunload(event => {
    if (isSaving === true) {
      event.preventDefault();
      setNotification({ key: 'common:saving', type: NotificationType.WARNING });
      return null;
    }
  });

  const isReadOnlyModeCommentChange = () => {};

  return (
    <>
     <AuthorizationComponent
      rules={[Rule.CAN_VIEW_CHECKLIST_REMARK_MENU]}
      email={email}
    >
      <Paper elevation={0}>
        <Grid container>
          <Grid lg={3} xs={3} item>
            <Box m={2}>
              <Typography
                variant="subtitle2"
                className={classes.checklistThemeRemarkTitle}
              >
                {translateShortName(theme, language)}
              </Typography>
            </Box>
          </Grid>
          <Grid lg={8} xs={7} item>
            <Box m={2}>
              <TextareaAutosize
                aria-label={t('checklist_remark:themeRemarkLabel')}
                id="comment"
                name="comment"
                minRows={2}
                className={classes.textarea}
                value={comment}
                onChange={event =>
                  isReadOnlyMode
                    ? isReadOnlyModeCommentChange
                    : handleChangeChecklistThemeComment(event)
                }
                placeholder={t('checklist_remark:themeRemarkLabel')}
              />
            </Box>
          </Grid>
          <Grid item lg={1} xs={1}>
            <Box mt={2} ml={2}>
              <Button
                onClick={
                  isReadOnlyMode
                    ? isReadOnlyModeCommentChange
                    : handleChecklistThemeRemarkDelete
                }
              >
                <DeleteIcon color="primary" />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Paper>
      </AuthorizationComponent>
    </>
  );
};

export default ChecklistThemeRemarkComponent;
