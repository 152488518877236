import React, { useCallback, useEffect, useMemo } from 'react';
import { ItemRemarkFormData } from '../../../../services/Item/Remark/ItemRemarkFormData';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextareaAutosize,
  TextField,
  FormHelperText,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../../Shared/Styles/CommonFormSyles';
import { itemRemarkVar } from '../ItemRemarkCache';
import { useReactiveVar } from '@apollo/client';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useItemRemarkCheckUnique } from '../../../../services/Item/Remark/ReadService/ItemRemarkReadService';
import { useBrowserLocationState } from '../../../Shared/BrowserLocationState/BrowserLocationState';

export const formItemRemarkDialogTestIds = {
  updateCreateThemeNameInput: 'item-name',
  updateCreateItemRemakNameFrInput: 'Item-Remark-fr',
  updateCreateItemRemakNameNlInput: 'Item-Remark-nl',
  applyUpdateBtn: 'item-applyUpdateBtn',
  cancelUpdateBtn: 'item-cancelUpdateBtn',
  applyCreateAddBtn: 'item-applyCreateAddBtn',
};
export interface ItemFormProps {
  isOpenForm: boolean;
  handleClose: any;
  handleFormSubmit: any;
  handleCreateAddItemRemark?: any;
  isOpenCreateForm?: boolean;
  templateThemeItemId: number;
  id?: number;
}
const ItemRemarkFormComponent: React.FC<ItemFormProps> = ({
  isOpenForm,
  handleClose,
  handleFormSubmit,
  handleCreateAddItemRemark,
  isOpenCreateForm,
  templateThemeItemId,
  id
}: ItemFormProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formItem: ItemRemarkFormData = useReactiveVar(itemRemarkVar);

  const schema = useMemo(() => {
    return yup.object().shape({
      remark_text_fr: yup.string().nullable().required(t('item_remark:remarkTextFrError')),
      remark_text_nl: yup.string().nullable().required(t('item_remark:remarkTextNlError')),
    });
  }, [t])

  const { register, errors, setValue, handleSubmit, setError, reset } = useForm({
    resolver: yupResolver(schema),
  });
  const { onCheckUnique } = useItemRemarkCheckUnique()

  const currentLocationState = useBrowserLocationState<{ item: any }>({ keysRequired: ["item"] })

  const item = currentLocationState?.item
    ? currentLocationState?.item?.title_fr + ' / ' + currentLocationState?.item?.title_nl
    : '';

  // Update form when api call finishes
  useEffect(() => {
    setValue('remark_text_nl', formItem.remark_text_nl);
    setValue('remark_text_fr', formItem.remark_text_fr);
  }, [formItem, setValue]);

  // Register form inputs
  useEffect(() => {
    register({ name: 'remark_text_nl' });
    register({ name: 'remark_text_fr' });
  }, [register]);

  const handleChange = event => {
    if (event.target.value || event.target.value === '') {
      setValue(event.currentTarget.name, event.currentTarget.value as never);
      itemRemarkVar({
        ...formItem,
        [event.currentTarget.name]: event.currentTarget.value,
      });
    }
  };

  const onSubmit = useCallback((data, process) => {
    const { remark_text_fr, remark_text_nl } = data
    onCheckUnique({ remark_text_fr, remark_text_nl, template_theme_item_id: templateThemeItemId, id: id }).then(errorsVar => {
      if (errorsVar) {
        Object.keys(errorsVar).forEach(key => {
          setError(key, { type: "manual", message: errorsVar[key] })
        });
      } else {
        process(data)
      }
    })
  }, [onCheckUnique, setError, templateThemeItemId, id])

  const handleFormClose = useCallback(
    () => {
      reset();
      handleClose();
    }, [handleClose, reset]

  )

  return (
    <Dialog
      open={isOpenForm}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return false;
        } else {
          handleClose()
        }
      }}
      fullWidth={true}
    >
      <DialogContent>
        <h1>
          {isOpenCreateForm
            ? t('item_remark:createItem')
            : t('item_remark:updateItem')}
        </h1>
        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={3}
        >
          <Grid item className={classes.control}>
            <TextField
              data-testid={
                formItemRemarkDialogTestIds.updateCreateThemeNameInput
              }
              name="item_name"
              aria-readonly={true}
              label={t('item_remark:itemName')}
              variant="outlined"
              value={item}
              fullWidth
            />
          </Grid>
          <Grid item className={classes.control}>
            <TextareaAutosize
              data-testid={
                formItemRemarkDialogTestIds.updateCreateItemRemakNameFrInput
              }
              aria-label={t('item_remark:RemarktextFr')}
              id="remark_text_fr"
              name="remark_text_fr"
              minRows={2}
              className={classes.textarea}
              value={formItem.remark_text_fr}
              onChange={event => handleChange(event)}
              placeholder={t('item_remark:RemarktextFr')}
            />
            {errors.remark_text_fr && <FormHelperText error>{errors.remark_text_fr.message}</FormHelperText>}

          </Grid>
          <Grid item className={classes.control}>
            <TextareaAutosize
              data-testid={
                formItemRemarkDialogTestIds.updateCreateItemRemakNameNlInput
              }
              aria-label=" tesstt"
              id="remark_text_nl"
              name="remark_text_nl"
              minRows={2}
              className={classes.textarea}
              value={formItem.remark_text_nl}
              onChange={event => handleChange(event)}
              placeholder={t('item_remark:RemarktextNl')}
            />
            {errors.remark_text_nl && <FormHelperText error>{errors.remark_text_nl.message}</FormHelperText>}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions className={classes.divButton}>
        {handleCreateAddItemRemark ? (
          <Button
            className={classes.buttonValidateAdd}
            id="applyCreateAddBtn"
            data-testid={formItemRemarkDialogTestIds.applyCreateAddBtn}
            onClick={handleSubmit((data) => onSubmit(data, handleCreateAddItemRemark))}
            fullWidth
          >
            {t('item_remark:validateAdd')}
          </Button>
        ) : (
          ''
        )}

        <Button
          className={classes.buttonValidate}
          id="applyUpdateBtn"
          data-testid={formItemRemarkDialogTestIds.applyUpdateBtn}
          onClick={handleSubmit((data) => onSubmit(data, handleFormSubmit))}
          fullWidth
        >
          {t('common:validate')}
        </Button>
        <Button
          id="cancelUpdateBtn"
          data-testid={formItemRemarkDialogTestIds.cancelUpdateBtn}
          onClick={() => handleFormClose()}
          variant="contained"
          color="default"
          fullWidth
        >
          {t('common:cancel')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ItemRemarkFormComponent;
