import { useQuery } from '@apollo/client';
import { useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { checklist_response } from './CheckistReadModel';
import {
  GET_CHECKLIST_BY_PK,
  get_checklist_by_pk_data_response,
  GET_CHECKLIST_BY_TEMPLATE_ID_AND_AUDIT_ID_AND_BUILDING_ID,
} from './ChecklistReadQuery';

/**
 * Description - service to get a template by id
 */
export const useGetChecklistById = id => {
  const { setNotification } = useContext(MainAppContext);
  const { data, loading, error } = useQuery<get_checklist_by_pk_data_response>(
    GET_CHECKLIST_BY_PK,
    {
      variables: { id },
      skip: id === -1,
      onError() {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    },
  );
  return { data, loading, error };
};

export const useGetChecklistByTemplateIdAndBuildingIdAndAuditId = (
  template_id,
  audit_id,
  building_id,
) => {
  const { setNotification } = useContext(MainAppContext);

  const { data, loading, error } = useQuery<checklist_response>(
    GET_CHECKLIST_BY_TEMPLATE_ID_AND_AUDIT_ID_AND_BUILDING_ID,
    {
      variables: { template_id, audit_id, building_id },
      skip: template_id === -1,
      onError(errorResponse) {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    },
  );

  return { data, loading, error };
};
