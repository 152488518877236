import React from 'react';
import { AppContextType } from '../../../../../AppContext';
import { useLanguagePresenter } from '../LanguageMenuPresenter/LanguageMenuPresenter';
import LanguageMenuMenuView from '../LanguageMenuView/LanguageMenuView';

export interface LanguageMenuProps {
  classes: any;
  context: AppContextType;
}

/**
 * This component is responsible to display and handle the current language input (nl/fr)
 */
const LanguageMenuComponent = () => {
  const props = useLanguagePresenter();
  return <LanguageMenuMenuView {...props} />;
};

export default LanguageMenuComponent;
