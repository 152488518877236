import React, { useCallback, useContext, useMemo } from 'react';
import {
  IconButton,
  Table,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddRounded from '@material-ui/icons/AddRounded';
import { CreateRounded, DeleteRounded } from '@material-ui/icons';
import { useStyles } from '../Shared/Styles/CommonFormSyles';
import AppContext from '../../AppContext';
import { useHistory } from 'react-router-dom';
import routes from '../../routes';
import {
  openCreateItemVar,
  itemLastIndexVar,
  openUpdateItemVar,
} from '../Item/ItemCache';
import { theme_template_checklist_data_from_list } from '../../services/Theme/ThemeReadService';
import TableTrueValueComponent from '../Shared/Table/TableTrueValueComponent';
import TableFalseValueComponent from '../Shared/Table/TableFalseValueComponent';
import {
  translateLongName,
  translateShortName,
} from '../../services/Translation/TranslationService';
import {
  openCreateSubThemeVar,
  openUpdateSubThemeVar,
} from '../SubTheme/SubThemeCache';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useBrowserLocationState } from '../Shared/BrowserLocationState/BrowserLocationState';

export const tableTestIds = {
  buttonOnUpdate: 'button-table-update-theme',
  buttonOnDelete: 'button-table-delete-theme',
  buttonOnItemsVisibility: 'button-table-itemvisibility-theme',
  buttonOnSubThemesVisibility: 'button-table-subthemevisibility-theme',
  paginationComponent: 'pagination-component',
  tableThemeId: 'theme-table-id',
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,
  ...(isDragging && {
    background: 'rgb(235,235,235)',
  }),
});

export interface ThemeTableProps {
  isLoading?: boolean;
  themes: theme_template_checklist_data_from_list[];
  handleDeleteClicked: (row: theme_template_checklist_data_from_list) => void;
  openUpdateDialog: (row: theme_template_checklist_data_from_list) => void;
  onDragEnd: (result) => void;
}

const ThemeTableComponent: React.FC<ThemeTableProps> = ({
  themes,
  handleDeleteClicked,
  openUpdateDialog,
  onDragEnd,
}: ThemeTableProps) => {
  const { t } = useTranslation();
  const { language } = useContext(AppContext);
  const classes = useStyles();

  const currentLocationState = useBrowserLocationState<{template: any}>({keysRequired: ["template"]})

  const template = useMemo(
    () => (currentLocationState?.template ? currentLocationState?.template : undefined),
    [currentLocationState],
  );

  const handleOpenUpdateDialog = useCallback(
    (rowData: theme_template_checklist_data_from_list) =>
      openUpdateDialog(Array.isArray(rowData) ? rowData[0] : rowData),
    [openUpdateDialog],
  );

  const onDeleteClicked = useCallback(
    (rowData: theme_template_checklist_data_from_list) =>
      handleDeleteClicked(Array.isArray(rowData) ? rowData[0] : rowData),
    [handleDeleteClicked],
  );
  const history = useHistory();
  const handleCreateItem = (theme: theme_template_checklist_data_from_list) => {
    openCreateItemVar(true);
    itemLastIndexVar(1);
    history.push({
      pathname: routes.createItem.path,
      state: {
        theme,
        template,
      },
    });
  };

  const handleCreateSubTheme = (
    theme: theme_template_checklist_data_from_list,
  ) => {
    openCreateSubThemeVar(true);
    itemLastIndexVar(1);
    history.push({
      pathname: routes.createSubThemeTemplate.path,
      state: {
        theme,
        template,
      },
    });
  };
  const handleOpenListOfSubthemeFromTheme = (
    theme: theme_template_checklist_data_from_list,
  ) => {
    openCreateSubThemeVar(false);
    openUpdateSubThemeVar(false);
    history.push({
      pathname: routes.allSubThemesTemplate.path,
      state: {
        theme,
        template,
      },
    });
  };

  const handleOpenListOfItemFromTheme = (
    theme: theme_template_checklist_data_from_list,
  ) => {
    openCreateItemVar(false);
    openUpdateItemVar(false);
    history.push({
      pathname: routes.allItems.path,
      state: {
        template,
        theme
      },
    });
  };

  const checkNotDeletedSubTteme = (
    currentTheme: theme_template_checklist_data_from_list | undefined,
  ) => {
    let haveNotDeletedSubtheme: boolean = false;
    if (
      currentTheme &&
      currentTheme.sub_theme_template_checklists &&
      currentTheme.sub_theme_template_checklists.length > 0
    ) {
      haveNotDeletedSubtheme = currentTheme.sub_theme_template_checklists.some(
        subtheme => subtheme?.deleted_date == null,
      );
    }
    return haveNotDeletedSubtheme;
  };

  const checkNotDeletedItem = (
    currentTheme: theme_template_checklist_data_from_list | undefined,
  ) => {
    let haveNotDeletedItem: boolean = false;
    if (
      currentTheme &&
      currentTheme.template_theme_items &&
      currentTheme.template_theme_items.length > 0
    ) {
      haveNotDeletedItem = currentTheme.template_theme_items.some(
        item => item?.deleted_at == null,
      );
    }
    return haveNotDeletedItem;
  };

  return (
    <>
    <Paper className={classes.paper}>
    <TableContainer className={classes.tableContainer}>
    <Table stickyHeader className={classes.table} aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableRow}>{t('common:order')}</TableCell>
            <TableCell className={classes.tableRow}>{t('theme:longName')}</TableCell>
            <TableCell className={classes.tableRow}>{t('theme:shortName')}</TableCell>
            <TableCell className={classes.tableRow}>{t('theme:duplicable')}</TableCell>
            <TableCell className={classes.tableRow}>{t('theme:notApplicable')}</TableCell>
            <TableCell className={classes.tableRow} align="center">{t('theme:item')}</TableCell>
            <TableCell className={classes.tableRow}>{t('subtheme:subTheme')}</TableCell>
            <TableCell className={classes.tableRow} align="center">{t('common:edit')}</TableCell>
            <TableCell className={classes.tableRow} align="center">{t('common:delete')}</TableCell>
          </TableRow>
        </TableHead>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={'theme-table-body'} direction="vertical">
            {provided => {
              return (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {themes.map((theme, index) => (
                    <Draggable
                      key={theme.id}
                      draggableId={theme.id.toString()}
                      index={index}
                    >
                      {(provided_var, snapshot) => (
                        <TableRow
                          ref={provided_var.innerRef}
                          {...provided_var.draggableProps}
                          {...provided_var.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided_var.draggableProps.style,
                          )}
                        >
                          <TableCell align="center" className={classes.index}>
                            {index + 1}
                          </TableCell>
                          <TableCell>
                            {translateLongName(theme, language)}
                          </TableCell>
                          <TableCell>
                            {translateShortName(theme, language)}
                          </TableCell>
                          <TableCell
                            className={classes.tableSmallCellSize}
                            align="center"
                          >
                            {theme.duplicable ? (
                              <TableTrueValueComponent />
                            ) : (
                              <TableFalseValueComponent />
                            )}
                          </TableCell>
                          <TableCell
                            className={classes.tableSmallCellSize}
                            align="center"
                          >
                            {theme.not_applicable_option ? (
                              <TableTrueValueComponent />
                            ) : (
                              <TableFalseValueComponent />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {checkNotDeletedItem(theme) ? (
                              //exist items
                              <IconButton
                                data-testid={
                                  tableTestIds.buttonOnItemsVisibility
                                }
                                onClick={() =>
                                  handleOpenListOfItemFromTheme(theme)
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                data-testid={
                                  tableTestIds.buttonOnItemsVisibility
                                }
                                onClick={() => handleCreateItem(theme)}
                              >
                                <AddRounded />
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {checkNotDeletedSubTteme(theme) ? (
                              //exist subThemes
                              <IconButton
                                data-testid={
                                  tableTestIds.buttonOnSubThemesVisibility
                                }
                                onClick={() =>
                                  handleOpenListOfSubthemeFromTheme(theme)
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                data-testid={
                                  tableTestIds.buttonOnSubThemesVisibility
                                }
                                onClick={() => handleCreateSubTheme(theme)}
                              >
                                <AddRounded />
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              data-testid={tableTestIds.buttonOnUpdate}
                              onClick={() => handleOpenUpdateDialog(theme)}
                            >
                              <CreateRounded />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              data-testid={tableTestIds.buttonOnDelete}
                              onClick={() => onDeleteClicked(theme)}
                            >
                              <DeleteRounded />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              );
            }}
          </Droppable>
        </DragDropContext>
    </Table>
    </TableContainer>
    </Paper>
    </>
  );
};
export default ThemeTableComponent;
