import { useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { AuditStatus } from '../AuditDataModel';
import {
  READ_ALL_DONE_AUDITS_SORTED,
  READ_CURRENT_AUDITS,
} from '../ReadService/AuditReadQuery';
import { CLOSE_AUDIT } from './AuditCloseMutation';

/**
 * Description - Service to update audit status value to "DONE"
 */
export const useCloseAudit = (): { onCloseAudit: (id: number) => void } => {
  const { setNotification } = useContext(MainAppContext);

  const [closeAudit] = useMutation(CLOSE_AUDIT, {
    refetchQueries: [
      {
        query: READ_ALL_DONE_AUDITS_SORTED,
        variables: { order_by: { last_updated_date: 'desc' } },
      },
      { query: READ_CURRENT_AUDITS },
    ],
    awaitRefetchQueries: true,
    onCompleted({ closedAudit }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const onCloseAudit = useCallback(
    (id: number) => {
      const qat_audit_pk_columns_input = { id: id };
      const qat_audit_set_input = { audit_status: AuditStatus.DONE };
      closeAudit({
        variables: { qat_audit_pk_columns_input, qat_audit_set_input },
      });
    },
    [closeAudit],
  );

  return { onCloseAudit };
};
