import { useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import AppContext from '../../../AppContext';
import {
  INSERT_CHECKLIST_THEME_SECTION,
  DELETE_CHECKLIST_THEME_SECTION,
} from '.';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { cheklist_section } from '../../Checklist/ChecklistReadService/CheckistReadModel';
import { GET_CHECKLIST_BY_PK } from '../../Checklist/ChecklistReadService';
import {
  ChecklistItemInsertInputType,
  ChecklistRemarkInsertInpuType,
} from '../../Checklist/ChecklistWriteService/ChecklistWriteService';

export interface ThemeChecklistSectionDuplicateResult {
  onCreate: ({ data: cheklist_section }) => Promise<any>;
}

/**
 * Description - service to duplicate checklist themesection in same theme
 */
export const useDuplicateChecklistThemeSection = ({
  checklistId,
}: {
  checklistId: number;
}): ThemeChecklistSectionDuplicateResult => {
  const { setNotification } = useContext(AppContext);
  const [create] = useMutation(INSERT_CHECKLIST_THEME_SECTION, {
    refetchQueries: [
      { query: GET_CHECKLIST_BY_PK, variables: { id: checklistId } },
    ],
    awaitRefetchQueries: true,
    onError: () => {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const onCreate = useCallback(
    async ({ data }: { data: cheklist_section }) => {
      const { checklist_theme_id, theme_template_checklist_id } = data;
      const newData: any = {
        checklist_theme_id,
        theme_template_checklist_id,
        specification: '',
        name_fr: data.theme_template_checklist
          ? data.theme_template_checklist.long_name_fr
          : '',
        name_nl: data.theme_template_checklist
          ? data.theme_template_checklist.long_name_nl
          : '',
        no_application: data?.no_application,
      };
      if (data.checklist_theme_sub_sections) {
        newData.checklist_theme_sub_sections = {
          data: data.checklist_theme_sub_sections.map(subSection => {
            const checklist_theme_items = subSection.checklist_theme_items?.map(
              item => {
                const checklist_theme_item_remarks:
                  | ChecklistRemarkInsertInpuType[]
                  | undefined = item.template_theme_item?.template_theme_item_remarks?.map(
                    remarkOption => ({
                      name_fr: remarkOption.remark_text_fr,
                      name_nl: remarkOption.remark_text_nl,
                      checked: false,
                      template_theme_item_remark_id: remarkOption.id,
                      index: remarkOption.index,
                    }),
                  );

                const checklist_items: ChecklistItemInsertInputType = {
                  not_application: false,
                  has_interview: false,
                  template_theme_item_id:
                    item.template_theme_item !== undefined
                      ? item.template_theme_item.id
                      : -1,
                  name_fr: item.template_theme_item
                    ? item.template_theme_item.title_fr
                    : '',
                  name_nl: item.template_theme_item
                    ? item.template_theme_item.title_nl
                    : '',
                  page_pdf_nl: item.template_theme_item?.page_pdf_nl,
                  page_pdf_fr: item.template_theme_item?.page_pdf_fr,
                  norm_code: item.template_theme_item?.norm_code,
                };
                if (checklist_theme_item_remarks) {
                  checklist_items.checklist_theme_item_remarks = {
                    data: checklist_theme_item_remarks,
                  };
                }
                return checklist_items;
              },
            );
            return {
              sub_theme_template_checklist_id:
                subSection.sub_theme_template_checklist?.id,
              checklist_theme_items: { data: checklist_theme_items },
              name_fr: subSection.sub_theme_template_checklist
                ? subSection.sub_theme_template_checklist.name_fr
                : '',
              name_nl: subSection.sub_theme_template_checklist
                ? subSection.sub_theme_template_checklist.name_nl
                : '',
              not_applicable_option: subSection.not_applicable_option,
            };
          }),
        };
      }

      newData.checklist_theme_items = {
        data: data.checklist_theme_items?.map(item => {
          const checklist_theme_item_remarks:
            | ChecklistRemarkInsertInpuType[]
            | undefined = item.template_theme_item?.template_theme_item_remarks?.map(
              remarkOption => ({
                name_fr: remarkOption.remark_text_fr,
                name_nl: remarkOption.remark_text_nl,
                checked: false,
                template_theme_item_remark_id: remarkOption.id,
                index: remarkOption.index,
              }),
            );
          return {
            not_application: false,
            has_interview: false,
            template_theme_item_id: item.template_theme_item?.id,
            name_fr: item.template_theme_item
              ? item.template_theme_item.title_fr
              : '',
            name_nl: item.template_theme_item
              ? item.template_theme_item.title_nl
              : '',
            checklist_theme_item_remarks: {
              data: checklist_theme_item_remarks,
            },
            page_pdf_nl: item.template_theme_item?.page_pdf_nl,
            page_pdf_fr: item.template_theme_item?.page_pdf_fr,
            norm_code: item.template_theme_item?.norm_code,
          };
        }),
      };
      return await create({ variables: { object: newData } });
    },
    [create],
  );

  return { onCreate };
};

export interface ThemeChecklistSectionDeleteResult {
  onDelete: (id: number) => Promise<any>;
}

/**
 * Description - service to delete checklist themesection in same theme
 */
export const useDeleteChecklistThemeSection = ({
  checklistId,
}: {
  checklistId: number;
}): ThemeChecklistSectionDeleteResult => {
  const { setNotification } = useContext(AppContext);

  const [deleteMutation] = useMutation(DELETE_CHECKLIST_THEME_SECTION, {
    refetchQueries: [
      { query: GET_CHECKLIST_BY_PK, variables: { id: checklistId } },
    ],
    awaitRefetchQueries: true,
    onError: () => {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const onDelete = useCallback(
    (id: number) => {
      return deleteMutation({ variables: { id } });
    },
    [deleteMutation],
  );

  return { onDelete };
};
