import React, { useCallback, useMemo, useState } from 'react';
import AddressFormComponent from '../../AddressForm/AddressFormComponent';
import { useHistory } from 'react-router-dom';
import routes from '../../../../routes';
import {
  AddressUpdateFormData,
  useUpdateAddress,
} from '../../../../services/Address/UpdateService/AddressUpdateService';
import ConfirmDialog from '../../../Shared/Dialog/ConfirmDialog/ConfirmDialogComponent/ConfirmDialogComponent';
import { useTranslation } from 'react-i18next';
import { useGetBuildingsByAddressId } from '../../../../services/Building/ReadService/BuildingReadService';
import { useBrowserLocationState } from '../../../Shared/BrowserLocationState/BrowserLocationState';

/**
 * Description - Component to update an address
 */
const AddressUpdateComponent = ({ parentPath }) => {
  const currentLocationState = useBrowserLocationState<{audit: any, building: any, address: any}>({keysRequired: ["audit", "building", "address"]})

  const { onUpdate } = useUpdateAddress();
  const history = useHistory();
  const [
    openUpdateConfirmDialog,
    setOpenUpdateConfirmDialog,
  ] = useState<boolean>(false);

  const { t } = useTranslation();
  const { getBuildingsByAddressId } = useGetBuildingsByAddressId();

  const audit = useMemo(() => {
    if (currentLocationState?.audit) {
      return currentLocationState.audit
    } else {
      console.error("audit is required from location.state")
      return null
    }
  }, [currentLocationState])

  const [
    locationAddress,
    setLocationAddress,
] = useState<AddressUpdateFormData>();

  const onUpdateAndRedirect = useCallback((_address) => {
    onUpdate(currentLocationState?.address?.id, _address).then(response => {
      if (response !== null) {
        const linkPath = currentLocationState?.building?.id
          ? `${parentPath}${routes.editBuilding.path}`
          : `${parentPath}${routes.createBuilding.path}`;
        
        const state: any = {
          audit
        }
  
        if(currentLocationState?.building?.id){
          state.building = {...currentLocationState.building, address: {id: response?.data?.update_qat_address_by_pk?.id}}
          state.building_id = currentLocationState.building.id
          state.address_id_to_inject_in_form = _address.id
        } else {
          state.address_id_to_inject_in_form = _address.id
        }

        history.push({
          pathname: linkPath,
          state
        });
      }
    });
  }, [history, currentLocationState, audit, onUpdate, parentPath])

  const handleClose = useCallback(() => {    
        const linkPath = currentLocationState?.building?.id
          ? `${parentPath}${routes.editBuilding.path}`
          : `${parentPath}${routes.createBuilding.path}`;
        let _building = {
          ...currentLocationState?.building,
          address: { id: currentLocationState?.address?.id },
        };
        
        history.push({
          pathname: linkPath,
          state: { audit: audit, building_id: _building.id ,building : _building },
        });
      
 
  }, [history, currentLocationState, audit, parentPath])

  const onSubmit = useCallback(
    async (_address: AddressUpdateFormData) => {
      getBuildingsByAddressId(currentLocationState?.address?.id).then(addressCount => {
        _address.id = currentLocationState?.address?.id
        if (addressCount > 0) {
          setOpenUpdateConfirmDialog(true);
          setLocationAddress(_address);
        } else {
          onUpdateAndRedirect(_address)
        }
      });
    },
    [currentLocationState, getBuildingsByAddressId, onUpdateAndRedirect],
  );

  const onConfirmedToUpdate = useCallback(() => {
    setOpenUpdateConfirmDialog(false);
    if (currentLocationState?.address?.id && locationAddress) {
      onUpdateAndRedirect(locationAddress)
    } else {
      throw new Error("the Address id to update doesn't exist");
    }

  }, [locationAddress, currentLocationState, onUpdateAndRedirect]);

  return (
    <div>
      <AddressFormComponent
        title="address:updateAddress"
        submitButtonLabel="common:update"
        audit={audit}
        address={currentLocationState?.address}
        onSubmit={onSubmit}
        handleClose={handleClose}
      />
      <ConfirmDialog
        open={openUpdateConfirmDialog}
        onClose={() => setOpenUpdateConfirmDialog(false)}
        onConfirm={onConfirmedToUpdate}
        title={t('address:updateAddressTitle')}
        text={t('address:updateAddressMsg')}
      />
    </div>
  );
};

export default AddressUpdateComponent;
