import {
  IconButton,
  makeStyles,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../../../AppContext';
import { template_checklist_data_from_list } from '../../../../../services/TemplateChecklist/TemplateChecklistReadService';
import { translate } from '../../../../../services/Translation/TranslationService';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { CreateRounded, DeleteRounded } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import routes from '../../../../../routes';

export interface TemplateChecklistTableComponentProps {
  templates: template_checklist_data_from_list[];
  onDelete: (id: number) => void;
  onUpdate: (id: number) => void;
}

export const testIds = {
  buttonToSeeTheme: 'template-checklist-button-see-theme',
  buttonOnUpdate: 'template-checklist-button-update',
  buttonOnDelete: 'template-checklist-button-delete',
};
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },

  tableRow: {
    backgroundColor: '#f0f0f0',
  },

  paper: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },

  tableContainer: {
    height: 'auto',
    maxHeight: 500,
  },
});

/**
 * Description - Table to list template checklist
 */
const TemplateChecklistTableComponent: React.FC<TemplateChecklistTableComponentProps> = ({
  templates,
  onDelete,
  onUpdate,
}) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const { language } = useContext(AppContext);

  return (
    <Paper className={classes.paper}>
    <TableContainer className={classes.tableContainer}>
    <Table stickyHeader className={classes.table} aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableRow}>{t('template:name')}</TableCell>
            <TableCell className={classes.tableRow}>{t('entity:category')}</TableCell>
            <TableCell className={classes.tableRow}>{t('subcategory:subCategory')}</TableCell>
            <TableCell className={classes.tableRow} align="center">{t('theme:theme')}</TableCell>
            <TableCell className={classes.tableRow} align="center">{t('common:edit')}</TableCell>
            <TableCell className={classes.tableRow} align="center">{t('common:delete')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {templates.map(template => (
            <TableRow key={template.id}>
              <TableCell>{translate(template, language)}</TableCell>
              <TableCell>
                {translate(template.category_location, language)}
              </TableCell>
              <TableCell>
                {template.sub_category_location
                  ? translate(template.sub_category_location, language)
                  : ''}
              </TableCell>
              <TableCell align="center">
                <IconButton
                  data-testid={testIds.buttonToSeeTheme}
                  component={Link}
                  to={{
                    pathname: routes.allThemesTemplate.path,
                    state: {
                      template: template,
                      categoryLocationId: template.category_location.id,
                      subCategoryLocationId: template.sub_category_location
                        ? template.sub_category_location.id
                        : undefined,
                    },
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              </TableCell>
              <TableCell align="center">
                <IconButton
                  data-testid={testIds.buttonOnUpdate}
                  component={Link}
                  to={routes.editTemplate.path}
                  onClick={() => onUpdate(template.id)}
                >
                  <CreateRounded />
                </IconButton>
              </TableCell>
              <TableCell align="center">
                <IconButton
                  data-testid={testIds.buttonOnDelete}
                  onClick={() => onDelete(template.id)}
                >
                  <DeleteRounded />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
    </Table>
    </TableContainer>
    </Paper>
  );
};
export default TemplateChecklistTableComponent;
