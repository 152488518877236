import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LinearProgress } from '@material-ui/core';
import { useStyles } from './EntityReadStyle';
import routes from '../../../../routes';
import AuthorizationComponent from '../../../Authorization/AuthorizationComponent/AuthorizationComponent';
import SearchField from '../../../Shared/Field/SearchField/SearchField';
import PaginationComponent from '../../../Shared/Pagination/PaginationComponent/PaginationComponent';
import EntityTableComponent from './EntityTableComponent/EntityTableComponent';
import ConfirmDialog from '../../../Shared/Dialog/ConfirmDialog/ConfirmDialogComponent/ConfirmDialogComponent';
import { Rule } from '../../../Authorization/AuthorizationComponent/Permissions';
import AddButtonComponent from '../../../Shared/Button/AddButtonComponent';
import { entity_data } from '../../../../services/Entity/EntityDataModel';
import { useGetAllSortedEntities } from '../../../../services/Entity/ReadService/EntityReadService';
import PageContainer from '../../../Shared/Container/PageContainer';

export const testIds = {
  buttonOnCreate: 'button-create-entity',
  dialogConfirmDelete: 'dialog-confirm-delete',
  searchFieldComponent: 'search-field-component',
  paginationComponent: 'entity-pagination-component',
};

export interface EntityReadComponentProps {
  listEntities: Array<entity_data>;
  loading: boolean;
  onUpdate: (id: number) => void;
  onDelete: (id: number) => void;
}

export interface OrderBy {
  column: string;
  direction?: OrderDirection;
}

export enum OrderDirection {
  ASC = 'asc',
  DESC = 'desc',
}

/**
 * Description - UI Component to list entities
 */
const EntityReadComponent: React.FC<EntityReadComponentProps> = ({
  listEntities,
  loading,
  onUpdate,
  onDelete,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [
    openDeleteConfirmDialog,
    setOpenDeleteConfirmDialog,
  ] = useState<boolean>(false);
  const [entityIdToDelete, setEntityIdToDelete] = useState<number>(-1);
  const [listEntitiesFiltered, setListEntitiesFiltered] = useState<
    Array<entity_data>
  >(listEntities);
  const [sortByItems, setSortByItems] = React.useState<OrderBy[]>([
    { column: 'label' },
    { column: 'abbreviation' },
  ]);
  const { orderEntitiesBy } = useGetAllSortedEntities();

  const handleDeleteButtonClick = useCallback((id: number) => {
    setOpenDeleteConfirmDialog(true);
    setEntityIdToDelete(id);
  }, []);

  const handleSort = sortItem => {
    const updatedSortingItems = sortByItems?.map(item => {
      if (item?.column === sortItem?.column) {
        item.direction =
          item?.direction === OrderDirection.ASC
            ? OrderDirection.DESC
            : OrderDirection.ASC;
        sortByColumn(item);
      } else delete item.direction;
      return item;
    });

    setSortByItems(updatedSortingItems);
  };

  const sortByColumn = item => {
    orderEntitiesBy(item).then(entities => setListEntitiesFiltered(entities));
  };

  const onConfirmedToDelete = useCallback(() => {
    if (entityIdToDelete !== -1) {
      onDelete(entityIdToDelete);
      setOpenDeleteConfirmDialog(false);
      setEntityIdToDelete(-1);
    } else {
      throw new Error("the entity id to delete doesn't exist");
    }
  }, [entityIdToDelete, onDelete]);

  const filterEntitiesByLabel = useCallback(
    (filter: string) => {
      const newFilter = filter.toLowerCase();
      if (newFilter && newFilter !== '') {
        setListEntitiesFiltered(() => {
          const newListEntitiesFiltered: Array<entity_data> = listEntities.filter(
            entity =>
              entity.label.toLowerCase().includes(newFilter) ||
              entity.abbreviation.toLowerCase().includes(newFilter),
          );
          return newListEntitiesFiltered;
        });
      } else {
        setListEntitiesFiltered(listEntities);
      }
    },
    [listEntities],
  );

  return (
    <AuthorizationComponent
      rules={[Rule.CAN_VIEW_ENTITY_CRUD]}
      message={'common:unauthorized'}
    >
      {!loading ? (
        <PageContainer>
          <div className={classes.tableHeader}>
            <AuthorizationComponent
              rules={[Rule.CAN_VIEW_ENTITY_CRUD]}
              message={'common:readOnly'}
            >
              <div data-testid={testIds.buttonOnCreate}>
                <AddButtonComponent
                  label="entity:createEntity"
                  linkPath={routes.createEntity.path}
                />
              </div>
            </AuthorizationComponent>
            <div
              data-testid={testIds.searchFieldComponent}
              className={classes.search}
            >
              <SearchField onChange={filterEntitiesByLabel} />
            </div>
          </div>
          <PaginationComponent
            items={listEntitiesFiltered}
            itemsPropsName="listEntities"
            dataTestId={testIds.paginationComponent}
          >
            <EntityTableComponent
              listEntities={listEntitiesFiltered}
              onUpdate={onUpdate}
              onDelete={handleDeleteButtonClick}
              onSort={handleSort}
              sortByItems={sortByItems}
            />
          </PaginationComponent>
          <ConfirmDialog
            dataTestId={testIds.dialogConfirmDelete}
            open={openDeleteConfirmDialog}
            onClose={() => setOpenDeleteConfirmDialog(false)}
            onConfirm={onConfirmedToDelete}
            title={t('common:delete')}
            text={t('common:confirmDelete')}
          />
        </PageContainer>
      ) : (
        <LinearProgress />
      )}
    </AuthorizationComponent>
  );
};

export default EntityReadComponent;
