import React, { useContext } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Paper,
  createStyles,
  TableSortLabel,
  Theme,
  withStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CreateRounded, DeleteRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import routes from '../../../../routes';
import { audit_data } from '../../../../services/Audit/AuditDataModel';
import moment from 'moment';
import AuthorizationComponent from '../../../Authorization/AuthorizationComponent/AuthorizationComponent';
import AuthorizationReadOnlyAuditComponent from '../../../Authorization/AuthorizationComponent/AuthorizationReadOnlyAuditComponent';
import { Rule } from '../../../Authorization/AuthorizationComponent/Permissions';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AppContext from '../../../../AppContext';
import { OrderBy } from '../../../Entity/EntityRead/EntityReadComponent/EntityReadComponent';
import { isMobile } from 'react-device-detect';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DescriptionIcon from '@material-ui/icons/Description';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },

  tableRow: {
    backgroundColor: '#f0f0f0',
  },

  paper: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },

  tableContainer: {
    height: 'auto',
    maxHeight: 500,
  },

  arrowButton: {
    color: '#03a9f4',
  },
});

const StyledTableSortLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#03a9f4',
      '&:hover': {
        color: '#03a9f4',
      },
      '&$active': {
        color: '#03a9f4',
      },
    },
    active: {},
    icon: {
      color: 'inherit !important',
    },
  }),
)(TableSortLabel);

export const testIds = {
  buttonOnClose: 'audit-table-button-close',
  buttonOnUpdate: 'audit-table-button-update',
  buttonOnDelete: 'audit-table-button-delete',
  buttonOnOpenReport: 'audit-table-button-report',
};

export interface AuditTableComponentProps {
  audits: audit_data[];
  onUpdate?: (id: number) => void;
  onDelete?: (id: number) => void;
  onClose?: (id: number) => void;
  onSort?: (sortItem: OrderBy) => void;
  sortByItems?: OrderBy[];
  fromListOfAudits?:boolean
}

/**
 * Description - Audit table view to read, update and delete audits
 */
const AuditTableComponent: React.FC<AuditTableComponentProps> = ({
  audits,
  onUpdate,
  onDelete,
  onClose,
  onSort,
  sortByItems,
  fromListOfAudits
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { language } = useContext(AppContext);

   const translateAndChangeLogementToLmgt = (object): string => {
     return language.substring(0, 2) === 'nl' ? object?.name_nl : fromListOfAudits && (object?.name_fr === "Logement")   ? "Lgmt" : object?.name_fr;
  };

  return (
      <Paper className={classes.paper}>
      <TableContainer className={classes.tableContainer}>
      <Table stickyHeader className={classes.table} aria-label="sticky table">
        <TableHead>
          <TableRow>
            {!isMobile && (
              <TableCell className={classes.tableRow}>
                {t('entity:entity')}{' '}
                {sortByItems && onSort && (
                  <StyledTableSortLabel
                    className={classes.arrowButton}
                    direction={
                      sortByItems.find(i => i?.column === 'entity')?.direction
                    }
                    onClick={() => onSort({ column: 'entity' })}
                    active={true}
                  />
                )}
              </TableCell>
            )}
            {!isMobile && (
              <TableCell className={classes.tableRow}>
                {!fromListOfAudits ? t('audit:category') : t('audit:cat')}{' '}
                {sortByItems && onSort && (
                  <StyledTableSortLabel
                    className={classes.arrowButton}
                    direction={
                      sortByItems.find(i => i?.column === 'category')?.direction
                    }
                    onClick={() => onSort({ column: 'category' })}
                    active={true}
                  />
                )}
              </TableCell>
            )}              

           
            {!isMobile && fromListOfAudits && (
              <TableCell className={classes.tableRow}>
                {t('audit:specification')}{' '}
                {sortByItems && onSort && (
                  <StyledTableSortLabel
                    className={classes.arrowButton}
                    direction={
                      sortByItems.find(i => i?.column === 'specification')?.direction
                    }
                    onClick={() => onSort({ column: 'specification' })}
                    active={true}
                  />
                )}
              </TableCell>
            )}
            <TableCell className={classes.tableRow}>
              {t('audit:location')}{' '}
              {sortByItems && onSort && (
                <StyledTableSortLabel
                  className={classes.arrowButton}
                  direction={
                    sortByItems.find(i => i?.column === 'location')?.direction
                  }
                  onClick={() => onSort({ column: 'location' })}
                  active={true}
                />
              )}
            </TableCell>
            <TableCell className={classes.tableRow}>
              {t('audit:auditDate')}{' '}
              {sortByItems && onSort && (
                <StyledTableSortLabel
                  className={classes.arrowButton}
                  direction={
                    sortByItems.find(i => i?.column === 'auditDate')?.direction
                  }
                  onClick={() => onSort({ column: 'auditDate' })}
                  active={true}
                />
              )}
            </TableCell>
            <TableCell className={classes.tableRow}>
              {t('audit:auditor')}{' '}
              {sortByItems && onSort && (
                <StyledTableSortLabel
                  className={classes.arrowButton}
                  direction={
                    sortByItems.find(i => i?.column === 'auditor')?.direction
                  }
                  onClick={() => onSort({ column: 'auditor' })}
                  active={true}
                />
              )}
            </TableCell>
            {onClose && (
              <TableCell className={classes.tableRow} align="center">{t('audit:close')}</TableCell>
            )}
            <TableCell className={classes.tableRow} align="center">{t('common:report')}</TableCell>
            {onUpdate && (
              <TableCell className={classes.tableRow} align="center">{t('common:consEdt')}</TableCell>
            )}
            {onDelete && (
              <TableCell className={classes.tableRow} align="center">{t('common:delete')}</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {Array.from(audits).map(audit => (
            <TableRow key={audit.id}>
              {!isMobile && <TableCell>{audit?.entity?.label}</TableCell>}
              {!isMobile && (
                <TableCell>
                  {translateAndChangeLogementToLmgt(
                    audit?.location_entity?.category_location
                  )}
                </TableCell>
              )}             
              {!isMobile && fromListOfAudits && (
              <TableCell>{audit?.location_entity?.specification}</TableCell>)}

              <TableCell>{audit?.location_entity?.name}</TableCell>
             
              <TableCell>
                {moment(audit?.audit_date).format('DD/MM/YYYY')}
              </TableCell>
              <TableCell>{audit?.user_id}</TableCell>
              {onClose && (
                <TableCell align="center">
                  <AuthorizationComponent
                    rules={[Rule.CAN_VIEW_AUDIT_CRUD]}
                    message={'common:readOnly'}
                    email={audit?.auditor_email}
                  >
                    <IconButton
                      data-testid={testIds.buttonOnClose}
                      onClick={() => onClose(audit?.id)}
                    >
                      <PowerSettingsNewIcon />
                    </IconButton>
                  </AuthorizationComponent>
                </TableCell>
              )}
              <TableCell align="center">
                <AuthorizationReadOnlyAuditComponent
                  rules={[Rule.CAN_VIEW_AUDIT_CRUD]}
                  message={'common:readOnly'}
                  email={audit?.auditor_email}
                  readOnlyComponent={
                    <IconButton
                      data-testid={testIds.buttonOnOpenReport}
                      component={Link}
                      to={{
                        pathname: `${routes.checklistReport.path}`,
                        state: { audit: audit, isReadonly: true },
                      }}
                    >
                      <DescriptionIcon />
                    </IconButton>
                  }
                >
                  <IconButton
                    data-testid={testIds.buttonOnOpenReport}
                    component={Link}
                    to={{
                      pathname: `${routes.checklistReport.path}`,
                      state: { audit: audit },
                    }}
                  >
                    <DescriptionIcon />
                  </IconButton>
                </AuthorizationReadOnlyAuditComponent>
              </TableCell>
              {onUpdate && (
                <TableCell align="center">
                  <AuthorizationReadOnlyAuditComponent
                    rules={[Rule.CAN_VIEW_AUDIT_CRUD]}
                    message={'common:readOnly'}
                    email={audit?.auditor_email}
                    readOnlyComponent={
                      <IconButton
                        data-testid={testIds.buttonOnOpenReport}
                        component={Link}
                        to={{
                          pathname: `${routes.editAudit.path}${routes.editAuditMainInfo.path}`,
                          state: { audit: audit, isReadonly: true },
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    }
                  >
                    <IconButton
                      data-testid={testIds.buttonOnUpdate}
                      component={Link}
                      to={{
                        pathname: `${routes.editAudit.path}${routes.editAuditMainInfo.path}`,
                        state: { audit: audit },
                      }}
                      onClick={() => onUpdate(audit?.id)}
                    >
                      <CreateRounded />
                    </IconButton>
                  </AuthorizationReadOnlyAuditComponent>
                </TableCell>
              )}
              {onDelete && (
                <TableCell align="center">
                  <AuthorizationComponent
                    rules={[Rule.CAN_VIEW_AUDIT_CRUD]}
                    message={'common:readOnly'}
                    email={audit?.auditor_email}
                  >
                    <IconButton
                      data-testid={testIds.buttonOnDelete}
                      onClick={() => onDelete(audit?.id)}
                    >
                      <DeleteRounded />
                    </IconButton>
                  </AuthorizationComponent>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </TableContainer>
      </Paper>
  );
};

export default AuditTableComponent;
