import { gql } from '@apollo/client';

/**
 * Description - graphql query to delete an theme template checklist
 */
export const DELETE_THEME_TEMPLATE_CHECKLIST = gql`
  mutation delete_soft_qat_theme_template_checklist_by_pk(
    $id: Int!
    $deletedDate: timestamptz
  ) {
    update_qat_theme_template_checklist_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_date: $deletedDate }
    ) {
      id
    }
  }
`;
