import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback, useContext, useMemo } from 'react';
import { get_by_building_by_pk_data, get_by_building_by_pk_response } from '.';
import AppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { building_data } from '../BuildingDataModel';
import {
  buildings_data,
  unique_building_audit_id_address_id_response,
  unique_building_audit_id_address_id_variables,
} from './BuildingReadModel';
import {
  GET_BUILDINGS_BY_ADDRESSID,
  GET_BUILDINGS_BY_AUDIT_ID_LENGTH,
  GET_BUILDING_BY_AUDIT,
  GET_BUILDING_BY_PK,
  UNIQUE_BUILDING_AUDIT_ID_ADDRESS_ID,
} from './BuildingReadQuery';

export const useGetBuildingById = ({
  id,
}: {
  id: number;
}): { data: get_by_building_by_pk_data | null; loading: boolean } => {
  const { setNotification } = useContext(AppContext);
  const { data, loading } = useQuery<
    get_by_building_by_pk_response,
    { id: number }
  >(GET_BUILDING_BY_PK, {
    variables: { id },
    onError: () => {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  return {
    data: data ? data?.qat_building_by_pk : null,
    loading,
  };
};

export const useGetBuildingsByAudit = ({ audit_id }) => {
  const { data, loading, error } = useQuery<buildings_data>(
    GET_BUILDING_BY_AUDIT,
    {
      variables: { audit_id },
      skip: audit_id === -1,
    },
  );

  const buildings: building_data[] = useMemo(() => {
    return data && !loading && !error ? data.qat_building : [];
  }, [data, loading, error]);

  return { data: buildings, loading, error };
};

export const useIsUniqueBuildingAuditIdAddressId = () => {
  const client = useApolloClient();
  const { setNotification } = useContext(AppContext);

  const isUniqueBuildingAuditIdAddressId = useCallback(
    ({
      audit_id,
      address_id,
      building_id,
    }: unique_building_audit_id_address_id_variables): Promise<boolean> => {
      return new Promise<boolean>(resolve => {
        client
          .query<
            unique_building_audit_id_address_id_response,
            unique_building_audit_id_address_id_variables
          >({
            query: UNIQUE_BUILDING_AUDIT_ID_ADDRESS_ID,
            variables: { audit_id, address_id, building_id },
            fetchPolicy: 'network-only',
          })
          .then(response => {
            if (!response.error) {
              resolve(response.data.qat_audit_building.length ? false : true);
            } else {
              setNotification({
                key: 'common:generalError',
                type: NotificationType.ERROR,
              });
              resolve(true);
            }
          }).catch(error => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      });
    },
    [client, setNotification],
  );

  return { isUniqueBuildingAuditIdAddressId };
};

export const useGetBuildingsByAuditIdLength = () => {
  const client = useApolloClient();
  const { setNotification } = useContext(AppContext);

  const getBuildingsByAuditIdLength = useCallback(
    (audit_id: number) =>
      new Promise<number>(resolve => {
        client
          .query<any>({
            query: GET_BUILDINGS_BY_AUDIT_ID_LENGTH,
            variables: { audit_id: audit_id },
            fetchPolicy: 'no-cache',
          })
          .then(response => {
            resolve(response?.data?.qat_building_aggregate?.aggregate?.count);
          })
          .catch(errorResponse => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      }),
    [setNotification, client],
  );

  return { getBuildingsByAuditIdLength };
};

export const useGetBuildingsByAddressId = () => {
  const client = useApolloClient();
  const { setNotification } = useContext(AppContext);

  const getBuildingsByAddressId = useCallback(
    (address_id: number) =>
      new Promise<number>(resolve => {
        client
          .query<any>({
            query: GET_BUILDINGS_BY_ADDRESSID,
            variables: { address_id: address_id },
            fetchPolicy: 'no-cache',
          })
          .then(response => {
            resolve(response?.data?.qat_building_aggregate?.aggregate?.count);
          })
          .catch(errorResponse => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      }),
    [setNotification, client],
  );

  return { getBuildingsByAddressId };
};

