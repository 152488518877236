import { Button, CircularProgress, Dialog, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react'
import { interview_document_result, useGetInterviewDocumentWithContent } from '../../../../services/InterviewDocument/InterviewDocumentRead/InterviewDocumentRead';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
    },
    page: {
        
    },
    buttonToClose: {
        position: "sticky",
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: "white"
    }
}))

export const testIds = {
    document: "document",
    buttonToClose: "button_to_close_document_dialog" 
}

export interface InterviewDocumentDialogProps {
    open: boolean;
    pageNumber: number;
    onCloseDialog: () => void
    url: string;
}   

const InterviewDocumentDialog: React.FC<InterviewDocumentDialogProps> = ({ open,  pageNumber, onCloseDialog, url }) => {
    const { data: interviewDocument, loading: loadingChecklistDocument } = useGetInterviewDocumentWithContent(url)
    const { t } = useTranslation()
    const classes = useStyles()

    const document: interview_document_result | null = useMemo(() => {
        return interviewDocument ? `${interviewDocument}#page=${pageNumber}` : null
    }, [ pageNumber, interviewDocument])

    return (
        <Dialog
            open={open}
            maxWidth="xl"
            onClose={onCloseDialog}
        >
            {loadingChecklistDocument
                ? <CircularProgress />
                : <div 
                    data-testid={testIds.document}
                    className={classes.root}>
                    <iframe
                        title="checklist_document_view"
                        width={1800}
                        height={900}
                        src={document ? document : ""}
                    />
                    <Button data-testid={testIds.buttonToClose} className={classes.buttonToClose} onClick={onCloseDialog}>{t("common:close")}</Button>
                </div>
            }
            
        </Dialog>
    )
}

export default InterviewDocumentDialog