import { gql } from '@apollo/client';

/**
 * Description - graphql query to get all template checklist
 */
export const READ_ALL_TEMPLATE_CHECKLIST = gql`
  query list_template_checklist {
    qat_template_checklist(where: { deleted_date: { _is_null: true } }) {
      id
      name_nl
      name_fr
      category_location {
        id
        name_fr
        name_nl
      }
      sub_category_location {
        id
        name_fr
        name_nl
      }
    }
  }
`;

/**
 * Description - graphql query to get a specific template by id
 */
export const GET_TEMPLATE_BY_PK = gql`
  query qat_template_checklist_by_pk($id: Int!) {
    qat_template_checklist_by_pk(id: $id) {
      id
      name_nl
      name_fr
    }
  }
`;

/**
 * Description - graphql query to get a specific template by category and sub category id
 */
export const GET_TEMPLATE_BY_CATEGORY_ID_AND_SUB_CATEGORY_ID = gql`
  query qat_template_checklist($category_id: Int!, $sub_category_id: Int) {
    qat_template_checklist(
      where: {
        sub_category_location_id: { _eq: $sub_category_id }
        category_location_id: { _eq: $category_id }
      }
      limit: 1
    ) {
      id
      name_nl
      name_fr
      sub_category_location {
        name_nl
        name_fr
        id
      }
      theme_template_checklists(
        order_by: { index: asc }
        where: { deleted_date: { _is_null: true } }
      ) {
        id
        long_name_nl
        short_name_nl
        long_name_fr
        short_name_fr
        not_applicable_option
        duplicable
        duplicate_section_number
        template_theme_items(
          order_by: { index: asc }
          where: {
            _and: [
              { deleted_at: { _is_null: true }},
              { template_sub_theme_id: {_is_null: true}}
            ]
          }
        ) {
          id
          title_nl
          title_fr
          information_nl
          information_fr
          norm_code
          page_pdf_nl
          page_pdf_fr
          not_application_option
          index
          template_theme_item_remarks(
            order_by: { index: asc }
            where: { deleted_at: { _is_null: true } }
          ) {
            id
            remark_text_nl
            remark_text_fr
            index
          }
        }
        sub_theme_template_checklists(
          order_by: { index: asc }
          where: { deleted_date: { _is_null: true } }
        ) {
          id
          name_nl
          name_fr
          index
          not_applicable_option
          template_theme_items(
            order_by: { index: asc }
            where: { deleted_at: { _is_null: true } }
          ) {
            id
            title_nl
            title_fr
            information_nl
            information_fr
            norm_code
            page_pdf_nl
            page_pdf_fr
            not_application_option
            index
            template_theme_item_remarks(
              order_by: { index: asc }
              where: { deleted_at: { _is_null: true } }
            ) {
              id
              remark_text_nl
              remark_text_fr
              index
            }
          }
        }
      }
    }
  }
`;
