import { gql } from '@apollo/client';

/**
 * Description - graphql mutation to create an audit
 */
export const INSERT_AUDIT = gql`
  mutation insert_audit_one($object: qat_audit_insert_input!) {
    insert_qat_audit_one(object: $object) {
      id
    }
  }
`;
