import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { EntityFormData } from '../../../components/Entity/EntityForm/EntityFormPresenter';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { isContraintViolationError } from '../../../graphql/utils';
import { READ_ALL_ENTITIES } from '../ReadService/EntityReadQuery';
import { INSERT_ENTITY } from './EntityWriteMutation';

export const useCreateEntity = () => {
  const { setNotification } = useContext(MainAppContext);

  const [createEntity] = useMutation(INSERT_ENTITY, {
    refetchQueries: [{ query: READ_ALL_ENTITIES }],
    awaitRefetchQueries: true,
    onCompleted({ createdEntity }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      if (isContraintViolationError(error)) {
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      } else {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      }
    },
  });

  const onCreate = async (entityData: EntityFormData) => {
    await createEntity({
      variables: { object: entityData },
    });
  };

  return { onCreate };
};
