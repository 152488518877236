import React, {
    useState,
    useCallback,
    useEffect,
    useMemo
} from "react";
import { useTranslation } from "react-i18next";
import { useReactiveVar } from "@apollo/client";
import ConfirmDialog from "../../Shared/Dialog/ConfirmDialog/ConfirmDialogComponent/ConfirmDialogComponent";
import AddButtonComponent from "../../Shared/Button/AddButtonComponent";
import SearchField from "../../Shared/Field/SearchField/SearchField";
import {
    LinearProgress,
    Typography,
} from "@material-ui/core";
import PageContainer from "../../Shared/Container/PageContainer";
import { useStyles } from "../../Shared/Styles/CommonFormSyles";

import PaginationComponent from "../../Shared/Pagination/PaginationComponent";
import { location_data } from "../../../services/Location/LocationDataModel";
import { locationAddressVar, openCreateLocationAddressVar, openUpdateLocationAddressVar } from "./LocationAdressCache";
import { address_data } from "../../../services/Address/AddressDataModel";
import { useDeleteAddressInLocation } from "../../../services/Address/DeleteService/AddressDeleteService";
import LocationAddressCreateDialogComponent from "./LocationAddressDialogComponent/LocationAddressCreateDialogComponent";
import LocationAddressUpdateDialogComponent from "./LocationAddressDialogComponent/LocationAddressUpdateDialogComponent";
import LocationAddressTableComponent from "./LocationAddressTableComponent";
import { AddressUpdateFormData } from "../../../services/Address/UpdateService/AddressUpdateService";

import { useGetAddressByLocationEntityId } from "../../../services/Address/ReadService/AddressReadService";
import { useBrowserLocationState } from "../../Shared/BrowserLocationState/BrowserLocationState";

const LocationAddressComponent = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const currentLocationState = useBrowserLocationState<{entity: any, locationInfo?: any}>({keysRequired: ["entity"]})

    const currentEntityName:string  = useMemo(
        () => (currentLocationState && currentLocationState.entity 
            ? currentLocationState.entity.label
            : " "), [currentLocationState],
    );  
    const currentLocation:location_data  = useMemo(
        () => (currentLocationState && currentLocationState.locationInfo 
            ? currentLocationState.locationInfo
            : null), [currentLocationState],
    );


    const locationName=useMemo(() => currentLocation?currentLocation.name:"",[currentLocation]);
    const locationId=useMemo(() => currentLocation?currentLocation.id:-1,[currentLocation]);

    const isOpenCreateLocationAddress = useReactiveVar(openCreateLocationAddressVar);
    const isOpenUpdateLocation = useReactiveVar(openUpdateLocationAddressVar);
      

    const { data, loading } = useGetAddressByLocationEntityId(locationId);

    const [locationAddresses, setLocationAddresses] = useState<address_data[]>([]);    
    const [locationAddressLoading, setLocationAddressesLoading] = useState<boolean>(false);    
    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState<boolean>(false);
    const [listLocationAddressFiltered, setListLocationAddressFiltered] = useState<Array<address_data>>(
        locationAddresses
    );
    const [deleteId, setDeleteId] = useState<number>(-1);   

    const {onDeleteInLocation}=useDeleteAddressInLocation (locationId)

    useEffect(
        () => {
            if (data  &&  data.length > 0 ) {
                setLocationAddresses(data);
                setLocationAddressesLoading(loading);
            } else {
                setLocationAddresses([]);
                setLocationAddressesLoading(loading);
            }
        },
        [data, loading],
    );

    const handleCreateLocationAddress = useCallback(
        () => {
            openCreateLocationAddressVar(!isOpenCreateLocationAddress)
        }, [isOpenCreateLocationAddress]);

    const handleDeleteClicked = useCallback(
        (row: address_data) => {
            if (row.id) {
                setOpenDeleteConfirmDialog(true);
                setDeleteId(row.id);
            }
        },
        [],
    )

    const openUpdateDialog = useCallback(
        (row: AddressUpdateFormData) => {
            if (row.id ) {
                openUpdateLocationAddressVar(!isOpenUpdateLocation);
                locationAddressVar(
                    {
                        ...locationAddressVar(),
                        street: row.street,
                        house_number: row.house_number.toString(),
                        box_number: row.box_number,
                        city: row.city,
                        id:row.id,
                        location_entity_id:row.location_entity_id                        
                    }
                );
            }
        },
        [isOpenUpdateLocation],
    )

    const onConfirmedToDelete = useCallback(
        () => {
            if (deleteId !== -1) {
                onDeleteInLocation(deleteId);
                setOpenDeleteConfirmDialog(false);
                setDeleteId(-1);
            } else {
                throw new Error("The location id to delete doesn't exist");
            }
        },
        [deleteId, onDeleteInLocation],
    )

      const handleSearch = useCallback((filter: string) => {
        const newFilter = filter.toLowerCase();
        if (newFilter && newFilter !== "") {
            let filterValue = locationAddresses.filter(address => {
                return (address.street.toLowerCase().includes(newFilter)||                 
                    address.house_number.toLowerCase().includes(newFilter) ||
                        address.city.toLowerCase().includes(newFilter)
                        )
            })
            setListLocationAddressFiltered(filterValue)
        } else {
            setListLocationAddressFiltered(locationAddresses)
        }

    }, [locationAddresses]);

    return (
        <div>
            {
                loading
                    ? (
                        <LinearProgress />
                    )
                    : (
                        <PageContainer>
                            <div
                                className={classes.itemTitle}
                            >
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                >
                                     {t("address:addressTableTitle",
                                         { 0: locationName, 1: currentEntityName })
                                    } 
                                </Typography>
                            </div>
                            <div
                                className={classes.tableHeader}
                            >
                                <AddButtonComponent
                                    label={t("address:createAddress")}
                                    onClick={handleCreateLocationAddress}
                                />
                                <div
                                    className={classes.search}
                                >
                                    <SearchField
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <PaginationComponent
                                items={listLocationAddressFiltered}
                                itemsPropsName="locationAddresses"
                            >
                                <LocationAddressTableComponent
                                    isLoading={locationAddressLoading}
                                    addresses={listLocationAddressFiltered}
                                    handleDeleteClicked={handleDeleteClicked}
                                    openUpdateDialog={openUpdateDialog}
                                />
                            </PaginationComponent>

                            <LocationAddressCreateDialogComponent />
                            <LocationAddressUpdateDialogComponent />

                            <ConfirmDialog
                                open={openDeleteConfirmDialog}
                                onClose={
                                    () => setOpenDeleteConfirmDialog(false)
                                }
                                onConfirm={onConfirmedToDelete}
                                title={t("common:delete")}
                                text={t("common:confirmDelete")}
                            />

                        </PageContainer>
                    )
            }
        </div>
    )
};

export default LocationAddressComponent;