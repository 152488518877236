import React from 'react';
import { get_audit_report_interview_by_audit_id_item_data,checklist_data} 
  from '../../../services/Audit/ReadService/AuditReadModel';
import { useChecklistReportItemPresenter } from '../ChecklistReporItemPresenter/ChecklistReportItemPresenter';
import ChecklistReportItemView from '../ChecklistReportItemView/ChecklistReportItemView';

export interface ChecklistReportItemComponentProps {
  audit_id: number;
  checklist_theme_item: get_audit_report_interview_by_audit_id_item_data;
  index: number;
  checklist: checklist_data;
  isReadOnlyMode?:boolean;
}

const ChecklistReportItemComponent: React.FC<ChecklistReportItemComponentProps> = ({
  audit_id,
  checklist_theme_item,
  index,
  checklist,
  isReadOnlyMode
}) => {
  const {
    onConformChanged,
    onNotConformChanged,
    onChangeComment,
    conform,
    comment,
  } = useChecklistReportItemPresenter(audit_id, checklist_theme_item);

  return (
    <ChecklistReportItemView
      checklist_theme_item={checklist_theme_item}
      onConformChanged={onConformChanged}
      onNotConformChanged={onNotConformChanged}
      onChangeComment={onChangeComment}
      conform={conform}
      comment={comment}
      audit_id={audit_id}
      index={index}
      checklist={checklist}
      isReadOnlyMode={isReadOnlyMode}
    />
  );
};

export default ChecklistReportItemComponent;
