import { useState, useEffect, useMemo } from 'react';
import { checklist_theme_item } from '../../../services/Checklist/ChecklistReadService/CheckistReadModel';
import { useUpdateChecklistItem } from '../../../services/ChecklistItem/UpdateService/ChecklistItemUpdateService';
import { isMobile } from 'react-device-detect';

export interface ChecklistItemViewProps {}

export interface ChecklistItemPresenterProps {}

export const useChecklistItemPresenter = (
  item: checklist_theme_item,
  checklistId,
  isLandscape,
) => {
  const [conform, setConform] = useState(item?.is_conform);
  const [noApplication, setNoApplication] = useState(item?.not_application);
  const [hasInterview, setHasInterview] = useState(item?.has_interview);
  const [showComment, setShowComment] = useState(false);
  const { onUpdate } = useUpdateChecklistItem(checklistId, false);


  useEffect(() => {
    setConform(item?.is_conform);
    setNoApplication(item?.not_application);
    setHasInterview(item?.has_interview);
  }, [item]);

  const handleConformChanged = (event, checklistItemId, isConform) => {
    isConform = isConform === true ? null : true;
    onUpdate(checklistItemId, { is_conform: isConform });
    event.stopPropagation();
    setConform(isConform);
  };

  const handleNotConformChanged = (event, checklistItemId, isConform) => {
    isConform = isConform === false ? null : false;
    onUpdate(checklistItemId, { is_conform: isConform });
    event.stopPropagation();
    setConform(isConform);
  };

  const handleNoApplicationChanged = (event, checklistItemId) => {
    onUpdate(checklistItemId, {
      not_application: !noApplication,
      is_conform: null,
      has_interview: null,
    });
    event.stopPropagation();
    setNoApplication(!noApplication);
  };

  const handleShowCommentChanged = event => {
    event.stopPropagation();
    setShowComment(!showComment);
  };

  const handleHasInterviewChanged = (event, checklistItemId) => {
    onUpdate(checklistItemId, { has_interview: !hasInterview });
    event.stopPropagation();
    setNoApplication(!hasInterview);
  };

  const remarkButtonActive: boolean = useMemo(
    () =>
      (item.checklist_theme_item_remarks !== undefined &&
        item.checklist_theme_item_remarks?.filter(
          ({ checked }) => checked === true,
        ).length > 0) ||
      (item.comment !== undefined && item.comment?.length > 0),
    [item],
  );
  const screen:
    | 'desktop'
    | 'mobile-landscape'
    | 'mobile-portrait' = useMemo(() => {
    return !isMobile
      ? 'desktop'
      : isLandscape
      ? 'mobile-landscape'
      : 'mobile-portrait';
  }, [isLandscape]);

  return {
    conform,
    onConformChanged: handleConformChanged,
    onNotConformChanged: handleNotConformChanged,
    onNoApplicationChanged: handleNoApplicationChanged,
    onHasInterviewChanged: handleHasInterviewChanged,
    onShowCommentChanged: handleShowCommentChanged,
    showComment,
    remarkButtonActive,
    screen
  };
};
