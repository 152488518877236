import { useContext } from 'react';
import { isAllowed } from './Auth';
import MainAppContext from '../../../AppContext';

/**
 * This component is responsible to check if a user role has access to a given component (children),
 * either the component renders or render readonly will be displayed
 * @param rules the rules that need to be authorized
 * @param children the component(s) inside AuthorizationComponent
 * @param readOnlyComponent  the component show in read only mode
 */
const AuthorizationReadOnlyAuditComponent: any = ({
  children,
  rules,
  message,
  email,
  readOnlyComponent,
}) => {
  const { role, email: currentUserEmail } = useContext(MainAppContext);
  return isAllowed(rules, role) &&
    (!email || (email && currentUserEmail === email))
    ? children
    : readOnlyComponent;
};
export default AuthorizationReadOnlyAuditComponent;
