import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  menuListItem: {
    color: 'inherit',
  },
  expand: {
    color: '#949ea3',
  },
  expandMore: {
    color: '#ffffff',
  },
  rootListItem: {
    color: '#949ea3',
    '&:hover': {
      backgroundColor: '#0f181c',
      color: '#ffffff',
    },
    '&$selected': {
      backgroundColor: theme.palette.primary.main,
      color: '#ffffff',
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: '#ffffff',
      },
    },
  },
  selected: {},
}));

export { useStyles };
