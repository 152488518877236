import { gql } from '@apollo/client';

/**
 * Description - graphql query to get current_audit (audit status PRE_AUDIT or AUDIT)
 */
export const READ_CURRENT_AUDITS = gql`
  query read_current_audits {
    qat_audit(
      order_by: { last_updated_date: desc }
      where: {
        _and: [
          { deleted_date: { _is_null: true } }
          { audit_status: { _neq: DONE } }
        ]
      }
    ) {
      id
      auditor_email
      audit_date
      user_id
      entity {
        id
        label
        abbreviation
      }
      location_entity {
        id
        name
        specification
        category_location {
          id
          name_fr
          name_nl
        }
      }
      interview_document {
        audit_id
        id
        url
      }
    }
  }
`;

/**
 * Description - graphql query to get all audits with audit status done
 */
export const READ_ALL_DONE_AUDITS_SORTED = gql`
  query qat_audit($order_by: qat_audit_order_by!) {
    qat_audit(
      order_by: [$order_by]
      where: { deleted_date: { _is_null: true }, audit_status: { _eq: DONE } }
    ) {
      id
      auditor_email
      audit_date
      user_id
      entity {
        id
        label
        abbreviation
      }
      location_entity {
        id
        name
          specification
        category_location {
          id
          name_fr
          name_nl
        }
      }
    }
  }
`;

/**
 * Description - graphql query to get a specific audit
 */
export const GET_AUDIT_BY_PK = gql`
  query qat_audit_by_pk($id: Int!) {
    qat_audit_by_pk(id: $id) {
      id
      auditor_email
      audit_date
      entity {
        id
        label
        abbreviation
      }
      location_entity {
        id
        name
        category_id
        category_location {
          id
          name_nl
          name_fr
          category_location_form_config {
            category_location_form_field_configs {
              id
              name_nl
              name_fr
              is_required
              form_field_type
              category_location_form_config_id
            }
          }
          sub_category_locations {
            id
            name_nl
            name_fr
          }
        }
        addresses {
          id
          street
          house_number
          box_number
          city
        }
      }
      audit_buildings {
        building {
          id
          address {
            id
            street
            house_number
            box_number
            city
          }
          building_field_values {
            category_location_form_field_config {
              id
              name_nl
              name_fr
              form_field_type
              is_required
            }
            category_location_form_field_config_id
            value
          }
          sub_category_location {
            id
            name_nl
            name_fr
          }
        }
      }
      interview_document {
        audit_id
        id
        url
      }
    }
  }
`;

export const CHECK_IF_UNIQUE_AUDIT = gql`
  query check_if_unique_audit(
    $entity_id: Int!
    $location_id: Int!
    $audit_date: date!
    $user_id: String!
    $id: Int
  ) {
    qat_audit(
      where: {
        _and: [
          { entity_id: { _eq: $entity_id } }
          { location_id: { _eq: $location_id } }
          { audit_date: { _eq: $audit_date } }
          { user_id: { _eq: $user_id } }
          { deleted_date: { _is_null: true } }
          { id: { _neq: $id } }
        ]
      }
    ) {
      id
    }
  }
`;

/**
 * Description - graphql query to get a specific audit with interview report details
 */
export const GET_AUDIT_REPORT_INTERVIEW_BY_AUDIT_ID = gql`
  query get_audit_report_interview_by_audit_id($id: Int!) {
    qat_audit_by_pk(id: $id) {
      id
      audit_date
      entity {
        id
        label
        abbreviation
      }
      audit_buildings(
        where: {
          building: {
            checklist: {
              checklist_themes: {
                checklist_theme_sections: {
                  checklist_theme_items: { has_interview: { _eq: true } }
                }
              }
              _and: { building: { deleted_date: { _is_null: true } } }
            }
          }
        }
        order_by: { id: asc }
      ) {
        id
        building {
          address {
            street
            house_number
          }
          checklist {
            id
            checklist_themes(
              where: {
                _or: [
                  {
                    checklist_theme_sections: {
                      checklist_theme_items: { has_interview: { _eq: true } }
                    }
                  }
                  {
                    checklist_theme_sections: {
                      checklist_theme_sub_sections: {
                        checklist_theme_items: { has_interview: { _eq: true } }
                      }
                    }
                  }
                ]
              }
              order_by: { id: asc }
            ) {
              id
              long_name_nl
              long_name_fr
              checklist_theme_sections(
                where: {
                  _or: [
                    { checklist_theme_items: { has_interview: { _eq: true } } }
                    {
                      checklist_theme_sub_sections: {
                        checklist_theme_items: { has_interview: { _eq: true } }
                      }
                    }
                  ]
                }
                order_by: { id: asc }
              ) {
                id
                specification
                checklist_theme_items(
                  where: { has_interview: { _eq: true } }
                  order_by: { id: asc }
                ) {
                  id
                  is_conform
                  name_fr
                  name_nl
                  template_theme_item {
                    norm_code
                    page_pdf_fr
                    page_pdf_nl
                  }
                  checklist_theme_item_remarks(order_by: { id: asc }) {
                    id
                    name_fr
                    name_nl
                    checked
                  }
                  comment
                }
                checklist_theme_sub_sections {
                  checklist_theme_items(
                    where: { has_interview: { _eq: true } }
                    order_by: { id: asc }
                  ) {
                    id
                    is_conform
                    name_fr
                    name_nl
                    template_theme_item {
                      norm_code
                    }
                    checklist_theme_item_remarks(order_by: { id: asc }) {
                      id
                      name_fr
                      name_nl
                      checked
                    }
                    comment
                  }
                }
              }
            }
          }
        }
      }
      location_entity {
        id
        name
      }
    }
  }
`;
