import { gql } from '@apollo/client';
/**
 * Description - graphql query to create an item
 */
export const INSERT_ITEM = gql`
  mutation insert_qat_template_theme_item_one(
    $object: qat_template_theme_item_insert_input!
  ) {
    insert_qat_template_theme_item_one(
      object: $object
    ) {
      id
      creation_date
      information_fr
      information_nl
      norm_code
      not_application_option
      page_pdf_fr
      page_pdf_nl
      template_sub_theme_id
      template_theme_id
      title_fr
      title_nl
      index
    }
  }
`;

export interface reactivate_template_theme_item_variables {
  id: number;
  object: {
    creation_date?: Date;
    information_fr: string;
    information_nl: string;
    last_updated_date?: Date;
    deleted_at?: string | Date | null;
    norm_code: string;
    not_application_option: boolean;
    page_pdf_fr: number;
    page_pdf_nl: number;
    template_sub_theme_id?: number;
    template_theme_id?: number;
    title_fr: string;
    title_nl: string;
    index: number;
  }
}

export interface reactivate_template_theme_item_response {
  update_qat_template_theme_item_by_pk: {
    id: number;
  }
}

export const REACTIVATE_ITEM = gql`
mutation reactivate_template_theme_item (
  $id: Int!
  $object: qat_template_theme_item_set_input
){
  update_qat_template_theme_item_by_pk(pk_columns:{id: $id }, _set: $object){
    id
  }
}
`
