import { CircularProgress } from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react'
import AppContext from '../../AppContext';
import { useGetAllAdminDocument, useGetContent } from '../../services/AdminDocument/AdminDocumentRead/AdminDocumentRead';
import { useDocumentDialogService } from '../AdminDocument/AdminDocumentRead/AdminDocumentService';
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';


type norm_document_result = string

export const testIds = {
  document: "document",
  buttonToClose: "button_to_close_document_dialog"
}

/**
 * This component is responsible for displaying the normes
 */
const NormComponent = () => {

  const { pageNumber, onSelectPage } = useDocumentDialogService()
  const { language } = useContext(AppContext)
  const { data } = useGetAllAdminDocument()
  const NAME_FILE = language.substring(0, 2) === 'fr' ? 'norm_pdf_fr' : 'norm_pdf_nl'

  const documentUrl: string | null = useMemo(() => {
    if (data) {
      const result = data.find(({ name_file }) => name_file === NAME_FILE)
      if (result && result.url && result.url !== "") {
        return result.url
      } else {
        return null
      }
    } else {
      return null
    }
  }, [data, NAME_FILE])

  const [dataWithDocumentContent, setDataWithDocumentContent] = useState<norm_document_result>("")
  const { getContent } = useGetContent()
  const [loadingContent, setLoadingContent] = useState<boolean>(true)

  const document: norm_document_result | null = useMemo(() => {
    return dataWithDocumentContent ? `${dataWithDocumentContent}#page=${pageNumber}` : null
  }, [pageNumber, dataWithDocumentContent])

  useEffect(() => {
    onSelectPage(1)
  }, [onSelectPage]);

  useEffect(() => {
    if (documentUrl) {
      getContent(documentUrl).then(content => {
        setDataWithDocumentContent((currentData: norm_document_result) => {
          if (content) {
            currentData = content
            setLoadingContent(false)
          } else {
            setLoadingContent(false)
          }
          return currentData
        })
      }).catch(error => {
        setLoadingContent(false)
        console.error(error)
      })
    } else {
      setDataWithDocumentContent("")
    }

  }, [language, documentUrl, getContent]);

  return (
    <>
      {loadingContent
        ? <CircularProgress />
        :
        <div>
          <Worker workerUrl={"https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js"}>
            <div style={{ height: '750px' }}>
              <Viewer fileUrl={document ? document : ""} />
            </div>
          </Worker>

        </div>


      }

    </>
  )
}

export default NormComponent;
