export const item = {
  item: 'Item',
  normeCode: 'Norme code',
  notApplicable: "Option pas d'application",
  pagePdf: 'Page du Pdf',
  information: 'Information',
  title: 'Titre',
  listOfItems: 'Liste des items',
  addItem: 'Ajouter un Item',
  createItem: 'Ajouter un item',
  updateItem: 'Editer un item',
  theme_name: 'Thème',
  title_fr: 'Intitulé français',
  title_nl: 'Intitulé néerlandais',
  norm_code: 'Code de la norme',
  page_pdf_fr: 'Page de la norme dans le pdf français',
  page_pdf_nl: 'Page de la norme dans le pdf néerlandais',
  information_fr: 'Informations en français',
  information_nl: 'Informations en néerlandais',
  not_application_option:
    "Ajouter un bouton pour indiquer que l'item peut ne pas être d'application",
  titleFrError: 'Le titre en français est un champ obligatoire',
  titleNlError: 'Le titre en néerlandais est un champ obligatoire',
  normCodeError: 'Le code norme est un champ obligatoire',
  pagePdfFrError: 'Le numéro de la page pdf français est un champ obligatoire',
  pagePdfNlError:
    'Le numéro de la page pdf néerlandais est un champ obligatoire',
  itemTableTitle: 'Liste des Items du thème "{{0}}" de la CheckList "{{1}}"',
  itemTableTitleWithSubtheme:
    'Liste des Items du sous-thème " {{0}}" du thème "{{1}}" de la CheckList "{{2}}"',
  validateAdd: 'Ajouter un autre item',
  remark: 'Remarque',
  titleFrUniqueError: "Il existe déjà un item avec ce titre en français",
  titleNlUniqueError: "Il existe déjà un item avec ce titre en néérlandais",
  informationFrUniqueError: "Il existe déjà un item avec cette information en français",
  informationNlUniqueError: "Il existe déjà un item avec cette information en néérlandais",
 };
