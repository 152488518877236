export const building = {
  createBuilding: 'Gebouw toevoegen',
  address: 'Adres',
  housing: 'Gedeelde / individuele woning',
  housingError: 'Gedeelde / individuele woning is een verplicht veld',
  addressError: 'Adres is een verplicht veld',
  formFieldError: '{{0}} is een verplicht veld',
  centre: 'Centrum',
  updateBuilding: 'Gebouw wijzigen',
  buildingValidationError: 'Het gebouw dat u heeft opgegeven bestaat reeds',
  buildingConfirmSubCategoryChangeTitle: 'Wijzig de subcategorie',
  buildingConfirmSubCategoryChangeText:
    'Als u de subcategorie wijzigt, blijven alleen de algemene opmerkingen behouden!',
  validateAdd: 'Een ander gebouw toevoegen',
  uniqueBuildingErrorMessage: 'Er is al een gebouw met ditzelfde adres',
};
