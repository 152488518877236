import gql from "graphql-tag";

export interface add_audit_photo_variables {
    id: number;
    url: string;
    audit_metadata_id:number;
}



export const INSERT_AUDIT_PHOTO = gql`
    mutation insert_qat_audit_photo_one(
       $object:qat_audit_photo_insert_input!
    ){
        insert_qat_audit_photo_one(object: $object){
            id
        }

    }
`
export const INSERT_AUDIT_PHOTO_METADATA = gql`
    mutation insert_qat_audit_photo_metadata_one(
       $object:qat_audit_photo_metadata_insert_input!
    ){
        insert_qat_audit_photo_metadata_one(object: $object){
            id
        }

    }
`