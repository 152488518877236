
  export  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays:any[] = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob:Blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    export const  blobToFile = (theBlob: Blob, fileName: string): File => {
       var b: any = theBlob;
      //A Blob() is almost a File() - it's just missing the two properties below which we will add
       // b.lastModifiedDate = new Date();
        b.name = fileName;

      //Cast to a File() type
      return theBlob as File;
}