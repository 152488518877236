export const item_remark = {
  remark: 'Opmerking',
  remarkPredefined: 'Vooraf gedefinieerde opmerkingen',
  createItem: 'Opmerking toevoegen',
  updateItem: 'Opmerking bewerken',
  addItemRemark: 'Een opmerking toevoegen',
  RemarktextFr: 'Franse opmerking',
  RemarktextNl: 'Nederlandse opmerking',
  remarkTextNlError: 'Nederlandse opmerking is een verplicht veld',
  remarkTextFrError: 'Franse opmerking is een verplicht veld',
  itemRemarkTitle:
    'Opmerking voor item "{{0}}" van het thema "{{1}}" van de CheckList "{{2}}"',
  itemName: 'Itemnaam',
  validateAdd: 'Een nieuwe opmerking toevoegen',
  remarkTextFrUniqueError: "Er is al een item met deze Franse tekst",
  remarkTextNlUniqueError: "Er is al een item met deze Nederlandse tekst",
  comment: 'Vrije  opmerking',
};
