import React from 'react';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { useStyles } from '.';

/**
 * Description - Icon to show true value in table
 */
const TableTrueValueComponent = () => {
  const classes = useStyles();

  return <CheckRoundedIcon className={classes.root} />;
};

export default TableTrueValueComponent;
