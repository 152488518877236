import React, { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import ItemFormDialog from './ItemFormDialogComponent';
import { openUpdateItemVar, itemVar } from '../ItemCache';
import { ItemFormData } from '../../../services/Item/ItemFormData';
import { useUpdateItemFromThemeOrSubTheme } from '../../../services/Item/UpdateService/ItemUpdateService';
import { ItemDisplayData } from '../ItemDisplayData';
import { useBrowserLocationState } from '../../Shared/BrowserLocationState/BrowserLocationState';

export interface tableProps {
  openUpdateForm: boolean;
}

const ItemUpdateDialogComponent = () => {

  const isOpenUpdateForm: boolean = useReactiveVar(openUpdateItemVar);
  const updateItem: ItemDisplayData = useReactiveVar(itemVar);

  const currentLocationState = useBrowserLocationState<{theme: any, subTheme?: any}>({keysRequired: ["theme"]})

  const { onUpdateItemFromThemeOrSubTheme } = useUpdateItemFromThemeOrSubTheme(
    currentLocationState?.subTheme
      ? currentLocationState?.subTheme?.id
      : currentLocationState?.theme?.id,
    currentLocationState?.subTheme ? false : true,
  );

  const defaultItem: ItemDisplayData = {
    id: -1,
    information_fr: '',
    information_nl: '',
    norm_code: '',
    page_pdf_fr: '',
    page_pdf_nl: '',
    not_application_option: false,
    title_fr: '',
    title_nl: '',
    template_theme_id: -1,
    template_sub_theme_id: -1,
    index: -1,
  };

  const handleUpdateItem = () => {
    let itemForm: ItemFormData;
    itemForm = {
      title_fr: updateItem.title_fr,
      title_nl: updateItem.title_nl,
      norm_code: updateItem.norm_code,
      page_pdf_fr: parseInt(updateItem.page_pdf_fr),
      page_pdf_nl: parseInt(updateItem.page_pdf_nl),
      information_fr: updateItem.information_fr,
      information_nl: updateItem.information_nl,
      not_application_option: updateItem.not_application_option,
      index: updateItem.index,
    };
    if (updateItem.id) {
      onUpdateItemFromThemeOrSubTheme(updateItem.id, itemForm);
      openUpdateItemVar(false);
      itemVar(defaultItem);
    }
  };

  const handleClose = useCallback(() => {
    openUpdateItemVar(false);
    itemVar(defaultItem);
  }, [defaultItem]);

  return (
    <ItemFormDialog
      isOpenForm={isOpenUpdateForm}
      handleClose={handleClose}
      handleFormSubmit={handleUpdateItem}
      templateThemeId={updateItem.template_theme_id}
      templateSubThemeId={updateItem.template_sub_theme_id}
      id={updateItem.id}
    />
  );
};

export default ItemUpdateDialogComponent;
