import { Grid, TextField } from '@material-ui/core';
import React from 'react';


const CategoryLocationListComponent = (
    { 
        name,
        label,       
        defaultValue,
        children,
        handleOnChange,
        value,
        error,
        dataTestid,
    helperText        
        
     }
    
    ) =>
 {       

   return (
    <Grid >
                   <TextField
                          name={name}
                       variant="outlined"
                       fullWidth
                       select
                       defaultValue={defaultValue}
                       label={label}
                       value={value}
                       onChange={(e) => handleOnChange(e)}
                       margin="normal"
               error={error}
               helperText={helperText}
               data-testid={dataTestid}
                   >
                    {children}
                   </TextField>
                     
  
     </Grid >)
    
};

export default CategoryLocationListComponent;
