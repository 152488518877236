import { Button, Grid, TextField } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './EntityFormStyle';
import { Controller } from 'react-hook-form';
import { EntityFormViewProps } from '../EntityFormComponent';
import FormContainer from '../../../Shared/Container/FormContainer';

export const testIds = {
  labelInput: 'entity-name-input',
  abbreviationInput: 'entity-abbreviation-input',
  categoryIdInput: 'entity-category-id-input',
  buttonValidate: 'entity-button-validation',
};

/**
 * Description - Form to create entity
 */
const EntityFormView: React.FC<EntityFormViewProps> = ({
  errors,
  onSubmit,
  control,
  title,
  submitButtonLabel,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormContainer>
      <form onSubmit={onSubmit}>
        <h1>{t(title)}</h1>
        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={3}
        >
          <Grid item className={classes.control}>
            <Controller
              control={control}
              name="label"
              as={
                <TextField
                  id="label"
                  data-testid={testIds.labelInput}
                  name="label"
                  label={t('entity:name')}
                  helperText={errors.label && t('entity:nameError')}
                  variant="outlined"
                  fullWidth
                  error={errors.label !== undefined || errors.label === null}
                />
              }
            ></Controller>
          </Grid>
          <Grid item className={classes.control}>
            <Controller
              control={control}
              name="abbreviation"
              as={
                <TextField
                  id="abbreviation"
                  data-testid={testIds.abbreviationInput}
                  name="abbreviation"
                  label={t('entity:abbreviation')}
                  helperText={
                    errors.abbreviation && t('entity:abbreviationError')
                  }
                  variant="outlined"
                  fullWidth
                  error={
                    errors.abbreviation !== undefined ||
                    errors.abbreviation === null
                  }
                />
              }
            ></Controller>
          </Grid>
          <Grid item className={classes.control}>
            <Button
              type="submit"
              id="validate"
              data-testid={testIds.buttonValidate}
              variant="contained"
              color="primary"
              fullWidth
            >
              {t(submitButtonLabel)}
            </Button>
          </Grid>
        </Grid>
      </form>
    </FormContainer>
  );
};

export default EntityFormView;
