
import { gql } from '@apollo/client';


export const READ_NOT_APPLICATION_ITEMS_THEME_SUB_THEME_WITH_OVERVIEW_DETAILS = gql`
query qat_checklist_theme_item(
  $audit_id: Int!
){
   qat_checklist_theme_item(where: {not_application: {_eq: true}, checklist_theme_section: {checklist_theme: {not_applicable: {_eq: false},
    checklist: {audit_id: {_eq: $audit_id}}}}}, order_by: {norm_code: asc}) {
     id
    name_nl
    name_fr
    comment
    norm_code
    not_application
    page_pdf_fr
    page_pdf_nl
    
    checklist_theme_section {
      specification
      checklist_theme {
        long_name_fr
        long_name_nl
        checklist {
          building {
            id
            index
            address {
              box_number
              city
              house_number
              street
            }
          }
            audit {
           id
           audit_date
            entity {
              abbreviation
            }
            location_entity {
              name
            }            
          }
        }
      }
    }  

  }
 sub_theme_items: qat_checklist_theme_sub_section(where: {checklist_theme_items: {not_application: {_eq: true}}, checklist_theme_section: {checklist_theme: {checklist: {audit_id: {_eq: $audit_id}}}}}) {
    checklist_theme_items(where: {not_application: {_eq: true}}) {
      id
      name_nl
      name_fr
      comment
      norm_code
      not_application
      page_pdf_fr
      page_pdf_nl
      checklist_theme_sub_section {
        checklist_theme_section {
          specification
          checklist_theme {
              long_name_fr
          long_name_nl
            checklist {
             building {
             id
             index
              address {
              box_number
              city
              house_number
              street
             }
            }
            audit {
              id
              audit_date
              entity {
                abbreviation
              }
              location_entity {
                name
              }
             }
            }
          }
        }
      }
    }
  }

  qat_checklist_theme_section(where: {checklist_theme: {not_applicable: {_eq: true}, checklist: {audit_id: {_eq: $audit_id }}}}) {
    checklist_theme {
      checklist {
        building {
           id
             index
          address {
           box_number
              city
              street
              house_number
          }
        }
         audit {
           id
           audit_date
            entity {
              abbreviation
            }
            location_entity {
              name
            }            
          }
      }
       short_name_nl
      short_name_fr
    }
  }


  qat_checklist_theme_sub_section(where: {no_application: {_eq: true}, 
  checklist_theme_section: {checklist_theme: {checklist: {audit_id: {_eq: $audit_id}, 
  checklist_theme: {not_applicable: {_eq: false}}}}}}) {
 
   checklist_theme_section {
      checklist_theme {
        checklist {
          building {
             id
               index
            address {
              box_number
              city
              street
              house_number
            }
          }
          audit{
            id
            audit_date
            entity{
              abbreviation
            }
            location_entity{
              name
            }
          }
        }
      }
    }
    name_fr
    name_nl   
  }
}
`;