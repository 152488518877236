import React from 'react';
import { checklist_theme_item } from '../../../services/Checklist/ChecklistReadService/CheckistReadModel';
import { useChecklistItemPresenter } from '../ChecklistItemPresenter/ChecklistItemPresenter';
import ChecklistItemView from '../ChecklistItemView/ChecklistItemView';
import { withOrientationChange } from 'react-device-detect';
export interface ChecklistItemComponentProps {
  item: checklist_theme_item;
  checklistId: number;
  isLandscape?: boolean;
  isReadOnlyMode: boolean;
}

const ChecklistItemComponent: React.FC<ChecklistItemComponentProps> = ({
  item,
  checklistId,
  isLandscape,
  isReadOnlyMode,
}) => {
  const {
    conform,
    onConformChanged,
    onNotConformChanged,
    onNoApplicationChanged,
    onHasInterviewChanged,
    onShowCommentChanged,
    showComment,
    remarkButtonActive,
    screen
  } = useChecklistItemPresenter(item, checklistId, isLandscape);

  return (
    <ChecklistItemView
      item={item}
      conform={conform}
      onConformChanged={onConformChanged}
      onNotConformChanged={onNotConformChanged}
      onNoApplicationChanged={onNoApplicationChanged}
      onHasInterviewChanged={onHasInterviewChanged}
      onShowCommentChanged={onShowCommentChanged}
      showComment={showComment}
      checklistId={checklistId}
      remarkButtonActive={remarkButtonActive}
      screen={screen}
      isReadOnlyMode={isReadOnlyMode}
    />
  );
};

export default withOrientationChange(ChecklistItemComponent);
