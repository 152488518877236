import { useEffect, useState } from "react";
import { useHistory } from 'react-router-dom';

import routes from "../../../routes";
import { useReportAuditPhotoMetadata } from "../../../services/AuditPhotoOverview/AuditPhotoOverviewService";
import { useGetPhotoContentDataFromApi } from "../../../services/AdminDocument/AdminDocumentRead/AdminDocumentRead";



export const useChecklistReportPhotoPresenter = (audit_id) => {
  const history = useHistory();
  useEffect(() => {
    if (!audit_id)
      history.push({ pathname: routes.currentAudits.path })

  }, [audit_id, history])
  const { data, loading } = useReportAuditPhotoMetadata(audit_id.audit_id)
  const { getContentByUrl } = useGetPhotoContentDataFromApi()
  const [content, setContent] = useState<{ [key: string]: string | null }>({})
     
  useEffect(() => {
    if (data && !loading) {
      const urls: string[] = []
      data[0]?.checklists?.forEach(checklist => {
        checklist?.checklist_themes?.forEach(theme => {
          theme?.checklist_theme_sections?.forEach(
            section => section?.audit_photo_metadata[0]?.audit_photos?.forEach(auditPhoto =>
              urls.push(auditPhoto.url))
          )
        })
      })
      urls?.forEach(url => {
        getContentByUrl(url).then(resuContent =>
          setContent((currentData) => { currentData[url] = resuContent; return { ...currentData } }))
      })
    }
    // eslint-disable-next-line
  }, [audit_id, data, loading, getContentByUrl])







  return { loading, data, content };
};





