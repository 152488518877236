import React from 'react';

export interface NetworkListenerContextType {
  onChangeIsOffline: (value: boolean) => void,
  isOffline: boolean
}

/**
 * Description - Network Listener Context to pass function to ifform is offline
 */
const NetworkListenerContext = React.createContext<NetworkListenerContextType>({
  onChangeIsOffline: (value) => {},
  isOffline: false
});

export const Provider = NetworkListenerContext.Provider;

export const Consumer = NetworkListenerContext.Consumer;

export default NetworkListenerContext;