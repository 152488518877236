import { useCallback, useState } from "react"
import { useHistory } from 'react-router-dom';
import routes from "../../../../routes";
import { useGetAuditById } from "../../../../services/Audit/ReadService/AuditReadService";
import { useAddInterviewDocument, useUpdateInterviewDocument } from "../../../../services/InterviewDocument/InterviewDocumentWrite/InterviewDocumentWrite";

export type InterviewDocumentUploadFormInputs = {
  file: File | null
}

export type InterviewDocumentUploadFormKeys = "file"

export type InterviewDocumentUploadErrorsValidation = {
  file?: "required" | "type-pdf"
} | undefined

export const validateInterviewDocumentUpload = (formInputs: InterviewDocumentUploadFormInputs): InterviewDocumentUploadErrorsValidation | void => {
  const errors: InterviewDocumentUploadErrorsValidation = {}

  const { file } = formInputs
  if (file === null) {
    errors.file = "required"
  } else if (file.type !== "application/pdf") {
    errors.file = "type-pdf"
  }

  if (Object.keys(errors).length) {
    return errors
  }
}

export const useInterviewDocumentUploadService = (audit, isReadonly, isNewDoc) => {
  const history = useHistory();
  const [inputValues, setInputValues] = useState<InterviewDocumentUploadFormInputs>({ file: null })
  const [errors, setErrors] = useState<InterviewDocumentUploadErrorsValidation>()
  const { onUpdateInterviewDocument } = useUpdateInterviewDocument(audit)
  const { onAddInterviewDocument } = useAddInterviewDocument(audit)
  const auditId = audit?.id
  const { getAuditById } = useGetAuditById();

  const resetErrors = useCallback((key: InterviewDocumentUploadFormKeys) => {
    setErrors(current => {
      return { ...current, [key]: undefined }
    })
  }, [])

  const handleChange = (key: InterviewDocumentUploadFormKeys) => (event) => {
    if (key === "file"){
      setInputValues(current => ({ ...current, [key]: event.target.files[0] }))
    }else{
      console.error(`${key} doesn't exist in form input model`)
    }
    resetErrors(key)
  }

  const handleSubmit = useCallback((event) => {
    event.preventDefault()
    const submitErrorsValidation = validateInterviewDocumentUpload(inputValues)
    if (submitErrorsValidation) {
      setErrors(submitErrorsValidation)
    } else {
      const { file } = inputValues
      if (auditId && file) {
        if (isNewDoc) {
          onAddInterviewDocument(auditId, file, file.name).then(response => {
            getAuditById(auditId).then(auditVar => {
              history.push({
                pathname: `${routes.editAudit.path}${routes.editAuditMainInfo.path}`,
                state: { audit: auditVar, isReadonly: isReadonly },
              });
            });
          });
        } else {
          onUpdateInterviewDocument(auditId, file, file.name, audit).then(response => {
            getAuditById(auditId).then(auditVar => {
              history.push({
                pathname: `${routes.editAudit.path}${routes.editAuditMainInfo.path}`,
                state: { audit: auditVar, isReadonly: isReadonly },
              });
            });
          });
        }
      }
    }
  }, [onUpdateInterviewDocument, inputValues, auditId, history, isReadonly, isNewDoc, onAddInterviewDocument, getAuditById, audit])

  return { values: inputValues, errors, onChange: handleChange, onSubmit: handleSubmit }
}
