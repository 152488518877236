import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  theme => ({
    checkbox: {
      '&$checked': {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    checked: {},
    textarea: {
      width: '99%',
      lineHeight: 1.3,
    },
    container: {
      margin: theme.spacing(1.5),
    },
    flexInline: {
      display: 'flex',
      verticalalign: 'middle',
    },
  }),
  {
    index: 1,
  },
);

export { useStyles };
