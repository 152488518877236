import { gql } from '@apollo/client';

export const UPSERT_USER = gql`
mutation upsert_user(
  $object: qat_user_insert_input!
  ) {
    insert_qat_user_one(
      object: $object,
      on_conflict: {constraint: user_pkey, update_columns: [x_hasura_user_id]}){
      id
    }
  }
`