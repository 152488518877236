import { IconButton, Grid, Typography, Box } from '@material-ui/core';
import React, { useContext } from 'react';
import AppContext from '../../../AppContext';
import {
  translate,
  translateformPagePDF,
  translateInformation,
} from '../../../services/Translation/TranslationService';
import { checklist_theme_item } from '../../../services/Checklist/ChecklistReadService/CheckistReadModel';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import EditIcon from '@material-ui/icons/Edit';
import CommentIcon from '@material-ui/icons/Comment';
import { useStyles } from './ChecklistItemStyle';
import ChecklistItemRemarkComponent from '../../ChecklistItemRemark/ChecklistItemRemarkComponent';
import clsx from 'clsx';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CancelIcon from '@material-ui/icons/Cancel';
import { isMobile } from 'react-device-detect';
import TableHelpIconWithToolTip from '../../Shared/Table/TableHelpIconWithToolTip.component';
import NormDocumentServiceContext from '../../AdminDocument/AdminDocumentRead/AdminDocumentServiceContext';

export const testIds = {
  buttonToDuplicate: 'checklist-theme-section-button-to-duplicate',
};

export interface ChecklistItemViewProps {
  item: checklist_theme_item;
  conform?: boolean;
  onConformChanged: (event, checklistItemId, isConform) => void;
  onNotConformChanged: (event, checklistItemId, isConform) => void;
  onNoApplicationChanged: (event, checklistItemId) => void;
  onHasInterviewChanged: (event, checklistItemId) => void;
  onShowCommentChanged: (event) => void;
  showComment: boolean;
  checklistId: number;
  remarkButtonActive: boolean;
  screen: 'desktop' | 'mobile-landscape' | 'mobile-portrait';
  isReadOnlyMode: boolean
}

const ChecklistItemView: React.FC<ChecklistItemViewProps> = ({
  item,
  conform,
  onConformChanged,
  onNotConformChanged,
  onNoApplicationChanged,
  onHasInterviewChanged,
  onShowCommentChanged,
  showComment,
  checklistId,
  remarkButtonActive,
  screen,
  isReadOnlyMode
}) => {
  const { language } = useContext(AppContext);
  const classes = useStyles();
  const readOnlyModeclick = e => e.preventDefault();
  const { onSelectPage } = useContext(NormDocumentServiceContext)

  return (
    <>

      <Grid
        container
        className={
          (item?.not_application === true && classes.rootShadow) || classes.root
        }
        alignItems="center"
      >
        <Grid lg={9} xs={7} item style={{display: "flex"}}>
          <Typography variant="subtitle2" className={classes.title}>
            {translate(item, language)}
            
          <span
            className={classes.normCode}
            onClick={() => { onSelectPage(translateformPagePDF(item, language)) }}
          >
            {item.template_theme_item?.norm_code}
          </span>

          </Typography>
          {translateInformation(item.template_theme_item, language) && <TableHelpIconWithToolTip
                helpInfo={translateInformation(
                  item.template_theme_item,
                  language,
                )}
              />}
        </Grid>
        <Grid item lg={3} xs={5}>
          <Grid container>
            <>
              <Box ml={isMobile ? 5 : 0}>
                <IconButton
                  className={
                    item.not_application === true
                      ? classes.hiddenIcon
                      : classes.button
                  }
                  onClick={e =>
                    isReadOnlyMode
                      ? readOnlyModeclick
                      : onConformChanged(e, item.id, item.is_conform)
                  }
                  aria-label="button-conform-item"
                  disabled={isReadOnlyMode}
                >
                  <CheckBoxIcon
                    className={
                      (conform === true && classes.conform) || classes.default
                    }
                  />
                </IconButton>

                <IconButton
                  className={
                    item.not_application === true
                      ? classes.hiddenIcon
                      : classes.button
                  }
                  onClick={e =>
                    isReadOnlyMode
                      ? readOnlyModeclick
                      : onNotConformChanged(e, item.id, item.is_conform)
                  }
                  aria-label="button-not-conform-item"
                  disabled={isReadOnlyMode}
                >
                  <CancelIcon
                    className={
                      (conform === false && classes.notConform) ||
                      classes.default
                    }
                  />
                </IconButton>

                <IconButton
                  className={clsx(
                    item.not_application === true
                      ? classes.hiddenIcon
                      : classes.button,
                    {
                      [classes.active]: remarkButtonActive,
                    },
                  )}
                  onClick={e => onShowCommentChanged(e)}
                  aria-label="button-edit-item"
                >
                  <EditIcon />
                </IconButton>

                <IconButton
                  className={
                    item.not_application === true
                      ? classes.hiddenIcon
                      : classes.button
                  }
                  onClick={e =>
                    isReadOnlyMode
                      ? readOnlyModeclick
                      : onHasInterviewChanged(e, item?.id)
                  }
                  aria-label="button-comment-item"
                  disabled={isReadOnlyMode}
                >
                  <CommentIcon
                    className={
                      (item.has_interview === true && classes.hasInterview) ||
                      classes.default
                    }
                  />
                </IconButton>
              </Box>
            </>

            {item.template_theme_item?.not_application_option && (
              <>
                <Box ml={screen === 'mobile-portrait' ? 23 : 5}>
                  <IconButton
                    className={classes.noApplicationButton}
                    onClick={e =>
                      isReadOnlyMode
                        ? readOnlyModeclick
                        : onNoApplicationChanged(e, item.id)
                    }
                    aria-label="button-no-application-item"
                    disabled={isReadOnlyMode}
                  >
                    <CancelPresentationIcon
                      className={
                        (item.not_application === true &&
                          classes.noApplication) ||
                        classes.blackDefault
                      }
                    />
                  </IconButton>
                </Box>
              </>
            )}
          </Grid>
        </Grid>
        {showComment && item.checklist_theme_item_remarks && (
          <ChecklistItemRemarkComponent
            item={item}
            checklistId={checklistId}
            remarks={item.checklist_theme_item_remarks}
            isReadOnlyMode={isReadOnlyMode}
          />
        )}
      </Grid>
    </>
  );
};

export default ChecklistItemView;
