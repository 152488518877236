import { gql } from '@apollo/client';

/**
 * Description - graphql query to get all items
 */
export const READ_ALL_ITEMS = gql`
  query qat_template_theme_item {
    qat_template_theme_item(
      order_by: { last_updated_date: desc }
      where: { deleted_at: { _is_null: true } }
    ) {
      id
      information_fr
      information_nl
      creation_date
      last_updated_date
      deleted_at
      norm_code
      not_application_option
      page_pdf_nl
      page_pdf_fr
      template_sub_theme_id
      template_theme_id
      title_fr
      title_nl
      index
      template_theme_item_remarks {
        id
        remark_text_fr
        remark_text_nl
      }
    }
  }
`;

/**
 * Description - graphql query to get all sorted items
 */
export const READ_ALL_ITEMS_SORTED = gql`
  query qat_template_theme_item($order_by: qat_item_order_by!) {
    qat_template_theme_item(
      where: { deleted_at: { _is_null: true } }
      order_by: [$order_by]
    ) {
      id
      information_fr
      information_nl
      creation_date
      last_updated_date
      norm_code
      not_application_option
      page_pdf_nl
      page_pdf_fr
      template_sub_theme_id
      template_theme_id
      title_fr
      title_nl
      index
      template_theme_item_remarks {
        remark_text_fr
        remark_text_nl
        id
      }
    }
  }
`;

/**
 * Description - graphql query to get all items include deleted in theme
 */
export const GET_ALL_ITEMS_INCLUDE_SOFT_DELETED_IN_THEME = gql`
query get_all_items_include_deleted_in_theme($template_theme_id: Int!) {
  qat_template_theme_item(
    order_by: { index: asc }
    where: {_and:[
      { template_theme_id: { _eq: $template_theme_id }},
      { template_sub_theme_id: { _is_null: true}}
    ]} 
  ) {
    id
    title_fr
    title_nl
    norm_code
    information_fr
    information_nl
    template_theme_id
    template_sub_theme_id
    index
    creation_date
    last_updated_date
    not_application_option
    page_pdf_nl
    page_pdf_fr
    deleted_at
  }
}
`;

/**
 * Description - graphql query to get all items include deleted in sub theme
 */
export const GET_ALL_ITEMS_INCLUDE_SOFT_DELETED_IN_SUBTHEME = gql`
  query get_all_items_include_deleted_in_subtheme(
    $template_sub_theme_id: Int!
  ) {
    qat_template_theme_item(
      order_by: { index: asc }
      where: { template_sub_theme_id: { _eq: $template_sub_theme_id } }
    ) {
      id
      title_fr
      title_nl
      norm_code
      information_fr
      information_nl
      template_theme_id
      template_sub_theme_id
      index
      creation_date
      last_updated_date
      not_application_option
      page_pdf_nl
      page_pdf_fr
    }
  }
`;

/**
 * Description - graphql query to get a specific entity
 */
export const GET_ITEM_BY_PK = gql`
  query qat_template_theme_item_by_pk($id: Int!) {
    qat_template_theme_item_by_pk(id: $id) {
      id
      information_fr
      information_nl
      last_updated_date
      creation_date
      deleted_at
      norm_code
      not_application_option
      page_pdf_nl
      page_pdf_fr
      template_sub_theme_id
      template_theme_id
      title_fr
      title_nl
      index
      template_theme_item_remarks {
        remark_text_fr
        remark_text_nl
        id
      }
    }
  }
`;

/**
 * Description - graphql query to get all items from theme id
 */
export const READ_ALL_ITEMS_FROM_TEMPLATE_THEME = gql`
  query qat_template_theme_items_from_theme($template_theme_id: Int!) {
    qat_template_theme_item(
      where: { _and: [
        { template_theme_id: { _eq: $template_theme_id }},
        { template_sub_theme_id: { _is_null: true}}
        { deleted_at: { _is_null: true }},
      ]
       
       
      }
      order_by: { index: asc }
    ) {
      id
      information_fr
      information_nl
      creation_date
      last_updated_date
      norm_code
      not_application_option
      page_pdf_nl
      page_pdf_fr
      template_sub_theme_id
      template_theme_id
      title_fr
      title_nl
      index
      template_theme_item_remarks(where: { deleted_at: { _is_null: true } }) {
        remark_text_fr
        remark_text_nl
        id
        index
      }
    }
  }
`;

/**
 * Description - graphql query to get all items from theme id
 */
export const READ_ALL_ITEMS_FROM_TEMPLATE_SUB_THEME = gql`
  query qat_template_theme_items_from_subtheme($template_sub_theme_id: Int!) {
    qat_template_theme_item(
      where: {
        template_sub_theme_id: { _eq: $template_sub_theme_id }
        deleted_at: { _is_null: true }
      }
      order_by: { index: asc }
    ) {
      id
      information_fr
      information_nl
      creation_date
      last_updated_date
      norm_code
      not_application_option
      page_pdf_nl
      page_pdf_fr
      template_sub_theme_id
      template_theme_id
      title_fr
      title_nl
      index
      template_theme_item_remarks(where: { deleted_at: { _is_null: true } }) {
        remark_text_fr
        remark_text_nl
        id
        index
      }
    }
  }
`;

/**
 * Description - graphql query to get all items from theme id
 */
export const READ_ALL_ITEMS_WITH_REMARK_FROM_TEMPLATE_THEME = gql`
  query qat_template_theme_items_from_theme($template_theme_id: Int!) {
    qat_template_theme_item(
      where: {
       _and:[
        { template_sub_theme_id: { _is_null: true}},
        { template_theme_id: { _eq: $template_theme_id }},
        { deleted_at: { _is_null: true }}
       ]
      }
      order_by: { index: asc }
    ) {
      id
      information_fr
      information_nl
      creation_date
      last_updated_date
      norm_code
      not_application_option
      page_pdf_nl
      page_pdf_fr
      template_sub_theme_id
      template_theme_id
      title_fr
      title_nl
      index
      template_theme_item_remarks {
        remark_text_fr
        remark_text_nl
        id
      }
    }
  }
`;
