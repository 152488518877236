import { Collapse, List, ListItem, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './MenuListItemStyle';
import { Link } from 'react-router-dom';
import { MenuItem } from '../../NavigationComponent/NavigationComponent';

export interface MenuListItemProps {
  menuItem: MenuItem;
  onSelect: (id: string) => void;
}

/**
 * This component is responsible to display each menu item (with corresponding childs if present) in the navigation
 * Each menu item also contains a Link (except for parent menuItems with childeren) to handle the routing
 * If there are childeren inside a menu item, a collapse will be displayed
 */
const MenuListItem: React.FC<MenuListItemProps> = ({ onSelect, menuItem }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Fragment>
      <ListItem
        button
        selected={menuItem.active}
        onClick={() => onSelect(menuItem.id)}
        component={menuItem.link ? Link : null} // check if there are route
        to={menuItem.link ? menuItem.link : null}
        classes={{
          root: classes.rootListItem,
          selected: classes.selected,
        }}
      >
        <ListItemText
          primary={t(menuItem.id)}
          className={classes.menuListItem}
        />
        {menuItem.childs ? (
          menuItem.open ? (
            <ExpandLess className={classes.expandMore} />
          ) : (
            <ExpandMore className={classes.expand} />
          )
        ) : null}
      </ListItem>
      {menuItem.childs && (
        <Collapse in={menuItem.open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {menuItem.childs.map(
              (childItem, index) =>
                childItem.isAllowed && (
                  <ListItem
                    selected={childItem.active}
                    button
                    key={childItem.id}
                    onClick={() => onSelect(childItem.id)}
                    component={childItem.link ? Link : null} // check if there are route
                    to={childItem.link ? childItem.link : null}
                    classes={{
                      root: classes.rootListItem,
                      selected: classes.selected,
                    }}
                  >
                    <ListItemText
                      primary={t(childItem.id)}
                      className={classes.menuListItem}
                    />
                  </ListItem>
                ),
            )}
          </List>
        </Collapse>
      )}
    </Fragment>
  );
};
export default MenuListItem;
