export const location = {
  locationValidationError: 'La localité que vous avez spécifiée existe déjà',
  search: 'Rechercher une localité',
  addLocation: "Ajouter une localité",
  addOtherLocation: "Ajouter une autre localité",
  name: "Localité",
  location: "Localité",
  category: "Catégorie",
  categoryError: "La categorie est un champ obligation",
  nameError: "Le nom de la location est un champ obligation",
  specification: "Spécification",
  locationTableTitle: 'Liste des localités de l\' entité "{{0}}"',
  address: "Adresse",
  nameCategorieSpecificationUniqueError:'Il existe déjà une localité  "{{0}}" avec la categorie "{{1}}" et la spécification "{{2}}" ',
  nameCategorieUniqueError:'Il existe déjà une localité "{{0}}" avec la categorie "{{1}}"',
  editLocation: "Modifier une localité", 
  confirmAuditText:"Cette localité est lié a un audit. Si vous la modifiez, l'audit sera lié à la nouvelle localité.Êtes-vous certain de continuer? ",
  confirmAddressText: "Cette localité contient déjà des adresses. Si vous la modifiez, ces adresses seront liées à la nouvelle localité. Êtes-vous certain de continuer ? ",
  confirmAuditTextconfirmAddressText:"Cette localité est lié a un audit et contient des adresses. Si vous la modifiez, l'audit et les adresses seront liés à la nouvelles localité. Êtes-vous certain de continuer?",
};
