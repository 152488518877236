import { Grid, Button, IconButton, List, ListSubheader, ListItem, ListItemSecondaryAction, Divider } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { FormProvider, UseFormMethods } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { audit_data } from '../../../../../services/Audit/AuditDataModel';
import { BuildingFormData } from '../../../../Building/BuildingForm/BuildingFormPresenter';
import BuildingListComponent from '../../../Building/BuildingRead/BuildingListComponent';
import { AuditFormData } from '../../AuditFormPresenter';
import { testIds } from '../../AuditFormView';
import AuditFormMainInfoComponent from '../AuditFormMainInfoComponent';
import { useStyles } from './AuditFormMainSectionStyle';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import routes from '../../../../../routes';
import { PictureAsPdfOutlined } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import { useDocumentDialogService } from '../../../../AdminDocument/AdminDocumentRead/AdminDocumentService';
import InterviewDocumentDialog from '../../../InterviewDocument/InterviewDocumentRead/InterviewDocumentDialog';
import { useBrowserLocationState } from '../../../../Shared/BrowserLocationState/BrowserLocationState';

export const testId = {
  updatePdfButton: 'building-update-pdf-button',
  uploadPdfButton: 'building-upload-pdf-button',
};
export interface AuditFormMainSectionComponentProps {
  building?: BuildingFormData;
  validation: UseFormMethods<AuditFormData>;
  onSubmit: (Event) => void;
  onCreateBuildingClick: () => void;
  title: string;
  submitButtonLabel: string;
  audit?: audit_data;
  displayBuildingSection: boolean;
  parentPath: string;
  fromCreateAuditComponent?: boolean;
}

const AuditFormMainSectionComponent: React.FC<AuditFormMainSectionComponentProps> = ({
  validation,
  onSubmit,
  onCreateBuildingClick,
  title,
  submitButtonLabel,
  audit,
  displayBuildingSection,
  parentPath,
  fromCreateAuditComponent
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const currentLocationState = useBrowserLocationState<{isReadonly?: boolean}>({keysRequired: []})

  const isReadOnlyMode = useMemo(
    () =>
    fromCreateAuditComponent ?  false : 
    currentLocationState && currentLocationState.isReadonly ? 
    currentLocationState.isReadonly : false,
    [currentLocationState, fromCreateAuditComponent],
  );
  const blockFunctionInReadOnlyMode = e => e.preventDefault();

  const [url, setUrl] = useState<string>("");

  const { open: openInterviewDocumentDialog, pageNumber: pageNumberInterviewDocumentDialog, onCloseDialog: onCloseInterviewDocumentDialog, onSelectPage: onSelectInterviewDocument } = useDocumentDialogService();

  const onOpenPdf = pdfUrl => {
    setUrl(pdfUrl)
    onSelectInterviewDocument(1);
  };

  const history = useHistory();
  const upLoadPdf = () => {
    history.push({
      pathname: `${routes.interviewUploadDocument.path}`,
      state: { audit: audit, isReadonly: isReadOnlyMode, isNewDoc: true },
    });
  };

  return (
    <>
      <InterviewDocumentDialog
        open={openInterviewDocumentDialog}
        pageNumber={pageNumberInterviewDocumentDialog}
        onCloseDialog={onCloseInterviewDocumentDialog}
        url={url}
      />
      <FormProvider {...validation}>
        <form
          onSubmit={isReadOnlyMode ? blockFunctionInReadOnlyMode : onSubmit}
        >
          <h1>{t(title)}</h1>
          <AuditFormMainInfoComponent
            audit={audit}
            isReadOnlyMode={isReadOnlyMode}
          />
          {!fromCreateAuditComponent &&
            <Grid item className={classes.control}>
              <Button
                className={classes.submitButton}
                type="submit"
                id="validate"
                data-testid={testIds.buttonValidate}
                variant="contained"
                color="primary"
                fullWidth
              >
                {t(submitButtonLabel)}
              </Button>
            </Grid>}
          {!fromCreateAuditComponent &&
            <Grid container className={classes.interviewLine}>
              <Grid lg={1} xs={1} item>
                <List
                  subheader={
                    <ListSubheader component="div" id="nested-list-subheader">
                      {t('audit:interview')}
                    </ListSubheader>
                  }
                >
                </List>
              </Grid>
              <Grid lg={11} xs={11} item >
                <List>
                  <ListItem>
                    <ListItemSecondaryAction
                      className={classes.interviewDocumentButtonContainer}
                    >
                      <div>
                        {(audit?.interview_document && audit?.interview_document?.length > 0) ? (
                          <IconButton
                            data-testid={testId.uploadPdfButton}
                            className={classes.onOpenPdf}
                            onClick={() => onOpenPdf(audit?.interview_document ? audit?.interview_document[0].url : "")}
                          >
                            <PictureAsPdfOutlined />
                          </IconButton>

                        ) : (
                          (!isReadOnlyMode) &&
                          <IconButton
                            data-testid={testId.uploadPdfButton}
                            className={classes.interviewPdf}
                            onClick={() => upLoadPdf()}>
                            <PictureAsPdfOutlined />
                          </IconButton>

                        )}
                        {!isReadOnlyMode && audit?.interview_document?.length !== 0 && (
                          <Link
                            to={{
                              pathname: `${routes.interviewUploadDocument.path}`,
                              state: { audit: audit, isReadonly: isReadOnlyMode, isNewDoc: false },
                            }}
                          >
                            <IconButton data-testid={testId.updatePdfButton}>
                              <EditIcon />
                            </IconButton>

                          </Link>
                        )}
                      </div>
                    </ListItemSecondaryAction>
                  </ListItem>
                </List>
              </Grid>
            </Grid>}
          <Divider />
          {displayBuildingSection && (
            <BuildingListComponent
              audit={audit}
              parentPath={parentPath}
              onCreateBuildingClick={onCreateBuildingClick}
              isReadOnlyMode={isReadOnlyMode}
            />
          )}
        </form>
      </FormProvider>
    </>
  );
};

export default AuditFormMainSectionComponent;
