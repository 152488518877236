import {
  Box,
  Grid,
  Paper,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import moment from 'moment';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../AppContext';
import { useStyles } from './ChecklistReportNotConformViewStyle';
import Chip from '@material-ui/core/Chip';
import { translate, translateformPagePDF } from '../../../services/Translation/TranslationService';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import _ from 'lodash';
import AdminDocumentServiceContext from '../../AdminDocument/AdminDocumentRead/AdminDocumentServiceContext'

export const testIds = {};

export interface ChecklistReportNotConformViewProps {
  isLoading: boolean;
  overviewDetails: any;
}

const ChecklistReportNotConformView: React.FC<ChecklistReportNotConformViewProps> = ({
  overviewDetails,
  isLoading,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { language } = useContext(AppContext);
  const { onSelectPage } = useContext(AdminDocumentServiceContext)

  return isLoading ? (
    <CircularProgress />
  ) : (
    <Fragment>
      <Grid container>
        <Grid item lg={12} xs={12} className={classes.titleGrid}>
          <Box m={1}>
            <Paper elevation={3}>
              <Box m={2}>
                <Typography className={classes.title}>
                  {t('report:not_conform_title', {
                    abbreviation: overviewDetails?.audit?.entity?.abbreviation,
                    location: overviewDetails?.audit?.location_entity?.name,
                    audit_date: moment(
                      overviewDetails?.audit?.audit_date,
                    )?.format('DD/MM/YYYY'),
                  })}
                </Typography>
              </Box>
            </Paper>
          </Box>
        </Grid>
        <Grid item lg={12} xs={12}>
          <Box m={1}>
            {overviewDetails?.items?.map((_item, index) => (
              <Paper elevation={3} key={index}>
                <Grid container key={index}>
                  <Grid item lg={4} xs={4}>
                    <Box m={2}>
                      <Grid container>
                        <Grid item lg={1} xs={1}>
                          <Typography
                            className={classes.normCode}
                            onClick={() => onSelectPage(translateformPagePDF(_item[0][0], language))}
                          >

                            {_item[0][0]?.norm_code}
                          </Typography>
                        </Grid>
                        <Grid item lg={11} xs={11}>
                          <Typography className={classes.itemName}>
                            {translate(_item[0][0], language)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item lg={8} xs={8}>
                    <Box m={0.2}>
                      {_.orderBy(
                        _item,
                        __item =>
                          __item[0]?.checklist_theme_section?.checklist_theme
                            ?.checklist?.building?.index ||
                          __item[0]?.checklist_theme_sub_section
                            ?.checklist_theme_section?.checklist_theme
                            ?.checklist?.building?.index,
                        ['asc'],
                      )?.map((_item_adress, index_adress) => (
                        <Grid container key={index_adress}>
                          <Grid item lg={4} xs={4}>
                            <Box mr={2}>
                              <Typography
                                className={classes.addressGrid}
                                component={'div'}
                              >
                                <span>
                                  <Chip
                                    className={classes.addressNumber}
                                    label={
                                      _item_adress[0]?.checklist_theme_section
                                        ?.checklist_theme?.checklist?.building
                                        ?.index ||
                                        _item_adress[0]?.checklist_theme_sub_section
                                        ?.checklist_theme_section
                                        ?.checklist_theme?.checklist?.building
                                        ?.index
                                    }
                                  ></Chip>
                                </span>
                                {_item_adress[0]?.checklist_theme_section
                                  ?.checklist_theme?.checklist?.building
                                  ?.address?.street ||
                                  _item_adress[0]?.checklist_theme_sub_section
                                    ?.checklist_theme_section?.checklist_theme
                                    ?.checklist?.building?.address?.street}{' '}
                                {_item_adress[0]?.checklist_theme_section
                                  ?.checklist_theme?.checklist?.building
                                  ?.address?.house_number ||
                                  _item_adress[0]?.checklist_theme_sub_section
                                    ?.checklist_theme_section?.checklist_theme
                                    ?.checklist?.building?.address
                                    ?.house_number}{' '}
                                {(_item_adress[0]?.checklist_theme_section
                                  ?.checklist_theme?.checklist?.building
                                  ?.address?.box_number? " / " + _item_adress[0]?.checklist_theme_section
                                  ?.checklist_theme?.checklist?.building
                                  ?.address?.box_number : "") ||
                                  (_item_adress[0]?.checklist_theme_sub_section
                                    ?.checklist_theme_section?.checklist_theme
                                    ?.checklist?.building?.address
                                    ?.box_number? " / " + _item_adress[0]?.checklist_theme_sub_section
                                    ?.checklist_theme_section?.checklist_theme
                                    ?.checklist?.building?.address
                                    ?.box_number : "")}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item lg={8} xs={8}>
                            {_item_adress?.map((_item_line, index_line) => (
                              <Grid key={index_line}>
                                <Typography>
                                  <span className={classes.themeGrid}>
                                    <ArrowForwardIcon
                                      fontSize="small"
                                      className={classes.arrowIcon}
                                    />
                                  </span>
                                  <span className={classes.themeName}>
                                    {translate(
                                      _item_line?.checklist_theme_section ||
                                      _item_line?.checklist_theme_sub_section
                                          ?.checklist_theme_section,
                                      language,
                                    )}
                                  </span>
                                  <span
                                    className={classes.sectionSpecification}
                                  >
                                    {
                                      _item_line?.checklist_theme_section? _item_line?.checklist_theme_section?.specification 
                                      : _item_line?.checklist_theme_sub_section?.checklist_theme_section?.specification
                                    }
                                  </span>
                                </Typography>
                                {_item_line?.checklist_theme_item_remarks?.map(
                                  (_remark, index_remark) => (
                                    <Typography key={index_remark}>
                                      <span>
                                        {translate(_remark, language)}
                                      </span>
                                    </Typography>
                                  ),
                                )}
                                <Typography>
                                  <span>{_item_line?.comment}</span>
                                </Typography>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      ))}
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            ))}
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default ChecklistReportNotConformView;
