import {
    InMemoryCache,
    makeVar,
} from "@apollo/client";
import { LocationAddressFormData } from "./LocationAddressFormModel";


export const openCreateLocationAddressVar = makeVar(false);
export const openUpdateLocationAddressVar = makeVar(false);
export const openLocationAddressTableVar = makeVar(false);
export const locationAddressLastIndexVar = makeVar(-1)
export const currentLocationId = makeVar(-1);

export const defaultLocation: LocationAddressFormData = {
 
    street: "",   
    house_number:"", 
   box_number:"",
   city: "",
   location_entity_id: -1    
};


export const locationAddressVar = makeVar(defaultLocation);




export const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                read() {
                    return (
                        openCreateLocationAddressVar(),
                        openUpdateLocationAddressVar(),
                        openLocationAddressTableVar(),
                        locationAddressLastIndexVar(),
                        locationAddressVar()                       
                    )
                }
            }
        }
    }
})




