import gql from 'graphql-tag';

/**
 * Description - graphql mutation to set not applicable value to false in checklist theme sub section
 */
export const SET_NOT_APPLICATION_THEME_SUB_SECTION_CHECKLIST_FALSE = gql`
  mutation update_qat_checklist_theme_sub_section_by_pk($id: Int!) {
    update_qat_checklist_theme_sub_section_by_pk(
      pk_columns: { id: $id }
      _set: { no_application: false }
    ) {
      id
    }
  }
`;

/**
 * Description - graphql mutation to set not applicable value to true and restore checklist theme sub section
 */
export const SET_NOT_APPLICATION_THEME_SUB_SECTION_CHECKLIST_TRUE = gql`
  mutation restore_theme_sub_section_checklist(
    $checklist_theme_sub_section_id: Int!
    $checklist_theme_sub_section: qat_checklist_theme_sub_section_insert_input!
  ) {
    delete_qat_checklist_theme_sub_section(
      where: { id: { _eq: $checklist_theme_sub_section_id } }
    ) {
      affected_rows
    }
    insert_qat_checklist_theme_sub_section_one(
      object: $checklist_theme_sub_section
    ) {
      id
    }
  }
`;
