import React from 'react';
import List from '@material-ui/core/List';
import ListItemText from '@material-ui/core/ListItemText';
import { Button, ListItem, Typography } from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useTranslation } from 'react-i18next';
import { useStyles } from '.';
import ChecklistNavigationListItemComponent from '../ChecklistNavigationListItemComponent';
import { get_checklist_by_pk_data } from '../../../../services/Checklist/ChecklistReadService/ChecklistReadQuery';

export interface ChecklistNavigationViewProps {
  onThemeSelected: (theme) => void;
  onRemarkSelected: () => void;
  selectedTheme: any;
  selectedChecklistRemark?: any;
  checklist?: get_checklist_by_pk_data;
  onCloseMenu: () => void;
}

export default function ChecklistNavigationView({
  checklist,
  onThemeSelected,
  selectedTheme,
  onCloseMenu,
  selectedChecklistRemark,
  onRemarkSelected,
}: ChecklistNavigationViewProps) {
  const classes = useStyles();
  const { t } = useTranslation();
  const ColoredLine = ({ color }) => (
    <hr
      style={{
        borderTopColor: color,
        borderBottomColor: '#cf012c',
        borderTopWidth: 2,
        margin: 0,
        padding: 0,
      }}
    />
  );
  return (
    <div>
      <div className={classes.containerCloseButton}>
        <Button
          className={classes.closeButton}
          endIcon={<ChevronRightIcon />}
          onClick={onCloseMenu}
        >
          <Typography variant="body1">{t('theme:theme')}</Typography>
        </Button>
      </div>
      <List>
        <ColoredLine color="#000000" />
        <ListItem
          classes={{
            root: classes.listItem,
            selected: classes.selected,
          }}
          button
          key={checklist?.id}
          selected={checklist?.id === selectedChecklistRemark?.id}
          onClick={() => onRemarkSelected()}
        >
          <ListItemText primary={t('checklist:checkListRemark')} />
        </ListItem>
        <ColoredLine color="#000000" />
        {checklist?.checklist_themes.map((theme, index) => {
          return (
            <ChecklistNavigationListItemComponent
              theme={theme}
              onThemeSelected={() => onThemeSelected(theme)}
              selectedTheme={selectedTheme}
              key={theme.id}
            />
          );
        })}
      </List>
    </div>
  );
}
