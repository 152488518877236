import { gql } from '@apollo/client';

/**
 * Description - graphql query to get all categories
 */
export const READ_ALL_CATEGORIES = gql`
  query read_all_categories {
    qat_category_location {
      id
      name_fr
      name_nl
    }
  }
`;
