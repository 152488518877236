import { useEffect, useState, useMemo, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import {
  BuildingFormComponentProps,
  BuildingFormViewProps,
} from '../BuildingFormComponent/BuildingFormComponent';
import { building_field_values } from '../../../../services/Building/BuildingDataModel';
import {
  category_location_form_field_config,
  sub_category_location,
} from '../../../../services/Category/CategoryDataModel';
import { address_data } from '../../../../services/Address/AddressDataModel';
import { useGetLocationById } from '../../../../services/Location/ReadService/LocationReadService';
import { useHistory } from 'react-router-dom';
import routes from '../../../../routes';
import { checklist_from_get_by_building_by_pk_data } from '../../../../services/Building/ReadService/BuildingReadQuery';
import { useGetBuildingsByAuditIdLength } from '../../../../services/Building/ReadService/BuildingReadService';
import { removeItemCacheLocationState, useBrowserLocationState } from '../../../Shared/BrowserLocationState/BrowserLocationState';
export interface BuildingFormData {
  id?: number;
  address_id: number | string;
  sub_category_location_id: number | string;
  building_field_values?: building_field_values[];
  audit_id?: number;
  index: number;
}

const mapBuildingFormData = (
  data: BuildingFormData | any,
  index: number,
): BuildingFormData => {
  data.building_field_values = data.category_location_form_field_configs?.map(
    (value, fieldConfig) => {
      return {
        category_location_form_field_config_id: fieldConfig,
        value: value,
      };
    },
  );

  data.building_field_values = data.building_field_values?.filter(
    x => x !== null,
  );
  if (data.building_field_values)
    data.building_field_values.data = data.building_field_values;
  delete data.category_location_form_field_configs;
  return {
    address_id: data.address_id,
    sub_category_location_id: data.sub_category_location_id,
    building_field_values: data.building_field_values?.data,
    index: index,
  };
};

export const isDirtyChecklist = (
  checklist: checklist_from_get_by_building_by_pk_data,
): boolean => {
  let isDirty: boolean = false;
  checklist.checklist_themes.forEach(theme => {
    if (theme.not_applicable === true) {
      isDirty = true;
    }
    theme.checklist_theme_sections.forEach(section => {
      if (
        section.specification &&
        section.specification !== null &&
        section.specification !== ''
      ) {
        isDirty = true;
      }
      section.checklist_theme_items.forEach(item => {
        if (
          (item.comment !== null && item.comment !== '') ||
          item.has_interview === true ||
          item.not_application === true ||
          item.is_conform !== null
        ) {
          isDirty = true;
        }
        item.checklist_theme_item_remarks.forEach(remark => {
          if (remark.checked === true) {
            isDirty = true;
          }
        });
      });
      section.checklist_theme_sub_sections.forEach(sub_section => {
        if (sub_section.no_application === true) {
          isDirty = true;
        }
        sub_section.checklist_theme_items.forEach(item => {
          if (
            (item.comment !== null && item.comment !== '') ||
            item.has_interview === true ||
            item.not_application === true ||
            item.is_conform !== null
          ) {
            isDirty = true;
          }
          item.checklist_theme_item_remarks.forEach(remark => {
            if (remark.checked === true) {
              isDirty = true;
            }
          });
        });
      });
    });
  });
  return isDirty;
};

const useBuildingFormPresenter = ({
  onSubmitBuilding,
  audit,
  building,
  title,
  submitButtonLabel,
  parentPath,
  fromCreateBuilding
}: BuildingFormComponentProps): BuildingFormViewProps => {
  //get latest index of all buildings for this audit
  const { getBuildingsByAuditIdLength } = useGetBuildingsByAuditIdLength();
  
  const currentLocationState = useBrowserLocationState<{address_id_to_inject_in_form? : number}>({keysRequired: [], storedInCache: false})

  const address_id = useMemo(() => {
    return currentLocationState?.address_id_to_inject_in_form
        ? currentLocationState.address_id_to_inject_in_form
        : building?.address?.id
          ? building.address.id
          : ""
  }, [currentLocationState, building])

  useEffect(() => {
    return () => removeItemCacheLocationState("address_id_to_inject_in_form")
  },[])

  const defaultValues = useMemo(() => {
    if (building) {
      const category_location_form_field_configs = {};

      if (building.building_field_values)
        building.building_field_values.forEach(building_field => {
          category_location_form_field_configs[
            building_field?.category_location_form_field_config_id
          ] = building_field?.value;
        });

      return {
        address_id: address_id,
        sub_category_location_id: building?.sub_category_location?.id,
        category_location_form_field_configs: category_location_form_field_configs,
        index: building?.index,
      };
    }

    return {
      address_id: address_id ? address_id : '',
      sub_category_location_id: '',
      category_location_form_field_configs: [],
    };
  }, [building, address_id]);

  const {
    errors,
    handleSubmit,
    control,
    getValues,
    reset,
  } = useForm<BuildingFormData>({ defaultValues });
  const [addresses, setAddresses] = useState<Array<address_data | undefined>>();
  const { getLocationById } = useGetLocationById();
  const history = useHistory();

  const [
    openConfirmSubCategoryChange,
    setOpenConfirmSubCategoryChange,
  ] = useState<boolean>(false);
  const [newDataToConfirm, setNewDataToConfirm] = useState<
    BuildingFormData | undefined
  >();

  const onConfirmSubCategoryChange = useCallback(() => {
    if (newDataToConfirm) {
      getBuildingsByAuditIdLength(audit?.id).then(buildings_length => {
        const _building = mapBuildingFormData(
          newDataToConfirm,
          buildings_length + 1,
        );
        onSubmitBuilding({
          building: _building,
          haveToUpdateChecklist: true,
          redirect: true,
        });
        setNewDataToConfirm(undefined);
      });
    } else {
      console.warn('current data to store is undefined');
    }
    setOpenConfirmSubCategoryChange(false);
  }, [newDataToConfirm, onSubmitBuilding, audit, getBuildingsByAuditIdLength]);

  const onCloseConfirmSubCategoryChange = useCallback(() => {
    setNewDataToConfirm(undefined);
    setOpenConfirmSubCategoryChange(false);
  }, []);

  useEffect(() => {
    getLocationById(audit?.location_entity?.id).then(location =>
      setAddresses(location?.addresses),
    );
  }, [audit, getLocationById]);

  useEffect(() => {
    if (!audit && !building)
      history.push({ pathname: routes.currentAudits.path });
  }, [audit, building, history]);

  const subCategoryOptions: sub_category_location[] = useMemo(() => {
    return (
      audit?.location_entity?.category_location?.sub_category_locations || []
    );
  }, [audit]);

  const formConfig: category_location_form_field_config[] = useMemo(() => {
    return (
      audit?.location_entity?.category_location?.category_location_form_config
        ?.category_location_form_field_configs || []
    );
  }, [audit]);

  const onSubmitForm = ((data: BuildingFormData | any) => {
    // if there are building to update and sub category location id of template from chceklist of this building is different than a new sub category value, the user have to confirm the change
    const haveToUpdateChecklist =
      building &&
        building.checklist &&
        building.checklist.template_checklist.sub_category_location_id &&
        building.checklist?.template_checklist.sub_category_location_id !==
        data.sub_category_location_id
        ? true
        : false;
    if (
      haveToUpdateChecklist &&
      building?.checklist &&
      isDirtyChecklist(building.checklist)
    ) {
      setOpenConfirmSubCategoryChange(true);
      setNewDataToConfirm(data);
    } else {
      getBuildingsByAuditIdLength(audit?.id).then(buildings_length => {
        const _building = mapBuildingFormData(data, buildings_length + 1);
        onSubmitBuilding({
          building: _building,
          haveToUpdateChecklist,
          redirect: true,
        });
      });
    }
  });

  const handleValidateAdd = useCallback((data: BuildingFormData | any) => {
    getBuildingsByAuditIdLength(audit?.id).then(buildings_length => {
      const _building = mapBuildingFormData(data, buildings_length + 1);
      onSubmitBuilding({ building: _building, redirect: false });
      reset({ address_id: undefined, sub_category_location_id: undefined, building_field_values: [] });
    });
  }, [reset, onSubmitBuilding, audit, getBuildingsByAuditIdLength]);

  return {
    audit,
    building,
    errors,
    handleSubmit,
    handleValidateAdd: handleValidateAdd,
    onSubmitForm: onSubmitForm,
    addresses,
    loadingAddress: addresses ? false : true,
    subCategoryOptions,
    formConfig,
    control,
    title,
    submitButtonLabel,
    parentPath,
    getValues,
    onConfirmSubCategoryChange,
    onCloseConfirmSubCategoryChange,
    openConfirmSubCategoryChange,
    fromCreateBuilding: fromCreateBuilding,
  };
};

export default useBuildingFormPresenter;
