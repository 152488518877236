export const building = {
  createBuilding: 'Ajouter un bâtiment',
  address: 'Adresse',
  housing: 'Logement partagée / individuel',
  housingError: 'Logement partagée / individuel est un champ obligatoire',
  addressError: "L'adresse est un champ obligatoire",
  formFieldError:'{{0}} est un champ obligatoire',
  centre: 'Centre',
  updateBuilding: 'Modifier un bâtiment',
  buildingValidationError: 'Le bâtiment que vous avez spécifiée existe déjà',
  buildingConfirmSubCategoryChangeTitle: 'Modifier la sous catégorie',
  buildingConfirmSubCategoryChangeText:
    'Si vous modifiez la sous catégorie, les données de la checklist seront en partie supprimées',
  validateAdd: 'Ajouter un autre bâtiment',
  uniqueBuildingErrorMessage: 'Il y a déjà un batiment avec cette même adresse',
};
