export const subtheme = {
  editSubTheme: 'Subthema wijzigen',
  subTheme: 'Subthema',
  addSubThemeTemplate: 'Subthema toevoegen',
  createSubTheme: 'Subthema aanmaken',
  name: 'Naam',
  nameFr: 'Naam (Frans)',
  nameNl: 'Naam (Nederlands)',
  nameFrError: 'Naam (Frans) is een verplicht veld',
  nameNlError: 'Naam (Nederlands) is een verplicht veld',
  validateAdd: 'Een ander subthema toevoegen',
  theme_name: 'Thema',
  subthemeTableTitle: 'Subthema voor  thema "{{1}}" van de CheckList "{{2}}"',
  itemTableTitleWithSubtheme:
    'Liste des Items du sous-thème " {{0}}" du thème "{{1}}" de la CheckList "{{2}}"',
  notApplicable: 'Optie n.v.t',
  nameFrUniqueError: "Er is al een sub theme met deze Franse naam",
  nameNlUniqueError: "Er is al een sub theme met deze Nederlandse naam"
};
