import {
  get_checklist_by_pk_item_data,
  get_checklist_by_pk_section_data,
  get_checklist_by_pk_sub_section_data,
  get_checklist_by_pk_theme_data,
} from '../../services/Checklist/ChecklistReadService/ChecklistReadQuery';

//check if all the item  corform or not_application are validated in subsection or section
export const checkSectionItemValidated = (
  sectionToBorder: get_checklist_by_pk_item_data[],
): boolean => {
  let isValidated = false;
  if (sectionToBorder && sectionToBorder?.length> 0) {
    isValidated = sectionToBorder.every(item => {
      if ( item.not_application === false && item.is_conform == null) {       
        return false;
       
      }    
      return true;
    });
  }
  return isValidated;
};

//check if all the item  corform or not_application are validated in subsection
export const checkSubSectionsValidated = (
  subSectionToborder: get_checklist_by_pk_sub_section_data[],
): boolean => {
  let isSubSectionValidated = false;

  if (subSectionToborder && subSectionToborder?.length > 0) {
    isSubSectionValidated = subSectionToborder.every(subSection => subSection.no_application || !subSection.checklist_theme_items || (subSection.checklist_theme_items && !subSection.checklist_theme_items.length)
    ? true
    : checkSectionItemValidated(subSection.checklist_theme_items));
  }

  return isSubSectionValidated;
};

// check if section or subsection is validated
export const checkSectionsValidated = (
  sectionCurrent: get_checklist_by_pk_section_data,
  sectionNumber: number,
  themeNotApplicable?: boolean
): boolean => {
  let isvalidatedItem = true;
  let isvalidatedSubSection = true;
  let isvalidatedSpecification = true;
 
  if((sectionNumber > 1 && !sectionCurrent.specification )){
    isvalidatedSpecification = false ;
  }
  if (
    sectionCurrent &&
    sectionCurrent.checklist_theme_items &&
    sectionCurrent.checklist_theme_items.length > 0 ){
      isvalidatedItem = checkSectionItemValidated(
      sectionCurrent.checklist_theme_items,
    );
  }
  if (
    sectionCurrent &&
    sectionCurrent.checklist_theme_sub_sections &&
    sectionCurrent.checklist_theme_sub_sections.length > 0) {
      isvalidatedSubSection = checkSubSectionsValidated(sectionCurrent.checklist_theme_sub_sections)
  }
  const isValidated = themeNotApplicable ? themeNotApplicable : isvalidatedSubSection && isvalidatedItem && isvalidatedSpecification;
  return isValidated
};

//check if theme is validated
export const checkThemeValidated = (
  themeCurrent: get_checklist_by_pk_theme_data,
): boolean => {
  let themeIsValidated = false;
  let sectionNumber : number = themeCurrent.checklist_theme_sections.length;
  if (
    themeCurrent &&
    themeCurrent.checklist_theme_sections &&
    themeCurrent.checklist_theme_sections?.length
  ) {
    themeIsValidated = themeCurrent.not_applicable 
      ? true
      : themeCurrent.checklist_theme_sections.every(section => {
        if (checkSectionsValidated(section, sectionNumber) === false) {
          return false;
        }
        return true;
      });
  }
  return themeIsValidated;
};
