import { useCallback, useContext, useMemo, useState } from "react"
import AppContext from "../../../../AppContext"
import { useGetAllAdminDocument } from "../../../../services/AdminDocument/AdminDocumentRead/AdminDocumentRead"
import { useAddAdminDocument } from "../../../../services/AdminDocument/AdminDocumentWrite/AdminDocumentWrite"
import { translateTitle } from "../../../../services/Translation/TranslationService"

export type AdminDocumentUploadFormInputs = {
    id: number | "",
    file: File | null
  }
  
  export type AdminDocumentUploadFormKeys = "file" | "id"
  
  export type AdminDocumentUploadErrorsValidation = {
    id?: "required"
    file?: "required" | "type-pdf"
  } | undefined
  
  export const validateAdminDocumentUpload = (formInputs: AdminDocumentUploadFormInputs): AdminDocumentUploadErrorsValidation | void => {
    const errors: AdminDocumentUploadErrorsValidation = {}
  
    const { id, file } = formInputs
    if (id === "") {
      errors.id = "required"
    }
    if (file === null) {
      errors.file = "required"
    } else if (file.type !== "application/pdf") {
      errors.file = "type-pdf"
    }
  
    if (Object.keys(errors).length) {
      return errors
    }
  }
  
  export const useAdminDocumentUploadService = () => {
    const { language } = useContext(AppContext)
    const [inputValues, setInputValues] = useState<AdminDocumentUploadFormInputs>({id: "", file: null})
    const [errors, setErrors] = useState<AdminDocumentUploadErrorsValidation>()
    const [isChangedFile, seIsChangedFile] = useState<boolean>(false)

    // get documentIdSelected
    const documentIdSelected = useMemo(() => {
      return inputValues.id !==  "" ? inputValues.id : -1
    },[inputValues])

    const { data, loading } = useGetAllAdminDocument()
  
    const documentSelectedOptions: {key: string | number ,value: number, label: string}[] = useMemo(() => {
      return !loading && data
        ? data.map(document => ({key: document.id, value: document.id, label: translateTitle(document, language) }))
        : []
      },[data, loading, language]) 

    const documentUrlSelected: string | null = useMemo(() => {
      if(data){
        const result = data.find(({id}) => id === inputValues.id)
        if(result && result.url && result.url !== ""){
          return result.url
        } else {
          return null
        }
      } else {
        return null
      }
    },[data, inputValues])  
    
    const { onAddAdminDocument } = useAddAdminDocument({documentIdSelected, documentUrlSelected})
   
  
    const resetErrors = useCallback((key: AdminDocumentUploadFormKeys) => {
      setErrors(current => {
        return { ...current, [key]: undefined }  
      })
    }, [])
  
    const handleChange = useCallback((key: AdminDocumentUploadFormKeys) => (event) => {
      switch(key){
        case "id":
          // if the user select another document, the file value is erased
          setInputValues(current => ({...current, [key]: event.target.value}))
          seIsChangedFile(false)
          break;
        case "file":
          setInputValues(current => ({...current, [key]: event.target.files[0]}))  
          seIsChangedFile(true)
          break;
        default:
          console.error(`${key} doesn't exist in form input model`)
      }
      resetErrors(key)
    },[resetErrors])
  
    const handleSubmit = useCallback((event) => {
      event.preventDefault()
      const submitErrorsValidation = validateAdminDocumentUpload(inputValues)
      if (submitErrorsValidation) {
        setErrors(submitErrorsValidation)
      } else {
        const { id,file } = inputValues
        if (id && file) {
          const document = data.find(documentVar => documentVar.id === id)
          if (document) {
            onAddAdminDocument(id, file, document.name_file).then(() => {
              setInputValues(current => ({...current, file: null})) 
            })
            
          }
        }
      }
    }, [onAddAdminDocument, data, inputValues])
  
    return {values: inputValues, errors, onChange: handleChange, onSubmit: handleSubmit, options: documentSelectedOptions, loading,  isChangedFile: isChangedFile, documentIdSelected: documentIdSelected}
  }
  