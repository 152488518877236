import { useMutation, useReactiveVar } from "@apollo/client"
import { useCallback, useContext } from "react";
import AppContext from "../../../AppContext";
import { NotificationType } from "../../../components/Notification/NotificationComponent";
import { keycloakInstanceVar } from "../../../graphql/ClientProvider";
import { UPDATE_INTERVIEW_DOCUMENT, ADD_INTERVIEW_DOCUMENT, update_interview_document_response, update_interview_document_variables, add_interview_document_response, add_interview_document_variables } from "./InterviewDocumentMutation";
import { GET_AUDIT_BY_PK, READ_ALL_DONE_AUDITS_SORTED, READ_CURRENT_AUDITS } from "../../Audit/ReadService/AuditReadQuery";
import { InterviewDocumentFormData } from "../InterviewDocumentFormData";
import { GET_BUILDING_BY_AUDIT } from "../../Building/ReadService";
import { useAxiosInstance } from "../../../axios/AxiosService";

export interface AddContentResponseData {
    destination: string;
    encoding: string;
    fieldname: string;
    filename: string;
    mimetype: string;
    originalname: string;
    path: string;
    size: number;
}
export interface UpdateContentResponseData {
    file: {
        destination: string;
        encoding: string;
        fieldname: string;
        filename: string;
        mimetype: string;
        originalname: string;
        path: string;
        size: number;
    }
}

export const useAddNewContent = (folder,) => {
    const {instance} = useAxiosInstance({})
    const keycloak = useReactiveVar(keycloakInstanceVar);
    const addNewContent = useCallback((file: any, name: string) => {
        return new Promise<AddContentResponseData>(async (resolve, reject) => {
            if (process.env.REACT_APP_API_FILE_URI && keycloak) {
                let formData = new FormData()
                await keycloak.updateToken(10)
                const jwtToken = keycloak.idToken
                formData.append('file', file)

                return instance.post(`${process.env.REACT_APP_API_FILE_URI}/file/?folder=${folder}&filename=${name}`,
                    formData, {
                    headers: {
                        Accept: 'application/json',
                        "Authorization": jwtToken ? `Bearer ${jwtToken}` : "",
                        "Content-Type": "multipart/form-data",
                    }
                }).then((response: { data: AddContentResponseData }) => {
                    if (response.data && response.data.path && response.data.path.length) {
                        let pathWithoutRootFolderArray = response.data.path.split("/")
                        pathWithoutRootFolderArray.shift()
                        const pathWithoutRootFolder = pathWithoutRootFolderArray && pathWithoutRootFolderArray.length ? pathWithoutRootFolderArray.join("/") : response.data.path
                        resolve({ ...response.data, path: pathWithoutRootFolder })
                    }
                })
            } else {
                console.error("variable environment for api file uri doesn't exist")
                reject()
            }
        })
    }, [keycloak, folder, instance])

    return { addNewContent }
}


export const useAddContent = () => {
    const keycloak = useReactiveVar(keycloakInstanceVar);
    const {instance} = useAxiosInstance({})
    const addContent = useCallback((file: any, name: string) => {
        return new Promise<AddContentResponseData>(async (resolve, reject) => {
            if (process.env.REACT_APP_API_FILE_URI && keycloak) {
                let formData = new FormData()
                await keycloak.updateToken(10)
                const jwtToken = keycloak.idToken
                formData.append('file', file)

                return instance.post(`${process.env.REACT_APP_API_FILE_URI}/file/?folder=interview_document&filename=${name}`,
                    formData, {
                    headers: {
                        Accept: 'application/json',
                        "Authorization": jwtToken ? `Bearer ${jwtToken}` : "",
                        "Content-Type": "multipart/form-data",
                    }
                }).then((response: { data: AddContentResponseData }) => {
                    if (response.data && response.data.path && response.data.path.length) {
                        let pathWithoutRootFolderArray = response.data.path.split("/")
                        pathWithoutRootFolderArray.shift()
                        const pathWithoutRootFolder = pathWithoutRootFolderArray && pathWithoutRootFolderArray.length ? pathWithoutRootFolderArray.join("/") : response.data.path
                        resolve({ ...response.data, path: pathWithoutRootFolder })
                    } else {
                        console.warn("no data from api file")
                        reject()
                    }
                }).catch(error => {
                    console.error(error)
                    reject()
                })
            } else {
                console.error("variable environment for api file uri doesn't exist")
                reject()
            }
        })
    }, [keycloak, instance])

    const updateContent = useCallback((file: any, name: string, filename: string) => {
        const pathSegment = filename.split("/")
        const url = pathSegment[pathSegment.length - 1]
        return new Promise<UpdateContentResponseData>(async (resolve, reject) => {
            if (process.env.REACT_APP_API_FILE_URI && keycloak) {
                let formData = new FormData()
                await keycloak.updateToken(10)
                const jwtToken = keycloak.idToken
                formData.append('file', file)

                return instance.put(`${process.env.REACT_APP_API_FILE_URI}/file/${url}/?folder=interview_document`,
                    formData, {
                    headers: {
                        Accept: 'application/json',
                        "Authorization": jwtToken ? `Bearer ${jwtToken}` : "",
                        "Content-Type": "multipart/form-data",
                    }
                }).then((response: { data: UpdateContentResponseData }) => {
                    if (response.data && response.data.file && response.data.file.path && response.data.file.path.length) {
                        let pathWithoutRootFolderArray = response.data.file.path.split("/")
                        pathWithoutRootFolderArray.shift()
                        const pathWithoutRootFolder = pathWithoutRootFolderArray && pathWithoutRootFolderArray.length ? pathWithoutRootFolderArray.join("/") : response.data.file.path
                        resolve({ file: { ...response.data.file, path: pathWithoutRootFolder } })
                    } else {
                        console.warn("no data from api file")
                        reject()
                    }
                }).catch(error => {
                    console.error(error)
                    reject()
                })
            } else {
                console.error("variable environment for api file uri doesn't exist")
                reject()
            }
        })
    }, [keycloak, instance])

    return { addContent, updateContent }
}

export const useUpdateInterviewDocument = (audit) => {

    const { setNotification } = useContext(AppContext);
    const { updateContent } = useAddContent()
    const auditId = audit?.id
    const [UpdateInterviewDocument] = useMutation<update_interview_document_response, update_interview_document_variables>(
        UPDATE_INTERVIEW_DOCUMENT,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                { query: GET_AUDIT_BY_PK, variables: { id: auditId } },
                { query: READ_CURRENT_AUDITS },
                {
                    query: READ_ALL_DONE_AUDITS_SORTED,
                    variables: { order_by: { last_updated_date: 'desc' } },
                },
                { query: GET_BUILDING_BY_AUDIT, variables: { audit_id: auditId } },

            ],
            onError: () => {
                setNotification({
                    key: 'common:generalError',
                    type: NotificationType.ERROR,
                });
            },
        }
    )

    const onUpdateInterviewDocument = (id: number, file: any, name: string, auditVar: any): Promise<any> => {
        return new Promise(resolve => {
            let url = auditVar?.interview_document?  auditVar?.interview_document[0]?.url : null
              if (url) { 
                updateContent(file, name, url).then((data) => {
                    UpdateInterviewDocument({ variables: { id, url: data.file.path } }).then(response => {
                        setNotification({ key: 'common:savedSuccess', type: NotificationType.SUCCESS });
                        resolve(response);
                    },
                    );
                }).catch(() => {
                    setNotification({ key: 'common:generalError', type: NotificationType.ERROR })
                })
             }  
        });
    };

    return { onUpdateInterviewDocument }
}

export const useAddInterviewDocument = (audit) => {
    const auditId = audit?.id
    const { setNotification } = useContext(AppContext);
    const { addContent } = useAddContent()
    const [AddInterviewDocument] = useMutation<add_interview_document_response, add_interview_document_variables>(
        ADD_INTERVIEW_DOCUMENT,
        {
            awaitRefetchQueries: true,
            refetchQueries: [
                { query: GET_AUDIT_BY_PK, variables: { id: auditId } },
                { query: READ_CURRENT_AUDITS },
                {
                    query: READ_ALL_DONE_AUDITS_SORTED,
                    variables: { order_by: { last_updated_date: 'desc' } },
                },
                { query: GET_BUILDING_BY_AUDIT, variables: { audit_id: auditId } },

            ],
            onError: () => {
                setNotification({
                    key: 'common:generalError',
                    type: NotificationType.ERROR,
                });
            },
        }
    )

    const onAddInterviewDocument = (id: number, file: any, name: string): Promise<any> => {
        return new Promise(resolve => {
            addContent(file, name).then((data) => {
                let interviewDocumentData: InterviewDocumentFormData = {
                    url: data.path,
                    audit_id: id
                }
                AddInterviewDocument({ variables: { object: interviewDocumentData } }).then(response => {
                    setNotification({ key: 'common:savedSuccess', type: NotificationType.SUCCESS });
                    resolve(response);
                },
                );
            }).catch(() => {
                setNotification({ key: 'common:generalError', type: NotificationType.ERROR })
            })
        });
    };

    return { onAddInterviewDocument }
}

