import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  listItem: {
    transition: 'background-color 0.5s',
    backgroundColor: theme.palette.primary.main,
    border: '1px solid',
    borderBlockStyle: 'none',
    color: 'white',
    '&$selected': {
      backgroundColor: '#293d46',
      color: 'white',
    },
    '&$selected:hover': {
      backgroundColor: '#293d46',
      color: 'white',
    },
    '&:hover': {
      backgroundColor: '#293d46',
      color: 'white',
    },
  },
  listItemValidated: {
    transition: 'background-color 0.5s',
    backgroundColor: '#00802b',
    border: '1px solid',
    borderBlockStyle: 'none',
    color: 'white',
    '&$selected': {
      backgroundColor: '#00802b',
      color: 'white',
    },
    '&$selected:hover': {
      backgroundColor: '#00802b',
      color: 'white',
    },
    '&:hover': {
      backgroundColor: '#00802b',
      color: 'white',
    },
  },
  selected: {},
  themeValidatedColor: { backgroundColor: theme.palette.success.main },
  themeValidatedDefaultColor: { backgroundColor: theme.palette.primary.main },
}));
