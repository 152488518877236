import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  control: {
    width: '100%',
  },
  title: {
    flexGrow: 1,
    color: '#141414',
  },
  grey: {
    backgroundColor: '#d3d3d3',
    marginTop: 10,
    '&:hover': {
      background: '#afafaf',
    },
    color: '#141414',
  },
}));

export { useStyles };
