import React from 'react';
import { audit_data } from '../../../../../services/Audit/AuditDataModel';
import { OrderBy } from '../../../../Entity/EntityRead/EntityReadComponent/EntityReadComponent';
import PaginationComponent from '../../../../Shared/Pagination/PaginationComponent';
import AuditTableComponent from '../../AuditTableComponent';

export interface AuditReadAllViewProps {
  audits: Array<audit_data>;
  onUpdate: (id: number) => void;
  onSort: (sortItem: OrderBy) => void;
  sortByItems: OrderBy[];
}

export const testIds = {
  buttonOnCreate: 'button-create-audit',
  dialogConfirmDelete: 'dialog-confirm-delete',
  dialogConfirmClose: 'dialog-confirm-close',
  searchFieldComponent: 'search-field-component',
  paginationComponent: 'audit-pagination-component',
};

/**
 * Description - View of AudtRead component
 * @param param0
 */
const AuditReadAllView: React.FC<AuditReadAllViewProps> = ({
  audits,
  onUpdate,
  onSort,
  sortByItems,
}) => {
  return (
    <PaginationComponent items={audits} itemsPropsName="audits">
      <AuditTableComponent
        audits={audits}
        onUpdate={onUpdate}
        onSort={onSort}
        sortByItems={sortByItems}
        fromListOfAudits={true}
      />
    </PaginationComponent>
  );
};

export default AuditReadAllView;
