import { gql } from '@apollo/client';


export const READ_AUDIT_PHOTO_BY_AUDIT_ID_WHITH_OVERVIEW_DETAIL  = gql`
query qat_audit(
  $audit_id: Int!
)
  {
    qat_audit(where: {id: {_eq: $audit_id}}) {
      id
    audit_date
    location_entity {
      name
    }    
      entity {
      abbreviation
      label
    }
    checklists(where: {checklist_themes: {checklist_theme_sections: {audit_photo_metadata: {audit_photos: {id: {_is_null: false}}}}}},
     order_by :{building : {index:asc}}) {
      building {
          id
          index
        address {
          box_number
          city
          house_number
          street
        }
      }
      checklist_themes(where: {checklist_theme_sections: {audit_photo_metadata: {id: {_is_null: false}}}}) {
        checklist_theme_sections {
          name_fr
          name_nl
          specification
          audit_photo_metadata {
            audit_photos {
              url
            }
          }

        }
      }

    }
  }
}`
