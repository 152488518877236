import React from "react"
import { useGetContentService } from "../../../../../services/InterviewDocument/InterviewDocumentRead/InterviewDocumentRead"
import { InterviewDocumentUploadFormInputs } from "../InterviewDocumentUploadService"
import { useStyles } from './InterviewDocumentUploadPreviewStyle'

export interface InterviewDocumentUploadPreviewProps {
    url: string | null
    values: InterviewDocumentUploadFormInputs
}

export const testIds = {
    documentContent: "interview_document_upload_preview_document_content"
}

const InterviewDocumentUploadPreview = ({ url, values }: InterviewDocumentUploadPreviewProps) => {
    const classes = useStyles()

    const { content } = useGetContentService({ url })

    let imgPreview;
    if (values?.file) {
        imgPreview = URL.createObjectURL(new Blob([values.file], { type: "application/pdf" }))
    }

    return (
        <div>
            {
                ((content) || imgPreview) &&
                <div
                    className={classes.contentContainer}
                    data-testid={testIds.documentContent}
                >
                    {content && imgPreview && <iframe
                        width={1500}
                        height={900}
                        title="interview_document_preview"
                        src={imgPreview}

                    />}
                    {content && !imgPreview && <iframe
                        width={1500}
                        height={900}
                        title="interview_document_preview"
                        src={content ? content : ""}

                    />}
                    {imgPreview && !content && <iframe
                        width={1500}
                        height={900}
                        title="interview_document_preview"
                        src={imgPreview}

                    />}
                </div>
            }
        </div>
    )

}

export default InterviewDocumentUploadPreview