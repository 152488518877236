import { AppBar, Toolbar, Typography, IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import clsx from 'clsx';
import React from 'react';
import LanguageMenuComponent from '../LanguageMenu/LanguageMenuComponent/LanguageMenuComponent';
import UserMenuComponent from '../UserMenu/UserMenuComponent/UserMenuComponent';
import { useStyles } from '../AppBarStyle';

export const testIds = {
  buttonToOpenDrawer: 'button-open-drawer',
};

export interface AppBarComponentProps {
  open: boolean;
  setOpen: () => void;
}

const AppBarComponent: React.FC<AppBarComponentProps> = ({ open, setOpen }) => {
  const classes = useStyles();

  return (
    <AppBar
      elevation={0}
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: open,
      })}
    >
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="end"
          onClick={setOpen}
          className={clsx(open ? classes.hide : classes.open)}
          data-testid={testIds.buttonToOpenDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="h6" noWrap className={classes.title}>
          Quality Audit Tool
        </Typography>
        <UserMenuComponent />
        <LanguageMenuComponent />
      </Toolbar>
    </AppBar>
  );
};

export default AppBarComponent;
