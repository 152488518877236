export function handleReorder<T>(
  list: T[],
  startIndex: number,
  endIndex: number,
) {
  const result: T[] = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
}

export function sortAlphaNum(a: any, b: any, property: string) {

  var reA = /[^a-zA-Z]/g;
  var reN = /[^0-9]/g;

  const [first, second] = !property
    ? [a, b]
    : [a[property], b[property]]
  var firstA = first.replace(reA, "");
  var secondA = second.replace(reA, "");
  if (firstA === secondA) {
    var firstN = parseInt(first.replace(reN, ""), 10);
    var secondN = parseInt(second.replace(reN, ""), 10);
    return firstN === secondN ? 0 : firstN > secondN ? 1 : -1;
  } else {
    return firstA > secondA ? 1 : -1;
  }
}
