import { Button, CircularProgress, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../../Shared/Container/PageContainer';
import { useStyles } from '../AdminDocumentUploadStyle';
import clsx from 'clsx';
import { useAdminDocumentUploadService } from './AdminDocumentUploadFormService';
import AdminDocumentUploadPreview from '../AdminDocumentUploadPreview/AdminDocumentUploadPreview';

export const testIds = {
  documentSelectedMenu: "document-selected-menu",
  fileUploadInput: "file-upload-input"
}
export interface AdminDocumentUploadFormProps {
    selectDocumentToPreview: (id: number) => void
}

/**
 * This component is responsible for displaying the normes
 */
const AdminDocumentUploadForm = () => {

  const { values, onChange, onSubmit, errors, options, loading, isChangedFile, documentIdSelected } = useAdminDocumentUploadService()

  const classes = useStyles()
  const { t } = useTranslation();

  const errorsMessage: { id?: string, file?: string } = useMemo(() => {
    const idMessage = errors?.id === "required"
      ? t("adminDocument:documentSelectInputValidationRequired")
      : undefined
    const fileMessage = errors && errors.file === "required"
      ? t("adminDocument:fileUploadInputValidationRequired")
      : errors && errors.file === "type-pdf"
        ? t("adminDocument:fileUploadInputValidationTypePdf")
        : undefined
    return { id: idMessage, file: fileMessage }
  }, [errors, t])

  return (
    <PageContainer>
      {
        !loading
          ? <form onSubmit={onSubmit}>
            <h1>{t("adminDocument:titleForm")}</h1>
            <Grid
              container
              direction="column"
              justifyContent="space-evenly"
              alignItems="center"
              spacing={3}
            >
              <Grid
                item
                className={classes.control}
              >
                <FormControl
                  className={classes.control}
                  error={errors?.id !== undefined ? true : false}
                >
                  <InputLabel id="admin-document-select-label">{t("adminDocument:documentSelectInputLabel")}</InputLabel>
                  <Select
                    data-testid={testIds.documentSelectedMenu}
                    labelId="admin-document-select-label"
                    id="admin-document-select"
                    value={values.id}
                    onChange={onChange('id')}
                  >
                    {options.map((option) => (
                      <MenuItem key={option.key} value={option.value}>{option.label}</MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{errorsMessage.id ? errorsMessage.id : ""}</FormHelperText>
                </FormControl>

              </Grid>
              <Grid
                item
                className={classes.control}
              >
                {/*Input label is outside form control because there are css problem between button for input (component label) and label itself*/}
                <InputLabel
                  id="document-mix-select-label"
                  className={clsx(classes.labelInputUploadFile, { [classes.error]: errors?.file !== undefined })}
                >{t("adminDocument:fileUploadInputLabel")}</InputLabel>
                <FormControl
                  className={classes.control}
                  error={errors?.file !== undefined ? true : false}
                >
                  <div className={classes.containerInputUploadFile}>

                    <Button
                      variant="contained"
                      component="label"
                      className={classes.buttonUploadFile}
                    >
                      {t("adminDocument:selectFileButtonLabel")}
                      <input
                        data-testid={testIds.fileUploadInput}
                        id="document-upload"
                        name="document-upload"
                        aria-label="document-upload-label"
                        type="file"
                        accept="application/pdf"
                        onChange={onChange("file")}
                        hidden
                      />
                    </Button>
                    < Typography className={classes.fileName} variant="body1">{values.file ? values.file.name : t("adminDocument:noFileUploadMessage")}</Typography>
                  </div>

                  <FormHelperText>{errorsMessage.file
                    ? errorsMessage.file
                    : ""
                  }</FormHelperText>
                </FormControl>
              </Grid>
              <Grid item className={classes.control}>
                <Button
                  type="submit"
                  className={classes.buttonSubmit}
                >
                  {t("adminDocument:submit")}
                </Button>
              </Grid>
            </Grid>
          </form>
          : <CircularProgress />
      }
       {(documentIdSelected || isChangedFile || values ) &&
      <AdminDocumentUploadPreview
        documentId={documentIdSelected}
        values={values}
        isChangedFile={isChangedFile}
      />
    }
    </PageContainer>
  )
}

export default AdminDocumentUploadForm;
