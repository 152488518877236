import { CircularProgress } from '@material-ui/core';
import React, { Fragment } from 'react';
import { audit_data } from '../../../../services/Audit/AuditDataModel';
import { building_data } from '../../../../services/Building/BuildingDataModel';
import AuditFormComponent from '../../AuditForm/AuditFormComponent';
import { AuditFormData } from '../../AuditForm/AuditFormPresenter';
export interface AuditUpdateViewProps {
  loading: boolean;
  onUpdateAudit: (dataForm: AuditFormData) => Promise<number>;
  audit?: audit_data;
  building?: building_data;
}

const AuditUpdateView: React.FC<AuditUpdateViewProps> = ({
  loading,
  onUpdateAudit,
  audit,
  building,
}) =>
  loading ? (
    <CircularProgress />
  ) : (
    <Fragment>
      <AuditFormComponent
        onSubmitAudit={onUpdateAudit}
        audit={audit}
        building={building}
        title="audit:updateAudit"
        submitButtonLabel="common:update"
      />
    </Fragment>
  );

export default AuditUpdateView;
