import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  buildingNumber: {
    marginRight: 15,
  },
  addBuildingButton: {
    marginTop: 25,
  },
  buildingContainer: {
    marginTop: 25,
  },
  editButton: {
    marginRight: 25,
  },
   deleteButton: {
    marginRight: 380,
  }, 
  buildingDocumentButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 450,
  },
  updateChecklistButton: {
    margin: 10,
    backgroundColor: theme.palette.grey[300],
    paddingLeft: theme.spacing(3),
  },
  onOpenPdf: {
    color: theme.palette.warning.main,
  },
}));
