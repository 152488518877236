import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4),
  },
  icon: {
    fontSize: 100,
  },
  button: {
    margin: theme.spacing(4),
    color: theme.palette.error.main,
  },
  item: {
    textAlign: 'center',
  },
}));
