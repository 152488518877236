import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  control: {
    width: '100%',
  },
  title: {
    flexGrow: 1,
    color: '#141414',
  },
}));

export { useStyles };
