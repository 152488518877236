import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { audit_data } from '../../../../../services/Audit/AuditDataModel';
import { useGetAllEntities } from '../../../../../services/Entity/ReadService/EntityReadService';
import { location_data } from '../../../../../services/Location/LocationDataModel';
import { AuditFormMainInfoViewProps } from '../AuditFormMainInfoView/AuditFormMainInfoViewProps';
import _ from "lodash"

export interface AuditFormMainInfoPresenterProps {
  audit?: audit_data;
  isReadOnlyMode?: boolean;
}

/**
 * Description - service to manage AuditFormMainInfoForm
 */
export const useAuditFormMainInfoPresenter = ({
  audit,
  isReadOnlyMode,
}: AuditFormMainInfoPresenterProps): AuditFormMainInfoViewProps => {
  const [locationOptions, setLocationOptions] = useState<Array<location_data>>(
    [],
  );
  const { watch, control, errors, setValue } = useFormContext();
  const { data: entityData, loading: entityLoading } = useGetAllEntities();
  const [disableEntity, setDisableEntity] = useState(false);
  const [disableLocation, setDisableLocation] = useState(false);

  // watch to enable re-render when entity_id is changed
  const watchEntityId = watch('entity_id');

  useEffect(() => {
    if (!audit?.location_entity.id) {
      setValue('location_id', '');
    }
  }, [watchEntityId, audit, setValue]);

  useEffect(() => {
    const entity_locations = entityData?.find(
      entity => entity.id === watchEntityId,
    )?.location_entities;
    if (entity_locations) setLocationOptions(_.sortBy(entity_locations, 'name'));
    // eslint-disable-next-line
  }, [watchEntityId, entityData]);

  useEffect(() => {
    if (audit?.entity?.id) setDisableEntity(true);
    if (audit?.location_entity?.id || !watchEntityId) setDisableLocation(true);
    else setDisableLocation(false);
  }, [audit, watchEntityId]);

  const loadingLocationOptions: boolean = useMemo(
    () => (watchEntityId && !locationOptions ? true : false),
    [watchEntityId, locationOptions],
  );

   const sortedEntityOptions = useMemo( () => {
    return _.sortBy(entityData, 'label')
  }, [entityData]) 

  return {
    disableEntity,
    disableLocation,
    control,
    entityOptions: sortedEntityOptions,
    locationOptions,
    loadingEntityOptions: entityLoading,
    loadingLocationOptions,
    errors,
    isReadOnlyMode,
  };
};
