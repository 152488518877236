import React, { useCallback } from 'react';
import { useCreateAudit } from '../../../../services/Audit/WriteService/AuditWriteService';
import AuditFormComponent from '../../AuditForm/AuditFormComponent/AuditFormComponent';
import { AuditFormData } from '../../AuditForm/AuditFormPresenter';


export interface AuditCreateComponentProps {
  onUpdate: (id: number) => void;
}

/**
 * Description - Component to create an audit
 */
const AuditCreateComponent: React.FC = () => {
  const { onCreate: onCreateAudit } = useCreateAudit();

  const handleSubmitAudit = useCallback(
    async (audit: AuditFormData): Promise<number> => {
      return onCreateAudit(audit);
    },
    [onCreateAudit],
  );

  return (
    <AuditFormComponent
      title="audit:createAudit"
      submitButtonLabel="common:validate"
      onSubmitAudit={handleSubmitAudit}
      audit={undefined}
      fromCreateAuditComponent={true}
    />
  );
};

export default AuditCreateComponent;
