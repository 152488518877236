import { gql } from '@apollo/client';

/**
 * Description - graphql query to create a location
 */
export const UPDATE_LOCATION = gql`
  mutation update_qat_location_entity_by_pk(
    $id: Int!
    $qat_location_entity_set_input: qat_location_entity_set_input!
  ) {
    update_qat_location_entity_by_pk(
      pk_columns: { id: $id }
      _set: $qat_location_entity_set_input
    ) {
      id
    }
  }
`;
