import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { building_data } from '../../../../services/Building/BuildingDataModel';
import { BuildingFormData } from '../../BuildingForm/BuildingFormPresenter';
import BuildingUpdateView from '../BuildingUpdateView/BuildingUpdateView';
import { useHistory } from 'react-router-dom';
import { useUpdateBuilding } from '../../../../services/Building/UpdateService/BuildingUpdateService';
import routes from '../../../../routes';
import { useGetAuditById } from '../../../../services/Audit/ReadService/AuditReadService';
import { audit_data } from '../../../../services/Audit/AuditDataModel';
import { useGetBuildingById } from '../../../../services/Building/ReadService';
import { useGetTemplateByCategoryIdAndSubCategoryId } from '../../../../services/TemplateChecklist/TemplateChecklistReadService/TemplateChecklistReadService';
import { useUpdateChecklistWithTemplate } from '../../../../services/Checklist/ChecklistWriteService/ChecklistWriteService';
import { useBrowserLocationState } from '../../../Shared/BrowserLocationState/BrowserLocationState';
export interface BuildingUpdateComponentProps {
  building?: building_data;
  parentPath: string;
}

const BuildingUpdateComponent: React.FC<BuildingUpdateComponentProps> = ({
  parentPath,
}) => {
  const history = useHistory();
  
  const currentLocationState = useBrowserLocationState<{audit: any, building_id: number}>({keysRequired: ["audit", "building_id"], storedInCache: false})

  const audit_id = useMemo(
    () => (currentLocationState?.audit?.id ? currentLocationState?.audit?.id : -1),
    [currentLocationState],
  );

  const building_id: number = useMemo(() => {
    if (currentLocationState?.building_id) {
      return currentLocationState.building_id;
    } else {
      console.error('location.state.building_id is not injected');
      return -1;
    }
  }, [currentLocationState]);
  const { onUpdate } = useUpdateBuilding({ audit_id });

  const { getAuditById } = useGetAuditById();
  const { data, loading: loadingBuilding } = useGetBuildingById({
    id: building_id,
  });
  const {
    getTemplateByCategoryIdAndSubCategoryId,
  } = useGetTemplateByCategoryIdAndSubCategoryId();
  const { onUpdateChecklistWithTemplate } = useUpdateChecklistWithTemplate({
    checklist_id: data?.checklist?.id,
  });

  const [audit, setAudit] = useState<audit_data>();

  const handleUpdateBuilding = useCallback(
    ({
      building,
      haveToUpdateChecklist,
    }: {
      building: BuildingFormData;
      haveToUpdateChecklist?: boolean;
    }) => {
      building.id = building_id;
      onUpdate(building).then(response => {
        if (haveToUpdateChecklist) {
          if (data && data.checklist) {
            const newSubCategoryLocation =
              typeof building.sub_category_location_id === 'string'
                ? parseInt(building.sub_category_location_id)
                : building.sub_category_location_id;
            getTemplateByCategoryIdAndSubCategoryId(
              data.checklist.template_checklist.category_location_id,
              newSubCategoryLocation,
            ).then(template => {
              // we have to remake condition inside promise
              if (data.checklist) {
                onUpdateChecklistWithTemplate({
                  template,
                  checklist: data.checklist,
                });
              }
            });
          }
        }
        history.push({
          pathname: `${parentPath}${routes.editAuditMainInfo.path}`,
          state: { audit: currentLocationState?.audit },
        });
      });
    },
    [
      building_id,
      data,
      history,
      currentLocationState,
      onUpdate,
      onUpdateChecklistWithTemplate,
      getTemplateByCategoryIdAndSubCategoryId,
      parentPath,
    ],
  );

  useEffect(() => {
    getAuditById(audit_id).then(_audit => setAudit(_audit));
  }, [audit_id, getAuditById]);

  const loading: boolean = useMemo(() => {
    return !loadingBuilding && audit ? false : true;
  }, [loadingBuilding, audit]);

  return (
    <BuildingUpdateView
      audit={audit}
      building={data}
      onUpdateBuilding={handleUpdateBuilding}
      parentPath={parentPath}
      loading={loading}
    />
  );
};

export default BuildingUpdateComponent;
