import { gql } from '@apollo/client';

/**
 * Description - graphql query to update a building
 */
export const UPDATE_BUILDING = gql`
  mutation update_qat_building_by_pk(
    $id: Int!
    $qat_building_set_input: qat_building_set_input!
  ) {
    update_qat_building_by_pk(
      pk_columns: { id: $id }
      _set: $qat_building_set_input
    ) {
      id
    }
  }
`;

/**
 * Description - graphql query to update a building field value
 */
export const UPDATE_BUILDING_FIELD_VALUE_BY_BUILDING = gql`
  mutation update_qat_building_field_values(
    $building_id: Int!
    $category_location_form_field_config_id: Int!
    $qat_building_field_values_set_input: qat_building_field_values_set_input!
  ) {
    update_qat_building_field_values(
      where: {
        building_id: { _eq: $building_id }
        _and: {
          category_location_form_field_config_id: {
            _eq: $category_location_form_field_config_id
          }
        }
      }
      _set: $qat_building_field_values_set_input
    ) {
      affected_rows
    }
  }
`;
