import { gql } from '@apollo/client';

/**
 * Description - graphql query to create a checklist
 */
export const INSERT_CHECKLIST = gql`
  mutation insert_qat_checklist_one($object: qat_checklist_insert_input!) {
    insert_qat_checklist_one(object: $object) {
      id
      checklist_themes(order_by: { theme_template_checklist: { index: asc } }) {
        id
        comment
        not_applicable
        checklist_theme_sections(order_by: { id: asc }) {
          id
          specification
          checklist_theme_id
          theme_template_checklist_id
          theme_template_checklist {
            id
            short_name_nl
            long_name_nl
            short_name_fr
            long_name_fr
            not_applicable_option
            duplicable
            index
          }
          checklist_theme_sub_sections(order_by: { index: asc }) {
            id
            checklist_theme_items(
              order_by: { template_theme_item: { index: asc } }
            ) {
              id
              not_application
              has_interview
              comment
              is_conform
              template_theme_item_id
              template_theme_item {
                id
                title_nl
                title_fr
                information_nl
                information_fr
                norm_code
                not_application_option
                index
                template_theme_item_remarks {
                  id
                  remark_text_nl
                  remark_text_fr
                  index
                }
              }
            }
            sub_theme_template_checklist {
              id
              name_fr
              name_nl
              not_applicable_option
              index
            }
          }
          checklist_theme_items(
            order_by: { template_theme_item: { index: asc } }
          ) {
            id
            not_application
            has_interview
            comment
            is_conform
            template_theme_item {
              id
              title_nl
              title_fr
              information_nl
              information_fr
              norm_code
              not_application_option
              index
              template_theme_item_remarks {
                id
                remark_text_nl
                remark_text_fr
                index
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * Description - graphql query to create a checklist
 */
export const UPSERT_CHECKLIST = gql`
  mutation insert_qat_checklist_one($object: qat_checklist_insert_input!) {
    insert_qat_checklist_one(
      object: $object
      on_conflict: {
        constraint: checklist_template_id_building_id_audit_id_key
        update_columns: [checklist_themes]
      }
    ) {
      id
    }
  }
`;

/**
 * Description - graphql query to update a checklist
 */
export const UPDATE_CHECKLIST = gql`
  mutation update_qat_checklist_by_pk(
    $id: Int!
    $object: qat_checklist_set_input!
  ) {
    update_qat_checklist_by_pk(pk_columns: { id: $id }, _set: $object) {
      id
      comment
    }
  }
`;

export const UPDATE_CHECKLIST_BY_TEMPLATE = gql`
  mutation update_checklist_by_template(
    $id: Int!
    $template_id: Int!
    $checklist_themes: [qat_checklist_theme_insert_input!]!
  ) {
    delete_qat_checklist_theme(where: { checklist_id: { _eq: $id } }) {
      affected_rows
    }
    update_qat_checklist(
      where: { id: { _eq: $id } }
      _set: { template_id: $template_id }
    ) {
      affected_rows
    }
    insert_qat_checklist_theme(objects: $checklist_themes) {
      affected_rows
    }
  }
`;
