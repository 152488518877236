import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  control: {
    width: '100%',
  },
  title: {
    flexGrow: 1,
    color: '#141414',
  },
  submitButton: {
    marginTop: 25,
  },
  interviewLine: {
    marginTop: 25,
  },
  interviewTitle: {
    marginLeft: 18,
    fontSize: '0.875rem',
    fontFamily: 'Poppins',
  },
  onOpenPdf: {
    color: theme.palette.warning.main,
    marginLeft: 75,
  },
  interviewPdf: {
    marginLeft: 75,
  },
  interviewDocumentButtonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 525,
    marginTop : 10,
  },
}));

export { useStyles };
