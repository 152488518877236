import gql from "graphql-tag";

export interface get_all_admin_document_response {
  qat_admin_document: get_all_admin_document_data[]
}

export interface get_all_admin_document_data {
  id: number;
  name_file: string,
  tag: string,
  description: string,
  url?: string;
  title_fr: string;
  title_nl: string;
  type: "application/pdf" | "application/msword",
  language: {
    application_code: string
  }
}

export const GET_ALL_ADMIN_DOCUMENT = gql`
query get_all_admin_document {
    qat_admin_document(order_by: {tag: asc}){
      id
      name_file
      tag,
      description,
      url,
      title_fr,
      title_nl,
      language {
        application_code
      }
    }
  }`

export const GET_NORM_DOCUMENT = gql`
  query get_norm_document {
      qat_admin_document( where:{tag:{_eq: "norm_pdf"}}){
        id
        name_file
        tag,
        description,
        url,
        title_fr,
        title_nl,
        language {
          application_code
        }
      }
    }`

export const GET_ITEM_NOT_APPLICABLE_BY_CATEGORY_DOCUMENT = gql`
  query get_item_not_applicable_document ($category: String!){
      qat_admin_document( where:{tag:{_eq: $category}}){
        id
        name_file
        tag,
        description,
        url,
        title_fr,
        title_nl,
        language {
          application_code
        }
      }
    }`


export interface get_admin_document_by_pk_variables { id: number }

export interface get_admin_document_by_pk_response {
  qat_admin_document_by_pk: get_admin_document_by_pk_data
}

export interface get_admin_document_by_pk_data {
  id: number,
  url: string,
  title_fr: string
  title_nl: string,
  last_updated_date: string | Date
}
export const GET_ADMIN_DOCUMENT_BY_PK = gql`
    query get_admin_document_by_pk ($id: Int!){
      qat_admin_document_by_pk(id: $id){
        id,
        url,
        title_fr,
        title_nl,
        last_updated_date
      }
    }
`