import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
  },
  section: {
    borderBottom: '2px solid #293d46',
  },
  leftPart: {
    display: 'flex',
    alignItems: 'center',
  },
  centerPart: {
    display: 'flex',
    alignItems: 'left',
  },
  rightPart: {
    display: 'flex',
    alignItems: 'right',
  },
  title: {
    color: '#cf012c',
    fontSize: '0.875rem',
  },
  cellInput: {
    padding: theme.spacing(0.5),
    width: 160,
  },
  button: {
    padding: theme.spacing(1.5),
  },
  collapseHidden: {
    height: 0,
  },
  conform: {
    color: '#4caf50',
  },
  notConform: {
    color: '#FF0000',
  },
  default: {
    color: '#808080',
  },
  photoPart: {
    display: 'flex',
    alignItems: 'right',
  },
  conformButton: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(0.75),
  },
  photoButton: {},
  collapseButton: {
    padding: theme.spacing(2),
  },
}));

export { useStyles };
