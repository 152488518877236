import { ApolloError, useQuery, useReactiveVar} from "@apollo/client"
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import MainAppContext from '../../../AppContext';
import { useAxiosInstance } from "../../../axios/AxiosService";
import { NotificationType } from "../../../components/Notification/NotificationComponent";
import { GET_ALL_ADMIN_DOCUMENT, get_all_admin_document_response, GET_NORM_DOCUMENT, GET_ITEM_NOT_APPLICABLE_BY_CATEGORY_DOCUMENT, GET_ADMIN_DOCUMENT_BY_PK, get_admin_document_by_pk_response, get_admin_document_by_pk_variables } from "./AdminDocumentQuery";
import { keycloakInstanceVar } from "../../../graphql/ClientProvider";
export type norm_document_result = { [language: string]: norm_document_request }
export type norm_document_request = string

export const ROUTE_DOWNLOAD_DOCUMENT = "download"

export const useGetContent = () => {
    const {instance} = useAxiosInstance({})
    const keycloak = useReactiveVar(keycloakInstanceVar);
    const getContent = useCallback(async (url: string) => {

        return new Promise<string>(async (resolve, reject) => {
            if (process.env.REACT_APP_API_FILE_URI) {
                if (keycloak && keycloak.tokenParsed && keycloak.idToken) {
                    await keycloak.updateToken(180)
                    const jwtToken = keycloak.idToken

                    return instance.get(`${process.env.REACT_APP_API_FILE_URI}/${ROUTE_DOWNLOAD_DOCUMENT}/${url}`, {
                        headers: {
                            Accept: 'application/pdf',
                            "Authorization": jwtToken ? `Bearer ${jwtToken}` : "",
                        },
                        responseType: "arraybuffer"
                    }).then(response => {
                        resolve(URL.createObjectURL(new Blob([response.data], { type: "application/pdf" })))
                    }
                    )
                        .catch(error => {
                            console.error(error)
                            reject()
                        })
                }
                
            } else {
                console.error("variable environment for api file uri doesn't exist")
                reject()
            }
        })

    }, [instance, keycloak])

    return { getContent }
}

export const useGetPhotoContentDataFromApi = () => {
    const {instance} = useAxiosInstance({})
    const keycloak = useReactiveVar(keycloakInstanceVar);
    const getContentByUrl = useCallback(async (url: string, type = 'image/jpeg') => {
        return new Promise<string>(async (resolve, reject) => {
            if (process.env.REACT_APP_API_FILE_URI) {
                if (keycloak && keycloak.tokenParsed && keycloak.idToken) {
                    await keycloak.updateToken(180)
                    const jwtToken = keycloak.idToken
                    return instance.get(`${process.env.REACT_APP_API_FILE_URI}/download/${url}`, {
                        headers: {
                            Accept: type,
                            "Authorization": jwtToken ? `Bearer ${jwtToken}` : "",
                        },
                        responseType: "blob"
                    }).then(response => {
                        resolve(URL.createObjectURL(response.data))
                    }
                    )
                    .catch(error =>{
                        console.error(error) 
                        reject()
                    })
                } else {
                    console.error("Error to get token from keycloak")
                    reject()
                }    
            } else {
                console.error("variable environment for api file uri doesn't exist")
                reject()
            }
        })

    }, [keycloak, instance])

    return { getContentByUrl }
}

export type get_content_response = Array<get_content_data>

export interface get_content_data {
    name: string;
    path: string;
}

/**
 * Description, to get all document from API
 * @returns {data: get_all_document_mix_response, loading: boolean}
 */
export const useGetAllAdminDocument = () => {

    const { setNotification } = useContext(MainAppContext);

    const { data, loading } = useQuery<get_all_admin_document_response>(GET_ALL_ADMIN_DOCUMENT, {
        fetchPolicy: "network-only",
        onError() {
            setNotification({
                key: 'common:generalError',
                type: NotificationType.ERROR,
            });
        },
    })

    return { data: data && data.qat_admin_document ? data.qat_admin_document : [], loading }
}

/**
 * Description, to get all document from API
 * @returns {data: get_all_document_mix_response, loading: boolean}
 */
export const useGetNormDocumentWithContent = () => {
    const { setNotification } = useContext(MainAppContext);
    const [dataWithDocumentContent, setDataWithDocumentContent] = useState<norm_document_result>({})
    const { getContent } = useGetContent()
    const [loadingContent, setLoadingContent] = useState<boolean>(true)

    const { data, loading } = useQuery<get_all_admin_document_response>(GET_NORM_DOCUMENT, {
        fetchPolicy: "network-only",
        onError() {
            setNotification({
                key: 'common:generalError',
                type: NotificationType.ERROR,
            });
        },
    })

    useEffect(() => {
        if (!loading && data && data.qat_admin_document && data.qat_admin_document.length) {
            data.qat_admin_document.forEach(async (documentMainInformation) => {
                try {
                    if(documentMainInformation.url){
                        getContent(documentMainInformation.url).then(content => {
                            setDataWithDocumentContent((currentData: norm_document_result) => {
                                if (content) {
                                    currentData[documentMainInformation.language.application_code] = content
                                    setLoadingContent(false)
                                    return { ...currentData }
                                } else {
                                    setLoadingContent(false)
                                    return currentData
                                }
                            })
                        }).catch(error => {
                            setLoadingContent(false)
                            console.error(error)
                        })
                    } else {
                        console.error(`${documentMainInformation.name_file} admin document has not upload`)
                    }
                } catch (error) {
                    setLoadingContent(false)
                    console.error(error)

                }

            })

        } else {
            setDataWithDocumentContent({})
        }
    }, [data, loading, getContent])
    return { data: dataWithDocumentContent, loading: loadingContent }
}

export type item_not_applicable_document_result = { [language: string]: item_not_applicable_document_request }
export type item_not_applicable_document_request = string

/**
 * Description, to get all document from API
 * @returns {data: get_all_document_mix_response, loading: boolean}
 */
export const useGetItemNotApplicableDocumentWithContent = category => {

    const { setNotification } = useContext(MainAppContext);
    const [dataWithDocumentContent, setDataWithDocumentContent] = useState<item_not_applicable_document_result>({})
    const { getContent } = useGetContent()
    const [loadingContent, setLoadingContent] = useState<boolean>(true)

    const { data, loading } = useQuery<get_all_admin_document_response>(GET_ITEM_NOT_APPLICABLE_BY_CATEGORY_DOCUMENT, {
        variables: { category },
        fetchPolicy: "network-only",
        onError() {
            setNotification({
                key: 'common:generalError',
                type: NotificationType.ERROR,
            });
        },
    })

    useEffect(() => {
        if (!loading && data && data.qat_admin_document && data.qat_admin_document.length) {
            data.qat_admin_document.forEach(async (documentMainInformation) => {
                try {
                    if(documentMainInformation.url){
                        getContent(documentMainInformation.url).then(content => {
                            setDataWithDocumentContent((currentData: item_not_applicable_document_result) => {
                                if (content) {
                                    currentData[documentMainInformation.language.application_code] = content
                                    setLoadingContent(false)
                                    return { ...currentData }
                                } else {
                                    setLoadingContent(false)
                                    return currentData
                                }
                            })
                        }).catch(error => {
                            setLoadingContent(false)
                            console.error(error)
                        })
                    } else {
                        console.error(`${documentMainInformation.name_file} admin document has not upload`)
                    }
                } catch (error) {
                    setLoadingContent(false)
                    console.error(error)
                }


            })

        } else {
            setDataWithDocumentContent({})
        }
    }, [data, loading, getContent])
    return { data: dataWithDocumentContent, loading: loadingContent }
}

export interface GetAdminDocumentByPkServiceResult {
    data: {
        id: number
        url?: string | null,
        title_fr: string,
        title_nl: string,
        last_updated_date: | Date
    } | null,
    loading: boolean,
    error?: ApolloError
}
/**
 * Description - service to get admin document metadata and content
 * @param {id: number}
 * @returns 
 */
export const useGetAdminDocumentByPk = ({ id }: { id: number }):GetAdminDocumentByPkServiceResult => {
    const { setNotification } = useContext(MainAppContext);
    const { data, loading, error } = useQuery<get_admin_document_by_pk_response, get_admin_document_by_pk_variables>(GET_ADMIN_DOCUMENT_BY_PK,
        {
            variables: { id },
            skip: id === -1,
            onError() {
                setNotification({
                    key: 'common:generalError',
                    type: NotificationType.ERROR,
                });
            },
        }
    )

    const documentInfo: {
        id: number
        url?: string,
        title_fr: string,
        title_nl: string,
        last_updated_date: | Date
    } | null = useMemo(() => {
        return data && data.qat_admin_document_by_pk
            ? {
                ...data.qat_admin_document_by_pk,
                last_updated_date: new Date(data.qat_admin_document_by_pk.last_updated_date)
            }   
            : null
    },[data])
    return {data: documentInfo, loading, error}
}

export interface useGetContentServiceProps {
    url: string | null
}

export const useGetContentService = ({url}: useGetContentServiceProps) => {

    const [loading, setLoading] = useState<boolean>(() => url !== null ? true : false)
    const [content, setContent] = useState<string | null>(null)
    const {getContent} = useGetContent()

    useEffect(() => {
        if(url !== null){
            setLoading(true)
            getContent(url).then((response) => {
                setContent(response)
                setLoading(false)
            })
        } else {
            setContent(null)
            setLoading(false)
        }
    },[url, getContent])
    
    return {content, loading}
}
