import SearchComponent, {
  SearchComponentProps,
  testId,
} from './SearchComponent';
import { useStyles } from './SearchStyle';

export default SearchComponent;

export type { SearchComponentProps };

export { testId, useStyles };
