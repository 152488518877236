import { useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { INSERT_AUDIT } from './AuditWriteMutation';
import {
  READ_ALL_DONE_AUDITS_SORTED,
  READ_CURRENT_AUDITS,
} from '../ReadService/AuditReadQuery';
import { AuditStatus } from '../AuditDataModel';
import { AuditFormData } from '../../../components/Audit/AuditForm/AuditFormPresenter';
import { useCheckUniqueAudit } from '../ReadService/AuditReadService';
import { isContraintViolationError } from '../../../graphql/utils';

export const useCreateAudit = () => {
  const { setNotification, email, username } = useContext(MainAppContext);

  const { checkUniqueAudit } = useCheckUniqueAudit();

  const [createAudit] = useMutation(INSERT_AUDIT, {
    refetchQueries: [
      {
        query: READ_ALL_DONE_AUDITS_SORTED,
        variables: { order_by: { last_updated_date: 'desc' } },
      },
      { query: READ_CURRENT_AUDITS },
    ],
    awaitRefetchQueries: true,
    onCompleted({ createdAudit }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      if (isContraintViolationError(error))
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      else
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
    },
  });

  const onCreate = useCallback(
    async (data: AuditFormData) =>
      new Promise<number>(resolve => {
        const qat_audit_insert_input = {
          ...data,
          audit_status: AuditStatus.PRE_AUDIT,
          auditor_email: email,
          user_id: username,
        };
        const { entity_id, location_id, audit_date } = data;
        checkUniqueAudit({
          entity_id: entity_id,
          location_id: location_id,
          user_id: username,
          audit_date: audit_date,
        }).then(isUnique => {
          if (isUnique) {
            createAudit({ variables: { object: qat_audit_insert_input } })
              .then(result => {
                resolve(result.data.insert_qat_audit_one.id);
              })
              .catch(() => {});
          } else {
            setNotification({
              key: 'audit:auditValidationError',
              type: NotificationType.ERROR,
            });
          }
        });
      }),
    [createAudit, email, username, checkUniqueAudit, setNotification],
  );

  return { onCreate };
};
