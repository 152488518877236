import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root:{},
    contentContainer: {
        paddingTop: theme.spacing(2),
        textAlign: "center",
        width: "100%"
    }
}))

export {useStyles}