import React from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import AddressFormView from '../AddressFormView';
import useAddressFormPresenter from '../AddressFormPresenter/AddressFormPresenter';
import { AddressCreateFormData } from '../../../../services/Address/WriteService/AddressWriteService';
import { audit_data } from '../../../../services/Audit/AuditDataModel';
import { address_data } from '../../../../services/Address/AddressDataModel';

export interface AddressFormComponentProps {
  onSubmit: (AddressFormData) => void;
  handleClose:()=>void;
  audit: audit_data;
  address?: address_data;
  title: string;
  submitButtonLabel: string;
}

export type AddressProperty =
  | 'id'
  | 'street'
  | 'house_number'
  | 'box_number'
  | 'city'
  | 'location_entity_id';

export type ValidationRulesType = { [key in AddressProperty]?: any };

export interface AddressFormViewProps {
  errors: DeepMap<Record<string, any>, FieldError>;
  onSubmit: (Event) => void;
  handleClose:()=>void
  control: Control<AddressCreateFormData> | any;
  title: string;
  submitButtonLabel: string;
  locationLoading: boolean;
}

const AddressFormComponent: React.FC<AddressFormComponentProps> = ({
  onSubmit,
  handleClose,
  audit,
  address,
  title,
  submitButtonLabel,
}) => {
  const props = useAddressFormPresenter({
    onSubmit,
    audit,
    address,
    title,
    submitButtonLabel,
    handleClose
  });
  return <AddressFormView {...props} />;
};

export default AddressFormComponent;
