import { useStyles } from './AuditReadAllStyle';
import AuditReadAllComponent, {
  AuditReadAllComponentProps,
} from './AuditReadAllComponent';

export default AuditReadAllComponent;

export { useStyles };

export type { AuditReadAllComponentProps };
