import { gql } from '@apollo/client';

/**
 * Description - graphql mutation to create an address
 */
export const INSERT_ADDRESS = gql`
  mutation insert_address_one($object: qat_address_insert_input!) {
    insert_qat_address_one(
      object: $object 
      on_conflict: {
        constraint: address_street_house_number_box_number_city_location_entity_id_
        update_columns: [deleted_date]
      }) 
      {
      id
    }
  }
`;
