import { gql } from '@apollo/client';

/**
 * Description - graphql query to update audit status value to "DONE"
 */
export const CLOSE_AUDIT = gql`
  mutation update_qat_audit_by_pk(
    $qat_audit_pk_columns_input: qat_audit_pk_columns_input!
    $qat_audit_set_input: qat_audit_set_input!
  ) {
    update_qat_audit_by_pk(
      pk_columns: $qat_audit_pk_columns_input
      _set: $qat_audit_set_input
    ) {
      id
    }
  }
`;
