import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { useAuditReadCurrentPresenter } from '../AuditReadCurrentPresenter';
import AuditReadCurrentView from '../AuditReadCurrentView';

export interface AuditReadCurrentComponentProps {
  onUpdate: (id: number) => void;
  onDelete: (id: number) => void;
  onClose: (id: number) => void;
}

/**
 * Description - This component is responsible for the management of AuditReads
 */
const AuditReadCurrentComponent: React.FC<AuditReadCurrentComponentProps> = ({
  onUpdate,
  onDelete,
  onClose,
}) => {
  const { currentAudits, loading } = useAuditReadCurrentPresenter();

  return loading ? (
    <CircularProgress />
  ) : (
    <AuditReadCurrentView
      audits={currentAudits}
      onUpdate={onUpdate}
      onDelete={onDelete}
      onClose={onClose}
    />
  );
};

export default AuditReadCurrentComponent;
