import { gql } from '@apollo/client';

/**
 * Description - graphql query to get a specific building
 */
export const GET_BUILDING_BY_PK = gql`
  query qat_building_by_pk($id: Int!) {
    qat_building_by_pk(id: $id) {
      id
      index
      address {
        id
        street
        house_number
        box_number
        city
      }
      building_field_values {
        id
        category_location_form_field_config {
          id
          name_nl
          name_fr
          form_field_type
          is_required
        }
        category_location_form_field_config_id
        value
      }
      sub_category_location {
        id
        name_nl
        name_fr
      }
      checklist {
        id
        template_checklist {
          sub_category_location_id
          category_location_id
        }
        comment
        checklist_themes(
          order_by: { theme_template_checklist: { index: asc } }
        ) {
          id
          comment
          not_applicable
          short_name_fr
          short_name_nl
          checklist_theme_sections(order_by: { id: asc }) {
            id
            specification
            checklist_theme_id
            theme_template_checklist_id
            name_fr
            name_nl
            theme_template_checklist {
              id
              short_name_nl
              long_name_nl
              short_name_fr
              long_name_fr
              not_applicable_option
              duplicable
              index
            }
            checklist_theme_sub_sections(
              order_by: { sub_theme_template_checklist: { index: asc } }
            ) {
              id
              no_application
              name_fr
              name_nl
              checklist_theme_items(
                order_by: { template_theme_item: { index: asc } }
              ) {
                id
                not_application
                has_interview
                comment
                is_conform
                template_theme_item_id
                name_fr
                name_nl
                checklist_theme_item_remarks(
                  order_by: { template_theme_item_remark: { index: asc } }
                ) {
                  id
                  template_theme_item_remark_id
                  checked
                }
                template_theme_item {
                  id
                  title_nl
                  title_fr
                  information_nl
                  information_fr
                  norm_code
                  not_application_option
                  index
                  page_pdf_fr
                  page_pdf_nl
                  template_theme_item_remarks(
                    where: { deleted_at: { _is_null: true } }
                    order_by: { index: asc }
                  ) {
                    id
                    remark_text_nl
                    remark_text_fr
                    index
                  }
                }
              }
              sub_theme_template_checklist {
                id
                name_fr
                name_nl
                index
                not_applicable_option
              }
            }
            checklist_theme_items(
              order_by: { template_theme_item: { index: asc } }
            ) {
              id
              not_application
              has_interview
              comment
              is_conform
              name_fr
              name_nl
              checklist_theme_item_remarks(
                order_by: { template_theme_item_remark: { index: asc } }
              ) {
                id
                template_theme_item_remark_id
                checked
              }
              template_theme_item {
                id
                title_nl
                title_fr
                information_nl
                information_fr
                norm_code
                not_application_option
                index
                page_pdf_fr
                page_pdf_nl
                template_theme_item_remarks(
                  where: { deleted_at: { _is_null: true } }
                  order_by: { index: asc }
                ) {
                  id
                  remark_text_nl
                  remark_text_fr
                  index
                }
              }
            }
          }
        }
      }
    }
  }
`;

export interface get_by_building_by_pk_response {
  qat_building_by_pk: get_by_building_by_pk_data;
}

export interface checklist_item_from_get_by_building_by_pk_data {
  id: number;
  not_application: boolean;
  has_interview: boolean;
  comment: string;
  name_fr: string;
  name_nl: string;
  is_conform: boolean | null;
  template_theme_item_id: number;
  checklist_theme_item_remarks: {
    id: number;
    template_theme_item_remark_id: number;
    checked: boolean;
  }[];
  template_theme_item: {
    id: number;
    title_nl: string;
    title_fr: string;
    information_nl?: string;
    information_fr?: string;
    norm_code: string;
    not_application_option: boolean;
    index: number;
    page_pdf_fr: number;
    page_pdf_nl: number;
    template_theme_item_remarks: {
      id: number;
      remark_text_nl: string;
      remark_text_fr: string;
      index: number;
    }[];
  };
}
export interface checklist_from_get_by_building_by_pk_data {
  id: number;
  template_checklist: {
    sub_category_location_id: number;
    category_location_id: number;
  };
  comment: string;
  checklist_themes: {
    id: number;
    comment: string;
    not_applicable: boolean;
    short_name_fr: string;
    short_name_nl: string;
    long_name_fr: string;
    long_name_nl: string;
    checklist_theme_sections: {
      id: number;
      specification?: string;
      checklist_theme_id: number;
      theme_template_checklist_id: number;
      name_fr: string;
      name_nl: string;
      theme_template_checklist: {
        id: number;
        short_name_nl: string;
        long_name_nl: string;
        short_name_fr: string;
        long_name_fr: string;
        not_applicable_option: boolean;
        duplicable: boolean;
        index: number;
      };
      checklist_theme_sub_sections: {
        id: number;
        no_application: boolean;
        checklist_theme_items: checklist_item_from_get_by_building_by_pk_data[];
        name_fr: string;
        name_nl: string;
        sub_theme_template_checklist: {
          id: number;
          name_fr: string;
          name_nl: string;
          index: number;
          not_applicable_option: boolean;
        };
      }[];
      checklist_theme_items: checklist_item_from_get_by_building_by_pk_data[];
    }[];
  }[];
}

export interface get_by_building_by_pk_data {
  id: number;
  index: number;
  address: {
    id: number;
    street: string;
    house_number: string;
    box_number?: string;
    city: string;
    location_entity_id:number|string;
    
  };
  building_field_values: {
    id: number;
    category_location_form_field_config: {
      id: number;
      name_nl: string;
      name_fr: string;
      form_field_type: string;
      is_required: boolean;
    };
    category_location_form_field_config_id: number;
    value: string;
  }[];
  sub_category_location: {
    id: number;
    name_nl: string;
    name_fr: string;
  };
  checklist?: checklist_from_get_by_building_by_pk_data;
}
export const UNIQUE_BUILDING_AUDIT_ID_ADDRESS_ID = gql`
  query unique_building_audit_id_address_id(
    $audit_id: Int!
    $address_id: Int!
    $building_id: Int
  ) {
    qat_audit_building(
      where: {
        _and: [
          { audit_id: { _eq: $audit_id } }
          { building: { address_id: { _eq: $address_id } } }
          { building: { deleted_date: { _is_null: true } } }
          { building_id: { _neq: $building_id } }
        ]
      }
    ) {
      id
    }
  }
`;

/**
 * Description - graphql query to get buildings by audit
 */
export const GET_BUILDING_BY_AUDIT = gql`
  query qat_building($audit_id: Int!) {
    qat_building(
      order_by: { id: asc }
      where: {
        audit_buildings: { audit_id: { _eq: $audit_id } }
        _and: { deleted_date: { _is_null: true } }
      }
    ) {
      id
      index
      checklist {
        id
      }
      address {
        id
        street
        house_number
        box_number
        city
        location_entity_id
      }
      building_field_values {
        category_location_form_field_config {
          id
          name_nl
          name_fr
          form_field_type
          is_required
        }
        category_location_form_field_config_id
        value
      }
      sub_category_location {
        id
        name_nl
        name_fr
      }
      checklist_document {
        id
        url
        building_id
      }
    }
  }
`;

export const GET_BUILDINGS_BY_AUDIT_ID_LENGTH = gql`
  query qat_building_aggregate($audit_id: Int!) {
    qat_building_aggregate(
      where: { audit_buildings: { audit_id: { _eq: $audit_id } } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

export const GET_BUILDINGS_BY_ADDRESSID= gql`
  query qat_building_aggregate($address_id: Int!) {
    qat_building_aggregate(
      where:  {address_id: {_eq: $address_id}}) {
      aggregate {
        count
      }
    }
  }
`;
