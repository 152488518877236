import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  AuditFormViewProps,
  AuditValidationRulesType,
} from '../AuditFormComponent/AuditFormComponent';
import routes from '../../../../routes';
import {
  building as buildingInterface,
  building_data,
  building_field_values,
} from '../../../../services/Building/BuildingDataModel';
import { address_data } from '../../../../services/Address/AddressDataModel';
import { sub_category_location } from '../../../../services/Category/CategoryDataModel';
import { audit_data } from '../../../../services/Audit/AuditDataModel';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useGetAuditById } from '../../../../services/Audit/ReadService/AuditReadService';

const validationRules: AuditValidationRulesType = {
  entity_id: { validate: value => value !== 0 },
  location_id: { validate: value => value !== 0 },
  audit_date: { validate: value => value !== '' },
};
export interface AuditFormData {
  entity_id: number | string;
  location_id: number | string;
  audit_date: Date | string;
  auditor_email: string;
  audit_buildings?: buildingInterface[];
}

export interface AuditFormPresenterProps {
  onSubmitAudit: (auditFormData: AuditFormData) => Promise<any>;
  audit: audit_data | undefined;
  building?: building_data;
  title: string;
  submitButtonLabel: string;
  fromCreateAuditComponent?: boolean;
}
export interface Building {
  address: address_data | undefined;
  sub_category_location: sub_category_location;
  building_field_values: building_field_values[];
}

const useAuditFormPresenter = ({
  onSubmitAudit,
  audit,
  building,
  title,
  submitButtonLabel,
  fromCreateAuditComponent
}: AuditFormPresenterProps): AuditFormViewProps => {
  const history = useHistory();
  let { path: parentPath } = useRouteMatch();
  const { getAuditById } = useGetAuditById();

  const defaultValues = useMemo(
    () =>
      audit
        ? {
            entity_id: audit?.entity?.id,
            location_id: audit?.location_entity?.id,
            audit_date: audit?.audit_date,
          }
        : { entity_id: '', location_id: '', audit_date: '' },
    [audit],
  );
  const validation = useForm<AuditFormData>({
    defaultValues,
    shouldUnregister: false,
  });

  const watchEntityId = validation.watch('entity_id');
  const watchLocationId = validation.watch('location_id');
  const watchAuditDate = validation.watch('audit_date');

  useEffect(() => {
    validation.register({ name: 'entity_id' }, validationRules.entity_id);
    validation.register({ name: 'location_id' }, validationRules.location_id);
    validation.register({ name: 'audit_date' }, validationRules.audit_date);

    return () => {
      validation.unregister('entity_id');
      validation.unregister('location_id');
      validation.unregister('audit_date');
    };
  }, [validation]);

  const handleSubmitAuditForm = validation.handleSubmit(
    (auditFormData: AuditFormData) => {
      onSubmitAudit(auditFormData).then(response =>
        history.push({ pathname: routes.currentAudits.path }),
      );
    },
  );

  const handleCreateBuildingClick = () => {
    onSubmitAudit(validation.getValues()).then(response => {
      const audit_id = response?.data?.update_qat_audit_by_pk?.id
        ? response?.data?.update_qat_audit_by_pk?.id
        : response;
      getAuditById(audit_id).then(auditVar => {
        history.push({
          pathname: `${parentPath}${routes.createBuilding.path}`,
          state: { audit: auditVar },
        });
      });
    });
  };

  const displayBuildingSection = useMemo(
    () =>
      (watchEntityId && watchLocationId && watchAuditDate) ||
      (audit && audit.audit_buildings && audit.audit_buildings.length > 0)
        ? true
        : false,
    [watchEntityId, watchLocationId, watchAuditDate, audit],
  );

  return {
    audit,
    building,
    onSubmit: handleSubmitAuditForm,
    onCreateBuildingClick: handleCreateBuildingClick,
    validation,
    title,
    submitButtonLabel,
    displayBuildingSection,
    parentPath,
    fromCreateAuditComponent
  };
};

export default useAuditFormPresenter;
