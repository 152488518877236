import gql from 'graphql-tag';

/**
 * Description - mutation to insert new checklist theme section
 */
export const INSERT_CHECKLIST_THEME_SECTION = gql`
  mutation insert_checklist_theme_section(
    $object: qat_checklist_theme_section_insert_input!
  ) {
    insert_qat_checklist_theme_section_one(object: $object) {
      id
    }
  }
`;

/**
 * Description - mutation to delete checklist theme section
 */
export const DELETE_CHECKLIST_THEME_SECTION = gql`
  mutation insert_checklist_theme_section($id: Int!) {
    delete_qat_checklist_theme_section_by_pk(id: $id) {
      id
    }
  }
`;
