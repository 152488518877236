import React from 'react';
import { Notification } from './components/Notification/Notification';

export interface AppContextType {
  username: string;
  role: string;
  language: string;
  setLanguage: (lng: string) => void;
  loadingUser: boolean;
  email: string;
  notification?: Notification;
  setNotification: (notification: Notification | null) => void;
  logOut: () => void;
}

const AppContext = React.createContext<AppContextType>({
  username: '',
  role: '',
  language: '',
  setLanguage: (lng: string) => {},
  loadingUser: true,
  email: '',
  notification: undefined,
  setNotification: (notification: Notification | null) => {},
  logOut: () => {},
});

export const Provider = AppContext.Provider;

export const Consumer = AppContext.Consumer;

export default AppContext;
