export const audit = {
  currentAudit: 'Audits en cours',
  listAudit: 'Liste des audits',
  createAudit: 'Créer un nouvel audit',
  audit: 'Audit',
  location: 'Localité',
  auditor: 'Auditeur',
  auditDate: "Date de l'audit",
  locationsNotFound: 'Aucun localité trouvé pour cette entité',
  updateAudit: 'Modifier un audit',
  locationError: 'La localité est un champ obligatoire',
  auditValidationError: "L'audit que vous avez spécifiée existe déjà",
  auditDateError: "Le date de l'audit est un champ obligatoire",
  checklist: 'Checklist',
  buildings: 'Bâtiments',
  close: 'Clôturer',
  confirmClose: 'Voulez vous vraiment le clôturer',
  interview: 'Interview',
  category: 'Catégorie',
  cat: 'Cat',
  specification: 'Spéc',
};
