import { useEffect, useState, useMemo, useCallback, useContext } from 'react';
import { ChecklistViewProps } from '../ChecklistView';
import { useHistory } from 'react-router-dom';
import { useGetTemplateByCategoryIdAndSubCategoryId } from '../../../services/TemplateChecklist/TemplateChecklistReadService/TemplateChecklistReadService';
import { useForm } from 'react-hook-form';
import { useGetChecklistById } from '../../../services/Checklist/ChecklistReadService/ChecklistReadService';
import { useCreateChecklistWithTemplate } from '../../../services/Checklist/ChecklistWriteService/ChecklistWriteService';
import routes from '../../../routes';
import { debounce } from 'lodash';
import { useUpdateChecklistTheme } from '../../../services/ChecklistTheme/ChecklistThemeWriteService';
import { useBeforeunload } from 'react-beforeunload';
import AppContext from '../../../AppContext';
import { NotificationType } from '../../Notification/NotificationComponent';
import {
  get_checklist_by_pk_data,
  get_checklist_by_pk_theme_data,
} from '../../../services/Checklist/ChecklistReadService/ChecklistReadQuery';
import { currentAuditIdVar, selectedThemeNavigationVar as currenttSelectedThemeNavigationVar } from '../ChecklistCache';
import {  useBrowserLocationState } from '../../Shared/BrowserLocationState/BrowserLocationState';

export interface InitChecklistProps {
  setChecklistId: (id: number) => void;
  auditId: number;
  buildingId: number;
  checklistId?: number;
  category_id: number;
  sub_category_id?: number;
}

export interface InitChecklistResult {
  checklist?: get_checklist_by_pk_data;
}

export const useInitChecklist = ({
  setChecklistId,
  auditId,
  buildingId,
  checklistId,
  category_id,
  sub_category_id,
}: InitChecklistProps): InitChecklistResult => {
  const {
    data: checklistData,
    loading: checklistLoading,
    error: checklistError,
  } = useGetChecklistById(checklistId);
  const {
    getTemplateByCategoryIdAndSubCategoryId,
  } = useGetTemplateByCategoryIdAndSubCategoryId();
  const { onCreate } = useCreateChecklistWithTemplate({ audit_id: auditId });
  const [checklist, setChecklist] = useState<get_checklist_by_pk_data>();

  useEffect(() => {
    if (
      checklistId !== -1 &&
      checklistData &&
      checklistData.qat_checklist_by_pk &&
      !checklistLoading &&
      !checklistError
    ) {
      setChecklist(checklistData?.qat_checklist_by_pk);
    } else if (checklistId === -1 && !checklistLoading && !checklistError) {
      getTemplateByCategoryIdAndSubCategoryId(
        category_id,
        sub_category_id,
      ).then(template => {
        onCreate({ building_id: buildingId, template }).then(
          response => {
            setChecklistId(response?.data.insert_qat_checklist_one.id);
          },
        );
      });
    }
  }, [
    setChecklistId,
    getTemplateByCategoryIdAndSubCategoryId,
    category_id,
    sub_category_id,
    checklistData,
    checklistLoading,
    checklistError,
    auditId,
    buildingId,
    checklistId,
    onCreate,
  ]);

  return { checklist };
};


export const useChecklistPresenter = (): ChecklistViewProps => {
  const form = useForm();
  const history = useHistory();

  const [themeMenuOpen, setThemeMenuOpen] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { setNotification } = useContext(AppContext);

  
  const currentLocationState = useBrowserLocationState<{audit: any, building: any, isReadOnlyMode?, checklist?}>({keysRequired: ["audit", "building"]})

  const category_location = useMemo(
    () => currentLocationState?.audit?.location_entity?.category_location,
    [currentLocationState],
  );
  const sub_category_location = useMemo(
    () => currentLocationState?.building?.sub_category_location,
    [currentLocationState],
  );
  const isReadOnlyMode = useMemo(() => currentLocationState?.isReadOnlyMode, [
    currentLocationState,
  ]);

  const audit = useMemo(() => currentLocationState?.audit, [currentLocationState]);

  currentAuditIdVar(audit ? audit?.id : -1)
  const building_id = useMemo(() => currentLocationState?.building?.id, [currentLocationState]);
  const [checklistId, setChecklistId] = useState<number | undefined>(-1);
  const building = useMemo(() => currentLocationState?.building, [currentLocationState]);

  useEffect(() => {
    if (!currentLocationState?.building) {
      history.push({ pathname: `${routes.currentAudits.path}` });
    }
  }, [currentLocationState, history]);

  const checklist_id = useMemo(
    () =>
      checklistId !== -1
        ? checklistId
        : currentLocationState?.building?.checklist?.id
          ? currentLocationState?.building?.checklist?.id
          : currentLocationState?.checklist?.id || -1,
    [currentLocationState, checklistId],
  );

  const { checklist } = useInitChecklist({
    setChecklistId,
    auditId: audit?.id,
    buildingId: building_id,
    category_id: category_location?.id,
    sub_category_id: sub_category_location?.id,
    checklistId: checklist_id,
  });


  const [
    selectedTheme,
    setSelectedTheme,
  ] = useState<get_checklist_by_pk_theme_data>();
  const [
    selectedThemeNavigation,
    setSelectedThemeNavigation,
  ] = useState<get_checklist_by_pk_theme_data>();

  const [expanded, setExpanded] = useState<boolean>(true);
  const [comment, setComment] = useState(selectedTheme?.comment);

  const onExpand = y => (event, isExpanded) => {
    setExpanded(expanded ? false : true);
  };

  const { onUpdate: updateChecklistTheme } = useUpdateChecklistTheme(
    checklist_id,
  );

  useEffect(() => {
    setComment(selectedTheme?.comment);
  }, [selectedTheme,checklist_id]);

  const debouncedSave = useCallback(
    debounce(
      (nextValue, themeId) =>
        updateChecklistTheme(themeId, { comment: nextValue })?.then(
          response => {
            handleSetState(false);
          },
        ),
      1000,
    ),
    [],
  );

  const handleSetState = useCallback(_isSaving => {
    setIsSaving(_isSaving);
  }, []);

  useBeforeunload(event => {
    if (isSaving === true) {
      event.preventDefault();
      setNotification({ key: 'common:saving', type: NotificationType.WARNING });
      return null;
    }
  });

  const onChangeComment = event => {
    handleSetState(true);
    const { value: nextValue } = event.target;
    setComment(nextValue);
    debouncedSave(nextValue, selectedTheme?.id);
  };

  useEffect(() => {
    const theme = checklist?.checklist_themes.find(
      _theme => _theme?.id === selectedThemeNavigation?.id,
    );
    if (theme) {
      setSelectedTheme(theme);
    } else {
      // set first theme by default or last theme when you made goBack      
      setSelectedTheme(currenttSelectedThemeNavigationVar() ? currenttSelectedThemeNavigationVar() : checklist?.checklist_themes[0]);
    }
  }, [checklist, selectedThemeNavigation]);

  const onThemeSelected = useCallback((theme: get_checklist_by_pk_theme_data) => {
    setSelectedThemeNavigation(theme);
    currenttSelectedThemeNavigationVar(theme)
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checklist]);

  const onCloseMenu = useCallback(() => {
    setThemeMenuOpen(false);
  }, []);

  const onOpenMenu = useCallback(() => {
    setThemeMenuOpen(true);
  }, []);

  const width: 'small' | 'full' | 'medium' = useMemo(() => {
    return themeMenuOpen ? 'small' : 'medium';
  }, [themeMenuOpen]);

  const onRemarkSelected = () => {
    history.push({
      pathname: routes.checklistRemarks.path,
      state: {
        audit: currentLocationState?.audit,
        checklist: checklist,
        building: building,
        isReadOnlyMode: isReadOnlyMode,
      },
    });
  };

  return {
    category: category_location,
    subCategory: sub_category_location,
    onThemeSelected,
    selectedTheme,
    building: {...building,checklist},
    form,
    checklist,
    width,
    themeMenuOpen,
    onCloseMenu,
    onOpenMenu,
    onRemarkSelected,
    expanded,
    onExpand,
    comment,
    onChangeComment,
    isReadOnlyMode,
    audit
  };
};
