import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    control: {
        width: '100%',
    },
    title: {
        flexGrow: 1,
        color: '#141414',
    },
    labelInputUploadFile:{
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    containerInputUploadFile: {
        display:"flex"
    },
    fileName: {
        marginLeft: theme.spacing(2),
        paddingTop: theme.spacing(1)
    },
    error:{
        color: theme.palette.error.main
    },
    buttonUploadFile: {
        color: 'white',
        backgroundColor: theme.palette.success.main,
        width: 250
      },
    buttonSubmit: {
        width: '100%',
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        "&:hover":{
            backgroundColor: theme.palette.primary.dark
        }
    },
}));

export { useStyles };