import { useQuery, useApolloClient } from '@apollo/client';
import { useCallback, useContext } from 'react';
import AppContext from '../../../AppContext';
import {
  GET_ITEM_BY_PK,
  READ_ALL_ITEMS,
  READ_ALL_ITEMS_FROM_TEMPLATE_SUB_THEME,
  READ_ALL_ITEMS_FROM_TEMPLATE_THEME,
  READ_ALL_ITEMS_SORTED,
  GET_ALL_ITEMS_INCLUDE_SOFT_DELETED_IN_SUBTHEME,
  GET_ALL_ITEMS_INCLUDE_SOFT_DELETED_IN_THEME,
} from './ItemReadQuery';
import {
  get_all_items_include_deleted_data,
  get_all_items_include_deleted_in_sub_theme_variables,
  get_all_items_include_deleted_in_theme_variables,
  get_all_items_include_deleted_response,
  items_data,
  item_data_by_pk,
} from './ItemReadModel';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { OrderBy } from '../../../components/Entity/EntityRead/EntityReadComponent/EntityReadComponent';
import { ItemData } from '../ItemDataModel';
import { ItemFormData } from '../ItemFormData';
import { useTranslation } from 'react-i18next';

/**
 * Description - service to get all entities
 */
export const useGetAllItems = () => {
  const { setNotification } = useContext(AppContext);

  const { data, loading, error } = useQuery<items_data>(READ_ALL_ITEMS, {
    onError: errorResponse => {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });
  return {
    data: data?.qat_template_theme_item ? data?.qat_template_theme_item : [],
    loading,
    error,
  };
};

export interface ItemUniqueErrorResponse {
  title_fr?: string;
  title_nl?: string;
  information_fr?: string;
  information_nl?: string;
}

export const useCheckItemUnique = () => {
  const { getItems } = useGetAllItemsIncludeDeleted()
  const { t } = useTranslation()

  const onCheckItemUnique = useCallback((
    itemData: ItemFormData,
    { template_sub_theme_id, template_theme_id }: { template_sub_theme_id?: number, template_theme_id?: number }
  ) => {
    return new Promise<ItemUniqueErrorResponse | void>(resolve => {
      const errors: ItemUniqueErrorResponse = {}
      getItems({ template_sub_theme_id, template_theme_id }).then(items => {
        const doubleWithTitleFr: get_all_items_include_deleted_data | undefined =
          !itemData.id
            ? items.find((item) => item.title_fr === itemData.title_fr && item.deleted_at === null)
            : items.find((item) => item.title_fr === itemData.title_fr && item.id !== itemData.id && item.deleted_at === null)

        const doubleWithTitleNl: get_all_items_include_deleted_data | undefined =
          !itemData.id
            ? items.find((item) => item.title_nl === itemData.title_nl && item.deleted_at === null)
            : items.find((item) => item.title_nl === itemData.title_nl && item.id !== itemData.id && item.deleted_at === null)

        const doubleWithInformationFr: get_all_items_include_deleted_data | undefined =
          !itemData.id
            ? items.find((item) => item.information_fr === itemData.information_fr && item.information_fr !== null && item.information_fr.length !== 0 && item.deleted_at === null)
            : items.find((item) => item.information_fr === itemData.information_fr && item.information_fr !== null && item.information_fr.length !== 0 && item.id !== itemData.id && item.deleted_at === null)

        const doubleWithInformationNl: get_all_items_include_deleted_data | undefined =
          !itemData.id
            ? items.find((item) => item.information_nl === itemData.information_nl && item.information_nl !== null && item.information_nl.length !== 0 && item.deleted_at === null)
            : items.find((item) => item.information_nl === itemData.information_nl && item.information_nl !== null && item.information_nl.length !== 0 && item.id !== itemData.id && item.deleted_at === null)


        if (doubleWithTitleFr) {
          errors.title_fr = t("item:titleFrUniqueError")
        }
        if (doubleWithTitleNl) {
          errors.title_nl = t("item:titleNlUniqueError")
        }
        if (doubleWithInformationFr) {
          errors.information_fr = t("item:informationFrUniqueError")
        }
        if (doubleWithInformationNl) {
          errors.information_nl = t("item:informationNlUniqueError")
        }

        if (Object.keys(errors).length) {
          resolve(errors)
        } else {
          resolve()
        }
      })
    })

  }, [t, getItems])

  return { onCheckItemUnique }
}

export const useGetAllItemsIncludeDeleted = () => {
  const client = useApolloClient();
  const { setNotification } = useContext(AppContext);

  const getItems = useCallback(
    ({
      template_theme_id,
      template_sub_theme_id,
    }: {
      template_theme_id?: number;
      template_sub_theme_id?: number;
    }) =>
      new Promise<get_all_items_include_deleted_data[]>(resolve => {
        client
          .query<
            get_all_items_include_deleted_response,
            | get_all_items_include_deleted_in_theme_variables
            | get_all_items_include_deleted_in_sub_theme_variables
          >({
            variables:
              template_theme_id && !template_sub_theme_id
                ? { template_theme_id }
                : template_sub_theme_id
                  ? { template_sub_theme_id }
                  : { template_sub_theme_id: -1 },
            query:
              template_theme_id && !template_sub_theme_id
                ? GET_ALL_ITEMS_INCLUDE_SOFT_DELETED_IN_THEME
                : GET_ALL_ITEMS_INCLUDE_SOFT_DELETED_IN_SUBTHEME,
            fetchPolicy: "network-only"
          })
          .then(result => {
            if (result.error) {
              setNotification({
                key: 'common:generalError',
                type: NotificationType.ERROR,
              });
              resolve([]);
            }
            resolve(
              result.data.qat_template_theme_item.map(
                ({ __typename, ...rest }) => ({ ...rest }),
              ),
            );
          }).catch(error => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      }),
    [client, setNotification],
  );

  return { getItems };
};

export const useGetAllSortedItems = () => {
  const client = useApolloClient();
  const { setNotification } = useContext(AppContext);

  const orderItemsBy = useCallback(
    (orderBy: OrderBy) =>
      new Promise<ItemData[]>(resolve => {
        client
          .query<items_data>({
            query: READ_ALL_ITEMS_SORTED,
            variables: { order_by: { [orderBy?.column]: orderBy?.direction } },
          })
          .then(response => {
            resolve(response?.data?.qat_template_theme_item);
          })
          .catch(errorResponse => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      }),
    [setNotification, client],
  );

  return { orderItemsBy };
};

export const useGetItemByIdDefault = id => {
  const { setNotification } = useContext(AppContext);

  let { data, loading, error } = useQuery<item_data_by_pk>(GET_ITEM_BY_PK, {
    variables: { id },
    onError: errorResponse => {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });
  return { data: data?.qat_template_theme_item_by_pk, loading, error };
};

export const useGetAllItemFromTheme = template_theme_id => {
  const { setNotification } = useContext(AppContext);

  let { data, loading, error } = useQuery<items_data>(
    READ_ALL_ITEMS_FROM_TEMPLATE_THEME,
    {
      variables: { template_theme_id },
      fetchPolicy: 'network-only',
      onError: errorResponse => {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    },
  );
  return {
    data: data?.qat_template_theme_item ? data?.qat_template_theme_item : [],
    loading,
    error,
  };
};

export const useGetAllItemFromSubTheme = template_sub_theme_id => {
  const { setNotification } = useContext(AppContext);

  let { data, loading, error } = useQuery<items_data>(
    READ_ALL_ITEMS_FROM_TEMPLATE_SUB_THEME,
    {
      variables: { template_sub_theme_id },
      fetchPolicy: 'network-only',
      onError: errorResponse => {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    },
  );
  return {
    data: data?.qat_template_theme_item ? data?.qat_template_theme_item : [],
    loading,
    error,
  };
};

export const useGetAllItemFromThemeOrSubTheme = (template_theme_id, template_sub_theme_id) => {
  const { data: themeData, loading: themeLoading, error: themeError } = useGetAllItemFromTheme(template_theme_id);
  const { data: subThemeData, loading: subThemeLoading, error: subThemeError } = useGetAllItemFromSubTheme(template_sub_theme_id);

  if (template_sub_theme_id !== -1) {
    return ({ data: subThemeData, loading: subThemeLoading, error: subThemeError })
  } else {
    return ({ data: themeData, loading: themeLoading, error: themeError })
  }

};
