import React, { useCallback, useMemo } from "react";
import { useReactiveVar } from "@apollo/client";
import {
    openCreateLocationVar,
    locationVar,
    locationDeleteDoubleIdVar
} from "../LocationCache";
import { LocationFormData } from "../LocationFormModel";
import { useCreateLocation } from "../../../services/Location/WriteService/LocationWriteService";
import LocationFormComponent from "./LocationFormComponent";
import { useUpdateLocation } from "../../../services/Location/UpdateService/LocationUpdateService";
import { useBrowserLocationState } from "../../Shared/BrowserLocationState/BrowserLocationState";


const LocationCreateDialogComponent = () => {
    const isOpenCreateLocation: boolean = useReactiveVar(openCreateLocationVar);
    const currentLocation: LocationFormData = useReactiveVar(locationVar);

    const currentLocationState = useBrowserLocationState<{entity: any}>({keysRequired: ["entity"]})

    const entityId = useMemo
        (() => {
            return currentLocationState?.entity
                ? currentLocationState?.entity?.id
                : -1;
        }, [currentLocationState])

    const { onCreate } = useCreateLocation(entityId);
    const { onUpdate } = useUpdateLocation(entityId)



    const defaultLocation: LocationFormData = {
        name: "",
        category_id: -1,
        entity_id: -1,
        specification: "",
        deleted_date: null
    };

    const locationFormValue = useCallback(
        () => {
            let locationForm: LocationFormData;
            locationForm = {
                name: currentLocation.name,
                entity_id: entityId,
                category_id: currentLocation.category_id,
                specification: currentLocation.specification,
                deleted_date: null

            };
            return locationForm;
        }, [currentLocation, entityId]);

    const handleCreateAnotherLocation = useCallback(
        () => {
            let locationForm: LocationFormData = locationFormValue();
            let locationDeleteDoubleId = locationDeleteDoubleIdVar()
            if (locationForm) {

                if (locationDeleteDoubleId && locationDeleteDoubleId !== -1) {
                    onUpdate(locationDeleteDoubleId, locationForm)
                    locationDeleteDoubleIdVar(-1)
                } else {
                    onCreate(locationForm);
                }
                locationVar(defaultLocation);
            }
        }, [locationFormValue, defaultLocation, onCreate, onUpdate]);

    const handleCreateLocation = useCallback(
        () => {
            handleCreateAnotherLocation();
            openCreateLocationVar(false);

        }, [handleCreateAnotherLocation]);


    const handleClose = useCallback(() => {
        openCreateLocationVar(false);
        locationVar(defaultLocation);

    }, [defaultLocation]);

    return (
        <div>
            <LocationFormComponent
                isOpenForm={isOpenCreateLocation}
                handleClose={handleClose}
                handleFormSubmit={handleCreateLocation}
                handleCreateLocation={handleCreateAnotherLocation}

            />
        </div>
    )
}

export default LocationCreateDialogComponent;