import ChecklistThemeView, {
  ChecklistThemeViewProps,
  testIds,
} from './ChecklistThemeView';

export default ChecklistThemeView;

export { testIds };

export type { ChecklistThemeViewProps };
