import { gql } from '@apollo/client';

/**
 * Description - graphql query to get theme template checklist by template checklist id
 *
 */
export const READ_THEME_TEMPLATE_CHECKLIST_BY_TEMPLATE_ID = gql`
  query list_theme_template_checklist_by_template_id(
    $templateChecklistId: Int!
  ) {
    qat_theme_template_checklist(
      where: {
        _and: [
          { deleted_date: { _is_null: true } },
          { template_checklist_id: { _eq: $templateChecklistId } }
        ]
      }
      order_by: { index: asc }
    ) {
      id
      long_name_fr
      long_name_nl
      short_name_fr
      short_name_nl
      duplicable
      not_applicable_option
      index
      template_checklist_id
      sub_theme_template_checklists {
        id
        name_fr
        name_nl
        index
        deleted_date
      }
      template_theme_items(
        where:{ template_sub_theme_id: { _is_null: true}}
      ) {
        id
        deleted_at
      }
    }
  }
`;

export const GET_ALL_THEME_INCLUDE_SOFT_DELETE_IN_TEMPLATE = gql`
  query get_all_theme_include_soft_delete_in_template(
    $template_checklist_id: Int!
  ) {
    qat_theme_template_checklist(
      where: { template_checklist_id: { _eq: $template_checklist_id } }
      order_by: { index: asc }
    ) {
      id
      long_name_fr
      long_name_nl
      short_name_fr
      short_name_nl
      duplicable
      not_applicable_option
      index
      template_checklist_id
      deleted_date
    }
  }
`;

/**
 * Description - graphql query to get theme template checklist by id
 */
export const GET_THEME_FROM_TEMPLATE_CHECKLIST_WITH_SAME_CATEGORY_LOCATION = gql`
  query get_theme_from_template_checklist_with_same_category_location(
    $category_location_id: Int!
    $sub_category_location_id: Int
  ) {
    qat_theme_template_checklist(
      where: {
        _and: [
          {
            template_checklist: {
              category_location_id: { _eq: $category_location_id }
            }
          }
          {
            template_checklist: {
              sub_category_location_id: { _neq: $sub_category_location_id }
            }
          }
          { deleted_date: { _is_null: true } }
        ]
      }
      order_by: { index: asc }
    ) {
      id
      long_name_fr
      long_name_nl
      short_name_fr
      short_name_nl
      duplicable
      index
      not_applicable_option
      sub_theme_template_checklists {
        id
        name_fr
        name_nl
        index
      }
      template_theme_items {
        id
      }
    }
  }
`;
