import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  button: {
    padding: `0px ${theme.spacing(2)}px`,
  },
  conform: {
    color: '#4caf50',
  },
  notConform: {
    color: '#FF0000',
  },
  default: {
    color: '#808080',
  },
  normCode: {
    cursor: "pointer",
    color: '#2979ff',
    fontWeight: 'bold',
    marginRight: theme.spacing(3),
    textDecoration: 'none',
  },
  itemTitle: {
    fontWeight: 'bold',
  },
  remarkTitle: {
    color: theme.palette.grey[500],
    fontWeight: 'bold',
  },
  textarea: {
    width: '99%',
    lineHeight: 1.3,
  },
}));

export { useStyles };
