import { translate, translateLongName, translateformPagePDF } from '../../../services/Translation/TranslationService';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import _ from "lodash"
import moment from 'moment';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../AppContext';
import { useStyles } from './ChecklistReportNotApplicableViewStyle';
import { CircularProgress, Grid, Box, Paper, Typography, Chip, Button } from '@material-ui/core';
import AdminDocumentServiceContext from '../../AdminDocument/AdminDocumentRead/AdminDocumentServiceContext'
import ItemNotApplicableDocumentDialog from './ChecklistReportNotApplicableDocumentDialog';
import { useDocumentDialogService } from '../../AdminDocument/AdminDocumentRead/AdminDocumentService';


export const testIds = {};

export interface ChecklistReportNotApplicableViewProps {
  isLoading: boolean;
  noAppOverviewDetails: any;
}

const ChecklistReportNotApplicableView: React.FC<ChecklistReportNotApplicableViewProps> = ({
  isLoading,
  noAppOverviewDetails
}) => {
  const { t } = useTranslation();
  const classes = useStyles()
  const { language } = useContext(AppContext);
  const { onSelectPage } = useContext(AdminDocumentServiceContext)
  const { open: openNotApplicableDocumentDialog, pageNumber: pageNumberNotApplicableDocumentDialog, onCloseDialog: onCloseNotApplicableDocumentDialog, onSelectPage: onSelectNotApplicableDocument } = useDocumentDialogService()
  
  const categoryNameFr: string = useMemo(
    () =>
      noAppOverviewDetails.audit?.location_entity?.category_location?.name_fr
        ? noAppOverviewDetails.audit.location_entity.category_location.name_fr
        : "",
    [noAppOverviewDetails],
  );

  return isLoading ? (
    <CircularProgress />
  ) : (
    <>
      <ItemNotApplicableDocumentDialog
        open={openNotApplicableDocumentDialog}
        pageNumber={pageNumberNotApplicableDocumentDialog}
        onCloseDialog={onCloseNotApplicableDocumentDialog}
        categoryLocation={categoryNameFr}
      />
      <Grid
        container
      >

        <Grid item lg={10} xs={10} className={classes.titleGrid}>
          <Paper elevation={3}>
            <Box className={classes.content}>

              <Box style={{ flexGrow: 2 }}>
                <Typography className={classes.title}>
                  {t("report:not_applicable_title", { abbreviation: noAppOverviewDetails?.audit?.entity?.abbreviation, location: noAppOverviewDetails?.audit?.location_entity?.name, audit_date: moment(noAppOverviewDetails?.audit?.audit_date)?.format('DD/MM/YYYY') })}
                </Typography>

              </Box>
              <Box style={{ flexGrow: 1 }}>
                <Button
                  className={classes.documentButton}
                  variant="contained"
                  onClick={() => onSelectNotApplicableDocument(1)}
                >
                  {t('report:documentButton')}
                </Button>
              </Box>


            </Box>
          </Paper>        
         
        </Grid>
        <Grid item lg={12} xs={12}>
          <Box m={1}>
            {noAppOverviewDetails?.items?.map((_item, index) =>
              <Paper elevation={3} key={index}>
                <Grid
                  container
                  key={index}
                >
                  <Grid item lg={5} xs={5}>
                    <Box m={2}>
                      <Grid container>
                        <Grid item lg={1} xs={1}>
                          <Typography
                            className={classes.normCode}
                            onClick={() => onSelectPage(translateformPagePDF(_item[0][0], language))}
                          >
                            {_item[0][0]?.norm_code}
                          </Typography>
                        </Grid>
                        <Grid item lg={11} xs={11}>
                          <Typography className={classes.itemName}>
                            {translate(_item[0][0], language)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item lg={7} xs={7}>
                    <Box m={0.2}>
                      {_.orderBy(_item, __item => __item[0]?.addres?.building_index, ['asc'])?.map((_item_adress, index_adress) =>
                        <Grid container key={index_adress}>
                          <Grid item lg={4} xs={4} >
                            <Box mr={2}>
                              <Typography className={classes.addressGrid} component={"div"} >
                                <span><Chip className={classes.addressNumber} label={_item_adress[0]?.addres?.building_index}></Chip></span>{_item_adress[0]?.addres?.street} {_item_adress[0]?.addres?.house_number} {_item_adress[0]?.addres?.box_number ? t("report:boxNumber", { 0: _item_adress[0]?.addres?.box_number }) : ""}{" "}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item lg={5} xs={5}>
                            {_item_adress?.map((_item_spe_com, index_spe_com) =>
                              <Grid key={index_spe_com}>
                                <Box m={2} style={{ display: "inline-flex" }}>
                                  <ArrowForwardIcon fontSize="small" className={classes.arrowIcon} />
                                  <Typography component={"div"}>
                                    <div style={{
                                      display: 'flex',
                                      alignItems: 'center',
                                      flexWrap: 'wrap',
                                    }} className={classes.themeGrid}>
                                      <span>
                                        {/* eslint-disable-next-line */}
                                        {translateLongName(_item_spe_com, language)}
                                      </span>
                                    </div>
                                  </Typography>
                                  {
                                    _item_spe_com.specification && <Typography className={classes.specification}>
                                      <span> {_item_spe_com.specification}</span>
                                    </Typography>
                                  }
                                  {
                                    _item_spe_com.comment && <Typography className={classes.specification}>
                                      <span> {_item_spe_com.comment}</span>
                                    </Typography>
                                  }

                                </Box>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            )}
            {noAppOverviewDetails?.themes?.map((_themeOrSubtheme, index) => {
              return <Paper elevation={3} key={index}>
                <Grid
                  container
                  key={index}
                >
                  <Grid item lg={5} xs={5}>
                    <Box m={2}>
                      <Grid container>

                        <Grid item lg={11} xs={11}>
                          <Typography className={classes.themeName}>
                            <span className={classes.sectionNotApplicable}>{_themeOrSubtheme[0][0]?.fromTheme ? t("report:theme") : t("report:subtheme")}</span>
                            {translate(_themeOrSubtheme[0][0], language)}
                            <span className={classes.sectionNotApplicable}>{t("report:notApplicable")}</span>
                          </Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item lg={7} xs={7}>
                    <Box m={0.2}>
                      {_.orderBy(_themeOrSubtheme, __themeOrSubtheme => __themeOrSubtheme[0]?.addres?.building_index, ['asc'])?.map((_themeOrSubtheme_adress, index_adress) => <Grid container key={index_adress}>
                        <Grid item lg={4} xs={4}>
                          <Box mr={2}>
                            <Typography className={classes.addressGrid} component={"div"}>
                              <span><Chip className={classes.addressNumber} label={_themeOrSubtheme_adress[0]?.addres?.building_index}></Chip></span>{_themeOrSubtheme_adress[0]?.addres?.street} {_themeOrSubtheme_adress[0]?.addres?.house_number}  {_themeOrSubtheme_adress[0]?.addres?.box_number ? t("report:boxNumber", { 0: _themeOrSubtheme_adress[0]?.addres?.box_number }) : ""}{" "} </Typography>
                          </Box>
                        </Grid>

                      </Grid>
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </Paper>;
            }
            )}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ChecklistReportNotApplicableView;