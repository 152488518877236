import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    padding: theme.spacing(0.5),
  },
  itemGrid: {},
  titleGrid: {
    marginBottom: '0.5px',
  },
  address: {
    fontWeight: 'bold',
    padding: theme.spacing(0.5),
  },
  addressNumber: {
    margin: 10,
  },
  addressGrid: {
    backgroundColor: '#bdbdbd',
  },
  theme: {
    fontWeight: 'bold',
  },
  formControl: {
    height: '100%',
  },
  checklistThemeGrid: {
    height: 'auto',
  },
  normCode: {
    cursor: "pointer",
    color: '#2979ff',
    fontWeight: 'bold',
    marginRight: theme.spacing(3),
    textDecoration: 'none',
  },
  itemName: {
    fontWeight: 'bold',
  },
  itemTitle: {
    fontWeight: 'bold',
  },
  themeGrid: {
    fontWeight: 'bold',
  },
  sectionSpecification: {
    marginLeft: theme.spacing(1),
  },
  arrowIcon: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1.5),
  },
  themeName: {
    fontWeight: 'bold',
  },
}));

export { useStyles };
