export const audit = {
  currentAudit: 'Huidige audits',
  listAudit: 'Lijst audits',
  createAudit: 'Audit aanmaken',
  audit: 'Audit',
  location: 'Locatie',
  auditor: 'Auditeur',
  auditDate: 'Datum audit',
  locationsNotFound: 'Geen locaties gevonden voor deze entiteit',
  updateAudit: 'Audit wijzigen',
  locationError: 'Locatie is een verplicht veld',
  auditValidationError: 'De audit die u heeft opgegeven bestaat reeds',
  auditDateError: 'Datum audit is een verplicht veld',
  checklist: 'Checklist',
  buildings: 'Gebouwen',
  close: 'Afsluiten',
  confirmClose: 'Weet u zeker dat u de audit wil afsluiten?',
  interview: 'Interview',
  category: 'Categorie',
  cat: 'Cat',
  specification: 'Spec',
};
