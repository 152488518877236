import React from 'react';
import { get_checklist_by_pk_data } from '../../../../services/Checklist/ChecklistReadService/ChecklistReadQuery';
import ChecklistNavigationView from '../ChecklistNavigationView';

export interface ChecklistNavigationComponentProps {
  onThemeSelected: (theme) => void;
  onRemarkSelected: () => void;
  selectedTheme: any;
  checklist?: get_checklist_by_pk_data;
  onCloseMenu: () => void;
  selectedChecklistRemark: any;
}

const ChecklistNavigationComponent = ({
  onThemeSelected,
  onRemarkSelected,
  selectedTheme,
  checklist,
  onCloseMenu,
  selectedChecklistRemark,
}: ChecklistNavigationComponentProps) => {
  return (
    <ChecklistNavigationView
      onThemeSelected={onThemeSelected}
      onRemarkSelected={onRemarkSelected}
      selectedTheme={selectedTheme}
      checklist={checklist}
      onCloseMenu={onCloseMenu}
      selectedChecklistRemark={selectedChecklistRemark}
    />
  );
};

export default ChecklistNavigationComponent;
