import AuditView from '../AuditView/AuditView';
import composeHooks from 'react-hooks-compose';
import { useAuditComponentService } from '../AuditComponentService';
import { building_field_values } from '../../../services/Building/BuildingDataModel';

export interface AuditProps {
  entity_id: string | number;
  location_id: string | number;
  audit_date: Date | string;
  auditor_email: string;
  building_field_values: building_field_values[];
}

/**
 * This component is responsible for the management of audits
 */
const AuditComponent = composeHooks({ useAuditComponentService })(AuditView);
export default AuditComponent;
