import { useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import AppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import {
  GET_CHECKLIST_BY_PK,
} from '../../Checklist/ChecklistReadService';
import { ChecklistThemeFormData } from './ChecklistThemeModel';
import {
  UPDATE_THEME_CHECKLIST,
  SET_NOT_APPLICATION_THEME_CHECKLIST_TRUE,
  SET_NOT_APPLICATION_SECTION_FALSE,
} from '.';
import {
  ChecklistSubThemeSectionInsertInputType,
  ChecklistThemeSectionInsertInputType,
} from '../../Checklist/ChecklistWriteService';
import { ChecklistItemInsertInputType } from '../../Checklist/ChecklistWriteService/ChecklistWriteService';
import { get_checklist_by_pk_theme_data } from '../../../services/Checklist/ChecklistReadService/ChecklistReadQuery';

export interface ThemeChecklistCreateResult {
  onUpdate: (id: number, data: ChecklistThemeFormData) => Promise<any>;
}

export interface ThemeChecklistSetNotApplicableResult {
  onSetNotApplicationFalse: ({ id: number }) => Promise<any>;
}

/**
 * Description - service to create a checklist theme
 */
export const useUpdateChecklistTheme = (
  checklistId,
): ThemeChecklistCreateResult => {
  const { setNotification } = useContext(AppContext);

  const [update] = useMutation(UPDATE_THEME_CHECKLIST, {
    refetchQueries: [
      { query: GET_CHECKLIST_BY_PK, variables: { id: checklistId } },
    ],
    awaitRefetchQueries: true,
    onError: () => {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const onUpdate = useCallback(
    async (id: number, data: ChecklistThemeFormData) => {
      return await update({ variables: { id: id, object: data } });
    },
    [update],
  );

  return { onUpdate };
};

/**
 * Description - service to create a checklist theme
 */
export const useSetNotApplicationChecklistThemeFalse = ({
  checklist_id,
}: {
  checklist_id;
}): ThemeChecklistSetNotApplicableResult => {
  const { setNotification } = useContext(AppContext);

  const [setNotApplicationFalse] = useMutation(
    SET_NOT_APPLICATION_SECTION_FALSE,
    {
      refetchQueries: [
        { query: GET_CHECKLIST_BY_PK, variables: { id: checklist_id } },
      ],
      awaitRefetchQueries: true,
      onError: () => {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    },
  );

  const onSetNotApplicationFalse = useCallback(
    async ({ id }: { id: number }) => {
      return await setNotApplicationFalse({ variables: { id } });
    },
    [setNotApplicationFalse],
  );

  return { onSetNotApplicationFalse };
};

export const useSetNotApplicationChecklistThemeTrue = ({
  checklist_id,
}: {
  checklist_id: number;
}) => {
  const { setNotification } = useContext(AppContext);

  const [setNotApplicationTrue] = useMutation(
    SET_NOT_APPLICATION_THEME_CHECKLIST_TRUE,
    {
      refetchQueries: [
        { query: GET_CHECKLIST_BY_PK, variables: { id: checklist_id } },
      ],
      awaitRefetchQueries: true,
      onError: () => {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    },
  );

  const onSetNotApplicationTrue = useCallback(
    ({ theme }: { theme: get_checklist_by_pk_theme_data }) => new Promise<void | number>((resolve) => {
      if (
        theme.checklist_theme_sections &&
        theme.checklist_theme_sections.length
      ) {
        const sub_theme_sections:
          | ChecklistSubThemeSectionInsertInputType[]
          | undefined = theme.checklist_theme_sections[0].checklist_theme_sub_sections?.map(
            (previous_sub_theme) => {
              if (
                previous_sub_theme.sub_theme_template_checklist &&
                previous_sub_theme.sub_theme_template_checklist.id
              ) {
                const sub_theme_section: ChecklistSubThemeSectionInsertInputType = {
                  sub_theme_template_checklist_id: previous_sub_theme.sub_theme_template_checklist.id,
                  name_fr: previous_sub_theme.name_fr,
                  name_nl: previous_sub_theme.name_nl,
                  not_applicable_option: previous_sub_theme.not_applicable_option,
                  index: previous_sub_theme.index,
                };

                const checklist_theme_items_in_sub_section:
                  | ChecklistItemInsertInputType[]
                  | undefined = previous_sub_theme.checklist_theme_items.map(
                    themeItem => ({
                      template_theme_item_id: themeItem.template_theme_item_id,
                      not_application: false,
                      has_interview: false,
                      name_fr: themeItem.name_fr,
                      name_nl: themeItem.name_nl,
                      norm_code: themeItem?.norm_code,
                      page_pdf_nl: themeItem?.page_pdf_nl,
                      page_pdf_fr: themeItem?.page_pdf_fr,
                    }),
                  );
                sub_theme_section.checklist_theme_items = {
                  data: checklist_theme_items_in_sub_section,
                };
                return sub_theme_section;
              } else {
                throw new Error(
                  'id from sub theme_template_checklits is required',
                );
              }
            },
          );

        const checklist_theme_items:
          | ChecklistItemInsertInputType[]
          | undefined = theme.checklist_theme_sections[0].checklist_theme_items?.map(
            ({ template_theme_item }) => ({
              template_theme_item_id: template_theme_item
                ? template_theme_item?.id
                : -1,
              not_application: false,
              has_interview: false,
              name_fr: template_theme_item ? template_theme_item.title_fr : '',
              name_nl: template_theme_item ? template_theme_item.title_nl : '',
              norm_code: template_theme_item?.norm_code,
              page_pdf_nl: template_theme_item?.page_pdf_nl,
              page_pdf_fr: template_theme_item?.page_pdf_fr,
            }),
          );
        if (
          theme.checklist_theme_sections[0].theme_template_checklist &&
          theme.checklist_theme_sections[0].theme_template_checklist.id
        ) {
          const checklist_theme_sections: ChecklistThemeSectionInsertInputType[] = [
            {
              checklist_theme_sub_sections: {
                data: sub_theme_sections,
              },
              theme_template_checklist_id:
                theme.checklist_theme_sections[0].theme_template_checklist?.id,
              specification: '',
              checklist_theme_items: {
                data: checklist_theme_items,
              },
              checklist_theme_id: theme.id,
              name_fr:
                theme.checklist_theme_sections[0].theme_template_checklist
                  .long_name_fr,
              name_nl:
                theme.checklist_theme_sections[0].theme_template_checklist
                  .long_name_nl,
              no_application: true,
            },
          ];

          setNotApplicationTrue({
            variables: {
              checklist_theme_id: theme.id,
              checklist_theme_sections,
            },
          }).then((response) => {
            if (response) {
              resolve(response.data.insert_qat_checklist_theme_section.returning[0].id)
            } else {
              console.error("No response from theme write service")
            }
          });
        } else {
          console.error(
            'theme_template_checklist?.id from theme.checklist_theme_sections[0] is required',
          );
          resolve()
        }
      }
    }),
    [setNotApplicationTrue],
  );


  return { onSetNotApplicationTrue };
};
