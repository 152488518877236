import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    title: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        padding: theme.spacing(0.5)
    },
    itemGrid: {
    },
    titleGrid: {
        marginTop:"4px",
        marginBottom: "0.5px"
    },
    address: {
        fontWeight: 'bold',
        padding: theme.spacing(0.5)
    },
    addressNumber: {
        margin: 10,
    },
    addressGrid: {
        backgroundColor: "#bdbdbd"
    },
    theme: {
        fontWeight: 'bold',
    },
    formControl: {
        height: "100%",
    },
    checklistThemeGrid: {
        height: "auto"
    },
    normCode: {
        cursor: "pointer",
        color: '#2979ff',
        fontWeight: 'bold',
        marginRight: theme.spacing(3),
        textDecoration: "none",
    },
    itemName: {
        fontWeight: 'bold',
    },
    itemTitle: {
        fontWeight: 'bold',
    },
    themeGrid: {
        fontWeight: 'bold',
    },
    sectionSpecification: {
        marginLeft: theme.spacing(1),
    },
    arrowIcon: {
        marginRight: theme.spacing(0.5),
        marginLeft: theme.spacing(0.5)
    },
    themeName: {
        fontWeight: 'bold',
    },
    sectionNotApplicable: {
        fontWeight: 'bold',
        color: "red"
    },
    specification: {
        paddingLeft: theme.spacing(1)
    },
    documentButton: {
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
        float: 'right',
        marginBottom:"4px"
    },
    content:{
        display:'flex',
        justifyContent:"space-around",
        flexFlow:'row',  
        alignItems:'stretch'
    }
}));

export { useStyles };
