import { gql } from '@apollo/client';

/**
 * Description - graphql query to create a checklist
 */
export const UPDATE_THEME_CHECKLIST = gql`
  mutation update_qat_checklist_theme_by_pk(
    $id: Int!
    $object: qat_checklist_theme_set_input!
  ) {
    update_qat_checklist_theme_by_pk(pk_columns: { id: $id }, _set: $object) {
      comment
    }
  }
`;

/**
 * Description - graphql mutation to set not applicable value to false in checklist theme
 */
export const SET_NOT_APPLICATION_SECTION_FALSE = gql`
  mutation update_qat_checklist_theme_by_pk($id: Int!) {
    update_qat_checklist_theme_by_pk(pk_columns: {id: $id}, _set: {not_applicable: false}) {
      id
    }
  }
`;

/**
 * Description - graphql mutation to set not applicable value to true and restore checklist_theme_section in checklist theme
 */
export const SET_NOT_APPLICATION_THEME_CHECKLIST_TRUE = gql`
  mutation restore_theme_checklist(
    $checklist_theme_id: Int!
    $checklist_theme_sections: [qat_checklist_theme_section_insert_input!]!
  ) {
    delete_qat_checklist_theme_section(
      where: { checklist_theme_id: { _eq: $checklist_theme_id } }
    ) {
      affected_rows
    }
    update_qat_checklist_theme(where: {id: {_eq: $checklist_theme_id}}, _set: {not_applicable: true}){
      affected_rows
    }
    insert_qat_checklist_theme_section(objects: $checklist_theme_sections) {
      returning{
        id
      }
    }
  }
`;
