import { useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { ChecklistItemFormData } from '../ChecklistItemFormData';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { GET_CHECKLIST_BY_PK } from '../../Checklist/ChecklistReadService';
import {
  UPDATE_CHECKLIST_ITEM,
  UPDATE_CHECKLIST_LIST_OF_ITEMS,
} from './ChecklistItemUpdateMutation';
import { GET_AUDIT_REPORT_INTERVIEW_BY_AUDIT_ID } from '../../Audit/ReadService/AuditReadQuery';
import { isContraintViolationError } from '../../../graphql/utils';

/**
 * Description - service to update a checklist item and refetch checklist
 */
export const useUpdateChecklistItem = (checklistId, showNotification) => {
  const { setNotification } = useContext(MainAppContext);

  const [updateItem] = useMutation(UPDATE_CHECKLIST_ITEM, {
    refetchQueries: [
      {
        query: GET_CHECKLIST_BY_PK,
        variables: { id: checklistId },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted() {
      showNotification &&
        setNotification({
          key: 'common:savedSuccess',
          type: NotificationType.SUCCESS,
        });
    },
    onError(error) {
       if (isContraintViolationError(error))
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      else
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
    },
  });

  const onUpdate = useCallback(
    (id: number, itemData: ChecklistItemFormData) => {
      const qat_checklist_theme_item_set_input = {
        ...itemData,
      };
      return updateItem({
        variables: { id, qat_checklist_theme_item_set_input },
      });
    },
    [updateItem],
  );

  return { onUpdate };
};

/**
 * Description - service to update a checklist item and refetch audit
 */
export const useUpdateChecklistItemRefetchAudit = (
  auditId,
  showNotification,
) => {
  const { setNotification } = useContext(MainAppContext);

  const [updateItem] = useMutation(UPDATE_CHECKLIST_ITEM, {
    refetchQueries: [
      {
        query: GET_AUDIT_REPORT_INTERVIEW_BY_AUDIT_ID,
        variables: { id: auditId },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted() {
      showNotification &&
        setNotification({
          key: 'common:savedSuccess',
          type: NotificationType.SUCCESS,
        });
    },
    onError(error) {
      if (isContraintViolationError(error))
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      else
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
    },
  });

  const onUpdate = useCallback(
    (id: number, itemData: ChecklistItemFormData) => {
      const qat_checklist_theme_item_set_input = {
        ...itemData,
      };
      updateItem({ variables: { id, qat_checklist_theme_item_set_input } });
    },
    [updateItem],
  );

  return { onUpdate };
};

/**
 * Description - service to update a checklist item and refetch checklist
 */
export const useUpdateChecklistListOfItem = (checklistId, showNotification) => {
  const { setNotification } = useContext(MainAppContext);

  const [updateListItem] = useMutation(UPDATE_CHECKLIST_LIST_OF_ITEMS, {
    refetchQueries: [
      {
        query: GET_CHECKLIST_BY_PK,
        variables: { id: checklistId },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted() {
      showNotification &&
        setNotification({
          key: 'common:savedSuccess',
          type: NotificationType.SUCCESS,
        });
    },
    onError(error) {
      if (isContraintViolationError(error))
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      else
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
    },
  });

  const onUpdateListOfItems = useCallback(
    (itemIds: number[]) => {
      return updateListItem({
        variables: { ids: itemIds },
      });
    },
    [updateListItem],
  );

  return { onUpdateListOfItems };
};
