import { gql } from '@apollo/client';

/**
 * Description - graphql mutation to create an building
 */
export const INSERT_BUILDING = gql`
  mutation insert_qat_audit_building_one(
    $object: qat_audit_building_insert_input!
  ) {
    insert_qat_audit_building_one(object: $object) {
      id
    }
  }
`;
