import { useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import AppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { GET_CHECKLIST_BY_PK } from '../../Checklist/ChecklistReadService';
import { ChecklistThemeSectionFormData } from './ChecklistThemeSectionModel';
import { UPDATE_SECTION } from './ChecklistThemeSectionWriteMutation';

export interface ThemeChecklistCreateResult {
  onUpdate: (id: number, data: ChecklistThemeSectionFormData) => Promise<any>;
}

/**
 * Description - service to update a section
 */
export const useUpdateChecklistThemeSection = (
  checklistId,
): ThemeChecklistCreateResult => {
  const { setNotification } = useContext(AppContext);

  const [update] = useMutation(UPDATE_SECTION, {
    refetchQueries: [
      { query: GET_CHECKLIST_BY_PK, variables: { id: checklistId } },
    ],
    awaitRefetchQueries: true,
    fetchPolicy: 'no-cache',
    onError: () => {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const onUpdate = useCallback(
    async (id: number, data: ChecklistThemeSectionFormData) => {
      return await update({ variables: { id: id, object: data } });
    },
    [update],
  );

  return { onUpdate };
};
