import React, { useState, useCallback } from 'react';
import PaginationComponent from '../../../../Shared/Pagination/PaginationComponent/PaginationComponent';
import AuditTableComponent from '../../AuditTableComponent/AuditTableComponent';
import { audit_data } from '../../../../../services/Audit/AuditDataModel';
import ConfirmDialog from '../../../../Shared/Dialog/ConfirmDialog/ConfirmDialogComponent/ConfirmDialogComponent';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../../../Shared/Container/PageContainer';

export interface AuditReadCurrentViewProps {
  audits: Array<audit_data>;
  onUpdate: (id: number) => void;
  onDelete: (id: number) => void;
  onClose: (id: number) => void;
}

export const testIds = {
  buttonOnCreate: 'button-create-audit',
  dialogConfirmDelete: 'dialog-confirm-delete',
  dialogConfirmClose: 'dialog-confirm-close',
  searchFieldComponent: 'search-field-component',
  paginationComponent: 'audit-pagination-component',
};

/**
 * Description - View of AudtRead component
 * @param param0
 */
const AuditReadCurrentView: React.FC<AuditReadCurrentViewProps> = ({
  audits,
  onUpdate,
  onDelete,
  onClose,
}) => {
  const { t } = useTranslation();
  const [
    openDeleteConfirmDialog,
    setOpenDeleteConfirmDialog,
  ] = useState<boolean>(false);
  const [auditIdToDelete, setAuditIdToDelete] = useState<number>(-1);

  const [openCloseConfirmDialog, setOpenCloseConfirmDialog] = useState<boolean>(
    false,
  );
  const [auditIdToClose, setAuditIdToClose] = useState<number>(-1);

  const handleDeleteButtonClick = useCallback((id: number) => {
    setOpenDeleteConfirmDialog(true);
    setAuditIdToDelete(id);
  }, []);

  const onConfirmedToDelete = useCallback(() => {
    if (auditIdToDelete !== -1) {
      onDelete(auditIdToDelete);
      setOpenDeleteConfirmDialog(false);
      setAuditIdToDelete(-1);
    } else {
      throw new Error("the entity id to delete doesn't exist");
    }
  }, [auditIdToDelete, onDelete]);

  const handleCloseButtonClick = useCallback((id: number) => {
    setOpenCloseConfirmDialog(true);
    setAuditIdToClose(id);
  }, []);

  const onConfirmedToClose = useCallback(() => {
    if (auditIdToClose !== -1) {
      onClose(auditIdToClose);
      setOpenCloseConfirmDialog(false);
      setAuditIdToClose(-1);
    } else {
      throw new Error("the entity id to close doesn't exist");
    }
  }, [auditIdToClose, onClose]);

  return (
    <PageContainer>
      <PaginationComponent items={audits} itemsPropsName="audits">
        <AuditTableComponent
          audits={audits}
          onUpdate={onUpdate}
          onDelete={handleDeleteButtonClick}
          onClose={handleCloseButtonClick}
        />
      </PaginationComponent>
      <ConfirmDialog
        dataTestId={testIds.dialogConfirmDelete}
        open={openDeleteConfirmDialog}
        onClose={() => setOpenDeleteConfirmDialog(false)}
        onConfirm={onConfirmedToDelete}
        title={t('common:delete')}
        text={t('common:confirmDelete')}
      />
      <ConfirmDialog
        dataTestId={testIds.dialogConfirmClose}
        open={openCloseConfirmDialog}
        onClose={() => setOpenCloseConfirmDialog(false)}
        onConfirm={onConfirmedToClose}
        title={t('audit:close')}
        text={t('audit:confirmClose')}
      />
    </PageContainer>
  );
};

export default AuditReadCurrentView;
