import AuditReadAllView, {
  AuditReadAllViewProps,
  testIds,
} from './AuditReadAllView';

export default AuditReadAllView;

export type { AuditReadAllViewProps };

export { testIds };
