import { useQuery, useApolloClient } from '@apollo/client';
import { useCallback, useContext } from 'react';
import AppContext from '../../../../AppContext';
import {
  GET_REMARK_BY_PK,
  GET_REMARK_INCLUDE_SOFT_DELETED_BY_ITEM,
  READ_ALL_REMARKS,
  READ_ALL_REMARKS_FROM_TEMPLATE_THEME_ITEM,
  READ_ALL_REMARKS_SORTED,
} from './ItemRemarkReadQuery';
import {
  get_remark_include_soft_deleted_by_item_data,
  get_remark_include_soft_deleted_by_item_response,
  get_remark_include_soft_deleted_by_item_variables,
  remarks_data,
  remarks_data_by_pk,
} from './ItemRemarkReadModel';
import { NotificationType } from '../../../../components/Notification/NotificationComponent';
import { OrderBy } from '../../../../components/Entity/EntityRead/EntityReadComponent/EntityReadComponent';
import { ItemRemarkFormData } from '../ItemRemarkFormData';
import { ItemRemarkDataUniqueErrorResponse } from '../ItemRemakDataModel';
import { useTranslation } from 'react-i18next';

/**
 * Description - service to get all remarks
 */
export const useGetAllItemRemarks = () => {
  const { setNotification } = useContext(AppContext);

  const { data, loading, error } = useQuery<remarks_data>(READ_ALL_REMARKS, {
    onError: errorResponse => {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });
  return {
    data: data?.qat_template_theme_item_remark
      ? data?.qat_template_theme_item_remark
      : [],
    loading,
    error,
  };
};


/**
 * Description - service to get all remark include soft deleted by item (template)
 */
export const useGetAllRemarkIncludeSoftDeletedByItem = () => {
  const { setNotification } = useContext(AppContext);
  const client = useApolloClient();

  const getAllRemarkIncludeSoftDeletedByItem = useCallback(
    ({
      template_theme_item_id,
    }: get_remark_include_soft_deleted_by_item_variables) => {
      return new Promise<get_remark_include_soft_deleted_by_item_data[]>(
        resolve => {
          client
            .query<
              get_remark_include_soft_deleted_by_item_response,
              get_remark_include_soft_deleted_by_item_variables
            >({
              query: GET_REMARK_INCLUDE_SOFT_DELETED_BY_ITEM,
              variables: { template_theme_item_id },
              fetchPolicy: 'network-only',
            })
            .then(result => {
              if (result.error) {
                setNotification({
                  key: 'common:generalError',
                  type: NotificationType.ERROR,
                });
                resolve([]);
              } else {
                resolve(result.data.qat_template_theme_item_remark);
              }
            }).catch(error => {
              setNotification({
                key: 'common:generalError',
                type: NotificationType.ERROR,
              });
            });
        },
      );
    },
    [client, setNotification],
  );

  return { getAllRemarkIncludeSoftDeletedByItem };
};

export const useItemRemarkCheckUnique = () => {
  const {
    getAllRemarkIncludeSoftDeletedByItem,
  } = useGetAllRemarkIncludeSoftDeletedByItem();

  const { t } = useTranslation()
  const onCheckUnique = useCallback((itemRemarkFormData: { remark_text_fr: string, remark_text_nl: string, template_theme_item_id: number, id?: number }) => {
    return new Promise<ItemRemarkDataUniqueErrorResponse | void>(resolve => {
      getAllRemarkIncludeSoftDeletedByItem({
        template_theme_item_id: itemRemarkFormData.template_theme_item_id,
      }).then(itemRemarks => {
        const doubleWithTextFr: get_remark_include_soft_deleted_by_item_data | undefined =
          !itemRemarkFormData.id
            ? itemRemarks.find((remark) => remark.remark_text_fr === itemRemarkFormData.remark_text_fr && remark.deleted_at === null)
            : itemRemarks.find((remark) => remark.remark_text_fr === itemRemarkFormData.remark_text_fr && remark.deleted_at === null && remark.id !== itemRemarkFormData.id )

        const doubleWithTextNl: get_remark_include_soft_deleted_by_item_data | undefined =
          !itemRemarkFormData.id
            ? itemRemarks.find((remark) => remark.remark_text_nl === itemRemarkFormData.remark_text_nl && remark.deleted_at === null)
            : itemRemarks.find((remark) => remark.remark_text_nl === itemRemarkFormData.remark_text_nl && remark.deleted_at === null && remark.id !== itemRemarkFormData.id)

        let errorMessage: ItemRemarkDataUniqueErrorResponse = {}
        if (doubleWithTextFr) {
          errorMessage.remark_text_fr = t('item_remark:remarkTextFrUniqueError')
        }
        if (doubleWithTextNl) {
          errorMessage.remark_text_nl = t('item_remark:remarkTextNlUniqueError')
        }    
        if (Object.keys(errorMessage).length) {
          resolve(errorMessage)
        } else {
          resolve()
        }

      })
    })
  }, [getAllRemarkIncludeSoftDeletedByItem, t])

  return { onCheckUnique }
}

export const useGetAllSortedItemRemarks = () => {
  const client = useApolloClient();
  const { setNotification } = useContext(AppContext);

  const orderItemsBy = useCallback(
    (orderBy: OrderBy) =>
      new Promise<ItemRemarkFormData[]>(resolve => {
        client
          .query<remarks_data>({
            query: READ_ALL_REMARKS_SORTED,
            variables: { order_by: { [orderBy?.column]: orderBy?.direction } },
          })
          .then(response => {
            resolve(response?.data?.qat_template_theme_item_remark);
          })
          .catch(errorResponse => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      }),
    [setNotification, client],
  );

  return { orderItemsBy };
};

export const useGetItemRemarkByIdDefault = id => {
  const { setNotification } = useContext(AppContext);

  let { data, loading, error } = useQuery<remarks_data_by_pk>(
    GET_REMARK_BY_PK,
    {
      variables: { id },
      onError: errorResponse => {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    },
  );
  return { data: data?.qat_template_theme_item_remark_by_pk, loading, error };
};

export const useGetAllItemRemarksFromItem = template_theme_item_id => {
  const { setNotification } = useContext(AppContext);

  let { data, loading, error } = useQuery<remarks_data>(
    READ_ALL_REMARKS_FROM_TEMPLATE_THEME_ITEM,
    {
      variables: { template_theme_item_id },
      onError: errorResponse => {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    },
  );
  return { data: data?.qat_template_theme_item_remark, loading, error };
};
