import React, { useMemo } from 'react';
import { get_checklist_by_pk_theme_data } from '../../../../services/Checklist/ChecklistReadService/ChecklistReadQuery';
import { ChecklistNavigationListItemView } from '../ChecklistNavigationListItemView';
import { checkThemeValidated } from '../../checklist.utils';

export interface ChecklistNavigationListItemComponentProps {
  onThemeSelected: () => void;
  selectedTheme: any;
  theme: get_checklist_by_pk_theme_data;
}

const ChecklistNavigationListItemComponent = ({
  onThemeSelected,
  selectedTheme,
  theme,
}: ChecklistNavigationListItemComponentProps) => {
  const isThemeValidated = useMemo(() => checkThemeValidated(theme), [theme]);

  return (
    <ChecklistNavigationListItemView
      onThemeSelected={onThemeSelected}
      selectedTheme={selectedTheme}
      theme={theme}
      isThemeValidated={isThemeValidated}
    />
  );
};

export default ChecklistNavigationListItemComponent;
