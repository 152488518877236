import React, {
    useCallback,
    useEffect, useMemo
} from "react";
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
  
} from "@material-ui/core";
import { useReactiveVar } from "@apollo/client";

import { useTranslation } from "react-i18next";
import { useStyles } from "../../../Shared/Styles/CommonFormSyles";
import { useForm } from "react-hook-form";
import {  useHistory } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LocationAddressFormData } from "../LocationAddressFormModel";
import { locationAddressVar, openCreateLocationAddressVar } from "../LocationAdressCache";
import { useGetAddressByLocationEntityId } from "../../../../services/Address/ReadService/AddressReadService";import { useBrowserLocationState } from "../../../Shared/BrowserLocationState/BrowserLocationState";


export interface LocationAddressFormComponentProps {
    isOpenForm: boolean;
    handleClose: any;
    handleFormSubmit: any;
    handleCreateLocationAddress?: any;
}
export const formDialogTestIds = {  
    updateCreateStreetInput: 'street-input',
    updateCreateLocationNameInput: 'location-name-input',
    updateCreateEntityNameInput: 'entity-name-input',
    updateCreateHouseNumberInput: 'house-number-input',
    updateCreateBoxNumberInput: 'box-number-input',        
    updateCreateApplyBtn: 'location-apply-btn',
    updateCreateCancelBtn: 'location-cancel-btn',
    updateCreateAddBtn: 'location-add-btn',
};

const schema = yup.object().shape({
    street: yup.string().nullable().required("streetError"),
    house_number: yup.string().nullable().required("houseNumberError"),
    box_number: yup.string().nullable(),   
});

const LocationAddressFormComponent: React.FC<LocationAddressFormComponentProps> = ({
    handleClose,
    isOpenForm,
    handleFormSubmit,
    handleCreateLocationAddress,
}) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const formLocationAddress: LocationAddressFormData = useReactiveVar(locationAddressVar);
    const isCreateLocationAddressForm = useReactiveVar(openCreateLocationAddressVar);
    const { register, errors, setValue, handleSubmit } = useForm({
        resolver: yupResolver(schema),
    });
    
    const currentLocationState = useBrowserLocationState<{entity: any, locationInfo: any}>({keysRequired: ["entity", "locationInfo"]})

    const currentEntity= useMemo(() => {
        return currentLocationState?.entity
            ? currentLocationState?.entity
            : ""
    }
        , [currentLocationState]) 
    
        const currentLocation= useMemo(() => {
            return currentLocationState?.locationInfo
                ? currentLocationState?.locationInfo
            : ""
    }
            , [currentLocationState])
      
        

    const entityName = useMemo(() => currentEntity ? currentEntity.label : "", [currentEntity]);  
    const locationName = useMemo(() => currentLocation ? currentLocation.name : "", [currentLocation]);  
   useEffect(
        () => {
            setValue("street", formLocationAddress.street);
            setValue("house_number", formLocationAddress.house_number);         
            setValue("box_number", formLocationAddress.box_number );
            
        }, [formLocationAddress, setValue],
    );
    useEffect(
        () => {
            register({ name: "street"});
            register({ name: "house_number"});           
            register({ name: "box_number"});
        },
        [register],
    );

    const handleChange = useCallback(
        e => {            
            if (e.target.value || e.target.value === '') {           

                locationAddressVar({
                    ...formLocationAddress,
                    [e.target.name]: e.target.value ? e.target.value:''
                });
                setValue(e.target.name, e.target.value as never);
            }
        }, [formLocationAddress, setValue])

    const { data } = useGetAddressByLocationEntityId(currentLocation ? currentLocation.id : -1);
    const history = useHistory();
    const handleFormClose = useCallback(
        () => {

            handleClose();
             if (data  && data.length === 0) {
                 history.goBack();
             }

        }, [handleClose,data, history]

    )
   

    return (
        <Dialog
            open={isOpenForm}
            onClose={(event, reason) => {
                if (reason === "backdropClick") {
                  return false;
                } else {
                  handleClose()
                }
              }}
            fullWidth={true}
        >
            <DialogContent>
                <h1>
                    {
                        isCreateLocationAddressForm
                            ? t("address:createAddress")
                            : t("address:updateAddress")
                    }
                </h1>
                <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={3}
                >
                    <Grid
                        item
                        className={classes.control}
                    >
                        <TextField
                            data-testid={formDialogTestIds.updateCreateEntityNameInput}
                            name="entity_name"
                            aria-readonly={true}
                            label={t("address:entityName")}
                            variant="outlined"
                            fullWidth
                            value={entityName}
                        />
                    </Grid>   
                     <Grid
                        item
                        className={classes.control}
                    >
                        <TextField
                            data-testid={formDialogTestIds.updateCreateLocationNameInput}
                            name="location_name"
                            aria-readonly={true}
                            label={t("address:locationName")}
                            variant="outlined"
                            fullWidth
                            value={locationName}
                        />
                    </Grid>
                    <Grid
                        item
                        className={classes.control}
                    >
                        <TextField
                            data-testid={formDialogTestIds.updateCreateStreetInput}                       
                            name="street"
                            value={formLocationAddress.street}
                            label={t('address:street')}
                            helperText={errors.street && t('address:streetError')}
                            variant="outlined"
                            fullWidth
                            error={errors.street?true:false}                            
                            onChange={(event) => handleChange(event)}     />

                    </Grid>                    
                    <Grid item
                        className={classes.control}>
                        <Grid item className={classes.control}>
                            <TextField                                                              
                                value={formLocationAddress.house_number}
                                data-testid={formDialogTestIds.updateCreateHouseNumberInput}                                                
                                name="house_number"
                                label={t('address:houseNumber')}
                                helperText={errors.house_number && t('address:houseNumberError') }
                                variant="outlined"
                                fullWidth
                                error={errors.house_number ?true:false  }
                                onChange={(event) => handleChange(event)}
                                  />                       

                        </Grid>
                    </Grid>
                    <Grid
                        item
                        className={classes.control}
                    >
                        <TextField                            
                            data-testid={formDialogTestIds.updateCreateBoxNumberInput}
                            name="box_number"
                            value={formLocationAddress.box_number}
                            label={t('address:boxNumber')}
                            helperText={errors.box_number && t('address:boxNumberError')}
                            variant="outlined"
                            fullWidth
                            error={errors.box_number ?true:false }
                            onChange={(event)=>handleChange(event)}
                        />
                    
                    </Grid>                    
                </Grid>
            </DialogContent>
            <DialogActions
                className={classes.divButton}
            >
                {handleCreateLocationAddress ? (
                    <Button
                    data-testid={formDialogTestIds.updateCreateAddBtn}
                    className={classes.buttonValidateAdd}
                    id="applyCreateAddBtn"        
                        onClick={handleSubmit( handleCreateLocationAddress)}
                    fullWidth
                >
                    {t("address:validateAdd")}
                </Button>):''}
                <Button
                    data-testid={formDialogTestIds.updateCreateApplyBtn}
                    className={classes.buttonValidate}
                    id="applyUpdateBtn"                   
                    onClick={handleSubmit(handleFormSubmit)}
                    fullWidth
                >
                    {t("common:validate")}
                </Button>
                <Button
                    data-testid={formDialogTestIds.updateCreateCancelBtn}
                    id="cancelUpdateBtn"
                    onClick={() => handleFormClose()}
                    variant="contained"
                    color="default"
                    fullWidth
                >
                    {t("common:cancel")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default LocationAddressFormComponent;