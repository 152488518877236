import React, { useContext } from 'react';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import AppContext from '../../../AppContext';
import { translate } from '../../../services/Translation/TranslationService';
import ChecklistItemComponent from '../../ChecklistItem/ChecklistItemComponent';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import ConfirmDialog from '../../Shared/Dialog/ConfirmDialog/ConfirmDialogComponent/ConfirmDialogComponent';
import { useTranslation } from 'react-i18next';
import { get_checklist_by_pk_sub_section_data } from '../../../services/Checklist/ChecklistReadService/ChecklistReadQuery';
import { isMobile } from 'react-device-detect';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';

export const testIds = {
  buttonToSetNotApplication:
    'checklist-theme-subsection-set-not-application-button',
  confirmDialogNotApplication: 'confirm-dialog-not-application',
  checkAllButtonSubSection: 'checklist-theme-subsection-check-all-button'
};

const useStyles = makeStyles(theme => ({
  root: {},
  rootShadow: {
    backgroundColor: '#d1d1d1',
  },
  title: {
    color: '#cf012c',
    fontSize: '0.875rem',
    fontFamily: 'Poppins',
    paddingTop: '15px',
  },
  button: {
    padding: theme.spacing(0.5),
  },
  dividerColor: {
    backgroundColor: '#333333',
  },
  conform: {
    color: '#4caf50',
  },
  conformButton: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(0.10),
  },
  leftPart: {
    display: 'flex',
    alignItems: 'left',
  },
  rightPart: {
    display: 'flex',
    alignItems: 'right',
  },
}));

export interface ChecklistThemeSubSectionViewProps {
  subSection: get_checklist_by_pk_sub_section_data;
  checklistId: number;
  onUpdateNoApplication: () => void;
  onConfirmUpdateNoApplication: () => void;
  openConfirmNoApplication: boolean;
  onCloseConfirmNoApplication: () => void;
  colorNotApplicationButton: 'error' | 'disabled';
  noApplication?: boolean;
  isReadOnlyMode: boolean;
  onConformSubSectionChanged: (event, subSection) => void;
}

const ChecklistThemeSubSectionView: React.FC<ChecklistThemeSubSectionViewProps> = ({
  subSection,
  checklistId,
  onUpdateNoApplication,
  onConfirmUpdateNoApplication,
  openConfirmNoApplication,
  onCloseConfirmNoApplication,
  colorNotApplicationButton,
  noApplication,
  isReadOnlyMode,
  onConformSubSectionChanged
}) => {
  const { language } = useContext(AppContext);
  const classes = useStyles();
  const { t } = useTranslation();

  const isReadOnlyModeOnclick = e => e.preventDefault();
  return (
    <>
      <Divider className={classes.dividerColor} style={{ height: 2 }} />
      <Grid container
        className={
          (subSection?.no_application === true && classes.rootShadow) || classes.root
        }
        spacing={1}>
        <Grid lg={9} xs={7} item className={classes.leftPart}>
          <Typography variant="subtitle1" className={classes.title}>
            {subSection.sub_theme_template_checklist &&
              translate(subSection, language)}
            {subSection.not_applicable_option && (
              <IconButton
                className={classes.button}
                data-testid={testIds.buttonToSetNotApplication}
                onClick={
                  isReadOnlyMode
                    ? isReadOnlyModeOnclick
                    : e => {
                      e.stopPropagation();
                      onUpdateNoApplication();
                    }
                }
                aria-label="button-to-set-not-application"
              >
                <CancelPresentationIcon color={colorNotApplicationButton} />
              </IconButton>
            )}
          </Typography>
        </Grid>
        <Grid lg={3} xs={5} item className={classes.rightPart}>
          <Box ml={isMobile ? 5 : 0}>
            <IconButton
              className={classes.conformButton}
              onClick={e => onConformSubSectionChanged(e, subSection)}
              aria-label="button-to-check-all-item"
              data-testid={testIds.checkAllButtonSubSection}
              disabled={isReadOnlyMode}
            >
              <CheckBoxOutlinedIcon
                style={{ fontSize: '35px' }}
                className={classes.conform}
              />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {subSection?.checklist_theme_items?.map(item => {
            return (
              noApplication === false && (
                <ChecklistItemComponent
                  key={item?.id}
                  item={item}
                  checklistId={checklistId}
                  isReadOnlyMode={isReadOnlyMode}
                />
              )
            );
          })}
        </Grid>
      </Grid>

      <ConfirmDialog
        dataTestId={testIds.confirmDialogNotApplication}
        title={t('checklist_theme:confirmNotApplicationTitle')}
        text={t('checklist_theme:confirmNotApplicationText')}
        onConfirm={onConfirmUpdateNoApplication}
        open={openConfirmNoApplication}
        onClose={onCloseConfirmNoApplication}
      />
    </>
  );
};

export default ChecklistThemeSubSectionView;
