import { useApolloClient, useQuery } from '@apollo/client';
import { useCallback, useContext, useMemo } from 'react';
import MainAppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import {
  list_sub_theme_template_checklist_include_soft_delete_by_template_id_data,
  list_sub_theme_template_checklist_include_soft_delete_by_template_id_variables,
  READ_SUB_THEME_TEMPLATE_CHECKLIST_BY_THEME_ID,
  GET_SUB_THEME_INCLUDE_SOFT_DELETE_BY_THEME,
  sub_theme_template_checklist_data_from_list,
  sub_theme_template_checklist_data_list,
  list_sub_theme_template_checklist_include_soft_delete_by_template_id_response,
} from '.';
import { SubThemeFormData } from '../../../components/SubTheme/SubThemeFormModel';
import { useTranslation } from 'react-i18next';

/**
 * Description - service to get all sub_theme template checklist by template checklist id
 */
export const useGetSubThemeByIdService = ({ themeId }) => {
  const { setNotification } = useContext(MainAppContext);

  const {
    data,
    loading,
    error,
  } = useQuery<sub_theme_template_checklist_data_list>(
    READ_SUB_THEME_TEMPLATE_CHECKLIST_BY_THEME_ID,
    {
      variables: { themeId },
      fetchPolicy: 'network-only',
      onError(errorResponse) {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
      skip: themeId === -1,
    },
  );

  const sub_themes: sub_theme_template_checklist_data_from_list[] = useMemo(() => {
    return !loading && data?.qat_sub_theme_template_checklist
      ? data?.qat_sub_theme_template_checklist
      : [];
  }, [data, loading]);

  return { data: sub_themes, loading, error };
};

export interface SubThemeUniqueResponse {
  name_fr?: string
  name_nl?: string
}

export const useCheckSubThemeUnique = () => {

  const {getSubThemeIncludeSoftDeleteByTheme} = useGetSubThemeIncludeSoftDeleteByTheme()
  const {t} = useTranslation()
  const onCheckSubThemeUnique = useCallback((data: SubThemeFormData, {template_theme_id}) => {
    return new Promise<SubThemeUniqueResponse | void>(resolve => {
      getSubThemeIncludeSoftDeleteByTheme({
        theme_template_checklist_id: template_theme_id,
      }).then(subThemes => {
        const doubleNameFr = 
          !data.id
            ? subThemes.find(subTheme => subTheme.name_fr === data.name_fr && subTheme.deleted_date === null)
            : subThemes.find(subTheme => subTheme.name_fr === data.name_fr && subTheme.id !== data.id && subTheme.deleted_date === null)
        const doubleNameNl = 
          !data.id
            ? subThemes.find(subTheme => subTheme.name_nl === data.name_nl && subTheme.deleted_date === null)
            : subThemes.find(subTheme => subTheme.name_nl === data.name_nl && subTheme.id !== data.id && subTheme.deleted_date === null)
  
        const errorMessage: SubThemeUniqueResponse = {}    
        if(doubleNameFr){
          errorMessage.name_fr = t("subtheme:nameFrUniqueError")
        }
        
        if(doubleNameNl){
          errorMessage.name_nl = t("subtheme:nameNlUniqueError")
        }
        
        if(Object.keys(errorMessage).length){
          resolve(errorMessage)
        } else {
          resolve()
        }
      });
    })
  },[getSubThemeIncludeSoftDeleteByTheme, t])

  return {onCheckSubThemeUnique}
}

/**
 * Description - Service to get sub theme include soft delete by template theme
 */
export const useGetSubThemeIncludeSoftDeleteByTheme = () => {
  const { setNotification } = useContext(MainAppContext);
  const client = useApolloClient();

  const getSubThemeIncludeSoftDeleteByTheme = useCallback(
    ({
      theme_template_checklist_id,
    }: list_sub_theme_template_checklist_include_soft_delete_by_template_id_variables) => {
      return new Promise<
        list_sub_theme_template_checklist_include_soft_delete_by_template_id_data[]
      >(resolve => {
        client
          .query<
            list_sub_theme_template_checklist_include_soft_delete_by_template_id_response,
            list_sub_theme_template_checklist_include_soft_delete_by_template_id_variables
          >({
            query: GET_SUB_THEME_INCLUDE_SOFT_DELETE_BY_THEME,
            variables: { theme_template_checklist_id },
            fetchPolicy: 'network-only',
          })
          .then(response => {
            if (response.error) {
              setNotification({
                key: 'common:generalError',
                type: NotificationType.ERROR,
              });
              resolve([]);
            } else {
              resolve(response.data.qat_sub_theme_template_checklist);
            }
          }).catch(error => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      });
    },
    [setNotification, client],
  );

  return { getSubThemeIncludeSoftDeleteByTheme };
};
