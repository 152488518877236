import React, { useCallback} from 'react';
import { useReactiveVar } from '@apollo/client';
import { openUpdateItemRemarkVar, itemRemarkVar } from '../ItemRemarkCache';
import { ItemRemarkFormData } from '../../../../services/Item/Remark/ItemRemarkFormData';
import ItemRemarkFormComponent from './ItemRemarkFormDialogComponent';
import { useUpdateItemRemarkFromItem } from '../../../../services/Item/Remark/UpdateService/ItemRemarkUpdateService';
import { useBrowserLocationState } from '../../../Shared/BrowserLocationState/BrowserLocationState';

export interface tableProps {
  openUpdateForm: boolean;
}

const ItemRemakUpdateDialogComponent = () => {
  const isOpenUpdateForm: boolean = useReactiveVar(openUpdateItemRemarkVar);
  const updateItemRemark: ItemRemarkFormData = useReactiveVar(itemRemarkVar);

  const currentLocationState = useBrowserLocationState<{item: any}>({keysRequired: ["item"]})

  const currentItemId: number = currentLocationState?.item
    ? currentLocationState?.item?.id
    : -1;
  const { onUpdateItemRemarkFromItem } = useUpdateItemRemarkFromItem(
    currentItemId,
  );
  const defaultItemRemark: ItemRemarkFormData = {
    id: -1,
    remark_text_fr: '',
    remark_text_nl: '',
    template_theme_item_id: -1,
    index: -1,
  };

  const handleUpdateItemRemark = () => {
    let itemRemarkForm: ItemRemarkFormData;
    itemRemarkForm = {
      remark_text_fr: updateItemRemark.remark_text_fr,
      remark_text_nl: updateItemRemark.remark_text_nl,
      template_theme_item_id: updateItemRemark.template_theme_item_id,
      index: updateItemRemark.index,
    };
    if (updateItemRemark.id) {
      onUpdateItemRemarkFromItem(updateItemRemark.id, itemRemarkForm);
      openUpdateItemRemarkVar(false);
      itemRemarkVar(defaultItemRemark);
    }
  };

  const handleClose = useCallback(() => {
    openUpdateItemRemarkVar(false);
    itemRemarkVar(defaultItemRemark);
  }, [defaultItemRemark]);

  return (
    <ItemRemarkFormComponent
      isOpenForm={isOpenUpdateForm}
      handleClose={handleClose}
      handleFormSubmit={handleUpdateItemRemark}
      templateThemeItemId={currentItemId}
      id={updateItemRemark.id}
    />
  );
};
export default ItemRemakUpdateDialogComponent;
