import React from 'react';
import routes from '../../../routes';
import EntityUpdateComponent from '../EntityUpdate/EntityUpdateComponent';
import { Switch, Route, Redirect } from 'react-router-dom';
import EntityReadComponent from '../EntityRead/EntityReadComponent';
import EntityCreateComponent from '../EntityCreate/EntityCreateComponent/EntityCreateComponent';
import { entity_data } from '../../../services/Entity/EntityDataModel';

export interface EntityViewProps {
  entities: Array<entity_data>;
  loading: boolean;
  error: any;
  handleIdToUpdate: (id: number) => void;
  onDelete: (id: number) => void;
  idToUpdate: number;
}

export const testIds = {
  editEntityComponent: 'edit-entity-component',
  createEntityComponent: 'create-entity-component',
  listEntityComponent: 'list-entity-component',
};

/**
 * Description - Component navigate in different entity component,
 */
const EntityView: React.FC<EntityViewProps> = ({
  entities,
  loading,
  onDelete,
  handleIdToUpdate,
  idToUpdate,
}) => (
  <Switch>
    <Route path={routes.allEntities.path}>
      <div data-testid={testIds.listEntityComponent}>
        <EntityReadComponent
          listEntities={entities}
          loading={loading}
          onUpdate={handleIdToUpdate}
          onDelete={onDelete}
        />
      </div>
    </Route>
    <Route path={routes.createEntity.path}>
      <div data-testid={testIds.createEntityComponent}>
        <EntityCreateComponent />
      </div>
    </Route>
    <Route path={routes.editEntity.path}>
      {idToUpdate !== 0 ? (
        <div data-testid={testIds.editEntityComponent}>
          <EntityUpdateComponent idToUpdate={idToUpdate} />
        </div>
      ) : (
        <Redirect to={routes.allEntities.path} />
      )}
    </Route>
  </Switch>
);

export default EntityView;
