
import {useMemo} from "react";
import { useApolloClient, useQuery } from '@apollo/client';
import {
  addresses_data,
  address_data_by_pk,
  is_unique_address_response,
  is_unique_address_variables,
  is_unique_address_data,
  address_location_data,
  get_all_location_address_include_deleted_response,
  get_all_location_address_active_response,
} from './AddressReadModel';
import { useContext, useCallback } from 'react';
import AppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import {
  GET_ADDRESS_BY_PK,
  IS_UNIQUE_ADDRESS,
  READ_ALL_ADDRESSES,
  READ_ALL_ADDRESSES_BY_LOCATION_ID,
  READ_ALL_ADDRESSES_SOFT_DELETE_BY_LOCATION_ID,
} from './AddressReadQuery';

interface AddressFormData {
  address_id: number;
}

/**
 * Description - service to get all addresss
 */
export const useGetAllAddress = () => {
  let { data, loading, error } = useQuery<addresses_data>(READ_ALL_ADDRESSES);
  return { data: data?.qat_address, loading, error };
};

export const useGetAddressByIdDefault = id => {
  const { setNotification } = useContext(AppContext);

  let { data, loading, error } = useQuery<address_data_by_pk>(
    GET_ADDRESS_BY_PK,
    {
      variables: { id },
      onError: errorResponse => {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    },
  );
  return { data: data?.qat_address_by_pk, loading, error };
};

export const useGetAddressById = () => {
  const client = useApolloClient();
  const { setNotification } = useContext(AppContext);

  const getAddressById = useCallback(
    id =>
      new Promise<any>(resolve => {
        client
          .query<address_data_by_pk>({
            query: GET_ADDRESS_BY_PK,
            variables: { id },
          })
          .then(response => {
            resolve(response.data.qat_address_by_pk);
          })
          .catch(error => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      }),
    [setNotification, client],
  );

  return { getAddressById };
};

export const useGetAddressByLocationEntityId = (location_entity_id) => {  
  const { setNotification } = useContext(AppContext); 
  let { data, loading, error } = useQuery<addresses_data>(
    READ_ALL_ADDRESSES_BY_LOCATION_ID,
    {
      variables: { location_entity_id},
      fetchPolicy:"network-only",
      onError: errorResponse => {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    },
  );

  const addresses = useMemo(() => data ? data.qat_address : [], [data])

  return { data: addresses , loading, error }; 
};

export const useGetAddressSoftDeleteByLocationEntityId = (location_entity_id) => {  
  const { setNotification } = useContext(AppContext); 
  let { data, loading, error } = useQuery<addresses_data>(
    READ_ALL_ADDRESSES_SOFT_DELETE_BY_LOCATION_ID,
    {
      variables: { location_entity_id},
      onError: errorResponse => {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    },
  );
  return { data: data?.qat_address ? data?.qat_address:[] , loading, error }; 
};

/**
 * Description - service to return condition is it is unique address
 */
export const useIsUniqueAddress = () => {
  const client = useApolloClient();
  const { setNotification } = useContext(AppContext);

  const isUniqueAddress = useCallback(
    (variables: is_unique_address_variables): Promise<boolean> => {
      return new Promise(resolve => {
        client
          .query<is_unique_address_response, is_unique_address_variables>({
            query: IS_UNIQUE_ADDRESS,
            variables,
            fetchPolicy: 'network-only',
          })
          .then(response => {
            const isUnique = response.data.qat_address.length ? false : true;
            resolve(isUnique);
          })
          .catch(() => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
            resolve(true);
          });
      });
    },
    [setNotification, client],
  );

  return { isUniqueAddress };
};


export const useGetAllAddressIncludeDeleted = () => {
  const client = useApolloClient();
  const { setNotification } = useContext(AppContext);

  const getLocationAddresses = useCallback(
    ({
     location_entity_id
    }: {
        location_entity_id: number|string;      
    }) =>
      new Promise<address_location_data[]>(resolve => {
        client.query<get_all_location_address_include_deleted_response,is_unique_address_data
          >({
            variables:{location_entity_id },  
            query: READ_ALL_ADDRESSES_SOFT_DELETE_BY_LOCATION_ID,
            fetchPolicy: "network-only"
          })
          .then(result => {
            if (result.error) {
              setNotification({
                key: 'common:generalError',
                type: NotificationType.ERROR,
              });
              resolve([]);
            }
            resolve(
              result.data.qat_address.map(
                ({  ...rest }) => ({ ...rest }),
              ),
            );
          }).catch(error => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      }),
    [client, setNotification],
  );

  return { getLocationAddresses };
};

export const useGetAllAddressActiveByLocationId = () => {
  const client = useApolloClient();
  const { setNotification } = useContext(AppContext);

  const getAllAddressActiveByLocationId = useCallback(
    ({
     location_entity_id
    }: {
        location_entity_id: number|string;      
    }) =>
      new Promise<address_location_data[]>(resolve => {
        client.query<get_all_location_address_active_response,is_unique_address_data
          >({
            variables:{location_entity_id },  
            query: READ_ALL_ADDRESSES_BY_LOCATION_ID,
            fetchPolicy: "network-only"
          })
          .then(result => {
            if (result.error) {
              setNotification({
                key: 'common:generalError',
                type: NotificationType.ERROR,
              });
              resolve([]);
            }
            resolve(
              result.data.qat_address.map(
                ({  ...rest }) => ({ ...rest }),
              ),
            );
          }).catch(error => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      }),
    [client, setNotification],
  );

  return { getAllAddressActiveByLocationId };
};