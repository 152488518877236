import { Button, CircularProgress, Grid, TextField } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './AddressFormStyle';
import { Controller } from 'react-hook-form';
import { AddressFormViewProps } from '../AddressFormComponent';

export const testIds = {
  streetInput: 'street-input',
  houseNumberInput: 'house-number-input',
  boxNumberInput: 'box-number-input',
  cityInput: 'city-input',
  locationIdInput: 'location-id-input',
  buttonValidate: 'address-button-validation',
  cancelUpdateBtn: 'address-button-cancel',
};

/**
 * Description - Form to create address
 */
const AddressFormView: React.FC<AddressFormViewProps> = ({
  errors,
  onSubmit,
  control,
  title,
  submitButtonLabel,
  locationLoading,
  handleClose
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Fragment>
      <form onSubmit={onSubmit}>
        <h1>{t(title)}</h1>
        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={3}
        >
          <Grid item className={classes.control}>
            <Controller
              control={control}
              name="street"
              defaultValue={''}
              as={
                <TextField
                  id="street"
                  data-testid={testIds.streetInput}
                  name="street"
                  label={t('address:street')}
                  helperText={errors.street && t('address:streetError')}
                  variant="outlined"
                  fullWidth
                  error={errors.street !== undefined || errors.street === null}
                />
              }
            ></Controller>
          </Grid>
          <Grid item className={classes.control}>
            <Controller
              control={control}
              name="house_number"
              defaultValue={''}
              as={
                <TextField
                  id="house_number"
                  data-testid={testIds.houseNumberInput}
                  type="number"
                  inputProps={{ min: '1' }}
                  name="house_number"
                  label={t('address:houseNumber')}
                  helperText={
                    errors.house_number && t('address:houseNumberError')
                  }
                  variant="outlined"
                  fullWidth
                  error={
                    errors.house_number !== undefined ||
                    errors.house_number === null
                  }
                />
              }
            ></Controller>
          </Grid>
          <Grid item className={classes.control}>
            <Controller
              control={control}
              name="box_number"
              defaultValue={''}
              as={
                <TextField
                  id="box_number"
                  data-testid={testIds.boxNumberInput}
                  name="box_number"
                  label={t('address:boxNumber')}
                  helperText={errors.box_number && t('address:boxNumberError')}
                  variant="outlined"
                  fullWidth
                  error={
                    errors.box_number !== undefined ||
                    errors.box_number === null
                  }
                />
              }
            ></Controller>
          </Grid>

          <Grid item className={classes.control}>
            {locationLoading && <CircularProgress />}
            <Controller
              control={control}
              name="city"
              defaultValue={''}
              as={
                <TextField
                  id="city"
                  data-testid={testIds.cityInput}
                  type="city"
                  name="city"
                  label={t('address:city')}
                  helperText={errors.city && t('address:cityError')}
                  variant="outlined"
                  fullWidth
                  disabled
                  error={errors.city !== undefined || errors.city === null}
                />
              }
            ></Controller>
          </Grid>

          <Grid item className={classes.control}>
            <Button
              type="submit"
              id="validate"
              data-testid={testIds.buttonValidate}
              variant="contained"
              color="primary"
              fullWidth
            >
              {t(submitButtonLabel)}
            </Button>
            <Button
              fullWidth
              id="cancelUpdateBtn"
              className={classes.grey}
              data-testid={testIds.cancelUpdateBtn}
              onClick={() => handleClose()}
            >
              {t('common:cancel')}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Fragment>
  );
};

export default AddressFormView;
