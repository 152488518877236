import { useState, useCallback, useContext } from 'react';
import { debounce } from 'lodash';
import {
  checklist_theme_item,
  checklist_theme_item_remark,
} from '../../../services/Checklist/ChecklistReadService/CheckistReadModel';
import { useUpdateChecklistItem } from '../../../services/ChecklistItem/UpdateService/ChecklistItemUpdateService';
import { useBeforeunload } from 'react-beforeunload';
import AppContext from '../../../AppContext';
import { NotificationType } from '../../Notification/NotificationComponent';
import {
  useUpdateAllCheckValueInItem,
  useUpdateCheckValue,
} from '../../../services/ChecklistItemRemark/UpdateService/ChecklistItemRemarkUpsertService';

export interface ChecklistItemViewProps {}

export interface ChecklistItemPresenterProps {
  item: checklist_theme_item;
  checklistId: number;
  remarks?: checklist_theme_item_remark[];
}

export const useChecklistItemRemarkPresenter = ({
  item,
  checklistId,
  remarks,
}: ChecklistItemPresenterProps) => {
  const [comment, setComment] = useState(item?.comment);
  const { onUpdate } = useUpdateChecklistItem(checklistId, false);
  const { setNotification } = useContext(AppContext);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const { onUpdateCheckValue } = useUpdateCheckValue({
    checklist_id: checklistId,
    showNotification: false,
  });
  const { onUpdateAllCheckValueInItem } = useUpdateAllCheckValueInItem({
    checklist_id: checklistId,
    showNotification: false,
  });

  const debouncedSaveComment = useCallback(
    debounce(
      (nextValue, themeId) =>
        onUpdate(themeId, { comment: nextValue })?.then(response => {
          handleSetState(false);
        }),
      1000,
    ),
    [],
  );
  const handleSetState = useCallback(isSavingVar => {
    setIsSaving(isSavingVar);
  }, []);

  useBeforeunload(event => {
    if (isSaving === true) {
      event.preventDefault();
      setNotification({ key: 'common:saving', type: NotificationType.WARNING });
      return null;
    }
  });

  const handleChangeComment = event => {
    handleSetState(true);
    const { value: nextValue } = event.target;
    setComment(nextValue);
    debouncedSaveComment(nextValue, item?.id);
  };

  const handleDeativateRemark = useCallback(
    (event, _remark) => {
      onUpdateCheckValue({ id: _remark.id, checked: false });
    },
    [onUpdateCheckValue],
  );

  const handleRemarkChanged = useCallback(
    (event, _remark) => {
      onUpdateCheckValue({ id: _remark.id, checked: !_remark.checked });
    },
    [onUpdateCheckValue],
  );

  const handleDeactivateAllRemarks = useCallback(() => {
    if (item.id) {
      onUpdateAllCheckValueInItem({
        checklist_theme_item_id: item.id,
        checked: false,
      });
    } else {
      console.warn('item.id is required');
    }
  }, [onUpdateAllCheckValueInItem, item]);

  return {
    onChangeComment: handleChangeComment,
    comment,
    onDeativateRemark: handleDeativateRemark,
    onDeactivateRemarks: handleDeactivateAllRemarks,
    onRemarkChanged: handleRemarkChanged,
  };
};
