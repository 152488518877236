import { useContext, useMemo } from "react"
import AppContext from "../../../../AppContext"
import { useGetAdminDocumentByPk, useGetContentService } from "../../../../services/AdminDocument/AdminDocumentRead/AdminDocumentRead"
import { translateTitle } from "../../../../services/Translation/TranslationService"

export interface AdminDocumentUploadPreviewServiceResult {
    documentInfo: {
        title: string;
        date: string;
    };
    loadingDocumentInfo: boolean;
    showDocumentInfo: boolean;
    content: string | null;
    loadingContent: boolean;
    noContentUpload: boolean;
}

export const useAdminDocumentUploadPreviewService = ({ id }): AdminDocumentUploadPreviewServiceResult => {

    const { language } = useContext(AppContext)

    const { data, loading: loadingDocumentInfo } = useGetAdminDocumentByPk({ id })

    const { title_fr, title_nl, last_updated_date } = useMemo(() => {
        return data ? data : {
            title_fr: "",
            title_nl: "",
            last_updated_date: null
        }
    }
        , [data])

    const url: string | null = useMemo(() => {
        return data && data.url ? data.url : null
    }, [data])

    const { content, loading: loadingContent } = useGetContentService({ url })

    const title: string = useMemo(() => translateTitle({ title_fr, title_nl }, language), [title_fr, title_nl, language])
    const date: string = useMemo(() => last_updated_date ? `${last_updated_date.getDate()}/${last_updated_date.getMonth() + 1}/${last_updated_date.getFullYear()}` : "", [last_updated_date])
    
    const showDocumentInfo = useMemo(() => {
        return title === "" || date === "" ? false : true
    }, [title, date])

    const noContentUpload: boolean = useMemo(() => {
        return data && data.url === null ? true : false
    },[data])

    return {documentInfo: {title, date}, loadingDocumentInfo , showDocumentInfo, content, loadingContent, noContentUpload}
}