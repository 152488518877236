import { gql } from '@apollo/client';

/**
 * Description - graphql mutation to upsert a checklist item remark
 */
export const UPSERT_CHECKLIST_ITEM_REMARK = gql`
  mutation update_qat_checklist_theme_item_remark_by_pk(
    $qat_checklist_theme_item_remark_insert_input: qat_checklist_theme_item_remark_insert_input!
  ) {
    insert_qat_checklist_theme_item_remark_one(
      object: $qat_checklist_theme_item_remark_insert_input
    ) {
      id
    }
  }
`;

/**
 * Description - graphql mutation to upsert a checklist item remark
 */
export const UPDATE_CHECKLIST_ITEM_REMARK_BY_PK = gql`
  mutation update_qat_checklist_theme_item_remark_by_pk(
    $id: Int!
    $qat_checklist_theme_item_remark_set_input: qat_checklist_theme_item_remark_set_input!
  ) {
    update_qat_checklist_theme_item_remark_by_pk(
      pk_columns: { id: $id }
      _set: $qat_checklist_theme_item_remark_set_input
    ) {
      id
    }
  }
`;

export interface update_checked_value_theme_tem_remark_variables {
  id: number;
  checked: boolean;
}

export interface update_checked_value_theme_tem_remark_response {
  update_qat_checklist_theme_item_remark_by_pk: {
    id: number;
  };
}

/**
 * Description: graphql mutation to update checked value in checklist item remark
 */
export const UPDATE_CHECK_VALUE_ITEM_REMARK = gql`
  mutation update_checked_value_theme_item_remark(
    $id: Int!
    $checked: Boolean!
  ) {
    update_qat_checklist_theme_item_remark_by_pk(
      pk_columns: { id: $id }
      _set: { checked: $checked }
    ) {
      id
    }
  }
`;
export interface update_all_checked_value_remark_in_item_variables {
  checklist_theme_item_id: number;
  checked: boolean;
}

export interface update_all_checked_value_remark_in_item_response {
  update_qat_checklist_theme_item_remark_by_pk: {
    id: number;
  }[];
}

/**
 * Description: graphql mutation to update all checked value in list of remark with same item
 */
export const UPDATE_ALL_CHECK_VALUE_REMARK_IN_ITEM = gql`
  mutation update_all_checked_value_remark_in_item(
    $checklist_theme_item_id: Int!
    $checked: Boolean!
  ) {
    update_qat_checklist_theme_item_remark(
      where: { checklist_theme_item_id: { _eq: $checklist_theme_item_id } }
      _set: { checked: $checked }
    ) {
      returning {
        id
      }
    }
  }
`;
