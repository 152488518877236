import React from 'react';
import { useChecklistThemePresenter } from '../ChecklistThemePresenter';
import ChecklistThemeView from '../ChecklistThemeView';
import { get_checklist_by_pk_theme_data } from '../../../services/Checklist/ChecklistReadService/ChecklistReadQuery';
import { building_data } from '../../../services/Building/BuildingDataModel';
import { audit_data } from '../../../services/Audit/AuditDataModel';

export interface ChecklistThemeComponentProps {
  theme:  get_checklist_by_pk_theme_data;
  checklistId: number;
  isReadOnlyMode: boolean;
  building: building_data;
  audit:audit_data;
}

const ChecklistThemeComponent: React.FC<ChecklistThemeComponentProps> = ({ theme, checklistId, isReadOnlyMode,building, audit}) => {
  const {
    themeNotApplicationValue,
    onUpdateNotApplication,
    onConfirmUpdateNotApplication,
    openConfirmNotApplication,
    onCloseConfirmNotApplication,
    onChangeSectionOpen,
    sections,
    handleDuplicate,
    handleDelete,
    newSelectedTheme 

  } = useChecklistThemePresenter({ theme, checklistId});

  

  return (
    <ChecklistThemeView
      checklistId={checklistId}
      theme={newSelectedTheme ? newSelectedTheme:theme}
      onUpdateNotApplication={onUpdateNotApplication}
      themeNotApplicationValue={themeNotApplicationValue}
      onConfirmUpdateNotApplication={onConfirmUpdateNotApplication}
      openConfirmNotApplication={openConfirmNotApplication}
      onCloseConfirmNotApplication={onCloseConfirmNotApplication}
      onChangeSectionOpen={onChangeSectionOpen}
      sections={sections}
      handleDuplicate={handleDuplicate}
      isReadOnlyMode={isReadOnlyMode}
      handleDelete={handleDelete}
      building={building}
      audit={audit}
      
    />
  );
};

export default ChecklistThemeComponent;
