import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import MainAppContext from '../../../../AppContext';
import { NotificationType } from '../../../../components/Notification/NotificationComponent';
import { READ_ALL_REMARKS_FROM_TEMPLATE_THEME_ITEM } from '../ReadService/ItemRemarkReadQuery';
import { DELETE_REMARK } from './ItemRemarkDeleteMutation';

export const useDeleteItemRemark = templateThemeItemId => {
  const { setNotification } = useContext(MainAppContext);

  const [deleteItemRemark] = useMutation(DELETE_REMARK, {
    refetchQueries: [
      {
        query: READ_ALL_REMARKS_FROM_TEMPLATE_THEME_ITEM,
        variables: { template_theme_item_id: templateThemeItemId },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted({ deletedItemRemark }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const myDeleteItemRemark = async (id: number) => {
    deleteItemRemark({
      variables: { id: id, deletedDate: new Date() },
    });
  };

  return { myDeleteItemRemark };
};
