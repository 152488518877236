import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: "1px solid #333333"
  },
  rootShadow: {
    backgroundColor: '#d1d1d1',
  },
  title: {
    paddingRight: theme.spacing(1),
  },
  button: {},
  active: {
    color: theme.palette.warning.main,
  },
  conform: {
    color: '#4caf50',
  },
  notConform: {
    color: '#FF0000',
  },
  hasInterview: {
    color: theme.palette.warning.main,
  },
  default: {
    color: '#808080',
  },
  noApplication: {
    color: '#FF0000',
    float: 'right',
  },
  hiddenIcon: {
    visibility: 'hidden',
  },
  noApplicationButton: {
    float: 'right',
  },
  blackDefault: {
    color: '#000000',
  },
  normCode:{
    paddingLeft: theme.spacing(0.5),
    cursor: "pointer",
    color: "#0000EE",
  }
}));

export { useStyles };
