import React, {
    useState,
    useCallback,
    useEffect,
    useMemo,
    useContext
} from "react";
import { useTranslation } from "react-i18next";
import { useReactiveVar } from "@apollo/client";
import ConfirmDialog from "../Shared/Dialog/ConfirmDialog/ConfirmDialogComponent/ConfirmDialogComponent";
import AddButtonComponent from "../Shared/Button/AddButtonComponent";
import SearchField from "../Shared/Field/SearchField/SearchField";
import {
    LinearProgress,
    Typography,
} from "@material-ui/core";
import PageContainer from "../Shared/Container/PageContainer";
import { useStyles } from "../Shared/Styles/CommonFormSyles";
import { useDeleteLocation } from "../../services/Location/DeleteService/LocationDeleteService";
import LocationTableComponent, { OrderBy, OrderDirection } from "./LocationTableComponent";
import LocationCreateDialogComponent from "./LocationDialogComponent/LocationCreateDialogComponent";
import LocationUpdateDialogComponent from "./LocationDialogComponent/LocationUpdateDialogComponent";
import {
    openCreateLocationVar,
    openUpdateLocationVar,
    locationVar,
    categoryVar,
    idEditLocationVar
} from "./LocationCache";
import { location_data } from "../../services/Location/LocationDataModel";
import PaginationComponent from "../Shared/Pagination/PaginationComponent";
import { Category, Entity } from "../Entity/EntityModel";
import { useGetLocationByEntityId, useGetSortedLocationByEntityId } from "../../services/Location/ReadService/LocationReadService";
import { useGetAllCategories } from "../Entity/CategoryDataService/CategoryReadService/CategoryReadService";
import AppContext from '../../AppContext';
import { useBrowserLocationState } from "../Shared/BrowserLocationState/BrowserLocationState";

const LocationComponent = () => {
    const { t } = useTranslation();
    const classes = useStyles();

    const currentLocationState = useBrowserLocationState<{entity?: any}>({keysRequired: []})

    const entity: Entity = useMemo(
        () => (
            currentLocationState && currentLocationState.entity 
                ? currentLocationState.entity
                : null
        ),
        [currentLocationState],
    );

    const isOpenCreateLocation = useReactiveVar(openCreateLocationVar);
    const isOpenUpdateLocation = useReactiveVar(openUpdateLocationVar);
    const entityId = entity ? entity.id : -1;

    const { data, loading } = useGetLocationByEntityId(entityId);
    const { data: categories } = useGetAllCategories();

    const [locations, setLocations] = useState<location_data[]>([]);
    const [loadingLocation, setIsLoadingLocation] = useState<boolean>();
    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState<boolean>(false);
    const [listLocationFiltered, setListLocationFiltered] = useState<Array<location_data>>(
        locations
    );
    const [deleteId, setDeleteId] = useState<number>(-1);
    const [category, setCategory] = useState<Category[]>([]);

    const { orderLocationsBy } = useGetSortedLocationByEntityId(entityId);

    const { onDelete } = useDeleteLocation(entityId);
    const { language } = useContext(AppContext);

    useEffect(() => {
        if (categories.length) {
            categoryVar(categories)
            setCategory(categories)
        }
    }, [categories])

    useEffect(
        () => {

            if (data && data.length > 0 && !loading) {
                setLocations(data);
                setIsLoadingLocation(loading);
            } else {
                setLocations([]);
                setIsLoadingLocation(loading);
            }
        },
        [data, loading],
    );


    const handleCreateLocation = useCallback(
        () => {
            openCreateLocationVar(!isOpenCreateLocation)
        }, [isOpenCreateLocation]);

    const handleDeleteClicked = useCallback(
        (row: location_data) => {
            if (row.id) {
                setOpenDeleteConfirmDialog(true);
                setDeleteId(row.id);
            }
        },
        [],
    )

    const openUpdateDialog = useCallback(
        (row: location_data) => {
            if (row.id && row.category_location && entity) {
                openUpdateLocationVar(!isOpenUpdateLocation);
                idEditLocationVar(row.id)
                locationVar(
                    { 
                        ...locationVar(),
                        id: row.id,
                        name: row.name,
                        entity_id: entity.id,
                        category_id: row.category_location.id,
                        specification: row.specification
                    }
                );
            }
        },
        [isOpenUpdateLocation, entity],
    )

    const onConfirmedToDelete = useCallback(
        () => {
            if (deleteId !== -1) {
                onDelete(deleteId);
                setOpenDeleteConfirmDialog(false);
                setDeleteId(-1);
            } else {
                throw new Error("The location id to delete doesn't exist");
            }
        },
        [deleteId, onDelete],
    )

    const isCategorySearch = useCallback(
        (search: number | undefined): string => {
            let foundCategory: Category | undefined = undefined
            if (category && category.length) {
                foundCategory = category.find(cat => { return cat.id === search })
                return foundCategory ? foundCategory.name : "";

            }
            return ""
        }, [category])

    const handleSearch = useCallback((filter: string) => {
        const newFilter = filter.toLowerCase();
        if (newFilter && newFilter !== "") {
            let filterValue = locations.filter(location => {
                return (location.name.toLowerCase().includes(newFilter)
                    || isCategorySearch(location?.category_location?.id).toLocaleLowerCase().includes(newFilter)
                    || location.specification?.toLowerCase().includes(newFilter))
            })
            setListLocationFiltered(filterValue)
        } else {
            setListLocationFiltered(locations)
        }

    }, [locations, isCategorySearch]);

    const [sortByLocations, setSortByLocations] = useState<OrderBy[]>([
        { column: 'name' },
        { column: 'category_id' },
        { column: 'specification' },
    ]);

    const handleOnSort = sortLocation => {
        const updatedSortingLocations = sortByLocations?.map(item => {
            if (item?.column === sortLocation?.column) {
                item.direction =
                    item?.direction === OrderDirection.ASC
                        ? OrderDirection.DESC
                        : OrderDirection.ASC;
                 let orderBy;
                switch (item?.column) {
                    case 'category_id':
                        if (language.substring(0,2) === 'nl')
                            orderBy = {
                                    category_location: { name_nl: item.direction },
                            };
                        else if (language.substring(0,2) === 'fr')
                            orderBy = {        
                                    category_location: { name_fr: item.direction },
                            };
                        break;
                    case 'name':
                        orderBy =  { name: item.direction } ;
                        break;
                    case 'specification':
                        orderBy = { specification: item.direction } ;
                        break;
                    default:
                        break;
                }

                sortByColumn(orderBy); 

            } else delete item.direction;
            return item;
        });

        setSortByLocations(updatedSortingLocations);
    };

    const sortByColumn = item => {
        orderLocationsBy(item).then(locations_var => setListLocationFiltered(locations_var));
    };

    return (
        <div>
            {
                loading
                    ? (
                        <LinearProgress />
                    )
                    : (
                        <PageContainer>
                            <div
                                className={classes.itemTitle}
                            >
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                >
                                    {t("location:locationTableTitle",
                                        { 0: entity?.label })
                                    }
                                </Typography>
                            </div>
                            <div
                                className={classes.tableHeader}
                            >
                                <AddButtonComponent
                                    label={t("location:addLocation")}
                                    onClick={handleCreateLocation}
                                />
                                <div
                                    className={classes.search}
                                >
                                    <SearchField
                                        onChange={handleSearch}
                                    />
                                </div>
                            </div>
                            <PaginationComponent
                                items={listLocationFiltered}

                                itemsPropsName="locations"
                            >
                                <LocationTableComponent
                                    isLoading={loadingLocation}
                                    locations={listLocationFiltered}
                                    handleDeleteClicked={handleDeleteClicked}
                                    openUpdateDialog={openUpdateDialog}
                                    onSort={handleOnSort}
                                    sortByLocation={sortByLocations}
                                />
                            </PaginationComponent>

                            <LocationCreateDialogComponent />
                            <LocationUpdateDialogComponent />

                            <ConfirmDialog
                                open={openDeleteConfirmDialog}
                                onClose={
                                    () => setOpenDeleteConfirmDialog(false)
                                }
                                onConfirm={onConfirmedToDelete}
                                title={t("common:delete")}
                                text={t("common:confirmDelete")}
                            />

                        </PageContainer>
                    )
            }
        </div>
    )
};

export default LocationComponent;