import { useMutation } from '@apollo/client';
import { useCallback, useContext, useMemo } from 'react';
import MainAppContext from '../../../../AppContext';
import { ItemRemarkFormData } from '../ItemRemarkFormData';
import { NotificationType } from '../../../../components/Notification/NotificationComponent';
import { READ_ALL_REMARKS_FROM_TEMPLATE_THEME_ITEM } from '../ReadService/ItemRemarkReadQuery';
import {
  UPDATE_INDEX_ITEM_REMARK,
  UPDATE_ITEM_REMARK,
} from './ItemRemarkUpdateMutation';
import {
  update_index_item_remark_data,
  update_index_item_remark_response,
  update_index_item_remark_variables,
} from './ItemRemarkUpdateModel';
import { useGetAllRemarkIncludeSoftDeletedByItem } from '../ReadService/ItemRemarkReadService';
import { get_remark_include_soft_deleted_by_item_data } from '../ReadService/ItemRemarkReadModel';
import { handleReorder } from '../../../../components/Shared/utils/order.utils';
import { isContraintViolationError } from '../../../../graphql/utils';

/**
 * Description - service to update index value in item remark list
 */
export const useUpdateIndexItemRemarkFromItem = ({
  template_theme_item_id,
}: {
  template_theme_item_id: number;
}) => {
  const { setNotification } = useContext(MainAppContext);
  const [updateIndexItemRemark] = useMutation<
    update_index_item_remark_response,
    update_index_item_remark_variables
  >(UPDATE_INDEX_ITEM_REMARK, {
    refetchQueries: [
      {
        query: READ_ALL_REMARKS_FROM_TEMPLATE_THEME_ITEM,
        variables: { template_theme_item_id },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted() {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });
  const {
    getAllRemarkIncludeSoftDeletedByItem,
  } = useGetAllRemarkIncludeSoftDeletedByItem();

  const onUpdateIndexItemRemark = useCallback(
    (newValue: { id: number; index: number }) => {
      return new Promise<update_index_item_remark_data[]>(resolve => {
        getAllRemarkIncludeSoftDeletedByItem({ template_theme_item_id }).then(
          itemRemarks => {
            const previousValue:
              | get_remark_include_soft_deleted_by_item_data
              | undefined = itemRemarks.find(({ id }) => id === newValue.id);
            if (!previousValue) {
              setNotification({
                key: 'common:generalError',
                type: NotificationType.ERROR,
              });
              resolve([]);
            } else {
              const itemRemarkReordered: get_remark_include_soft_deleted_by_item_data[] = handleReorder<get_remark_include_soft_deleted_by_item_data>(
                itemRemarks,
                previousValue.index,
                newValue.index,
              ).map(({ id, __typename, ...rest }, index) => ({
                ...rest,
                id,
                index,
              }));
              updateIndexItemRemark({
                variables: { objects: itemRemarkReordered },
              }).then(response => {
                if (response && response.data) {
                  resolve(response.data.insert_qat_template_theme_item_remark);
                } else {
                  resolve([]);
                }
              });
            }
          },
        );
      });
    },
    [
      setNotification,
      template_theme_item_id,
      updateIndexItemRemark,
      getAllRemarkIncludeSoftDeletedByItem,
    ],
  );

  return { onUpdateIndexItemRemark };
};

/**
 * Description - service to update item
 */
export const useUpdateItemRemarkFromItem = (
  templateThemeItemId,
  showNotification = true,
) => {
  const { setNotification } = useContext(MainAppContext);

  const mutationConfiguration = useMemo(() => (
    {
      refetchQueries: [
        {
          query: READ_ALL_REMARKS_FROM_TEMPLATE_THEME_ITEM,
          variables: { template_theme_item_id: templateThemeItemId },
        },
      ],
      awaitRefetchQueries: true,
      onCompleted() {
        if (showNotification)
          setNotification({
            key: 'common:savedSuccess',
            type: NotificationType.SUCCESS,
          });
      },
      onError(error) {
        if (isContraintViolationError(error)) {
          setNotification({
            key: 'item:itemValidationError',
            type: NotificationType.WARNING,
          });
        } else {
          setNotification({
            key: 'common:generalError',
            type: NotificationType.ERROR,
          });
        }
      },
    }
  ), [setNotification, templateThemeItemId, showNotification])
  const [updateItemRemark] = useMutation(UPDATE_ITEM_REMARK, {
    ...mutationConfiguration
  });

  const onUpdateItemRemarkFromItem = useCallback(
    (id: number, itemRemakData: ItemRemarkFormData) => {
      return new Promise<boolean>(resolve => {
        const qat_template_theme_item_remark_set_input = {
          updated_at: new Date(),
          ...itemRemakData,
        };
        updateItemRemark({
          variables: { id, qat_template_theme_item_remark_set_input },
        });
      })
    },
    [updateItemRemark],
  );

  return { onUpdateItemRemarkFromItem };
};
