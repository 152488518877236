import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  AddressFormViewProps,
  ValidationRulesType,
} from '../AddressFormComponent/AddressFormComponent';
import { AddressCreateFormData } from '../../../../services/Address/WriteService/AddressWriteService';
import { useGetLocationByIdDefault } from '../../../../services/Location/ReadService/LocationReadService';
import { audit_data } from '../../../../services/Audit/AuditDataModel';
import { address_data } from '../../../../services/Address/AddressDataModel';

const validationRules: ValidationRulesType = {
  street: { required: true },
  house_number: { required: true },
  box_number: { required: false },
  city: { required: true },
};

export interface AddressFormPresenterProps {
  onSubmit: (AddressFormData) => void;
  handleClose:()=>void;
  audit: audit_data;
  address?: address_data;
  title: string;
  submitButtonLabel: string;
}

const useAddressFormPresenter = ({
  onSubmit,
  audit,
  address,
  title,
  submitButtonLabel,
  handleClose
}: AddressFormPresenterProps): AddressFormViewProps => {
  const {
    data: locationData,
    loading: locationLoading,
    error: locationError,
  } = useGetLocationByIdDefault(audit?.location_entity?.id);

  const defaultValues = useMemo(() => {
    return address
      ? {
          street: address?.street,
          house_number: address?.house_number,
          box_number: address?.box_number,
          city: address?.city,
        }
      : { street: '', house_number: undefined, city: '' };
  }, [address]);
  const {
    register,
    unregister,
    errors,
    handleSubmit,
    control,
    setValue,
  } = useForm<AddressCreateFormData>({ defaultValues: defaultValues });

  useEffect(() => {
    if (locationData && !locationLoading && !locationError) {
      setValue('city', locationData?.name);
    }
  }, [locationData, locationLoading, locationError, setValue]);

  useEffect(() => {
    register({ name: 'street' }, validationRules.street);
    register({ name: 'house_number' }, validationRules.house_number);
    register({ name: 'box_number' }, validationRules.box_number);
    register({ name: 'city' }, validationRules.city);

    return () => {
      unregister('street');
      unregister('house_number');
      unregister('box_number');
      unregister('city');
    };
  }, [register, unregister]);

  const onSubmitForm = handleSubmit((data: AddressCreateFormData | any) => {
    data.location_entity_id = audit?.location_entity?.id;
    if(data.location_entity_id){
      onSubmit(data);
    }
  });

  return {
    locationLoading,
    errors,
    onSubmit: onSubmitForm,
    handleClose,
    control,
    title,
    submitButtonLabel,
  };
};

export default useAddressFormPresenter;
