export interface RoutePath {
  id: string;
  path: string;
}

export interface RoutesPath {
  appPage: RoutePath;
  errorPage: RoutePath;
  audit: RoutePath;
  listAudit: RoutePath;
  currentAudits: RoutePath;
  createAudit: RoutePath;
  createAddress: RoutePath;
  editAddress: RoutePath;
  editAuditMainInfo: RoutePath;
  editAudit: RoutePath;
  createBuilding: RoutePath;
  editBuilding: RoutePath;
  editChecklist: RoutePath;
  template: RoutePath;
  allTemplate: RoutePath;
  createTemplate: RoutePath;
  editTemplate: RoutePath;
  normesMinimal: RoutePath;
  allLocations: RoutePath; //buy
  currentLocation: RoutePath; //buy
  editLocation: RoutePath; //buy
  createLocation: RoutePath; //buy
  changePassword: RoutePath;
  entity: RoutePath;
  allEntities: RoutePath;
  createEntity: RoutePath;
  editEntity: RoutePath;
  themeTemplate: RoutePath;
  allThemesTemplate: RoutePath;
  createThemeTemplate: RoutePath;
  editThemeTemplate: RoutePath;
  subThemeTemplate: RoutePath;
  allSubThemesTemplate: RoutePath;
  createSubThemeTemplate: RoutePath;
  editSubThemeTemplate: RoutePath;
  item: RoutePath;
  allItems: RoutePath;
  createItem: RoutePath;
  editItem: RoutePath;
  notAuthorized: RoutePath;
  allItemRemarks: RoutePath;
  createItemRemark: RoutePath;
  updateItemRemark: RoutePath;
  checklistReport: RoutePath;
  checklistRemarks: RoutePath;
  allLocationAddress: RoutePath;
  createLocationAddress: RoutePath;
  updateLocationAddress: RoutePath;
  cameraPhoto: RoutePath;
  uploadDocument: RoutePath;
  checklistUploadDocument: RoutePath;
  interviewUploadDocument: RoutePath;
}

// model
const appPageRoute = '/app';
const template = '/template';
const profile = '/profile';
const audit = '/audit';
const auditMainInfo = '/mainInfo';
const building = '/building';
const normesMinimal = '/norme';
const themeTemplate = '/themeTemplate';
const subThemeTemplate = '/subThemeTemplate';
const entity = '/entity';
const location = '/location'; // buy
const item = '/item';
const address = '/address';
const checklist = '/checklist';
const report = '/report';
const notAuthorized = '/notAuthorized';
//action
const create = '/create';
const edit = '/edit';
//const read = "/read"
const list = '/list';
const current = '/current';
const itemRemark = '/itemRemark';
const checklistRemark = '/checklistRemark';
const locationAddress = '/locationAddress'
const auditPhoto = '/auditPhoto'
const vue = '/vue'
const uploadDocument = "/uploadDocument"
const checklistUploadDocument = "/checklistUploadDocument"
const interviewUploadDocument = "/interviewUploadDocument"
/**
 * Description - routes of application
 */
const routes: RoutesPath = {
  //app page
  appPage: { id: 'appPage', path: appPageRoute },

  // error page
  errorPage: { id: 'errorPage', path: '/error' },

  // audit pages
  audit: { id: 'audit:audit', path: `${appPageRoute}${audit}` },
  listAudit: {
    id: 'audit:listAudit',
    path: `${appPageRoute}${audit}${list}`,
  },
  currentAudits: {
    id: 'audit:currentAudit',
    path: `${appPageRoute}${audit}${current}`,
  },
  createAudit: {
    id: 'audit:createAudit',
    path: `${appPageRoute}${audit}${create}`,
  },
  createAddress: {
    id: 'address:createAddress',
    path: `${appPageRoute}${address}${create}`,
  },
  editAddress: { id: 'editAddress', path: `${appPageRoute}${address}${edit}` },
  editAudit: { id: 'editAudit', path: `${appPageRoute}${audit}${edit}` },
  editAuditMainInfo: { id: 'editAudit', path: `${auditMainInfo}${edit}` },

  // building pages
  createBuilding: {
    id: 'building:createBuilding',
    path: `${building}${create}`,
  },
  editBuilding: { id: 'building:editBuilding', path: `${building}${edit}` },

  //checklist pages
  editChecklist: {
    id: 'editChecklist',
    path: `${appPageRoute}${checklist}${edit}`,
  },

  // template pages
  template: {
    id: 'template',
    path: `${appPageRoute}${template}`,
  },
  allTemplate: {
    id: 'allTemplate',
    path: `${appPageRoute}${template}${list}`,
  },
  createTemplate: {
    id: 'createTemplate',
    path: `${appPageRoute}${template}${create}`,
  },
  editTemplate: {
    id: 'editTemplate',
    path: `${appPageRoute}${template}${edit}`,
  },

  //norme pages
  normesMinimal: {
    id: 'normesMinimal',
    path: `${appPageRoute}${normesMinimal}`,
  },

  // location pages //buy
  allLocations: {
    id: 'allLocations',
    path: `${appPageRoute}${location}${list}`,
  },
  currentLocation: {
    id: 'currentLocation',
    path: `${appPageRoute}${location}${current}`
  },
  editLocation: {
    id: 'editLocation',
    path: `${appPageRoute}${location}${edit}`
  },
  createLocation: {
    id: 'createLocation',
    path: `${appPageRoute}${location}${create}`
  },

  //profile pages
  changePassword: {
    id: 'profile:changePassword',
    path: `${appPageRoute}${profile}/changePassword`,
  },

  // entity pages
  entity: { id: 'entity', path: `${appPageRoute}${entity}` },
  allEntities: { id: 'allEntities', path: `${appPageRoute}${entity}${list}` },
  createEntity: {
    id: 'createEntity',
    path: `${appPageRoute}${entity}${create}`,
  },
  editEntity: { id: 'editEntity', path: `${appPageRoute}${entity}${edit}` },

  // theme pages
  themeTemplate: {
    id: 'themeTemplate',
    path: `${appPageRoute}${themeTemplate}`,
  },
  allThemesTemplate: {
    id: 'allThemesTemplate',
    path: `${appPageRoute}${themeTemplate}${list}`,
  },
  createThemeTemplate: {
    id: 'createThemeTemplate',
    path: `${appPageRoute}${themeTemplate}${create}`,
  },
  editThemeTemplate: {
    id: 'editThemeTemplate',
    path: `${appPageRoute}${themeTemplate}${edit}`,
  },
  subThemeTemplate: {
    id: 'subThemeTemplate',
    path: `${appPageRoute}${subThemeTemplate}`,
  },
  allSubThemesTemplate: {
    id: 'allSubThemesTemplate',
    path: `${appPageRoute}${subThemeTemplate}${list}`,
  },
  createSubThemeTemplate: {
    id: 'createSubThemeTemplate',
    path: `${appPageRoute}${subThemeTemplate}${create}`,
  },
  editSubThemeTemplate: {
    id: 'editSubThemeTemplate',
    path: `${appPageRoute}${subThemeTemplate}${edit}`,
  },
  //Item page
  item: { id: 'item', path: `${appPageRoute}${item}` },
  allItems: { id: 'allItems', path: `${appPageRoute}${item}${list}` },
  createItem: { id: 'createItem', path: `${appPageRoute}${item}${create}` },
  editItem: { id: 'editItem', path: `${appPageRoute}${item}${edit}` },

  notAuthorized: {
    id: 'notAuthorized',
    path: `${appPageRoute}${notAuthorized}`,
  },
  allItemRemarks: {
    id: 'allItemRemarks',
    path: `${appPageRoute}${itemRemark}${list}`,
  },
  createItemRemark: {
    id: 'createItemRemarks',
    path: `${appPageRoute}${itemRemark}${create}`,
  },
  updateItemRemark: {
    id: 'editItemRemarks',
    path: `${appPageRoute}${itemRemark}${edit}`,
  },

  // Report
  checklistReport: {
    id: 'checklistReport',
    path: `${appPageRoute}${audit}${report}`,
  },
  checklistRemarks: {
    id: 'checklistRemarks',
    path: `${appPageRoute}${checklist}${checklistRemark}`,
  },

  allLocationAddress: {
    id: 'allLocationAddress',
    path: `${appPageRoute}${locationAddress}${list}`
  },
  createLocationAddress: {
    id: 'createLocationAddress',
    path: `${appPageRoute}${locationAddress}${create}`
  },
  updateLocationAddress: {
    id: 'updateLocationAddress',
    path: `${appPageRoute}${locationAddress}${edit}`
  },
  cameraPhoto: {
    id: "cameraPhoto",
    path: `${appPageRoute}${auditPhoto}${vue}`
  },
  uploadDocument: {
    id: "adminDocument:title",
    path: `${appPageRoute}${uploadDocument}`
  },
  checklistUploadDocument: {
    id: "checklistUploadDocument:title",
    path: `${appPageRoute}${checklistUploadDocument}`
  },
  interviewUploadDocument: {
    id: "interviewUploadDocument:title",
    path: `${appPageRoute}${interviewUploadDocument}`
  }

};

export default routes;