export const checklist = {
  checklist: 'Checklist',
  generalRemark: 'Remarques générales pour ce thème',
  checkListRemark: 'Remarques',
  itemRemark: 'Sélectionnez une remarque',
  itemRemarkPredefined: 'Sélectionner une remarque',
  itemRemarkFreeText: 'Ecrivez une remarque',
  takePicture: 'Prendre une photo',
  takeAndOthePicture: 'Remplacer la photo',
  errorStartCamera: 'Problème détecté avec votre caméra.\nVérifier si elle n\'est pas utilisée par une autre application par exemple.'
  };
