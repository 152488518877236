import React, { useContext } from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import { translateShortName } from '../../../../services/Translation/TranslationService';
import AppContext from '../../../../AppContext';
import ListItem from '@material-ui/core/ListItem';
import { checklist_theme } from '../../../../services/Checklist/ChecklistReadService';
import { useStyles } from '.';
import { get_checklist_by_pk_theme_data } from '../../../../services/Checklist/ChecklistReadService/ChecklistReadQuery';

export interface ChecklistNavigationListItemProps {
  theme: get_checklist_by_pk_theme_data;
  isThemeValidated: boolean;
  selectedTheme: checklist_theme;
  onThemeSelected: () => void;
}

export default function ChecklistNavigationListItemView({
  theme,
  isThemeValidated,
  onThemeSelected,
  selectedTheme,
}: ChecklistNavigationListItemProps) {
  const { language } = useContext(AppContext);
  const classes = useStyles();

  return (
    <ListItem
      classes={{
        root: isThemeValidated ? classes.listItemValidated : classes.listItem,
        selected: classes.selected,
      }}
      button
      className={
        isThemeValidated
          ? classes.themeValidatedColor
          : classes.themeValidatedDefaultColor
      }
      key={theme?.id}
      selected={theme?.id === selectedTheme?.id}
      onClick={onThemeSelected}
    >
      <ListItemText primary={translateShortName(theme, language)} />
    </ListItem>
  );
}
