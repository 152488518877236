import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { READ_ALL_ADDRESSES, READ_ALL_ADDRESSES_BY_LOCATION_ID } from '../ReadService/AddressReadQuery';
import { DELETE_ADDRESS } from './AddressDeleteMutation';

export const useDeleteAddress = () => {
  const { setNotification } = useContext(MainAppContext);

  const [deleteAddress] = useMutation(DELETE_ADDRESS, {
    refetchQueries: [{ query: READ_ALL_ADDRESSES }],
    awaitRefetchQueries: true,
    onCompleted({ deletedAddress }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const myDelete = async (id: number) => {
    deleteAddress({
      variables: { id: id, deletedDate: new Date() },
    });
  };

  return { myDelete };
};

export const useDeleteAddressInLocation = (location_entity_id) => {
  const { setNotification } = useContext(MainAppContext);

  const [deleteAddress] = useMutation(DELETE_ADDRESS, {
    refetchQueries: [{ query: READ_ALL_ADDRESSES_BY_LOCATION_ID,
    variables:{location_entity_id} }],
    awaitRefetchQueries: true,
    onCompleted({ deletedAddress }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const onDeleteInLocation = async (id: number) => {
    deleteAddress({
      variables: { id: id, deletedDate: new Date() },
    });
  };

  return { onDeleteInLocation };
};
