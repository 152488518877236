export const address = {
  address: 'Adres',
  addAddress: 'Adres toevoegen',
  updateAddress:'bewerk adres',
  createAddress: 'Adres toevoegen',
  street: 'Straat',
  houseNumber: 'Huisnummer',
  boxNumber: 'Bus',
  city: 'Stad',
  postalCode: 'Postcode',
  streetError: 'Straat is een verplicht veld',
  houseNumberError: 'Huisnummer is een verplicht veld',
  cityError: 'Stad is een verplicht veld',
  postalCodeError: 'Postcode is een verplicht veld',
  addressValidationError: 'Het adres dat u heeft opgegeven bestaat reeds', 
  addressTableTitle: 'Lijst van adressen voor locatie "{{0}}" van de entiteit "{{1}}" ',
  validateAdd:'Een ander adres toevoegen',
  LoactionAddresWithBoxUniqueError: 'Er is al adres  met deze straat "{{0}}" ,huisnummer "{{1}}" en busnummer "{{2}}" in locatie "{{3}}"',
  LoactionAddresWithoutBoxUniqueError: 'Er is al adres  met deze straat "{{0}}" en huisnummer {{1}} in locatie {{3}}',
  entityName: "Entiteit",
  locationName: "Locatie",
  updateAddressTitle:"Adres bijwerken",
  updateAddressMsg:"Dit adres wordt al gebruikt bij audits. Als je het wijzigt, verander je ook het adres in deze audits. Weet je zeker dat je doorgaat?"
};
