import { gql } from '@apollo/client';

/**
 * Description - graphql query to create an entity
 */

export const UPDATE_ITEM_REMARK = gql`
  mutation update_qat_template_theme_item_remark_by_pk(
    $id: Int!
    $qat_template_theme_item_remark_set_input: qat_template_theme_item_remark_set_input!
  ) {
    update_qat_template_theme_item_remark_by_pk(
      pk_columns: { id: $id }
      _set: $qat_template_theme_item_remark_set_input
    ) {
      id
      created_at
      remark_text_fr
      remark_text_nl
      index
    }
  }
`;

export const UPDATE_INDEX_ITEM_REMARK = gql`
  mutation update_index_item_remark(
    $objects: [qat_template_theme_item_remark_insert_input!]!
  ) {
    insert_qat_template_theme_item_remark(
      objects: $objects
      on_conflict: {
        constraint: template_theme_item_remark_pkey
        update_columns: [index]
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export interface upsert_item_remark_response {
  update_qat_template_theme_item_remark_by_pk: {
    id: number
  },
  delete_qat_template_theme_item_remark_by_pk: {
    id: number
  }
}
export interface upsert_item_remark_variables {
  id: number;
  remark_text_nl: string;
  remark_text_fr: string;
  index: number;
  id_to_delete: number;
}

export const UPSERT_ITEM_REMARK = gql`
mutation upsert_item_remark(
  $id: Int!,
  $remark_text_fr: String!,
  $remark_text_nl: String!,
  $index: Int!,
  $id_to_delete: Int!
){
  update_qat_template_theme_item_remark_by_pk(pk_columns: {id: $id}, _set:{
    remark_text_fr: $remark_text_fr,
    remark_text_nl: $remark_text_nl,
    index: $index,
    deleted_at: null,
  }){
    id
  }
  delete_qat_template_theme_item_remark_by_pk(id: $id_to_delete){
    id
  }
} 
`

export interface  delete_item_remark_variables {
  id: number
}

export interface delete_item_remark_response {
  delete_qat_template_theme_item_remark_by_pk: {
    id
  }
}


export const DELETE_ITEM_REMARK = gql`
  mutation delete_item_remark(
    $id: Int!
  ){
    delete_qat_template_theme_item_remark_by_pk(id: $id){
      id
    }
  }
`
