import { useContext } from 'react';
import MainAppContext from '../../../../../AppContext';
import { LanguageMenuProps } from '../LanguageMenuComponent/LanguageMenuComponent';
import { useStyles } from '../LanguageMenuStyle';

export const useLanguagePresenter = (): LanguageMenuProps => {
  const classes = useStyles();
  const context = useContext(MainAppContext);

  return { classes, context };
};
