import React, { useCallback, useContext, useMemo } from 'react';
import {
  IconButton,
  Table,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../AppContext';
import { CreateRounded, DeleteRounded } from '@material-ui/icons';
import { ItemRemarkFormData } from '../../../services/Item/Remark/ItemRemarkFormData';
import { useStyles } from '../../Shared/Styles/CommonFormSyles';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,
  ...(isDragging && {
    background: 'rgb(235,235,235)',
  }),
});

export const tableTestIds = {
  buttonOnUpdate: 'button-table-update-item-remark',
  buttonOnDelete: 'button-table-delete-item-remark',
  paginationComponent: 'pagination-component',
  tableItemRemarkId: 'table-id',
};

export interface ItemRemarkTableProps {
  isLoading?: boolean;
  itemRemarks: ItemRemarkFormData[];
  handleDeleteClicked: (row: ItemRemarkFormData) => void;
  openUpdateDialog: (row: ItemRemarkFormData) => void;
  onDragEnd: (result) => void;
}

const ItemRemarkTable: React.FC<ItemRemarkTableProps> = ({
  isLoading,
  itemRemarks,
  handleDeleteClicked,
  openUpdateDialog,
  onDragEnd,
}: ItemRemarkTableProps) => {
  const { t } = useTranslation();
  const { language } = useContext(AppContext);
  const classes = useStyles();

  const translateRemarkText = (
    itemRemarkObject: ItemRemarkFormData,
  ): string => {
    return language.substring(0, 2) === 'nl'
      ? itemRemarkObject.remark_text_nl
      : itemRemarkObject.remark_text_fr;
  };

  const handleOpenUpdateDialog = useCallback(
    (rowData: ItemRemarkFormData) => {
      openUpdateDialog(Array.isArray(rowData) ? rowData[0] : rowData);
    },
    [openUpdateDialog],
  );

  const onDeleteClicked = useCallback(
    (rowData: ItemRemarkFormData) =>
      handleDeleteClicked(Array.isArray(rowData) ? rowData[0] : rowData),
    [handleDeleteClicked],
  );

  // items remarks data mapping to be sure about id
  const itemsRemarksData: {
    id: number;
    remark_text_fr: string;
    remark_text_nl: string;
    template_theme_item_id: number;
    index: number;
  }[] = useMemo(
    () =>
      itemRemarks.map(itemsRemarkData => {
        if (itemsRemarkData.id) {
          const {
            id,
            remark_text_fr,
            remark_text_nl,
            template_theme_item_id,
            index,
          } = itemsRemarkData;
          return {
            id,
            remark_text_fr,
            remark_text_nl,
            template_theme_item_id,
            index,
          };
        } else {
          throw new Error('id property is required');
        }
      }),
    [itemRemarks],
  );

  return (
    <>
    <Paper className={classes.paper}>
    <TableContainer className={classes.tableContainer}>
    <Table stickyHeader className={classes.table} aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell className={`${classes.tableRow} ${classes.tableSmallCellSize}`}>
              {t('common:order')}
            </TableCell>
            <TableCell className={classes.tableRow} >
              {t('item_remark:remark')}
            </TableCell>
            <TableCell className={classes.tableRow} align="center">{t('common:edit')}</TableCell>
            <TableCell className={classes.tableRow} align="center">{t('common:delete')}</TableCell>
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable
            droppableId={'item-remark-table-body'}
            direction="vertical"
          >
            {provided => {
              return (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {itemsRemarksData.map((itemRemark, index) => (
                    <Draggable
                      key={itemRemark.id}
                      draggableId={itemRemark.id.toString()}
                      index={index}
                    >
                      {(provided_var, snapshot) => (
                        <TableRow
                          ref={provided_var.innerRef}
                          {...provided_var.draggableProps}
                          {...provided_var.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided_var.draggableProps.style,
                          )}
                        >
                          <TableCell
                            className={
                              classes.tableSmallCellSize + ' ' + classes.index
                            }
                            align="center"
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell className={classes.tableLargeCellSize}>
                            {translateRemarkText(itemRemark)}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              data-testid={tableTestIds.buttonOnUpdate}
                              onClick={() => handleOpenUpdateDialog(itemRemark)}
                            >
                              <CreateRounded />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              data-testid={tableTestIds.buttonOnDelete}
                              onClick={() => onDeleteClicked(itemRemark)}
                            >
                              <DeleteRounded />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              );
            }}
          </Droppable>
        </DragDropContext>
    </Table>
    </TableContainer>
    </Paper>
    </>
  );
};

export default ItemRemarkTable;
