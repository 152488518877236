import React from 'react';
import { makeStyles, Paper } from '@material-ui/core';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(3),
  },
  rootMobile: {
    margin: theme.spacing(1),
    height: '100%',
  },
  paper: {
    padding: theme.spacing(3),
  },
  paperMobile: {
    padding: theme.spacing(1),
    height: '100%',
  },
}));

const PageContainer = ({ children }) => {
  const classes = useStyles();

  return (
    <div
      className={clsx(classes.root, {
        [classes.rootMobile]: isMobile,
      })}
    >
      <Paper
        className={clsx(classes.paper, {
          [classes.paperMobile]: isMobile,
        })}
      >
        {children}
      </Paper>
    </div>
  );
};

export default PageContainer;
