import { useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { LocationFormData } from '../../../components/Location/LocationFormModel';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { isContraintViolationError } from '../../../graphql/utils';
import { READ_ALL_ENTITIES, READ_ALL_ENTITIES_SORTED } from '../../Entity/ReadService/EntityReadQuery';
import { READ_ALL_LOCATIONS_BY_ENTITY_ID } from '../ReadService/LocationReadQuery';
import { UPDATE_LOCATION } from './LocationUpdateMutation';

export interface LocationUpdateFormData {
  id: number;
  name: string;
  entity_id: number;
}

/**
 * Description - service to update location
 */
export const useUpdateLocation = (entity_id) => {
  const { setNotification } = useContext(MainAppContext);

  const [updateLocation] = useMutation(UPDATE_LOCATION, {
    refetchQueries: [{
      query: READ_ALL_LOCATIONS_BY_ENTITY_ID,
      variables: { entity_id }
    },
    {query: READ_ALL_ENTITIES},
    {
      query: READ_ALL_ENTITIES_SORTED,
      variables:{order_by:{label: "asc"}}
    }
  ],
   
    awaitRefetchQueries: true,
    onCompleted({ createdLocation }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      if (isContraintViolationError(error))
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      else
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
    },
  });

  const onUpdate = useCallback(
    (id: number, locationData: LocationFormData) => {
      const qat_location_entity_set_input = {
        last_updated_date: new Date(),
        ...locationData,
      };
      updateLocation({ variables: { id, qat_location_entity_set_input } });
    },
    [updateLocation],
  );

  return { onUpdate };
};
