import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { GET_BUILDING_BY_AUDIT } from '../ReadService/BuildingReadQuery';
import {
  DELETE_BUILDING,
  DELETE_BUILDING_BY_AUDIT,
} from './BuildingDeleteMutation';

export const useDeleteBuildingById = ({ audit_id }) => {
  const { setNotification } = useContext(MainAppContext);

  const [onDelete] = useMutation(DELETE_BUILDING, {
    refetchQueries: [{ query: GET_BUILDING_BY_AUDIT, variables: { audit_id } }],
    awaitRefetchQueries: true,
    onCompleted({ deletedBuilding }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const deleteBuildingById = async (id: number) => {
    onDelete({
      variables: { id: id, deletedDate: new Date() },
    });
  };

  return { deleteBuildingById };
};

export const useDeleteBuildingsByAudit = () => {
  const { setNotification } = useContext(MainAppContext);

  const [deleteBuildingsByAudit] = useMutation(DELETE_BUILDING_BY_AUDIT, {
    onCompleted({ deletedBuilding }) {},
    onError(error) {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const deleteBuildings = async (id: number) => {
    deleteBuildingsByAudit({
      variables: { id },
    });
  };

  return { deleteBuildings };
};
