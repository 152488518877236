import { CircularProgress, Grid } from '@material-ui/core';
import React from 'react';
import { useAuditReadAllPresenter } from '../AuditReadAllPresenter';
import AuditReadAllView from '../AuditReadAllView';
import { useStyles } from '.';
import SearchComponent from '../../../../Shared/Search/SearchComponent';
import PageContainer from '../../../../Shared/Container/PageContainer';

export const testIds = {
  searchFieldComponent: 'search-field-component',
};
export interface AuditReadAllComponentProps {
  onUpdate: (id: number) => void;
}

/**
 * Description - This component is responsible for the management of AuditReads
 */
const AuditReadAllComponent: React.FC<AuditReadAllComponentProps> = ({
  onUpdate,
}) => {
  const {
    allAudits,
    loading,
    onSort,
    sortByItems,
    filterPredicate,
    entityfilterPredicate,
    locationfilterPredicate,
    filteredAudits,
    setFilteredAudits,
  } = useAuditReadAllPresenter();
  const classes = useStyles();

  return loading ? (
    <CircularProgress />
  ) : (
    <PageContainer>
      <Grid container className={classes.tableHeader}>
        <Grid item sm={12} lg={4}>
          <SearchComponent
            items={allAudits}
            filterPredicate={entityfilterPredicate}
            filteredItems={filteredAudits}
            setFilteredItems={setFilteredAudits}
            placeholderTranslation={'entity:search'}
          />
        </Grid>
        <Grid item sm={12} lg={4}>
          <SearchComponent
            items={allAudits}
            filterPredicate={locationfilterPredicate}
            filteredItems={filteredAudits}
            setFilteredItems={setFilteredAudits}
            placeholderTranslation={'location:search'}
          />
        </Grid>
        <Grid item sm={12} lg={4}>
          <SearchComponent
            items={allAudits}
            filterPredicate={filterPredicate}
            filteredItems={filteredAudits}
            setFilteredItems={setFilteredAudits}
            placeholderTranslation={'common:search'}
          />
        </Grid>
      </Grid>
      <AuditReadAllView
        audits={allAudits}
        onUpdate={onUpdate}
        onSort={onSort}
        sortByItems={sortByItems}
      />
    </PageContainer>
  );
};

export default AuditReadAllComponent;
