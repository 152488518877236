import { gql } from '@apollo/client';

export const READ_ITEMS_WITH_OVERVIEW_DETAILS = gql`
  query qat_checklist_theme_item($audit_id: Int!) {
    qat_checklist_theme_item(
      where: {
        checklist_theme_section: {
          checklist_theme: { checklist: { audit_id: { _eq: $audit_id } } }
        }
        _and: { is_conform: { _eq: false } }
      }
      order_by: { norm_code: asc }
    ) {
      id
      name_nl
      name_fr
      comment
      norm_code
      page_pdf_fr
      page_pdf_nl
      checklist_theme_item_remarks(
        order_by: { index: asc }
        where: { checked: { _eq: true } }
      ) {
        id
        name_nl
        name_fr
        index
      }
      checklist_theme_section {
        id
        name_nl
        name_fr
        specification
        checklist_theme {
          id
          checklist {
            building {
              id
              index
              address {
                street
                house_number
                box_number
                city
              }
            }
            audit {
              id
              entity {
                abbreviation
              }
              location_entity {
                name
              }
              audit_date
            }
          }
        }
      }
    }
    sub_section_items: qat_checklist_theme_item(
      where: {
        _and: [
          {
            _or: [
              {
                checklist_theme_sub_section: {
                  checklist_theme_section: {
                    checklist_theme: {
                      checklist: { audit_id: { _eq: $audit_id } }
                    }
                  }
                }
              }
            ]
          }
          { is_conform: { _eq: false } }
          { is_conform: { _is_null: false } }
        ]
      }
    ) {
      id
      name_nl
      name_fr
      comment
      norm_code
      page_pdf_fr
      page_pdf_nl
      checklist_theme_item_remarks(
        order_by: { index: asc }
        where: { checked: { _eq: true } }
      ) {
        id
        name_nl
        name_fr
        index
      }
      checklist_theme_sub_section {
        id
        name_nl
        name_fr
        checklist_theme_section {
          id
          name_nl
          name_fr
          specification
          checklist_theme {
            id
            checklist {
              building {
                id
                index
                address {
                  street
                  house_number
                  box_number
                  city
                }
              }
              audit {
                id
                entity {
                  abbreviation
                }
                location_entity {
                  name
                }
                audit_date
              }
            }
          }
        }
      }
    }
  }
`;
