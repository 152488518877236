import { useCallback, useState } from 'react';
import { get_checklist_by_pk_sub_section_data } from '../../../services/Checklist/ChecklistReadService/ChecklistReadQuery';
import {
  useSetNotApplicationChecklistThemeSubSectionFalse,
  useSetNotApplicationChecklistSubThemeTrue,
} from '../../../services/ChecklistThemeSubSection/ChecklistThemeSubSectionWriteService/ChecklistThemeSubSectionWriteService';
import { useUpdateChecklistListOfItem } from '../../../services/ChecklistItem/UpdateService/ChecklistItemUpdateService';


export const useChecklistThemeSubSection = (
  subSection,
  checklistId,
  sectionId,
) => {

  const { onUpdateListOfItems } = useUpdateChecklistListOfItem(
    checklistId,
    false,
  );

  const {
    onSetNotApplicationFalse,
  } = useSetNotApplicationChecklistThemeSubSectionFalse({
    checklist_id: checklistId,
  });

  const { onSetNotApplicationTrue } = useSetNotApplicationChecklistSubThemeTrue(
    {
      checklist_id: checklistId,
      section_id: sectionId,
    },
  );

  const [
    openConfirmNoApplication,
    setOpenConfirmNoApplication,
  ] = useState<boolean>(false);
  const [noApplication, setNoApplication] = useState<boolean | undefined>(
    subSection?.no_application,
  );

  const handleUpdateNoApplication = useCallback(() => {
    if (noApplication === false) {
      setOpenConfirmNoApplication(true);
    } else {
      //update no application (false) sub section
      onSetNotApplicationFalse({ id: subSection.id });
      setNoApplication(false);
    }
  }, [noApplication, subSection, onSetNotApplicationFalse]);

  const onConfirmUpdateNoApplication = useCallback(() => {
    setOpenConfirmNoApplication(false);
    setNoApplication(true);
    onSetNotApplicationTrue({
      subSection,
    });
  }, [subSection, onSetNotApplicationTrue]);

  const onCloseConfirmNoApplication = useCallback(() => {
    setOpenConfirmNoApplication(false);
  }, []);

  const colorNotApplicationButton: 'error' | 'disabled' = noApplication
    ? 'error'
    : 'disabled'; // it is only about a color

  const handleConformChanged = (
    event,
    _subSection: get_checklist_by_pk_sub_section_data,
  ) => {
    let listItemIdsToUpdate: number[] = [];
    Array.from(_subSection.checklist_theme_items).forEach(
      (checklist_item: any) => {
        listItemIdsToUpdate.push(checklist_item?.id);
      },
    );
    if (listItemIdsToUpdate.length > 0) {
      onUpdateListOfItems(Array.from(listItemIdsToUpdate));
    }
    event.stopPropagation();
  }


  return {
    onUpdateNoApplication: handleUpdateNoApplication,
    onConfirmUpdateNoApplication,
    openConfirmNoApplication,
    onCloseConfirmNoApplication,
    colorNotApplicationButton,
    noApplication,
    onConformSubSectionChanged: handleConformChanged
  };
};
