import React from 'react';
import useAuditFormPresenter, {
  AuditFormData,
} from '../AuditFormPresenter/AuditFormPresenter';
import { AuditProperty } from '../../AuditModel';
import { UseFormMethods } from 'react-hook-form';
import AuditFormView from '../AuditFormView/AuditFormView';
import { audit_data } from '../../../../services/Audit/AuditDataModel';
import { building_data } from '../../../../services/Building/BuildingDataModel';

export interface AuditFormComponentProps {
  onSubmitAudit: (auditFormData: AuditFormData) => Promise<number>;
  audit?: audit_data;
  building?: building_data;
  title: string;
  submitButtonLabel: string;
  fromCreateAuditComponent?: boolean;
}

export type AuditValidationRulesType = { [key in AuditProperty]?: any };

export interface AuditFormViewProps {
  building?: building_data;
  audit: audit_data | undefined;
  validation: UseFormMethods<AuditFormData>;
  onSubmit: (Event) => void;
  onCreateBuildingClick: () => void;
  buildingIndexToUpdate?: number;
  title: string;
  submitButtonLabel: string;
  defaultValues?: AuditFormData;
  displayBuildingSection: boolean;
  parentPath: string;
  fromCreateAuditComponent?: boolean;
}

const AuditFormComponent: React.FC<AuditFormComponentProps> = ({
  onSubmitAudit,
  audit,
  building,
  title,
  submitButtonLabel,
  fromCreateAuditComponent
}) => {
  const props = useAuditFormPresenter({
    onSubmitAudit,
    audit,
    building,
    title,
    submitButtonLabel, 
    fromCreateAuditComponent
  });
  return <AuditFormView {...props} />;
};

export default AuditFormComponent;
