import React, { useContext } from 'react';
import AppContext from '../../AppContext';
import {
  checklist_theme_item_remark,
} from '../../services/Checklist/ChecklistReadService/CheckistReadModel';
import { translate } from '../../services/Translation/TranslationService';
import { useStyles } from './ChecklistItemRemarkView/ChecklistItemRemarkStyle';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloseIcon from '@material-ui/icons/Close';
import Chip from '@material-ui/core/Chip';
import _ from 'lodash';
import { getShortLabel } from '../../services/Utils/ShortLabelService';
import { useTranslation } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';

export const testIds = {
  remarkIdInput: 'remark-id-input',
};

export interface ItemRemarkListProps {
  onDeativateRemark: any;
  onRemarkChanged: any;
  onDeactivateRemarks: any;
  remarks?: checklist_theme_item_remark[];
  isReadOnlyMode?: boolean;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ItemRemarkListComponent: React.FC<ItemRemarkListProps> = ({
  onDeativateRemark,
  onRemarkChanged,
  onDeactivateRemarks,
  remarks,
  isReadOnlyMode,
}) => {
  const { language } = useContext(AppContext);
  const classes = useStyles();
  const { t } = useTranslation();

  const readOnlyModeclick = e => e.preventDefault();
  
  return (
    <>
        {remarks && remarks.length > 0 && (
        <Autocomplete
            multiple
            id="remark"
            renderTags={(options, props) =>
            _.uniqBy(options, 'id')
                .filter(_option => _option?.checked === true)
                .map(option => (
                <Chip
                    key={option?.id}
                    size="small"
                    label={getShortLabel(translate(option, language), 50)}
                    onDelete={e =>
                    isReadOnlyMode
                        ? readOnlyModeclick
                        : onDeativateRemark(e, option)
                    }
                />
                )) || []
            }
            defaultValue={remarks}
            value={remarks?.filter(_option => _option?.checked === true)}
            options={remarks}
            disableCloseOnSelect
            getOptionLabel={option =>
            getShortLabel(translate(option, language), 50)
            }
            getOptionSelected={(option, value) =>
            option && option?.checked === true
            }
            closeIcon={
            <CloseIcon
                fontSize="small"
                onClick={e =>
                isReadOnlyMode ? readOnlyModeclick : onDeactivateRemarks()
                }
            />
            }
            renderOption={(option, { selected }) => (
            <>
                <Tooltip
                disableHoverListener={
                    translate(option, language).length < 50
                }
                className={classes.flexInline}
                title={translate(option, language)}
                >
                <div>
                    <Checkbox
                    classes={{
                        root: classes.checkbox,
                        checked: classes.checked,
                    }}
                    icon={icon}
                    checkedIcon={checkedIcon}
                    checked={option?.checked}
                    onClick={e =>
                        isReadOnlyMode
                        ? readOnlyModeclick
                        : onRemarkChanged(e, option)
                    }
                    />
                    {getShortLabel(translate(option, language), 50)}
                </div>
                </Tooltip>
            </>
            )}
            renderInput={params => (
            <TextField
                {...params}
                InputProps={{
                    ...params.InputProps,
                    readOnly: true,
                }}
                variant="outlined"
                label=""
                placeholder={
                (remarks?.filter(_option => _option?.checked === true)
                    ?.length === 0 &&
                    t('checklist:itemRemarkPredefined')) ||
                ''
                }
            />
            )}
        />
        )}
    </>
  );
};

export default ItemRemarkListComponent;
