import React from 'react';
import { useChecklistReportInterviewPresenter } from '../ChecklistReportInterviewPresenter/ChecklistReportInterviewPresenter';
import ChecklistReportInterviewView from '../ChecklistReportInterviewView/ChecklistReportInterviewView';

export interface ChecklistReportInterviewComponentProps {
  audit_id: number;
  isReadOnlyMode? : boolean;
}

const ChecklistReportInterviewComponent: React.FC<ChecklistReportInterviewComponentProps> = ({
  audit_id,
  isReadOnlyMode
}) => {
  const { audit, loading } = useChecklistReportInterviewPresenter(audit_id);

  return <ChecklistReportInterviewView audit={audit} loading={loading} isReadOnlyMode = {isReadOnlyMode}/>;
};

export default ChecklistReportInterviewComponent;
