import { useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { ChecklistItemRemarkFormData } from '../ChecklistItemRemarkFormData';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { GET_CHECKLIST_BY_PK } from '../../Checklist/ChecklistReadService';
import {
  update_all_checked_value_remark_in_item_response,
  update_all_checked_value_remark_in_item_variables,
  UPDATE_ALL_CHECK_VALUE_REMARK_IN_ITEM,
  update_checked_value_theme_tem_remark_response,
  update_checked_value_theme_tem_remark_variables,
  UPDATE_CHECKLIST_ITEM_REMARK_BY_PK,
  UPDATE_CHECK_VALUE_ITEM_REMARK,
  UPSERT_CHECKLIST_ITEM_REMARK,
} from './ChecklistItemRemarkUpsertMutation';
import { GET_AUDIT_REPORT_INTERVIEW_BY_AUDIT_ID } from '../../Audit/ReadService/AuditReadQuery';
import { isContraintViolationError } from '../../../graphql/utils';

/**
 * Description - service to upsert a checklist item
 */
export const useUpsertChecklistItemRemark = (checklistId, showNotification) => {
  const { setNotification } = useContext(MainAppContext);

  const [upsertItem] = useMutation(UPSERT_CHECKLIST_ITEM_REMARK, {
    refetchQueries: [
      {
        query: GET_CHECKLIST_BY_PK,
        variables: { id: checklistId },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted({ upsertItemRemark }) {
      showNotification &&
        setNotification({
          key: 'common:savedSuccess',
          type: NotificationType.SUCCESS,
        });
    },
    onError(error) {
      if (isContraintViolationError(error)) {
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      } else {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      }
    },
  });

  const onUpsert = useCallback(
    (itemData: ChecklistItemRemarkFormData) => {
      const qat_checklist_theme_item_remark_insert_input = {
        ...itemData,
      };
      upsertItem({
        variables: { qat_checklist_theme_item_remark_insert_input },
      });
    },
    [upsertItem],
  );

  return { onUpsert };
};

/**
 * Description - service to upsert a checklist item
 */
export const useUpdateChecklistItemRemarkRefetchAudit = (
  auditId,
  showNotification,
) => {
  const { setNotification } = useContext(MainAppContext);

  const [updateRemarkItem] = useMutation(UPDATE_CHECKLIST_ITEM_REMARK_BY_PK, {
    refetchQueries: [
      {
        query: GET_AUDIT_REPORT_INTERVIEW_BY_AUDIT_ID,
        variables: { id: auditId },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted({ upsertItemRemark }) {
      showNotification &&
        setNotification({
          key: 'common:savedSuccess',
          type: NotificationType.SUCCESS,
        });
    },
    onError(error) {
      if (isContraintViolationError(error)) {
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      } else {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      }
    },
  });

  const onUpdate = useCallback(
    (itemRemarkData: ChecklistItemRemarkFormData, id: number) => {
      const qat_checklist_theme_item_remark_set_input = {
        ...itemRemarkData,
      };
      updateRemarkItem({
        variables: {
          qat_checklist_theme_item_remark_set_input: qat_checklist_theme_item_remark_set_input,
          id: id,
        },
      });
    },
    [updateRemarkItem],
  );

  return { onUpdate };
};

/**
 * Description - service to update checked value in a checklist item
 */
export const useUpdateCheckValue = ({
  checklist_id,
  showNotification,
}: {
  checklist_id: number;
  showNotification?: boolean;
}) => {
  const { setNotification } = useContext(MainAppContext);

  const [updateCheckValue] = useMutation<
    update_checked_value_theme_tem_remark_response,
    update_checked_value_theme_tem_remark_variables
  >(UPDATE_CHECK_VALUE_ITEM_REMARK, {
    refetchQueries: [
      {
        query: GET_CHECKLIST_BY_PK,
        variables: { id: checklist_id },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted() {
      showNotification &&
        setNotification({
          key: 'common:savedSuccess',
          type: NotificationType.SUCCESS,
        });
    },
    onError(error) {
      if (isContraintViolationError(error))
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      else
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
    },
  });

  const onUpdateCheckValue = useCallback(
    ({ id, checked }: update_checked_value_theme_tem_remark_variables) => {
      return updateCheckValue({ variables: { id, checked } });
    },
    [updateCheckValue],
  );

  return { onUpdateCheckValue };
};

/**
 * Description - service to update checked value in a checklist item
 */
export const useUpdateAllCheckValueInItem = ({
  checklist_id,
  showNotification,
}: {
  checklist_id: number;
  showNotification?: boolean;
}) => {
  const { setNotification } = useContext(MainAppContext);

  const [updateAllCheckValueInItem] = useMutation<
    update_all_checked_value_remark_in_item_response,
    update_all_checked_value_remark_in_item_variables
  >(UPDATE_ALL_CHECK_VALUE_REMARK_IN_ITEM, {
    refetchQueries: [
      {
        query: GET_CHECKLIST_BY_PK,
        variables: { id: checklist_id },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted() {
      showNotification &&
        setNotification({
          key: 'common:savedSuccess',
          type: NotificationType.SUCCESS,
        });
    },
    onError(error) {
      if (isContraintViolationError(error))
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      else
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
    },
  });

  const onUpdateAllCheckValueInItem = useCallback(
    ({
      checklist_theme_item_id,
      checked,
    }: update_all_checked_value_remark_in_item_variables) => {
      return updateAllCheckValueInItem({
        variables: { checklist_theme_item_id, checked },
      });
    },
    [updateAllCheckValueInItem],
  );

  return { onUpdateAllCheckValueInItem };
};
