import React, { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useIsOnline } from 'react-use-is-online';
import InfoDialog from '../Shared/Dialog/InfoDialog/InfoDialogComponent';
import {Provider as NetworkListenerContextProvider } from './NetworkListenerContext';


export const testIds = {
    dialog: "dialog-offline"
}

const NetworkListener = ({ children }) => {
    const { isOffline: isOfflineForNavigator } = useIsOnline();
    const [isOffline, setIsOffline] = useState<boolean>(false) 
    const [open, setOpen] = useState<boolean>(false)
    const { t } = useTranslation()

    const onInformIsOffline = useCallback(() => {
        setOpen(true)
    },[])

    const onChangeIsOffline = useCallback((value:boolean) => {
        setIsOffline(value)
        if(value){
            onInformIsOffline()
        }
    },[onInformIsOffline])

    useEffect(() => {
        onChangeIsOffline(isOfflineForNavigator)
    }, [isOfflineForNavigator, onChangeIsOffline])

    const onClose = useCallback(() => {
        setOpen(false)
    }, [])

    return (
       <React.Fragment>
            <InfoDialog
                dataTestId={testIds.dialog}
                title={t("common:titleDialogOffline")}
                text={t("common:textDialogOffline")}
                onClose={onClose}
                open={open}
            />
            <NetworkListenerContextProvider
              value={{
                isOffline,
                onChangeIsOffline 
              }}
            >
                {children}
            </NetworkListenerContextProvider>
       </React.Fragment>
    )
}

export default NetworkListener