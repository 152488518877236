import { useCallback, Dispatch, SetStateAction } from 'react';

export interface SearchServiceProps {
  items: any[];
  filteredItems: any[];
  setFilteredItems: Dispatch<SetStateAction<any>>;
  filterPredicate: (item, search) => boolean;
}

export const testId = 'component-search';

const useSearch = ({
  items,
  filteredItems,
  setFilteredItems,
  filterPredicate,
}: SearchServiceProps) => {
  const filter = useCallback((search: string) => {
    const _search = search.toLowerCase();
    if (_search && _search !== '') {
      setFilteredItems(() => {
        const _filteredItems: Array<any> = items.filter(item =>
          filterPredicate(item, _search),
        );
        return _filteredItems;
      });
    } else {
      setFilteredItems(items);
    }
    // eslint-disable-next-line
  }, []);

  return { filteredItems, filter };
};

export default useSearch;
