import { useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { READ_ALL_ADDRESSES } from '../ReadService/AddressReadQuery';
import { useGetAllAddressActiveByLocationId } from '../ReadService/AddressReadService';
import { UPDATE_ADDRESS } from './AddressUpdateMutation';
import { useTranslation } from 'react-i18next';
import { address_location_data } from '../ReadService/AddressReadModel';
import { isContraintViolationError } from '../../../graphql/utils';

export interface AddressUpdateFormData {
  id: number;
  street: string;
  house_number: string;
  box_number?: string;
  city: string;
  location_entity_id: string | number;
}

/**
 * Description - service to update address
 */
export interface AddressUniqueErrorResponseForUpdate {
  sameAddressExist?: string;
  updateAddressId?: number;
}

export const useCheckAddressUniqueForUpdate = () => {
  const { getAllAddressActiveByLocationId } = useGetAllAddressActiveByLocationId()

  const { t } = useTranslation()

  const onCheckAddressUniqueForUpdate = useCallback((
    id: number,
    addressData: AddressUpdateFormData
  ) => {
    return new Promise<AddressUniqueErrorResponseForUpdate | void>(resolve => {
      const results: AddressUniqueErrorResponseForUpdate = {}
      const location_entity_id = addressData.location_entity_id
      getAllAddressActiveByLocationId({ location_entity_id }).then(addresses => {
        let doubleAddress: address_location_data | undefined = undefined
        doubleAddress = addresses.find((address) =>
          address.street?.trim().toLowerCase() === addressData.street?.trim().toLowerCase() &&
          address.city?.trim().toLowerCase() === addressData.city?.trim().toLowerCase() &&
          address.house_number?.trim().toLowerCase()  === addressData.house_number?.trim().toLowerCase()  &&
          address.box_number?.trim().toLowerCase() === addressData.box_number?.trim().toLowerCase() &&
          address.id !== addressData.id &&
          address.location_entity_id === addressData.location_entity_id)
        if (doubleAddress) {
          const msg = t("address:addressValidationError")
          results.sameAddressExist = msg
          results.updateAddressId = undefined
        } else {
          results.sameAddressExist = undefined
          results.updateAddressId = addressData.id?  addressData.id : id
        }

        if (Object.keys(results).length) {
          resolve(results)
        } else {
          resolve()
        }
      })
    })

  }, [t, getAllAddressActiveByLocationId])

  return { onCheckAddressUniqueForUpdate }
}


export const useUpdateAddress = () => {
  const { setNotification } = useContext(MainAppContext);
  const { onCheckAddressUniqueForUpdate } = useCheckAddressUniqueForUpdate();

  const [updateAddress] = useMutation(UPDATE_ADDRESS, {
    refetchQueries: [{ query: READ_ALL_ADDRESSES }],
    awaitRefetchQueries: true,
    onCompleted({ createdAddress }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      if (isContraintViolationError(error))
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      else
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
    },
  });

  const onUpdate = useCallback(
    (id: number, addressData: AddressUpdateFormData) => {
      return new Promise<any>(resolve => {
        onCheckAddressUniqueForUpdate(
          id,
          addressData
        )
          .then(results => {
            if (results["updateAddressId"] && results["updateAddressId"] !== 0) {
              const qat_address_set_input = {
                last_updated_date: new Date(),
                deleted_date: null,
                ...addressData,
              };
              updateAddress({ variables: { id, qat_address_set_input } }).then(
                response => {
                  resolve(response);
                },
              ).catch(error => console.error(error));
            } else {
              setNotification({
                key: 'address:addressValidationError',
                type: NotificationType.WARNING,
              });
              resolve(null);
            }
          });
      });
    },
    [updateAddress, onCheckAddressUniqueForUpdate, setNotification],
  );

  return { onUpdate };
};




