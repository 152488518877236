import gql from "graphql-tag";


export interface get_audit_photo_data {
  __typename?: 'qat_audit_photo'
    id: number;
    url: string;
    minetype: "image/png" | "image/jpg";
    description: string;
    audit_photo_metadata_id:number;         
}
export interface get_audit_photo_metadata {
  __typename?: 'qat_audit_photo_metadata'
  id?: number;
  auditId: number;
  checklistId: number;
  themeId: number;
  sectionId: number;
}


export const GET_AUDIT_PHOTO = gql`
  query get_audit_photo($url:string!) {
      qat_audit_photo( where:{url:{_eq: $url}}){
      id
      audit_photo_metadata_id
      minetype
      url
      }
    }`;  
    
    export const GET_AUDIT_PHOTO_METADATA = gql`
      query get_audit_photo_metadata($audit_id:Int!,$checklist_id:Int!,$theme_id:Int!,$section_id:Int!)
       {
     qat_audit_photo_metadata(where: { audit_id: {_eq:$audit_id}, checklist_id: {_eq: $checklist_id},
      section_id: {_eq:  $section_id}, theme_id: {_eq: $theme_id}}) {
       id  
      }
      }
    `;

export const GET_AUDIT_PHOTO_BY_METADATA_ID = gql`
query get_audit_photo_by_metadata_id($audit_photo_metadata_id:Int!){
  qat_audit_photo( where:{audit_photo_metadata_id: {_eq:$audit_photo_metadata_id}})
  {
     id
     audit_photo_metadata_id
     minetype
     url
  }
}
` ;