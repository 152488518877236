import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import {
  READ_ALL_DONE_AUDITS_SORTED,
  READ_CURRENT_AUDITS,
} from '../ReadService/AuditReadQuery';
import { DELETE_ENTITY } from './AuditDeleteMutation';

export const useDeleteAudit = () => {
  const { setNotification } = useContext(MainAppContext);

  const [deleteAudit] = useMutation(DELETE_ENTITY, {
    refetchQueries: [
      {
        query: READ_ALL_DONE_AUDITS_SORTED,
        variables: { order_by: { last_updated_date: 'desc' } },
      },
      { query: READ_CURRENT_AUDITS },
    ],
    awaitRefetchQueries: true,
    onCompleted({ deletedAudit }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const myDelete = async (id: number) => {
    deleteAudit({
      variables: { id: id, deletedDate: new Date() },
    });
  };

  return { myDelete };
};
