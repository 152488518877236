import { useContext, useEffect } from 'react'
import axios, { AxiosInstance } from 'axios'
import NetworkListenerContext from '../components/NetworkListener/NetworkListenerContext';
import AppContext from '../AppContext';
import { NotificationType } from '../components/Notification/NotificationComponent';

export const useAxiosInstance = ({ onError }: { onError?: () => void }): { instance: AxiosInstance } => {
    const { onChangeIsOffline } = useContext(NetworkListenerContext)
    const { setNotification } = useContext(AppContext);

    useEffect(() => {
        axios.interceptors.request.use(
            (config) => {
                return config
            },
            (error) => {
                if (onError) {
                    onError()
                }
                return Promise.reject(error)
            }
        )
    }, [onError])

    useEffect(() => {
        axios.interceptors.response.use(
            (response) => {
                if (response.status <= 400 && onError) {
                    onError()
                }
                return response

            },
            (error) => {
                console.log(error.response)
                if (onError) {
                    onError()
                } else if (error.response.status && error.response?.status === 404) {
                    setNotification({ key: 'adminDocument:noContentUpload', type: NotificationType.INFO });
                    return Promise.reject(error);
                } else if (error.response.status && error.response?.status >= 400) {
                    setNotification({ key: 'common:generalError', type: NotificationType.ERROR });
                    return Promise.reject(error);
                }
                onChangeIsOffline(true)
                return Promise.reject(error)
            }
        )
    }, [onChangeIsOffline, onError, setNotification])

    return { instance: axios }
}


