import React from 'react';
import { useTranslation } from 'react-i18next';
import ChecklistThemeSectionComponent from '../../ChecklistThemeSection/ChecklistThemeSectionComponent';
import clsx from 'clsx';
import ConfirmDialog from '../../Shared/Dialog/ConfirmDialog/ConfirmDialogComponent/ConfirmDialogComponent';
import { useStyles } from './ChecklistThemeStyle';
import { SectionsOpenState } from '../ChecklistThemePresenter/ChecklistThemePresenter';
import { get_checklist_by_pk_theme_data } from '../../../services/Checklist/ChecklistReadService/ChecklistReadQuery';
import { building_data } from '../../../services/Building/BuildingDataModel';
import { audit_data } from '../../../services/Audit/AuditDataModel';


export const testIds = {
  confirmDialogNotApplication: 'confirm-dialog-not-application',
};

export interface ChecklistThemeViewProps {
  theme: get_checklist_by_pk_theme_data;
  checklistId: number;
  onUpdateNotApplication: (id, no_application) => void;
  themeNotApplicationValue: boolean;
  onConfirmUpdateNotApplication: () => void;
  openConfirmNotApplication: boolean;
  onCloseConfirmNotApplication: () => void;
  onChangeSectionOpen: (id: number) => void;
  sections: SectionsOpenState;
  isReadOnlyMode: boolean;
  handleDuplicate: (section: any) => void;
  handleDelete: (id) => void;
  audit: audit_data;
  building: building_data;

}

const ChecklistThemeView = ({
  theme,
  checklistId,
  onUpdateNotApplication,
  themeNotApplicationValue,
  onConfirmUpdateNotApplication,
  openConfirmNotApplication,
  onCloseConfirmNotApplication,
  onChangeSectionOpen,
  sections,
  isReadOnlyMode,
  handleDuplicate,
  handleDelete,
  audit,
  building,
}: ChecklistThemeViewProps) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={clsx(classes.containerThemeSection)}>
        {Object.values(sections).length && theme?.checklist_theme_sections?.map((section, index) => {
          return (
            <ChecklistThemeSectionComponent
              themeNotApplicationValue={themeNotApplicationValue}
              sectionsCounter={theme.checklist_theme_sections.length}
              section={section}
              checklistId={checklistId}
              onUpdateNotApplication={onUpdateNotApplication}
              key={section.id}
              expanded={sections[section.id] ? sections[section.id].isOpen : true}
              onExpand={() => onChangeSectionOpen(section.id)}
              isReadOnlyMode={isReadOnlyMode}
              handleDuplicate={handleDuplicate}
              handleDelete={handleDelete}
              themeNotApplicationOptionValue={theme.not_applicable_option}
              audit={audit}
              building={building}

            />
          );
        })}
      </div>
      <ConfirmDialog
        dataTestId={testIds.confirmDialogNotApplication}
        title={t('checklist_theme:confirmNotApplicationTitle')}
        text={t('checklist_theme:confirmNotApplicationText')}
        onConfirm={onConfirmUpdateNotApplication}
        open={openConfirmNotApplication}
        onClose={onCloseConfirmNotApplication}
      />
    </div>
  );
};

export default ChecklistThemeView;
