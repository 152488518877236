import { useCallback, useMemo, useReducer } from 'react'

export interface DocumentDialogServiceState {
    open: boolean;
    pageNumber: number
}

export interface DocumentDialogServiceContextValue {
    onSelectPage: (pageNumber: number) => void;
    onCloseDialog: () => void;
    onNextPage: () => void;
    onPreviousPage: () => void;
    open: boolean,
    pageNumber: number;
}

export type DocumentDialogServiceAction =
    | { type: "SELECT_PAGE", payload: number }
    | { type: "CLOSE_DIALOG" }
    | { type: "NEXT_PAGE" }
    | { type: "PREVIOUS_PAGE" }


function reducer(state: DocumentDialogServiceState, action: DocumentDialogServiceAction) {
    switch (action.type) {
        case 'SELECT_PAGE':
            return { open: true, pageNumber: action.payload };
        case 'CLOSE_DIALOG':
            return { ...state, open: false };
        case 'NEXT_PAGE':
            return { ...state, pageNumber: state.pageNumber + 1 };
        case 'PREVIOUS_PAGE':
            return { ...state, pageNumber: state.pageNumber - 1 };
        default:
            throw new Error();
    }
}

const initState: DocumentDialogServiceState = {
    open: false,
    pageNumber: 1
}

const init = () => initState



export const useDocumentDialogService = (): DocumentDialogServiceContextValue => {

    const [state, dispatch] = useReducer(reducer, initState, init)

    const onSelectPage = useCallback((pageNumberVar: number) => dispatch({ type: "SELECT_PAGE", payload: pageNumberVar }), [])
    const onCloseDialog = useCallback(() => dispatch({ type: "CLOSE_DIALOG" }), [])
    const onNextPage = useCallback(() => dispatch({ type: "NEXT_PAGE" }), [])
    const onPreviousPage = useCallback(() => dispatch({ type: "PREVIOUS_PAGE" }), [])

    const open = useMemo(() => state.open, [state])
    const pageNumber = useMemo(() => state.pageNumber, [state])

    return {
        onSelectPage,
        onCloseDialog,
        onNextPage,
        onPreviousPage,
        open,
        pageNumber
    }

}