import React, { useCallback, useMemo} from 'react';
import { useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { openCreateItemVar, itemVar } from '../ItemCache';
import ItemFormDialogComponent from '../ItemDialogComponent/ItemFormDialogComponent';
import { ItemFormData } from '../../../services/Item/ItemFormData';
import { useCreateItemFromThemeOrSubTheme } from '../../../services/Item/WriteService/ItemWriteService';
import { ItemDisplayData } from '../ItemDisplayData';
import {
  useGetAllItemFromTheme,
  useGetAllItemFromSubTheme,
} from '../../../services/Item/ReadService/ItemReadService';
import { useBrowserLocationState } from '../../Shared/BrowserLocationState/BrowserLocationState';


function itemFormValue({
  currentItem,
  template_theme_id,
  template_sub_theme_id,
}: {
  currentItem: ItemDisplayData;
  template_theme_id?: number;
  template_sub_theme_id?: number;
}): ItemFormData | null {
  const itemForm: ItemFormData = {
    title_fr: currentItem.title_fr,
    title_nl: currentItem.title_nl,
    norm_code: currentItem.norm_code,
    page_pdf_fr: parseInt(currentItem.page_pdf_fr),
    page_pdf_nl: parseInt(currentItem.page_pdf_nl),
    information_fr: currentItem.information_fr,
    information_nl: currentItem.information_nl,
    not_application_option: currentItem.not_application_option,
  };

  if (!template_sub_theme_id && !template_theme_id) {
    console.error('template_sub_theme_id or template_theme_id is required');
    return null;
  }

  if (template_sub_theme_id) {
    itemForm.template_sub_theme_id = template_sub_theme_id;
  } else {
    itemForm.template_theme_id = template_theme_id;
  }

  return itemForm;
}

const ItemCreateDialogComponent = () => {
  const isOpenCreateItem: boolean = useReactiveVar(openCreateItemVar);
  const currentItem: ItemDisplayData = useReactiveVar(itemVar);


  const currentLocationState = useBrowserLocationState<{theme: any, subTheme?: any}>({keysRequired: ["theme"]})
 
  const templateThemeId: number | undefined = useMemo(
    () => currentLocationState?.theme?.id,
    [currentLocationState],
  );
  const templateSubThemeId: number | undefined = useMemo(
    () => currentLocationState?.subTheme?.id,
    [currentLocationState],
  );

  const { onCreateItemFromThemeOrSubTheme } = useCreateItemFromThemeOrSubTheme({
    template_theme_id: templateThemeId,
    template_sub_theme_id: templateSubThemeId,
  });
  
   const {
    data: themeItemData
  } = useGetAllItemFromTheme(templateThemeId? templateThemeId : -1);
  const {
    data: subThemeItemData
  } = useGetAllItemFromSubTheme(templateSubThemeId? templateSubThemeId : -1); 

  const defaultItem: ItemDisplayData = useMemo(() => {
    return {
      id: -1,
      information_fr: '',
      information_nl: '',
      norm_code: '',
      page_pdf_fr: '',
      page_pdf_nl: '',
      not_application_option: false,
      title_fr: '',
      title_nl: '',
      template_theme_id: -1,
      template_sub_theme_id: -1,
      index: -1,
    };
  }, []);

  const handleCreateItem = useCallback(() => {
    let itemForm: ItemFormData | null = itemFormValue({
      currentItem,
      template_sub_theme_id: templateSubThemeId,
      template_theme_id: templateThemeId,
    });
    if (itemForm) {
      onCreateItemFromThemeOrSubTheme(itemForm);
      openCreateItemVar(false);
      itemVar(defaultItem);
    }
  }, [
    currentItem,
    templateSubThemeId,
    templateThemeId,
    onCreateItemFromThemeOrSubTheme,
    defaultItem,
  ]);

  const handleCreateAddItem = useCallback(() => {
    let itemForm: ItemFormData | null = itemFormValue({
      currentItem,
      template_sub_theme_id: templateSubThemeId,
      template_theme_id: templateThemeId,
    });
    if (itemForm) {
      onCreateItemFromThemeOrSubTheme(itemForm);
      itemVar(defaultItem);
    }
  }, [
    currentItem,
    templateSubThemeId,
    templateThemeId,
    onCreateItemFromThemeOrSubTheme,
    defaultItem,
  ]);

  const history = useHistory();
  const handleClose = useCallback(() => {
    openCreateItemVar(false);
    itemVar(defaultItem);
    if((templateThemeId && themeItemData.length ===0) || (templateSubThemeId && subThemeItemData.length === 0)) {
      history.goBack();
    }
  }, [defaultItem, history, subThemeItemData.length, themeItemData.length, templateThemeId, templateSubThemeId]);

  return (
    <ItemFormDialogComponent
      isOpenForm={isOpenCreateItem}
      handleClose={handleClose}
      handleFormSubmit={handleCreateItem}
      handleCreateAddItem={handleCreateAddItem}
      templateSubThemeId={templateSubThemeId}
      templateThemeId={templateThemeId}
    />
  );
};

export default ItemCreateDialogComponent;
