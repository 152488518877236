import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface ConfirmDialogProps {
  dataTestId?: string;
  open: boolean;
  onConfirm: () => void;
  onClose: () => void;
  title: string;
  text: string;
}

/**
 * Description - Dialog to confirm action
 */
const ConfirmDialog: React.FC<ConfirmDialogProps> = ({
  dataTestId,
  open,
  onConfirm,
  onClose,
  title,
  text,
}) => {
  const { t } = useTranslation();

  return (
    <Dialog data-testid={dataTestId} open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{text}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm}>{t('common:confirm')}</Button>
        <Button onClick={onClose}>{t('common:cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
