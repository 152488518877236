import React, { useCallback } from 'react';
import {
  Table,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  IconButton,
  Paper,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import routes from '../../../../../routes';
import { CreateRounded, DeleteRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import AuthorizationComponent from '../../../../Authorization/AuthorizationComponent/AuthorizationComponent';
import { Rule } from '../../../../Authorization/AuthorizationComponent/Permissions';
import { entity_data } from '../../../../../services/Entity/EntityDataModel';
import { TableSortLabel } from '@material-ui/core';
import { OrderBy } from '../EntityReadComponent';
import { useHistory } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { openCreateLocationVar, locationLastIndexVar } from "../../../../Location/LocationCache";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },

  tableRow: {
    backgroundColor: '#f0f0f0',
  },

  paper: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },

  tableContainer: {
    height: 'auto',
    maxHeight: 500,
  },

  arrowButton: {
    color: '#03a9f4',
  },
});

const StyledTableSortLabel = withStyles((theme: Theme) =>
createStyles({
  root: {
    color: '#03a9f4',
    '&:hover': {
      color: '#03a9f4',
    },
    '&$active': {
      color: '#03a9f4',
    },
  },
  active: {},
  icon: {
    color: 'inherit !important',
  },
}),
)(TableSortLabel);

/**
 * Description - test id for EntityTableComponent
 */
export const testIds = {
  buttonOnUpdate: 'button-update-entity',
  buttonOnDelete: 'button-delete-entity',
  paginationComponent: 'pagination-component',
  buttonOnCreateLocation: 'button-create-location',
  buttonOpenListOfLocation: 'button-open-location',


};

export interface EntityTableComponentProps {
  listEntities: Array<entity_data>;
  onUpdate: (id: number) => void;
  onDelete: (id: number) => void;
  onSort: (sortItem: OrderBy) => void;
  sortByItems: OrderBy[];
}

const EntityTableComponent: React.FC<EntityTableComponentProps> = ({
  listEntities,
  onUpdate,
  onDelete,
  onSort,
  sortByItems,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const history = useHistory();

  const onCreateLocation = useCallback(
    (currentEntity: entity_data) => {
      openCreateLocationVar(true);
      locationLastIndexVar(1)
      history.push({
        pathname: routes.createLocation.path,
        state: {
          entity: currentEntity
        },
      })
    },
    [history],
  )


  const onOpenListOfLocation = useCallback(
    (currentEntity: entity_data) => {
      openCreateLocationVar(false);
      history.push({
        pathname: routes.allLocations.path,
        state: {
          entity: currentEntity,
          entityLocations: currentEntity.location_entities,
        },
      });
    },
    [history]);
  return (
    <Paper className={classes.paper}>
    <TableContainer className={classes.tableContainer}>
    <Table stickyHeader className={classes.table} aria-label="sticky table">
      <TableHead>
        <TableRow>
          <TableCell className={classes.tableRow}>
            {t('entity:name')}{' '}
            <StyledTableSortLabel
              className={classes.arrowButton}
              direction={
                sortByItems.find(i => i?.column === 'label')?.direction
              }
              onClick={() => onSort({ column: 'label' })}
              active={true}
            />
          </TableCell>
          <TableCell className={classes.tableRow}>
          {t('entity:abbreviation')}{' '}
            <StyledTableSortLabel
              direction={
                sortByItems.find(i => i?.column === 'abbreviation')?.direction
              }
              onClick={() => onSort({ column: 'abbreviation' })}
              active={true}
            />
          </TableCell>
          <TableCell className={classes.tableRow} align="center">{t("location:location")}</TableCell>
          <TableCell className={classes.tableRow} align="center">{t('common:edit')}</TableCell>
          <TableCell className={classes.tableRow} align="center">{t('common:delete')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {listEntities.map((entityToShow: entity_data) => (
          <TableRow key={entityToShow.id}>
            <TableCell component="th" scope="row">
              {entityToShow.label}
            </TableCell>
            <TableCell>{entityToShow.abbreviation}</TableCell>
            <TableCell align="center">
              {entityToShow && entityToShow?.location_entities && entityToShow?.location_entities?.length > 0 ?
                (
                  <IconButton
                    data-testid={testIds.buttonOpenListOfLocation}
                    onClick={() => onOpenListOfLocation(entityToShow)}
                  >
                    <VisibilityIcon />
                  </IconButton>
                ) : (
                  <IconButton
                    data-testid={testIds.buttonOnCreateLocation}
                    onClick={() => onCreateLocation(entityToShow)}
                  >
                    <AddRoundedIcon />
                  </IconButton>
                )
              }
            </TableCell>
            <TableCell align="center">
              <AuthorizationComponent
                rules={[Rule.CAN_VIEW_ENTITY_CRUD]}
                message={'common:readOnly'}
              >
                <IconButton
                  data-testid={testIds.buttonOnUpdate}
                  component={Link}
                  to={routes.editEntity.path}
                  onClick={() => onUpdate(entityToShow.id)}
                >
                  <CreateRounded />
                </IconButton>
              </AuthorizationComponent>
            </TableCell>
            <TableCell align="center">
              <AuthorizationComponent
                rules={[Rule.CAN_VIEW_ENTITY_CRUD]}
                message={'common:readOnly'}
              >
                <IconButton
                  data-testid={testIds.buttonOnDelete}
                  onClick={() => onDelete(entityToShow.id)}
                >
                  <DeleteRounded />
                </IconButton>
              </AuthorizationComponent>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    </TableContainer>
    </Paper>
  );
};

export default EntityTableComponent;
