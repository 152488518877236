export const entity = {
  name: 'Nom',
  abbreviation: 'Abréviation',
  category: 'Catégorie',
  entity: 'Entité',
  createEntity: 'Ajouter une entité',
  editEntities: 'Editer une entité',
  allEntities: 'Liste des Entités',
  nameError: 'Le nom est un champ obligatoire',
  abbreviationError: "L'abréviation est un champ obligatoire",
  categoryError: 'La catégorie est un champ obligatoire',
  entityValidationError: "L'entité que vous avez spécifiée existe déjà",
  updateEntity: 'Modifier une entité',
  entityError: "L'entité est un champ obligatoire",
  search: 'Rechercher une entité',
};
