import { useApolloClient, useMutation } from '@apollo/client';
import { useCallback, useContext, useMemo } from 'react';
import { INSERT_THEME_TEMPLATE_CHECKLIST } from '.';
import MainAppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import {
  GET_ALL_THEME_INCLUDE_SOFT_DELETE_IN_TEMPLATE,
  get_all_theme_include_soft_delete_in_template_data,
  READ_THEME_TEMPLATE_CHECKLIST_BY_TEMPLATE_ID,
  theme_template_checklist_data_from_list,
  useGetAllThemeIncludeSoftDeleteByTemplate,
} from '../ThemeReadService';
import {
  ADD_THEME_FROM_ANOTHER_TEMPLATE_FUNCTION,
  UPDATE_THEMES_INDEX,
  UPDATE_THEME_TEMPLATE_CHECKLIST,
} from './ThemeWriteMutation';
import {
  ThemeFormData,
  ThemeUpdateFormData,
} from '../../../components/Theme/ThemeFormModel';
import { handleReorder } from '../../../components/Shared/utils/order.utils';
import {
  update_themes_index_data,
  update_themes_index_response,
  update_themes_index_variables,
} from './ThemeWriteModel';
export interface ThemeCreateServiceProps {
  templateChecklistId: number;
}

export interface ThemeUpdateServiceProps {
  templateChecklistId: number;
  showNotification: boolean;
}
export interface ThemeCreateServiceResult {
  onCreate: (themeData: ThemeFormData) => void;
}

export interface ThemeUpdateServiceResult {
  onUpdate: (themeData: ThemeUpdateFormData) => void;
}

/**
 * Description - Service to create theme template checklist data
 */
export const useCreateTheme = ({
  templateChecklistId,
}: ThemeCreateServiceProps): ThemeCreateServiceResult => {
  const { setNotification } = useContext(MainAppContext);
  const {
    getAllThemeIncludeSoftDeleteByTemplate,
  } = useGetAllThemeIncludeSoftDeleteByTemplate();

  const mutationConfiguration = useMemo(() => ({
    refetchQueries: [
      {
        query: READ_THEME_TEMPLATE_CHECKLIST_BY_TEMPLATE_ID,
        variables: { templateChecklistId },
      },
      {
        query: GET_ALL_THEME_INCLUDE_SOFT_DELETE_IN_TEMPLATE,
        variables: { template_checklist_id: templateChecklistId },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted() {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    }
  }), [setNotification, templateChecklistId])

  const [onCreateTheme] = useMutation(INSERT_THEME_TEMPLATE_CHECKLIST, {
    ...mutationConfiguration
  });

  const onCreate = useCallback(
    (data: ThemeFormData) => {
      getAllThemeIncludeSoftDeleteByTemplate({
        template_checklist_id: templateChecklistId,
      }).then(themes => {
        const newIndex = themes.length;

        

        onCreateTheme({
          variables: {
            object: {
              ...data,
              template_checklist_id: templateChecklistId,
              index: newIndex,
            },
          },
        });
      });
    },
    [
      onCreateTheme,
      getAllThemeIncludeSoftDeleteByTemplate,
      templateChecklistId
    ],
  );

  return { onCreate };
};

/**
 * Description - Service to update a theme
 */
export const useUpdateTheme = ({
  templateChecklistId,
  showNotification,
}: ThemeUpdateServiceProps): ThemeUpdateServiceResult => {
  const { setNotification } = useContext(MainAppContext);

  const mutationConfiguration = useMemo(() => ({
    refetchQueries: [
      {
        query: READ_THEME_TEMPLATE_CHECKLIST_BY_TEMPLATE_ID,
        variables: { templateChecklistId },
      },
      {
        query: GET_ALL_THEME_INCLUDE_SOFT_DELETE_IN_TEMPLATE,
        variables: { template_checklist_id: templateChecklistId },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted() {
      if(showNotification){
        setNotification({
          key: 'common:savedSuccess',
          type: NotificationType.SUCCESS,
        });
      }
    },
    onError(error) {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    }
  }), [setNotification, templateChecklistId, showNotification])

  const [onUpdateTheme] = useMutation(UPDATE_THEME_TEMPLATE_CHECKLIST, {
    ...mutationConfiguration
  })

  const onUpdate = useCallback(
    (data: ThemeUpdateFormData) => {
      onUpdateTheme({
        variables: {
          qat_theme_template_checklist_pk_columns_input: { id: data?.id },
          qat_theme_template_checklist_set_input: { ...data },
        },
      });
    },
    [onUpdateTheme],
  );

  return { onUpdate };
};

export const useUpdateThemesIndex = ({
  template_checklist_id,
}: {
  template_checklist_id: number;
}) => {
  const { setNotification } = useContext(MainAppContext);
  const {
    getAllThemeIncludeSoftDeleteByTemplate,
  } = useGetAllThemeIncludeSoftDeleteByTemplate();

  const [updateIndex] = useMutation<
    update_themes_index_response,
    update_themes_index_variables
  >(UPDATE_THEMES_INDEX, {
    refetchQueries: [
      {
        query: READ_THEME_TEMPLATE_CHECKLIST_BY_TEMPLATE_ID,
        variables: { templateChecklistId: template_checklist_id },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted() {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      console.error(error);
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const onUpdateThemesIndex = useCallback(
    (newValue: { id: number; index: number }) => {
      return new Promise<update_themes_index_data[]>(resolve => {
        getAllThemeIncludeSoftDeleteByTemplate({ template_checklist_id }).then(
          themes => {
            const previousValue:
              | get_all_theme_include_soft_delete_in_template_data
              | undefined = themes.find(({ id }) => id === newValue.id);
            if (!previousValue) {
              setNotification({
                key: 'common:generalError',
                type: NotificationType.ERROR,
              });
              resolve([]);
            } else {
              const themesReordered: get_all_theme_include_soft_delete_in_template_data[] = handleReorder<get_all_theme_include_soft_delete_in_template_data>(
                themes,
                previousValue.index,
                newValue.index,
              ).map(({ id, __typename, ...rest }, index) => ({
                ...rest,
                id,
                index,
              }));
              updateIndex({ variables: { objects: themesReordered } }).then(
                response => {
                  if (response && response.data) {
                    resolve(response.data.insert_qat_theme_template_checklist);
                  } else {
                    resolve([]);
                  }
                },
              );
            }
          },
        );
      });
    },
    [
      getAllThemeIncludeSoftDeleteByTemplate,
      updateIndex,
      template_checklist_id,
      setNotification,
    ],
  );

  return { onUpdateThemesIndex };
};

export interface CreateThemesServiceProps {
  templateChecklistId: number;
}

export const useCreateThemesService = ({
  templateChecklistId,
}: CreateThemesServiceProps) => {
  const { setNotification } = useContext(MainAppContext);

  const client = useApolloClient();

  const onAddThemesAndReOrderThemes = useCallback(
    (
      data: theme_template_checklist_data_from_list[],
      themeAlreadyExist: theme_template_checklist_data_from_list[],
    ) => {
      // delete themes already duplicate
      const dataLongNameFr = data.map(({ long_name_fr }) => long_name_fr);
      const dataLongNameNl = data.map(({ long_name_nl }) => long_name_nl);
      const dataShortNameFr = data.map(({ short_name_fr }) => short_name_fr);
      const dataShortNameNl = data.map(({ short_name_nl }) => short_name_nl);
      const themesWithOutPreviousDuplicate = themeAlreadyExist.filter(
        ({ long_name_fr, long_name_nl, short_name_fr, short_name_nl }) =>
          !dataLongNameFr.includes(long_name_fr) &&
          !dataLongNameNl.includes(long_name_nl) &&
          !dataShortNameFr.includes(short_name_fr) &&
          !dataShortNameNl.includes(short_name_nl),
      );

      // update index to order the new theme before the theme already existed
      const themesWithIndexUpdated: theme_template_checklist_data_from_list[] = data
        .concat(themesWithOutPreviousDuplicate)
        .map((theme, i) => ({ ...theme, index: i }));

      const ADD_THEME_FROM_ANOTHER_TEMPLATE = ADD_THEME_FROM_ANOTHER_TEMPLATE_FUNCTION(
        { themes: themesWithIndexUpdated },
      );
      client
        .mutate({
          mutation: ADD_THEME_FROM_ANOTHER_TEMPLATE,
          refetchQueries: [
            {
              query: READ_THEME_TEMPLATE_CHECKLIST_BY_TEMPLATE_ID,
              variables: { templateChecklistId },
            },
          ],
          awaitRefetchQueries: true,
          variables: { templateChecklistId },
        })
        .then(() => {
          setNotification({
            key: 'common:savedSuccess',
            type: NotificationType.SUCCESS,
          });
        })
        .catch(() => {
          setNotification({
            key: 'common:generalError',
            type: NotificationType.ERROR,
          });
        });
    },
    [client, setNotification, templateChecklistId],
  );

  return { onAddThemesAndReOrderThemes };
};
