import { useApolloClient, useQuery } from '@apollo/client';
import {
  CHECK_IF_UNIQUE_AUDIT,
  GET_AUDIT_BY_PK,
  GET_AUDIT_REPORT_INTERVIEW_BY_AUDIT_ID,
  READ_ALL_DONE_AUDITS_SORTED,
  READ_CURRENT_AUDITS,
} from './AuditReadQuery';
import {
  audits_data,
  audit_data_by_pk,
  check_if_unique_audit,
  check_if_unique_audit_variables,
  get_audit_report_interview_by_audit_id_response,
  get_audit_report_interview_by_audit_id_variables,
} from './AuditReadModel';
import { useContext, useCallback } from 'react';
import AppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { audit_data } from '../AuditDataModel';

/**
 * Description - service to get all audits
 */
export const useGetCurrentAudit = () => {
  let { data, loading, error } = useQuery<audits_data>(READ_CURRENT_AUDITS);
  return { data, loading, error };
};

/**
 * Description - service to get all sorted audits with status done
 */
export const useGetAllSortedDoneAudits = () => {
  const client = useApolloClient();
  const { setNotification } = useContext(AppContext);

  const orderAuditsBy = useCallback(
    (orderBy: any) =>
      new Promise<audit_data[]>(resolve => {
        client
          .query<audits_data>({
            query: READ_ALL_DONE_AUDITS_SORTED,
            variables: { order_by: orderBy },
          })
          .then(response => {
            resolve(response?.data?.qat_audit);
          })
          .catch(errorResponse => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      }),
    [setNotification, client],
  );

  return { orderAuditsBy };
};

export const useGetAuditById = () => {
  const client = useApolloClient();
  const { setNotification } = useContext(AppContext);

  const getAuditById = useCallback(
    id =>
      new Promise<audit_data>(resolve => {
        client
          .query<audit_data_by_pk>({
            query: GET_AUDIT_BY_PK,
            variables: { id },
            fetchPolicy: 'network-only',
          })
          .then(response => {
            resolve(response.data.qat_audit_by_pk);
          })
          .catch(error => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      }),
    [setNotification, client],
  );

  return { getAuditById };
};

/**
 * Description: service to check if the audit is unique
 *
 * Remark: key: (entity_id, location_id, user_id, audit_date) unique and deleted_date is null
 */
export const useCheckUniqueAudit = () => {
  const client = useApolloClient();
  const { setNotification } = useContext(AppContext);

  const checkUniqueAudit = useCallback(
    ({
      entity_id,
      location_id,
      audit_date,
      user_id,
      id,
    }: check_if_unique_audit_variables) =>
      new Promise<boolean>(resolve => {
        client
          .query<check_if_unique_audit, check_if_unique_audit_variables>({
            query: CHECK_IF_UNIQUE_AUDIT,
            variables: { entity_id, location_id, audit_date, user_id, id },
            fetchPolicy: 'network-only',
          })
          .then(response => {
            resolve(response.data.qat_audit.length === 0);
          })
          .catch(error => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      }),
    [setNotification, client],
  );

  return { checkUniqueAudit };
};

export const useGetAuditReportInterviewByAuditId = ({
  id,
}: get_audit_report_interview_by_audit_id_variables) => {
  const { setNotification } = useContext(AppContext);

  const { data, loading, error } = useQuery<
    get_audit_report_interview_by_audit_id_response,
    get_audit_report_interview_by_audit_id_variables
  >(GET_AUDIT_REPORT_INTERVIEW_BY_AUDIT_ID, {
    variables: { id },
    fetchPolicy: 'network-only',
    onError: () => {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  return {
    data: data !== undefined ? data.qat_audit_by_pk : null,
    loading,
    error,
  };
};
