import { makeStyles } from '@material-ui/core/styles';
import { drawerWidth } from '../NavigationStyle';

const useStyles = makeStyles(theme => ({
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: '#ffffff',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
  hide: {
    display: 'none',
  },
  open: {
    color: '#cf012c',
  },
  title: {
    flexGrow: 1,
    color: '#141414',
    padding: theme.spacing(2),
  },
}));

export { useStyles };
