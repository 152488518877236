import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import {
  Button,
  Grid,
  Typography,
  Paper,
  TextareaAutosize,
  Container,
  Box,
  Divider,
  AppBar,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { useTranslation } from 'react-i18next';
import AppContext from '../../AppContext';
import { useHistory } from 'react-router-dom';
import { useStyles } from './ChecklistRemarkStyle';
import { translate } from '../../services/Translation/TranslationService';
import { debounce } from 'lodash';
import routes from '../../routes';
import ChecklistThemeRemarkComponent from './ChecklistThemeRemark/ChecklistThemeRemarkComponent';
import { useUpdateChecklistById } from '../../services/Checklist/ChecklistWriteService';
import { useBeforeunload } from 'react-beforeunload';
import { NotificationType } from '../Notification/NotificationComponent';
import AuthorizationComponent from '../Authorization/AuthorizationComponent/AuthorizationComponent';
import { Rule } from '../Authorization/AuthorizationComponent/Permissions';
import { useBrowserLocationState } from '../Shared/BrowserLocationState/BrowserLocationState';

export const mainTestIds = {
  buttonOnCreate: 'button-create-item-remark',
};

const ChecklistRemarkComponent = () => {
 
  const currentLocationState = useBrowserLocationState<{audit: any, building: any, checklist?:any, isReadOnlyMode?: boolean}>({keysRequired: ["audit", "building"]})

  const { t } = useTranslation();
  const classes = useStyles();
  const { language, setNotification } = useContext(AppContext);
  const history = useHistory();
  const audit = useMemo(() => currentLocationState?.audit, [currentLocationState]);
  const building = useMemo(() => currentLocationState?.building, [currentLocationState]);
  const checklist = useMemo(() => currentLocationState?.checklist, [currentLocationState]);
  const [comment, setComment] = useState(checklist?.comment);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { email } = useContext(AppContext);


  const category = useMemo(
    () => currentLocationState?.audit?.location_entity?.category_location,
    [currentLocationState],
  );

  const subCategory = useMemo(
    () => currentLocationState?.building?.sub_category_location,
    [currentLocationState],
  );

  const checklistId = useMemo(() => currentLocationState?.checklist?.id, [currentLocationState]);
  const isReadOnlyMode = useMemo(() => currentLocationState?.isReadOnlyMode ? currentLocationState.isReadOnlyMode : false, [
    currentLocationState,
  ]);

  const { onUpdateById: updateChecklist } = useUpdateChecklistById(checklistId);

  useEffect(() => {
    setComment(checklist?.comment);
  }, [checklist, checklistId]);

  const handleReturnToChecklist = () => {
    history.push({
      pathname: routes.editChecklist.path,
      state: {
        building: building,
        audit: audit,
        checklist: checklist,
      },
    });
  };

  const debouncedSave = useCallback(
    debounce(
      (nextValue, checklistIdVar) =>
        updateChecklist(checklistIdVar, { comment: nextValue })?.then(response => {
          handleSetState(false);
        }),
      1000,
    ),
    [],
  );

  const handleSetState = useCallback(isSavingVar => {
    setIsSaving(isSavingVar);
  }, []);

  useBeforeunload(event => {
    if (isSaving === true) {
      event.preventDefault();
      setNotification({ key: 'common:saving', type: NotificationType.WARNING });
      return null;
    }
  });

  const handleChecklistRemarkDelete = () => {
    const nextValue = '';
    setComment(nextValue);
    debouncedSave(nextValue, checklistId);
  };

  const handleChangeComment = event => {
    handleSetState(true);
    const { value: nextValue } = event.target;
    setComment(nextValue);
    debouncedSave(nextValue, checklistId);
  };

  const isReadOnlyModeRemarkChange = () => { };

  return (
    <>
      <AuthorizationComponent
        rules={[Rule.CAN_VIEW_CHECKLIST_REMARK_MENU]}
        email={email}
      >
        <Container maxWidth="xl">
          <AppBar
            style={{ top: '63px' }}
            position="sticky"
            color="inherit"
            elevation={0}
          >
            <Box mb={2}>
              <Button
                className={classes.returnButton}
                variant="contained"
                onClick={handleReturnToChecklist}
              >
                {t('checklist_remark:returnButton')}
              </Button>
            </Box>

            <Grid container>
              <Box m={0.5} ml={2}>
                <Grid item lg={12} xs={12}>
                  <Typography
                    display="inline"
                    className={classes.checklistRemarkTitle}
                  >
                    {`${building?.address?.street} ${building?.address?.house_number} ${building?.address?.city}`}
                    {`, `}
                    {!subCategory && category && translate(category, language)}
                    {subCategory && translate(subCategory, language)}
                  </Typography>
                </Grid>
              </Box>
              <Grid item lg={12} xs={12}>
                <Box ml={2} mt={1}>
                  {building?.building_field_values?.map(fieldValue => (
                    <Typography
                      className={classes.checklistRemarkTitle}
                      key={fieldValue.category_location_form_field_config_id}
                      variant="subtitle1"
                    >
                      {translate(
                        fieldValue.category_location_form_field_config,
                        language,
                      )}
                      : {fieldValue.value}{' '}
                    </Typography>
                  ))}
                </Box>
              </Grid>
              <Grid item lg={12} xs={12}>
                <Box ml={2} mt={1}>
                  <Typography
                    variant="subtitle1"
                    className={classes.generalRemarkLabel}
                  >
                    {t('checklist_remark:generalRemarkLabel')}
                  </Typography>
                </Box>
                <Divider />
              </Grid>
              <Grid item lg={11} xs={10}>
                <Box m={2}>
                  <TextareaAutosize
                    aria-label={t('checklist_remark:generalRemarkLabel')}
                    id="comment"
                    name="comment"
                    minRows={2}
                    className={classes.textarea}
                    value={comment ? comment : ''}
                    onChange={event =>
                      isReadOnlyMode
                        ? isReadOnlyModeRemarkChange
                        : handleChangeComment(event)
                    }
                    placeholder={t('checklist_remark:generalRemarkLabel')}
                  />
                </Box>
              </Grid>
              <Grid item lg={1} xs={1}>
                <Box mt={2} ml={2}>
                  <Button
                    onClick={
                      isReadOnlyMode
                        ? isReadOnlyModeRemarkChange
                        : handleChecklistRemarkDelete
                    }
                  >
                    <DeleteIcon color="primary" />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </AppBar>

          <Paper elevation={0}>
            <Grid container>
              <Grid item lg={12} xs={12}>
                {checklist?.checklist_themes
                  .filter(
                    checklist_theme =>
                      checklist_theme.comment !== null &&
                      checklist_theme.comment.length !== 0,
                  )
                  .map(theme => {
                    return (
                      <ChecklistThemeRemarkComponent
                        key={theme?.id}
                        theme={theme}
                        checklistId={checklistId}
                        isReadOnlyMode={isReadOnlyMode}
                      />
                    );
                  })}
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </AuthorizationComponent>
    </>
  );
};
//Injecte la propriété isLandscape
export default ChecklistRemarkComponent;
