export const common = {
  validate: 'Valider',
  generalError: "Une erreur technique s'est produite",
  edit: 'Éditer',
  delete: 'Supprimer',
  confirmDelete: 'Voulez-vous vraiment le supprimer?',
  search: 'Rechercher',
  unauthorized: 'Vous n’avez pas accès',
  management: 'Gestion',
  savedSuccess: 'Enregistré avec succès',
  rowsPerPage: 'Ligne par page',
  update: 'Modifier',
  confirm: 'Confirmer',
  cancel: 'Annuler',
  navigation: 'Navigation',
  readOnly: "Vous n'avez que des droits de lecture",
  order: "Changer l'ordre",
  roleNotAuthorized: "Vous n'êtes pas authorisé à venir dans cette page",
  roleNotAuthorizedBack: 'Retourner à la liste des audits',
  remark: 'Remarque',
  report: 'Aperçu',
  saving: 'Enregistrement en cours (ne quittez pas encore la page)',
  consEdt: 'Voir/Edt',
  close: 'Fermer',
  titleDialogOffline: 'Connection Internet',
  textDialogOffline: "Actuellement vous n'avez pas de connection internet. il y a probablement des actions que vous avez faites qui n'ont pas été sauvegardé."
};
