import { Grid, TextareaAutosize } from '@material-ui/core';
import React from 'react';
import {
  checklist_theme_item,
  checklist_theme_item_remark,
} from '../../../services/Checklist/ChecklistReadService/CheckistReadModel';
import { useStyles } from './ChecklistItemRemarkStyle';
import { useTranslation } from 'react-i18next';
import ItemRemarkListComponent from '../ItemRemarkListComponent';

export const testIds = {
  remarkIdInput: 'remark-id-input',
};

export interface ChecklistItemRemarkViewProps {
  onChangeComment: any;
  onDeativateRemark: any;
  onRemarkChanged: any;
  onDeactivateRemarks: any;
  comment: any;
  item: checklist_theme_item;
  remarks?: checklist_theme_item_remark[];
  isReadOnlyMode: boolean;
}

const ChecklistItemRemarkView: React.FC<ChecklistItemRemarkViewProps> = ({
  onChangeComment,
  onDeativateRemark,
  onRemarkChanged,
  onDeactivateRemarks,
  comment,
  remarks,
  isReadOnlyMode,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const readOnlyModeclick = () => {};

  return (
    <>
      <Grid container spacing={2}>
        <Grid xs={6} item>
        <ItemRemarkListComponent
          onDeativateRemark={onDeativateRemark}
          onRemarkChanged={onRemarkChanged}
          onDeactivateRemarks={onDeactivateRemarks}
          remarks={remarks}
          isReadOnlyMode={isReadOnlyMode}
        />
        </Grid>
        <Grid xs={remarks && remarks.length > 0 ? 6 : 12} item>
          <TextareaAutosize
            id="remark"
            name="remark"
            minRows={3}
            className={classes.textarea}
            value={comment || ''}
            placeholder={t('checklist:itemRemarkFreeText')}
            onChange={event =>
              isReadOnlyMode ? readOnlyModeclick : onChangeComment(event)
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ChecklistItemRemarkView;
