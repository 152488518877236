import { useApolloClient } from '@apollo/client';
import { useContext } from 'react';
import { NotificationType } from '../../components/Notification/NotificationComponent';
import MainAppContext from '../../AppContext';
import {  READ_NOT_APPLICATION_ITEMS_THEME_SUB_THEME_WITH_OVERVIEW_DETAILS } from './NotApplicableItemQuery';
import { useCallback } from 'react';


export const useGetNotApplicableItemsThemeSubThemeOverviewByAuditId = (audit_id) => {
   const client = useApolloClient();
  const { setNotification } = useContext(MainAppContext);

  const getNotApplicableItemsThemeSubThemeOverviewByAuditId = useCallback(() =>
    new Promise<any>(resolve => {
      client
        .query<any>({
          query: READ_NOT_APPLICATION_ITEMS_THEME_SUB_THEME_WITH_OVERVIEW_DETAILS,
          variables: { audit_id: audit_id },
          fetchPolicy: 'network-only',
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          setNotification({
            key: 'common:generalError',
            type: NotificationType.ERROR,
          });
        });
    }),
    [setNotification, client, audit_id],
  );

  return { getNotApplicableItemsThemeSubThemeOverviewByAuditId };
};