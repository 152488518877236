import React from 'react';
import AdminDocumentUploadForm from './AdminDocumentUploadForm/AdminDocumentUploadForm';

const AdminDocumentUpload = () => {

  return <React.Fragment>
    <AdminDocumentUploadForm/>
  </React.Fragment>
}

export default AdminDocumentUpload