import { gql } from '@apollo/client';

/**
 * Description - graphql query to get all remarks
 */
export const READ_ALL_REMARKS = gql`
  query qat_template_theme_item_remark {
    qat_template_theme_item_remark(
      order_by: { last_updated_date: desc }
      where: { deleted_at: { _is_null: true } }
    ) {
      id
      remark_text_nl
      remark_text_fr
      deleted_at
      template_theme_item_id
      created_at
      index
    }
  }
`;

export const GET_REMARK_INCLUDE_SOFT_DELETED_BY_ITEM = gql`
  query get_remark_include_soft_deleted_by_item($template_theme_item_id: Int!) {
    qat_template_theme_item_remark(
      where: { template_theme_item_id: { _eq: $template_theme_item_id } }
      order_by: { index: asc }
    ) {
      id
      remark_text_nl
      remark_text_fr
      template_theme_item_id
      index
      deleted_at
    }
  }
`;

/**
 * Description - graphql query to get all sorted items
 */
export const READ_ALL_REMARKS_SORTED = gql`
  query qat_template_theme_item_remark($order_by: qat_remark_order_by!) {
    qat_template_theme_item_remark(
      where: { deleted_at: { _is_null: true } }
      order_by: [$order_by]
    ) {
      id
      information_fr
      information_nl
      creation_date
      last_updated_date
      norm_code
      not_application_option
      page_pdf
      template_sub_theme_id
      template_theme_id
      title_fr
      title_nl
      index
    }
  }
`;

/**
 * Description - graphql query to get a specific remark
 */
export const GET_REMARK_BY_PK = gql`
  query qat_template_theme_item_remark_by_pk($id: Int!) {
    qat_template_theme_item_remark_by_pk(id: $id) {
      id
      remark_text_nl
      remark_text_fr
      template_theme_item_id
      created_at
      index
    }
  }
`;

/**
 * Description - graphql query to get all remarks from item
 */
export const READ_ALL_REMARKS_FROM_TEMPLATE_THEME_ITEM = gql`
  query qat_template_theme_items_from_item($template_theme_item_id: Int!) {
    qat_template_theme_item_remark(
      where: {
        template_theme_item_id: { _eq: $template_theme_item_id }
        deleted_at: { _is_null: true }
      }
      order_by: { index: asc }
    ) {
      id
      remark_text_nl
      remark_text_fr
      template_theme_item_id
      created_at
      index
    }
  }
`;
