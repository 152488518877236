/**
 * This enum is responsible to dermine all rules in the application
 * New rules can be added here
 */
export const Rule = {
  CAN_VIEW_ENTITY_CRUD: 'can_view_entity_crud',
  CAN_VIEW_AUDIT_CRUD: 'can_view_audit_crud',
  CAN_VIEW_AUDIT_READ: 'can_view_audit_read',
  CAN_VIEW_NORME: 'can_view_norme',
  CAN_VIEW_PROFILE: 'can_view_profile',
  CAN_VIEW_TEMPLATE_CRUD: 'can_view_template_crud',
  CAN_VIEW_THEME_CRUD: 'can_view_theme_crud',
  CAN_VIEW_ITEM_CRUD: 'can_view_item_crud',
  CAN_VIEW_ITEM_REMARK_CRUD: 'can_view_item_remark_crud',
  CAN_VIEW_CHECKLIST_REMARK_MENU: 'can_view_checklist_remark_menu',
  CAN_VIEW_UPLOAD_ADMIN_DOCUMENT: 'can_view_upload_document',
  CAN_VIEW_UPLOAD_CHECKLIST_DOCUMENT: 'can_view_upload_checklist_document',
  CAN_VIEW_UPLOAD_INTERVIEW_DOCUMENT: 'can_view_upload_interview_document'
};

/**
 * This enum is responsible to dermine all roles in the application
 * New roles can be added here
 */
export const Role = {
  AUDITOR: 'auditor',
  ADMIN: 'admin',
  SUPER_ADMIN: 'superadmin',
  READER: 'reader',
};

/**
 * Interface to determine a permission
 */
export interface Permission {
  role: string;
  rules: string[];
}

/**
 * This function is responsible to configure and return specific rules for each role in terms of permissions
 */
export const getPermissions = (): Permission[] => [
  {
    role: Role.AUDITOR,
    rules: [
      Rule.CAN_VIEW_AUDIT_READ,
      Rule.CAN_VIEW_AUDIT_CRUD,
      Rule.CAN_VIEW_NORME,
      Rule.CAN_VIEW_PROFILE,
      Rule.CAN_VIEW_CHECKLIST_REMARK_MENU,
      Rule.CAN_VIEW_UPLOAD_CHECKLIST_DOCUMENT,
      Rule.CAN_VIEW_UPLOAD_INTERVIEW_DOCUMENT
    ],
  },
  {
    role: Role.ADMIN,
    rules: [
      Rule.CAN_VIEW_AUDIT_READ,
      Rule.CAN_VIEW_ENTITY_CRUD,
      Rule.CAN_VIEW_AUDIT_CRUD,
      Rule.CAN_VIEW_NORME,
      Rule.CAN_VIEW_PROFILE,
      Rule.CAN_VIEW_TEMPLATE_CRUD,
      Rule.CAN_VIEW_THEME_CRUD,
      Rule.CAN_VIEW_ITEM_CRUD,
      Rule.CAN_VIEW_ITEM_CRUD,
      Rule.CAN_VIEW_ITEM_REMARK_CRUD,
      Rule.CAN_VIEW_CHECKLIST_REMARK_MENU,
      Rule.CAN_VIEW_UPLOAD_ADMIN_DOCUMENT,
      Rule.CAN_VIEW_UPLOAD_CHECKLIST_DOCUMENT,
      Rule.CAN_VIEW_UPLOAD_INTERVIEW_DOCUMENT
    ],
  },
  {
    role: Role.SUPER_ADMIN,
    rules: [
      Rule.CAN_VIEW_AUDIT_READ,
      Rule.CAN_VIEW_ENTITY_CRUD,
      Rule.CAN_VIEW_AUDIT_CRUD,
      Rule.CAN_VIEW_NORME,
      Rule.CAN_VIEW_PROFILE,
      Rule.CAN_VIEW_TEMPLATE_CRUD,
      Rule.CAN_VIEW_THEME_CRUD,
      Rule.CAN_VIEW_ITEM_CRUD,
      Rule.CAN_VIEW_ITEM_CRUD,
      Rule.CAN_VIEW_ITEM_REMARK_CRUD,
      Rule.CAN_VIEW_CHECKLIST_REMARK_MENU,
      Rule.CAN_VIEW_UPLOAD_ADMIN_DOCUMENT,
      Rule.CAN_VIEW_UPLOAD_CHECKLIST_DOCUMENT,
      Rule.CAN_VIEW_UPLOAD_INTERVIEW_DOCUMENT
    ],
  },
  {
    role: Role.READER,
    rules: [
      Rule.CAN_VIEW_AUDIT_READ,
      Rule.CAN_VIEW_NORME,
      Rule.CAN_VIEW_PROFILE,
    ],
  },
];
