import React, {useContext, useMemo} from 'react';
import { ApolloProvider, ApolloLink, RequestHandler, makeVar, useReactiveVar } from '@apollo/client';
import { ApolloClient, InMemoryCache, HttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import Keycloak  from 'keycloak-js';
import NetworkListenerContext from '../components/NetworkListener/NetworkListenerContext';

export interface ClientProviderProps {
  children: any;
}
 
export const keycloakInstanceVar = makeVar<Keycloak.KeycloakInstance | null>(null)



const ClientProvider: React.FC<ClientProviderProps> = ({ children }) => {
  const keycloak=useReactiveVar(keycloakInstanceVar);

  const {onChangeIsOffline} = useContext(NetworkListenerContext)

  const authLink = useMemo(() => {
    return setContext(async (_: any, { headers }: any) => {
    
      if (keycloak && keycloak.tokenParsed && keycloak.idToken){
      
        const { resource_access } = keycloak.tokenParsed;
      
        const hasuraRole = 
          resource_access && 
          process.env.REACT_APP_KEYCLOAK_CLIENT_ID &&
          resource_access[process.env.REACT_APP_KEYCLOAK_CLIENT_ID]
            ? resource_access[process.env.REACT_APP_KEYCLOAK_CLIENT_ID]['roles'][0]
            : null;
    
        await keycloak.updateToken(10)               
        const jwtToken = keycloak.token         
        headers = {
          ...headers,
          Authorization: jwtToken ? `Bearer ${jwtToken}` : ''
        }
        if(hasuraRole){
          headers['X-Hasura-Role'] = hasuraRole
          // if they are not role, hasura will take default role (reader) from token
        }
      }else{
        console.error("Token not found !!!")
      }
    
      return {
        headers,
      };
    });
  },[keycloak])

  const errorLink: ApolloLink | RequestHandler = useMemo(() => {
    return onError(
      ({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
          graphQLErrors.map(({ message, locations, path }) =>
            console.warn(
              `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
            ),
          );
    
        if (networkError) {
          console.warn(`[Network error]: ${networkError}`);
          onChangeIsOffline(true)
        }
      },
    );
  },[onChangeIsOffline])

  const httpLink = useMemo(() => {
    return new HttpLink({
      uri: process.env.REACT_APP_HASURA_GRAPHQL,
    });
  },[])

  const link = useMemo(() => {
    return authLink.concat(httpLink);
  },[httpLink, authLink])

  const client = useMemo(() =>  {
    return new ApolloClient({
      cache: new InMemoryCache({
        typePolicies: {
          Query: {
            fields: {
              read(){
                return keycloakInstanceVar()
              },
              qat_audit: {
                merge(existing, incoming, opts) {
                  return incoming;
                },
              },
              qat_building: {
                merge(existing, incoming, opts) {
                  return incoming;
                },
              },
              qat_location_entity_by_pk: {
                merge(existing, incoming, opts) {
                  return incoming;
                },
              },
              qat_theme_template_checklist: {
                merge(existing, incoming, opts) {
                  return incoming;
                },
              },
              qat_checklist_by_pk: {
                merge(existing, incoming, opts) {
                  return incoming;
                },
              },
              qat_template_theme_item: {
                merge(existing, incoming, opts) {
                  return incoming;
                },
              },
              qat_template_theme_item_remark: {
                merge(existing, incoming, opts) {
                  return incoming;
                },
              },
              qat_sub_theme_template_checklist: {
                merge(existing, incoming, opts) {
                  return incoming;
                },
              }, qat_location_entity: {
                merge(existing, incoming, opts) {
                  return incoming;
                },
              },
              qat_address: {
                merge(existing, incoming, opts) {
                  return incoming;
                },
              },
              qat_checklist_theme_item: {
                merge(existing, incoming, opts) {
                  return incoming;
                },
              }
            },
          },
          qat_audit: {
            fields: {
              entity: {
                merge(existing, incoming, opts) {
                  return incoming;
                },
              },
            }
          },
          qat_building: {
            fields: {
              address: {
                merge(existing, incoming, opts) {
                  return incoming;
                },
              },
            }
          },
          qat_checklist_theme: {
            fields: {
              checklist_theme_sections: {
                merge(existing, incoming, opts) {
                  return incoming;
                },
              },
            },
          },
          qat_checklist: {
            fields: {
              checklist_themes: {
                merge(existing, incoming, opts) {
                  return incoming;
                },
              },
              template_checklist: {
                merge(existing, incoming, opts) {
                  return incoming;
                },
              },
            },
          },
        },
      }),
      link: ApolloLink.from([errorLink, link]),
    });
  },[errorLink, link])
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ClientProvider;
