import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  search: {
    width: '80%',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: 10,
  },
  create: {
    margin: 10,
  },
  tableHeader: {
    display: 'flex',
  },
}));

export { useStyles };
