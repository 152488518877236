export const profile = {
  profile: 'Profiel',
  changePassword: 'Wachtwoord wijzigen',
  email: 'Email',
  currentPassword: 'Huidig wachtwoord',
  newPassword: 'Nieuw wachtwoord',
  confirmationPassword: 'Bevestig nieuw wachtwoord',
  emailError: 'Email kon niet opgehaald worden',
  currentPasswordRequired: 'Huidig wachtwoord is een verplicht veld',
  newPasswordError:
    'Nieuw wachtwoord is een verplicht veld en moet minimaal 8 tot maximum 16 karakters bevatten, met minimaal één hoofdletter, één numerieke waarde, één symbool en moet verschillend zijn van het huidige wachtwoord',
  confirmationPasswordError:
    'Bevestiging nieuw wachtwoord is een verplicht veld en moet overeen komen met het nieuwe wachtwoord',
  passwordSuccess: 'Uw wachtwoord is succesvol gewijzigd',
  NotAuthorizedException:
    'U kan uw wachtwoord niet wijzigen omdat u huidig wachtwoord niet  correct is',
  LimitExceededException:
    'Poginglimiet overschreden, probeer het na enige tijd opnieuw',
  InvalidParameterException:
    'Validatiefout: nieuw wachtwoord voldoet niet aan reguliere expressie: ^ [\\ S] +. * [\\ S] + $',
};
