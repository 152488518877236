import React, { useMemo } from 'react';
import { useChecklistReportPresenter } from '../ChecklistReportPresenter/ChecklistReportPresenter';
import ChecklistReportView from '../ChecklistReportView/ChecklistReportView';
import { useBrowserLocationState } from '../../Shared/BrowserLocationState/BrowserLocationState';

export interface ChecklistReportComponentProps { }

const ChecklistReportComponent: React.FC<ChecklistReportComponentProps> = () => {
  useChecklistReportPresenter();
 
  const currentLocationState = useBrowserLocationState<{isReadonly?: boolean}>({keysRequired: []})

  const isReadOnlyMode = useMemo(
    () =>
      currentLocationState && currentLocationState.isReadonly
        ? currentLocationState.isReadonly
        : false,
    [currentLocationState],
  );
  return <ChecklistReportView isReadOnlyMode={isReadOnlyMode} />;
};

export default ChecklistReportComponent;
