import React from 'react';
import {
  checklist_theme_item,
  checklist_theme_item_remark,
} from '../../../services/Checklist/ChecklistReadService/CheckistReadModel';
import { useChecklistItemRemarkPresenter } from '../ChecklistItemRemarklPresenter/ChecklistItemRemarkPresenter';
import ChecklistItemRemarkView from '../ChecklistItemRemarkView/ChecklistItemRemarkView';

export interface ChecklistItemRemarkComponentProps {
  item: checklist_theme_item;
  checklistId: number;
  remarks?: checklist_theme_item_remark[];
  isReadOnlyMode: boolean;
}

const ChecklistItemRemarkComponent: React.FC<ChecklistItemRemarkComponentProps> = ({
  item,
  checklistId,
  remarks,
  isReadOnlyMode,
}) => {
  const {
    comment,
    onChangeComment,
    onDeativateRemark,
    onDeactivateRemarks,
    onRemarkChanged,
  } = useChecklistItemRemarkPresenter({ item, checklistId, remarks });

  return (
    <ChecklistItemRemarkView
      onChangeComment={onChangeComment}
      onDeativateRemark={onDeativateRemark}
      onDeactivateRemarks={onDeactivateRemarks}
      comment={comment}
      item={item}
      remarks={remarks}
      onRemarkChanged={onRemarkChanged}
      isReadOnlyMode={isReadOnlyMode}
    />
  );
};

export default ChecklistItemRemarkComponent;
