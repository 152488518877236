import React, { useCallback, useContext, useMemo } from 'react';
import {
  IconButton,
  Table,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddRounded from '@material-ui/icons/AddRounded';
import { CreateRounded, DeleteRounded } from '@material-ui/icons';
import { useStyles } from '../Shared/Styles/CommonFormSyles';
import AppContext from '../../AppContext';
import { useHistory } from 'react-router-dom';
import routes from '../../routes';
import {
  openCreateItemVar,
  itemLastIndexVar,
  openUpdateItemVar,
} from '../Item/ItemCache';
import { sub_theme_template_checklist_data_from_list } from '../../services/SubTheme/SubThemeReadService';
import TableTrueValueComponent from '../Shared/Table/TableTrueValueComponent';
import TableFalseValueComponent from '../Shared/Table/TableFalseValueComponent';
import { translate } from '../../services/Translation/TranslationService';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useBrowserLocationState } from '../Shared/BrowserLocationState/BrowserLocationState';
export const tableTestIds = {
  buttonOnUpdate: 'button-table-update-subtheme',
  buttonOnDelete: 'button-table-delete-subtheme',
  buttonOnRemarksVisibility: 'button-table-visibility-subtheme',
  paginationComponent: 'pagination-component',
  tableEntityId: 'subtheme-table-id',
};
export interface SubThemeTableProps {
  isLoading?: boolean;
  subThemes: sub_theme_template_checklist_data_from_list[];
  handleDeleteClicked: (
    row: sub_theme_template_checklist_data_from_list,
  ) => void;
  openUpdateDialog: (row: sub_theme_template_checklist_data_from_list) => void;
  onDragEnd: (result) => void;
}

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,
  ...(isDragging && {
    background: 'rgb(235,235,235)',
  }),
});

const SubThemeTableComponent: React.FC<SubThemeTableProps> = ({
  subThemes,
  handleDeleteClicked,
  openUpdateDialog,
  onDragEnd,
}: SubThemeTableProps) => {
  const { t } = useTranslation();
  const { language } = useContext(AppContext);
 
  const classes = useStyles();

  const currentLocationState = useBrowserLocationState<{template:any, theme: any}>({keysRequired: ["template", "theme"]})

  const template = useMemo(
    () => (currentLocationState?.template ? currentLocationState?.template : undefined),
    [currentLocationState],
  );
  const theme = useMemo(
    () => (currentLocationState?.theme ? currentLocationState?.theme : undefined),
    [currentLocationState],
  );

  const handleOpenUpdateDialog = useCallback(
    (rowData: sub_theme_template_checklist_data_from_list) =>
      openUpdateDialog(Array.isArray(rowData) ? rowData[0] : rowData),
    [openUpdateDialog],
  );

  const onDeleteClicked = useCallback(
    (rowData: sub_theme_template_checklist_data_from_list) =>
      handleDeleteClicked(Array.isArray(rowData) ? rowData[0] : rowData),
    [handleDeleteClicked],
  );

  const history = useHistory();
  const handleCreateItem = (
    subTheme: sub_theme_template_checklist_data_from_list,
  ) => {
    openCreateItemVar(true);
    itemLastIndexVar(1);
    history.push({
      pathname: routes.createItem.path,
      state: {
        subTheme,
        template,
        theme
      },
    });
  };

  const handleOpenListOfItemFromSubtheme = (
    subTheme: sub_theme_template_checklist_data_from_list,
  ) => {
    openCreateItemVar(false);
    openUpdateItemVar(false);
    history.push({
      pathname: routes.allItems.path,
      state: {
        subTheme,
        template,
        theme
      },
    });
  };

  const checkNotDeletedItem = (
    currentSubtheme: sub_theme_template_checklist_data_from_list | undefined,
  ) => {
    let haveNotDeletedItem: boolean = false;
    if (
      currentSubtheme &&
      currentSubtheme.template_theme_items &&
      currentSubtheme.template_theme_items.length > 0
    ) {
      haveNotDeletedItem = currentSubtheme.template_theme_items.some(
        item => item?.deleted_at == null,
      );
    }
    return haveNotDeletedItem;
  };

  return (
    <>
    <Paper className={classes.paper}>
    <TableContainer className={classes.tableContainer}>
    <Table stickyHeader className={classes.table} aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableRow} align="center">{t('common:order')}</TableCell>
            <TableCell className={classes.tableRow}>{t('subtheme:name')}</TableCell>
            <TableCell className={classes.tableRow} align="center">{t('subtheme:notApplicable')} </TableCell>
            <TableCell className={classes.tableRow} align="center">{t('item:item')}</TableCell>
            <TableCell className={classes.tableRow} align="center">{t('common:edit')}</TableCell>
            <TableCell className={classes.tableRow} align="center">{t('common:delete')}</TableCell>
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={'theme-table-body'} direction="vertical">
            {provided => {
              return (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {subThemes.map((subTheme, index) => (
                    <Draggable
                      key={subTheme.id}
                      draggableId={subTheme.id.toString()}
                      index={index}
                    >
                      {(provided_var, snapshot) => (
                        <TableRow
                          ref={provided_var.innerRef}
                          {...provided_var.draggableProps}
                          {...provided_var.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided_var.draggableProps.style,
                          )}
                        >
                          <TableCell align="center" className={classes.index}>
                            {index + 1}
                          </TableCell>
                          <TableCell>{translate(subTheme, language)}</TableCell>
                          <TableCell
                            className={classes.tableSmallCellSize}
                            align="center"
                          >
                            {subTheme.not_applicable_option ? (
                              <TableTrueValueComponent />
                            ) : (
                              <TableFalseValueComponent />
                            )}
                          </TableCell>
                          <TableCell align="center">
                            {checkNotDeletedItem(subTheme) ? (
                              //exist items
                              <IconButton
                                data-testid={
                                  tableTestIds.buttonOnRemarksVisibility
                                }
                                onClick={() =>
                                  handleOpenListOfItemFromSubtheme(subTheme)
                                }
                              >
                                <VisibilityIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                data-testid={
                                  tableTestIds.buttonOnRemarksVisibility
                                }
                                onClick={() => handleCreateItem(subTheme)}
                              >
                                <AddRounded />
                              </IconButton>
                            )}
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              data-testid={tableTestIds.buttonOnUpdate}
                              onClick={() => handleOpenUpdateDialog(subTheme)}
                            >
                              <CreateRounded />
                            </IconButton>
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              data-testid={tableTestIds.buttonOnDelete}
                              onClick={() => onDeleteClicked(subTheme)}
                            >
                              <DeleteRounded />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              );
            }}
          </Droppable>
        </DragDropContext>
    </Table>
    </TableContainer>
    </Paper>
    </>
  );
};
export default SubThemeTableComponent;
