import { useQuery, useApolloClient } from '@apollo/client';
import { useCallback, useContext } from 'react';
import AppContext from '../../../AppContext';
import {
  GET_ENTITY_BY_PK,
  READ_ALL_ENTITIES,
  READ_ALL_ENTITIES_SORTED,
} from './EntityReadQuery';
import { entities_data, entity_data_by_pk } from './EntityReadModel';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { EntityFormData } from '../../../components/Entity/EntityForm/EntityFormPresenter';
import { OrderBy } from '../../../components/Entity/EntityRead/EntityReadComponent/EntityReadComponent';
import { entity_data } from '../EntityDataModel';

/**
 * Description - service to get all entities
 */
export const useGetAllEntities = () => {
  const { setNotification } = useContext(AppContext);

  const { data, loading, error } = useQuery<entities_data>(READ_ALL_ENTITIES, {
    onError: errorResponse => {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });
  return { data: data?.qat_entity ? data?.qat_entity : [], loading, error };
};

export const useGetAllSortedEntities = () => {
  const client = useApolloClient();
  const { setNotification } = useContext(AppContext);

  const orderEntitiesBy = useCallback(
    (orderBy: OrderBy) =>
      new Promise<entity_data[]>(resolve => {
        client
          .query<entities_data>({
            query: READ_ALL_ENTITIES_SORTED,
            variables: { order_by: { [orderBy?.column]: orderBy?.direction } },
          })
          .then(response => {
            resolve(response?.data?.qat_entity);
          })
          .catch(errorResponse => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      }),
    [setNotification, client],
  );

  return { orderEntitiesBy };
};

export const useGetEntityByIdDefault = id => {
  const { setNotification } = useContext(AppContext); 
  let { data, loading, error } = useQuery<entity_data_by_pk>(GET_ENTITY_BY_PK, {
    variables: { id },
    fetchPolicy: 'network-only',
    onError: errorResponse => {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });
  return { data: data?.qat_entity_by_pk , loading, error };
};

export const useGetEntityById = () => {
  const client = useApolloClient();
  const { setNotification } = useContext(AppContext);

  const getEntityById = useCallback(
    id =>
      new Promise<EntityFormData>(resolve => {
        client
          .query<entity_data_by_pk>({
            query: GET_ENTITY_BY_PK,
            variables: { id },
          })
          .then(response => {
            const {
              __typename,
              category_location_form_config,
              ...restEntity
            } = response.data.qat_entity_by_pk;
            const entityToUpdate: EntityFormData = {
              ...restEntity,
            };
            resolve(entityToUpdate);
          })
          .catch(errorResponse => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      }),
    [setNotification, client],
  );

  return { getEntityById };
};
