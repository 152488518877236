import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    title: {
        color: theme.palette.primary.main,
        fontWeight: 'bold',
        padding: theme.spacing(0.5)
    },
    titleGrid: {
        marginBottom: "0.5px"
    },
    address: {
        fontWeight: 'bold',
        padding: theme.spacing(0.5)
    },
    addressNumber: {
        margin: 10,
    },
    addressGrid: {
        backgroundColor: "#bdbdbd"
    },

    sectionSpecification: {
        marginLeft: theme.spacing(1),
    },
    sectionNameGrid: {
        textAlign: 'center',
    },
    sectionPhotoGrid: {
        borderRight: '1px solid #bdbdbd'
    },

    sectionName: {
        fontWeight: 'bold',
        alignItems: 'center',
        display: 'flex',

    },

    specification: {
        paddingLeft: theme.spacing(1)
    }

}));

export { useStyles };
