import { useCallback, useContext, useState } from 'react';
import AppContext from '../../../../AppContext';
import { template_checklist_data_from_list } from '../../../../services/TemplateChecklist/TemplateChecklistReadService';
import { translate } from '../../../../services/Translation/TranslationService';

export interface TemplateChecklistReadPresenterProps {
  templates: template_checklist_data_from_list[];
  onDelete: (id: number) => {};
}

export interface TemplateChecklistReadPresenterResult {
  templates: template_checklist_data_from_list[];
  onConfirmedToDelete: () => void;
  onCloseDeleteConfirmDialog: () => void;
  handleDeleteButtonClick: (id: number) => void;
  openDeleteConfirmDialog: boolean;
  filterTemplateChecklist: (filterInput: string) => void;
}

/**
 * Description - Presenter of TemplateChecklistReadComponent
 *
 * - Manage confirm delete state
 * - Template checklist list filtering
 */

export const useTemplateChecklistReadPresenter = ({
  templates,
  onDelete,
}: TemplateChecklistReadPresenterProps): TemplateChecklistReadPresenterResult => {
  const [
    openDeleteConfirmDialog,
    setOpenDeleteConfirmDialog,
  ] = useState<boolean>(false);
  const [
    templateChecklistIdToDelete,
    setTemplateChecklistIdToDelete,
  ] = useState<number>(-1);
  const [
    listTemplateChecklistFiltered,
    setListTemplateChecklistFiltered,
  ] = useState<template_checklist_data_from_list[]>([]);
  const { language } = useContext(AppContext);

  const filterTemplateChecklist = useCallback(
    (filter: string) => {
      const newFilter = filter.toLowerCase();
      if (newFilter && newFilter !== '') {
        setListTemplateChecklistFiltered(() => {
          const newListTemplateChecklistFiltered: template_checklist_data_from_list[] = templates.filter(
            template =>
              translate(template, language).toLowerCase().includes(newFilter) ||
              translate(template.category_location, language)
                .toLowerCase()
                .includes(newFilter) ||
              (template.sub_category_location &&
                translate(template.sub_category_location, language)
                  .toLowerCase()
                  .includes(newFilter)),
          );
          return newListTemplateChecklistFiltered;
        });
      } else {
        setListTemplateChecklistFiltered(templates);
      }
    },
    [templates, language],
  );

  const handleDeleteButtonClick = useCallback((id: number) => {
    setOpenDeleteConfirmDialog(true);
    setTemplateChecklistIdToDelete(id);
  }, []);

  const onConfirmedToDelete = useCallback(() => {
    if (templateChecklistIdToDelete !== -1) {
      onDelete(templateChecklistIdToDelete);
      setOpenDeleteConfirmDialog(false);
      setTemplateChecklistIdToDelete(-1);
    } else {
      throw new Error("the template checklist id to delete doesn't exist");
    }
  }, [templateChecklistIdToDelete, onDelete]);

  const onCloseDeleteConfirmDialog = useCallback(() => {
    setOpenDeleteConfirmDialog(false);
  }, []);

  return {
    templates: listTemplateChecklistFiltered,
    onConfirmedToDelete,
    handleDeleteButtonClick,
    openDeleteConfirmDialog,
    onCloseDeleteConfirmDialog,
    filterTemplateChecklist,
  };
};
