import { useReactiveVar } from "@apollo/client"
import { useCallback, useEffect, useState } from "react";
import { useAxiosInstance } from "../../../axios/AxiosService";
import { keycloakInstanceVar } from "../../../graphql/ClientProvider";


const useGetContent = () => {
    const { instance } = useAxiosInstance({})
    const keycloak = useReactiveVar(keycloakInstanceVar);
    const getContent = useCallback(async (url: string) => {
        return new Promise<string>(async (resolve, reject) => {
            if (process.env.REACT_APP_API_FILE_URI) {

                if (!keycloak) {
                    console.error("keycloak is not initalized")
                    reject()
                } else {
                    await keycloak.updateToken(10)
                    const jwtToken = keycloak.idToken
                    return instance.get(`${process.env.REACT_APP_API_FILE_URI}/download/${url}`, {
                        headers: {
                            Accept: 'application/pdf',
                            "Authorization": jwtToken ? `Bearer ${jwtToken}` : "",
                        },
                        responseType: "arraybuffer"
                    }).then(response => {
                        resolve(URL.createObjectURL(new Blob([response.data], { type: "application/pdf" })))
                    }
                    )
                        .catch(error => {
                            console.error(error)
                            reject()
                        })
                }
            } else {
                console.error("variable environment for api file uri doesn't exist")
                reject()
            }
        })

    }, [keycloak, instance])

    return { getContent }
}

export interface get_checklist_document_response {
    qat_checklist_document: get_checklist_document_data
}
export interface get_checklist_document_data {
    id: number;
    url: string;
    building_id: number;
}
export type checklist_document_result = string

/**
 * Description, to get document from API
 * @returns {data: get_checklist_document_response, loading: boolean}
 */
export const useGetChecklistDocumentWithContent = url => {

    const [dataWithDocumentContent, setDataWithDocumentContent] = useState<checklist_document_result>("")
    const { getContent } = useGetContent()
    const [loadingContent, setLoadingContent] = useState<boolean>(true)

    useEffect(() => {
        if (url) {
            getContent(url).then(content => {
                setDataWithDocumentContent((currentData: checklist_document_result) => {
                    if (content) {
                        currentData = content
                        setLoadingContent(false)
                    } else {
                        setLoadingContent(false)
                    }
                    return currentData
                })
            }).catch(error => {
                setLoadingContent(false)
                console.error(error)
            })
        } else {
            setDataWithDocumentContent("")
        }
    }, [url, getContent])
    return { data: dataWithDocumentContent, loading: loadingContent }
}

export interface useGetContentServiceProps {
    url: string | null
}

export const useGetContentService = ({ url }: useGetContentServiceProps) => {

    const [loading, setLoading] = useState<boolean>(() => url !== null ? true : false)
    const [content, setContent] = useState<string | null>(null)
    const { getContent } = useGetContent()

    useEffect(() => {
        if (url) {
            setLoading(true)
            getContent(url).then((response) => {
                setContent(response)
                setLoading(false)
            })
        } else {
            setContent(null)
            setLoading(false)
        }
    }, [url, getContent])

    return { content, loading }
}

