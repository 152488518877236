import logo from '../logo.png';
import React, { Fragment, useContext, useEffect, useMemo } from 'react';
import { useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useStyles } from '../DrawerStyle';
import { isMobile } from 'react-device-detect';
import AppContext from '../../../../AppContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import { isAllowed } from '../../../Authorization/AuthorizationComponent/Auth';
import MenuListItem from '../MenuListItem/MenuListItem';
import { MenuItem } from '../../NavigationComponent/NavigationComponent';
import { selectedThemeNavigationVar } from '../../../Checklist/ChecklistCache';
import {useLocation} from 'react-router-dom'
import routes, {RoutePath} from "../../../../routes"

export const localStorageMenuItemActiveKey = "menuItemId";

export const defaultRoute: RoutePath = routes.currentAudits

export const onActiveOrOpenFromCurrentId = (menuItem: MenuItem, menuItemId): MenuItem => {
  if(menuItem.id === menuItemId){
    return {...menuItem, active: true}
  }
  if(menuItem.childs?.find(({id}) => id === menuItemId)){
    return {...menuItem, open: true}
  }
  return menuItem
}

export interface DrawerComponentProps {
  open: boolean;
  onClosed: () => void;
  menuItems: MenuItem[];
}

export const testIds = {
  buttonCloseDrawer: 'button-close-drawer',
  menuHeader: 'menu-header-drawer'
};

export const onActiveMenuItem = (menuItem: MenuItem) => {
  menuItem.active = true;
  localStorage.setItem(localStorageMenuItemActiveKey, menuItem.id)
}

/**
 * This component is responsible for the side bar navigation
 * @param onClosed function property used to close the side bar
 * @param open variable property used to indicate if the side bar is open
 * @param menuItems array property with the corresponding menu items
 */
const DrawerComponent: React.FC<DrawerComponentProps> = ({
  onClosed,
  open,
  menuItems,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const { loadingUser, role } = useContext(AppContext);

  const {pathname} = useLocation()
  const currentMenuItemActive: string = useMemo(() => {
    const menuItemActiveIdFromBrowserCache = localStorage.getItem(localStorageMenuItemActiveKey)
    let menuActive: string = "";
    if(pathname === defaultRoute.path || !menuItemActiveIdFromBrowserCache){
      if(menuItemActiveIdFromBrowserCache){
        localStorage.removeItem(localStorageMenuItemActiveKey)
      }
      menuActive = defaultRoute.id
    } else if(menuItemActiveIdFromBrowserCache){
      menuActive =  menuItemActiveIdFromBrowserCache
    }
    return menuActive
  },[pathname])

  const [menuItemsActive, setMenuItemsActive] = React.useState<MenuItem[]>(
    () => {
      const newMenuItems: MenuItem[] = Array.from(menuItems).map(menuItem => {
        const menuItemUpdated = onActiveOrOpenFromCurrentId(menuItem, currentMenuItemActive)
        if (menuItemUpdated.childs && menuItemUpdated.open) {
          menuItemUpdated.childs = menuItemUpdated.childs.map(childItem => {
            const childItemUpdated = onActiveOrOpenFromCurrentId(childItem, currentMenuItemActive)
            return childItemUpdated;
          });
        }
        return menuItemUpdated;
      });
      return newMenuItems;
    },
  );

  useEffect(() => {
    setMenuItemsActive(prevMenuItemsActive => {
      if (role) {
        const newMenuItemsActive: Array<MenuItem> = [];
        prevMenuItemsActive.forEach(menuItemActive => {
          const newMenuItemActive: MenuItem = {
            ...menuItemActive,
            isAllowed: isAllowed(menuItemActive.rules, role),
          };
          if (newMenuItemActive.childs) {
            const newMenuItemsChildActive: Array<MenuItem> = [];
            newMenuItemActive.childs.forEach(menuItemChildActive => {
              const newMenuItemChildActive: MenuItem = {
                ...menuItemChildActive,
                isAllowed: isAllowed(menuItemChildActive.rules, role),
              };
              newMenuItemsChildActive.push(newMenuItemChildActive);
            });
            newMenuItemActive.childs = newMenuItemsChildActive;
          }
          newMenuItemsActive.push(newMenuItemActive);
        });
        return newMenuItemsActive;
      } else {
        return prevMenuItemsActive;
      }
    });
  }, [menuItems, role]);

  const onSelect = id => {
    selectedThemeNavigationVar(undefined)
    setMenuItemsActive(state => {
      if (!state.length) {
        return state;
      }
      return Array.from(state).map(menuItem => {
        menuItem.active = false;
        if (menuItem.id === id) {
          if (menuItem.childs) {
            menuItem.open = !menuItem.open;
          } else {
            onActiveMenuItem(menuItem)
          }
        } else if (menuItem.childs) {
          menuItem.open = false;
          menuItem.childs = menuItem.childs.map(childItem => {
            childItem.active = false;
            if (childItem.id === id) {
              onActiveMenuItem(childItem)
              menuItem.open = true;
            }
            return childItem;
          });
          return menuItem;
        }
        return menuItem;
      });
    });
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="left"
      open={open}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.drawerHeader}>
        {loadingUser ? (
          <CircularProgress color="secondary" />
        ) : isMobile ? (
          <IconButton
            onClick={onClosed}
            data-testid={testIds.buttonCloseDrawer}
          >
            {theme.direction === 'rtl' ? (
              <ChevronLeftIcon className={classes.chevronIcon} />
            ) : (
              <ChevronRightIcon className={classes.chevronIcon} />
            )}
          </IconButton>
        ) : (
          <img data-testid={testIds.menuHeader} src={logo} alt="Logo" className={classes.logo} />
        )}
      </div>
      {!loadingUser && (
        <Fragment>
          <List component="nav">
            {menuItemsActive
              ? Array.from(menuItemsActive).map(menuItem => {
                  return (
                    menuItem.isAllowed && (
                      <MenuListItem
                        key={menuItem.id}
                        menuItem={menuItem}
                        onSelect={onSelect}
                      />
                    )
                  );
                })
              : null}
          </List>
        </Fragment>
      )}
    </Drawer>
  );
};

export default DrawerComponent;
