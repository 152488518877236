import React, { useCallback, useMemo } from "react";
import { useReactiveVar } from "@apollo/client";
import {
    openCreateLocationAddressVar,
    locationAddressVar
} from "../LocationAdressCache";
import { LocationAddressFormData } from "../LocationAddressFormModel";
import { AddressCreateFormData, useCreateAddress } from "../../../../services/Address/WriteService/AddressWriteService";
import LocationAddressFormComponent from "./LocationAddressFormComponent";
import { useBrowserLocationState } from "../../../Shared/BrowserLocationState/BrowserLocationState";


const LocationAddressCreateDialogComponent = () => {
    const isOpenCreateLocationAddress: boolean = useReactiveVar(openCreateLocationAddressVar);
    const currentLocationAddress: LocationAddressFormData = useReactiveVar(locationAddressVar);

    const currentLocationState = useBrowserLocationState<{entity: any, locationInfo: any}>({keysRequired: ["entity", "locationInfo"]})

    const locationInfo = useMemo(() => {
        return currentLocationState?.locationInfo
            ? currentLocationState?.locationInfo
            : -1;
    }, [currentLocationState])


    const locationId = useMemo(() => locationInfo ? locationInfo.id : -1, [locationInfo]);
    const locationName = useMemo(() => locationInfo ? locationInfo.name : "", [locationInfo]);

    const { onCreate } = useCreateAddress({ location_entity_id: locationInfo.id });

    const defaultLocationAddress: LocationAddressFormData = {
        street: "",
        house_number: "",
        box_number: "",
        city: "",
        location_entity_id: -1
    };

    const locationAddressFormValue = useCallback(
        () => {
            let locationAddressForm: LocationAddressFormData;

            locationAddressForm = {
                street: currentLocationAddress.street,
                house_number: currentLocationAddress.house_number,
                box_number: currentLocationAddress.box_number,
                city: locationName,
                location_entity_id: locationId

            };

            if (!locationId || locationId === -1) {
                console.error('location id is required');
                return null;
            }


            return locationAddressForm;
        }, [currentLocationAddress, locationName, locationId]);

    const handleCreateAnotherLocationAddress = useCallback(
        () => {

            if (locationAddressFormValue()) {
                let locationAddressForm: AddressCreateFormData | null = locationAddressFormValue();
                if (locationAddressForm) {
                    onCreate(locationAddressForm);
                    locationAddressVar(defaultLocationAddress);
                }
            }
        }, [locationAddressFormValue, defaultLocationAddress, onCreate]);


    const handleCreateLocationAddress = useCallback(
        () => {
            let locationAddressForm: AddressCreateFormData | null = locationAddressFormValue();

            if (locationAddressForm) {

                openCreateLocationAddressVar(false);
                onCreate(locationAddressForm);

                locationAddressVar(defaultLocationAddress);
            }

        }, [locationAddressFormValue, onCreate, defaultLocationAddress]);


    const handleClose = useCallback(() => {
        openCreateLocationAddressVar(false);
        locationAddressVar(defaultLocationAddress);
    }, [defaultLocationAddress]);

    return (
        <div>
            <LocationAddressFormComponent
                isOpenForm={isOpenCreateLocationAddress}
                handleClose={handleClose}
                handleFormSubmit={handleCreateLocationAddress}
                handleCreateLocationAddress={handleCreateAnotherLocationAddress}

            />
        </div>
    )
}

export default LocationAddressCreateDialogComponent;