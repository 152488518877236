import { LinearProgress } from '@material-ui/core';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from '.';
import routes from '../../../../routes';
import { template_checklist_data_from_list } from '../../../../services/TemplateChecklist/TemplateChecklistReadService';
import AddButtonComponent from '../../../Shared/Button/AddButtonComponent';
import PageContainer from '../../../Shared/Container/PageContainer';
import ConfirmDialog from '../../../Shared/Dialog/ConfirmDialog/ConfirmDialogComponent/ConfirmDialogComponent';
import SearchField from '../../../Shared/Field/SearchField/SearchField';
import TemplateChecklistTableComponent from '../TemplateChecklistTable/TemplateChecklistTableComponent';

export const testIds = {
  searchFieldComponent: 'template-checklist-table-search-field',
};
export interface TemplateChecklistReadViewProps {
  templates: template_checklist_data_from_list[];
  onConfirmedToDelete: () => void;
  handleDeleteButtonClick: (id: number) => void;
  openDeleteConfirmDialog: boolean;
  loading: boolean;
  onCloseDeleteConfirmDialog: () => void;
  filterTemplateChecklist: (filterInput: string) => void;
  onUpdate: (id: number) => void;
}

/**
 * Description - View of TemplateChecklistReadComponent
 */
const TemplateChecklistReadView: React.FC<TemplateChecklistReadViewProps> = ({
  templates,
  loading,
  onConfirmedToDelete,
  handleDeleteButtonClick,
  openDeleteConfirmDialog,
  onCloseDeleteConfirmDialog,
  filterTemplateChecklist,
  onUpdate,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <PageContainer>
      {loading ? (
        <LinearProgress />
      ) : (
        <Fragment>
          <div className={classes.tableHeader}>
            <div>
              <AddButtonComponent
                label="template:addTemplate"
                linkPath={routes.createTemplate.path}
              />
            </div>
            <div
              className={classes.search}
              data-testid={testIds.searchFieldComponent}
            >
              <SearchField onChange={filterTemplateChecklist} />
            </div>
          </div>

          <TemplateChecklistTableComponent
            templates={templates}
            onDelete={handleDeleteButtonClick}
            onUpdate={onUpdate}
          />
          <ConfirmDialog
            open={openDeleteConfirmDialog}
            onClose={onCloseDeleteConfirmDialog}
            title={t('common:delete')}
            text={t('common:confirmDelete')}
            onConfirm={onConfirmedToDelete}
          />
        </Fragment>
      )}
    </PageContainer>
  );
};

export default TemplateChecklistReadView;
