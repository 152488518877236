import { Box, CircularProgress, Grid, Paper, Typography } from '@material-ui/core';
import React, { Fragment, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useStyles } from './ChecklistReportInterviewStyle';
import { translateLongName } from '../../../services/Translation/TranslationService';
import AppContext from '../../../AppContext';
import {
  get_audit_report_interview_by_audit_id_data,
  get_audit_report_interview_by_audit_id_item_data,
  get_audit_report_interview_by_audit_id_section_data,
  checklist_data
} from '../../../services/Audit/ReadService/AuditReadModel';
import ChecklistReportSectionComponent from '../ChecklistReportSectionInterview/ChecklistReportSectionInterview';

export const testIds = {
  buttonToDuplicate: 'checklist-theme-section-button-to-duplicate',
};
export interface ChecklistReporInterviewtViewProps {
  audit: get_audit_report_interview_by_audit_id_data | null;
  loading: boolean;
  isReadOnlyMode?: boolean;
}

export interface audit_report_has_interview_section_view {
  id: number;
  checklist_theme_items: get_audit_report_interview_by_audit_id_item_data[];
}

const ChecklistReportInterviewView: React.FC<ChecklistReporInterviewtViewProps> = ({
  audit,
  loading,
  isReadOnlyMode
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { language } = useContext(AppContext);
  
  const printSection = useCallback((
    currentChecklistTheme,
    themeSection: get_audit_report_interview_by_audit_id_section_data,
    checklist : checklist_data,
  ) => {
    const themeName: string = translateLongName(
      currentChecklistTheme,
      language,
    );
    return (
      <>
        <Grid item lg={4} xs={4} className={classes.checklistThemeGrid}>
          <Box m={2} fontWeight="fontWeightBold">
            <Typography className={classes.theme}>
              {themeSection.specification
                ? themeName + ': ' + themeSection.specification
                : themeName}
            </Typography>
          </Box>
        </Grid>
        <ChecklistReportSectionComponent
          key={themeSection.id}
          audit_id={audit?.id}
          checklist_theme_section={themeSection}
          checklist={checklist}
          isReadOnlyMode={isReadOnlyMode}
        />
      </>
    );
  },[audit, classes, isReadOnlyMode, language])


  return loading ? (
    <CircularProgress/>
  ) : (
    <>
      <Grid container>
        <Grid item lg={12} xs={12} className={classes.titleGrid}>
          <Box m={1}>
            <Paper elevation={3}>
              <Box m={2}>
                <Typography className={classes.title}>
                  {t('report:interview_title', {
                    abbreviation: audit?.entity?.abbreviation,
                    location: audit?.location_entity?.name,
                    audit_date: moment(audit?.audit_date)?.format('DD/MM/YYYY'),
                  })}
                </Typography>
              </Box>
            </Paper>
          </Box>

          <Box m={1}>
            <div className={classes.themeGrid}>
              <Paper elevation={3}>
                {audit?.audit_buildings?.map((audit_building, index) => {
                  return (
                    <Fragment key={audit_building.id}>
                      <Grid
                        item
                        lg={12}
                        xs={12}
                        className={classes.addressGrid}
                      >
                        <Box m={2}>
                          <Typography className={classes.address}>
                            <span className={classes.addressNumber}>
                              {index + 1}
                            </span>{' '}
                            {audit_building?.building?.address?.street}{' '}
                            {audit_building?.building?.address?.house_number}
                          </Typography>
                        </Box>
                      </Grid>
                      {audit_building.building?.checklist?.checklist_themes?.map(
                        currentChecklist_theme => {
                          return (
                            <Fragment key={currentChecklist_theme?.id}>
                              <Paper
                                elevation={3}
                                className={classes.checklistThemeGrid}
                              >
                                <Grid item lg={12} xs={12}>
                                  <Grid container>
                                    {currentChecklist_theme.checklist_theme_sections.map(
                                      checklist_theme_section =>
                                        <Fragment key={checklist_theme_section.id}>
                                          {
                                            printSection(
                                              currentChecklist_theme,
                                              checklist_theme_section,
                                              audit_building.building?.checklist 
                                            )
                                          }
                                        </Fragment>
                                    )}
                                  </Grid>
                                </Grid>
                              </Paper>
                            </Fragment>
                          );
                        },
                      )}
                    </Fragment>
                  );
                })}
              </Paper>
            </div>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default ChecklistReportInterviewView;
