/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Drawer,
  Grid,
  Typography,
  TextareaAutosize,
  AppBar,
} from '@material-ui/core';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppContext from '../../../AppContext';
import clsx from 'clsx';
import { translate, translateLongName } from '../../../services/Translation/TranslationService';
import ChecklistNavigationComponent from '../ChecklistNavigation/ChecklistNavigationComponent';
import { checklist_data } from '../../../services/Checklist/ChecklistReadService';
import ChecklistThemeComponent from '../../ChecklistTheme/ChecklistThemeComponent';
import { building_data } from '../../../services/Building/BuildingDataModel';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  useStyles,
  AccordionThemeRemark,
  AccordionSummaryThemeRemark,
  AccordionDetailsThemeRemark,
} from '.';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  get_checklist_by_pk_data,
  get_checklist_by_pk_theme_data,
} from '../../../services/Checklist/ChecklistReadService/ChecklistReadQuery';
import AdminDocumentDialog from '../../AdminDocument/AdminDocumentRead/AdminDocumentDialog';
import AdminDocumentServiceContext from '../../AdminDocument/AdminDocumentRead/AdminDocumentServiceContext';
import { audit_data } from '../../../services/Audit/AuditDataModel';
export interface ChecklistViewProps {
  onThemeSelected: (theme) => void;
  selectedTheme?: get_checklist_by_pk_theme_data;
  building: building_data;
  audit: audit_data;
  form: any;
  category: any;
  subCategory: any;
  checklist?: get_checklist_by_pk_data;
  width: 'small' | 'full' | 'medium';
  onCloseMenu: () => void;
  onOpenMenu: () => void;
  themeMenuOpen: boolean;
  onRemarkSelected: () => void;
  selectedChecklistRemark?: checklist_data;
  expanded;
  onExpand;
  comment;
  onChangeComment;
  isReadOnlyMode: boolean;
}
/**
 * Description - View of ChecklistComponent
 */
const ChecklistView: React.FC<ChecklistViewProps> = ({
  subCategory,
  category,
  onThemeSelected,
  selectedTheme,
  building,
  checklist,
  width,
  themeMenuOpen,
  onCloseMenu,
  onOpenMenu,
  onRemarkSelected,
  selectedChecklistRemark,
  expanded,
  onExpand,
  onChangeComment,
  comment,
  isReadOnlyMode,
  audit
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { language } = useContext(AppContext);
  const { open: openDocumentDialog, pageNumber, onCloseDialog: onCloseDocumentDialog } = useContext(AdminDocumentServiceContext)

  return (
    <div className={classes.root}>
      <AppBar
        className={clsx(classes.mainInfoBar, {
          [classes.mainInfoBarShiftMedium]: width === 'medium',
          [classes.mainInfoBarShiftSmall]: width === 'small',
        })}
        color="inherit"
        position="sticky"
        elevation={2}
      >
        <AdminDocumentDialog
          open={openDocumentDialog}
          pageNumber={pageNumber}
          onCloseDialog={onCloseDocumentDialog}
        />
        <Grid container>
          <Grid item lg={12} xs={12}>
            {building?.address?.box_number ?
              <Typography display="inline" className={classes.checlikstTitle}>
                {`${building?.address?.street} ${building?.address?.house_number}`}
                {` / `}
                {`${building?.address?.box_number} ${building?.address?.city}`}
                {`, `}
                {!subCategory && category && translate(category, language)}
                {subCategory && translate(subCategory, language)}
              </Typography>
              :
              <Typography display="inline" className={classes.checlikstTitle}>
                {`${building?.address?.street} ${building?.address?.house_number} ${building?.address?.city}`}
                {`, `}
                {!subCategory && category && translate(category, language)}
                {subCategory && translate(subCategory, language)}
              </Typography>
            }
          </Grid>
          <Grid item lg={12} xs={12}>
            {building?.building_field_values?.map(fieldValue => (
              <Typography
                className={classes.checlikstTitle}
                key={fieldValue.category_location_form_field_config_id}
                variant="subtitle1"
              >
                {translate(
                  fieldValue.category_location_form_field_config,
                  language,
                )}
                : {fieldValue.value}{' '}
              </Typography>
            ))}
          </Grid>
          <Grid item lg={12} xs={12}>
            <AccordionThemeRemark
              className={classes.containerRemarkPaper}
              defaultExpanded={true}
              onChange={onExpand(selectedTheme?.id.toString())}
              expanded={expanded}
            >
              <AccordionSummaryThemeRemark
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  variant="subtitle1"
                  className={classes.generalRemarkLabel}
                >
                  {selectedTheme && t('checklist:generalRemark')}
                  {selectedTheme && ` "`}
                  {selectedTheme && translateLongName(selectedTheme, language)}
                  {selectedTheme && `"`}
                </Typography>
              </AccordionSummaryThemeRemark>
              <AccordionDetailsThemeRemark>
                {selectedTheme && (
                  <TextareaAutosize
                    id="comment"
                    name="comment"
                    minRows={1}
                    className={classes.textarea}
                    value={comment}
                    onChange={event => onChangeComment(event)}
                    disabled={isReadOnlyMode}
                  />
                )}
              </AccordionDetailsThemeRemark>
            </AccordionThemeRemark>
          </Grid>
        </Grid>
        {!themeMenuOpen && (
          <div className={classes.containerButtonOpen}>
            <Button
              className={classes.buttonOpen}
              startIcon={<ChevronLeftIcon />}
              onClick={onOpenMenu}
            >
              <Typography variant="body1">
                {t('checklist_theme:openMenuTheme')}
              </Typography>
            </Button>
          </div>
        )}
      </AppBar>

      <div
        className={clsx(classes.containerContent, {
          [classes.containerContentShift]: themeMenuOpen,
        })}
      >
        <div className={classes.content}>
          {checklist?.checklist_themes?.map((theme) => (
            selectedTheme && selectedTheme.id === theme.id && (
              <ChecklistThemeComponent
                key={theme.id}
                theme={selectedTheme}
                checklistId={checklist.id}
                isReadOnlyMode={isReadOnlyMode}
                building={building}
                audit={audit}
              />
            )
          ))}
        </div>
      </div>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={themeMenuOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <ChecklistNavigationComponent
          onThemeSelected={onThemeSelected}
          onRemarkSelected={onRemarkSelected}
          selectedTheme={selectedTheme}
          checklist={checklist}
          onCloseMenu={onCloseMenu}
          selectedChecklistRemark={selectedChecklistRemark}
        />
      </Drawer>
    </div>
  );
};

export default ChecklistView;
