import { CircularProgress } from '@material-ui/core';
import React, { Fragment } from 'react';
import { audit_data } from '../../../../services/Audit/AuditDataModel';
import { get_by_building_by_pk_data } from '../../../../services/Building/ReadService';
import BuildingFormComponent from '../../BuildingForm/BuildingFormComponent/BuildingFormComponent';
import { BuildingFormData } from '../../BuildingForm/BuildingFormPresenter';

export interface BuildingUpdateViewProps {
  audit?: audit_data;
  building?: get_by_building_by_pk_data | null;
  onUpdateBuilding: ({
    building,
    haveToUpdateChecklist,
  }: {
    building: BuildingFormData;
    haveToUpdateChecklist?: boolean;
  }) => void;
  parentPath: string;
  loading: boolean;
}

const BuildingUpdateView: React.FC<BuildingUpdateViewProps> = ({
  audit,
  building,
  onUpdateBuilding,
  parentPath,
  loading,
}) =>
  // loading concern audit and building loading
  loading ? (
    <CircularProgress />
  ) : audit && building ? (
    <Fragment>
      <BuildingFormComponent
        audit={audit}
        building={building}
        onSubmitBuilding={onUpdateBuilding}
        title="building:updateBuilding"
        submitButtonLabel="common:update"
        parentPath={parentPath}
      />
    </Fragment>
  ) : null;

export default BuildingUpdateView;
