import React from 'react'
import { DocumentDialogServiceContextValue } from './AdminDocumentService'

const AdminDocumentServiceContext = React.createContext<DocumentDialogServiceContextValue>({
    open: false,
    pageNumber: -1,
    onNextPage: () =>{},
    onPreviousPage: () =>{},
    onCloseDialog: () => {},
    onSelectPage: (numberPage: number) => {}
})

export const  AdminocumentServiceContextProvider = AdminDocumentServiceContext.Provider

export default AdminDocumentServiceContext