import { useMutation } from '@apollo/client';
import { useCallback, useContext, useMemo } from 'react';
import MainAppContext from '../../../../AppContext';
import { NotificationType } from '../../../../components/Notification/NotificationComponent';
import { isContraintViolationError } from '../../../../graphql/utils';
import { ItemRemarkFormData } from '../ItemRemarkFormData';
import { READ_ALL_REMARKS_FROM_TEMPLATE_THEME_ITEM } from '../ReadService/ItemRemarkReadQuery';
import { useGetAllRemarkIncludeSoftDeletedByItem } from '../ReadService/ItemRemarkReadService';
import { INSERT_ITEM_REMARK, insert_qat_template_theme_item_remark_one_response, insert_qat_template_theme_item_remark_one_variables } from './ItemRemarkWriteMutation';

export const useCreateItemRemark = templateThemeItemId => {
  const { setNotification } = useContext(MainAppContext);

  const {
    getAllRemarkIncludeSoftDeletedByItem,
  } = useGetAllRemarkIncludeSoftDeletedByItem();

  const mutationConfiguration = useMemo(() => ({
    refetchQueries: [
      {
        query: READ_ALL_REMARKS_FROM_TEMPLATE_THEME_ITEM,
        variables: { template_theme_item_id: templateThemeItemId },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted() {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      if (isContraintViolationError(error)) {
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      } else {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      }
    },
  }), [setNotification, templateThemeItemId])

  const [createdItemRemark] = useMutation<insert_qat_template_theme_item_remark_one_response, insert_qat_template_theme_item_remark_one_variables>(INSERT_ITEM_REMARK, {
    ...mutationConfiguration
  });

  const onCreateItemRemark = useCallback(
    (itemRemarkFormData: ItemRemarkFormData) => {
      getAllRemarkIncludeSoftDeletedByItem({ template_theme_item_id: templateThemeItemId }).then(items => {
        itemRemarkFormData.index = items.length
        createdItemRemark({
          variables: { object: itemRemarkFormData },
        });
      })

    },
    [
      createdItemRemark,
      getAllRemarkIncludeSoftDeletedByItem,
      templateThemeItemId
    ],
  );

  return { onCreateItemRemark };
};
