export const checklist = {
  checklist: 'Checklist',
  generalRemark: 'Algemene opmerkingen voor dit thema',
  checkListRemark: 'Opmerkingen',
  itemRemark: 'Selecteer een opmerking',
  itemRemarkPredefined: 'Kies een opmerking',
  itemRemarkFreeText: 'schrijf een opmerking',
  takePicture: 'Neem een foto',
  takeAndOthePicture: 'Foto vervangen',
  errorStartCamera: 'Er is een probleem met uw camera.\n Verifieer of hij bv. niet gebruikt wordt door een andere applicatie..'
};
