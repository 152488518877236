import { getPermissions } from './Permissions';

/**
 * This method is responsible to determine if the user role has access to the given rules
 * @param rules rules to check
 * @param role rules for a given role
 * @returns true if allowed, false otherwise
 */
export const isAllowed = (rules: string[], role): boolean => {
  if (!rules || rules.length === 0 || !role) return false;

  const permission = getPermissions().find(
    permissionItem => permissionItem && permissionItem.role === role,
  );

  if (!permission || !permission.rules || permission.rules.length === 0)
    return false;

  return rules.some(rule => permission.rules.includes(rule));
};
