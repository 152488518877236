import React from 'react';
import HelpIcon from '@material-ui/icons/Help';
//import { useStyles } from '../Table/TableTrueValueComponent/TableTrueValueStyles'
import { Tooltip, makeStyles, ClickAwayListener } from '@material-ui/core';

/**
 * Description - Icon to show info
 */
interface TableHelpIconWithToolTipProps {
  helpInfo: string;
}
const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.info.main,
  },
  tooltip: {
    height: 22,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
  },
}));
const TableHelpIconWithToolTip = ({
  helpInfo,
}: TableHelpIconWithToolTipProps) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        PopperProps={{
          disablePortal: true,
        }}
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={helpInfo}
        className={classes.tooltip}
      >
        <HelpIcon fontSize="large" className={classes.root} onClick={handleTooltipOpen} />
      </Tooltip>
    </ClickAwayListener>

  );
};

export default TableHelpIconWithToolTip;
