import { useGetAuditReportInterviewByAuditId } from '../../../services/Audit/ReadService/AuditReadService';

export const useChecklistReportInterviewPresenter = audit_id => {
  const { data, loading } = useGetAuditReportInterviewByAuditId({
    id: audit_id,
  });

  return {
    audit: data,
    loading,
  };
};
