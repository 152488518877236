import { makeStyles } from '@material-ui/core/styles';
import { createStyles, Theme, withStyles, TableSortLabel } from '@material-ui/core';

const useStyles = makeStyles(
  theme => ({
    checkbox: {
      '&$checked': {
        color: theme.palette.success.main,
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    root: {
      backgroundColor: theme.palette.success.main,
      marginBottom: 20,
      '&:hover': {
         background: theme.palette.success.dark,
      },
      color: '#ffffff',
    },

    grey: {
      backgroundColor: '#d3d3d3',
      marginTop: 10,
      '&:hover': {
         background: '#afafaf',
      },
      color: '#141414',
    },
    
  table: {
    minWidth: 650,
  },

  tableRow: {
    backgroundColor: '#f0f0f0',
  },

  paper: {
    width: '100%',
    height: '100%',
    overflow: 'hidden'
  },

  tableContainer: {
    height: 'auto',
    maxHeight: 500,
  },

    checked: {},
    control: {
      width: '100%',
    },
    title: {
      flexGrow: 1,
      color: '#141414',
    },
    divCheckBox: {
      display: 'flex',
      flexDirection: 'row',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },

    divButton: {
      '&$checked': {
        color: theme.palette.success.main,
      },
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
    },

    buttonValidateAdd: {
      '&:hover': {
        backgroundColor: theme.palette.success.main,
        color: 'white',
      },
      backgroundColor: theme.palette.success.main,
      color: 'white',
      marginBottom: 10,
      width: '100%',
    },
    buttonValidate: {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      width: '100%',
    },
    dialogPaper: {
      height: '80vh',
    },
    tableWidth: {
      minWidth: 650,
    },
    tableSmallCellSize: { minWidth: 75, maxWidth: 75 },
    tableLargeCellSize: { minWidth: 100, maxWidth: 100 },
    search: {
      width: '79%',
      display: 'flex',
      justifyContent: 'flex-end',
      margin: 10,
    },
    create: {
      margin: 10,
    },
    tableHeader: {
      display: 'flex',
    },
    textarea: {
      width: '100%',
      fontSize: '0.875rem',
      fontFamily: 'Poppins',
      padding: '10px 14px',
    },
    itemTitle: {
      margin: 15,
    },
    index: {
      color: '#03a9f4',
    },
  }),
  {
    index: 1,
  },
);

export { useStyles };

export const StyledTableSortLabel = withStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#03a9f4',
      '&:hover': {
        color: '#03a9f4',
      },
      '&$active': {
        color: '#03a9f4',
      },
    },
    active: {},
    icon: {
      color: 'inherit !important',
    },
  }),
)(TableSortLabel);