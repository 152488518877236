
import {  useQuery } from '@apollo/client';
import { useContext } from 'react';
import { NotificationType } from '../../components/Notification/NotificationComponent';
import { READ_AUDIT_PHOTO_BY_AUDIT_ID_WHITH_OVERVIEW_DETAIL } from './AuditPhotoOverviewQuery';
import { photoOverview } from './AuditPhotoOverviewModel';
import AppContext from '../../AppContext';

export const  useReportAuditPhotoMetadata=(audit_id)=>{
    const { setNotification } = useContext(AppContext);

    let { data, loading, error } = useQuery<photoOverview>(
        READ_AUDIT_PHOTO_BY_AUDIT_ID_WHITH_OVERVIEW_DETAIL,
        {
            variables: { audit_id: audit_id} ,
            fetchPolicy: 'network-only',
            onError(errorResponse) {
                setNotification({
                    key: 'common:generalError',
                    type: NotificationType.ERROR,
                });
            },
        });
    return { data: data ? data.qat_audit:[], loading, error };

}





