import React, { useCallback, useMemo } from 'react';
import {
  AddressCreateFormData,
  useCreateAddress,
} from '../../../../services/Address/WriteService/AddressWriteService';
import AddressFormComponent from '../../AddressForm/AddressFormComponent';
import { useHistory } from 'react-router-dom';
import routes from '../../../../routes';
import {useBrowserLocationState} from "../../../Shared/BrowserLocationState/BrowserLocationState"

/**
 * Description - Component to create address
 */
const AddressCreateComponent = ({ parentPath }) => {

  const currentLocationState = useBrowserLocationState<{audit: any, building: any}>({keysRequired: ["audit"]})
  const history = useHistory();
  const location_entity_id = useMemo(() => {
    if (currentLocationState?.audit?.location_entity?.id) {
      return currentLocationState.audit.location_entity.id
    } else {
      console.error("the state audit.location_entity.id is required")
      return ""
    }
  }, [currentLocationState])

  const { onCreate } = useCreateAddress({ location_entity_id });

  const audit = useMemo(() => {
    if (currentLocationState?.audit) {
      return currentLocationState.audit
    } else {
      console.error("audit is required from location.state")
      return null
    }
  }, [currentLocationState])

  const onSubmit = useCallback(
    async (address: AddressCreateFormData) => {
      onCreate(address).then(response => {
        if (response !== null) {
          const linkPath = currentLocationState?.building?.id
            ? `${parentPath}${routes.editBuilding.path}`
            : `${parentPath}${routes.createBuilding.path}`;
          
            const state: any = {
              audit: currentLocationState?.audit
            }

            if(currentLocationState?.building?.id){
              state.building = {...currentLocationState.building, address_id: response?.data?.insert_qat_address_one?.id }
              state.building_id = currentLocationState.building.id
              state.address_id_to_inject_in_form = response?.data?.insert_qat_address_one?.id 
            } else {
              state.address_id_to_inject_in_form = response?.data?.insert_qat_address_one?.id 
            }
          
          history.push({
            pathname: linkPath,
            state,
          });
        }
      });
    },
    [onCreate, history, parentPath, currentLocationState],
  );

  const handleClose = useCallback(
    () => {
      const linkPath = currentLocationState?.building?.id
        ? `${parentPath}${routes.editBuilding.path}`
        : `${parentPath}${routes.createBuilding.path}`;

      history.push({
        pathname: linkPath,
        state: { audit: currentLocationState?.audit},
      });
    },

    [history, parentPath, currentLocationState],
  );

  return (
    <AddressFormComponent
      title="address:createAddress"
      submitButtonLabel="common:validate"
      audit={audit}
      onSubmit={onSubmit}
      handleClose={handleClose}
    />
  );
};

export default AddressCreateComponent;
