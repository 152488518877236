export const report = {
  interview: 'Overzicht interview',
  not_applicable: 'Overzicht nvt',
  not_conform: 'Overzicht niet conform',
  photo: "Overzicht foto's",
  export: "Exporteer als .doc",
  interview_title: "Lijst van items voor interview {{abbreviation}} {{location}} op {{audit_date}}",
  not_conform_title: "lijst vaststellingen niet conform voor {{abbreviation}} {{location}} op {{audit_date}}",
  not_applicable_title: "Lijst van items niet van toepassing voor {{abbreviation}} {{location}} op {{audit_date}}",
  photo_title: "Lijst van fotos voor {{abbreviation}} {{location}} op {{audit_date}}",
  section_not_applicable: "Thema niet van toepassing",
  sub_section_not_applicable: "Subthema niet van toepassing",
  theme: "Het thema ",
  subtheme: "Het subthema ",
  notApplicable: " Niet van toepassing",
  boxNumber: "bus {{0}} ",
  albumPhoto:"Foto-album",
  documentButton: "Document" 

};
