import React, { useMemo, useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import {
  lastSubThemeIndexVar,
  openCreateSubThemeVar,
  subThemeVar,
} from '../SubThemeCache';
import { SubThemeFormData } from '../SubThemeFormModel';
import { useCreateSubTheme } from '../../../services/SubTheme/SubThemeWriteService';
import SubThemeFormComponent from './SubThemeFormDialogComponent';
import { useGetSubThemeByIdService } from '../../../services/SubTheme/SubThemeReadService/SubThemeReadService';
import { useBrowserLocationState } from '../../Shared/BrowserLocationState/BrowserLocationState';

const SubThemeCreateDialogComponent = () => {
  const isOpenCreateSubTheme: boolean = useReactiveVar(openCreateSubThemeVar);
  const currentSubTheme: SubThemeFormData = useReactiveVar(subThemeVar);
  const currentLastIndex = lastSubThemeIndexVar();

  const currentLocationState = useBrowserLocationState<{theme: any}>({keysRequired: ["theme"]})

  const themeId: number = useMemo(
    () =>
    currentLocationState && currentLocationState.theme
        ? currentLocationState.theme.id
        : -1,
    [currentLocationState],
  );
  const { onCreate } = useCreateSubTheme({
    themeId: themeId,
    index: currentLastIndex,
  });

  const { data} = useGetSubThemeByIdService({
    themeId: themeId,
  });

  const defaultSubtheme: SubThemeFormData = {
    id: -1,
    name_fr: '',
    name_nl: '',
    index: -1,
    not_applicable_option: false,
    theme_template_checklist_id: -1,
  };

  function subThemeFormValue() {
    let subThemeForm: SubThemeFormData;
    subThemeForm = {
      name_fr: currentSubTheme.name_fr,
      name_nl: currentSubTheme.name_nl,
      not_applicable_option: currentSubTheme.not_applicable_option,
      theme_template_checklist_id: currentSubTheme.theme_template_checklist_id,
    };
    return subThemeForm;
  }

  const handleCreateSubTheme = () => {
    let subThemeForm: SubThemeFormData = subThemeFormValue();
    onCreate(subThemeForm);
    openCreateSubThemeVar(false);
    subThemeVar(defaultSubtheme);
  };

  const handleCreateAddItem = () => {
    let subThemeForm: SubThemeFormData = subThemeFormValue();
    onCreate(subThemeForm);
    subThemeVar(defaultSubtheme);
  };
  const history = useHistory();
  const handleClose = useCallback(() => {
    openCreateSubThemeVar(false);
    subThemeVar(defaultSubtheme);
    if(data.length === 0){
      history.goBack();
    }
  }, [defaultSubtheme, history, data.length]);

  return (
    <SubThemeFormComponent
      isOpenForm={isOpenCreateSubTheme}
      handleClose={handleClose}
      handleFormSubmit={handleCreateSubTheme}
      handleCreateAddItem={handleCreateAddItem}
      templateThemeId={themeId}
    />
  );
};

export default SubThemeCreateDialogComponent;
