import { useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import AppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import {
  checklist_from_get_by_building_by_pk_data,
  GET_BUILDING_BY_AUDIT,
} from '../../Building/ReadService/BuildingReadQuery';
import { qat_template_checklist_data } from '../../TemplateChecklist/TemplateChecklistReadService/TemplateCheckistReadModel';
import { GET_CHECKLIST_BY_PK } from '../ChecklistReadService';
import { get_checklist_by_pk_data } from '../ChecklistReadService/ChecklistReadQuery';
import { ChecklistFormData, ChecklistData } from './ChecklistModel';
import {
  INSERT_CHECKLIST,
  UPDATE_CHECKLIST,
  UPDATE_CHECKLIST_BY_TEMPLATE,
  UPSERT_CHECKLIST,
} from './ChecklistWriteMutation';

export interface CheckListCreateResult {
  onCreate: (params: {
    template: qat_template_checklist_data;
    building_id: number;
  }) => Promise<any>;
}

export interface CheckListUpsertResult {
  onCreate: (data: ChecklistFormData) => Promise<any>;
}

export interface CheckListUpdateResult {
  onUpdate: (data: ChecklistFormData) => Promise<any>;
}

export interface CheckListUpdatebyIdResult {
  onUpdateById: (id: number, data: ChecklistData) => Promise<any>;
}

export interface ChecklistInsertInputType {
  checklist_themes: {
    data?: ChecklistThemeInsertInputType[];
  };
}

export interface ChecklistThemeInsertInputType {
  checklist_theme_sections: {
    data?: ChecklistThemeSectionInsertInputType[];
  };
  long_name_fr: string;
  long_name_nl: string;
  short_name_fr: string;
  short_name_nl: string;
  comment?: string;
  not_applicable: boolean;
  duplicate_section_number: number;
}

export interface ChecklistThemeSectionInsertInputType {
  checklist_theme_sub_sections?: {
    data?: ChecklistSubThemeSectionInsertInputType[];
  };
  checklist_theme_items?: {
    data?: ChecklistItemInsertInputType[];
  };
  theme_template_checklist_id: number;
  specification?: string;
  checklist_theme_id?: number;
  name_fr: string;
  name_nl: string;
  no_application: boolean;
}

export interface ChecklistSubThemeSectionInsertInputType {
  sub_theme_template_checklist_id: number;
  checklist_theme_items?: {
    data?: ChecklistItemInsertInputType[];
  };
  name_fr: string;
  name_nl: string;
  not_applicable_option: boolean;
  index: number;
}

export interface ChecklistItemInsertInputType {
  template_theme_item_id: number;
  not_application: boolean;
  has_interview: boolean;
  name_fr: string;
  name_nl: string;
  checklist_theme_item_remarks?: {
    data: ChecklistRemarkInsertInpuType[];
  };
  norm_code?: string;
  page_pdf_nl?: number;
  page_pdf_fr?: number;
}

export interface ChecklistRemarkInsertInpuType {
  checklist_theme_item_id?: number;
  template_theme_item_remark_id: number;
  checked: boolean;
  name_fr: string;
  name_nl: string;
  index: number;
}

/**
 * Description - service to create a checklist
 */
export const useCreateChecklistWithTemplate = ({
  audit_id,
}: {
  audit_id: number;
}): CheckListCreateResult => {
  const { setNotification } = useContext(AppContext);

  const [create] = useMutation(INSERT_CHECKLIST, {
    refetchQueries: [{ query: GET_BUILDING_BY_AUDIT, variables: { audit_id } }],
    awaitRefetchQueries: true,
    onError: () => {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const onCreate = useCallback(
    async ({
      template,
      building_id,
    }: {
      template: qat_template_checklist_data;
      building_id: number;
    }): Promise<any> => {
      const checklist_themes: ChecklistThemeInsertInputType[] = template.theme_template_checklists.map(
        theme_template => {
          const sub_theme_section:
            | ChecklistSubThemeSectionInsertInputType[]
            | undefined = theme_template.sub_theme_template_checklists?.map(
            sub_theme_template => {
              const sub_theme_template_checklist_id = sub_theme_template?.id;
              const checklist_theme_items_sub_section:
                | ChecklistItemInsertInputType[]
                | undefined = sub_theme_template.template_theme_items?.map(
                themeItem => {
                  const checklist_theme_item_remarks:
                    | ChecklistRemarkInsertInpuType[]
                    | undefined = themeItem.template_theme_item_remarks?.map(
                    remarkOption => ({
                      name_fr: remarkOption.remark_text_fr,
                      name_nl: remarkOption.remark_text_nl,
                      checked: false,
                      template_theme_item_remark_id: remarkOption.id,
                      index: remarkOption.index,
                    }),
                  );
                  const checklist_items: ChecklistItemInsertInputType = {
                    template_theme_item_id: themeItem.id,
                    not_application: false,
                    has_interview: false,
                    name_fr: themeItem.title_fr,
                    name_nl: themeItem.title_nl,
                    norm_code: themeItem.norm_code,
                    page_pdf_nl: themeItem.page_pdf_nl,
                    page_pdf_fr: themeItem.page_pdf_fr,
                  };
                  if (checklist_theme_item_remarks) {
                    checklist_items.checklist_theme_item_remarks = {
                      data: checklist_theme_item_remarks,
                    };
                  }
                  return checklist_items;
                },
              );
              return {
                sub_theme_template_checklist_id,
                checklist_theme_items: {
                  data: checklist_theme_items_sub_section,
                },
                name_fr: sub_theme_template.name_fr,
                name_nl: sub_theme_template.name_nl,
                not_applicable_option: sub_theme_template.not_applicable_option,
                index: sub_theme_template.index,
              };
            },
          );

          const checklist_theme_items_section:
            | ChecklistItemInsertInputType[]
            | undefined = theme_template.template_theme_items?.map(
            themeItem => {
              const checklist_theme_item_remarks:
                | ChecklistRemarkInsertInpuType[]
                | undefined = themeItem.template_theme_item_remarks?.map(
                remarkOption => ({
                  name_fr: remarkOption.remark_text_fr,
                  name_nl: remarkOption.remark_text_nl,
                  checked: false,
                  template_theme_item_remark_id: remarkOption.id,
                  index: remarkOption.index,
                }),
              );
              const checklist_items: ChecklistItemInsertInputType = {
                template_theme_item_id: themeItem.id,
                not_application: false,
                has_interview: false,
                name_fr: themeItem.title_fr,
                name_nl: themeItem.title_nl,
                norm_code: themeItem.norm_code,
                page_pdf_nl: themeItem.page_pdf_nl,
                page_pdf_fr: themeItem.page_pdf_fr,
              };
              if (checklist_theme_item_remarks) {
                checklist_items.checklist_theme_item_remarks = {
                  data: checklist_theme_item_remarks,
                };
              }
              return checklist_items;
            },
          );
            let checklist_theme_section: ChecklistThemeSectionInsertInputType = 
            {
              checklist_theme_sub_sections: {
                data: sub_theme_section,
              },
              name_fr: theme_template.long_name_fr,
              name_nl: theme_template.long_name_nl,
              theme_template_checklist_id: theme_template?.id,
              specification: '',
              no_application: false,
              checklist_theme_items: {
                data: checklist_theme_items_section,
              },
            };
            let checklist_theme_sections: ChecklistThemeSectionInsertInputType[] = [];
            for(let i = 0; i <theme_template.duplicate_section_number; i++ ){
                checklist_theme_sections.push(checklist_theme_section)
            }
          return {
            theme_template_id: theme_template?.id,
            comment: '',
            checklist_theme_sections: {
              data: checklist_theme_sections,
            },
            long_name_fr: theme_template ? theme_template.long_name_fr : '',
            long_name_nl: theme_template ? theme_template.long_name_nl : '',
            short_name_fr: theme_template ? theme_template.short_name_fr : '',
            short_name_nl: theme_template ? theme_template.short_name_nl : '',
            not_applicable: false,
            not_applicable_option: theme_template ? theme_template.not_applicable_option : false,
            duplicate_section_number: theme_template ? theme_template.duplicate_section_number : 1
          };
        },
      );

      let data = {
        template_id: template.id,
        checklist_themes: { data: checklist_themes },
        audit_id: audit_id,
        building_id: building_id,
      };
      return await create({ variables: { object: data } });
    },
    [create, audit_id],
  );

  return { onCreate };
};

export const useUpdateChecklistWithTemplate = ({
  checklist_id,
}: {
  checklist_id: number | undefined;
}) => {
  const { setNotification } = useContext(AppContext);

  const [update] = useMutation(UPDATE_CHECKLIST_BY_TEMPLATE, {
    refetchQueries: [
      { query: GET_CHECKLIST_BY_PK, variables: { id: checklist_id } },
    ],
    awaitRefetchQueries: true,
    onError: () => {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const onUpdateChecklistWithTemplate = useCallback(
    async ({
      template,
      checklist,
    }: {
      template: qat_template_checklist_data;
      checklist:
        | get_checklist_by_pk_data
        | checklist_from_get_by_building_by_pk_data
        | any;
    }): Promise<any> => {
      const checklist_themes: ChecklistThemeInsertInputType[] = template.theme_template_checklists.map(
        theme_template => {
          const sub_theme_section:
            | ChecklistSubThemeSectionInsertInputType[]
            | undefined = theme_template.sub_theme_template_checklists?.map(
            sub_theme_template => {
              const sub_theme_template_checklist_id = sub_theme_template?.id;
              const checklist_theme_items_sub_section:
                | ChecklistItemInsertInputType[]
                | undefined = sub_theme_template.template_theme_items?.map(
                themeItem => {
                  const checklist_theme_item_remarks:
                    | ChecklistRemarkInsertInpuType[]
                    | undefined = themeItem.template_theme_item_remarks?.map(
                    remarkOption => ({
                      name_fr: remarkOption.remark_text_fr,
                      name_nl: remarkOption.remark_text_nl,
                      checked: false,
                      template_theme_item_remark_id: remarkOption.id,
                      index: remarkOption.index,
                    }),
                  );
                  const checklist_items: ChecklistItemInsertInputType = {
                    template_theme_item_id: themeItem.id,
                    not_application: false,
                    has_interview: false,
                    name_fr: themeItem.title_fr,
                    name_nl: themeItem.title_nl,
                    norm_code: themeItem.norm_code,
                    page_pdf_nl: themeItem.page_pdf_nl,
                    page_pdf_fr: themeItem.page_pdf_fr,
                  };
                  if (checklist_theme_item_remarks) {
                    checklist_items.checklist_theme_item_remarks = {
                      data: checklist_theme_item_remarks,
                    };
                  }
                  return checklist_items;
                },
              );
              return {
                sub_theme_template_checklist_id,
                checklist_theme_items: {
                  data: checklist_theme_items_sub_section,
                },
                name_fr: sub_theme_template.name_fr,
                name_nl: sub_theme_template.name_nl,
                not_applicable_option: sub_theme_template.not_applicable_option,
                index: sub_theme_template.index,
              };
            },
          );

          const checklist_theme_items_section:
            | ChecklistItemInsertInputType[]
            | undefined = theme_template.template_theme_items?.map(
            themeItem => {
              const checklist_theme_item_remarks:
                | ChecklistRemarkInsertInpuType[]
                | undefined = themeItem.template_theme_item_remarks?.map(
                remarkOption => ({
                  name_fr: remarkOption.remark_text_fr,
                  name_nl: remarkOption.remark_text_nl,
                  checked: false,
                  template_theme_item_remark_id: remarkOption.id,
                  index: remarkOption.index,
                }),
              );
              const checklist_items: ChecklistItemInsertInputType = {
                template_theme_item_id: themeItem.id,
                not_application: false,
                has_interview: false,
                name_fr: themeItem.title_fr,
                name_nl: themeItem.title_nl,
                norm_code: themeItem.norm_code,
                page_pdf_nl: themeItem.page_pdf_nl,
                page_pdf_fr: themeItem.page_pdf_fr,
              };
              if (checklist_theme_item_remarks) {
                checklist_items.checklist_theme_item_remarks = {
                  data: checklist_theme_item_remarks,
                };
              }
              return checklist_items;
            },
          );
          let checklist_theme_section: ChecklistThemeSectionInsertInputType = 
            {
              checklist_theme_sub_sections: {
                data: sub_theme_section,
              },
              theme_template_checklist_id: theme_template?.id,
              specification: '',
              checklist_theme_items: {
                data: checklist_theme_items_section,
              },
              name_fr: theme_template.long_name_fr,
              name_nl: theme_template.long_name_nl,
              no_application: false,
            };
          let checklist_theme_sections: ChecklistThemeSectionInsertInputType[] = [];
          for(let i = 0; i <theme_template.duplicate_section_number; i++ ){
              checklist_theme_sections.push(checklist_theme_section)
          }

          const matchingTheme = checklist.checklist_themes.find(
            checklist_theme => {
              return (
                theme_template.short_name_fr ===
                  checklist_theme.short_name_fr &&
                theme_template.short_name_nl === checklist_theme.short_name_nl
              );
            },
          );
          const comment: string = matchingTheme ? matchingTheme.comment : '';

          return {
            theme_template_id: theme_template?.id,
            comment,
            checklist_theme_sections: {
              data: checklist_theme_sections,
            },
            checklist_id: checklist?.id,
            long_name_fr: theme_template ? theme_template.long_name_fr : '',
            long_name_nl: theme_template ? theme_template.long_name_nl : '',
            short_name_fr: theme_template ? theme_template.short_name_fr : '',
            short_name_nl: theme_template ? theme_template.short_name_nl : '',
            not_applicable: false,
            not_applicable_option: theme_template ? theme_template.not_applicable_option : false,
            duplicate_section_number: theme_template ? theme_template.duplicate_section_number : 1
          };
        },
      );

      return await update({
        variables: {
          id: checklist.id,
          template_id: template.id,
          checklist_themes,
        },
      });
    },
    [update],
  );

  return { onUpdateChecklistWithTemplate };
};

/**
 * Description - service to create a checklist
 */
export const useCreateChecklist = (): CheckListUpsertResult => {
  const { setNotification } = useContext(AppContext);

  const [create] = useMutation(UPSERT_CHECKLIST, {
    awaitRefetchQueries: true,
    onError: () => {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const onCreate = useCallback(
    async (data: ChecklistFormData) => {
      return await create({ variables: { object: data } });
    },
    [create],
  );

  return { onCreate };
};

/**
 * Description - service to update a checklist
 */
export const useUpdateChecklist = (): CheckListUpdateResult => {
  const { setNotification } = useContext(AppContext);

  const [update] = useMutation(UPDATE_CHECKLIST, {
    awaitRefetchQueries: true,
    onError: () => {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const onUpdate = useCallback(
    async (data: ChecklistFormData) => {
      return await update({ variables: { object: data } });
    },
    [update],
  );

  return { onUpdate };
};

/**
 * Description - service to update a checklist
 */
export const useUpdateChecklistById = (
  checklistId,
): CheckListUpdatebyIdResult => {
  const { setNotification } = useContext(AppContext);

  const [update] = useMutation(UPDATE_CHECKLIST, {
    refetchQueries: [
      { query: GET_CHECKLIST_BY_PK, variables: { id: checklistId } },
    ],
    awaitRefetchQueries: true,
    onError: () => {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const onUpdateById = useCallback(
    async (id: number, data: ChecklistData) => {
      return await update({ variables: { id: id, object: data } });
    },
    [update],
  );

  return { onUpdateById };
};
