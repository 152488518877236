import { gql } from '@apollo/client';

/**
 * Description - graphql query to get all addresses
 */
export const READ_ALL_ADDRESSES = gql`
  query get_all_adress {
    qat_address(
      order_by: { last_updated_date: desc }
      where: { deleted_date: { _is_null: true } }
    ) {
      id
      street
      house_number
      box_number
      city
      location_entity_id
    }
  }
`;
/**
 * Description - graphql query to get all addresses
 */
export const READ_ALL_ADDRESSES_BY_LOCATION_ID = gql`
  query get_adress ($location_entity_id: Int!){
    qat_address(
      order_by: { last_updated_date: desc }
         where: {_and: {location_entity_id: {_eq: $location_entity_id}}, deleted_date: {_is_null: true}}
    ) {
      id
      street
      house_number
      box_number
      city
      location_entity_id
    }
  }
`;
/**
 * Description - graphql query to get all addresses
 */
export const READ_ALL_ADDRESSES_SOFT_DELETE_BY_LOCATION_ID = gql`
  query get_adress ($location_entity_id: Int!){
    qat_address(
      order_by: { last_updated_date: desc }
         where: {location_entity_id: {_eq: $location_entity_id}}
    ) {
      id
      street
      house_number
      box_number
      city
      location_entity_id
      deleted_date
    }
  }
`;

/**
 * Description - graphql query to get a specific address
 */
export const GET_ADDRESS_BY_PK = gql`
  query qat_address_by_pk($id: Int!) {
    qat_address_by_pk(id: $id) {
      id
      street
      house_number
      box_number
      city
      location_entity_id
    }
  }
`;

/**
 * Description - graphql query to get unique address
 */

export const IS_UNIQUE_ADDRESS = gql`
  query is_unique_address(
    $street: String!
    $house_number: String!
    $box_number: String
    $city: String!
    $id: Int
  ) {
    qat_address(
      where: {
        _and: [
          { street: { _eq: $street } }
          { house_number: { _eq: $house_number } }
          { box_number: { _eq: $box_number } }
          { city: { _eq: $city } }
          { id: { _neq: $id } }
          {deleted_date:{_is_null:true}}
        ]
      }
    ) {
      id
    }
  }
`;

export const IS_UNIQUE_ADDRESS_IN_LOCATION = gql`
  query is_unique_address_in_location(
    $street: String!
    $house_number: String!
    $box_number: String
    $city: String!
    $location_entity_id: Int!

  ) {
    qat_address(
      where: {
        _and: [
          { street: { _eq: $street } }
          { house_number: { _eq: $house_number } }
          { box_number: { _eq: $box_number } }
          { city: { _eq: $city } }
          { location_entity_id: { _neq: $location_entity_id } }
        ]
      }
    ) {
      id
    }
  }
`;
