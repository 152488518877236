import { useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import AppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { GET_CHECKLIST_BY_PK } from '../../Checklist/ChecklistReadService';
import { checklist_sub_section } from '../../Checklist/ChecklistReadService/CheckistReadModel';
import {
  SET_NOT_APPLICATION_THEME_SUB_SECTION_CHECKLIST_FALSE,
  SET_NOT_APPLICATION_THEME_SUB_SECTION_CHECKLIST_TRUE,
} from './ChecklistThemeSubSectionWriteMutation';

export interface ChecklistThemeSubSectionSetNotApplicableResult {
  onSetNotApplicationFalse: ({ id: number }) => Promise<any>;
}

export const useSetNotApplicationChecklistThemeSubSectionFalse = ({
  checklist_id,
}: {
  checklist_id;
}): ChecklistThemeSubSectionSetNotApplicableResult => {
  const { setNotification } = useContext(AppContext);

  const [setNotApplicationFalse] = useMutation(
    SET_NOT_APPLICATION_THEME_SUB_SECTION_CHECKLIST_FALSE,
    {
      refetchQueries: [
        { query: GET_CHECKLIST_BY_PK, variables: { id: checklist_id } },
      ],
      awaitRefetchQueries: true,
      onError: () => {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    },
  );

  const onSetNotApplicationFalse = useCallback(
    async ({ id }: { id: number }) => {
      return await setNotApplicationFalse({ variables: { id } });
    },
    [setNotApplicationFalse],
  );

  return { onSetNotApplicationFalse };
};

export const useSetNotApplicationChecklistSubThemeTrue = ({
  checklist_id,
  section_id,
}: {
  checklist_id: number;
  section_id: number;
}) => {
  const { setNotification } = useContext(AppContext);

  const [setNotApplicationTrue] = useMutation(
    SET_NOT_APPLICATION_THEME_SUB_SECTION_CHECKLIST_TRUE,
    {
      refetchQueries: [
        { query: GET_CHECKLIST_BY_PK, variables: { id: checklist_id } },
      ],
      awaitRefetchQueries: true,
      onError: () => {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    },
  );

  const onSetNotApplicationTrue = useCallback(
    ({ subSection }: { subSection: checklist_sub_section }) => {
      const checklist_theme_items: any = subSection.checklist_theme_items?.map(
         ({ name_nl, name_fr, norm_code, template_theme_item_id, page_pdf_nl, page_pdf_fr }) => ({
          template_theme_item_id,
          not_application: false,
          has_interview: false,
          name_fr,
          name_nl,
          norm_code,
          page_pdf_fr,
          page_pdf_nl
        }),
      );

      const checklist_theme_sub_section = {
        checklist_theme_items: { data: checklist_theme_items },
        no_application: true,
        sub_theme_template_checklist_id:
          subSection?.sub_theme_template_checklist?.id,
        checklist_theme_section_id: section_id,
        name_fr: subSection.name_fr,
        name_nl: subSection.name_nl,
        not_applicable_option: subSection.not_applicable_option,
        index: subSection.index,
      };

      setNotApplicationTrue({
        variables: {
          checklist_theme_sub_section_id: subSection.id,
          checklist_theme_sub_section,
        },
      });
    },
    [setNotApplicationTrue, section_id],
  );

  return { onSetNotApplicationTrue };
};
