import { useContext, useEffect, useMemo } from "react"
import { useLocation, useHistory } from "react-router-dom"
import AppContext from "../../../AppContext"
import { NotificationType } from "../../Notification/NotificationComponent"
import routes from "../../../routes/index"

export const localStorageKey = "location-state"

export interface BrowserLocationStateProps {
    keysRequired: string[],
    redirect?: boolean,
    storedInCache?: boolean
}

/**
 * Description - Function to check if required key exist in object§f
 * @param object Object to validate
 * @param keysRequired List of required keys
 * @returns boolean if is validate or not
 */
export const checkKeyRequired = (object: any, keysRequired: string[]): boolean => {
    let valid: boolean = true
    keysRequired.forEach(key => {
        if(!object[key]){
            console.warn(`${key} is required in location state`)
            valid = false
        }
    })
    return valid
}

export const useBrowserLocationState = <T>({keysRequired, redirect, storedInCache}: BrowserLocationStateProps):T | undefined=> {

    const location: any = useLocation()
    const history = useHistory();
    const {setNotification} = useContext(AppContext)

    const state: T | undefined = useMemo(() => {
        
        let stateFromLocation;
        if(location?.state){
            stateFromLocation = location.state
        }

        const stateFromBrowserCacheInString = localStorage.getItem(localStorageKey)

        let stateFromBrowserCache;
        if(stateFromBrowserCacheInString){
            const stateFromLocalStorage = JSON.parse(stateFromBrowserCacheInString)
            stateFromBrowserCache = stateFromLocalStorage
        }

        const toStore = storedInCache === undefined || storedInCache === true

        if(stateFromLocation && toStore){
            checkKeyRequired(location.state, keysRequired)
            if(stateFromBrowserCache){
                localStorage.setItem(localStorageKey, JSON.stringify({...stateFromBrowserCache, ...stateFromLocation}))
            } else {
                localStorage.setItem(localStorageKey, JSON.stringify(stateFromLocation))
            }
            return stateFromLocation
        } 
        if(stateFromBrowserCache){
            checkKeyRequired(stateFromBrowserCache, keysRequired)
            return stateFromBrowserCache
        }

        setNotification({
            type: NotificationType.WARNING,
            key: "locationStateRequired"
        })
        return undefined
    },[location, keysRequired, setNotification, storedInCache])

    useEffect(() => {
        if(!state && history && redirect){
            history.push(routes.currentAudits)
        }
    },[state, history, redirect])

    return state
}

export const removeItemCacheLocationState = (itemKey: string) => {
    const stateFromBrowserCacheInString = localStorage.getItem(localStorageKey)
    if(stateFromBrowserCacheInString){
        const stateFromBrowserCache = JSON.parse(stateFromBrowserCacheInString)
        delete stateFromBrowserCache[itemKey]
        localStorage.setItem(localStorageKey, JSON.stringify(stateFromBrowserCache))
    } 
   
}