import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Checkbox,
  Box,
  ButtonGroup,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../Shared/Styles/CommonFormSyles';
import { openCreateThemeVar, themeVar } from '../ThemeCache';
import { useReactiveVar } from '@apollo/client';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ThemeFormData } from '../ThemeFormModel';
import { useCheckThemeUnique } from '../../../services/Theme/ThemeReadService/ThemeReadService';
import { useBrowserLocationState } from '../../Shared/BrowserLocationState/BrowserLocationState';

export const formDialogTestIds = {
  updateCreateTemplateNameInput: 'template-name',
  updateCreateShortNameFrInput: 'short_name_fr_input',
  updateCreateShortNameNlInput: 'short_name_nl_input',
  updateCreateLongNameFrInput: 'long_name_fr_input',
  updateCreateLongNameNlInput: 'long_name_nl_input',
  updateCreateNotApplicableCheckBox: 'not_applicable_checkbox',
  updateCreateDuplicableCheckBox: 'duplicable_checkbox',
  applyUpdateBtn: 'theme-applyUpdateBtn',
  cancelUpdateBtn: 'theme-cancelUpdateBtn',
  applyCreateAddBtn: 'theme-applyCreateAddBtn',
};
interface ThemeFormComponentProps {
  isOpenForm: boolean;
  handleClose: any;
  handleFormSubmit: any;
  handleCreateAddItem?: any;
  templateId: number;
  id?: number;
}
const ThemeFormComponent: React.FC<ThemeFormComponentProps> = ({
  isOpenForm,
  handleClose,
  handleFormSubmit,
  handleCreateAddItem,
  templateId,
  id
}: ThemeFormComponentProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formTheme: ThemeFormData = useReactiveVar(themeVar);
  const isCreateThemeForm = useReactiveVar(openCreateThemeVar);
  const { onCheckThemeUnique } = useCheckThemeUnique()


  const schema = useMemo(() => {
    return yup.object().shape({
      id: yup.number(),
      short_name_fr: yup.string().nullable().required(t("theme:shortNameFrError")),
      short_name_nl: yup.string().nullable().required(t("theme:shortNameNlError")),
      long_name_fr: yup.string().nullable().required(t('theme:longNameFrError')),
      long_name_nl: yup.string().nullable().required(t('theme:longNameNlError')),
      not_applicable_option: yup.boolean(),
      duplicable: yup.boolean(),
    });
  }, [t])

  const { register, errors, setValue, handleSubmit, setError, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const currentLocationState = useBrowserLocationState<{ template: any }>({ keysRequired: ["template"] })

  const template = currentLocationState?.template
    ? currentLocationState?.template?.name_fr +
    ' / ' +
    currentLocationState?.template?.name_nl
    : '';

  // Update form when api call finishes
  useEffect(() => {
    setValue('short_name_fr', formTheme.short_name_fr);
    setValue('short_name_nl', formTheme.short_name_nl);
    setValue('long_name_fr', formTheme.long_name_fr);
    setValue('long_name_nl', formTheme.long_name_nl);
    setValue('not_applicable_option', formTheme.not_applicable_option);
    setValue('duplicable', formTheme.duplicable);
  }, [formTheme, setValue]);

  // Register form inputs
  useEffect(() => {
    register({ name: 'short_name_fr' });
    register({ name: 'short_name_nl' });
    register({ name: 'long_name_fr' });
    register({ name: 'long_name_nl' });
    register({ name: 'not_applicable_option' });
    register({ name: 'duplicable' });
  }, [register]);

  const handleChange = event => {
    if (event.target.value || event.target.value === '') {
      themeVar({
        ...formTheme,
        [event.currentTarget.name]: event.currentTarget.value,
      });
      setValue(event.currentTarget.name, event.currentTarget.value as never);
    }
  };
  const handleCheckBoxChange = event => {
    if (event.target.checked) {
      setValue(event.target.name, event.currentTarget.checked as never);
    } else {
      setValue(event.target.name, event.currentTarget.value as never);
    }
    if (event.target.name === 'duplicable') {
      themeVar({
        ...formTheme,
        duplicable: !formTheme.duplicable,
      });
    } else {
      themeVar({
        ...formTheme,
        not_applicable_option: !formTheme.not_applicable_option,
      });
    }
  };

  const onSubmit = useCallback((data, process) => {
    data.id = id
    onCheckThemeUnique(data, { template_checklist_id: templateId }).then(errors_var => {
      if (errors_var) {
        Object.keys(errors_var).forEach(key => {
          setError(key, { type: "manual", message: errors_var[key] })
        });
      } else {
        process(data)
      }
    })
  }, [onCheckThemeUnique, setError, id, templateId])

  const handleFormClose = useCallback(
    () => {
      reset();
      handleClose();

    }, [handleClose, reset]

  )

  return (
    <Dialog
      open={isOpenForm}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return false;
        } else {
          handleClose()
        }
      }}
      fullWidth={true}
    >
      <DialogContent>
        <h1>
          {isCreateThemeForm
            ? t('theme:addThemeTemplate')
            : t('theme:editTheme')}
        </h1>
        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={3}
        >
          <Grid item className={classes.control}>
            <TextField
              data-testid={formDialogTestIds.updateCreateTemplateNameInput}
              name="template_name"
              aria-readonly={true}
              label={t('template:template')}
              variant="outlined"
              value={template}
              fullWidth
            />
          </Grid>
          <Grid item className={classes.control}>
            <TextField
              data-testid={formDialogTestIds.updateCreateLongNameFrInput}
              onChange={event => handleChange(event)}
              name="long_name_fr"
              label={t('theme:longNameFr')}
              variant="outlined"
              value={formTheme.long_name_fr}
              fullWidth
              error={errors.long_name_fr ? true : false}
              helperText={errors.long_name_fr && errors.long_name_fr.message}
            />
          </Grid>
          <Grid item className={classes.control}>
            <TextField
              data-testid={formDialogTestIds.updateCreateShortNameFrInput}
              name="short_name_fr"
              onChange={event => handleChange(event)}
              label={t('theme:shortNameFr')}
              variant="outlined"
              value={formTheme.short_name_fr}
              fullWidth
              error={errors.short_name_fr ? true : false}
              helperText={errors.short_name_fr && errors.short_name_fr.message}
            />
          </Grid>
          <Grid item className={classes.control}>
            <TextField
              data-testid={formDialogTestIds.updateCreateLongNameNlInput}
              onChange={event => handleChange(event)}
              name="long_name_nl"
              label={t('theme:longNameNl')}
              variant="outlined"
              value={formTheme.long_name_nl}
              fullWidth
              error={errors.long_name_nl ? true : false}
              helperText={errors.long_name_nl && errors.long_name_nl.message}
            />
          </Grid>
          <Grid item className={classes.control}>
            <TextField
              data-testid={formDialogTestIds.updateCreateShortNameNlInput}
              onChange={event => handleChange(event)}
              name="short_name_nl"
              label={t('theme:shortNameNl')}
              variant="outlined"
              value={formTheme.short_name_nl}
              fullWidth
              error={errors.short_name_nl ? true : false}
              helperText={errors.short_name_nl && errors.short_name_nl.message}
            />
          </Grid>

          <Grid item className={classes.control}>
            <div className={classes.divCheckBox}>
              <Checkbox
                data-testid={
                  formDialogTestIds.updateCreateNotApplicableCheckBox
                }
                onChange={event => handleCheckBoxChange(event)}
                checked={formTheme.not_applicable_option}
                name="not_applicable_option"
                classes={{
                  root: classes.checkbox,
                  checked: classes.checked,
                }}
              />
              <p>{t('theme:notApplicable')}</p>
            </div>
          </Grid>
          <Grid item className={classes.control}>
            <div className={classes.divCheckBox}>
              <Checkbox
                data-testid={formDialogTestIds.updateCreateDuplicableCheckBox}
                onChange={event => handleCheckBoxChange(event)}
                checked={formTheme.duplicable}
                name="duplicable"
                classes={{
                  root: classes.checkbox,
                  checked: classes.checked,
                }}
              />
              <p>{t('theme:duplicable')}</p>
            </div>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Box display="flex" width="100%">
          <ButtonGroup
            disableElevation
            orientation="vertical"
            variant="contained"
            aria-label="vertical"
            fullWidth
          >
            {handleCreateAddItem ? (
              <Button
                disableElevation
                className={classes.root}
                variant="contained"
                id="applyCreateAddBtn"
                data-testid={formDialogTestIds.applyCreateAddBtn}
                onClick={handleSubmit((data) => onSubmit(data, handleCreateAddItem))}
                fullWidth
              >
                {t('theme:validateAdd')}
              </Button>
            ) : (
              ''
            )}
            <Button
              fullWidth
              color="primary"
              id="applyUpdateBtn"
              data-testid={formDialogTestIds.applyUpdateBtn}
              onClick={handleSubmit((data => onSubmit(data, handleFormSubmit)))}
            >
              {t('common:validate')}
            </Button>
            <Button
              fullWidth
              id="cancelUpdateBtn"
              className={classes.grey}
              data-testid={formDialogTestIds.cancelUpdateBtn}
              onClick={() => handleFormClose()}
            >
              {t('common:cancel')}
            </Button>
          </ButtonGroup>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ThemeFormComponent;
