import {
    InMemoryCache,
    makeVar,
} from "@apollo/client";
import { Category } from "../Entity/EntityModel";
import { LocationFormData } from "./LocationFormModel"

export const openCreateLocationVar = makeVar(false);
export const openUpdateLocationVar = makeVar(false);
export const openLocationTableVar = makeVar(false);
export const locationLastIndexVar = makeVar(-1);
export const currentEntityId = makeVar(-1);
export const locationDeleteDoubleIdVar = makeVar(-1);
export const openConfirmUpdatDialogVar=makeVar(false);

export const defaultLocation: LocationFormData = {
    name: "",
    entity_id: -1,
    category_id: -1,
    specification: ""
};


export const categories: Category[] = []
export const locationVar = makeVar(defaultLocation);
export const categoryVar = makeVar(categories)
export const idEditLocationVar = makeVar(-1);


export const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                read() {
                    return (
                        openCreateLocationVar(),
                        openUpdateLocationVar(),
                        currentEntityId(),
                        openLocationTableVar(),
                        locationLastIndexVar(),
                        locationVar(),
                        categoryVar(),
                        locationDeleteDoubleIdVar(),
                        openConfirmUpdatDialogVar(),
                        idEditLocationVar()
                    )
                }
            }
        }
    }
})