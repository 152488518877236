import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface InfoDialogProps {
    dataTestId?: string;
    open: boolean;
    onClose: () => void;
    title: string;
    text: string;
}

/**
 * Description - Dialog to confirm action
 */
const InfoDialog: React.FC<InfoDialogProps> = ({
    dataTestId,
    open,
    onClose,
    title,
    text,
}) => {
    const { t } = useTranslation();

    return (
        <Dialog data-testid={dataTestId} open={open}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>                
                <Button onClick={onClose}>{t('common:close')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default InfoDialog;
