import React from 'react';
import { useChecklistReportPhotoPresenter } from '../ChecklistReportPhotoPresenter/ChecklistReportPhotoPresenter';
import ChecklistReportPhotoView from '../ChecklistReportPhotoView/ChecklistReportPhotoView';

export interface ChecklistReportPhotoComponentProps { audit_id?:number}

const ChecklistReportPhotoComponent: React.FC<ChecklistReportPhotoComponentProps> = (audit_id) => {
 
 const {loading,data,content}= useChecklistReportPhotoPresenter(audit_id);

  return <ChecklistReportPhotoView isLoading = { loading } photoOverviewDetails={data} contentData={content} />;
};

export default ChecklistReportPhotoComponent;
