import { gql } from '@apollo/client';

/**
 * Description - graphql query to get all entities
 */
export const READ_ALL_ENTITIES = gql`
  query qat_entity {
    qat_entity(
      order_by: { last_updated_date: desc }
      where: { deleted_date: { _is_null: true } }
    ) {
      id
      label
      abbreviation
      location_entities ( where: { deleted_date: { _is_null: true } }){
        id
        name
         specification
        category_location {
          id
          name_fr
          name_nl         
          sub_category_locations {
            id
            name_nl
            name_fr
          }
          category_location_form_config {
            category_location_form_field_configs {
              id
              name_nl
              name_fr
              category_location_form_config_id
              form_field_type
              is_required
            }
          }
        }
      }
    }
  }
`;

/**
 * Description - graphql query to get all sorted entities
 */
export const READ_ALL_ENTITIES_SORTED = gql`
  query qat_entity($order_by: qat_entity_order_by!) {
    qat_entity(
      order_by: [$order_by]
      where: { deleted_date: { _is_null: true } }
    ) {
      id
      label
      abbreviation
      location_entities( where: { deleted_date: { _is_null: true } }) {
        id
        name
         specification
        category_location {
          id
          name_fr
          name_nl         
          sub_category_locations {
            id
            name_nl
            name_fr
          }
          category_location_form_config {
            category_location_form_field_configs {
              id
              name_nl
              name_fr
              category_location_form_config_id
              form_field_type
              is_required
            }
          }
        }
      }
    }
  }
`;

/**
 * Description - graphql query to get a specific entity
 */
export const GET_ENTITY_BY_PK = gql`
  query qat_entity_by_pk($id: Int!) {
    qat_entity_by_pk(id: $id) {
      id
      label
      abbreviation
      location_entities( where: { deleted_date: { _is_null: true } }) {
        id
        name
          specification
        category_location {
          id
          name_fr
          name_nl        
          sub_category_locations {
            id
            name_nl
            name_fr
          }
          category_location_form_config {
            category_location_form_field_configs {
              id
              name_nl
              name_fr
              category_location_form_config_id
              form_field_type
              is_required
            }
          }
        }
      }
    }
  }
`;
