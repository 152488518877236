import React, { useCallback, useMemo } from 'react';
import { AuditFormData } from '../../AuditForm/AuditFormPresenter';
import AuditUpdateView from '../AuditUpdateView/AuditUpdateView';
import { useUpdateAudit } from '../../../../services/Audit/UpdateService/AuditUpdateService';
import { audit_data } from '../../../../services/Audit/AuditDataModel';
import { useBrowserLocationState } from '../../../Shared/BrowserLocationState/BrowserLocationState';

export interface AuditUpdateComponentProps { }

const AuditUpdateComponent: React.FC<AuditUpdateComponentProps> = () => {
  const { onUpdate } = useUpdateAudit();

  const currentLocationState = useBrowserLocationState<{audit: any}>({keysRequired: ["audit"]})

  const auditToUpdate: audit_data = useMemo(() => currentLocationState?.audit, [
    currentLocationState,
  ]);

  const handleUpdateAudit = useCallback(
    (_audit: AuditFormData): Promise<number> => {
      if (auditToUpdate) {
        const { audit_date, auditor_email } = _audit;

        const id = auditToUpdate.id;
        const entity_id = auditToUpdate.entity.id;
        const location_id = auditToUpdate.location_entity.id;

        return onUpdate(id, {
          entity_id,
          location_id,
          audit_date,
          auditor_email,
        });
      } else {
        console.error('location.state have not a audit data');
        return new Promise((resolve, reject) => {
          reject();
        });
      }
    },
    [onUpdate, auditToUpdate],
  );

  return (
    <AuditUpdateView
      audit={auditToUpdate}
      loading={!auditToUpdate}
      onUpdateAudit={handleUpdateAudit}
    />
  );
};

export default AuditUpdateComponent;
