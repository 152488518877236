export const adminDocument = {
    title: "Document",
    titleForm: "Administratief document",
    upload: "Upload",
    documentSelectInputLabel: "Te wijzigen document van de beheerder",
    fileUploadInputLabel: "Te uploaden bestand",
    selectFileButtonLabel: "Selecteer uw bestand",
    noFileUploadMessage: "Geen bestand om te uploaden",
    submit: "Uploaden",
    documentSelectInputValidationRequired: "U moet het te bewerken document selecteren",
    fileUploadInputValidationRequired: "U moet het te uploaden bestand selecteren",
    fileUploadInputValidationTypePdf: "Het bestandstype moet een pdf zijn",
    dateUpdated: "Laatst gewijzigd op ",
    noSelectDocumentMessage: "Er is geen bestand geselecteerd",
    noContentUpload: "Er zijn geen bestanden opgeslagen"
}