import React, { useMemo, useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import { ThemeFormData } from '../ThemeFormModel';
import { openUpdateThemeVar, themeVar } from '../ThemeCache';
import ThemeFormComponent from './ThemeFormDialogComponent';
import { useUpdateTheme } from '../../../services/Theme/ThemeWriteService/ThemeWriteService';
import { useBrowserLocationState } from '../../Shared/BrowserLocationState/BrowserLocationState';
export interface tableProps {
  openUpdateForm: boolean;
}

const ThemeUpdateDialogComponent = () => {

  const isOpenUpdateForm: boolean = useReactiveVar(openUpdateThemeVar);
  const updateTheme: ThemeFormData = useReactiveVar(themeVar);

  const currentLocationState = useBrowserLocationState<{template: any}>({keysRequired: ["template"]})

  const templateId: number = useMemo(
    () =>
      currentLocationState && currentLocationState.template
        ? currentLocationState.template.id
        : -1,
    [currentLocationState],
  );
  const { onUpdate } = useUpdateTheme({
    templateChecklistId: templateId,
    showNotification: true,
  });

  const defaultTheme: ThemeFormData = {
    id: -1,
    short_name_fr: '',
    short_name_nl: '',
    long_name_fr: '',
    long_name_nl: '',
    index: -1,
    not_applicable_option: false,
    duplicable: false,
  };

  const handleUpdateTheme = () => {
    let themeForm: ThemeFormData;
    themeForm = {
      id: updateTheme.id,
      short_name_fr: updateTheme.short_name_fr,
      short_name_nl: updateTheme.short_name_nl,
      long_name_fr: updateTheme.long_name_fr,
      long_name_nl: updateTheme.long_name_nl,
      not_applicable_option: updateTheme.not_applicable_option,
      duplicable: updateTheme.duplicable,
      index: updateTheme.index,
    };
    if (updateTheme.id) {
      onUpdate(themeForm);
      openUpdateThemeVar(false);
      themeVar(defaultTheme);
    }
  };

  const handleClose = useCallback(() => {
    openUpdateThemeVar(false);
    themeVar(defaultTheme);
  }, [defaultTheme]);

  return (
    <ThemeFormComponent
      isOpenForm={isOpenUpdateForm}
      handleClose={handleClose}
      handleFormSubmit={handleUpdateTheme}
      templateId={templateId}
      id={updateTheme.id}
    />
  );
};

export default ThemeUpdateDialogComponent;
