export const common = {
  validate: 'Valideren',
  generalError: 'Er heeft zich een technische fout voorgedaan',
  edit: 'Bewerken',
  delete: 'Verwijderen',
  confirmDelete: 'Weet u zeker dat u dit wilt verwijderen?',
  search: 'Zoeken',
  unauthorized: 'U heeft geen toegang',
  management: 'Beheer',
  savedSuccess: 'Succesvol opgeslagen',
  rowsPerPage: 'Rijen per pagina',
  update: 'Wijzigen',
  confirm: 'Bevestigen',
  cancel: 'Annuleren',
  navigation: 'Navigatie',
  readOnly: 'U heeft enkel leesrechten',
  order: 'Volgorde aanpassen',
  roleNotAuthorized: 'U mag niet naar deze pagina komen',
  roleNotAuthorizedBack: 'Terug naar de lijst van audits',
  report: 'Overzicht',
  saving: 'Bezig aan het opslaan (verlaat de pagina nog niet)',
  consEdt: 'Zien/Bew',
  close: 'Sluiten',
  titleDialogOffline: "Internetverbinding",
  textDialogOffline: "U hebt momenteel geen internetverbinding. Er zijn waarschijnlijk acties die u heeft gedaan die niet zijn opgeslagen."
};
