import { useCallback, useMemo } from 'react';
import { useUpdateChecklistListOfItem } from '../../../services/ChecklistItem/UpdateService/ChecklistItemUpdateService';
import { checkSectionsValidated } from '../../Checklist/checklist.utils';
import {
  get_checklist_by_pk_section_data,
  get_checklist_by_pk_sub_section_data,
} from '../../../services/Checklist/ChecklistReadService/ChecklistReadQuery';

export interface ChecklistThemeViewProps {
  form: any;
}

export interface ChecklistThemePresenterProps {
  section: get_checklist_by_pk_section_data;
  checklistId: number;
  themeNotApplicationValue: boolean;
  handleDuplicate: (section: any) => void
  handleDelete: (id) => void
}

export const useChecklistThemeSectionPresenter = ({
  section,
  checklistId,
  themeNotApplicationValue,
  handleDuplicate,
  handleDelete
}: ChecklistThemePresenterProps) => {

  const { onUpdateListOfItems } = useUpdateChecklistListOfItem(
    checklistId,
    false,
  );

  
  const handleConformChanged = (
    event,
    _section: get_checklist_by_pk_section_data,
  ) => {
    let listItemIdsToUpdate: number[] = [];
    Array.from(_section.checklist_theme_items).forEach(
      (checklist_item: any) => {
        listItemIdsToUpdate.push(checklist_item?.id);
      },
    );
    Array.from(_section?.checklist_theme_sub_sections).filter(
      _sub_section =>_sub_section.no_application === false
    ).forEach(
      (checklist_theme_sub_section: get_checklist_by_pk_sub_section_data) => {
        checklist_theme_sub_section.checklist_theme_items.forEach(
          checklist_item => {
            listItemIdsToUpdate.push(checklist_item?.id);
          },
        );
      },
    );
    if (listItemIdsToUpdate.length > 0) {
      onUpdateListOfItems(Array.from(listItemIdsToUpdate));
    }

    event.stopPropagation();
  };


  const disabledRemoveButton: boolean = useMemo(
    () =>
      section.specification && section.specification !== '' ? true : false,
    [section],
  );
  const colorNotApplicationButton:
    | 'error'
    | 'disabled' = themeNotApplicationValue ? 'error' : 'disabled'; // it is only about a color

  const onDuplicate = useCallback(() => {
    handleDuplicate(section)
  }, [handleDuplicate, section]);

  return {
    onDuplicate,
    onDelete: handleDelete,
    disabledRemoveButton,
    colorNotApplicationButton,
    onConformChanged: handleConformChanged,
    checkSectionValidated: checkSectionsValidated,
  };
};
