import gql from "graphql-tag";
import { InterviewDocumentFormData } from "../InterviewDocumentFormData";

export interface update_interview_document_variables {
    id: number;
    url: string;
}

export interface update_interview_document_response {
    update_qat_interview_document_one: {
        id: string
    }
}
export interface add_interview_document_variables {  
        object: InterviewDocumentFormData,
}

export interface add_interview_document_response {
    add_qat_interview_document_one: {
        id:string
    }
}

export const ADD_INTERVIEW_DOCUMENT = gql`
  mutation insert_qat_interview_document_one(
    $object: qat_interview_document_insert_input!
  ) {
    insert_qat_interview_document_one(
      object: $object
    ) {
        id
    }
  }
`;

  export const UPDATE_INTERVIEW_DOCUMENT = gql`
  mutation update_qat_interview_document(
      $id: Int!
      $url: String!
  ){
    update_qat_interview_document(where: {audit_id: {_eq: $id}}, _set: {url: $url}) {
        affected_rows
      }

  }
`
