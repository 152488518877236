export const theme = {
  editTheme: 'Thema wijzigen',
  theme: 'Thema',
  duplicable: 'Optie dupliceerbaar',
  notApplicable: 'Optie n.v.t',
  addThemeTemplate: 'Thema toevoegen',
  duplicateThemeTemplate: "Alle thema's kopiëren",
  createTheme: 'Thema aanmaken',
  shortNameFr: 'Korte naam (Frans)',
  shortNameNl: 'Korte naam (Nederlands)',
  longNameFr: 'Lange naam (Frans)',
  longNameNl: 'Lange naam (Nederlands)',
  shortName: 'Navigatieknop', // short name is for navigation
  longName: 'Titel pagina', // long name is title for page or section in checklist
  shortNameFrError: 'Korte naam (Frans) is een verplicht veld',
  shortNameNlError: 'Korte naam (Nederlands) is een verplicht veld',
  longNameFrError: 'Lange naam (Frans) is een verplicht veld',
  longNameNlError: 'Lange naam (Nederlands) is een verplicht veld',
  item: 'Item',
  validateAdd: 'Een ander subthema toevoegen',
  themeTableTitle: 'Template " {{0}}"',
  shortNameFrUniqueError: "Er is al een korte naam met deze Franse tekst",
  shortNameNlUniqueError: "Er is al een korte naam met deze Nederlandse tekst",
  longNameFrUniqueError: "Er is al een lange naam met deze Franse tekst",
  longNameNlUniqueError: "Er is al een lange naam met deze Nederlandse tekst",
};
