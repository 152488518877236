import React, {
    useMemo,
    useCallback,
    useState,
    useEffect,
} from "react";
import { useReactiveVar } from "@apollo/client";
import { LocationFormData } from "../LocationFormModel";
import {
    openUpdateLocationVar,
    locationVar,
    openConfirmUpdatDialogVar,
    idEditLocationVar
} from "../LocationCache";
import { useUpdateLocation } from "../../../services/Location/UpdateService/LocationUpdateService"
import LocationFormComponent from "./LocationFormComponent";
import { Entity } from "../../Entity/EntityModel";
import { useGetAuditByEntityIdAndLocationId } from "../../../services/Location/ReadService/LocationReadService";
import { useGetAddressByLocationEntityId } from "../../../services/Address/ReadService/AddressReadService";
import { useBrowserLocationState } from "../../Shared/BrowserLocationState/BrowserLocationState";

export interface tableProps {
    openUpdateForm: boolean;
}

const LocationUpdateDialogComponent = () => {

    const currentLocationState = useBrowserLocationState<{entity: any}>({keysRequired: ["entity"]})	

    const entity: Entity = useMemo(
        () => (
            currentLocationState && currentLocationState.entity
                ? currentLocationState.entity
                : null
        ),
        [currentLocationState],
    );
    const isOpenUpdateForm: boolean = useReactiveVar(openUpdateLocationVar);
    const updateLocation: LocationFormData = useReactiveVar(locationVar);
    const entityId = entity ? entity.id : -1;
    const locationId = updateLocation && updateLocation.id ? updateLocation.id : -1;


    const { onUpdate } = useUpdateLocation(entityId);
    const { data } = useGetAuditByEntityIdAndLocationId(entityId, locationId)
    const { data: addresses } = useGetAddressByLocationEntityId(locationId)
    const [auditId, setAuditId] = useState<number>();
    const [isAddress, setIsAddress] = useState<boolean>(false);
    const [confirmBoxText, setConfirmBoxText] = useState<string>("");

    useEffect(() => {
        if (data && data.length > 0 && locationId !== -1 && entityId !== -1) {
            setAuditId(data[0].id)

        }
        else {
            setAuditId(-1);
        }
        if (addresses && addresses.length > 0) {
            setIsAddress(true)
        } else {
            setIsAddress(false)
        }
    }, [locationId, data, entityId, addresses]);

    const defaultLocation: LocationFormData = {
        name: "",
        category_id: -1,
        entity_id: -1,
        specification: ""
    };

    const handleConfirmedToUpdate = useCallback(
        () => {

            if (locationId !== -1) {
                onUpdate(locationId, locationVar());
                openConfirmUpdatDialogVar(false)
                locationVar(defaultLocation);
                idEditLocationVar(-1)
            } else {
                throw new Error("The location id to Update doesn't exist");
            }
        },
        [locationId, onUpdate, defaultLocation],
    )


    const handleUpdateLocation = () => {
        let locationForm: LocationFormData;

        locationForm = {
            ...updateLocation,
            name: updateLocation.name,
            category_id: updateLocation.category_id,
            specification: updateLocation.specification,
            entity_id: updateLocation.entity_id
        };

        if ((auditId !== -1 || isAddress) && locationId) {
            let textConfirm = auditId !== -1 ? "confirmAuditText" : ""
            textConfirm = isAddress ? textConfirm + "confirmAddressText" : textConfirm
            setConfirmBoxText(textConfirm)
            openConfirmUpdatDialogVar(true)

        }
        else if (locationId) {
            onUpdate(locationId, locationForm);
            locationVar(defaultLocation);
            idEditLocationVar(-1)
        } else {
            throw new Error(" the update of location was not successful !!")
        }
        openUpdateLocationVar(false);

    };

    const handleClose = useCallback(
        () => {
            openUpdateLocationVar(false);
            locationVar(defaultLocation);
            idEditLocationVar(-1)
        },
        [defaultLocation],
    );

    return (
        <div>
            <LocationFormComponent
                isOpenForm={isOpenUpdateForm}
                handleClose={handleClose}
                handleFormSubmit={handleUpdateLocation}
                handleConfirmedToUpdate={handleConfirmedToUpdate}
                textConfirm={confirmBoxText}
            />
        </div>
    );
};

export default LocationUpdateDialogComponent;