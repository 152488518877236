import React, { Fragment, useContext } from 'react';
import routes from '../../routes';
import AppContext from '../../AppContext';
import { LinearProgress } from '@material-ui/core';
import { NotificationComponent } from '../Notification';
import EntityComponent from '../Entity/EntityComponent';
import { Switch, Route } from 'react-router-dom';
import AuditComponent from '../Audit/AuditComponent/AuditComponent';
import TemplateChecklistComponent from '../TemplateChecklist/TemplateChecklistComponent';
import ThemeComponent from '../Theme/ThemeComponent';
import SubThemeComponent from '../SubTheme/SubThemeComponent';
import NotAuthorizationPageComponent from '../Authorization/NotAuthorizationPageComponent';
import { Rule } from '../Authorization/AuthorizationComponent/Permissions';
import AuthorizationRouteComponent from '../Authorization/AuthorizationRouteComponent';
import ItemComponent from '../Item/ItemComponent';
import ItemRemarkComponent from '../Item/Remark/ItemRemarkComponent';
import ChecklistComponent from '../Checklist/ChecklistComponent';
import ChecklistRemarkComponent from '../ChecklistRemark/ChecklistRemarkComponent';
import { NormComponent } from '../Norm';
import LocationComponent from '../Location/LocationComponent';
import LocationAddressComponent from '../Location/LocationAddress/LocationAddressComponent';
import TakeImageComponent from '../ChecklistAuditPhoto/ChecklistAuditPhotoCaptureComponent';

import AdminDocumentUpload from '../AdminDocument/AdminDocumentUpload/AdminDocumentUpload';
import ChecklistDocumentUpload from '../Audit/ChecklistDocument/ChecklistDocumentUpload/ChecklistDocumentUpload';
import InterviewDocumentUpload from '../Audit/InterviewDocument/InterviewDocumentUpload/InterviewDocumentUpload';

/**
 * This component is responsible of routing all main views (except navigation views)
 */
const MainAppComponent = () => {
  const { loadingUser, notification } = useContext(AppContext);

  return loadingUser ? (
    <LinearProgress color="secondary" />
  ) : (
    <Fragment>
      <Switch>
        <Route path={routes.audit.path}>
          <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_AUDIT_READ]}>
            <AuditComponent />
          </AuthorizationRouteComponent>
        </Route>
        <Route path={routes.template.path}>
          <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_TEMPLATE_CRUD]}>
            <TemplateChecklistComponent />
          </AuthorizationRouteComponent>
        </Route>
        <Route path={routes.normesMinimal.path}>
          <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_NORME]}>
            <NormComponent />
          </AuthorizationRouteComponent>
        </Route>
        <Route path={routes.entity.path}>
          <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_ENTITY_CRUD]}>
            <EntityComponent />
          </AuthorizationRouteComponent>
        </Route>
        <Route path={routes.allLocations.path}>
          <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_ENTITY_CRUD]}>
            <LocationComponent />
          </AuthorizationRouteComponent>
        </Route>

        <Route path={routes.createLocation.path}>
          <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_ENTITY_CRUD]}>
            <LocationComponent />
          </AuthorizationRouteComponent>
        </Route>

        <Route path={routes.allLocationAddress.path}>
          <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_ENTITY_CRUD]}>
            <LocationAddressComponent />
          </AuthorizationRouteComponent>
        </Route>
        <Route path={routes.createLocationAddress.path}>
          <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_ENTITY_CRUD]}>
            <LocationAddressComponent />
          </AuthorizationRouteComponent>
        </Route>

        <Route path={routes.themeTemplate.path}>
          <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_THEME_CRUD]}>
            <ThemeComponent />
          </AuthorizationRouteComponent>
        </Route>
        <Route path={routes.subThemeTemplate.path}>
          <SubThemeComponent />
        </Route>
        <Route path={routes.editChecklist.path}>
          <ChecklistComponent />
        </Route>
        <Route path={routes.allItems.path}>
          <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_ITEM_CRUD]}>
            <ItemComponent />
          </AuthorizationRouteComponent>
        </Route>
        <Route path={routes.createItem.path}>
          <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_ITEM_CRUD]}>
            <ItemComponent />
          </AuthorizationRouteComponent>
        </Route>
        <Route path={routes.allItemRemarks.path}>
          <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_ITEM_CRUD]}>
            <ItemRemarkComponent />
          </AuthorizationRouteComponent>
        </Route>
        <Route path={routes.createItemRemark.path}>
          <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_ITEM_CRUD]}>
            <ItemRemarkComponent />
          </AuthorizationRouteComponent>
        </Route>
        <Route path={routes.checklistRemarks.path}>
          <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_CHECKLIST_REMARK_MENU]}>
            <ChecklistRemarkComponent />
          </AuthorizationRouteComponent>
        </Route>
        <Route path={routes.uploadDocument.path}>
          <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_UPLOAD_ADMIN_DOCUMENT]}>
            <AdminDocumentUpload />
          </AuthorizationRouteComponent>
        </Route>
        <Route path={routes.notAuthorized.path}>
          <NotAuthorizationPageComponent />
        </Route>
        <Route path={routes.cameraPhoto.path}>
          <TakeImageComponent />
        </Route>
        <Route path={routes.checklistUploadDocument.path}>
          <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_UPLOAD_CHECKLIST_DOCUMENT]}>
            <ChecklistDocumentUpload />
          </AuthorizationRouteComponent>
        </Route>
        <Route path={routes.interviewUploadDocument.path}>
          <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_UPLOAD_INTERVIEW_DOCUMENT]}>
            <InterviewDocumentUpload />
          </AuthorizationRouteComponent>
        </Route>
      </Switch>
      <NotificationComponent notification={notification} />
    </Fragment>
  );
};

export default MainAppComponent;
