import { gql } from '@apollo/client';

/**
 * Description - graphql query to create an entity
 */
export const INSERT_ENTITY = gql`
  mutation insert_qat_entity_one($object: qat_entity_insert_input!) {
    insert_qat_entity_one(
      object: $object
      on_conflict: {
        constraint: entity_label_abbreviation_key
        update_columns: [deleted_date]
      }
    ) {
      id
      label
      abbreviation
    }
  }
`;
