import { useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { BuildingFormData } from '../../../components/Building/BuildingForm/BuildingFormPresenter';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { isContraintViolationError } from '../../../graphql/utils';
import { GET_BUILDING_BY_AUDIT } from '../ReadService/BuildingReadQuery';
import { useIsUniqueBuildingAuditIdAddressId } from '../ReadService/BuildingReadService';
import { INSERT_BUILDING } from './BuildingWriteMutation';
export interface BuildingCreateFormData {
  address_id: number;
  sub_category_location_id: number;
}

export const useCreateBuilding = ({ audit_id }) => {
  const { setNotification } = useContext(MainAppContext);

  const {
    isUniqueBuildingAuditIdAddressId,
  } = useIsUniqueBuildingAuditIdAddressId();

  const [createBuilding] = useMutation(INSERT_BUILDING, {
    refetchQueries: [{ query: GET_BUILDING_BY_AUDIT, variables: { audit_id } }],
    awaitRefetchQueries: true,
    onCompleted({ createdBuilding }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      if (isContraintViolationError(error))
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      else
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
    },
  });

  const onCreate = useCallback(
    async (building: BuildingFormData) => {
      return new Promise(resolve => {
        if (building.audit_id && building.address_id) {
          const address_id: number =
            typeof building.address_id === 'string'
              ? parseInt(building.address_id)
              : building.address_id;
          isUniqueBuildingAuditIdAddressId({ audit_id, address_id }).then(
            isUnique => {
              if (isUnique) {
                createBuilding({
                  variables: {
                    object: {
                      building: {
                        data: {
                          address_id: building.address_id,
                          sub_category_location_id:
                            building.sub_category_location_id,
                          building_field_values: {
                            data: building.building_field_values,
                          },
                          index: building?.index,
                        },
                      },
                      audit_id: building.audit_id,
                    },
                  },
                }).then(result => resolve(result));
              } else {
                setNotification({
                  key: 'building:uniqueBuildingErrorMessage',
                  type: NotificationType.WARNING,
                });
              }
            },
          );
        } else {
          console.error('audit_id and address_id properties is required');
        }
      });
    },
    [createBuilding, setNotification, isUniqueBuildingAuditIdAddressId, audit_id],
  );

  return { onCreate };
};
