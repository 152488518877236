import { useCallback, useContext } from 'react';
import MainAppContext from '../../../../../AppContext';

export interface UseUserMenuHookResult {
  logout: () => void;
}

/**
 * This hook contains all logic for UserMenuComponent
 * @returns logout: responsible to logout the current user
 */
const useUserMenuService = (): UseUserMenuHookResult => {
  const { logOut } = useContext(MainAppContext);

  const logout = useCallback(async () => {
    logOut();
  }, [logOut]);

  return {
    logout,
  };
};

export default useUserMenuService;
