import composeHooks from 'react-hooks-compose';
import { useEntityComponentService } from './EntityComponentService';
import EntityView from './EntityView';

/**
 * Description - Component to manage entity,
 */
const EntityComponent = composeHooks({ useEntityComponentService })(EntityView);

export default EntityComponent;
