import { CircularProgress, Typography, IconButton } from '@material-ui/core';
import React, { Fragment } from 'react';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';

export const testIds = {
  logout: 'logout-icon',
};

/**
 * This component is responsible to display the username and role of the current logged in user
 * This component also contains a logout button to exit your current authentication session
 * The username and role are taken from the MainAppContext
 */
const UserMenuView = ({ classes, context, service }) => {
  return context?.loadingUser ? (
    <CircularProgress color="secondary" />
  ) : (
    <Fragment>
      <PersonIcon fontSize="large" className={classes?.personIcon} />
      <div className={classes?.userContainer}>
        <Typography className={classes?.userInfo} variant="subtitle2">
          <strong>{context?.username}</strong>
        </Typography>
        <Typography className={classes?.userInfo} variant="caption">
          {context?.role}
        </Typography>
      </div>
      <IconButton
        onClick={() => service?.logout()}
        data-testid={testIds.logout}
      >
        <ExitToAppIcon fontSize="large" className={classes?.exitToAppIcon} />
      </IconButton>
    </Fragment>
  );
};

export default UserMenuView;
