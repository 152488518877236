export const adminDocument = {
    title: "Document",
    upload: "Upload",
    titleForm: "Document administratif",
    documentSelectInputLabel: "Document administrateur à modifier",
    fileUploadInputLabel: "Fichier à télécharger",
    selectFileButtonLabel: "Selectionner votre fichier",
    noFileUploadMessage: "Pas de fichier téléchargé",
    submit: "Télécharger",
    documentSelectInputValidationRequired: "Vous devez selectionner le document pour le modifier",
    fileUploadInputValidationRequired: "Vous devez selectionner le fichier à uploader",
    fileUploadInputValidationTypePdf: "Le type de fichier doit être un pdf",
    dateUpdated: "Dernière modification le ",
    noSelectDocumentMessage: "Aucun fichier n'a été sélectionné",
    noContentUpload: "Aucun fichier n'a été enregistré"
}