import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  personIcon: {
    color: '#4f5f6f',
  },
  exitToAppIcon: {
    color: '#4f5f6f',
    marginRight: theme.spacing(1),
  },
  userContainer: {
    paddingLeft: theme.spacing(1),
  },
  userInfo: {
    lineHeight: 0.7,
    paddingTop: 7,
    color: '#4f5f6f',
  },
}));

export { useStyles };
