import React from 'react';
import { useCreateEntity } from '../../../../services/Entity/WriteService/EntityWriteService';
import EntityFormComponent from '../../EntityForm/EntityFormComponent';

/**
 * Description - Component to create entity
 */
const EntityCreateComponent = () => {
  const { onCreate } = useCreateEntity();

  return (
    <EntityFormComponent
      title="entity:createEntity"
      submitButtonLabel="common:validate"
      onSubmit={onCreate}
    />
  );
};

export default EntityCreateComponent;
