import { common } from './common';
import { address } from './address';
import { audit } from './audit';
import { building } from './building';
import { entity } from './entity';
import { item } from './item';
import { location } from './location';
import { normes } from './normes';
import { profile } from './profile';
import { subcategory } from './subcategory';
import { template } from './template';
import { theme } from './theme';
import { subtheme } from './subtheme';
import { checklist } from './checklist';
import { checklist_theme_section } from './checklist_theme_section';
import { item_remark } from './item_remark';
import { checklist_theme } from './checklist_theme';
import { report } from './report';
import { checklist_remark } from './checklist_remark';
import {adminDocument} from './adminDocument'
import {checklistDocument} from './checklistDocument'
import {interviewDocument} from './interviewDocument'

export const translation: any = {
  translation: {
    common: common,
    address: address,
    building: building,
    entity: entity,
    item: item,
    location: location,
    normes: normes,
    profile: profile,
    subcategory: subcategory,
    template: template,
    theme: theme,
    subtheme: subtheme,
    audit: audit,
    checklist: checklist,
    checklist_theme_section: checklist_theme_section,
    item_remark: item_remark,
    checklist_theme: checklist_theme,
    report: report,
    checklist_remark: checklist_remark,
    adminDocument: adminDocument,
    checklistDocument: checklistDocument,
    interviewDocument: interviewDocument
  },
};
