import React from 'react';
import { useGetAllTemplateChecklist } from '../../../../services/TemplateChecklist/TemplateChecklistReadService/TemplateChecklistReadService';
import { useTemplateChecklistReadPresenter } from '../TemplateChecklistReadPresenter';
import TemplateChecklistReadView from '../TemplateCheckListReadView';

export interface TemplateCheckListReadComponentProps {
  onDelete: (id) => void;
  onUpdate: (id: number) => void;
}

const TemplateCheckListReadComponent = ({ onDelete, onUpdate }) => {
  const { data, loading } = useGetAllTemplateChecklist();
  const {
    templates,
    onConfirmedToDelete,
    openDeleteConfirmDialog,
    handleDeleteButtonClick,
    onCloseDeleteConfirmDialog,
    filterTemplateChecklist,
  } = useTemplateChecklistReadPresenter({ templates: data, onDelete });

  return (
    <TemplateChecklistReadView
      templates={templates}
      loading={loading}
      onConfirmedToDelete={onConfirmedToDelete}
      openDeleteConfirmDialog={openDeleteConfirmDialog}
      handleDeleteButtonClick={handleDeleteButtonClick}
      onCloseDeleteConfirmDialog={onCloseDeleteConfirmDialog}
      filterTemplateChecklist={filterTemplateChecklist}
      onUpdate={onUpdate}
    />
  );
};

export default TemplateCheckListReadComponent;
