import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
    control: {
        width: '100%',
    },
    title: {
        fontSize: '0.875rem',
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    labelInputUploadFile: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(5),
    },
    containerInputUploadFile: {
        display: "flex"
    },
    fileName: {
        marginLeft: theme.spacing(2),
        paddingTop: theme.spacing(1)
    },
    error: {
        color: theme.palette.error.main
    },
    buttonUploadFile: {
        color: 'white',
        backgroundColor: theme.palette.success.main,
        width: 250
    },
    buttonSubmit: {
        width: '100%',
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        "&:hover": {
            backgroundColor: theme.palette.primary.dark
        }
    },
    InterviewDocTitle: {
        fontSize: '0.875rem',
        fontFamily: 'Poppins',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
}));

export { useStyles };