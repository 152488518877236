import { useCallback, useEffect, useMemo, useState, useContext } from 'react';
import {
  useSetNotApplicationChecklistThemeFalse,
  useSetNotApplicationChecklistThemeTrue,
  useUpdateChecklistTheme,
} from '../../../services/ChecklistTheme/ChecklistThemeWriteService';
import { debounce } from 'lodash';
import { useBeforeunload } from 'react-beforeunload';
import AppContext from '../../../AppContext';
import { NotificationType } from '../../Notification/NotificationComponent';
import { useDeleteChecklistThemeSection, useDuplicateChecklistThemeSection } from '../../../services/ChecklistThemeSection/ChecklistThemeSectionWriteService';
import { get_checklist_by_pk_theme_data } from '../../../services/Checklist/ChecklistReadService/ChecklistReadQuery';
import { useGetChecklistById } from '../../../services/Checklist/ChecklistReadService/ChecklistReadService';

export interface ChecklistThemeViewProps {
  form: any;
}

export interface SectionOpenState {
  id: number;
  isOpen: boolean;
}

export interface SectionsOpenState {
  [key: string] : SectionOpenState
}

export interface ChecklistThemePresenterProps {
  theme: get_checklist_by_pk_theme_data;
  checklistId: number;
}
export const useChecklistThemePresenter = ({
  theme,
  checklistId,
}: ChecklistThemePresenterProps) => {
  const { setNotification } = useContext(AppContext);
  const [comment, setComment] = useState(theme?.comment);
  const [isSaving, setIsSaving] = useState<boolean>(false);
   const { data } = useGetChecklistById(checklistId)
 // const [newSelectedTheme, setNewSelectedTheme] = useState(data?.qat_checklist_by_pk?.checklist_themes?.find(th => th.id === theme.id) ? data.qat_checklist_by_pk.checklist_themes?.find(th => th.id === theme.id) : theme);
 const newSelectedTheme = data?.qat_checklist_by_pk?.checklist_themes?.find(th => th.id === theme.id) ? data.qat_checklist_by_pk.checklist_themes?.find(th => th.id === theme.id):theme;


  const themeNotApplicationValue: boolean = useMemo(
    () => (newSelectedTheme?.not_applicable ? newSelectedTheme?.not_applicable : false),
    [newSelectedTheme],
  );

   //const newSelectedTheme = data?.qat_checklist_by_pk?.checklist_themes?.find(th => th.id === theme.id) ? data.qat_checklist_by_pk.checklist_themes?.find(th => th.id === theme.id):theme;


  const [sections, setSections] = useState<SectionsOpenState>(() => {
    const newSectionsOpenState: SectionsOpenState = {}
newSelectedTheme && newSelectedTheme.checklist_theme_sections.forEach(section => {
      if(section.id){
        if (newSelectedTheme.duplicate_section_number !== 1){
          newSectionsOpenState[section.id] = {id: section.id, isOpen: false}
        }else{
        newSectionsOpenState[section.id] = {id: section.id, isOpen: themeNotApplicationValue ? false : true}
        }
      }else {
        console.error("property id in section is required")
      }
    })
    return newSectionsOpenState
  });

  const { onCreate } = useDuplicateChecklistThemeSection({ checklistId });
  const { onDelete } = useDeleteChecklistThemeSection({ checklistId });

  const [
    openConfirmNotApplication,
    setOpenConfirmNotApplication,
  ] = useState<boolean>(false);

  const onChangeSectionOpen = useCallback((id: number) => {
    setSections((currentSections) => {
      const newSectionsStateOpen = {...currentSections, [id]: {id, isOpen: themeNotApplicationValue ? false : !currentSections[id].isOpen}}
      return newSectionsStateOpen
    })
  },[themeNotApplicationValue])

  const handleDelete = useCallback((id: number) => {
    if (id) {
      onDelete(id).then(() => {
        setSections((currentSections) => {
          const {[id]: deleteSection, ...restSections} = currentSections
          return restSections
        });
      });
    }
    
  }, [onDelete]);

  const handleDuplicate = useCallback((section) => {
    onCreate({ data: section }).then(response => {
      
      setSections((currentSections) => {
        const newSectionsOpenState = {} 
        Object.keys(currentSections).forEach((key) => {
          return newSectionsOpenState[key] = {id: key, isOpen: false}
        })
        if(response.data.insert_qat_checklist_theme_section_one.id){
          newSectionsOpenState[response.data.insert_qat_checklist_theme_section_one.id] = {id: response.data.insert_qat_checklist_theme_section_one.id, isOpen: true }
        } else {
          console.error("property id is required in section")
        }
        return newSectionsOpenState
      })
    });
  }, [onCreate]);

  useEffect(() => {
    if (newSelectedTheme){
        setComment(newSelectedTheme?.comment);}
  
  }, [newSelectedTheme, checklistId]);


  const { onUpdate: updateChecklistTheme } = useUpdateChecklistTheme(
    checklistId,
  );
  const { onSetNotApplicationFalse } = useSetNotApplicationChecklistThemeFalse({
    checklist_id: checklistId,
  });

  const { onSetNotApplicationTrue } = useSetNotApplicationChecklistThemeTrue({
    checklist_id: checklistId,
  });

  const debouncedSave = useCallback(
    debounce(
      (nextValue, themeId) =>
        updateChecklistTheme(themeId, { comment: nextValue })?.then(
          response => {
            setNotification({
              key: 'common:savedSuccess',
              type: NotificationType.SUCCESS,
            });
            handleSetState(false);
          },
        ),
      1000,
    ),
    [],
  );

  const handleSetState = useCallback(isSavingVar => {
    setIsSaving(isSavingVar);
  }, []);

  useBeforeunload(event => {
    if (isSaving === true) {
      event.preventDefault();
      setNotification({ key: 'common:saving', type: NotificationType.WARNING });
      return null;
    }
  });

  const handleUpdateNotApplication = useCallback(() => {
    // confirm not application value if user want to set true as value
    if (!themeNotApplicationValue) {
      setOpenConfirmNotApplication(true);
    } else {
      onSetNotApplicationFalse({ id: newSelectedTheme?.id });
      setSections((currentSectionsStateOpen) => {
        const newSectionsOpenState: SectionsOpenState = {}
     newSelectedTheme && newSelectedTheme.checklist_theme_sections.forEach(({id}) => {
          if(id){
            newSectionsOpenState[id] = {id, isOpen: true}
          } else {
            console.error("property id in section is required")
          }
        })
      
        return newSectionsOpenState
      })

    }
  }, [onSetNotApplicationFalse, themeNotApplicationValue, newSelectedTheme]);

  const onConfirmUpdateNotApplication = useCallback(() => {
    setOpenConfirmNotApplication(false);
    if ( newSelectedTheme?.checklist_theme_sections[0].theme_template_checklist) {
      onSetNotApplicationTrue({
       theme: newSelectedTheme,
      }).then((idNewCleanSection) => {
        if(idNewCleanSection){
          setSections(() => {
            const newSectionsOpenState: SectionsOpenState = {}
            newSectionsOpenState[idNewCleanSection] = {id: idNewCleanSection, isOpen: false }
            return newSectionsOpenState
          })
        }
      });
    } else {
      console.error(
        'theme_template_checklist from theme.checkist_theme_section is required',
      );
    }
  }, [newSelectedTheme, onSetNotApplicationTrue]);

  const handleChangeComment = event => {
    handleSetState(true);
    const { value: nextValue } = event.target;
    setComment(nextValue);
    debouncedSave(nextValue, theme?.id);
  };

  const onCloseConfirmNotApplication = useCallback(() => {
    setOpenConfirmNotApplication(false);
  }, []);

  return {
    comment,
    onChangeComment: handleChangeComment,
    checklistId,
    onConfirmUpdateNotApplication,
    themeNotApplicationValue,
    onUpdateNotApplication: handleUpdateNotApplication,
    openConfirmNotApplication,
    onCloseConfirmNotApplication,
    onChangeSectionOpen,
    sections,
    handleDuplicate,
    handleDelete,  
    newSelectedTheme 
  };
};
