import React, { useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { Redirect } from 'react-router-dom';
import { isAllowed } from '../AuthorizationComponent/Auth';
import routes from '../../../routes';

export interface AuthorizationRouteComponentProps {
  children: any;
  rules: string[];
  email?: string;
}

/**
 * This component is responsible to check if a user role has access to a given component (children),
 * either the component redirect in page to inform a user that is not authorized
 * @param rules the rules that need to be authorized
 * @param children the component(s) inside AuthorizationComponent
 */
const AuthorizationRouteComponent: React.FC<AuthorizationRouteComponentProps> = ({
  children,
  rules,
  email,
}) => {
  const { role, email: currentUserEmail } = useContext(MainAppContext);

  return isAllowed(rules, role) &&
    (!email || (email && currentUserEmail === email)) ? (
    children
  ) : (
    <Redirect to={routes.notAuthorized.path} />
  );
};

export default AuthorizationRouteComponent;
