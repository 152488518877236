import { useCallback, useState } from "react"
import { useAddChecklistDocument, useUpdateChecklistDocument } from "../../../../services/ChecklistDocument/ChecklistDocumentWrite/ChecklistDocumentWrite"
import { useHistory } from 'react-router-dom';
import routes from "../../../../routes";

export type ChecklistDocumentUploadFormInputs = {
  file: File | null
}

export type ChecklistDocumentUploadFormKeys = "file" 

export type ChecklistDocumentUploadErrorsValidation = {
  file?: "required" | "type-pdf"
} | undefined

export const validateChecklistDocumentUpload = (formInputs: ChecklistDocumentUploadFormInputs): ChecklistDocumentUploadErrorsValidation | void => {
  const errors: ChecklistDocumentUploadErrorsValidation = {}

  const { file } = formInputs
  if (file === null) {
    errors.file = "required"
  } else if (file.type !== "application/pdf") {
    errors.file = "type-pdf"
  }

  if (Object.keys(errors).length) {
    return errors
  }
}

export const useChecklistDocumentUploadService = (building, audit, isReadonly, isNewDoc) => {
  const buildingId = building?.id
  const history = useHistory();
  const [inputValues, setInputValues] = useState<ChecklistDocumentUploadFormInputs>({ file: null })
  const [errors, setErrors] = useState<ChecklistDocumentUploadErrorsValidation>()
  const { onUpdateChecklistDocument } = useUpdateChecklistDocument(building, audit)
  const { onAddChecklistDocument } = useAddChecklistDocument(building, audit)
  

  const resetErrors = useCallback((key: ChecklistDocumentUploadFormKeys) => {
    setErrors(current => {
      return { ...current, [key]: undefined }
    })
  }, [])

  const handleChange = (key: ChecklistDocumentUploadFormKeys) => (event) => {
    if (key === "file"){
      setInputValues(current => ({ ...current, [key]: event.target.files[0] }))
    }else{
      console.error(`${key} doesn't exist in form input model`)
    }
    resetErrors(key)
  }

  const handleSubmit = useCallback((event) => {
    event.preventDefault()
    const submitErrorsValidation = validateChecklistDocumentUpload(inputValues)
    if (submitErrorsValidation) {
      setErrors(submitErrorsValidation)
    } else {
      const { file } = inputValues
      if (buildingId && file) {
        if (isNewDoc) {
          onAddChecklistDocument(buildingId, file, file.name).then(response => {
            history.push({
              pathname: `${routes.editAudit.path}${routes.editAuditMainInfo.path}`,
              state: { audit: audit, isReadonly: isReadonly },
            });
          });
        } else {
          onUpdateChecklistDocument(buildingId, file, file.name, building).then(response => {
            history.push({
              pathname: `${routes.editAudit.path}${routes.editAuditMainInfo.path}`,
              state: { audit: audit, isReadonly: isReadonly },
            });
          });
        }
      }
    }
  }, [onUpdateChecklistDocument, inputValues, buildingId, audit, history, isReadonly, isNewDoc, onAddChecklistDocument, building])

  return { values: inputValues, errors, onChange: handleChange, onSubmit: handleSubmit }
}
