import React, { useState, useEffect } from 'react';
import { Paper, InputBase } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation } from 'react-i18next';
import { useStyles } from './SearchFieldStyle';
export interface SearchFieldProps {
  onChange: (filter: string) => void;
  placeholderTranslation?: string;
}

/**
 * Description - UI Component to return search field
 */
const SearchField: React.FC<SearchFieldProps> = ({
  onChange,
  placeholderTranslation,
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const classes = useStyles();
  const { t } = useTranslation();

  const handleChange = event => {
    setInputValue(event.target.value);
  };

  const clearSearchInput = () => {
    setInputValue('');
  };

  useEffect(() => {
    onChange(inputValue);
  }, [inputValue, onChange]);

  return (
    <Paper component="form" className={classes.root}>
      <SearchIcon />
      <InputBase
        className={classes.input}
        placeholder={t(placeholderTranslation || 'common:search')}
        inputProps={{ 'aria-label': 'search' }}
        onChange={handleChange}
        value={inputValue}
      />
      <IconButton
        role="button"
        className={classes.iconButton}
        aria-label="search"
        onClick={clearSearchInput}
      >
        <ClearIcon />
      </IconButton>
    </Paper>
  );
};

export default SearchField;
