import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import ClientProvider from './graphql/ClientProvider';
import i18n from './config/translation/i18n';
import { I18nextProvider } from 'react-i18next';
import { createTheme, ThemeProvider } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from './App'
import NetworkListener from './components/NetworkListener/NetworkListener';

const theme = createTheme({
  palette: {
    primary: {
      main: '#cf012c',
    },
    secondary: {
      main: '#293D46',
    },
  },
  typography: {
    fontFamily: 'Poppins',
  },
  overrides: {
    MuiButton: {
      contained: {
        boxShadow: 'none',
      }
    }
  }
});

theme.props = {
  MuiButton: { // `MuiButton` is the global class name for the <Button /> component
    disableElevation: true, // this prop disables the drop shadow on all Buttons
  },
};

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <I18nextProvider i18n={i18n}>
    <Router>
      <NetworkListener>
        <ClientProvider>
          <SnackbarProvider maxSnack={3}>
            

              <Route>
                <App />
              </Route>

            
          </SnackbarProvider>
        </ClientProvider>
      </NetworkListener>
      </Router>
    </I18nextProvider>
  </ThemeProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
