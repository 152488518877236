export const theme = {
  editSubTheme: 'Changer le thème',
  theme: 'Thème',
  duplicable: 'Option duplicable',
  notApplicable: "Option pas d'application",
  addThemeTemplate: 'Ajouter un thème',
  duplicateThemeTemplate: 'Copier tout les thèmes',
  createTheme: 'Ajouter un thème',
  shortNameFr: 'Nom court (Français)',
  shortNameNl: 'Nom court (Néerlandais)',
  longNameFr: 'Nom long (Français)',
  longNameNl: 'Nom long (Néerlandais)',
  shortName: 'Nom de navigation', // short name is for navigation
  longName: 'Titre de page', // long name is title for page or section in checklist
  shortNameFrError: 'Nom court (Français) est un champ obligatoire',
  shortNameNlError: 'Nom court (Néerlandais) est un champ obligatoire',
  longNameFrError: 'Nom long (Français) est un champ obligatoire',
  longNameNlError: 'Nom long (Néerlandais) est un champ obligatoire',
  item: 'Item',
  editTheme: 'Editer un thème',
  validateAdd: 'Ajouter un autre thème',
  themeTableTitle: 'Template " {{0}}"',
  shortNameFrUniqueError: "Il existe déjà un nom court avec ce texte français",
  shortNameNlUniqueError: "Il existe déjà un nom court avec ce texte néerlandais",
  longNameFrUniqueError: "Il existe déjà un nom long avec ce texte français",
  longNameNlUniqueError: "Il existe déjà un nom long avec ce texte néerlandais",
  };
