import React, { useContext, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './ChecklistReportItemStyle';
import AppContext from '../../../AppContext';
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Paper,
  TextareaAutosize,
  Typography,
} from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CancelIcon from '@material-ui/icons/Cancel';
import { get_audit_report_interview_by_audit_id_item_data,
  checklist_data }
   from '../../../services/Audit/ReadService/AuditReadModel';
import { translate, translateformPagePDF } from '../../../services/Translation/TranslationService';
import ItemRemarkListComponent from '../../ChecklistItemRemark/ItemRemarkListComponent';
import {checklist_theme_item_remark, template_theme_item_remark} from '../../../services/Checklist/ChecklistReadService/CheckistReadModel';
import {} from '../../ChecklistItemRemark/ChecklistItemRemarklPresenter/ChecklistItemRemarkPresenter';
import {
  useUpdateAllCheckValueInItem,
  useUpdateCheckValue,
} from '../../../services/ChecklistItemRemark/UpdateService/ChecklistItemRemarkUpsertService';
import AdminDocumentServiceContext from '../../AdminDocument/AdminDocumentRead/AdminDocumentServiceContext';


export const testIds = {};

export interface ChecklistReporItemtViewProps {
  checklist_theme_item: get_audit_report_interview_by_audit_id_item_data;
  onConformChanged: (event, checklistItemId, isConform) => void;
  onNotConformChanged: (event, checklistItemId, isConform) => void;
  onChangeComment: (event, checklist_theme_item) => void;
  conform?: boolean;
  comment?: string;
  audit_id?: number;
  index: number;
  checklist: checklist_data;
  isReadOnlyMode?:boolean;
}

const ChecklistReportItemView: React.FC<ChecklistReporItemtViewProps> = ({
  checklist_theme_item,
  onConformChanged,
  onNotConformChanged,
  onChangeComment,
  conform,
  comment,
  audit_id,
  index,
  checklist,
  isReadOnlyMode
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { language } = useContext(AppContext);

  const {onSelectPage} = useContext(AdminDocumentServiceContext)

  const readOnlyModeclick = e => e.preventDefault();

  const showRemarksPredifined: boolean = useMemo(
    () =>
      checklist_theme_item?.checklist_theme_item_remarks?.length ? true : false,
    [checklist_theme_item],
  );

  const  template_remark :template_theme_item_remark = {
    id: 0,
    remark_text_nl: '',
    remark_text_fr:'',
  };

  const remarks : checklist_theme_item_remark[] = useMemo(() => 
    checklist_theme_item.checklist_theme_item_remarks?.map(
      checklist_theme_item_remark_map => ({
          id: checklist_theme_item_remark_map.id,
          template_theme_item_remark_id: 0,
          checked: checklist_theme_item_remark_map.checked,
          name_fr: checklist_theme_item_remark_map.name_fr,
          name_nl: checklist_theme_item_remark_map.name_nl,
          template_theme_item_remark: template_remark,
        })),  [checklist_theme_item, template_remark],
     );
 
     const { onUpdateCheckValue } = useUpdateCheckValue({
      checklist_id: checklist?.id,
      showNotification: false,
    });
    const { onUpdateAllCheckValueInItem } = useUpdateAllCheckValueInItem({
      checklist_id: checklist?.id,
      showNotification: false,
    });
    const onDeativateRemark = useCallback(
      (event, _remark) => {
        onUpdateCheckValue({ id: _remark.id, checked: false });
      },
      [onUpdateCheckValue],
    );
  
    const onRemarkChanged = useCallback(
      (event, _remark) => {
        onUpdateCheckValue({ id: _remark.id, checked: !_remark.checked });
      },
      [onUpdateCheckValue],
    );
    const onDeactivateRemarks = useCallback(() => {
      if (checklist_theme_item.id) {
        onUpdateAllCheckValueInItem({
          checklist_theme_item_id: checklist_theme_item.id,
          checked: false,
        });
      } else {
        console.warn('item.id is required');
      }
    }, [onUpdateAllCheckValueInItem, checklist_theme_item]);
 
  return (
    <>
      {index !== 0 && (
        <Grid item lg={4} xs={4}>
          <Box m={2} />
        </Grid>
      )}
      <Grid item lg={8} xs={8} key={checklist_theme_item?.id}>
        <Paper elevation={3}>
          <Grid container>
            <Grid item lg={12} xs={12}>
              <Grid container spacing={1}>
                <Grid item lg={10} xs={9}>
                  <Box m={2}>
                    <Typography>
                      <span 
                        className={classes.normCode}
                        onClick={() => onSelectPage(translateformPagePDF(checklist_theme_item.template_theme_item, language))}
                        >
                         {checklist_theme_item.template_theme_item.norm_code}
                      </span>
                      <span className={classes.itemTitle}>
                        {translate(checklist_theme_item, language)}
                      </span>
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={2} xs={3}>
                  <Box m={1}>
                    <IconButton
                      className={classes.button}
                      onClick={e =>
                        isReadOnlyMode? readOnlyModeclick : onConformChanged(e, checklist_theme_item.id, conform)
                      }
                      aria-label="button-conform-item"
                    >
                      <CheckBoxIcon
                        className={
                          (conform === true && classes.conform) ||
                          classes.default
                        }
                      />
                    </IconButton>

                    <IconButton
                      className={classes.button}
                      onClick={e =>
                        isReadOnlyMode? readOnlyModeclick : onNotConformChanged(e, checklist_theme_item.id, conform)
                      }
                      aria-label="button-not-conform-item"
                    >
                      <CancelIcon
                        className={
                          (conform === false && classes.notConform) ||
                          classes.default
                        }
                      />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {showRemarksPredifined && (
              <Grid item lg={12} xs={12}>
                <Box ml={2} mr={2} mt={1}>
                  <Box>
                    <Typography className={classes.remarkTitle}>
                      {t('item_remark:remarkPredefined')}
                    </Typography>
                  </Box>
                  <Box mb={1} mt={1}>
                    <Divider />
                  </Box>
                  <ItemRemarkListComponent
                    onDeativateRemark={onDeativateRemark}
                    onRemarkChanged={onRemarkChanged}
                    onDeactivateRemarks={onDeactivateRemarks}
                    remarks={remarks}
                    isReadOnlyMode={isReadOnlyMode}
                  />
                  <Box mt={1}>
                    <Divider />
                  </Box>
                </Box>
              </Grid>
            )}
            <Grid item lg={12} xs={12}>
              <Grid container>
                <Grid item lg={12} xs={12}>
                  <Box mt={1} mr={2} ml={2} mb={1}>
                    <Box mb={1}>
                      <Typography className={classes.remarkTitle}>
                        {t('item_remark:comment')}
                      </Typography>
                    </Box>

                    <TextareaAutosize
                      id="comment"
                      name="comment"
                      minRows={3}
                      className={classes.textarea}
                      value={comment || ''}
                      onChange={event =>
                         isReadOnlyMode? readOnlyModeclick : onChangeComment(event, checklist_theme_item?.id)
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default ChecklistReportItemView;
