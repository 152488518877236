import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import {
  READ_ALL_ITEMS_FROM_TEMPLATE_THEME,
  READ_ALL_ITEMS_FROM_TEMPLATE_SUB_THEME,
} from '../ReadService/ItemReadQuery';
import { DELETE_ITEM } from './ItemDeleteMutation';

export const useDeleteItem = (templateThemeOrSubThemeId, fromTheme = true) => {
  const { setNotification } = useContext(MainAppContext);

  const [deleteItem] = useMutation(DELETE_ITEM, {
    refetchQueries: [
      fromTheme
        ? {
            query: READ_ALL_ITEMS_FROM_TEMPLATE_SUB_THEME,
            variables: { template_sub_theme_id: templateThemeOrSubThemeId },
          }
        : {
            query: READ_ALL_ITEMS_FROM_TEMPLATE_THEME,
            variables: { template_theme_id: templateThemeOrSubThemeId },
          },
    ],
    awaitRefetchQueries: true,
    onCompleted({ deletedItem }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const myDeleteItem = async (id: number) => {
    deleteItem({
      variables: { id: id, deletedDate: new Date() },
    });
  };

  return { myDeleteItem };
};
