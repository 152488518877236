export const entity = {
  name: 'Naam',
  abbreviation: 'Afkorting',
  category: 'Categorie',
  entity: 'Entiteit',
  createEntity: 'Entiteit aanmaken',
  editEntities: 'Entiteit aanpassen',
  allEntities: 'Lijst entiteit',
  nameError: 'Naam is een verplicht veld',
  abbreviationError: 'Afkorting is een verplicht veld',
  categoryError: 'Categorie is een verplicht veld',
  entityValidationError: 'De entiteit die u heeft opgegeven bestaat reeds',
  updateEntity: 'Entiteit wijzigen',
  entityError: 'Entiteit is een verplicht veld',
  search: 'Zoek een entiteit',
};
