import { useMutation } from '@apollo/client';
import { useCallback, useContext, useMemo } from 'react';
import { INSERT_SUB_THEME_TEMPLATE_CHECKLIST } from '.';
import MainAppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import {
  READ_SUB_THEME_TEMPLATE_CHECKLIST_BY_THEME_ID,
  useGetSubThemeIncludeSoftDeleteByTheme,
  list_sub_theme_template_checklist_include_soft_delete_by_template_id_data,
} from '../SubThemeReadService';
import {
  UPDATE_INDEX_SUB_THEME_TEMPLATE_CHECKLIST,
  UPDATE_SUB_THEME_TEMPLATE_CHECKLIST,
} from './SubThemeWriteMutation';
import {
  SubThemeFormData,
  SubThemeUpdateFormData,
} from '../../../components/SubTheme/SubThemeFormModel';
import {
  update_index_sub_theme_template_checklist_data,
  update_index_sub_theme_template_checklist_response,
  update_index_sub_theme_template_checklist_variables,
} from './SubThemeWriteModel';
import { handleReorder } from '../../../components/Shared/utils/order.utils';

export interface SubThemeCreateServiceProps {
  themeId: number;
  index: number;
}

export interface SubThemeUpdateServiceProps {
  themeId: number;
  showNotification: boolean;
}
export interface SubThemeCreateServiceResult {
  onCreate: (themeData: SubThemeFormData) => void;
}

export interface SubThemeUpdateServiceResult {
  onUpdate: (themeData: SubThemeUpdateFormData) => void;
}

/**
 * Description - Service to create a sub theme
 */
export const useCreateSubTheme = ({
  themeId,
  index,
}: SubThemeCreateServiceProps): SubThemeCreateServiceResult => {
  const { setNotification } = useContext(MainAppContext);

  const {
    getSubThemeIncludeSoftDeleteByTheme,
  } = useGetSubThemeIncludeSoftDeleteByTheme();

  const mutationConfiguration = useMemo(() => ({
    refetchQueries: [
      {
        query: READ_SUB_THEME_TEMPLATE_CHECKLIST_BY_THEME_ID,
        variables: { themeId },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted() {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  }), [setNotification, themeId])

  const [onCreateSubTheme] = useMutation(INSERT_SUB_THEME_TEMPLATE_CHECKLIST, { ...mutationConfiguration })

  const onCreate = useCallback(
    (data: SubThemeFormData) => {
      getSubThemeIncludeSoftDeleteByTheme({
        theme_template_checklist_id: themeId,
      }).then(subThemes => {
        const newIndex = subThemes.length
        onCreateSubTheme({
          variables: {
            object: {
              ...data,
              theme_template_checklist_id: themeId,
              index: newIndex,
            },
          },
        });
      });
    },
    [onCreateSubTheme, themeId, getSubThemeIncludeSoftDeleteByTheme],
  );

  return { onCreate };
};

/**
 * Description - Service to update a sub theme
 */
export const useUpdateSubTheme = ({
  themeId,
  showNotification,
}: SubThemeUpdateServiceProps): SubThemeUpdateServiceResult => {
  const { setNotification } = useContext(MainAppContext);

  const mutationConfiguration = useMemo(() => ({
    refetchQueries: [
      {
        query: READ_SUB_THEME_TEMPLATE_CHECKLIST_BY_THEME_ID,
        variables: { themeId },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted() {
      if (showNotification) {
        setNotification({
          key: 'common:savedSuccess',
          type: NotificationType.SUCCESS,
        });
      }
    },
    onError(error) {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  }), [setNotification, themeId, showNotification])

  const [onUpdateSubTheme] = useMutation(UPDATE_SUB_THEME_TEMPLATE_CHECKLIST, { ...mutationConfiguration })
  
  const onUpdate = useCallback(
    (data: SubThemeUpdateFormData) => {
      onUpdateSubTheme({
        variables: {
          qat_sub_theme_template_checklist_pk_columns_input: { id: data?.id },
          qat_sub_theme_template_checklist_set_input: { ...data },
        },
      })
    },
    [onUpdateSubTheme],
  );

  return { onUpdate };
};

/**
 * Description - Service to update sub theme list in theme
 */
export const useUpdateIndexSubTheme = ({ theme_template_checklist_id }) => {
  const { setNotification } = useContext(MainAppContext);
  const [updateIndex] = useMutation<
    update_index_sub_theme_template_checklist_response,
    update_index_sub_theme_template_checklist_variables
  >(UPDATE_INDEX_SUB_THEME_TEMPLATE_CHECKLIST, {
    refetchQueries: [
      {
        query: READ_SUB_THEME_TEMPLATE_CHECKLIST_BY_THEME_ID,
        variables: { themeId: theme_template_checklist_id },
      },
    ],
    onCompleted() {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });
  const {
    getSubThemeIncludeSoftDeleteByTheme,
  } = useGetSubThemeIncludeSoftDeleteByTheme();

  const onUpdateIndexSubTheme = useCallback(
    (newValue: { id: number; index: number }) => {
      return new Promise<update_index_sub_theme_template_checklist_data[]>(
        resolve => {
          getSubThemeIncludeSoftDeleteByTheme({
            theme_template_checklist_id,
          }).then(subThemes => {
            const previousValue:
              | list_sub_theme_template_checklist_include_soft_delete_by_template_id_data
              | undefined = subThemes.find(({ id }) => id === newValue.id);
            if (!previousValue) {
              setNotification({
                key: 'common:generalError',
                type: NotificationType.ERROR,
              });
              resolve([]);
            } else {
              const subThemesOrdered: list_sub_theme_template_checklist_include_soft_delete_by_template_id_data[] = handleReorder<list_sub_theme_template_checklist_include_soft_delete_by_template_id_data>(
                subThemes,
                previousValue.index,
                newValue.index,
              ).map(({ id, __typename, ...rest }, index) => ({
                ...rest,
                id,
                index,
              }));
              updateIndex({ variables: { objects: subThemesOrdered } }).then(
                response => {
                  if (response && response.data) {
                    resolve(
                      response.data.insert_qat_sub_theme_template_checklist,
                    );
                  } else {
                    resolve([]);
                  }
                },
              );
            }
          });
        },
      );
    },
    [
      getSubThemeIncludeSoftDeleteByTheme,
      updateIndex,
      theme_template_checklist_id,
      setNotification,
    ],
  );

  return { onUpdateIndexSubTheme };
};
