import { gql } from '@apollo/client';

/**
 * Description - graphql query to get all locations
 */
export const READ_ALL_LOCATIONS = gql`
  query qat_location_entity {
    qat_location_entity(
      order_by: { last_updated_date: desc }
      where: { deleted_date: { _is_null: true } }
    ) {
      id
      name
      entity_id
      category_id
      specification
      category_location {
        id
        sub_category_locations {
          id
        }
      }
    }
  }
`;

export const READ_ALL_LOCATIONS_BY_ENTITY_ID = gql`
  query qat_location_entity_by_entity($entity_id: Int! ) {
    qat_location_entity(
      order_by: { name: asc }
      where: {
       deleted_date: { _is_null: true } ,
       entity_id:{ _eq: $entity_id} }
    ) {
      id
      name
      entity_id
      category_id
      specification
      addresses(where: {deleted_date:{_is_null:true}}){
        id
      }
      category_location {
        id
        name_fr
        name_nl
        sub_category_locations {
          id
        }
      }
    }
  }
`;

export const READ_ALL_LOCATIONS_INCLUDE_SOFT_DELETED_BY_ENTITY_ID = gql`
  query qat_location_entity_by_entity($entity_id: Int! ) {
    qat_location_entity(
      order_by: { last_updated_date: desc }
      where: {       
       entity_id:{ _eq: $entity_id} }
    ) {
      id
      name
      entity_id
      category_id
      specification
      deleted_date
      addresses(where: {deleted_date:{_is_null:true}}){
        id
      }
      category_location {
        id
        name_fr
        name_nl
        sub_category_locations {
          id
        }
      }
      
    }
  }
`;

/**
 * Description - graphql query to get a specific location
 */
export const GET_LOCATION_BY_PK = gql`
  query qat_location_entity_by_pk($id: Int!) {
    qat_location_entity_by_pk(id: $id) {
      id
      name
      entity_id
      category_id
      specification
      addresses(where:{deleted_date:{_is_null: true}}) {
        id
        street
        house_number
        box_number
        city
      }
      category_location {
        id
        name_fr
        name_nl
        sub_category_locations {
          id
          name_fr
          name_nl
        }
        category_location_form_config {
          id
          category_location_form_field_configs {
            id
            name_fr
            name_nl
            is_required
          }
        }
      }
    }
  }
`;

/**
 * Description - graphql query to get a specific location and addresses by postal code
 */
export const GET_LOCATION_BY_PK_AND_ADDRESSES_BY_POSTAL_CODE = gql`
  query qat_location_entity_by_pk($id: Int!) {
    qat_location_entity_by_pk(id: $id) {
      addresses {
        id
        street
        house_number
        box_number
        city
      }
    }
  }
`;

export const READ_ALL_LOCATIONS_SORTED = gql`
  query qat_entity($order_by: qat_location_entity_order_by!,$entity_id:Int!) {
    qat_location_entity(
      order_by: [ $order_by ]
      where: {
       deleted_date: { _is_null: true } ,
       entity_id:{ _eq: $entity_id} }
    ) {
      id
      name
      entity_id
      category_id
      specification
      addresses(where: {deleted_date:{_is_null:true}}){
        id
      }
      category_location {
        id
        name_fr
        name_nl
        sub_category_locations {
          id
        }
      }
    }
  }
`;


export const GET_AUDIT_BY_ENTITY_ID_AND_LOCATION_ID=gql`
query  qat_audit($entity_id:Int!, $location_id:Int!){
  qat_audit(where: {location_id: {_eq: $location_id}, entity_id: {_eq: $entity_id}}) {   
    id  
  }
}
`;