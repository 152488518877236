import React from 'react';
import { get_checklist_by_pk_sub_section_data } from '../../../services/Checklist/ChecklistReadService/ChecklistReadQuery';
import { useChecklistThemeSubSection } from '../ChecklistThemeSubSectionPresenter';
import ChecklistThemeSubSectionView from '../ChecklistThemeSubSectionView';

export interface ChecklistThemeSubSectionComponentProps {
  subSection: get_checklist_by_pk_sub_section_data;
  checklistId: number;
  sectionId?: number;
  isReadOnlyMode: boolean;
}

const ChecklistThemeSubSectionComponent: React.FC<ChecklistThemeSubSectionComponentProps> = ({
  subSection,
  checklistId,
  sectionId,
  isReadOnlyMode,
}) => {
  const {
    onUpdateNoApplication,
    onConfirmUpdateNoApplication,
    openConfirmNoApplication,
    onCloseConfirmNoApplication,
    colorNotApplicationButton,
    noApplication,
    onConformSubSectionChanged
  } = useChecklistThemeSubSection(subSection, checklistId, sectionId);

  return (
    <ChecklistThemeSubSectionView
      subSection={subSection}
      checklistId={checklistId}
      onUpdateNoApplication={onUpdateNoApplication}
      onConfirmUpdateNoApplication={onConfirmUpdateNoApplication}
      openConfirmNoApplication={openConfirmNoApplication}
      onCloseConfirmNoApplication={onCloseConfirmNoApplication}
      colorNotApplicationButton={colorNotApplicationButton}
      noApplication={noApplication}
      isReadOnlyMode={isReadOnlyMode}
      onConformSubSectionChanged={onConformSubSectionChanged}
    />
  );
};

export default ChecklistThemeSubSectionComponent;
