import { Button, FormControl, FormHelperText, Grid, InputLabel, Typography } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PageContainer from '../../../Shared/Container/PageContainer';
import { useStyles } from './InterviewDocumentUploadStyles';
import clsx from 'clsx';
import { useInterviewDocumentUploadService } from './InterviewDocumentUploadService';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import InterviewDocumentUploadPreview from './InterviewDocumentUploadPreview/InterviewDocumentUploadPreview';
import { useBrowserLocationState } from '../../../Shared/BrowserLocationState/BrowserLocationState';


export const testIds = {
  documentSelectedMenu: "document-selected-menu",
  fileUploadInput: "file-upload-input",
  cancelBtn: "cancel-btton"
}

/**
 * This component is responsible for displaying the normes
 */
const InterviewDocumentUpload = () => {
  const currentLocationState = useBrowserLocationState<{audit: any, building: any, isReadonly: any, isNewDoc:any}>({keysRequired: ["audit", "building"]})

  const audit = useMemo(
    () => (currentLocationState?.audit ? currentLocationState?.audit : undefined),
    [currentLocationState],
  );
  const isReadonly = useMemo(
    () => (currentLocationState?.isReadonly ? currentLocationState?.isReadonly : false),
    [currentLocationState],
  );
  const isNewDoc = useMemo(
    () => (currentLocationState?.isNewDoc ? currentLocationState?.isNewDoc : false),
    [currentLocationState],
  );

  const { values, onChange, onSubmit, errors } = useInterviewDocumentUploadService(audit, isReadonly, isNewDoc)

  const classes = useStyles()
  const { t } = useTranslation();

  const errorsMessage: { file?: string } = useMemo(() => {
    const fileMessage = errors && errors.file === "required"
      ? t("adminDocument:fileUploadInputValidationRequired")
      : errors && errors.file === "type-pdf"
        ? t("adminDocument:fileUploadInputValidationTypePdf")
        : undefined
    return { file: fileMessage }
  }, [errors, t])

  const history = useHistory();
  const handleFormClose = useCallback(
    () => {
      history.goBack();

    }, [history]
  )
  return (
    <PageContainer>
      {
        <form onSubmit={onSubmit}>
          <h1>{isNewDoc ? t("interviewDocument:addTitleForm") : t("interviewDocument:updateTitleForm")}</h1>
          <Grid item lg={12} xs={12}>
            <Typography className={classes.title}>
              {t('interviewDocument:upload_title', {
                abbreviation: audit?.entity?.abbreviation,
                location: audit?.location_entity?.name,
                audit_date: moment(audit?.audit_date)?.format('DD/MM/YYYY'),
              })}
            </Typography>
          </Grid>
          <Grid
            container
            direction="column"
            justifyContent="space-evenly"
            alignItems="center"
            spacing={3}
          >
            <Grid
              item
              className={classes.control}
            >
              {/*Input label is outside form control because there are css problem between button for input (component label) and label itself*/}
              <InputLabel
                id="document-mix-select-label"
                className={clsx(classes.labelInputUploadFile, { [classes.error]: errors?.file !== undefined })}
              >{t("adminDocument:fileUploadInputLabel")}</InputLabel>
              <FormControl
                className={classes.control}
                error={errors?.file !== undefined ? true : false}
              >
                <div className={classes.containerInputUploadFile}>

                  <Button
                    variant="contained"
                    component="label"
                    className={classes.buttonUploadFile}
                  >
                    {t("adminDocument:selectFileButtonLabel")}
                    <input
                      data-testid={testIds.fileUploadInput}
                      id="document-upload"
                      name="document-upload"
                      aria-label="document-upload-label"
                      type="file"
                      accept="application/pdf"
                      onChange={onChange("file")}
                      hidden
                    />
                  </Button>
                  < Typography className={classes.fileName} variant="body1">{values.file ? values.file.name : t("adminDocument:noFileUploadMessage")}</Typography>
                </div>

                <FormHelperText>{errorsMessage.file
                  ? errorsMessage.file
                  : ""
                }</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item className={classes.control}>
              <Button
                type="submit"
                className={classes.buttonSubmit}
              >
                {isNewDoc ? t("interviewDocument:uploadNewDoc") : t("interviewDocument:modifyDoc")}
              </Button>
              <Button
                data-testid={testIds.cancelBtn}
                id="cancelUpdateBtn"
                onClick={() => handleFormClose()}
                variant="contained"
                color="default"
                fullWidth
              >
                {t("common:cancel")}
              </Button>
            </Grid>
          </Grid>
        </form>
      }
      {(audit?.interview_document[0]?.url || values) &&
        <InterviewDocumentUploadPreview
          url={audit?.interview_document[0]?.url}
          values={values}
        />
      }
    </PageContainer>
  )
}

export default InterviewDocumentUpload;
