import { useUpdateChecklistItemRefetchAudit } from '../../../services/ChecklistItem/UpdateService/ChecklistItemUpdateService';
import { useCallback, useEffect, useState } from 'react';
import { debounce } from 'lodash';

export const useChecklistReportItemPresenter = (
  audit_id,
  checklist_theme_item,
) => {
  const [conform, setConform] = useState();
  const [comment, setComment] = useState('');
  const { onUpdate: onUpdateItem } = useUpdateChecklistItemRefetchAudit(
    audit_id,
    false,
  );

  useEffect(() => {
    setConform(checklist_theme_item?.is_conform);
    setComment(checklist_theme_item?.comment);
  }, [audit_id, checklist_theme_item]);

  const handleConformChanged = (event, checklistItemId, isConform) => {
    isConform = isConform === true ? null : true;
    setConform(isConform);
    onUpdateItem(checklistItemId, { is_conform: isConform });
    event.stopPropagation();
  };

  const handleNotConformChanged = (event, checklistItemId, isConform) => {
    isConform = isConform === false ? null : false;
    setConform(isConform);
    onUpdateItem(checklistItemId, { is_conform: isConform });
    event.stopPropagation();
  };

  const debouncedSaveComment = useCallback(
    debounce(
      (nextValue, themeId) => onUpdateItem(themeId, { comment: nextValue }),
      2000,
    ),
    [],
  );

  const handleChangeComment = (event, checklist_item_id) => {
    const { value: nextValue } = event.target;
    setComment(nextValue);
    debouncedSaveComment(nextValue, checklist_item_id);
  };

  return {
    onConformChanged: handleConformChanged,
    onNotConformChanged: handleNotConformChanged,
    onChangeComment: handleChangeComment,
    conform,
    comment,
  };
};
