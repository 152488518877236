import { gql } from '@apollo/client';

/**
 * Description - graphql query to delete a location
 */
export const DELETE_LOCATION = gql`
  mutation update_qat_location_entity_by_pk(
    $id: Int!
    $deletedDate: timestamptz!
  ) {
    update_qat_location_entity_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_date: $deletedDate }
    ) {
      id
    }
  }
`;
