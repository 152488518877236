import { InMemoryCache, makeVar } from '@apollo/client';
import { ItemRemarkFormData } from '../../../services/Item/Remark/ItemRemarkFormData';

export const openItemRemarkTableVar = makeVar(false);
export const openUpdateItemRemarkVar = makeVar(false);
export const openCreateItemRemarkVar = makeVar(false);
export const remarkLastIndexVar = makeVar(-1);

const currentItemRemark: ItemRemarkFormData = {
  remark_text_fr: '',
  remark_text_nl: '',
  template_theme_item_id: -1,
  index: -1,
};

export const itemRemarkVar = makeVar(currentItemRemark);
export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        read() {
          return (
            openItemRemarkTableVar(),
            openUpdateItemRemarkVar(),
            openCreateItemRemarkVar(),
            remarkLastIndexVar(),
            itemRemarkVar()
          );
        },
      },
    },
  },
});
