import { InMemoryCache, makeVar } from '@apollo/client';
import { ThemeFormData } from './ThemeFormModel';

export const openCreateThemeVar = makeVar(false);
export const openUpdateThemeVar = makeVar(false);
export const openItemThemeVar = makeVar(false);
export const openThemeTableVar = makeVar(false);
export const lastThemeIndexVar = makeVar(-1);

export const currentThemeId = makeVar(-1);

export const defaultTheme: ThemeFormData = {
  long_name_fr: '',
  long_name_nl: '',
  short_name_fr: '',
  short_name_nl: '',
  duplicable: false,
  not_applicable_option: false,
  index: -1,
};

export const themeVar = makeVar(defaultTheme);
export const themeChange = makeVar(false);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        read() {
          return (
            openCreateThemeVar(),
            openUpdateThemeVar(),
            openItemThemeVar(),
            openThemeTableVar(),
            lastThemeIndexVar(),
            themeVar()
          );
        },
      },
    },
  },
});
