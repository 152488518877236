import { useMutation } from "@apollo/client";
import { useContext, useCallback } from "react";

import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { GET_AUDIT_PHOTO_METADATA } from "../ReadService/AuditPhotoQuery";
import { INSERT_AUDIT_PHOTO, INSERT_AUDIT_PHOTO_METADATA } from "./AuditPhotoWriteMutation";
import MainAppContext from '../../../AppContext';

import { useAddNewContent } from '../../AdminDocument/AdminDocumentWrite/AdminDocumentWrite'
import { isContraintViolationError } from "../../../graphql/utils";
import { GET_AUDIT_BY_PK } from "../../Audit/ReadService/AuditReadQuery";
import { GET_CHECKLIST_BY_PK } from "../../Checklist/ChecklistReadService";

export const useCreateAuditPhoto = ({ audit_id, checklist_id }) => {
  const { setNotification } = useContext(MainAppContext);
  const { addNewContent } = useAddNewContent("AuditPhoto")
  const [createAuditPhoto] = useMutation(INSERT_AUDIT_PHOTO, {
    refetchQueries: [
      { query: GET_AUDIT_BY_PK, variables: { id: audit_id } },
      { query: GET_CHECKLIST_BY_PK, variables: { id: checklist_id } }
    ],
    onCompleted({ createdLocation }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      if (isContraintViolationError(error))
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      else
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
    },
  });

  const onCreateAuditPhoto = useCallback((auditPhotoMetadataId: number, file: any, name: string, contentType: string) => {
    return new Promise<void>((resolve, reject) => {
      addNewContent(file, name).then(response => {
        createAuditPhoto({
          variables: {
            object: { url: response.path, minetype: contentType, audit_photo_metadata_id: auditPhotoMetadataId }
          }
        }).then(() => {
          resolve()
        }).catch(() => reject())
      }).catch(() => reject())
    })
  }, [addNewContent, createAuditPhoto])
  return { onCreateAuditPhoto };
};


export const useCreateAuditPhotoMetadata = (auditId, checklistId, themeId, sectionId) => {
  const { setNotification } = useContext(MainAppContext);

  const [createAuditPhotoMetaData] = useMutation(INSERT_AUDIT_PHOTO_METADATA, {
    refetchQueries: [{
      query: GET_AUDIT_PHOTO_METADATA,
      variables: { audit_id: auditId, checklist_id: checklistId, theme_id: themeId, section_id: sectionId }
    }],
    onError(error) {
      if (isContraintViolationError(error))
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      else
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
    },
  });

  const onCreateAuditPhotoMetaData = useCallback(
    ({
      audit_id,
      checklist_id,
      theme_id,
      section_id,
    }: {

      audit_id: number;
      checklist_id: number;
      theme_id: number;
      section_id: number;
    }): Promise<any> => {
      return new Promise((resolve, reject) => {
        let data = {
          audit_id: audit_id,
          checklist_id: checklist_id,
          theme_id: theme_id,
          section_id: section_id
        };
        createAuditPhotoMetaData({ variables: { object: data }}).then((newMetadata: any) => resolve(newMetadata)).catch(() => reject)
      })
      
    },
    [createAuditPhotoMetaData])

  return { onCreateAuditPhotoMetaData };
};






