import React, {
    useContext,
    useCallback,
    useMemo
} from "react";
import {
    Table,
    Paper,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    IconButton,
} from "@material-ui/core";
import { useStyles, StyledTableSortLabel } from "../Shared/Styles/CommonFormSyles";
import { useTranslation } from "react-i18next";
import { translate } from "../../services/Translation/TranslationService";
import AppContext from "../../AppContext";
import {
    CreateRounded,
    DeleteRounded,
} from "@material-ui/icons";
import { location_data } from "../../services/Location/LocationDataModel";
import { makeStyles } from '@material-ui/core/styles';
import { locationAddressLastIndexVar, openCreateLocationAddressVar, openUpdateLocationAddressVar } from "./LocationAddress/LocationAdressCache";
import routes from "../../routes";
import { useHistory } from 'react-router-dom';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import { useBrowserLocationState } from "../Shared/BrowserLocationState/BrowserLocationState";

const myStyles = makeStyles({
    table: {
      minWidth: 650,
    },
  
    tableRow: {
      backgroundColor: '#f0f0f0',
    },
  
    paper: {
      width: '100%',
      height: '100%',
      overflow: 'hidden'
    },
  
    tableContainer: {
      height: 'auto',
      maxHeight: 500,
    },
    arrowButton: {
        color: '#03a9f4',
    },
});

export interface OrderBy {
    column: string;
    direction?: OrderDirection;
}

export enum OrderDirection {
    ASC = 'asc',
    DESC = 'desc',
}

export interface LocationTableProps {
    isLoading?: boolean;
    locations: location_data[];
    handleDeleteClicked: (row: location_data) => void;
    openUpdateDialog: (row: location_data) => void;
    onSort: (sortItem: OrderBy) => void;
    sortByLocation: OrderBy[];
}
export const locationAddressTableTestIds = {
    buttonOnUpdate: 'button-table-update-location',
    buttonOnDelete: 'button-table-delete-location',
    paginationComponent: 'pagination-component',
    buttonOnLocationAddressVisibility: 'button-table-visibility-location',
    tableEntityId: 'table-id',
    buttonOpenListOfLocation: "button-open-list-of-location",
    buttonOnCreateLocation: "button-on-create-location",
};

const LocationTableComponent: React.FC<LocationTableProps> = ({
    locations,
    handleDeleteClicked,
    openUpdateDialog,
    onSort,
    sortByLocation,
}: LocationTableProps) => {
    const { t } = useTranslation();
    const { language } = useContext(AppContext);
    const classes = useStyles();
    const myClasses = myStyles();

    const currentLocationState = useBrowserLocationState<{entity: any}>({keysRequired: ["entity"]})

    const handleOpenUpdateDialog = useCallback(
        (rowData: location_data) => {
            openUpdateDialog(Array.isArray(rowData)
                ? rowData[0]
                : rowData
            )
        },
        [openUpdateDialog],
    );



    const onDeleteClicked = useCallback(
        (rowData: location_data) => (
            handleDeleteClicked(Array.isArray(rowData)
                ? rowData[0]
                : rowData
            )
        ),
        [handleDeleteClicked],
    );

    const entity = useMemo(
        () => {
            return currentLocationState && currentLocationState?.entity ? currentLocationState.entity : null;
        }, [currentLocationState]
    )

    const history = useHistory();
    const handleCreateLocationAddress = (location: location_data) => {
        openCreateLocationAddressVar(true);
        locationAddressLastIndexVar(1);
        history.push({
            pathname: routes.createLocationAddress.path,
            state: {
                locationInfo: location,
                entity: entity

            },
        });
    };

    const handOpenListofLocationAddress = (location: location_data) => {
        openUpdateLocationAddressVar(false);
        openCreateLocationAddressVar(false);
        history.push({
            pathname: routes.allLocationAddress.path,
            state: {
                locationInfo: location,
                entity: entity

            },
        });
    };
    return (
        <>
        <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
        <Table stickyHeader className={classes.table} aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableRow}>
                            {t("location:name")}
                            {sortByLocation && onSort && (
                                <StyledTableSortLabel
                                    className={myClasses.arrowButton}
                                    direction={sortByLocation.find(i => i?.column === 'name')?.direction}
                                    onClick={() => onSort({ column: 'name' })}
                                    active={true}
                                />
                            )}
                        </TableCell>
                        <TableCell className={classes.tableRow}>
                            {t("location:category")}
                            {sortByLocation && onSort && (
                                <StyledTableSortLabel
                                    className={myClasses.arrowButton}
                                    direction={
                                        sortByLocation.find(i => i?.column === 'category_id')?.direction
                                    }
                                    onClick={() => onSort({ column: 'category_id' })}
                                    active={true}
                                />
                            )}
                        </TableCell>
                        <TableCell className={classes.tableRow}>
                            {t("location:specification")}
                            {sortByLocation && onSort && (
                                <StyledTableSortLabel
                                    className={myClasses.arrowButton}
                                    direction={sortByLocation.find(i => i?.column === 'specification')?.direction}
                                    onClick={() => onSort({ column: 'specification' })}
                                    active={true}
                                />
                            )}
                        </TableCell>
                        <TableCell 
                            className={classes.tableRow}
                            align="center"
                        >
                            {t("location:address")}
                        </TableCell>
                        <TableCell
                             className={classes.tableRow}
                             align="center"
                        >
                            {t("common:edit")}
                        </TableCell>
                        <TableCell
                             className={classes.tableRow}
                             align="center"
                        >
                            {t("common:delete")}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {locations.map((location, index) => (
                        <TableRow
                            key={location.id}
                        >
                            <TableCell>
                                {location.name}
                            </TableCell>
                            <TableCell>
                                {
                                    location.category_location
                                        ? translate(location.category_location, language)
                                        : "NA"
                                }
                            </TableCell>
                            <TableCell>
                                {location.specification}
                            </TableCell>
                            <TableCell align="center">
                                {location &&
                                    location.addresses &&
                                    location.addresses.length > 0 ? (
                                    //exist 
                                    <IconButton
                                        data-testid={locationAddressTableTestIds.buttonOnLocationAddressVisibility}
                                        onClick={() => handOpenListofLocationAddress(location)}
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        data-testid={locationAddressTableTestIds.buttonOnLocationAddressVisibility}
                                        onClick={() => handleCreateLocationAddress(location)}
                                    >
                                        <AddRoundedIcon />
                                    </IconButton>
                                )}
                            </TableCell>

                            <TableCell
                                align="center"
                            >
                                <IconButton
                                    data-testid={locationAddressTableTestIds.buttonOnUpdate}
                                    onClick={() => handleOpenUpdateDialog(location)}
                                >
                                    <CreateRounded />
                                </IconButton>
                            </TableCell>
                            <TableCell
                                align="center"
                            >
                                <IconButton
                                    data-testid={locationAddressTableTestIds.buttonOnDelete}
                                    onClick={() => onDeleteClicked(location)}
                                >
                                    <DeleteRounded />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
    </Table>
    </TableContainer>
    </Paper>
        </>
    )
}

export default LocationTableComponent;