import React, { useMemo, useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import { lastThemeIndexVar, openCreateThemeVar, themeVar } from '../ThemeCache';
import { ThemeFormData } from '../ThemeFormModel';
import ThemeFormComponent from './ThemeFormDialogComponent';
import { useCreateTheme } from '../../../services/Theme/ThemeWriteService';
import {
  useGetThemeByIdService,
} from '../../../services/Theme/ThemeReadService/ThemeReadService';
import { useHistory } from 'react-router-dom';
import { useBrowserLocationState } from '../../Shared/BrowserLocationState/BrowserLocationState';

const ThemeCreateDialogComponent = () => {
  const isOpenCreateTheme: boolean = useReactiveVar(openCreateThemeVar);
  const currentTheme: ThemeFormData = useReactiveVar(themeVar);

  const currentLocationState = useBrowserLocationState<{template: any}>({keysRequired: ["template"]})

  const templateId: number = useMemo(
    () =>
      currentLocationState && currentLocationState.template
        ? currentLocationState.template.id
        : -1,
    [currentLocationState],
  );

  const { onCreate } = useCreateTheme({
    templateChecklistId: templateId,
  });

  const { data } = useGetThemeByIdService({
    templateChecklistId: templateId,
  });

  const lastIndex = useReactiveVar(lastThemeIndexVar);
  const defaultTheme: ThemeFormData = {
    id: -1,
    short_name_fr: '',
    short_name_nl: '',
    long_name_fr: '',
    long_name_nl: '',
    index: -1,
    not_applicable_option: false,
    duplicable: false,
  };

  function themeFormValue() {
    let themeForm: ThemeFormData;
    themeForm = {
      short_name_fr: currentTheme.short_name_fr,
      short_name_nl: currentTheme.short_name_nl,
      long_name_fr: currentTheme.long_name_fr,
      long_name_nl: currentTheme.long_name_nl,
      not_applicable_option: currentTheme.not_applicable_option,
      duplicable: currentTheme.duplicable,
      index: lastIndex,
    };
    return themeForm;
  }

  const handleCreateTheme = () => {
    let themeForm: ThemeFormData = themeFormValue();
    onCreate(themeForm);
    openCreateThemeVar(false);
    themeVar(defaultTheme);
  };

  const handleCreateAddItem = () => {
    let themeForm: ThemeFormData = themeFormValue();
    onCreate(themeForm);
    themeVar(defaultTheme);
    lastThemeIndexVar(lastThemeIndexVar() + 1);
  };

  const history = useHistory();
  const handleClose = useCallback(() => {
    openCreateThemeVar(false);
    themeVar(defaultTheme);
    if (data.length === 0) {
      history.goBack();
    }
  }, [defaultTheme, history, data.length]);

  return (
    <ThemeFormComponent
      isOpenForm={isOpenCreateTheme}
      handleClose={handleClose}
      handleFormSubmit={handleCreateTheme}
      handleCreateAddItem={handleCreateAddItem}
      templateId={templateId}
    />
  );
};

export default ThemeCreateDialogComponent;
