import { gql } from '@apollo/client';

/**
 * Description - graphql query to create an audit
 */
export const UPDATE_AUDIT = gql`
  mutation update_qat_audit_by_pk(
    $id: Int!
    $qat_audit_set_input: qat_audit_set_input!
  ) {
    update_qat_audit_by_pk(
      pk_columns: { id: $id }
      _set: $qat_audit_set_input
    ) {
      id
    }
  }
`;
