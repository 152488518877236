import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Checkbox,
  Box,
  ButtonGroup,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../Shared/Styles/CommonFormSyles';
import { openCreateSubThemeVar, subThemeVar } from '../SubThemeCache';
import { useReactiveVar } from '@apollo/client';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubThemeFormData } from '../SubThemeFormModel';
import { useCheckSubThemeUnique } from '../../../services/SubTheme/SubThemeReadService/SubThemeReadService';
import { useBrowserLocationState } from '../../Shared/BrowserLocationState/BrowserLocationState';

export const formDialogTestIds = {
  updateCreateThemeNameInput: 'theme-name',
  updateCreateNameFrInput: 'subTheme-nameFr-input',
  updateCreateNameNlInput: 'subTheme-nameNl-input',
  updateCreateNotApplicableOptionInput: 'subTheme-not_application_option-input',
  applyUpdateBtn: 'subTheme-applyUpdateBtn',
  cancelUpdateBtn: 'subTheme-cancelUpdateBtn',
  applyCreateAddBtn: 'subTheme-applyCreateAddBtn',
};


interface SubThemeFormComponentProps {
  isOpenForm: boolean;
  handleClose: any;
  handleFormSubmit: any;
  handleCreateAddItem?: any;
  templateThemeId: number;
  id?: number;
}
const SubThemeFormComponent: React.FC<SubThemeFormComponentProps> = ({
  isOpenForm,
  handleClose,
  handleFormSubmit,
  handleCreateAddItem,
  templateThemeId,
  id
}: SubThemeFormComponentProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formSubTheme: SubThemeFormData = useReactiveVar(subThemeVar);
  const isCreateItemForm = useReactiveVar(openCreateSubThemeVar);
  const { onCheckSubThemeUnique } = useCheckSubThemeUnique()

  const currentLocationState = useBrowserLocationState<{ theme: any }>({ keysRequired: ["theme"] })

  const schema = useMemo(() => {
    return yup.object().shape({
      id: yup.number(),
      name_fr: yup.string().nullable().required(t("subtheme:nameFrError")),
      name_nl: yup.string().nullable().required(t("subtheme:nameNlError")),
      not_applicable_option: yup.boolean(),
    });
  }, [t])

  const { register, errors, setValue, handleSubmit, setError, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const theme = currentLocationState?.theme
    ? currentLocationState?.theme?.long_name_fr +
    ' / ' +
    currentLocationState?.theme?.long_name_nl
    : '';

  // Update form when api call finishes
  useEffect(() => {
    setValue('name_fr', formSubTheme.name_fr);
    setValue('name_nl', formSubTheme.name_nl);
    setValue('not_applicable_option', formSubTheme.not_applicable_option);
  }, [formSubTheme, setValue]);

  // Register form inputs
  useEffect(() => {
    register({ name: 'name_fr' });
    register({ name: 'name_nl' });
    register({ name: 'not_applicable_option' });
  }, [register]);

  const handleChange = event => {
    if (event.target.value || event.target.value === '') {
      subThemeVar({
        ...formSubTheme,
        [event.currentTarget.name]: event.currentTarget.value,
      });
      setValue(event.currentTarget.name, event.currentTarget.value as never);
    }
  };
  const handleCheckBoxChange = event => {
    if (event.target.checked) {
      setValue(event.target.name, event.currentTarget.checked as never);
    } else {
      setValue(event.target.name, event.currentTarget.value as never);
    }
    subThemeVar({
      ...formSubTheme,
      not_applicable_option: !formSubTheme.not_applicable_option,
    });
  };

  const onSubmit = useCallback((data, process) => {
    data.id = id
    onCheckSubThemeUnique(data, { template_theme_id: templateThemeId }).then(errors_var => {
      if (errors_var) {
        Object.keys(errors_var).forEach(key => {
          setError(key, { type: "manual", message: errors_var[key] })
        });
      } else {
        process(data)
      }
    })
  }, [setError, onCheckSubThemeUnique, templateThemeId, id])

  const handleFormClose = useCallback(
    () => {
      reset();
      handleClose();

    }, [handleClose, reset]

  )

  return (
    <Dialog
      open={isOpenForm}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return false;
        } else {
          handleClose()
        }
      }}
      fullWidth={true}
    >
      <DialogContent>
        <h1>
          {isCreateItemForm
            ? t('subtheme:createSubTheme')
            : t('subtheme:editSubTheme')}
        </h1>
        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={3}
        >
          <Grid item className={classes.control}>
            <TextField
              data-testid={formDialogTestIds.updateCreateThemeNameInput}
              name="theme_name"
              aria-readonly={true}
              label={t('subtheme:theme_name')}
              variant="outlined"
              value={theme}
              fullWidth
            />
          </Grid>
          <Grid item className={classes.control}>
            <TextField
              data-testid={formDialogTestIds.updateCreateNameFrInput}
              name="name_fr"
              onChange={event => handleChange(event)}
              label={t('subtheme:nameFr')}
              variant="outlined"
              value={formSubTheme.name_fr}
              fullWidth
              error={errors.name_fr ? true : false}
              helperText={errors.name_fr && errors.name_fr.message}
            />
          </Grid>
          <Grid item className={classes.control}>
            <TextField
              data-testid={formDialogTestIds.updateCreateNameNlInput}
              onChange={event => handleChange(event)}
              name="name_nl"
              label={t('subtheme:nameNl')}
              variant="outlined"
              value={formSubTheme.name_nl}
              fullWidth
              error={errors.name_nl ? true : false}
              helperText={errors.name_nl && errors.name_nl.message}
            />
          </Grid>
          <Grid item className={classes.control}>
            <div className={classes.divCheckBox}>
              <Checkbox
                data-testid={
                  formDialogTestIds.updateCreateNotApplicableOptionInput
                }
                onChange={event => handleCheckBoxChange(event)}
                checked={formSubTheme.not_applicable_option}
                name="not_applicable_option"
                classes={{
                  root: classes.checkbox,
                  checked: classes.checked,
                }}
              />
              <p>{t('subtheme:notApplicable')}</p>
            </div>
          </Grid>
        </Grid>
      </DialogContent>


      <DialogActions>
        <Box display="flex" width="100%">
          <ButtonGroup
            disableElevation
            orientation="vertical"
            variant="contained"
            aria-label="vertical"
            fullWidth
          >
            {handleCreateAddItem ? (
              <Button
                disableElevation
                className={classes.root}
                variant="contained"
                id="applyCreateAddBtn"
                data-testid={formDialogTestIds.applyCreateAddBtn}
                onClick={handleSubmit((data) => onSubmit(data, handleCreateAddItem))}
                fullWidth
              >
                {t('subtheme:validateAdd')}
              </Button>
            ) : (
              ''
            )}

            <Button
              fullWidth
              color="primary"
              id="applyUpdateBtn"
              data-testid={formDialogTestIds.applyUpdateBtn}
              onClick={handleSubmit((data => onSubmit(data, handleFormSubmit)))}
            >
              {t('common:validate')}
            </Button>
            <Button
              fullWidth
              id="cancelUpdateBtn"
              className={classes.grey}
              data-testid={formDialogTestIds.cancelUpdateBtn}
              onClick={() => handleFormClose()}
            >
              {t('common:cancel')}
            </Button>
          </ButtonGroup>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default SubThemeFormComponent;
