import React from 'react';
import { get_checklist_by_pk_section_data } from '../../../services/Checklist/ChecklistReadService/ChecklistReadQuery';
import { useChecklistThemeSectionPresenter } from '../ChecklistThemeSectionPresenter/ChecklistThemeSectionPresenter';
import ChecklistThemeSectionView from '../ChecklistThemeSectionView';
import {audit_data} from '../../../services/Audit/AuditDataModel';
import {building_data} from '../../../services/Building/BuildingDataModel';

export interface ChecklistThemeSectionComponentProps {
  sectionsCounter: number;
  section: get_checklist_by_pk_section_data;
  checklistId: number;
  onUpdateNotApplication: (id, no_application) => void;
  themeNotApplicationValue: boolean;
  themeNotApplicationOptionValue: boolean;
  expanded: boolean;
  isReadOnlyMode: boolean;
  onExpand: () => void;
  handleDuplicate: (section: any) => void;
  handleDelete: (id) => void;
  audit:audit_data;
  building:building_data;
}

const ChecklistThemeSectionComponent: React.FC<ChecklistThemeSectionComponentProps> = ({
  sectionsCounter,
  section,
  checklistId,
  onUpdateNotApplication,
  themeNotApplicationValue,
  expanded,
  onExpand,
  isReadOnlyMode,
  handleDelete,
  handleDuplicate,
  themeNotApplicationOptionValue,
  audit,
  building

}) => {
  const {
    disabledRemoveButton,
    onDuplicate,
    onDelete,
    colorNotApplicationButton,
    onConformChanged,
    checkSectionValidated,
  } = useChecklistThemeSectionPresenter({
    section,
    checklistId,
    themeNotApplicationValue,
    handleDuplicate,
    handleDelete
  });

  return (
    <ChecklistThemeSectionView
      colorNotApplicationButton={colorNotApplicationButton}
      themeNotApplicationValue={themeNotApplicationValue}
      disabledRemoveButton={disabledRemoveButton}
      sectionsCounter={sectionsCounter}
      section={section}
      onDuplicate={onDuplicate}
      onDelete={onDelete}
      onUpdateNotApplication={onUpdateNotApplication}
      onExpand={onExpand}
      expanded={expanded}
      checklistId={checklistId}
      onConformChanged={onConformChanged}
      checkSectionValidated={checkSectionValidated}
      isReadOnlyMode={isReadOnlyMode}
      themeNotApplicationOptionValue={themeNotApplicationOptionValue}
      audit={audit}
      building={building}
    />
  );
};

export default ChecklistThemeSectionComponent;
