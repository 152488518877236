import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import {
  EntityFormViewProps,
  ValidationRulesType,
} from '../EntityFormComponent/EntityFormComponent';
import { useHistory } from 'react-router-dom';
import routes from '../../../../routes';

const validationRules: ValidationRulesType = {
  label: { required: true },
  abbreviation: { required: true },
};

export interface EntityFormData {
  label: string;
  abbreviation: string;
}

export interface EntityFormPresenterProps {
  onSubmit: (data: EntityFormData) => void;
  entity?: EntityFormData;
  title: string;
  submitButtonLabel: string;
}

const useEntityFormPresenter = ({
  onSubmit,
  entity,
  title,
  submitButtonLabel,
}: EntityFormPresenterProps): EntityFormViewProps => {
  const defaultValues = useMemo(
    () =>
      entity
        ? entity
        : {
            label: '',
            abbreviation: '',
            category_id: '',
          },
    [entity],
  );

  const {
    register,
    errors,
    handleSubmit,
    unregister,
    control,
  } = useForm<EntityFormData>({ defaultValues });
  const history = useHistory();

  useEffect(() => {
    register({ name: 'label' }, validationRules.label);
    register({ name: 'abbreviation' }, validationRules.abbreviation);

    return () => {
      unregister('label');
      unregister('abbreviation');
    };
  }, [register, unregister]);

  const onSubmitForm = handleSubmit(data => {
    onSubmit(data);
    history.push(routes.allEntities.path);
  });

  return {
    errors,
    onSubmit: onSubmitForm,
    control,
    title,
    submitButtonLabel,
  };
};

export default useEntityFormPresenter;
