import {
  CircularProgress,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React, { Fragment, useContext } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useStyles } from '.';
import AppContext from '../../../../../AppContext';
import { translate } from '../../../../../services/Translation/TranslationService';
import { AuditFormMainInfoViewProps } from './AuditFormMainInfoViewProps';
import { testIds } from './AuditFormMainInfoViewTestIds';

const AuditFormMainInfoView: React.FC<AuditFormMainInfoViewProps> = ({
  disableEntity,
  disableLocation,
  loadingEntityOptions,
  entityOptions,
  loadingLocationOptions,
  locationOptions,
  errors,
  control,
  isReadOnlyMode,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { language } = useContext(AppContext);
  const blockFunctionInReadOnlyMode = e => e.preventDefault();
  return (
    <Fragment>
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={3}
      >
        <Grid item className={classes.control}>
          <InputLabel id="entityLabel">{t('entity:entity')}</InputLabel>
          {loadingEntityOptions ? (
            <CircularProgress />
          ) : (
            <Controller
              defaultValue=""
              render={({ onChange, onBlur, value }) => (
                <Select
                  onChange={
                    isReadOnlyMode ? blockFunctionInReadOnlyMode : onChange
                  }
                  onBlur={isReadOnlyMode ? blockFunctionInReadOnlyMode : onBlur}
                  value={value}
                  labelId="entitySelect"
                  id="entity_id"
                  fullWidth
                  data-testid={testIds.entityIdInput}
                  error={errors.entity_id}
                  disabled={disableEntity || isReadOnlyMode}
                >
                  {entityOptions &&
                    entityOptions.map(entityOption => {
                      return (
                        <MenuItem key={entityOption.id} value={entityOption.id}>
                          {entityOption.label} {entityOption.abbreviation}
                        </MenuItem>
                      );
                    })}
                </Select>
              )}
              name="entity_id"
              control={control}
            />
          )}

          <FormHelperText>
            {errors.entity_id && t('entity:entityError')}
          </FormHelperText>
        </Grid>

        <Grid item className={classes.control}>
          <InputLabel id="locationLabel">{t('audit:location')}</InputLabel>
          {loadingLocationOptions ? (
            <CircularProgress />
          ) : (
            <Controller
              render={({ onChange, onBlur, value }) => (
                <Select
                  onChange={
                    isReadOnlyMode ? blockFunctionInReadOnlyMode : onChange
                  }
                  onBlur={isReadOnlyMode ? blockFunctionInReadOnlyMode : onBlur}
                  value={
                    locationOptions.map(({ id }) => id).includes(value)
                      ? value
                      : ''
                  }
                  labelId="locationSelect"
                  id="location_id"
                  fullWidth
                  data-testid={testIds.locationIdInput}
                  error={errors.location_id}
                  disabled={disableLocation || isReadOnlyMode}
                >
                  {locationOptions.map(locationOption => (
                    <MenuItem
                      key={locationOption.id}
                      value={locationOption.id}
                    >{`${locationOption.name} (${translate(
                      locationOption?.category_location,
                      language,
                      )})${locationOption?.specification ? ("/" + locationOption?.specification) : ""}`}</MenuItem>
                  ))}
                </Select>
              )}
              name="location_id"
              control={control}
            />
          )}
          <FormHelperText>
            {errors.location_id && t('audit:locationError')}
          </FormHelperText>
        </Grid>

        <Grid item className={classes.control}>
          <Controller
            defaultValue=""
            as={
              <TextField
                label={t('audit:auditDate')}
                fullWidth
                id="audit_date"
                data-testid={testIds.auditDateInput}
                name="audit_date"
                type="date"
                error={errors.audit_date ? true : false}
                helperText={errors.audit_date && t('audit:auditDateError')}
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={isReadOnlyMode}
              />
            }
            name="audit_date"
            control={control}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default AuditFormMainInfoView;
