export const item_remark = {
  remark: 'Remarque',
  remarkPredefined: 'Remarques prédéfinies',
  createItem: "Ajouter une remarque pour l'item",
  updateItem: 'Editer la remarque',
  addItemRemark: 'Ajouter une remarque',
  RemarktextFr: 'Remarques en français',
  RemarktextNl: 'Remarque en néerlandais',
  remarkTextFrError: 'Remarque en français est un champ obligatoire',
  remarkTextNlError: 'Remarque en néerlandais est un champ obligatoire',
  itemRemarkTitle:
    'Remarque pour l\'item "{{0}}" du thème "{{1}}" de la CheckList "{{2}}"',
  itemName: "Nom de l'item",
  validateAdd: 'Ajouter un autre remarque',
  comment: 'Remarque libre',
  remarkTextFrUniqueError: "Il existe déjà un item avec ce texte français",
  remarkTextNlUniqueError: "Il existe déjà un item avec ce texte néerlandais",
};
