import makeStyles from '@material-ui/core/styles/makeStyles';
import { drawerWidth } from '../../Navigation/NavigationStyle';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
  },
  containerRemark: {
    zIndex: 100,
    position: 'fixed',
    padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      1,
    )}px `,
  },
  containerRemarkPaper: {},
  containerRemarkShiftFull: {
    width: `100%`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  containerRemarkShiftSmall: {
    width: `calc(100% - ${2 * drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  containerRemarkShiftMedium: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  containerThemeSection: {
    paddingTop: theme.spacing(0.5),
  },
  title: {
    margin: 15,
  },
  subThemeTitle: {
    margin: 20,
  },
  textarea: {
    //strange transition in accordion if set to 100%
    width: '99%',
  },
  generalRemarkLabel: {
    fontWeight: 'bold',
    fontSize: '0.875rem',
    fontFamily: 'Poppins',
  },
}));

export { useStyles };
