import { useContext } from 'react';
import MainAppContext from '../../../../../AppContext';
import { UserMenuProps } from '../UserMenuComponent/UserMenuComponent';
import useUserMenuService from '../UserMenuService/UserMenuService';
import { useStyles } from './UserMenuStyle';

export const useUserMenuPresenter = (): UserMenuProps => {
  const classes = useStyles();
  const context = useContext(MainAppContext);
  const service = useUserMenuService();

  return { classes, context, service };
};
