import { InMemoryCache, makeVar } from '@apollo/client';
import { get_checklist_by_pk_theme_data } from '../../services/Checklist/ChecklistReadService/ChecklistReadQuery';
export const currentAuditIdVar = makeVar(-1);
export const pictureAddedVar = makeVar(false);
export const selectedThemeNavigationVar = makeVar<get_checklist_by_pk_theme_data | undefined>(undefined)
export const closeMenuWhenOpenCameraVar=makeVar(false)
export const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                read() {
                    return (
                        currentAuditIdVar() ,                      
                        pictureAddedVar() ,
                        selectedThemeNavigationVar(),
                        closeMenuWhenOpenCameraVar()                      
                    );
                },
            },
        },
    },
});