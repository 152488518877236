import AuditReadCurrentView, {
  AuditReadCurrentViewProps,
  testIds,
} from './AuditReadCurrentView';

export default AuditReadCurrentView;

export type { AuditReadCurrentViewProps };

export { testIds };
