import React, { useCallback, useState, useEffect, useContext } from 'react';
import './App.css';
import { Provider as AppContextProvider } from './AppContext';
import i18n from './config/translation/i18n';
import { useHistory, Switch, Route, Redirect } from 'react-router-dom';
import routes from './routes';
import { NavigationComponent } from './components/Navigation/NavigationComponent';
import { keycloakInstanceVar } from './graphql/ClientProvider';
import { useReactiveVar } from '@apollo/client';
import Keycloak from 'keycloak-js'
import { useUpsertUser } from './services/User/UserWriteService/UserCreateService';
import NetworkListenerContext from './components/NetworkListener/NetworkListenerContext';

function App() {
  const [language, setLanguage] = useState(i18n.language);
  const [role, setRole] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [loadingUser, setLoadingUser] = useState(true);
  const [notification, setNotification] = useState(undefined);
  const [authentication, setAuthentication] = useState<{ authenticated: boolean }>({ authenticated: false })
  const keycloak = useReactiveVar(keycloakInstanceVar);
  const { onUpsertUser } = useUpsertUser()
  const {onChangeIsOffline} = useContext(NetworkListenerContext)

  const history = useHistory();

  const logOut = () => {
    if (keycloak) {
      sessionStorage.clear();
      history.push('/');
      keycloak.logout();


    } else {
      console.error("keycloak instance is not initialize")
    }
  };

  const changeLanguage = useCallback(async (lng: string) => {
    await i18n.changeLanguage(lng);
    setLanguage(lng);
  }, []);

  const changeNotification = useCallback(async newNotification => {
    setNotification(newNotification);
  }, []);

  useEffect(() => {

    if (process.env.REACT_APP_KEYCLOAK_FILE_CONFIG_PATH) {
      const newKeycloakInstance = Keycloak(process.env.REACT_APP_KEYCLOAK_FILE_CONFIG_PATH)
      newKeycloakInstance.onAuthError = () => {onChangeIsOffline(true)}
      newKeycloakInstance.init({ onLoad: 'login-required' }).then(authenticated => {
        keycloakInstanceVar(newKeycloakInstance)
        setAuthentication({ authenticated: authenticated })
        if (newKeycloakInstance.tokenParsed &&
          newKeycloakInstance.tokenParsed.sub &&
          newKeycloakInstance.tokenParsed['preferred_username']) {
          onUpsertUser({
            username: newKeycloakInstance.tokenParsed['preferred_username'],
            user_id: newKeycloakInstance.tokenParsed.sub
          })
        } else {
          console.error("the properties username and user_id is not in the token")
        }
      })
    } else {
      throw new Error("variable environment KEYCLOAK_FILE_CONFIG_PATH doesn't exist")
    }

  }, [onUpsertUser, onChangeIsOffline]);

  useEffect(() => {
    if (keycloak) {
      keycloak.loadUserProfile()
        .then(user => {
          setTimeout(function () {
            setLoadingUser(false);
          }, 1500);
          setUsername(user.username ? user.username : '');
          setEmail(user.email ? user.email : '');
          if (keycloak?.tokenParsed?.resource_access && process.env.REACT_APP_KEYCLOAK_CLIENT_ID) {
            const clientRole: string = keycloak?.tokenParsed?.resource_access[process.env.REACT_APP_KEYCLOAK_CLIENT_ID]
              ? keycloak?.tokenParsed?.resource_access[process.env.REACT_APP_KEYCLOAK_CLIENT_ID]['roles'][0]
              : keycloak.tokenParsed["client-default-role"]
            if (clientRole) {
              setRole(clientRole)
            } else {
              console.log("the role was not injected")
            }
          } else {
            console.error("problem occured when fetching authenticated user role token donesn't contains role or client id doens't exist in env variables")
          }
        })
        .catch((error) => {
          console.error(error)
          setLoadingUser(false);
          console.log('problem occured when fetching authenticated user');
          return null;
        });
    }
  }, [keycloak]);

  return (
    <AppContextProvider
      value={{
        role: role,
        username: username,
        email: email,
        language: language,
        setLanguage: changeLanguage,
        loadingUser: loadingUser,
        notification: notification,
        setNotification: changeNotification,
        logOut,
      }}
    >
      <Switch>
        <Route path={routes.appPage.path}>
          <NavigationComponent />
        </Route>
        <Route
          render={() =>
            authentication ? <Redirect to={routes.currentAudits.path} /> : null
          }
        />
      </Switch>
    </AppContextProvider>
  );
}

export default App;
