import React from 'react';
import { useChecklistPresenter } from '../ChecklistPresenter';
import ChecklistView from '../ChecklistView';

import {useDocumentDialogService} from '../../AdminDocument/AdminDocumentRead/AdminDocumentService'
import {AdminocumentServiceContextProvider} from '../../AdminDocument/AdminDocumentRead/AdminDocumentServiceContext'
export interface ChecklistComponentProps {
  location_id: number;
  subCategory_id: number;
}

const ChecklistComponent = () => {
  const {
    onThemeSelected,
    selectedTheme,
    building,
    form,
    category,
    subCategory,
    checklist,
    width,
    themeMenuOpen,
    onCloseMenu,
    onOpenMenu,
    onRemarkSelected,
    selectedChecklistRemark,
    expanded,
    onExpand,
    onChangeComment,
    comment,
    isReadOnlyMode,
    audit
  } = useChecklistPresenter();

  const documentDialogServiceContextValue = useDocumentDialogService()

  return (
    <AdminocumentServiceContextProvider
      value={documentDialogServiceContextValue}
    >
      <ChecklistView
      onThemeSelected={onThemeSelected}
      selectedTheme={selectedTheme}
      building={building}
      form={form}
      category={category}
      subCategory={subCategory}
      checklist={checklist}
      width={width}
      themeMenuOpen={themeMenuOpen}
      onCloseMenu={onCloseMenu}
      onOpenMenu={onOpenMenu}
      onRemarkSelected={onRemarkSelected}
      selectedChecklistRemark={selectedChecklistRemark}
      expanded={expanded}
      onExpand={onExpand}
      onChangeComment={onChangeComment}
      comment={comment}
      isReadOnlyMode={isReadOnlyMode}
      audit={audit}
    />
    </AdminocumentServiceContextProvider>
  );
};

export default ChecklistComponent;
