import { useMutation } from '@apollo/client'
import {useContext, useCallback} from 'react'
import { UPSERT_USER } from './UserWriteMutation'
import AppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import {upsert_user_response, upsert_user_variables} from './UserWriteModel'

export const useUpsertUser = () => {
    const { setNotification } = useContext(AppContext);

    const [upsertUser] = useMutation<upsert_user_response, upsert_user_variables>(
        UPSERT_USER,
        {
            awaitRefetchQueries: true,
            onCompleted: () => {
              setNotification({
                key: 'common:savedSuccess',
                type: NotificationType.SUCCESS,
              });
            },
            onError: () => {
              setNotification({
                key: 'common:generalError',
                type: NotificationType.ERROR,
              });
            },
          });
        

    const onUpsertUser = useCallback(async ({username, user_id}: {username: string, user_id: string } ) => {
      await upsertUser({variables: {object:{id: username, x_hasura_user_id: user_id}}})
    },[upsertUser])

    return {onUpsertUser}
}  