import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { translate } from '../../../services/Translation/TranslationService';
import moment from 'moment';
import AppContext from '../../../AppContext';
import { useStyles } from './ChecklistReportPhotoViewStyle';
import { CircularProgress, Grid, Box, Paper, Typography, Chip, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from '@material-ui/core';
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import CloseRounded from '@material-ui/icons/CloseRounded';



export interface ChecklistReportPhotoViewProps {
  isLoading: boolean;
  photoOverviewDetails: any;
  contentData: any;
}

const ChecklistReportPhotoView: React.FC<ChecklistReportPhotoViewProps> =
  ({ isLoading, photoOverviewDetails, contentData }) => {
    const { t } = useTranslation();
    const classes = useStyles()
    const { language } = useContext(AppContext);
    const [photos, setPhoto] = useState(photoOverviewDetails)
    const [content, setContent] = useState(contentData)
    const [previewImage, setPreviewImage] = useState(false)
    const [currentUrl, setCurrentUrl] = useState('')


    let images: { original: any, thumbnail: any, originalTitle: any }[] = []

    useEffect(() => {

      setPhoto(photoOverviewDetails)
      setContent(contentData)

    }, [isLoading, photoOverviewDetails, contentData])

    const showImage = (auditPhoto: any, index: number): React.ReactNode => {
      if (content[auditPhoto?.url])
        return (<Box ><img key={index} onClick={() => openPreviewImage(auditPhoto)}
          style={{ height: '70px' }}
          src={content[auditPhoto?.url]} alt={auditPhoto?.url.slice(auditPhoto?.url.lastIndexOf('/') + 1)}  /> </Box>)
      else
        return ''
    }

    const openPreviewImage = (auditPhoto: any) => {
      setPreviewImage(true)
      setCurrentUrl(auditPhoto?.url)

    }

    const openDiaporama = (selectedUrl) => {
      images = []
      for (let value in content) {
        images.push({ original: content[value], thumbnail: content[value], originalTitle: selectedUrl.slice(selectedUrl.lastIndexOf('/') + 1) })

      }
      var startIndex = images.findIndex(contentToCheck => contentToCheck.original === contentData[selectedUrl])

      return (<>
        <Dialog
          open={previewImage}
          onClose={() => handleClose()}
          maxWidth={false}
        >
          <div style={{ width: "700px" }}>
            <DialogTitle id={"alert-dialog-title"}>{t("report:albumPhoto")}</DialogTitle>
            <DialogContent>
              <Grid>
                <ImageGallery items={images} startIndex={startIndex} />
              </Grid>
            </DialogContent>
            <DialogActions>
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => handleClose()}
              >
                <CloseRounded />
              </IconButton>
            </DialogActions >

          </div>
        </Dialog>

      </>)
    }

    const handleClose = () => {
      setPreviewImage(false)
    }

    return isLoading ? (
      <CircularProgress />
    ) : (
      <>
        <Grid
          container
        >
          <Grid item lg={12} xs={12} className={classes.titleGrid}>
            <Box m={1}>
              <Paper elevation={3}>
                <Box m={2}>
                  <Typography className={classes.title}>
                    {t("report:photo_title", {
                      abbreviation: photos[0]?.entity?.abbreviation,
                      location: photos[0]?.location_entity.name, 
                      audit_date: moment(photos[0]?.audit_date)?.format('DD/MM/YYYY')
                    })}
                  </Typography>
                </Box>
              </Paper>
            </Box>
          </Grid>
          <Grid item lg={6} xs={6}>
            <Grid direction={'column'} container spacing={0}>
              {photos[0]?.checklists?.map((_checklist, index) =>
                <Paper elevation={3} key={index} >
                  <Paper elevation={3}>
                    <Grid item lg={12} xs={12} className={classes.addressGrid}>
                      <Box m={1}>
                        <Box >
                          <Typography className={classes.addressGrid} component={"div"} >
                            <span><Chip className={classes.addressNumber} label={_checklist?.building?.index}></Chip>
                            </span>{_checklist?.building?.address?.street} {_checklist?.building?.address?.house_number} {_checklist?.building?.address?.box_number ? t("report:boxNumber", { 0: _checklist?.building?.address?.box_number }) : ""}{" "}
                          </Typography>
                        </Box>

                      </Box>
                    </Grid>
                  </Paper>
                  {_checklist?.checklist_themes?.map(theme =>
                    theme?.checklist_theme_sections?.map(
                      _section => _section?.audit_photo_metadata[0]?.audit_photos?.map((auditPhoto, indexSection) =>
                        <Paper elevation={3} key={indexSection}>
                          <Grid
                            container
                            key={indexSection}
                          >
                            <Grid item lg={6} xs={6} className={classes.sectionPhotoGrid}>
                              <Box m={1}>
                                {showImage(auditPhoto, indexSection)}
                              </Box>
                            </Grid>
                            <Grid item lg={6} xs={6} className={classes.sectionPhotoGrid}>
                              <Box m={1}  >
                                <Typography component={"div"}>
                                  <span className={classes.sectionName}> {translate(_section, language)} {" "}{_section?.specification} </span>
                                </Typography>
                              </Box>
                            </Grid>

                          </Grid>
                        </Paper>
                      ))
                  )
                  }
                </Paper>
              )}
            </Grid>
          </Grid>
        </Grid>
        {

          previewImage && <Grid>
            {openDiaporama(currentUrl)}
          </Grid>
        }
      </>
    )
  };

export default ChecklistReportPhotoView;


