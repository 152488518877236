import { gql } from '@apollo/client';

/**
 * Description - graphql mutation to create a location
 */
export const INSERT_LOCATION = gql`
  mutation insert_location_entity_one(
    $object: qat_location_entity_insert_input!
  ) {
    insert_qat_location_entity_one(object: $object) {
      id
    }
  }
`;
