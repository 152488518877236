import { gql } from '@apollo/client';

/**
 * Description - graphql query to delete an item
 */
export const DELETE_ITEM = gql`
  mutation update_qat_template_theme_item_by_pk(
    $id: Int!
    $deletedDate: timestamptz!
  ) {
    update_qat_template_theme_item_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_at: $deletedDate }
    ) {
      id
    }
  }
`;
