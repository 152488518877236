import React, {
    useState,
    useCallback,
    useMemo
} from "react";
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from "@apollo/client";
import { locationAddressVar, openUpdateLocationAddressVar } from "../LocationAdressCache";
import { LocationAddressFormData } from "../LocationAddressFormModel";
import { AddressUpdateFormData, useUpdateAddress } from "../../../../services/Address/UpdateService/AddressUpdateService";
import LocationAddressFormComponent from "./LocationAddressFormComponent";
import ConfirmDialog from "../../../Shared/Dialog/ConfirmDialog/ConfirmDialogComponent/ConfirmDialogComponent";
import { useGetBuildingsByAddressId } from "../../../../services/Building/ReadService/BuildingReadService";
export interface tableProps {
    openUpdateForm: boolean;
}

const LocationAddressUpdateDialogComponent = () => {
    const isOpenUpdateForm: boolean = useReactiveVar(openUpdateLocationAddressVar);
    const updateLocationAddress: LocationAddressFormData = useReactiveVar(locationAddressVar);
    const [
        openUpdateConfirmDialog,
        setOpenUpdateConfirmDialog,
    ] = useState<boolean>(false);
    const { t } = useTranslation();
    const { onUpdate } = useUpdateAddress();
    const { getBuildingsByAddressId } = useGetBuildingsByAddressId();
    const [
        locationAddressFormUpdate,
        setLocationAddressFormUpdate,
    ] = useState<AddressUpdateFormData>();

    const defaultLocationAddress: AddressUpdateFormData
        = useMemo(() => ({
            street: "",
            house_number: "",
            box_number: "",
            city: "",
            id: -1,
            location_entity_id: -1
        }),[])

    const handleUpdateLocationAddress = useCallback( () => {
        // Check if address is used by buildings
        let address_id : number| undefined = updateLocationAddress.id
        if (address_id) {
            getBuildingsByAddressId(address_id).then(addressCount => {
                let locationAddressForm: AddressUpdateFormData;
                locationAddressForm = {
                    ...updateLocationAddress,
                    street: updateLocationAddress.street.trim(),
                    house_number: updateLocationAddress.house_number.trim(),
                    box_number: updateLocationAddress.box_number?.trim(),
                    city: updateLocationAddress.city.trim(),
                    id: updateLocationAddress.id ? updateLocationAddress.id : -1,
                    location_entity_id: updateLocationAddress.location_entity_id
                };

                if (addressCount > 0) {
                    setOpenUpdateConfirmDialog(true);
                    setLocationAddressFormUpdate(locationAddressForm);
                } else {
                    if (locationAddressForm && locationAddressForm.id && locationAddressForm.id !== -1) {
                        onUpdate(locationAddressForm.id, locationAddressForm);
                        openUpdateLocationAddressVar(false);
                        locationAddressVar(defaultLocationAddress);
                    }
                }
            });
        }
    },[getBuildingsByAddressId, onUpdate, defaultLocationAddress, updateLocationAddress])

    const handleClose = useCallback(
        () => {
            openUpdateLocationAddressVar(false);
            locationAddressVar(defaultLocationAddress);
        },
        [defaultLocationAddress],
    );

    const onConfirmedToUpdate = useCallback(() => {
          setOpenUpdateConfirmDialog(false);
        if (locationAddressFormUpdate && locationAddressFormUpdate.id && locationAddressFormUpdate.id !== -1) {
            onUpdate(locationAddressFormUpdate.id, locationAddressFormUpdate);
            openUpdateLocationAddressVar(false);
            locationAddressVar(defaultLocationAddress);
        } else {
            throw new Error("the Address id to update doesn't exist");
        }

    }, [locationAddressFormUpdate, onUpdate, defaultLocationAddress]);

    return (
        <div>
            <LocationAddressFormComponent
                isOpenForm={isOpenUpdateForm}
                handleClose={handleClose}
                handleFormSubmit={handleUpdateLocationAddress}
            />
            <ConfirmDialog
                open={openUpdateConfirmDialog}
                onClose={() => setOpenUpdateConfirmDialog(false)}
                onConfirm={onConfirmedToUpdate}
                title={t('address:updateAddressTitle')}
                text={t('address:updateAddressMsg')}
            />
        </div>
    );
};

export default LocationAddressUpdateDialogComponent;