import { useMemo } from 'react';
import { DocumentNode, useMutation } from '@apollo/client';
import { useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { ItemFormData } from '../ItemFormData';
import {
  READ_ALL_ITEMS_FROM_TEMPLATE_THEME,
  READ_ALL_ITEMS_FROM_TEMPLATE_SUB_THEME,
  GET_ALL_ITEMS_INCLUDE_SOFT_DELETED_IN_THEME,
  GET_ALL_ITEMS_INCLUDE_SOFT_DELETED_IN_SUBTHEME,
} from '../ReadService/ItemReadQuery';
import { useGetAllItemsIncludeDeleted } from '../ReadService/ItemReadService';
import { INSERT_ITEM } from './ItemWriteMutation';

export const useCreateItemFromThemeOrSubTheme = ({
  template_theme_id,
  template_sub_theme_id,
}: {
  template_theme_id?: number;
  template_sub_theme_id?: number;
}) => {
  const { setNotification } = useContext(MainAppContext);
  const { getItems } = useGetAllItemsIncludeDeleted();

  const refetchQueries: {
    query: DocumentNode;
    variables: { [key: string]: any };
  }[] = useMemo(() => {
    return template_theme_id && !template_sub_theme_id
      ? [
          {
            query: READ_ALL_ITEMS_FROM_TEMPLATE_THEME,
            variables: { template_theme_id },
          },
          {
            query: GET_ALL_ITEMS_INCLUDE_SOFT_DELETED_IN_THEME,
            variables: { template_theme_id },
          },
        ]
      : [
          {
            query: READ_ALL_ITEMS_FROM_TEMPLATE_SUB_THEME,
            variables: { template_sub_theme_id: template_sub_theme_id },
          },
          {
            query: GET_ALL_ITEMS_INCLUDE_SOFT_DELETED_IN_SUBTHEME,
            variables: { template_sub_theme_id },
          },
        ];
  }, [template_theme_id, template_sub_theme_id]);

  const mutationConfiguration = useMemo(() => ({
    refetchQueries,
    awaitRefetchQueries: true,
    onCompleted() {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError() {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  }), [refetchQueries, setNotification])

  const [createdItem] = useMutation(INSERT_ITEM, {
    ...mutationConfiguration
  });

  const onCreateItemFromThemeOrSubTheme = async (itemData: ItemFormData) => {
    getItems({ template_sub_theme_id, template_theme_id }).then(items => {
      // get all items includes deleted items to inject next index
      createdItem({
        variables: { object: {
          ...itemData,
          index: items.length
        } },
      });
    });
  };

  return { onCreateItemFromThemeOrSubTheme, setNotification };
};
