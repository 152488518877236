import { gql } from '@apollo/client';

/**
 * Description - graphql query to delete an entity
 */
export const DELETE_ENTITY = gql`
  mutation update_qat_audit_by_pk($id: Int!, $deletedDate: timestamptz!) {
    update_qat_audit_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_date: $deletedDate }
    ) {
      id
    }
  }
`;
