import { Button, CircularProgress, Dialog, makeStyles } from '@material-ui/core';
import React, { useContext, useMemo } from 'react'
import AppContext from '../../../AppContext';
import { norm_document_request, useGetNormDocumentWithContent } from '../../../services/AdminDocument/AdminDocumentRead/AdminDocumentRead';
import { useTranslation } from 'react-i18next';
import Viewer, { Worker } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

const useStyles = makeStyles(theme => ({
    root: {
        position: "relative",
    },
    page: {
        
    },
    buttonToClose: {
        position: "sticky",
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: "white"
    }
}))

export const testIds = {
    document: "document",
    buttonToClose: "button_to_close_document_dialog" 
}

export interface AdminDocumentDialogProps {
    open: boolean;
    pageNumber: number;
    onCloseDialog: () => void
}   

const AdminDocumentDialog: React.FC<AdminDocumentDialogProps> = ({ open, pageNumber, onCloseDialog }) => {
    const { language } = useContext(AppContext);
    const { data: normDocument, loading: loadingNormDocument } = useGetNormDocumentWithContent()
    const { t } = useTranslation()
    const classes = useStyles()

    const document: norm_document_request | null = useMemo(() => {
        const languageDocument = language
            ? language.split('-')[0]
            : null

        return languageDocument && normDocument[languageDocument] ? `${normDocument[languageDocument]}#page=${pageNumber}` : ""

    }, [language, normDocument, pageNumber])

 
    return (
        <Dialog
            open={open}
            maxWidth="xl"           
            onClose={onCloseDialog}
        >
            {loadingNormDocument
                ? <CircularProgress />
                : <div 
                    data-testid={testIds.document}
                    className={classes.root}>
                    <Worker workerUrl={"https://unpkg.com/pdfjs-dist@2.4.456/build/pdf.worker.min.js"}>
                        <div style={{ height: '750px' }}> 
                            <Viewer fileUrl={document} initialPage={pageNumber - 1}  />
                        </div>
                    </Worker>                 

                    <Button data-testid={testIds.buttonToClose} className={classes.buttonToClose} onClick={onCloseDialog}>{t("common:close")}</Button>
                </div>
            }
            
        </Dialog>
    )
}

export default AdminDocumentDialog