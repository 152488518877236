import { gql } from '@apollo/client';
import { ItemRemarkFormData } from '../ItemRemarkFormData';

export interface insert_qat_template_theme_item_remark_one_variables {
  object: ItemRemarkFormData,
}

export interface insert_qat_template_theme_item_remark_one_response {
  insert_qat_template_theme_item_remark_one: {
    data: {
      id: number
      created_at: string
      remark_text_fr: string
      remark_text_nl: string
      index: number
    }
  }
}

/**
 * Description - graphql query to create an item
 */
export const INSERT_ITEM_REMARK = gql`
  mutation insert_qat_template_theme_item_remark_one(
    $object: qat_template_theme_item_remark_insert_input!
  ) {
    insert_qat_template_theme_item_remark_one(
      object: $object
    ) {
      id
      created_at
      remark_text_fr
      remark_text_nl
      index
    }
  }
`;

export interface reactivate_unique_item_remark_variables {
  id: number;
  remark_text_fr: string;
  remark_text_nl: string;
  index: number;
}

export interface reactivate_unique_item_remark_response {
  update_qat_template_theme_item_remark_by_pk: {
    data: {
      id: number;
      created_at: string;
      remark_text_fr: string;
      remark_text_nl: string;
      index: number;
    }
  }
}

export const REACTIVATE_ITEM_REMARK = gql`
  mutation reactivate_unique_item_remark(
  $id: Int!
  $remark_text_fr: String!
  $remark_text_nl: String!
  $index: Int!
  ){
    update_qat_template_theme_item_remark_by_pk(pk_columns: {id: $id}, _set:{
      remark_text_fr: $remark_text_fr,
      remark_text_nl: $remark_text_nl,
      index: $index,
      deleted_at: null,
    }){
      id
      created_at
      remark_text_fr
      remark_text_nl
      index
    }
  }
`




