import { useMutation, useReactiveVar } from "@apollo/client"
import { useCallback, useContext } from "react";
import AppContext from "../../../AppContext";
import { NotificationType } from "../../../components/Notification/NotificationComponent";
import { GET_ADMIN_DOCUMENT_BY_PK, GET_ALL_ADMIN_DOCUMENT } from "../AdminDocumentRead/AdminDocumentQuery";
import { ADD_ADMIN_DOCUMENT, add_admin_document_response, add_admin_document_variables } from "./AdminDocumentMutation"
import { keycloakInstanceVar } from "../../../graphql/ClientProvider";
import { useAxiosInstance } from "../../../axios/AxiosService";


export interface AddContentResponseData {
    destination: string;
    encoding: string;
    fieldname: string;
    filename: string;
    mimetype: string;
    originalname: string;
    path: string;
    size: number;
}

export interface UpdateContentResponseData {
    file: {
        destination: string;
        encoding: string;
        fieldname: string;
        filename: string;
        mimetype: string;
        originalname: string;
        path: string;
        size: number;
    }
}

export const useAddNewContent = (folder,) => {
    const {instance} = useAxiosInstance({})
    const keycloak = useReactiveVar(keycloakInstanceVar);
    const addNewContent = useCallback((file: any, name: string) => {
        return new Promise<AddContentResponseData>(async (resolve, reject) => {
            if (process.env.REACT_APP_API_FILE_URI) {
                if (keycloak && keycloak.tokenParsed && keycloak.idToken) {
                    await keycloak.updateToken(180)
                    const jwtToken = keycloak.idToken
                    let formData = new FormData()
                    formData.append('file', file)
                    return instance.post(`${process.env.REACT_APP_API_FILE_URI}/file/?folder=${folder}&filename=${name}`,
                        formData, {
                        headers: {
                            Accept: 'application/json',
                            "Content-Type": "multipart/form-data",
                            "Authorization": jwtToken ? `Bearer ${jwtToken}` : "",
                        }
                    }).then((response: { data: AddContentResponseData }) => {
                        if (response.data && response.data.path && response.data.path.length) {
                            let pathWithoutRootFolderArray = response.data.path.split("/")
                            pathWithoutRootFolderArray.shift()
                            const pathWithoutRootFolder = pathWithoutRootFolderArray && pathWithoutRootFolderArray.length ? pathWithoutRootFolderArray.join("/") : response.data.path
                            resolve({ ...response.data, path: pathWithoutRootFolder })
                        }
                    })
                } else {
                    console.error("Error to get token from keycloak")
                    reject()
                }    
            } else {
                console.error("variable environment for api file uri doesn't exist")
                reject()
            }
        })
    }, [instance, keycloak, folder])

    return { addNewContent }
}



export const useAddContent = () => {
    const keycloak = useReactiveVar(keycloakInstanceVar);
    const {instance} = useAxiosInstance({})
    const addContent = useCallback((file: any, name: string) => {
        return new Promise<AddContentResponseData>(async (resolve, reject) => {
            if (process.env.REACT_APP_API_FILE_URI) {
                if (keycloak && keycloak.tokenParsed && keycloak.idToken) {
                    let formData = new FormData()
                    formData.append('file', file)
                    await keycloak.updateToken(180)
                    const jwtToken = keycloak.idToken
                    return instance.post(`${process.env.REACT_APP_API_FILE_URI}/file/?folder=admin_document&filename=${name}`,
                        formData, {
                        headers: {
                            Accept: 'application/json',
                            "Content-Type": "multipart/form-data",
                            "Authorization": jwtToken ? `Bearer ${jwtToken}` : "",
                        }
                    }).then((response: { data: AddContentResponseData }) => {
                        if (response.data && response.data.path && response.data.path.length) {
                            let pathWithoutRootFolderArray = response.data.path.split("/")
                            pathWithoutRootFolderArray.shift()
                            const pathWithoutRootFolder = pathWithoutRootFolderArray && pathWithoutRootFolderArray.length ? pathWithoutRootFolderArray.join("/") : response.data.path
                            resolve({ ...response.data, path: pathWithoutRootFolder })
                        } else {
                            console.warn("no data from api file")
                            reject()
                        }
                    }).catch(error => {
                        console.error(error)
                        reject()
                    })
                } else {
                    console.error("Error to get token from keycloak")
                    reject()
                }
            } else {
                console.error("variable environment for api file uri doesn't exist")
                reject()
            }
        })
    }, [keycloak, instance])

    const updateContent = useCallback((file: any, name: string, filename: string) => {
        const pathSegment = filename.split("/")
        const url = pathSegment[pathSegment.length - 1]
        return new Promise<UpdateContentResponseData>(async (resolve, reject) => {
            if (process.env.REACT_APP_API_FILE_URI) {
                if (keycloak && keycloak.tokenParsed && keycloak.idToken) {
                    await keycloak.updateToken(180)
                    const jwtToken = keycloak.idToken
                    let formData = new FormData()
                    formData.append('file', file)

                    return instance.put(`${process.env.REACT_APP_API_FILE_URI}/file/${url}/?folder=admin_document`,
                        formData, {
                        headers: {
                            Accept: 'application/json',
                            "Content-Type": "multipart/form-data",
                            "Authorization": jwtToken ? `Bearer ${jwtToken}` : "",
                        }
                    }).then((response: { data: UpdateContentResponseData }) => {
                        if (response.data && response.data.file && response.data.file.path && response.data.file.path.length) {
                            let pathWithoutRootFolderArray = response.data.file.path.split("/")
                            pathWithoutRootFolderArray.shift()
                            const pathWithoutRootFolder = pathWithoutRootFolderArray && pathWithoutRootFolderArray.length ? pathWithoutRootFolderArray.join("/") : response.data.file.path
                            resolve({ file: {...response.data.file, path: pathWithoutRootFolder} })
                        } else {
                            console.warn("no data from api file")
                            reject()
                        }
                    }).catch(error => {
                        console.error(error)
                        reject()
                    })
                } else {
                    console.error("Error to get token from keycloak")
                    reject()
                }    
            } else {
                console.error("variable environment for api file uri doesn't exist")
                reject()
            }
        })
    }, [keycloak, instance])

    return { addContent, updateContent }
}

export const useAddAdminDocument = ({ documentIdSelected, documentUrlSelected }: { documentIdSelected: number, documentUrlSelected: string | null }) => {

    const { setNotification } = useContext(AppContext);
    const { addContent, updateContent } = useAddContent()
    const [addAdminDocument] = useMutation<add_admin_document_response, add_admin_document_variables>(
        ADD_ADMIN_DOCUMENT,
        {
            awaitRefetchQueries: true,
            refetchQueries: [{ query: GET_ALL_ADMIN_DOCUMENT }, { query: GET_ADMIN_DOCUMENT_BY_PK, variables: { id: documentIdSelected } }],
            onError: () => {
                setNotification({
                    key: 'common:generalError',
                    type: NotificationType.ERROR,
                });
            },
        }
    )

    const onAddAdminDocument = useCallback((id: number, file: any, name: string) => {
        return new Promise<void>((resolve, reject) => {
            if (!documentUrlSelected) {
                addContent(file, name).then((data) => {
                    addAdminDocument({ variables: { id, url: data.path, last_updated_date: new Date() } })
                    setNotification({ key: 'common:savedSuccess', type: NotificationType.SUCCESS })
                    resolve()
                }).catch(() => {
                    setNotification({ key: 'common:generalError', type: NotificationType.ERROR })
                    reject()
                })
            } else {
                updateContent(file, name, documentUrlSelected).then((data) => {
                    addAdminDocument({ variables: { id, url: data.file.path, last_updated_date: new Date() } })
                    setNotification({ key: 'common:savedSuccess', type: NotificationType.SUCCESS })
                    resolve()
                }).catch(() => {
                    setNotification({ key: 'common:generalError', type: NotificationType.ERROR })
                    reject()
                })
            }
        })

    },[addAdminDocument, setNotification, addContent, documentUrlSelected, updateContent])

    return { onAddAdminDocument }
}


