export const location = {
  locationValidationError: 'De locatie die u heeft opgegeven bestaat reeds',
  search: 'Zoek een locatie',
  addLocation: "Locatie toevoegen",
  addOtherLocation: "Een andere localiteit toevoegen",
  name: "Locatie",
  location: "Locatie",
  category: "Categorie",
  categoryError: "De categorie is een verplicht veld",
  nameError: "De locatie naam is en verplicht veld",
  specification: "Specificatie",
  locationTableTitle: 'Lijst van locaties van entiteit "{{0}}"',
  address: "Adres",
  nameCategorieSpecificationUniqueError: 'Il existe déjà une localité avec le nom ""{{o}}"", la categorie ""{{1}}"" et la spécification ""{{2}}"" ',
  nameCategorieUniqueError: 'Il existe déjà une localité avec le nom ""{{o}}"" et la categorie ""{{1}}""',
  editLocation: "Locatie wijzigen",
  confirmAuditText: "Deze locatie is gelinkt aan een audit. Als u deze wijzigt, wordt de audit gelinkt aan de nieuwe locatie. Wil je echt doorgaan? ",
  confirmAddressText: "Deze locatie bevat al adressen. Als u deze wijzigt, worden deze adressen gelinkt aan de nieuwe locatie. Wil je echt doorgaan? ",
  confirmAuditTextconfirmAddressText: "Deze locatie is gelinkt aan een audit en bevat adressen. Als u deze wijzigt, worden de audit en de adressen gelinkt aan de nieuwe locatie. Wil je echt doorgaan? ",

}
