import { useApolloClient } from '@apollo/client';
import { useContext } from 'react';
import { NotificationType } from '../../components/Notification/NotificationComponent';
import MainAppContext from '../../AppContext';
import { READ_ITEMS_WITH_OVERVIEW_DETAILS } from './NotConformItemQuery';
import { useCallback } from 'react';

export const useGetItemsOverviewByAuditId = audit_id => {
  const client = useApolloClient();
  const { setNotification } = useContext(MainAppContext);

  const getItemsOverviewByAuditId = useCallback(
    () =>
      new Promise<any>(resolve => {
        client
          .query<any>({
            query: READ_ITEMS_WITH_OVERVIEW_DETAILS,
            variables: { audit_id: audit_id },
            fetchPolicy: 'network-only',
          })
          .then(response => {
            resolve(response.data);
          })
          .catch(error => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      }),
    [setNotification, client, audit_id],
  );

  return { getItemsOverviewByAuditId };
};
