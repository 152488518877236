import { useApolloClient, useQuery } from "@apollo/client";
import { useCallback, useContext, useEffect, useState } from "react";
import AppContext from "../../../AppContext";
import { get_all_audit_photo_metadata_response, get_audit_photo_data, get_all_audit_photo_response } from "../AuditPhotoModel";
import { GET_AUDIT_PHOTO, GET_AUDIT_PHOTO_BY_METADATA_ID, GET_AUDIT_PHOTO_METADATA } from "./AuditPhotoQuery";
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { useCreateAuditPhotoMetadata } from '../WriteService/AuditPhotoWriteMutationService'




export const useGetAuditPhotoMetadata = (audit_id, checklist_id, theme_id, section_id) => {

    const { setNotification } = useContext(AppContext);
    let { data, loading, error } = useQuery<get_all_audit_photo_metadata_response>
        (GET_AUDIT_PHOTO_METADATA, {
            variables: { audit_id, checklist_id, theme_id, section_id },
            onError: errorResponse => {
                setNotification({
                    key: 'common:generalError',
                    type: NotificationType.ERROR,
                });
            },
        });
    return { data: data?.qat_audit_photo_metadata, loading, error };

};

export const useGetAllAuditPhotoForIds = (audit_id, checklist_id, theme_id, section_id) => {

    const { setNotification } = useContext(AppContext);
    let { data, loading, error } = useQuery<get_all_audit_photo_response>
        (GET_AUDIT_PHOTO, {
            variables: { audit_id, checklist_id, theme_id, section_id },
            onError: errorResponse => {
                setNotification({
                    key: 'common:generalError',
                    type: NotificationType.ERROR,
                });
            },
        });
    return { data: data?.qat_audit_photo, loading, error };

};

/**
 * get audit Photo metadata id 
 * @returns promise
 */

export const useGetAuditPhotoMetadataId = () => {
    const client = useApolloClient();
    const { setNotification } = useContext(AppContext);

    const getPhotoMetadata = useCallback(({ audit_id, checklist_id, theme_id, section_id
    }) =>
        new Promise<number>(resolve => {
            client
                .query<
                    get_all_audit_photo_metadata_response
                >({
                    variables: { audit_id, checklist_id, theme_id, section_id },
                    query: GET_AUDIT_PHOTO_METADATA,
                    fetchPolicy: "network-only"
                })
                .then(result => {
                    if (result.error) {
                        setNotification({
                            key: 'common:generalError',
                            type: NotificationType.ERROR,
                        });
                        resolve(-1);
                    }

                    if (result.data && result.data.qat_audit_photo_metadata?.length) {
                        resolve(result.data.qat_audit_photo_metadata[0].id)
                    } else {
                        resolve(-1);
                    }

                }).catch(error => {
                    setNotification({
                        key: 'common:generalError',
                        type: NotificationType.ERROR,
                    });
                });
        }),
        [client, setNotification],
    );

    return { getPhotoMetadata };
};

/**
 * get audit photo by metadata Id
 * @returns promise
 */
export const useGetAuditPhoto = () => {
    const client = useApolloClient();
    const { setNotification } = useContext(AppContext);

    const getAuditPhotoByMetadataId = useCallback(({ audit_photo_metadata_id }) =>
        new Promise<get_audit_photo_data[]>(resolve => {
            client
                .query<
                    get_all_audit_photo_response
                >({
                    variables: { audit_photo_metadata_id },
                    query: GET_AUDIT_PHOTO_BY_METADATA_ID,
                    fetchPolicy: "network-only"
                })
                .then(result => {
                    if (result.error) {
                        setNotification({
                            key: 'common:generalError',
                            type: NotificationType.ERROR,
                        });
                        resolve([]);
                    }

                    if (result.data && result.data.qat_audit_photo?.length) {
                        resolve(result.data.qat_audit_photo)
                    } else {
                        resolve([]);
                    }

                }).catch(error => {
                    setNotification({
                        key: 'common:generalError',
                        type: NotificationType.ERROR,
                    });
                });
        }),
        [client, setNotification],
    );

    return { getAuditPhotoByMetadataId };
};


/**
 * get audit_photo_metadata_id if exist or create audit_photo_metadata and return audit_photo_metadata_id
 * @param audit_id 
 * @param checklist_id 
 * @param theme_id 
 * @param section_id 
 * @returns 
 */
export const useGetOrCreateAuditPhotoMetadata = (audit_id, checklist_id, theme_id, section_id) => {
    const { getPhotoMetadata } = useGetAuditPhotoMetadataId()
    const { onCreateAuditPhotoMetaData } = useCreateAuditPhotoMetadata(audit_id, checklist_id, theme_id, section_id)

    const getMetadataId = useCallback(
        () => {
            return new Promise<number>(
                resolve => {
                    getPhotoMetadata({ audit_id, checklist_id, theme_id, section_id })
                        .then(metadataId => {
                            if (metadataId !== -1) {
                                console.log("No metadata id ")
                                resolve(metadataId)

                            } else {
                                onCreateAuditPhotoMetaData({ audit_id, checklist_id, theme_id, section_id })
                                    .then(newMetadata => {
                                        let newId = newMetadata?.data?.insert_qat_audit_photo_metadata_one?.id;
                                        if (newId) {
                                            resolve(newId)
                                        } else {
                                            resolve(-1)
                                        }

                                    })
                            }
                        })
                }
            )
        },
        [getPhotoMetadata, audit_id, checklist_id, theme_id, section_id, onCreateAuditPhotoMetaData],
    )
    return { getMetadataId }



}
/**
 * get audi Photo data
 * @returns promise
 */
export const useGetAuditPhotoBeforeFoundMetdataIds = (audit_id, checklist_id, theme_id, section_id) => {
    const { getPhotoMetadata } = useGetAuditPhotoMetadataId()
    const {getAuditPhotoByMetadataId}=useGetAuditPhoto()
    const [auditPhotos, setAuditPhotos] = useState<get_audit_photo_data[]>([])       
     
    useEffect(() => {
        getPhotoMetadata({
            audit_id, checklist_id, theme_id, section_id
        }).then(auditPhotoMetadataId=>{        
            if (auditPhotoMetadataId!==-1){
                getAuditPhotoByMetadataId({audit_photo_metadata_id:auditPhotoMetadataId}).then(auditPhotosVar=>{
                    if (auditPhotosVar){
                        setAuditPhotos(auditPhotosVar)
                    }
                })
            }
        })    
    },[audit_id, checklist_id, getAuditPhotoByMetadataId, getPhotoMetadata, section_id, theme_id])

    return { auditPhotos };
};

