import { useQuery } from '@apollo/client';
import { useContext, useEffect, useState } from 'react';
import AppContext from '../../../../AppContext';
import { NotificationType } from '../../../Notification/NotificationComponent';
import { Category } from '../../EntityModel';
import { categories_data } from './CategoryReadModel';
import { READ_ALL_CATEGORIES } from './CategoryReadQuery';

/**
 * Description - service to get all categories
 */
export const useGetAllCategories = () => {
  const { language, setNotification } = useContext(AppContext);
  const [categoriesLoading, setCategoriesLoading] = useState(true);

  const { data, loading, error } = useQuery<categories_data>(
    READ_ALL_CATEGORIES,
    {
      onError: () => {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    },
  );

  const [categoryList, setCategoryList] = useState<Array<Category>>([]);
  useEffect(() => {
    if (!loading && !error && data && data.qat_category_location) {
      const newCategoryList: Array<Category> = [];
      data.qat_category_location.forEach(category => {
        const { name_fr, name_nl, id } = category;
        const viewCategory: Category = {
          id,
          name: language.substring(0, 2) === 'fr' ? name_fr : name_nl,
        };
        newCategoryList.push(viewCategory);
      });
      setCategoryList(newCategoryList);
      setCategoriesLoading(false);
    } else if (error) {
      setCategoriesLoading(false);
    }
  }, [data, error, loading, language]);

  return { data: categoryList, loading: categoriesLoading, error };
};
