import { Box, Button, ButtonGroup, Grid, makeStyles } from "@material-ui/core";
import React, { useState, useMemo, useCallback} from "react";
import { Camera, FACING_MODES  } from "react-html5-camera-photo";
import './ChecklistAuditPhotoOverwrite.css';
import ImagePreview from "./ChecklistAuditPhotoImagePreviewComponent";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { currentAuditIdVar } from '../Checklist/ChecklistCache';
import { useGetOrCreateAuditPhotoMetadata } from "../../services/ChecklistCameraPhoto/ReadService/AuditPhotoReadService";
import { useCreateAuditPhoto } from "../../services/ChecklistCameraPhoto/WriteService/AuditPhotoWriteMutationService";
import { b64toBlob } from "../Shared/utils/functions";
import routes from "../../routes";
import InfoDialog from "../Shared/Dialog/InfoDialog/InfoDialogComponent";
import { useBrowserLocationState } from "../Shared/BrowserLocationState/BrowserLocationState";



export const checklistAuidtPhotoTestIds = {

    checklistAuidtPhotoSaveBtn: 'checklistAuidtPhoto-save-btn',
    checklistAuidtPhotoCancelBtn: 'checklistAuidtPhoto-cancel-btn',
    checklistAuidtPhotoOtherPictureBtn: 'checklistAuidtPhoto-otherpicture-btn',

};



const useStyles = makeStyles(theme => ({

    gridContainer: {
        width: 'calc(100wh - 60px)',
        Height: 'calc(100wh - 60px)',
        gridTemplateRows: '90% 10%',
        padding: '10px'
    },
    gridCameraContainer: {
        width: '80vh',
        Height: '80vh',
    },

    grey: {
        backgroundColor: '#d3d3d3',
        marginTop: 10,
        '&:hover': {
            background: '#afafaf',
        },
        color: '#141414',
    },
    alignButton: {
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        justifyItems: 'center',
        minWidth: '60vh'


    },
    buttontakeOtherPhoto: {
        '&:hover': {
            backgroundColor: theme.palette.success.main,
            color: 'white',
        },
        backgroundColor: theme.palette.success.main,
        color: 'white',
        width: '100%',
        borderRadius: '15px important!'
    },
    buttonSavePhoto: {
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: 'white',
        },
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        width: '100%',
        borderRadius: '15px important!'
    },
    buttonCancel: {
        backgroundColor: '#d3d3d3',
        '&:hover': {
            background: '#afafaf',
        },
        color: '#141414',
        width: '100%',
        borderRadius: '15px important!'
    },
    ButtonBox: {
        display: 'grid',
        width: '100%',
        justifyItems: 'center'
    }


}));
const TakeImageComponent = () => {
    
    const currentLocationState = useBrowserLocationState<{checklistId: number, themeId: number, sectionId: number, building: any, isReadOnlyMode?: boolean}>({keysRequired: ["checklistId", "themeId", "sectionId", "building"]})

    const currentAuditId = useMemo(() => currentAuditIdVar(),[])
    const currentChecklistId = useMemo(() => currentLocationState?.checklistId ? currentLocationState?.checklistId : -1, [currentLocationState])
    const currentThemeId = useMemo(() => currentLocationState?.themeId ? currentLocationState?.themeId : -1, [currentLocationState])
    const currentSectionId = useMemo(() => currentLocationState?.sectionId ? currentLocationState?.sectionId : undefined, [currentLocationState])

    const { getMetadataId } = useGetOrCreateAuditPhotoMetadata(currentAuditId, currentChecklistId, currentThemeId, currentSectionId)
    const { onCreateAuditPhoto } = useCreateAuditPhoto({audit_id: currentAuditId, checklist_id: currentChecklistId})



    const [dataUri, setDataUri] = useState('');
    const classes = useStyles();
    const { t } = useTranslation();
    const history = useHistory()

    const handleTakePhotoAnimationDone = useCallback((photoDataUri) => {
        setDataUri(photoDataUri);
    },[])

    const handleCameraClose = useCallback(() => {
        history.push(
            {
                pathname: routes.editChecklist.path
            }
        )
    },
        [history],
    )

    const handleSave = useCallback(() => {
        getMetadataId().then(result => {
            if (result && result !== -1) {
                let fileName: string = `${currentAuditId}_${currentChecklistId}_${currentThemeId}_${currentSectionId}.jpg`
                const myBlob: Blob = b64toBlob(dataUri?.split(';base64,').pop(), 'image/jpeg')
                const myFile = new File([myBlob], fileName, {
                    type: myBlob.type
                })
                onCreateAuditPhoto(result, myFile, `${currentAuditId}_${currentChecklistId}_${currentThemeId}_${currentSectionId}`, 'image/jpg').then(() => {
                    handleCameraClose()
                })
            }
            else {
                console.error("The picture has not been added")
            }

        })
    },[getMetadataId, onCreateAuditPhoto, handleCameraClose, currentAuditId, currentChecklistId, currentSectionId, currentThemeId, dataUri])


    const handleTakeOther = useCallback(() => {
        setDataUri("")
    },[])

    const [open, setOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleCameraError = useCallback((error) => {
        console.error('handleCameraError', error);
        setErrorMessage(t('checklist:errorStartCamera'))
        setOpen(true)

    },[t])

    const viewMode: "camera" | "preview" = useMemo(() => dataUri ? "preview" : "camera", [dataUri])

    const isFullscreen = useMemo(() => true,[])
    return (

        <Grid className={classes.gridContainer}>
            <Grid item  >
                
                    { viewMode === "preview" &&
                        <ImagePreview dataUri={dataUri}
                            isFullscreen={isFullscreen}
                            
                        />
                    }
                    { viewMode === "camera" &&
                        <Camera
                        imageType={'jpg'}
                        onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
                        isFullscreen={isFullscreen}
                        imageCompression={1}
                        sizeFactor={1}
                        idealFacingMode = {FACING_MODES.ENVIRONMENT}
                        onCameraError={(error) => { handleCameraError(error); }}
                        idealResolution={{ width: 640, height: 480 }}
                        isDisplayStartCameraError={false}
                        isImageMirror={false}
                    />
                    }
                
            </Grid>

            <Grid item  >
                <Box className={classes.ButtonBox} >
                    <ButtonGroup className={classes.alignButton}
                        disableElevation
                        orientation="vertical"
                    >

                        {dataUri &&

                            (<Button data-testid={checklistAuidtPhotoTestIds.checklistAuidtPhotoOtherPictureBtn} className={classes.buttontakeOtherPhoto}
                                onClick={() => handleTakeOther()}>
                                {t('checklist:takeAndOthePicture')}
                            </Button>)}
                        {dataUri &&
                            <Button data-testid={checklistAuidtPhotoTestIds.checklistAuidtPhotoSaveBtn} className={classes.buttonSavePhoto} onClick={() => handleSave()}>
                                {t('common:validate')}
                            </Button>

                        }
                        <Button data-testid={checklistAuidtPhotoTestIds.checklistAuidtPhotoCancelBtn} className={classes.buttonCancel} onClick={() => handleCameraClose()}>{t('common:cancel')} </Button>

                    </ButtonGroup>
                </Box>

            </Grid>
            <InfoDialog
                open={open}
                onClose={() => setOpen(false)}
                title='Info'
                text={errorMessage}
            />
        </Grid>



    );
}
export default TakeImageComponent;