import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useHistory } from 'react-router-dom';
import routes from "../../../routes";
import _ from "lodash"
import { useGetNotApplicableItemsThemeSubThemeOverviewByAuditId } from "../../../services/NotApplicableOverview/NotApplicableItemService";
import { sortAlphaNum } from "../../Shared/utils/order.utils";
import AppContext from "../../../AppContext";
 
 
export interface ReportAddress{ 
  building_id: number;
  building_index: number;
  box_number:string;
  city:string;
  house_number:string;
  street:string; 
}

export interface ReportItems{ 
  addres: ReportAddress;  
  id: number;
  name_nl: string;
  name_fr: string;
  comment: string;
  norm_code: string;
  norm_pdf_url?:string;
  not_application: boolean;
  page_pdf_fr: string;
  page_pdf_nl: string;
 long_name_fr:string;
 long_name_nl:string;
 specification:string
}

export interface ThemeAndSubtheme{
  addres: ReportAddress;
  name_fr:string;
  name_nl:string;
  fromTheme:boolean;
}

export const useChecklistReportNotApplicablePresenter = (receivedAudit) => {
   const audit = useMemo(() => {
    if (receivedAudit){
      return receivedAudit
    } else {
      console.error("the auditIt is required")
      return ""
    }
  }, [receivedAudit])
  const history = useHistory();  
  const [noAppOverviewDetails, setNoAppOverviewDetails] = useState<any>({})
  const [isLoading, setIsLoading] = useState<boolean>(false);  
  const { getNotApplicableItemsThemeSubThemeOverviewByAuditId } = useGetNotApplicableItemsThemeSubThemeOverviewByAuditId(audit ? audit.id:-1)
  const { language } = useContext(AppContext);  

  const mapItems = useCallback( (_noAppOverviewDetails) => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        const groupingCriteria = language.substring(0, 2) === 'fr' ? 'name_fr' : 'name_nl'
        const groupingSortItemCriteria = "norm_code"
        const groupByItems = _.values(_.groupBy((_noAppOverviewDetails?.items?.sort((a, b) => sortAlphaNum(a, b, groupingSortItemCriteria))),groupingCriteria ))
        const groupByThemes = _.values(_.groupBy(_.sortBy(_noAppOverviewDetails?.themes, groupingCriteria),groupingCriteria))
        const groupBySubthemes = _.values(_.groupBy(_.sortBy(_noAppOverviewDetails?.subthemes, groupingCriteria),groupingCriteria))
           
        _noAppOverviewDetails.items = groupByItems?.map((group_items) => {
                  const groupItemByBuildings = _.values(_.groupBy(group_items, (group_item) =>
                    group_item?.addres?.building_id))
                  return groupItemByBuildings
                })

        const unionThemeAndSubtheme = _.concat(groupByThemes, groupBySubthemes)
        _noAppOverviewDetails.themes = unionThemeAndSubtheme?.map((group_themes) => {
          let groupThemeByBuildings =  _.values(_.groupBy(group_themes, (group_theme) =>
            group_theme?.addres?.building_id))
          return groupThemeByBuildings
        })            
   
        resolve(_noAppOverviewDetails)
      })
    })
  },[language])

  useEffect(() => {
    if (!audit)
      history.push({ pathname: routes.currentAudits.path })
  }, [audit, history]) 

  useEffect(() => {   

    let listItems: ReportItems[]=[]
    let listThemes: ThemeAndSubtheme[]=[]
    let listSubthemes: ThemeAndSubtheme[]=[]
    setIsLoading(true)
    getNotApplicableItemsThemeSubThemeOverviewByAuditId().then(async ( _response)=>{
      
      let items = _response?.qat_checklist_theme_item
      let listOfThemes = _response?.qat_checklist_theme_section
      let listOfItemFromSubThemes= _response?.sub_theme_items    
      let listOfSubThemes = _response?.qat_checklist_theme_sub_section

      items?.map(it => {
        const { box_number, city, house_number, street }: { box_number: string, city: string, house_number: string, street: string } =
          { ...it?.checklist_theme_section?.checklist_theme?.checklist?.building?.address }
        const { id, name_nl, name_fr, comment, norm_code, not_application, page_pdf_fr,
          page_pdf_nl }: {
            id: number,
            name_nl: string, name_fr: string, comment: string, norm_code: string, not_application: boolean,
            page_pdf_fr: string, page_pdf_nl: string
          } = { ...it }
        const { id: building_id, index: building_index}: { id:number, index:number, specification: string}=
         {...it?.checklist_theme_section?.checklist_theme?.checklist?.building}
       //theme or subteme long name
        const { long_name_fr, long_name_nl }: { long_name_fr: string, long_name_nl: string } ={...it?.checklist_theme_section?.checklist_theme}
        const { specification } = it?.checklist_theme_section
         const value: ReportItems = {          
          addres: { building_id, building_index, box_number, city, house_number, street },
          id, name_nl, name_fr, comment, norm_code, not_application, page_pdf_fr,
           page_pdf_nl, long_name_fr, long_name_nl, specification

        }        
        listItems.push(value)
      })
      
     
      listOfItemFromSubThemes?.map(itms =>{
        let itemSub = itms?.checklist_theme_items
        itemSub?.map(it=>{
        const { box_number, city, house_number, street }: { box_number: string, city: string, house_number: string, street: string } =
          { ...it?.checklist_theme_sub_section?.checklist_theme_section?.checklist_theme?.checklist?.building?.address }
         
        const { id, name_nl, name_fr, comment, norm_code, not_application, page_pdf_fr,
          page_pdf_nl}: {
          id: number,
          name_nl: string, name_fr: string, comment: string, norm_code: string, not_application: boolean, page_pdf_fr: string,
          page_pdf_nl: string
        } = { ...it }
        const { id: building_id, index: building_index }: { id: number, index: number } =
          { ...it?.checklist_theme_sub_section?.checklist_theme_section?.checklist_theme?.checklist?.building}
       //theme or subtheme name
        const { long_name_fr, long_name_nl }: { long_name_fr: string, long_name_nl: string } = { ...it?.checklist_theme_sub_section?.checklist_theme_section?.checklist_theme }
        const { specification } = it?.checklist_theme_sub_section?.checklist_theme_section
        const value: ReportItems = {       
          addres: { building_id, building_index, box_number, city, house_number, street },
            id, name_nl, name_fr, comment, norm_code, not_application, page_pdf_fr,
          page_pdf_nl, long_name_fr, long_name_nl, specification }
        
        listItems.push(value)
          })
      })
 
      listOfThemes?.map(theme => {
        const { box_number, city, house_number, street }: { box_number: string, city: string, house_number: string, street: string } =
          { ...theme?.checklist_theme?.checklist?.building?.address }
        const { short_name_fr, short_name_nl }: { short_name_fr: string, short_name_nl: string } = { ...theme?.checklist_theme }
       
        const { id: building_id, index: building_index }: { id: number, index: number } =
          { ...theme?.checklist_theme?.checklist?.building }
        listThemes.push(      {          
          addres: { building_id, building_index ,box_number, city, house_number, street }, name_fr: short_name_fr, name_nl: short_name_nl, fromTheme: true })
      })
    
          
      listOfSubThemes?.map(sub=>{       
        const { name_fr, name_nl }: { name_fr: string, name_nl: string } = { ...sub }
        const { box_number, city, house_number, street }: { box_number: string, city: string, house_number: string, street: string } =
          { ...sub?.checklist_theme_section?.checklist_theme?.checklist?.building?.address }
       
        const { id: building_id, index: building_index }: { id: number, index: number } =
          { ...sub?.checklist_theme_section?.checklist_theme?.checklist?.building }
        listSubthemes.push({ addres: { building_id, building_index, box_number, city, house_number, street }, name_fr: name_fr, name_nl: name_nl, fromTheme:false})
      })  
     
    let _noAppOverview:any={}
      _noAppOverview.audit =receivedAudit
      _noAppOverview.items=listItems
      _noAppOverview.themes=listThemes
      _noAppOverview.subthemes=listSubthemes

      mapItems(_noAppOverview).then((_noAppOverviewvar)=>{
        setNoAppOverviewDetails(_noAppOverviewvar)
        setIsLoading(false)        
      })           

    })
  }, [audit, mapItems, getNotApplicableItemsThemeSubThemeOverviewByAuditId, receivedAudit])

   return {  isLoading, noAppOverviewDetails};
};
