import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(checklistRemark => ({
  root: {},
  mainInfoBar: {
    width: '100%',
  },
  textarea: {
    width: '100%',
  },
  textareaDesktop: {
    width: '99%',
  },
  title: {
    fontWeight: 'bold',
  },
  returnButton: {
    color: 'white',
    backgroundColor: '#808080',
    '&:hover': {
      backgroundColor: '#808080',
    },
    float: 'right',
  },
  divButton: {
    width: '100%',
    height: '40px',
  },
  generalRemarkLabel: {
    fontWeight: 'bold',
  },
  checklistRemarkTitle: {
    fontWeight: 'bold',
    color: checklistRemark.palette.primary.main,
  },
  checklistThemeRemarkTitle: {
    fontWeight: 'bold',
  },
  checklistThemeRemarkInfoBar: {
    width: '100%',
    position: 'relative',
  },
}));

export { useStyles };
