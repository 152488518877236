import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  containerCloseButton: {
    width: '100%',
  },
  closeButton: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textTransform: 'none',
    width: '100%',
    color: 'white',
  },

  title: {
    padding: theme.spacing(2),
  },
  listItem: {
    transition: 'background-color 0.5s',
    backgroundColor: theme.palette.primary.main,
    border: '1px solid',
    borderBlockStyle: 'none',
    color: 'white',
    '&$selected': {
      backgroundColor: '#293d46',
      color: 'white',
    },
    '&$selected:hover': {
      backgroundColor: '#293d46',
      color: 'white',
    },
    '&:hover': {
      backgroundColor: '#293d46',
      color: 'white',
    },
  },
  selected: {},
  themeValidatedColor: { backgroundColor: theme.palette.success.main },
  themeValidatedDefaultColor: { backgroundColor: theme.palette.primary.main },
}));
