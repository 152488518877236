import { Button } from '@material-ui/core';
import React from 'react';

/**
 * This component is responsible to display and handle the current language input (nl/fr)
 */
const LanguageMenuView = ({ classes, context }) => {
  return (
    <div className={classes?.languageButtons}>
      <Button
        variant="outlined"
        size="small"
        onClick={() => context?.setLanguage('nl')}
        className={classes?.languageButton}
      >
        nl
      </Button>
      <Button
        variant="outlined"
        size="small"
        onClick={() => context?.setLanguage('fr')}
        className={classes?.languageButton}
      >
        fr
      </Button>
    </div>
  );
};

export default LanguageMenuView;
