import { InMemoryCache, makeVar } from '@apollo/client';
import { SubThemeFormData } from './SubThemeFormModel';

export const openCreateSubThemeVar = makeVar(false);
export const openUpdateSubThemeVar = makeVar(false);
export const openItemSubThemeVar = makeVar(false);
export const openSubThemeTableVar = makeVar(false);
export const lastSubThemeIndexVar = makeVar(-1);

export const currentThemeId = makeVar(-1);

export const defaultSubTheme: SubThemeFormData = {
  name_fr: '',
  name_nl: '',
  theme_template_checklist_id: -1,
  not_applicable_option: false,
  index: -1,
};

export const subThemeVar = makeVar(defaultSubTheme);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        read() {
          return (
            openCreateSubThemeVar(),
            openUpdateSubThemeVar(),
            openItemSubThemeVar(),
            openSubThemeTableVar(),
            lastSubThemeIndexVar(),
            subThemeVar()
          );
        },
      },
    },
  },
});
