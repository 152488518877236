import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { debounce } from 'lodash';
import { useUpdateChecklistThemeSection } from '../../../services/ChecklistSection/ChecklistThemeSectionWriteService';
import { FormControl, makeStyles, OutlinedInput } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { NotificationType } from '../../Notification/NotificationComponent';
import { useBeforeunload } from 'react-beforeunload';
import AppContext from '../../../AppContext';

export const useStyles = makeStyles(theme => ({
  formControl: {
    position: 'relative',
    paddingLeft: theme.spacing(1),
  },
  input: {
    padding: theme.spacing(1.75),
    fontSize: 12,
    width: 150,
    height: 3,
  },
}));
export interface ChecklistThemeSectionSpecificationProps {
  dataTestId?: string;
  sectionsCounter: number;
  specification: string;
  sectionId?: number;
  checklistId: number;
  isReadOnlyMode: boolean;
}

export interface ChecklistThemeSectionSpecificationServiceResult {
  onChange: (event: React.ChangeEvent<{}>) => void;
  value: string;
  open: boolean;
  valid: boolean;
}

export const useChecklistThemeSectionSpecificationService = ({
  sectionsCounter,
  specification,
  sectionId,
  checklistId,
}: ChecklistThemeSectionSpecificationProps): ChecklistThemeSectionSpecificationServiceResult => {
  const { setNotification } = useContext(AppContext);
  const [
    specificationInsertValue,
    setSpecificationInsertValue,
  ] = useState<string>(specification);
  const {
    onUpdate: updateChecklistThemeSection,
  } = useUpdateChecklistThemeSection(checklistId);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  useEffect(() => {
    setSpecificationInsertValue(specification);
  }, [specification]);

  const debouncedSave = useCallback(
    debounce(
      (nextValue, _sectionId) =>
        updateChecklistThemeSection(_sectionId, {
          specification: nextValue,
        })?.then(response => {
          handleSetState(false);
        }),
      1000,
    ),
    [],
  );

  const handleSetState = useCallback(isSavingVar => {
    setIsSaving(isSavingVar);
  }, []);

  useBeforeunload(event => {
    if (isSaving === true) {
      event.preventDefault();
      setNotification({ key: 'common:saving', type: NotificationType.WARNING });
      return null;
    }
  });

  const handleChangeSpecification = event => {
    handleSetState(true);
    const { value: nextValue } = event.target;
    setSpecificationInsertValue(nextValue);
    debouncedSave(nextValue, sectionId);
  };

  // show specification field if there are more of one section
  const openSpecificationComponent = useMemo(() => sectionsCounter > 1, [
    sectionsCounter,
  ]);
  // valid if there more of one section and value is not empty
  const valid = useMemo(
    () =>
      sectionsCounter <= 1 ||
      (sectionsCounter > 1 &&
      specificationInsertValue &&
      specificationInsertValue !== ''
        ? true
        : false),
    [sectionsCounter, specificationInsertValue],
  );

  return {
    onChange: handleChangeSpecification,
    value: specificationInsertValue,
    open: openSpecificationComponent,
    valid,
  };
};

const ThemeChecklistSectionSpecificationComponent: React.FC<ChecklistThemeSectionSpecificationProps> = ({
  dataTestId,
  sectionsCounter,
  specification,
  sectionId,
  checklistId,
  isReadOnlyMode
}) => {
  const { t } = useTranslation();
  const {
    onChange,
    value,
    open,
    valid,
 
  } = useChecklistThemeSectionSpecificationService({
    sectionsCounter,
    specification,
    sectionId,
    checklistId,
    isReadOnlyMode
  });
  const classes = useStyles();
  const readOnlyModeOnChange = e => e.preventDefault();

  return (
    <Fragment>
      {open && (
        <FormControl
          className={classes.formControl}
          size="small"
          error={!valid}
          component="div"
        >
          <OutlinedInput
            classes={{
              input: classes.input,
            }}
            required
            data-testid={dataTestId}
            id={`specification-section-${sectionId}`}
            name="specification"
            aria-label="specification"
            fullWidth
            value={value}
            placeholder={t('checklist_theme_section:specificationError')}
            onChange={isReadOnlyMode ? readOnlyModeOnChange : onChange}
          />
        </FormControl>
      )}
    </Fragment>
  );
};

export default ThemeChecklistSectionSpecificationComponent;
