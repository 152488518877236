import { gql } from '@apollo/client';

/**
 * Description - graphql mutation to create a checklist item
 */

export const UPDATE_CHECKLIST_ITEM = gql`
  mutation update_qat_checklist_theme_item_by_pk(
    $id: Int!
    $qat_checklist_theme_item_set_input: qat_checklist_theme_item_set_input!
  ) {
    update_qat_checklist_theme_item_by_pk(
      pk_columns: { id: $id }
      _set: $qat_checklist_theme_item_set_input
    ) {
      id
      not_application
      is_conform
      has_interview
      comment
    }
  }
`;

export const UPDATE_CHECKLIST_LIST_OF_ITEMS = gql`
  mutation update_qat_checklist_theme_item($ids: [Int!]) {
    update_qat_checklist_theme_item(
      where: { id: { _in: $ids } }
      _set: { is_conform: true }
    ) {
      affected_rows
    }
  }
`;

// export const UPDATE_CHECKLIST_LIST_OF_ITEMS = gql`
// mutation update_qat_checklist_theme_item (
//   $ids:[Int!]
//   ){
//   update_qat_checklist_theme_item_by_pk(
//     where:
//    {id: {_in: $ids}}, _set: {is_conform: true}
//   }
//    {
//       id
//       not_application
//       is_conform
//       has_interview
//       comment
//     }
// `
// ;
