import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    width: '100%',
  },
  title: {
    flexGrow: 1,
    color: '#141414',
  },
  editAddressButton: {
    marginLeft: 25,
  },
  buttonValidateAdd: {
    color: 'white',
    backgroundColor: theme.palette.success.main,
    marginBottom: 10,
  },
  grey: {
    backgroundColor: '#d3d3d3',
    marginTop: 10,
    '&:hover': {
       background: '#afafaf',
    },
    color: '#141414',
  },

  outlineErrorColor: {
    color:'#F44336'
  },
}));

export { useStyles };
