export const subtheme = {
  editSubTheme: 'Editer le sous-thème',
  subTheme: 'Sous-thème',
  addSubThemeTemplate: 'Ajouter un sous-thème',
  createSubTheme: 'Ajouter un sous-thème',
  name: 'Nom',
  nameFr: 'Nom (Français)',
  nameNl: 'Nom (Néerlandais)',
  nameFrError: 'Nom (Français) est un champ obligatoire',
  nameNlError: 'Nom (Néerlandais) est un champ obligatoire',
  theme_name: 'Thème',
  validateAdd: 'Ajouter un autre sous-thème',
  subthemeTableTitle:
    'Liste des Sous-thème du thème " {{0}}" de la CheckList "{{1}}"',
  notApplicable: "Option pas d'application",
  nameFrUniqueError: "Il existe déjà un sous thème avec ce nom en français",
  nameNlUniqueError: "Il existe déjà un sous thème avec ce nom en néerlandais"
};
