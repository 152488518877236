import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { DELETE_THEME_TEMPLATE_CHECKLIST } from '.';
import { READ_THEME_TEMPLATE_CHECKLIST_BY_TEMPLATE_ID } from '../ThemeReadService';

export interface ThemeDeleteServiceProps {
  templateChecklistId: number;
}

export const useThemeDeleteService = ({
  templateChecklistId,
}: ThemeDeleteServiceProps) => {
  const { setNotification } = useContext(MainAppContext);

  const [deleteTheme] = useMutation(DELETE_THEME_TEMPLATE_CHECKLIST, {
    refetchQueries: [
      {
        query: READ_THEME_TEMPLATE_CHECKLIST_BY_TEMPLATE_ID,
        variables: { templateChecklistId },
      },
    ],
    awaitRefetchQueries: true,
    onCompleted() {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const onDelete = async (id: number) => {
    return deleteTheme({
      variables: { id: id, deletedDate: new Date() },
    });
  };

  return { onDelete };
};
