import { Collapse, Grid, Paper, Typography, Box } from '@material-ui/core';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import AppContext from '../../../AppContext';
import { translate } from '../../../services/Translation/TranslationService';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import IconButton from '@material-ui/core/IconButton';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import ChecklistThemeSubSectionComponent from '../../ChecklistThemeSubSection/ChecklistThemeSubSectionComponent';
import { IndeterminateCheckBoxOutlined } from '@material-ui/icons';
import ChecklistThemeSectionSpecification from '../ChecklistThemeSectionSpecification';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ChecklistItemComponent from '../../ChecklistItem/ChecklistItemComponent';
import clsx from 'clsx';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core';
import { get_checklist_by_pk_section_data } from '../../../services/Checklist/ChecklistReadService/ChecklistReadQuery';
import { useHistory } from 'react-router-dom'
import routes from '../../../routes';
import { currentAuditIdVar } from '../../Checklist/ChecklistCache';
import { useGetAuditPhotoBeforeFoundMetdataIds } from '../../../services/ChecklistCameraPhoto/ReadService/AuditPhotoReadService';
import { building_data } from '../../../services/Building/BuildingDataModel';
import { audit_data } from '../../../services/Audit/AuditDataModel';


export const testIds = {
  buttonToDuplicate: 'checklist-theme-section-button-to-duplicate',
  buttonToDelete: 'checklist-theme-section-button-to-delete',
  inputFieldSpecification: 'checklist-theme-section-input-field-specification',
  checkAllButton: 'checklist-theme-section-check-all-button',
  addPhotoButton: 'checklist-theme-section-add-photo-button',
  buttonToSetNotApplication:
    'checklist-theme-section-set-not-application-button',
};
export interface ChecklistThemeSectionViewProps {
  sectionsCounter: number;
  section: get_checklist_by_pk_section_data;
  onDuplicate: () => void;
  onDelete: (id: number) => void;
  disabledRemoveButton: boolean;
  onUpdateNotApplication: (id, no_application) => void;
  themeNotApplicationValue: boolean;
  colorNotApplicationButton: 'error' | 'disabled';
  onExpand: () => void;
  expanded: boolean;
  checklistId: number;
  onConformChanged: (event, section) => void;
  checkSectionValidated: (
    sectionToBorder: get_checklist_by_pk_section_data,
    sectionNumber: number,
    themeNotApplicationValue: boolean
  ) => boolean;
  isReadOnlyMode: boolean;
  themeNotApplicationOptionValue: boolean;
  building: building_data;
  audit: audit_data;
}

export const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    border: `3px solid ${theme.palette.primary.main[200]}`,
    marginBottom: theme.spacing(0.3),
  },
  section: {
    borderBottom: '1.5px solid #293d46',
  },
  leftPart: {
    display: 'flex',
    alignItems: 'center',
  },
  centerPart: {
    display: 'flex',
    alignItems: 'left',
  },
  rightPart: {
    display: 'flex',
    alignItems: 'right',
  },
  title: {
    color: '#ffffff',
    fontWeight: 'bold',
    backgroundColor: '#cf012c',
    padding: '4px 7px',
    fontSize: '0.875rem',
  },
  cellInput: {
    padding: theme.spacing(0.5),
    width: 160,
  },
  button: {
    padding: theme.spacing(1.5),
  },
  collapseHidden: {
    height: 0,
  },
  conform: {
    color: '#4caf50',
  },
  notConform: {
    color: '#FF0000',
  },
  default: {
    color: '#808080',
  },
  photoPart: {
    display: 'flex',
    alignItems: 'right',
  },
  conformButton: {
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(0.75),
  },
  photoButton: {},
  collapseButton: {
    padding: theme.spacing(2),
  },
  defaultSectionBorder: {
    border: `2px solid ${theme.palette.primary.main}`,
  },
  validateSectionBorder: {
    border: `2px solid ${theme.palette.success.main}`,
  },
  hasPhoto: {
    color: theme.palette.warning.main,
  },
  noPhoto: {
    fontSize: '35px',
    color: '#808080'

  }
}));

const ChecklistThemeSectionView: React.FC<ChecklistThemeSectionViewProps> = ({
  disabledRemoveButton,
  sectionsCounter,
  section,
  onDuplicate,
  onDelete,
  onUpdateNotApplication,
  themeNotApplicationValue,
  colorNotApplicationButton,
  expanded,
  onExpand,
  checklistId,
  onConformChanged,
  checkSectionValidated,
  isReadOnlyMode,
  themeNotApplicationOptionValue,
  building,
  audit

}) => {
  const classes = useStyles();
  const { language } = useContext(AppContext);

  const history = useHistory()
  const currentAuditId = currentAuditIdVar();
  const currentThemeId = section?.checklist_theme_id;
  const currentSectionId = section?.id;
  const [isAuditPhoto, setIsAuditPhoto] = useState<boolean>(false)

  const { auditPhotos } = useGetAuditPhotoBeforeFoundMetdataIds(currentAuditId, checklistId, currentThemeId, currentSectionId)


  useEffect(() => {
    if (auditPhotos && auditPhotos.length>0) {
      setIsAuditPhoto(true)
    }
    else { setIsAuditPhoto(false) }

  }, [auditPhotos])

  const showCamera = useCallback(() => {
    history.push({
      pathname: routes.cameraPhoto.path,
      state: {
        currentAuditId: currentAuditId,
        checklistId: checklistId,
        themeId: currentThemeId,
        sectionId: currentSectionId,
        isReadOnlyMode: isReadOnlyMode,
        building: building,
        audit: audit

      },
    });
  }, [history, currentAuditId, checklistId, currentThemeId, currentSectionId, isReadOnlyMode, building, audit])

  const isgreenBorder: boolean = useMemo(() => checkSectionValidated(section, sectionsCounter, themeNotApplicationValue), [section, sectionsCounter, themeNotApplicationValue, checkSectionValidated])

  return (
    <>
      <Paper
        className={clsx(
          classes.paper,
          isgreenBorder
            ? classes.validateSectionBorder
            : classes.defaultSectionBorder,
        )}
      >
        <Grid container className={classes.section}>
          <Grid lg={9} xs={7} item className={classes.leftPart}>
            <Typography className={classes.title}>{section.theme_template_checklist && translate(section, language)}</Typography>
            {!themeNotApplicationValue && sectionsCounter > 1 && (
              <ChecklistThemeSectionSpecification
                dataTestId={testIds.inputFieldSpecification}
                specification={section.specification}
                sectionId={section?.id}
                sectionsCounter={sectionsCounter}
                checklistId={checklistId}
                isReadOnlyMode={isReadOnlyMode}
              />
            )}
            {themeNotApplicationOptionValue &&
              sectionsCounter <= 1 && (
                <IconButton
                  className={classes.button}
                  data-testid={testIds.buttonToSetNotApplication}
                  onClick={e => {
                    e.stopPropagation();
                    onUpdateNotApplication(section?.id, section?.no_application);
                  }}
                  aria-label="button-to-set-not-application"
                  disabled={isReadOnlyMode}
                >
                  <CancelPresentationIcon
                    style={{
                      fontSize: '35px',
                      color: colorNotApplicationButton,
                    }}
                    color={!themeNotApplicationValue || isReadOnlyMode ? 'disabled' : 'error'}
                  />
                </IconButton>
              )}
            {section.theme_template_checklist?.duplicable &&
              !themeNotApplicationValue && (
                <IconButton
                  className={classes.button}
                  data-testid={testIds.buttonToDuplicate}
                  onClick={e => {
                    e.stopPropagation();
                    onDuplicate();
                  }}
                  aria-label="button-to-duplicate-section"
                  disabled={isReadOnlyMode}
                >
                  <AddBoxOutlinedIcon
                    style={{ fontSize: '35px', color: '#4caf50' }}
                  />
                </IconButton>
              )}
            {section.theme_template_checklist?.duplicable &&
              sectionsCounter > 1 &&
              !themeNotApplicationValue && (
                <IconButton
                  className={classes.button}
                  disabled={disabledRemoveButton || isReadOnlyMode}
                  data-testid={testIds.buttonToDelete}
                  onClick={e => {
                    e.stopPropagation();
                    onDelete(section.id);
                  }}
                  aria-label="button-to-remove-section"
                >
                  <IndeterminateCheckBoxOutlined
                    style={{ fontSize: '35px' }}
                    color={
                      disabledRemoveButton || isReadOnlyMode
                        ? 'disabled'
                        : 'error'
                    }
                  />
                </IconButton>
              )}

          </Grid>
          <Grid item lg={3} xs={5}>
            <Grid container>
              <Grid xs={4} item>
                {expanded && !themeNotApplicationValue && (
                  <Box ml={isMobile ? 5 : 0}>
                    <IconButton
                      className={classes.conformButton}
                      onClick={e => onConformChanged(e, section)}
                      aria-label="button-to-check-all-item"
                      data-testid={testIds.checkAllButton}
                      disabled={isReadOnlyMode}
                    >
                      <CheckBoxOutlinedIcon
                        style={{ fontSize: '35px' }}
                        className={classes.conform}
                      />
                    </IconButton>
                  </Box>
                )}
              </Grid>
              <Grid item xs={4}></Grid>
              <Grid xs={2} item className={classes.photoPart}>
                {!themeNotApplicationValue && (
                  <IconButton
                    className={classes.photoButton}
                    onClick={e => { e.stopPropagation(); showCamera() }}
                    aria-label="button-to-add-photo"
                    data-testid={testIds.addPhotoButton}
                    disabled={isReadOnlyMode}
                  >
                    <PhotoCameraIcon className={isAuditPhoto ? classes.hasPhoto : classes.noPhoto}
                    />
                  </IconButton>
                )}
              </Grid>
              <Grid xs={2} item>
                <IconButton
                  className={classes.collapseButton}
                  aria-label="expand row"
                  size="small"
                  onClick={onExpand}
                >
                  {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid xs={12} item>
            <Collapse
              unmountOnExit
              timeout="auto"
              in={expanded}
              classes={{
                hidden: classes.collapseHidden,
              }}
            >
              {section.checklist_theme_items?.map(item => (
                <ChecklistItemComponent
                  key={item?.id}
                  item={item}
                  checklistId={checklistId}
                  isReadOnlyMode={isReadOnlyMode}
                />
              ))}
              {section.checklist_theme_sub_sections?.map(subSection => {
                return (
                  <ChecklistThemeSubSectionComponent
                    subSection={subSection}
                    key={subSection.id}
                    checklistId={checklistId}
                    sectionId={section.id}
                    isReadOnlyMode={isReadOnlyMode}
                  />
                );
              })}
            </Collapse>
          </Grid>
        </Grid>

      </Paper>

    </>

  );
};

export default ChecklistThemeSectionView;
