import AuditTableComponent, {
  AuditTableComponentProps,
  testIds,
} from './AuditTableComponent';

export default AuditTableComponent;

export { testIds };

export type { AuditTableComponentProps };
