import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  TextField,
  Checkbox,
  Box,
  ButtonGroup,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../../Shared/Styles/CommonFormSyles';
import { itemVar, openCreateItemVar } from '../ItemCache';
import { useReactiveVar } from '@apollo/client';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ItemDisplayData } from '../ItemDisplayData';
import { useCheckItemUnique } from '../../../services/Item/ReadService/ItemReadService';
import { useBrowserLocationState } from '../../Shared/BrowserLocationState/BrowserLocationState';

export const formDialogTestIds = {
  updateCreateThemeNameInput: 'theme-name',
  updateCreateTitleFrInput: 'item-titleFr-input',
  updateCreateTitleNlInput: 'item-titleNl-input',
  updateCreateNormCodeInput: 'item-normCoder-input',
  updateCreatePagePdfInputFr: 'item-pagePdf-input-fr',
  updateCreatePagePdfInputNl: 'item-pagePdf-input-nl',
  updateCreateInformationFrInput: 'item-informationFr-input',
  updateCreateInformationNlInput: 'item-informationNl-input',
  updateCreateNotApplicationOptionInput: 'item-notApplicationOption-input',
  applyUpdateBtn: 'item-applyUpdateBtn',
  cancelUpdateBtn: 'item-cancelUpdateBtn',
  applyCreateAddBtn: 'item-applyCreateAddBtn',
};
interface ItemFormComponentProps {
  isOpenForm: boolean;
  handleClose: any;
  handleFormSubmit: any;
  handleCreateAddItem?: any;
  templateThemeId?: number;
  templateSubThemeId?: number;
  id?: number;
}

const ItemFormComponent: React.FC<ItemFormComponentProps> = ({
  isOpenForm,
  handleClose,
  handleFormSubmit,
  handleCreateAddItem,
  templateThemeId,
  templateSubThemeId,
  id
}: ItemFormComponentProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const formItem: ItemDisplayData = useReactiveVar(itemVar);
  const isCreateItemForm = useReactiveVar(openCreateItemVar);
  const { onCheckItemUnique } = useCheckItemUnique()

  const schema = useMemo(() => {
    return yup.object().shape({
      id: yup.number(),
      title_fr: yup.string().nullable().required(t('item:titleFrError')),
      title_nl: yup.string().nullable().required(t('item:titleNlError')),
      norm_code: yup.string().nullable().required(t('item:normCodeError')),
      page_pdf_fr: yup.string().nullable().required(t('item:pagePdfFrError')),
      page_pdf_nl: yup.string().nullable().required(t('item:pagePdfNlError')),
      information_fr: yup.string(),
      information_nl: yup.string(),
      not_application_option: yup.boolean(),
    });
  }, [t])

  const { register, errors, setValue, handleSubmit, setError, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const currentLocationState = useBrowserLocationState<{ theme: any, subTheme?: any }>({ keysRequired: ["theme"] })

  const themeOrSubTheme = currentLocationState?.subTheme
    ? currentLocationState?.subTheme?.name_fr +
    ' / ' +
    currentLocationState?.subTheme?.name_nl
    : currentLocationState?.theme?.short_name_fr +
    ' / ' +
    currentLocationState?.theme?.short_name_nl;

  // Update form when api call finishes
  useEffect(() => {
    setValue('title_fr', formItem.title_fr);
    setValue('title_nl', formItem.title_nl);
    setValue('norm_code', formItem.norm_code);
    setValue('page_pdf_fr', formItem.page_pdf_fr);
    setValue('page_pdf_nl', formItem.page_pdf_nl);
    setValue('information_fr', formItem.information_fr);
    setValue('information_nl', formItem.information_nl);
    setValue('not_application_option', formItem.not_application_option);
  }, [formItem, setValue]);

  // Register form inputs
  useEffect(() => {
    register({ name: 'title_fr' });
    register({ name: 'title_nl' });
    register({ name: 'norm_code' });
    register({ name: 'page_pdf_fr' });
    register({ name: 'page_pdf_nl' });
    register({ name: 'information_fr' });
    register({ name: 'information_nl' });
    register({ name: 'not_application_option' });
  }, [register]);

  const handleChange = event => {
    if (event.target.value || event.target.value === '') {
      itemVar({
        ...formItem,
        [event.currentTarget.name]: event.currentTarget.value,
      });
      setValue(event.currentTarget.name, event.currentTarget.value as never);
    }
  };

  const handleCheckBoxChange = event => {
    if (event.target.checked) {
      setValue(event.target.name, event.currentTarget.checked as never);
    } else {
      setValue(event.target.name, event.currentTarget.value as never);
    }
    itemVar({
      ...formItem,
      not_application_option: !formItem.not_application_option,
    });
  };

  const onSubmit = useCallback((data, process) => {
    data.id = id
    if (!templateThemeId && !templateSubThemeId) {
      console.error("one of the properties templateThemeId and templateSubThemeId is required")
    } else {
      onCheckItemUnique(data, { template_theme_id: templateThemeId, template_sub_theme_id: templateSubThemeId }).then(errorsVar => {
        if (errorsVar) {
          Object.keys(errorsVar).forEach(key => {
            setError(key, { type: "manual", message: errorsVar[key] })
          });
        } else {
          process(data)
        }
      })
    }
  }, [onCheckItemUnique, templateThemeId, templateSubThemeId, setError, id])

  const handleFormClose = useCallback(
    () => {
      reset();
      handleClose();
    }, [handleClose, reset]

  )
  return (
    <Dialog
      open={isOpenForm}
      onClose={(event, reason) => {
        if (reason === "backdropClick") {
          return false;
        } else {
          handleClose()
        }
      }}
      fullWidth={true}
    >
      <DialogContent>
        <h1>
          {isCreateItemForm ? t('item:createItem') : t('item:updateItem')}
        </h1>
        <Grid
          container
          direction="column"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={3}
        >
          <Grid item className={classes.control}>
            <TextField
              data-testid={formDialogTestIds.updateCreateThemeNameInput}
              name="theme_name"
              aria-readonly={true}
              label={t('item:theme_name')}
              variant="outlined"
              value={themeOrSubTheme}
              fullWidth
            />
          </Grid>
          <Grid item className={classes.control}>
            <TextField
              data-testid={formDialogTestIds.updateCreateTitleFrInput}
              name="title_fr"
              onChange={event => handleChange(event)}
              label={t('item:title_fr')}
              variant="outlined"
              value={formItem.title_fr}
              fullWidth
              error={errors.title_fr ? true : false}
              helperText={errors.title_fr && errors.title_fr.message}
            />
          </Grid>
          <Grid item className={classes.control}>
            <TextField
              data-testid={formDialogTestIds.updateCreateTitleNlInput}
              onChange={event => handleChange(event)}
              name="title_nl"
              label={t('item:title_nl')}
              variant="outlined"
              value={formItem.title_nl}
              fullWidth
              error={errors.title_nl ? true : false}
              helperText={errors.title_nl && errors.title_nl.message}
            />
          </Grid>
          <Grid item className={classes.control}>
            <TextField
              data-testid={formDialogTestIds.updateCreateNormCodeInput}
              onChange={event => handleChange(event)}
              name="norm_code"
              label={t('item:norm_code')}
              variant="outlined"
              value={formItem.norm_code}
              fullWidth
              error={errors.norm_code ? true : false}
              helperText={errors.norm_code && errors.norm_code.message}
            />
          </Grid>
          <Grid item className={classes.control}>
            <TextField
              data-testid={formDialogTestIds.updateCreatePagePdfInputFr}
              onChange={event => handleChange(event)}
              type="number"
              name="page_pdf_fr"
              label={t('item:page_pdf_fr')}
              variant="outlined"
              value={formItem.page_pdf_fr}
              fullWidth
              error={errors.page_pdf_fr ? true : false}
              helperText={errors.page_pdf_fr && errors.page_pdf_fr.message}
            />
          </Grid>

          <Grid item className={classes.control}>
            <TextField
              data-testid={formDialogTestIds.updateCreatePagePdfInputNl}
              onChange={event => handleChange(event)}
              type="number"
              name="page_pdf_nl"
              label={t('item:page_pdf_nl')}
              variant="outlined"
              value={formItem.page_pdf_nl}
              fullWidth
              error={errors.page_pdf_nl ? true : false}
              helperText={errors.page_pdf_nl && errors.page_pdf_nl.message}
            />
          </Grid>
          <Grid item className={classes.control}>
            <TextField
              data-testid={formDialogTestIds.updateCreateInformationFrInput}
              name="information_fr"
              onChange={event => handleChange(event)}
              label={t('item:information_fr')}
              variant="outlined"
              value={formItem.information_fr}
              fullWidth
              helperText={errors.information_fr && errors.information_fr.message}
              error={errors.information_fr ? true : false}
            />
          </Grid>
          <Grid item className={classes.control}>
            <TextField
              data-testid={formDialogTestIds.updateCreateInformationNlInput}
              onChange={event => handleChange(event)}
              name="information_nl"
              label={t('item:information_nl')}
              variant="outlined"
              value={formItem.information_nl}
              fullWidth
              helperText={errors.information_nl && errors.information_nl.message}
              error={errors.information_nl ? true : false}
            />
          </Grid>
          <Grid item className={classes.control}>
            <div className={classes.divCheckBox}>
              <Checkbox
                data-testid={
                  formDialogTestIds.updateCreateNotApplicationOptionInput
                }
                onChange={event => handleCheckBoxChange(event)}
                checked={formItem.not_application_option}
                name="not_application_option"
                classes={{
                  root: classes.checkbox,
                  checked: classes.checked,
                }}
              />
              <p>{t('item:not_application_option')}</p>
            </div>
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Box display="flex" width="100%">
          <ButtonGroup
            disableElevation
            orientation="vertical"
            variant="contained"
            aria-label="vertical"
            fullWidth
          >
            {handleCreateAddItem ? (
              <Button
                disableElevation
                className={classes.root}
                variant="contained"
                id="applyCreateAddBtn"
                data-testid={formDialogTestIds.applyCreateAddBtn}
                onClick={handleSubmit((data) => onSubmit(data, handleCreateAddItem))}
                fullWidth
              >
                {t('item:validateAdd')}
              </Button>
            ) : (
              ''
            )}
            <Button
              fullWidth
              color="primary"
              id="applyUpdateBtn"
              data-testid={formDialogTestIds.applyUpdateBtn}
              onClick={handleSubmit((data => onSubmit(data, handleFormSubmit)))}
            >
              {t('common:validate')}
            </Button>
            <Button
              fullWidth
              id="cancelUpdateBtn"
              className={classes.grey}
              data-testid={formDialogTestIds.cancelUpdateBtn}
              onClick={() => handleFormClose()}
            >
              {t('common:cancel')}
            </Button>
          </ButtonGroup>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ItemFormComponent;
