import { makeStyles } from '@material-ui/core';
import { drawerWidth } from '../../Navigation/NavigationStyle';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { withStyles } from '@material-ui/core/styles';

export const AccordionThemeRemark = withStyles({
  root: {
    padding: 0,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummaryThemeRemark = withStyles({
  root: {
    padding: 0,
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    padding: 0,
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetailsThemeRemark = withStyles({
  root: {
    padding: 0,
  },
})(MuiAccordionDetails);

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1),
  },
  mainInfoBar: {
    top: '63px',
    zIndex: 100,
    width: '100%',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    padding: theme.spacing(2),
  },

  mainInfoBarShiftSmall: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  mainInfoBarShiftMedium: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    paddingTop: theme.spacing(8),
    zIndex: 100,
    width: drawerWidth,
    border: 0,
    backgroundColor: theme.palette.primary.main,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  containerContent: {
    width: '100%',
    transition: theme.transitions.create(['margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  containerContentShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  content: {
    width: '100%',
  },
  elevationContent: {
    width: '100%',
    padding: theme.spacing(3),
  },
  containerButtonOpen: {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: theme.spacing(2),
  },
  buttonOpen: {
    textTransform: 'none',
    backgroundColor: 'white',
  },
  checlikstTitle: {
    fontSize: '0.875rem',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  containerRemark: {
    zIndex: 100,
    padding: `0 ${theme.spacing(1)}px ${theme.spacing(1)}px ${theme.spacing(
      1,
    )}px `,
  },
  containerRemarkPaper: {},
  containerRemarkShiftFull: {
    width: `100%`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  containerRemarkShiftSmall: {
    width: `calc(100% - ${2 * drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  containerRemarkShiftMedium: {
    width: `100%`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  textarea: {
    //strange transition in accordion if set to 100%
    width: '99%',
    fontSize: '0.875rem',
    fontFamily: 'Poppins',
    fontWeight: 'bold',
    color: "#0000EE",
  },
  generalRemarkLabel: {
    fontSize: '0.875rem',
    fontFamily: 'Poppins',
  },
  photoButton: {
    padding: theme.spacing(2),
  },
}));

export { useStyles };
