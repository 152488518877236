import React, {
    useCallback,
    useEffect, useState, useMemo
} from "react";
import {
    Dialog,
    DialogContent,
    DialogActions,
    Button,
    Grid,
    TextField,
    MenuItem
} from "@material-ui/core";
import { useReactiveVar } from "@apollo/client";
import {
    openCreateLocationVar,
    locationVar,
    categoryVar,
    locationDeleteDoubleIdVar,
    openConfirmUpdatDialogVar,
    idEditLocationVar
} from "../LocationCache";
import { useTranslation } from "react-i18next";
import { useStyles } from "../../Shared/Styles/CommonFormSyles";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { LocationFormData } from "../LocationFormModel";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Category } from '../../Entity/EntityModel';
import CategoryLocationListComponent from "../../Shared/CategoryLocationList/CategoryLocationListComponent";
import { useCheckLocationUnique, useGetLocationByEntityId } from "../../../services/Location/ReadService/LocationReadService";
import ConfirmDialog from "../../Shared/Dialog/ConfirmDialog/ConfirmDialogComponent/ConfirmDialogComponent";
import { useBrowserLocationState } from "../../Shared/BrowserLocationState/BrowserLocationState";
export interface LocationFormComponentProps {
    isOpenForm: boolean;
    handleClose: any;
    handleFormSubmit: any;
    handleCreateLocation?: any;
    handleConfirmedToUpdate?: any;
    textConfirm?: any;
}
export const formDialogTestIds = {
    updateCreateEntityNameInput: 'entity-name',
    updateCreateNameFrInput: 'location-name-input',
    updateCreateCategoryInput: 'location-category-input',
    updateCreateSpecificationInput: 'location-specification-input',
    updateCreateApplyBtn: 'location-apply-btn',
    updateCreateCancelBtn: 'location-cancel-btn',
    updateCreateAddBtn: 'location-add-btn',
    updateCreateConfirmBox: 'location-confirm-box',
};

const LocationFormComponent: React.FC<LocationFormComponentProps> = ({
    handleClose,
    isOpenForm,
    handleFormSubmit,
    handleCreateLocation,
    handleConfirmedToUpdate,
    textConfirm
}) => {
    const { t } = useTranslation();
    const classes = useStyles();

    const schema = yup.object().shape({
        name: yup.string().nullable().required(t("location:nameError")),
        category_id: yup.string().nullable().required(t("location:categoryError")),
        specification: yup.string().nullable()
    });
    const formLocation: LocationFormData = useReactiveVar(locationVar);
    const isCreateLocationForm = useReactiveVar(openCreateLocationVar);
    const isOpenConfirmUpdateLocation = useReactiveVar(openConfirmUpdatDialogVar);
    const data = useReactiveVar(categoryVar);
    const [categoryLocationData, setCategoryLocationData] = useState<Category[]>([])
    const { register, errors, setValue, handleSubmit, setError, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const currentLocationState = useBrowserLocationState<{ entity: any }>({ keysRequired: ["entity"] })

    const currentEntity = useMemo(() => {
        return currentLocationState?.entity
            ? currentLocationState?.entity
            : ""
    }
        , [currentLocationState])

    const entityName = currentEntity ? currentEntity.label : "";
    const entityId = currentEntity ? currentEntity.id : -1;

    useEffect(() => {
        if (data.length > 0) {
            setCategoryLocationData(data)

        }
    }, [data]);

    const checkCategory = (formLocation_var: LocationFormData): string => {
        if (formLocation_var && formLocation_var.category_id && formLocation_var.category_id !== -1) {
            return formLocation_var.category_id.toString();
        }
        return ""
    }
    const [categoryValue, setCategoryValue] = useState<string>(checkCategory(formLocation))

    useEffect(
        () => {
            setValue("name", formLocation.name);
            setValue("category_id", checkCategory(formLocation) as never);
            setValue("specification", formLocation.specification as never);
            setCategoryValue(checkCategory(formLocation))
        }, [formLocation, setValue],
    );

    useEffect(
        () => {
            register({ name: "name" });
            register({ name: "category_id" });
            register({ name: "specification" });
        },
        [register],
    );

    const handleChange = useCallback(
        e => {
            if (e.target.value || e.target.value === '') {

                locationVar({
                    ...formLocation,
                    [e.target.name]: e.target.value,
                });
                setValue(e.target.name, e.target.value as never);
                if (e.target.name === 'category_id')
                    setCategoryValue(e.target.value)
            }
        }, [formLocation, setValue])
    const { onCheckLocationUnique } = useCheckLocationUnique()

    const onSubmit = useCallback((data_var, process) => {
         let idEditLocation = idEditLocationVar()
        if (idEditLocation !== -1) {
            data_var.id = idEditLocation
        } 

        if (!entityId) {
            console.error("one of the properties entity id is required")
        } else {

            const dataToCheck = { ...data_var, entity_id: entityId }
            onCheckLocationUnique(dataToCheck, { entity_id: entityId }).then(errors_var => {
                if (errors_var) {
                    if (Object.keys(errors_var).length === 1) {
                        locationDeleteDoubleIdVar(errors_var["locationId"])
                        process(data_var)
                    } else
                        Object.keys(errors_var).forEach(key => {
                            setError(key, { type: "manual", message: errors_var[key] })
                        })
                } else {
                    process(data_var)
                }
            })
        }
    }, [onCheckLocationUnique, entityId, setError])

    const { data: locationsLengthData } = useGetLocationByEntityId(entityId);
    const history = useHistory();

    const handleFormClose = useCallback(
        () => {
            reset();
            handleClose();
            if (locationsLengthData && locationsLengthData.length === 0) {
                history.goBack();
            }

        }, [handleClose, reset, history, locationsLengthData]

    )

    return (
        <>
            <Dialog
                open={isOpenForm}
                onClose={(event, reason) => {
                    if (reason === "backdropClick") {
                        return false;
                    } else {
                        handleClose()
                    }
                }}
                fullWidth={true}
            >
                <DialogContent>
                    <h1>
                        {
                            isCreateLocationForm
                                ? t("location:addLocation")
                                : t("location:editLocation")
                        }
                    </h1>
                    <Grid
                        container
                        direction="column"
                        justifyContent="space-evenly"
                        alignItems="center"
                        spacing={3}
                    >
                        <Grid
                            item
                            className={classes.control}
                        >
                            <TextField
                                data-testid={formDialogTestIds.updateCreateEntityNameInput}
                                name="entity_name"
                                aria-readonly={true}
                                label={t("entity:entity")}
                                variant="outlined"
                                fullWidth
                                value={entityName}
                            />
                        </Grid>
                        <Grid
                            item
                            className={classes.control}
                        >

                            <TextField
                                data-testid={formDialogTestIds.updateCreateNameFrInput}
                                name="name"
                                label={t("location:name")}
                                variant="outlined"
                                fullWidth
                                value={formLocation.name}
                                error={errors.name ? true : false}
                                helperText={errors.name && errors.name.message}
                                onChange={handleChange}
                            />

                        </Grid>
                        <Grid item
                            className={classes.control}>
                            <Grid item className={classes.control}>

                                <CategoryLocationListComponent
                                    name="category_id"
                                    label={t("location:category")}
                                    defaultValue={categoryValue}
                                    handleOnChange={(e) => handleChange(e)}
                                    value={categoryValue}
                                    error={errors.category_id ? true : false}
                                    dataTestid={formDialogTestIds.updateCreateCategoryInput}
                                    helperText={errors.category_id && errors.category_id.message}
                                    children={categoryLocationData.map(cat => (
                                        <MenuItem key={cat.id} value={cat.id}> {cat.name}</MenuItem>
                                    ))}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            className={classes.control}
                        >
                            <TextField
                                data-testid={formDialogTestIds.updateCreateSpecificationInput}
                                name="specification"
                                label={t("location:specification")}
                                variant="outlined"
                                fullWidth
                                value={formLocation.specification}
                                error={errors.specification ? true : false}
                                onChange={handleChange}
                                helperText={errors.specification?.message}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions
                    className={classes.divButton}
                >
                    {handleCreateLocation ? (
                        <Button
                            data-testid={formDialogTestIds.updateCreateAddBtn}
                            className={classes.buttonValidateAdd}
                            id="applyCreateAddBtn"
                            onClick={handleSubmit(locationData => onSubmit(locationData, handleCreateLocation))}
                            fullWidth
                        >
                            {t("location:addOtherLocation")}
                        </Button>
                    ) : ""}
                    <Button
                        data-testid={formDialogTestIds.updateCreateApplyBtn}
                        className={classes.buttonValidate}
                        id="applyUpdateBtn"
                        onClick={handleSubmit((locationData => onSubmit(locationData, handleFormSubmit)))}
                        fullWidth
                    >
                        {t("common:validate")}
                    </Button>
                    <Button
                        data-testid={formDialogTestIds.updateCreateCancelBtn}
                        id="cancelUpdateBtn"
                        onClick={() => handleFormClose()}
                        variant="contained"
                        color="default"
                        fullWidth
                    >
                        {t("common:cancel")}
                    </Button>

                </DialogActions>

            </Dialog>
            <ConfirmDialog
                data-testid={formDialogTestIds.updateCreateConfirmBox}
                open={isOpenConfirmUpdateLocation}
                onClose={
                    () => { openConfirmUpdatDialogVar(false) }
                }
                onConfirm={() => handleConfirmedToUpdate()}
                title={t("location:editLocation")}
                text={t("location:" + textConfirm)}
            />
        </>

    )
}

export default LocationFormComponent;