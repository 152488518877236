import React, { useMemo } from 'react';
import BuildingFormComponent from '../../BuildingForm/BuildingFormComponent';
import { useCreateBuilding } from '../../../../services/Building/WriteService/BuildingWriteService';
import { useHistory } from 'react-router-dom';
import { BuildingFormData } from '../../BuildingForm/BuildingFormPresenter';
import routes from '../../../../routes';
import { useBrowserLocationState } from '../../../Shared/BrowserLocationState/BrowserLocationState';

/**
 * Description - Component to create building
 */
const BuildingCreateComponent = ({ parentPath }) => {
  const history = useHistory();
  const currentLocationState = useBrowserLocationState<{audit: any, building}>({keysRequired: ["audit"]})

  const audit_id = useMemo(
    () => (currentLocationState?.audit?.id ? currentLocationState?.audit?.id : -1),
    [currentLocationState],
  );

  const audit = useMemo(() => {
    return currentLocationState?.audit ? currentLocationState.audit : undefined
  },[currentLocationState])

  const { onCreate: onCreateBuilding } = useCreateBuilding({ audit_id });

  const handleSubmitBuilding = ({
    building,
    redirect,
  }: {
    building: BuildingFormData;
    redirect?: boolean;
  }) => {
    building.audit_id = currentLocationState?.audit?.id;
    onCreateBuilding(building).then(response => {
      if (redirect === true)
        history.push({
          pathname: `${routes.editAudit.path}${routes.editAuditMainInfo.path}`,
          state: { audit: currentLocationState?.audit, building: building },
        });
    });
  };

  return (
    <BuildingFormComponent
      audit={audit}
      building={undefined}
      title="building:createBuilding"
      submitButtonLabel="common:validate"
      onSubmitBuilding={handleSubmitBuilding}
      parentPath={parentPath}
      fromCreateBuilding={true}
    />
  );
};

export default BuildingCreateComponent;
