import React, { useMemo, useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import { SubThemeFormData } from '../SubThemeFormModel';
import { openUpdateSubThemeVar, subThemeVar } from '../SubThemeCache';
import { useUpdateSubTheme } from '../../../services/SubTheme/SubThemeWriteService/SubThemeWriteService';
import SubThemeFormComponent from './SubThemeFormDialogComponent';
import { useBrowserLocationState } from '../../Shared/BrowserLocationState/BrowserLocationState';

export interface tableProps {
  openUpdateForm: boolean;
}

const SubThemeUpdateDialogComponent = () => {
  const isOpenUpdateForm: boolean = useReactiveVar(openUpdateSubThemeVar);
  const updateSubTheme: SubThemeFormData = useReactiveVar(subThemeVar);

  const currentLocationState = useBrowserLocationState<{theme: any}>({keysRequired: ["theme"]})

  const themeId: number = useMemo(
    () =>
    currentLocationState && currentLocationState.theme
        ? currentLocationState.theme.id
        : -1,
    [currentLocationState],
  );
  const { onUpdate } = useUpdateSubTheme({ themeId, showNotification: true });

  const defaultSubtheme: SubThemeFormData = {
    id: -1,
    name_fr: '',
    name_nl: '',
    index: -1,
    not_applicable_option: false,
    theme_template_checklist_id: -1,
  };

  const handleUpdateSubTheme = () => {
    let subThemeForm: SubThemeFormData;
    subThemeForm = {
      id: updateSubTheme.id,
      name_fr: updateSubTheme.name_fr,
      name_nl: updateSubTheme.name_nl,
      not_applicable_option: updateSubTheme.not_applicable_option,
      theme_template_checklist_id: updateSubTheme.theme_template_checklist_id,
      index: updateSubTheme.index,
    };
    if (updateSubTheme.id) {
      onUpdate(subThemeForm);
      openUpdateSubThemeVar(false);
      subThemeVar(defaultSubtheme);
    }
  };

  const handleClose = useCallback(() => {
    openUpdateSubThemeVar(false);
    subThemeVar(defaultSubtheme);
  }, [defaultSubtheme]);

  return (
    <SubThemeFormComponent
      isOpenForm={isOpenUpdateForm}
      handleClose={handleClose}
      handleFormSubmit={handleUpdateSubTheme}
      templateThemeId={themeId}
      id={updateSubTheme.id}
    />
  );
};

export default SubThemeUpdateDialogComponent;
