import React, { Dispatch, Fragment, SetStateAction } from 'react';
import { useStyles } from '.';
import useSearch from '../../../../services/Search/SearchService';
import SearchField from '../../Field/SearchField/SearchField';

export interface SearchComponentProps {
  items: any[];
  filteredItems: any[];
  setFilteredItems: Dispatch<SetStateAction<any>>;
  filterPredicate: (item, search) => boolean;
  placeholderTranslation: string;
}

export const testId = 'search-component';

const SearchComponent = ({
  items,
  filteredItems,
  setFilteredItems,
  filterPredicate,
  placeholderTranslation,
}: SearchComponentProps) => {
  const classes = useStyles();
  const { filter } = useSearch({
    items,
    filteredItems,
    setFilteredItems,
    filterPredicate,
  });

  return (
    <Fragment>
      <div data-testid={testId} className={classes.search}>
        <SearchField
          onChange={filter}
          placeholderTranslation={placeholderTranslation}
        />
      </div>
    </Fragment>
  );
};

export default SearchComponent;
