import { CircularProgress, Typography } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import PageContainer from "../../../Shared/Container/PageContainer"
import { AdminDocumentUploadFormInputs } from "../AdminDocumentUploadForm/AdminDocumentUploadFormService"
import { useAdminDocumentUploadPreviewService } from "./AdminDocumentUploadPreviewService"
import { useStyles } from './AdminDocumentUploadPreviewStyle'

export const testIds = {
    documentInfo: "admin_document_upload_preview_document_info",
    documentContent: "admin_document_upload_preview_document_content"
}
export interface AdminDocumentUploadPreviewProps {
    documentId: number
    values: AdminDocumentUploadFormInputs
    isChangedFile: boolean
}


const AdminDocumentUploadPreview = ({ documentId, values, isChangedFile }: AdminDocumentUploadPreviewProps) => {
    const classes = useStyles()
    const { t } = useTranslation()

    const { documentInfo, loadingDocumentInfo, showDocumentInfo, content, loadingContent, noContentUpload } = useAdminDocumentUploadPreviewService({ id: documentId })

    let imgPreview;
    if (values?.file) {
        imgPreview = URL.createObjectURL(new Blob([values.file], { type: "application/pdf" }))
    }

    return (
        <PageContainer>
            {
                loadingDocumentInfo
                    ? <CircularProgress />
                    : <div
                        data-testid={testIds.documentInfo}
                    >
                        {(showDocumentInfo && !isChangedFile && !values?.file) ?
                            <React.Fragment>
                                <Typography variant="h5">{documentInfo.title}</Typography>
                                {
                                    !noContentUpload
                                        ? <Typography variant="h5">{`${t("adminDocument:dateUpdated")}: ${documentInfo.date}`}</Typography>
                                        : <Typography variant="h5">{t("adminDocument:noContentUpload")}</Typography>
                                }
                            </React.Fragment>
                            : ""
                        }
                        {(isChangedFile && values && values?.file) ?
                            <React.Fragment>
                                <Typography variant="h5">{values?.file?.name}</Typography>
                            </React.Fragment>
                            : ""
                        }
                        {(!showDocumentInfo && !values?.file) ?
                            <React.Fragment>
                                <Typography variant="h5">{t("adminDocument:noSelectDocumentMessage")}</Typography>
                            </React.Fragment>
                            : ""
                        }
                    </div>

            }
            {
                loadingContent
                    ? <CircularProgress />
                    : <div
                        className={classes.contentContainer}
                        data-testid={testIds.documentContent}
                    >
                        {isChangedFile && imgPreview && <iframe
                            width={1500}
                            height={900}
                            title="admin_document_preview"
                            src={imgPreview ? imgPreview : ""}

                        />}
                        {!isChangedFile && content && <iframe
                            width={1500}
                            height={900}
                            title="admin_document_preview"
                            src={content ? content : ""}

                        />}
                    </div>
            }
        </PageContainer>
    )

}

export default AdminDocumentUploadPreview