import { useDeleteEntity } from '../../../services/Entity/DeleteService/EntityDeleteService';
import { useGetAllEntities } from '../../../services/Entity/ReadService/EntityReadService';
import { useState } from 'react';
import { EntityViewProps } from '../EntityView';

/**
 * Description : service to manage the entities (read - create - update - delete)
 */
export const useEntityComponentService = (): EntityViewProps => {
  const { data, loading, error } = useGetAllEntities();
  const { myDelete } = useDeleteEntity();

  const [idToUpdate, setIdToUpdate] = useState<number>(0);

  return {
    entities: data,
    loading,
    error,
    onDelete: id => {
      myDelete(id);
    },
    handleIdToUpdate: id => {
      setIdToUpdate(id);
    },
    idToUpdate,
  };
};
