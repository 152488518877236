import {
  Avatar,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListSubheader,
} from '@material-ui/core';
import React, { Fragment, useContext, useMemo, useState } from 'react';
import AddButtonComponent from '../../../../Shared/Button/AddButtonComponent';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import PictureAsPdfOutlined from '@material-ui/icons/PictureAsPdfOutlined';
import { useTranslation } from 'react-i18next';
import { translate } from '../../../../../services/Translation/TranslationService';
import EditIcon from '@material-ui/icons/Edit';
import { Link, useHistory } from 'react-router-dom';
import DeleteIcon from '@material-ui/icons/Delete';
import AppContext from '../../../../../AppContext';
import routes from '../../../../../routes';
import { useStyles } from './BuildingListStyle';
import { audit_data } from '../../../../../services/Audit/AuditDataModel';
import { CircularProgress } from '@material-ui/core';
import { useGetBuildingsByAudit } from '../../../../../services/Building/ReadService/BuildingReadService';
import { useDeleteBuildingById } from '../../../../../services/Building/DeleteService/BuildingDeleteService';
import ChecklistDocumentDialog from '../../../ChecklistDocument/ChecklistDocumentRead/ChecklistDocumentDialog';
import { useDocumentDialogService } from '../../../../AdminDocument/AdminDocumentRead/AdminDocumentService';
import { selectedThemeNavigationVar } from '../../../../Checklist/ChecklistCache';
import { building_data } from '../../../../../services/Building/BuildingDataModel';

export interface BuildingListComponentProps {
  audit?: audit_data;
  parentPath: string;
  onCreateBuildingClick: () => void;
  isReadOnlyMode: boolean;
}

export const testIds = {
  deleteButton: 'building-delete-button',
  updateButton: 'building-update-button',
  updatePdfButton: 'building-update-pdf-button',
  uploadPdfButton: 'building-upload-pdf-button',
};

/**
 * Description - Component to read audit building
 */
const BuildingListComponent: React.FC<BuildingListComponentProps> = ({
  audit,
  parentPath,
  onCreateBuildingClick,
  isReadOnlyMode,
}) => {
  const { language,role } = useContext(AppContext);
  const { t } = useTranslation();
  const classes = useStyles();
  const audit_id = useMemo(() => (audit ? audit.id : -1), [audit]);
  const { data: buildings, loading } = useGetBuildingsByAudit({ audit_id });
  const { deleteBuildingById } = useDeleteBuildingById({ audit_id });
  const ADMIN:string='admin'
  //from audit delete current cache and
  selectedThemeNavigationVar(undefined)

  const onBuildingDelete = building_id => {
    deleteBuildingById(building_id);
  };

  const [url, setUrl] = useState<string>("");

  const readOnlyModeclick = () => { };
  const { open: openChecklistDocumentDialog, pageNumber: pageNumberChecklistDocumentDialog, onCloseDialog: onCloseChecklistDocumentDialog, onSelectPage: onSelectChecklistDocument } = useDocumentDialogService();

  const onOpenPdf = pdfUrl => {
    setUrl(pdfUrl)
    onSelectChecklistDocument(1);
  };
   
  const showBuildingDeleteButton=( buildingForDeleteButton : building_data):boolean=>{  
      return !buildingForDeleteButton?.checklist || (buildingForDeleteButton?.checklist &&  role===ADMIN )

  }
  const history = useHistory();
  const upLoadPdf = (building) => {
    history.push({
      pathname: `${routes.checklistUploadDocument.path}`,
      state: { building: building, audit: audit, isReadonly: isReadOnlyMode, isNewDoc: true },
    });
  };

  return (
    <Grid>
      <div className={classes.buildingContainer}>
        {loading && <CircularProgress />}
        {buildings && buildings.length > 0 && (
          <List
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                {t('audit:buildings')}
              </ListSubheader>
            }
          >
            <ChecklistDocumentDialog
              open={openChecklistDocumentDialog}
              pageNumber={pageNumberChecklistDocumentDialog}
              onCloseDialog={onCloseChecklistDocumentDialog}
              url={url}
            />
            {buildings?.map((building, index) => {
              return (
                <Fragment key={index}>
                  <ListItem>
                    <h3 className={classes.buildingNumber}>
                      {index+1}
                    </h3>
                    <ListItemAvatar>
                      <Avatar>
                        <HomeWorkIcon />
                      </Avatar>
                    </ListItemAvatar>
                    <div>
                      {`${building?.address?.street} ${building?.address?.house_number} ${building?.address?.box_number ? ' / ' + building?.address?.box_number : ''}`}
                    </div>
                    <div>
                      {!isReadOnlyMode ? (
                        <Link
                          to={{
                            pathname: `${parentPath}${routes.editBuilding.path}`,
                            state: { building_id: building.id, audit: audit },
                          }}
                        >
                          <IconButton data-testid={testIds.updateButton}>
                            <EditIcon />
                          </IconButton>
                        </Link>
                      ) : (
                        <IconButton
                          data-testid={testIds.updateButton}
                        >
                          <EditIcon />
                        </IconButton>
                      )}
                      { showBuildingDeleteButton(building) && (
                        <IconButton
                          data-testid={testIds.deleteButton}
                          onClick={() =>
                            isReadOnlyMode
                              ? readOnlyModeclick
                              : onBuildingDelete(building?.id)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}
                    </div>
                    <ListItemSecondaryAction
                      className={classes.buildingDocumentButtonContainer}
                    >
                      <div>
                        {(building?.checklist_document && building?.checklist_document?.length > 0) ? (
                          <IconButton
                            data-testid={testIds.uploadPdfButton}
                            className={classes.onOpenPdf}
                            onClick={() => onOpenPdf(building?.checklist_document ? building?.checklist_document[0].url : "")}
                          >
                            <PictureAsPdfOutlined />
                          </IconButton>

                        ) : (
                          (!isReadOnlyMode) &&
                          <IconButton
                            data-testid={testIds.uploadPdfButton}
                            onClick={() => upLoadPdf(building)}>
                            <PictureAsPdfOutlined />
                          </IconButton>

                        )}
                        {!isReadOnlyMode && building?.checklist_document?.length !== 0 && (
                          <Link
                            to={{
                              pathname: `${routes.checklistUploadDocument.path}`,
                              state: { building: building, audit: audit, isReadonly: isReadOnlyMode, isNewDoc: false },
                            }}
                          >
                            <IconButton data-testid={testIds.updatePdfButton}>
                              <EditIcon />
                            </IconButton>

                          </Link>
                        )}
                      </div>
                      {!building?.checklist ? (
                        !isReadOnlyMode ? (

                          <AddButtonComponent
                            label={`${t('audit:checklist')} ${building.sub_category_location
                              ? translate(
                                building.sub_category_location,
                                language,
                              )
                              : translate(
                                audit?.location_entity.category_location,
                                language,
                              )
                              }`}
                            linkPath={{
                              pathname: routes.editChecklist.path,
                              state: { building: building, audit: audit, isReadOnlyMode: isReadOnlyMode },
                            }}

                          />
                        ) :
                          (
                            <AddButtonComponent
                              label={`${t('audit:checklist')} ${building.sub_category_location
                                ? translate(
                                  building.sub_category_location,
                                  language,
                                )
                                : translate(
                                  audit?.location_entity.category_location,
                                  language,
                                )
                                }`}
                            />
                          )


                      ) : (                        
                        <Button
                          className={classes.updateChecklistButton}
                          component={Link}
                          to={{
                            pathname: routes.editChecklist.path,
                            state: {
                              building: building,
                              audit: audit,
                              isReadOnlyMode: isReadOnlyMode,
                              fromAudit: true
                            },
                          }}
                        >
                          {`${t('audit:checklist')} ${building.sub_category_location
                            ? translate(
                              building.sub_category_location,
                              language,
                            )
                            : translate(
                              audit?.location_entity.category_location,
                              language,
                            )
                            }`}
                        </Button>                   
                      )}
                    </ListItemSecondaryAction>
                  </ListItem>
                </Fragment>
              );
            })}
          </List>
        )}
      </div>

      <div className={classes.addBuildingButton}>
        <AddButtonComponent
          label="building:createBuilding"
          onClick={() =>
            isReadOnlyMode ? readOnlyModeclick : onCreateBuildingClick()
          }
        />
      </div>
    </Grid >

  );
};

export default BuildingListComponent;
