import { useApolloClient, useQuery } from '@apollo/client';
import { get_all_locations_include_deleted_data, get_all_locations_include_deleted_variables, get_all_locations_include_delete_response, locations_data, location_data_by_pk } from './LocationReadModel';
import { useContext, useCallback } from 'react';
import AppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import {
  GET_AUDIT_BY_ENTITY_ID_AND_LOCATION_ID,
  GET_LOCATION_BY_PK,
  GET_LOCATION_BY_PK_AND_ADDRESSES_BY_POSTAL_CODE,
  READ_ALL_LOCATIONS,
  READ_ALL_LOCATIONS_BY_ENTITY_ID,
  READ_ALL_LOCATIONS_INCLUDE_SOFT_DELETED_BY_ENTITY_ID,
  READ_ALL_LOCATIONS_SORTED,
} from './LocationReadQuery';
import { OrderBy } from "../../../components/Location/LocationTableComponent"
import { location_data } from './../LocationDataModel';
import { useTranslation } from 'react-i18next';
import { useGetAllCategories } from '../../../components/Entity/CategoryDataService/CategoryReadService/CategoryReadService';
import { audits_data } from '../../Audit/ReadService/AuditReadModel';



interface LocationFormData {
  location_id: number;
}

/**
 * Description - service to get all locations
 */
export const useGetAllLocation = () => {
  const { setNotification } = useContext(AppContext);

  let { data, loading, error } = useQuery<locations_data>(
    READ_ALL_LOCATIONS,
    {
      fetchPolicy: 'network-only',
      onError(errorResponse) {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    });
  return { data: data?.qat_location_entity, loading, error };
};


export const useGetLocationByIdDefault = id => {
  const { setNotification } = useContext(AppContext);
  let { data, loading, error } = useQuery<location_data_by_pk>(
    GET_LOCATION_BY_PK,
    {
      fetchPolicy: 'network-only',
      variables: { id },
      skip: id === -1,

      onError(errorResponse) {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    },
  );

  if (!id) return { data: null, loading: false, error: true };

  return { data: data?.qat_location_entity_by_pk, loading, error };
};

export const useGetLocationById = () => {
  const client = useApolloClient();
  const { setNotification } = useContext(AppContext);

  const getLocationById = useCallback(
    id =>
      new Promise<any>(resolve => {
        client
          .query<location_data_by_pk>({
            query: GET_LOCATION_BY_PK,
            variables: { id },
            fetchPolicy: 'network-only',
          })
          .then(response => {
            resolve(response.data.qat_location_entity_by_pk);
          })
          .catch(error => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      }),
    [setNotification, client],
  );

  return { getLocationById };
};

export const useGetLocationByIdAndAddressPostalCode = () => {
  const client = useApolloClient();
  const { setNotification } = useContext(AppContext);

  const getLocationByIdAdnAddressPostalCode = useCallback(
    id =>
      new Promise<any>(resolve => {
        client
          .query<location_data_by_pk>({
            query: GET_LOCATION_BY_PK_AND_ADDRESSES_BY_POSTAL_CODE,
            variables: { id },
          })
          .then(response => {
            resolve(response.data.qat_location_entity_by_pk?.addresses);
          })
          .catch(error => {
            console.error(error);
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      }),
    [setNotification, client],
  );

  return { getLocationByIdAdnAddressPostalCode };
};

export const useGetLocationByEntityId = entity_id => {
  const { setNotification } = useContext(AppContext);

  let { data, loading, error } = useQuery<locations_data>(
    READ_ALL_LOCATIONS_BY_ENTITY_ID,
    {
      fetchPolicy: 'network-only',
      variables: { entity_id },
      skip: entity_id === -1,

      onError(errorResponse) {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    },
  );

  if (!entity_id) return { data: null, loading: false, error: true };

  return { data: data?.qat_location_entity, loading, error };
};

export const useGetSortedLocationByEntityId = (entity_id) => {
  const { setNotification } = useContext(AppContext);
  const client = useApolloClient();
  const orderLocationsBy = useCallback(
    (orderBy: OrderBy) =>
      new Promise<location_data[]>(resolve => {
        client
          .query<locations_data>({
            query: READ_ALL_LOCATIONS_SORTED,
            variables: { entity_id: entity_id, order_by: orderBy },
          })
          .then(response => {
            resolve(response?.data?.qat_location_entity);
          })
          .catch(errorResponse => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      }),
    [setNotification, client, entity_id],
  );
  return { orderLocationsBy };
};


export interface LocationUniqueErrorResponse {
  name?: string;    
  category_id?: string;    
  specification?: string;  
  locationId?:number;

}

export const useGetAllLocationsIncludeDeleted=()=>{
    const client = useApolloClient();
    const { setNotification } = useContext(AppContext);

    const getLocations = useCallback(
      ({
        entity_id
      
      }) =>
        new Promise<get_all_locations_include_deleted_data[]>(resolve => {
          client
            .query<
              get_all_locations_include_delete_response,            
              | get_all_locations_include_deleted_variables
            >({

              variables: {entity_id} ,
              query: READ_ALL_LOCATIONS_INCLUDE_SOFT_DELETED_BY_ENTITY_ID   ,              
              fetchPolicy: "network-only"
            })
            .then(result => {
              if (result.error) {
                setNotification({
                  key: 'common:generalError',
                  type: NotificationType.ERROR,
                });
                resolve([]);
              }
              resolve(
                result.data.qat_location_entity.map(
                  ({ __typename, ...rest }) => ({ ...rest }),
                ),
              );
            }).catch(error => {
              setNotification({
                key: 'common:generalError',
                type: NotificationType.ERROR,
              });
            });
        }),
      [client, setNotification],
    );

    return { getLocations };
  };


export const useCheckLocationUnique = () => {
  const { getLocations } = useGetAllLocationsIncludeDeleted()
  const { data: categories } = useGetAllCategories();

  const { t } = useTranslation()

  const onCheckLocationUnique = useCallback((
    locationData: location_data,
    { entity_id }: { entity_id?: number }
  ) => {
    return new Promise<LocationUniqueErrorResponse | void >(resolve => {
      const errors: LocationUniqueErrorResponse = {}
      getLocations({ entity_id }).then(locations => {
        let doubleWithSpecification: get_all_locations_include_deleted_data | undefined=undefined
       
           if (locationData?.id)
           {
        
            doubleWithSpecification = locations.find((location) =>                
                locationData.id!== location.id && location.name.trim().toLowerCase() === locationData.name.trim().toLowerCase() &&
                location?.specification?.trim().toLowerCase() === locationData?.specification?.trim().toLowerCase() &&
               location.entity_id === locationData.entity_id && location.category_id.toString() === locationData.category_id.toString())
                }
                  else{
            doubleWithSpecification = locations.find((location) =>
                  
              location.name.trim().toLowerCase() === locationData.name.trim().toLowerCase() &&
              location?.specification?.trim().toLowerCase() === locationData?.specification?.trim().toLowerCase() &&
              location.entity_id === locationData.entity_id && location.category_id.toString() === locationData.category_id.toString()
             )
            }
                     
        if (doubleWithSpecification && doubleWithSpecification?.deleted_date === null   ) {
          const categoryName = categories.find(cat =>     
                cat.id === doubleWithSpecification?.category_id)?.name            
         
                if(locationData?.specification){
                   const msg = t("location:nameCategorieSpecificationUniqueError",
            {
              0: doubleWithSpecification.name,
              1: categoryName,
              2: doubleWithSpecification?.specification
            })
               errors.name = msg            
               errors.specification = msg            
               errors.category_id = msg
                } else{
                  const msg = t("location:nameCategorieUniqueError",
                    {
                      0: doubleWithSpecification.name,
                      1: categoryName
                    })
                  errors.name = msg
                  errors.category_id = msg
                }         
        

        } else if (doubleWithSpecification?.deleted_date !== null ) {
         //found location from  softh delete 
          errors.locationId = doubleWithSpecification ? doubleWithSpecification.id :-1
           resolve(errors)
        }else{
          resolve()
        }

        if (Object.keys(errors).length) {
          resolve(errors)
        } else {
          resolve()
        }
      })
    })

  },[t, getLocations,categories])

  return { onCheckLocationUnique }
}

export const useGetAuditByEntityIdAndLocationId =(entity_id,location_id) => {
  const { setNotification } = useContext(AppContext);

  let { data, loading, error } = useQuery<audits_data>(
    GET_AUDIT_BY_ENTITY_ID_AND_LOCATION_ID,
    {
      fetchPolicy: 'network-only',
      variables: { entity_id,location_id },
      skip: entity_id === -1|| location_id===-1,

      onError(errorResponse) {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    },
  );

  if (!entity_id || !location_id) return { data: null, loading: false, error: true };
  
  return { data: data?.qat_audit, loading, error };
};