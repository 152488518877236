export const report = {
  interview: 'Aperçu interview',
  not_applicable: "Aperçu Items pas d'application",
  not_conform: 'Aperçu Non Confirmité',
  photo: 'Aperçu Photos',
  export: 'Export .doc',
  interview_title: "Liste des items pour l'interview {{abbreviation}} {{location}} le {{audit_date}}",
  not_conform_title: "Liste des constatations non conformes pour {{abbreviation}} {{location}} le {{audit_date}}",
  not_applicable_title: "Liste des items pas d'application pour {{abbreviation}} {{location}} le {{audit_date}}",
  photo_title: "Liste des photos pour {{abbreviation}} {{location}} le {{audit_date}}",
  section_not_applicable: "Thème pas d'application",
  sub_section_not_applicable: "Sous-thème pas d'application",
  theme: "Le thème ",
  subtheme: "Le sous-thème ",
  notApplicable:" Pas d'application ",
  boxNumber:"boîte {{0}} ",
  albumPhoto:"Album photo",   
  documentButton: "Document" 
};
