import React from 'react';
import useEntityUpdateModel from '../EntityUpdateModel';
import EntityUpdateView from '../EntityUpdateView';

export interface EntityUpdateComponentProps {
  idToUpdate: number;
}

const EntityUpdateComponent: React.FC<EntityUpdateComponentProps> = ({
  idToUpdate,
}) => {
  const { data, loading, onUpdate } = useEntityUpdateModel({ idToUpdate });
  return <EntityUpdateView data={data} loading={loading} onUpdate={onUpdate} />;
};

export default EntityUpdateComponent;
