import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext
} from 'react';
import { useTranslation } from 'react-i18next';

import { useReactiveVar } from '@apollo/client';
import {
  openCreateItemVar,
  openUpdateItemVar,
  itemVar,
  itemLastIndexVar,
} from './ItemCache';
import AuthorizationComponent from '../Authorization/AuthorizationComponent/AuthorizationComponent';
import { Rule } from '../Authorization/AuthorizationComponent/Permissions';
import ConfirmDialog from '../Shared/Dialog/ConfirmDialog/ConfirmDialogComponent/ConfirmDialogComponent';
import AddButtonComponent from '../Shared/Button/AddButtonComponent';
import SearchField from '../Shared/Field/SearchField/SearchField';
import { LinearProgress, Typography } from '@material-ui/core';
import AppContext from '../../AppContext';
import PageContainer from '../Shared/Container/PageContainer';
import { useStyles } from '../Shared/Styles/CommonFormSyles';
import ItemCreateDialogComponent from './ItemDialogComponent/ItemCreateDialogComponent';
import ItemUpdateDialogComponent from './ItemDialogComponent/ItemUpdateDialogComponent';
import ItemTableComponent from './ItemTableComponent';
import { useDeleteItem } from '../../services/Item/DeleteService/ItemDeleteService';
import {
  useGetAllItemFromThemeOrSubTheme,
} from '../../services/Item/ReadService/ItemReadService';
import { useUpdateIndex } from '../../services/Item/UpdateService/ItemUpdateService';
import { ItemData } from '../../services/Item/ItemDataModel';
import { sub_theme_template_checklist_data_from_list } from '../../services/SubTheme/SubThemeReadService';
import { theme_template_checklist_data_from_list } from '../../services/Theme/ThemeReadService';
import { template_checklist_data_from_list } from '../../services/TemplateChecklist/TemplateChecklistReadService';
import { useBrowserLocationState } from '../Shared/BrowserLocationState/BrowserLocationState';

export const mainTestIds = {
  buttonOnCreate: 'button-create-item',
  dialogConfirmDelete: 'dialog-confirm-delete-item',
  searchFieldComponent: 'search-field-component',
  paginationComponent: 'entity-pagination-component',
};

const ItemComponentDialog = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { email } = useContext(AppContext);

  const currentLocationState = useBrowserLocationState<{theme: any, subTheme?: any, template: any}>({keysRequired: ["theme", "template"]})

  const currentTheme: theme_template_checklist_data_from_list = useMemo(
    () =>
      currentLocationState && currentLocationState.theme
        ? currentLocationState.theme
        : undefined,
    [currentLocationState],
  );
  const currentSubTheme: sub_theme_template_checklist_data_from_list = useMemo(
    () =>
      currentLocationState && currentLocationState.subTheme
        ? currentLocationState.subTheme
        : undefined,
    [currentLocationState],
  );

  const currentTemplate: template_checklist_data_from_list = useMemo(
    () =>
      currentLocationState && currentLocationState.template
        ? currentLocationState.template
        : undefined,
    [currentLocationState],
  );

  const isOpenCreateItem = useReactiveVar(openCreateItemVar);
  const isOpenUpdateItem = useReactiveVar(openUpdateItemVar);

  const  { data : currentItemData, loading :currentItemDataLoading}  =useGetAllItemFromThemeOrSubTheme(currentTheme?  currentTheme?.id: -1, currentSubTheme? currentSubTheme?.id : -1)

  const [items, setItems] = useState<ItemData[]>([]);
  const [loading, setIsLoading] = useState<boolean>();

  const [
    openDeleteConfirmDialog,
    setOpenDeleteConfirmDialog,
  ] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(-1);
  const { myDeleteItem } = useDeleteItem(
    currentSubTheme ? currentSubTheme.id : currentTheme ? currentTheme.id : -1,
    currentSubTheme ? true : false,
  );

  const updateIndexObj =
    currentSubTheme && currentTheme
      ? {
        template_theme_id: currentTheme.id,
        template_sub_theme_id: currentSubTheme.id,
      }
      : currentTheme
        ? { template_theme_id: currentTheme.id }
        : {};

  const { onUpdateIndex } = useUpdateIndex(updateIndexObj);

  const context = useContext(AppContext);
  const [listItemsFiltered, setListItemsFiltered] = useState<Array<ItemData>>(
    items,
  );
  useEffect(() => {
    if (currentItemData && !currentItemDataLoading) {
      setItems(currentItemData);
      setIsLoading(currentItemDataLoading);
    }
  }, [
    currentItemData,
    currentItemDataLoading,
  ]);

  const handleCreateItem = () => {
    openCreateItemVar(!isOpenCreateItem);
    itemLastIndexVar(items.length + 1);
  };

  const handleDeleteClicked = (row: ItemData) => {
    if (row.id) {
      setOpenDeleteConfirmDialog(true);
      setDeleteId(row.id);
    }
  };

  const openUpdateDialog = (row: ItemData) => {
    if (row.id) {
      openUpdateItemVar(!isOpenUpdateItem);
      itemVar({
        ...itemVar(),
        id: row.id,
        information_fr: row.information_fr ? row.information_fr : '',
        information_nl: row.information_nl ? row.information_nl : '',
        last_updated_date: new Date(),
        norm_code: row.norm_code,
        not_application_option: row.not_application_option,
        page_pdf_fr: row.page_pdf_fr.toString(),
        page_pdf_nl: row.page_pdf_nl.toString(),
        template_sub_theme_id: row.template_sub_theme_id,
        template_theme_id: row.template_theme_id,
        title_fr: row.title_fr,
        title_nl: row.title_nl,
        index: row.index,
      });
    }
  };

  const onConfirmedToDelete = useCallback(() => {
    if (deleteId !== -1) {
      myDeleteItem(deleteId);
      setOpenDeleteConfirmDialog(false);
      setDeleteId(-1);
    } else {
      throw new Error("the entity id to delete doesn't exist");
    }
  }, [deleteId, myDeleteItem]);

  const handleDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }
      const itemToUpdate = items.find(
        ({ id }) => parseInt(result?.draggableId) === id,
      );
      if (itemToUpdate) {
        const { id } = itemToUpdate;

        const newIndex =
          items.length === 1
            ? // if one he return same index
            result.source.index
            : items[result.destination.index].index;

        onUpdateIndex({ id, index: newIndex });
      } else {
        console.warn("draggableId doesn't exist in item list");
      }
    },
    [onUpdateIndex, items],
  );

  const handleSearch = useCallback(
    (filter: string) => {
      const newFilter = filter.toLowerCase();

      if (newFilter && newFilter !== '') {
        let lang = context?.language.substring(0, 2);
        setListItemsFiltered(() =>
          items.filter(item => {
            if (lang === 'fr')
              return (
                item.index.toString().includes(newFilter) ||
                item.title_fr.toLowerCase().includes(newFilter) ||
                item.information_fr?.toLowerCase().includes(newFilter) ||
                item.norm_code.toLowerCase().includes(newFilter) ||
                item.page_pdf_fr.toString().toLowerCase().includes(newFilter)
              );
            if (lang === 'nl')
              return (
                item.index.toString().includes(newFilter) ||
                item.title_nl.toLowerCase().includes(newFilter) ||
                item.information_nl?.toLowerCase().includes(newFilter) ||
                item.norm_code.toLowerCase().includes(newFilter) ||
                item.page_pdf_nl.toString().toLowerCase().includes(newFilter)
              );
            return false;
          }),
        );
      } else setListItemsFiltered(items);
    },
    [context, items],
  );

  const { language } = useContext(AppContext);
  const translateTitle = useMemo((): string => {
    const currentThemeName = currentTheme
      ? language.substring(0, 2) === 'fr'
        ? currentTheme?.short_name_fr
        : currentTheme?.short_name_nl
      : undefined;
    const currentTemplateName =
      language.substring(0, 2) === 'fr'
        ? currentTemplate?.name_fr
        : currentTemplate?.name_nl;
    const currentSubThemeName = currentSubTheme
      ? language.substring(0, 2) === 'fr'
        ? currentSubTheme?.name_fr
        : currentSubTheme?.name_nl
      : undefined;
    if (currentSubThemeName) {
      return t('item:itemTableTitleWithSubtheme', {
        0: currentSubThemeName,
        1: currentThemeName,
        2: currentTemplateName,
      });
    } else {
      return t('item:itemTableTitle', {
        0: currentThemeName,
        1: currentTemplateName,
      });
    }
  }, [currentTheme, language, currentTemplate, currentSubTheme, t]);

  return (
    <AuthorizationComponent rules={[Rule.CAN_VIEW_ITEM_CRUD]} email={email}>
      {loading ? (
        <LinearProgress />
      ) : (
        <PageContainer>
          <div className={classes.itemTitle}>
            <Typography variant="h6" gutterBottom>
              {translateTitle}
            </Typography>
          </div>
          <div className={classes.tableHeader}>
            <AuthorizationComponent
              rules={[Rule.CAN_VIEW_ITEM_CRUD]}
              message={'readOnly'}
            >
              <AddButtonComponent
                label={t('item:addItem')}
                data-testid={mainTestIds.buttonOnCreate}
                onClick={handleCreateItem}
              />
            </AuthorizationComponent>
            <div className={classes.search}>
              {' '}
              <SearchField onChange={handleSearch} />{' '}
            </div>
          </div>
          <ItemTableComponent
            isLoading={loading}
            items={listItemsFiltered}
            handleDeleteClicked={handleDeleteClicked}
            openUpdateDialog={openUpdateDialog}
            onDragEnd={handleDragEnd}
          />
          <ItemCreateDialogComponent />
          <ItemUpdateDialogComponent />

          <ConfirmDialog
            dataTestId={mainTestIds.dialogConfirmDelete}
            open={openDeleteConfirmDialog}
            onClose={() => setOpenDeleteConfirmDialog(false)}
            onConfirm={onConfirmedToDelete}
            title={t('common:delete')}
            text={t('common:confirmDelete')}
          />
        </PageContainer>
      )}
    </AuthorizationComponent>
  );
};

export default ItemComponentDialog;
