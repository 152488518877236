import { makeStyles } from '@material-ui/core/styles';
import { drawerWidth } from '../NavigationStyle';

const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#293d46',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  chevronIcon: {
    color: '#949ea3',
  },
  navigationLabel: {
    paddingLeft: '7px',
    color: '#949ea3',
  },
  logo: {
    paddingLeft: '12px',
    paddingTop: '7px',
  },
}));

export { useStyles };
