import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import AuthorizationComponent from '../Authorization/AuthorizationComponent/AuthorizationComponent';
import { Rule } from '../Authorization/AuthorizationComponent/Permissions';
import ConfirmDialog from '../Shared/Dialog/ConfirmDialog/ConfirmDialogComponent/ConfirmDialogComponent';
import AddButtonComponent from '../Shared/Button/AddButtonComponent';
import SearchField from '../Shared/Field/SearchField/SearchField';
import { LinearProgress, Typography } from '@material-ui/core';
import AppContext from '../../AppContext';
import PageContainer from '../Shared/Container/PageContainer';
import { useStyles } from '../Shared/Styles/CommonFormSyles';
import { useSubThemeDeleteService } from '../../services/SubTheme/SubThemeDeleteService';
import { useGetSubThemeByIdService } from '../../services/SubTheme/SubThemeReadService/SubThemeReadService';
import SubThemeTableComponent from './SubThemeTableComponent';
import SubThemeCreateDialogComponent from './SubThemeDialogComponent/SubThemeCreateDialogComponent';
import SubThemeUpdateDialogComponent from './SubThemeDialogComponent/SubThemeUpdateDialogComponent';
import {
  openCreateSubThemeVar,
  openUpdateSubThemeVar,
  subThemeVar,
} from './SubThemeCache';
import { sub_theme_template_checklist_data_from_list } from '../../services/SubTheme/SubThemeReadService';
import { useUpdateIndexSubTheme } from '../../services/SubTheme/SubThemeWriteService/SubThemeWriteService';
import { useBrowserLocationState } from '../Shared/BrowserLocationState/BrowserLocationState';

export const mainTestIds = {
  buttonOnCreate: 'button-create-subTheme',
  dialogConfirmDelete: 'dialog-confirm-delete-subTheme',
  searchFieldComponent: 'search-field-component',
  paginationComponent: 'subTheme-pagination-component',
};

const SubThemeComponentDialog = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { email } = useContext(AppContext);

  const currentLocationState = useBrowserLocationState<{template:any, theme: any}>({keysRequired: ["template", "theme"]})	

  const currentTheme = useMemo(
    () =>
    currentLocationState && currentLocationState.theme
        ? currentLocationState.theme
        : undefined,
    [currentLocationState],
  );

  const { onUpdateIndexSubTheme } = useUpdateIndexSubTheme({
    theme_template_checklist_id: currentTheme.id,
  });

  const isOpenCreateSubTheme = useReactiveVar(openCreateSubThemeVar);
  const isOpenUpdateSubTheme = useReactiveVar(openUpdateSubThemeVar);

  const { data, loading } = useGetSubThemeByIdService({
    themeId: currentTheme ? currentTheme.id : -1,
  });

  const [subthemes, setSubthemes] = useState<
    sub_theme_template_checklist_data_from_list[]
  >([]);
  const [loadingSubtheme, setIsLoadingSubtheme] = useState<boolean>();

  const [
    openDeleteConfirmDialog,
    setOpenDeleteConfirmDialog,
  ] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(-1);

  const { onDelete } = useSubThemeDeleteService({
    themeId: currentTheme ? currentTheme.id : -1,
  });

  const context = useContext(AppContext);
  const [listItemsFiltered, setListItemsFiltered] = useState<
    Array<sub_theme_template_checklist_data_from_list>
  >(subthemes);

  useEffect(() => {
    //setSubTheme from Theme
    setSubthemes(data);
    setIsLoadingSubtheme(loading);
  }, [data, loading]);

  const handleCreateItem = () => {
    openCreateSubThemeVar(!isOpenCreateSubTheme);
  };

  const handleDeleteClicked = (
    row: sub_theme_template_checklist_data_from_list,
  ) => {
    if (row.id) {
      setOpenDeleteConfirmDialog(true);
      setDeleteId(row.id);
    }
  };

  const openUpdateDialog = (
    row: sub_theme_template_checklist_data_from_list,
  ) => {
    if (row.id && row.theme_template_checklist_id) {
      openUpdateSubThemeVar(!isOpenUpdateSubTheme);
      subThemeVar({
        ...subThemeVar(),
        id: row.id,
        name_fr: row.name_fr,
        name_nl: row.name_nl,
        not_applicable_option: row.not_applicable_option,
        theme_template_checklist_id: row.theme_template_checklist_id,
        index: row.index,
      });
    }
  };

  const onConfirmedToDelete = useCallback(() => {
    if (deleteId !== -1) {
      onDelete(deleteId);
      setOpenDeleteConfirmDialog(false);
      setDeleteId(-1);
    } else {
      throw new Error("the subtheme id to delete doesn't exist");
    }
  }, [deleteId, onDelete]);

  const handleDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }

      const subThemeToUpdate = subthemes.find(
        ({ id }) => parseInt(result?.draggableId) === id,
      );

      if (subThemeToUpdate) {
        const { id } = subThemeToUpdate;
        const newIndex =
          subthemes.length === 1
            ? result.source.index
            : subthemes[result.destination.index].index;
        onUpdateIndexSubTheme({ id, index: newIndex });
      } else {
        console.warn("draggableId doesn't exist in sub theme list");
      }
    },
    [subthemes, onUpdateIndexSubTheme],
  );

  const handleSearch = useCallback(
    (filter: string) => {
      const newFilter = filter.toLowerCase();

      if (newFilter && newFilter !== '') {
        let lang = context?.language.substring(0, 2);
        setListItemsFiltered(() =>
          subthemes.filter(subtheme => {
            if (lang === 'fr')
              return (
                subtheme.index.toString().includes(newFilter) ||
                subtheme.name_fr.toLowerCase().includes(newFilter) ||
                subtheme.not_applicable_option.toString().includes(newFilter)
              );
            if (lang === 'nl')
              return (
                subtheme?.index.toString().includes(newFilter) ||
                subtheme.name_nl.toLowerCase().includes(newFilter) ||
                subtheme.not_applicable_option.toString().includes(newFilter)
              );
            return false;
          }),
        );
      } else setListItemsFiltered(subthemes);
    },
    [context, subthemes],
  );

  const { language } = useContext(AppContext);
  const translateTitle = useMemo((): string => {
    const currentThemeName =
      language.substring(0, 2) === 'fr'
        ? currentTheme?.short_name_fr
        : currentTheme?.short_name_nl;

    const currentTemplateName =
      language.substring(0, 2) === 'fr'
        ? currentLocationState?.template?.name_fr
        : currentLocationState?.template?.name_nl;

    return t('subtheme:subthemeTableTitle', {
      0: currentThemeName,
      1: currentTemplateName,
    });
  }, [currentLocationState, currentTheme, language, t]);

  return (
    <AuthorizationComponent rules={[Rule.CAN_VIEW_THEME_CRUD]} email={email}>
      {loading ? (
        <LinearProgress />
      ) : (
        <PageContainer>
          <div className={classes.itemTitle}>
            <Typography variant="h6" gutterBottom>
              {translateTitle}
            </Typography>
          </div>
          <div className={classes.tableHeader}>
            <AuthorizationComponent
              rules={[Rule.CAN_VIEW_ITEM_CRUD]}
              message={'readOnly'}
            >
              <AddButtonComponent
                label={t('subtheme:createSubTheme')}
                data-testid={mainTestIds.buttonOnCreate}
                onClick={handleCreateItem}
              />
            </AuthorizationComponent>
            <div className={classes.search}>
              {' '}
              <SearchField onChange={handleSearch} />{' '}
            </div>
          </div>
          <SubThemeTableComponent
            isLoading={loadingSubtheme}
            subThemes={listItemsFiltered}
            handleDeleteClicked={handleDeleteClicked}
            openUpdateDialog={openUpdateDialog}
            onDragEnd={handleDragEnd}
          />
          <SubThemeCreateDialogComponent />
          <SubThemeUpdateDialogComponent />

          <ConfirmDialog
            dataTestId={mainTestIds.dialogConfirmDelete}
            open={openDeleteConfirmDialog}
            onClose={() => setOpenDeleteConfirmDialog(false)}
            onConfirm={onConfirmedToDelete}
            title={t('common:delete')}
            text={t('common:confirmDelete')}
          />
        </PageContainer>
      )}
    </AuthorizationComponent>
  );
};

export default SubThemeComponentDialog;
