import TemplateChecklistTableComponent, {
  TemplateChecklistTableComponentProps,
  testIds,
} from './TemplateChecklistTableComponent';

export default TemplateChecklistTableComponent;

export { testIds };

export type { TemplateChecklistTableComponentProps };
