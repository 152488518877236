import { DocumentNode, useMutation } from '@apollo/client';
import { useCallback, useContext, useMemo } from 'react';
import MainAppContext from '../../../AppContext';
import { ItemFormData } from '../ItemFormData';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import {
  READ_ALL_ITEMS_FROM_TEMPLATE_THEME,
  READ_ALL_ITEMS_FROM_TEMPLATE_SUB_THEME,
  GET_ALL_ITEMS_INCLUDE_SOFT_DELETED_IN_THEME,
  GET_ALL_ITEMS_INCLUDE_SOFT_DELETED_IN_SUBTHEME,
} from '../ReadService/ItemReadQuery';
import {
  UPDATE_ITEM,
  UPDATE_ITEMS_INDEX,
  update_items_index_response,
  update_items_index_variables,
} from './ItemUpdateMutation';
import { useGetAllItemsIncludeDeleted } from '../ReadService/ItemReadService';
import { get_all_items_include_deleted_data } from '../ReadService/ItemReadModel';
import { handleReorder } from '../../../components/Shared/utils/order.utils';

export const useUpdateIndex = ({
  template_theme_id,
  template_sub_theme_id,
}: {
  template_theme_id?: number;
  template_sub_theme_id?: number;
}) => {
  const { setNotification } = useContext(MainAppContext);
  const { getItems } = useGetAllItemsIncludeDeleted();
  const refetchQueries: {
    query: DocumentNode;
    variables: { [key: string]: any };
  }[] = useMemo(() => {
    return template_theme_id && !template_sub_theme_id
      ? [
        {
          query: READ_ALL_ITEMS_FROM_TEMPLATE_THEME,
          variables: { template_theme_id },
        },
        {
          query: GET_ALL_ITEMS_INCLUDE_SOFT_DELETED_IN_THEME,
          variables: { template_theme_id },
        },
      ]
      : [
        {
          query: READ_ALL_ITEMS_FROM_TEMPLATE_SUB_THEME,
          variables: { template_sub_theme_id },
        },
        {
          query: GET_ALL_ITEMS_INCLUDE_SOFT_DELETED_IN_SUBTHEME,
          variables: { template_sub_theme_id },
        },
      ];
  }, [template_theme_id, template_sub_theme_id]);

  const [updateIndex] = useMutation<
    update_items_index_response,
    update_items_index_variables
  >(UPDATE_ITEMS_INDEX, {
    refetchQueries,
    awaitRefetchQueries: true,
    onError(error) {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const onUpdateIndex = useCallback(
    (newValue: { id: number; index: number }) => {
      getItems({ template_sub_theme_id, template_theme_id }).then(items => {
        const previousValue:
          | get_all_items_include_deleted_data
          | undefined = items.find(({ id }) => id === newValue.id);
        if (!previousValue) {
          console.warn("the current item for index update does'nt exist");
        } else {
          const itemsReordered: get_all_items_include_deleted_data[] = handleReorder(
            items,
            previousValue.index,
            newValue.index,
          ).map(({ id, ...rest }, index) => ({ ...rest, id, index }));
          updateIndex({ variables: { objects: itemsReordered } });
        }
      });
    },
    [getItems, template_sub_theme_id, template_theme_id, updateIndex],
  );

  return { onUpdateIndex };
};

/**
 * Description - service to update item
 */
export const useUpdateItemFromThemeOrSubTheme = (
  templateThemeOrSubThemeId,
  fromTheme: boolean,
  showNotification = true,
) => {
  const { setNotification } = useContext(MainAppContext);

  const refetchQueries = useMemo(() => (
    [
      fromTheme
        ? {
          query: READ_ALL_ITEMS_FROM_TEMPLATE_THEME,
          variables: { template_theme_id: templateThemeOrSubThemeId },
        }
        : {
          query: READ_ALL_ITEMS_FROM_TEMPLATE_SUB_THEME,
          variables: { template_sub_theme_id: templateThemeOrSubThemeId },
        },
    ]
  ), [templateThemeOrSubThemeId, fromTheme])

  const mutationConfiguration = useMemo(() => ({
    refetchQueries,
    awaitRefetchQueries: true,
    onCompleted() {
      if (showNotification)
        setNotification({
          key: 'common:savedSuccess',
          type: NotificationType.SUCCESS,
        });
    },
    onError() {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });

    },
  }), [refetchQueries, setNotification, showNotification])

  const [updateItem] = useMutation(UPDATE_ITEM, {
    ...mutationConfiguration
  });

  const onUpdateItemFromThemeOrSubTheme = useCallback(
    (id: number, itemData: ItemFormData) => {
      const qat_template_theme_item_set_input = {
        last_updated_date: new Date(),
        ...itemData,
      };
      updateItem({ variables: { id, qat_template_theme_item_set_input } });

    },
    [updateItem],
  );

  return { onUpdateItemFromThemeOrSubTheme };
};
