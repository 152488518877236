import React, { MouseEventHandler } from 'react';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import BuildingFormView from '../BuildingFormView';
import { address_data } from '../../../../services/Address/AddressDataModel';
import useBuildingFormPresenter, {
  BuildingFormData,
} from '../BuildingFormPresenter/BuildingFormPresenter';
import {
  category_location_form_field_config,
  sub_category_location,
} from '../../../../services/Category/CategoryDataModel';
import { audit_data } from '../../../../services/Audit/AuditDataModel';
import { get_by_building_by_pk_data } from '../../../../services/Building/ReadService';

export interface BuildingFormComponentProps {
  onSubmitBuilding: ({
    building,
    redirect,
    haveToUpdateChecklist,
  }: {
    building: BuildingFormData;
    redirect?: boolean;
    haveToUpdateChecklist?: boolean;
  }) => void;
  building?: get_by_building_by_pk_data;
  audit: audit_data;
  title: string;
  submitButtonLabel: string;
  parentPath: string;
  fromCreateBuilding? : boolean;
}

export type BuildingProperty = 'id' | 'address_id' | 'sub_category_location_id';
export type ValidationRulesType = { [key in BuildingProperty]?: any };

export interface BuildingFormViewProps {
  errors: DeepMap<Record<string, any>, FieldError>;
  handleSubmit: (event) => MouseEventHandler<HTMLAnchorElement> | undefined;
  handleValidateAdd:(data: BuildingFormData | any) => void;
  onSubmitForm: (data: BuildingFormData | any) => void;
  addresses: address_data | any;
  loadingAddress: boolean;
  subCategoryOptions: sub_category_location[];
  formConfig: category_location_form_field_config[];
  control: Control<BuildingFormData> | any;
  title: string;
  submitButtonLabel: string;
  parentPath: string;
  audit: audit_data;
  building?: get_by_building_by_pk_data;
  getValues: () => BuildingFormData;
  onConfirmSubCategoryChange: () => void;
  onCloseConfirmSubCategoryChange: () => void;
  openConfirmSubCategoryChange: boolean;
  fromCreateBuilding? : boolean;
}

const BuildingFormComponent: React.FC<BuildingFormComponentProps> = props => {
  const presenterProps = useBuildingFormPresenter(props);
  return <BuildingFormView {...presenterProps} />;
};

export default BuildingFormComponent;
