import React, {
    useCallback,
} from "react";
import {
    Table,
    Paper,
    TableContainer,
    TableHead,
    TableBody,
    TableCell,
    TableRow,
    IconButton,
} from "@material-ui/core";
import { useStyles } from "../../Shared/Styles/CommonFormSyles"
import { useTranslation } from "react-i18next";
import {
    CreateRounded,
    DeleteRounded,
} from "@material-ui/icons";
import { address_data } from "../../../services/Address/AddressDataModel";

export interface LocationAddressTableProps {
    isLoading?: boolean;
    addresses: address_data[];
    handleDeleteClicked: (
        row: address_data,
    ) => void;
    openUpdateDialog: (
        row: address_data,
    ) => void;

}
export const locationAddressTableTestIds = {
    buttonOnUpdate: 'button-table-update-location',
    buttonOnDelete: 'button-table-delete-location',
    paginationComponent: 'pagination-component',
    tableEntityId: 'table-id',
};

const LocationAddressTableComponent: React.FC<LocationAddressTableProps> = ({
    addresses,
    handleDeleteClicked,
    openUpdateDialog,
}: LocationAddressTableProps) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const handleOpenUpdateDialog = useCallback(
        (rowData: address_data) => {
            openUpdateDialog(Array.isArray(rowData)
                ? rowData[0]
                : rowData
            )

        },
        [openUpdateDialog],
    );    
    const onDeleteClicked = useCallback(
        (rowData: address_data) => (
            handleDeleteClicked(Array.isArray(rowData)
                ? rowData[0]
                : rowData
            )
        ),
        [handleDeleteClicked],
    );

    return (
        <>
        <Paper className={classes.paper}>
        <TableContainer className={classes.tableContainer}>
        <Table stickyHeader className={classes.table} aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableRow}>
                            {t("address:street")}
                        </TableCell>
                        <TableCell className={classes.tableRow}>
                            {t("address:houseNumber")}
                        </TableCell>
                        <TableCell className={classes.tableRow}>
                            {t("address:boxNumber")}
                        </TableCell>                                                 
                        <TableCell
                             className={classes.tableRow}
                             align="center"
                        >
                            {t("common:edit")}
                        </TableCell>
                        <TableCell
                             className={classes.tableRow}
                             align="center"
                        >
                            {t("common:delete")}
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {addresses.map((address) => (
                        <TableRow
                            key={address.id}
                        >
                            <TableCell>
                                {address.street}
                            </TableCell>
                            <TableCell>
                                { address.house_number}
                            </TableCell>
                            <TableCell>
                                {address.box_number}
                            </TableCell>                             
                            <TableCell
                                align="center"
                            >
                                <IconButton
                                    data-testid={locationAddressTableTestIds.buttonOnUpdate}
                                    onClick={() => handleOpenUpdateDialog(address)}
                                >
                                    <CreateRounded />
                                </IconButton>
                            </TableCell>
                            <TableCell
                                align="center"
                            >
                                <IconButton
                                    data-testid={locationAddressTableTestIds.buttonOnDelete}
                                    onClick={() => onDeleteClicked(address)}
                                >
                                    <DeleteRounded />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
    </Table>
    </TableContainer>
    </Paper>
        </>
    )
}

export default LocationAddressTableComponent;