import { useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { EntityFormData } from '../../../components/Entity/EntityForm/EntityFormPresenter';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { isContraintViolationError } from '../../../graphql/utils';
import { READ_ALL_ENTITIES } from '../ReadService/EntityReadQuery';

import { UPDATE_ENTITY } from './EntityUpdateMutation';

/**
 * Description - service to update entity
 */
export const useUpdateEntity = () => {
  const { setNotification } = useContext(MainAppContext);

  const [updateEntity] = useMutation(UPDATE_ENTITY, {
    refetchQueries: [{ query: READ_ALL_ENTITIES }],
    awaitRefetchQueries: true,
    onCompleted({ createdEntity }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      if (isContraintViolationError(error))
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      else
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
    },
  });

  const onUpdate = useCallback(
    (id: number, entityData: EntityFormData) => {
      const qat_entity_set_input = {
        last_updated_date: new Date(),
        ...entityData,
      };
      updateEntity({ variables: { id, qat_entity_set_input } });
    },
    [updateEntity],
  );

  return { onUpdate };
};
