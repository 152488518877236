import { makeStyles } from '@material-ui/core/styles';
const useStyles = makeStyles(theme => ({
  search: {},
  create: {
    margin: 10,
  },
  tableHeader: {},
}));

export { useStyles };
