import { building } from '../Building/BuildingDataModel';
import { entity_data } from '../Entity/EntityDataModel';
import { location_data } from '../Location/LocationDataModel';

/**
 * Description - Audit data model
 */
export interface audit_data {
  __typename?: string;
  id: number;
  auditor_email: string;
  audit_date: Date;
  user_id?: string;
  entity: entity_data;
  location_entity: location_data;
  audit_buildings?: building[];
  interview_document?:interview_document[];
}

export enum AuditStatus {
  PRE_AUDIT = 'PRE_AUDIT',
  DONE = 'DONE',
}
export interface interview_document{
  id: number;
  url:string;
  audit_id: number;
}
