import { gql } from '@apollo/client';
import { get_all_items_include_deleted_data } from '../ReadService/ItemReadModel';

/**
 * Description - graphql query to create an entity
 */

export const UPDATE_ITEM = gql`
  mutation update_qat_template_theme_item_by_pk(
    $id: Int!
    $qat_template_theme_item_set_input: qat_template_theme_item_set_input!
  ) {
    update_qat_template_theme_item_by_pk(
      pk_columns: { id: $id }
      _set: $qat_template_theme_item_set_input
    ) {
      id
      information_fr
      information_nl
      last_updated_date
      norm_code
      not_application_option
      page_pdf_fr
      page_pdf_nl
      template_sub_theme_id
      template_theme_id
      title_fr
      title_nl
      index
    }
  }
`;

export interface update_items_index_variables {
  objects: get_all_items_include_deleted_data[];
}

export interface update_items_index_response {
  insert_qat_template_theme_item: Array<{ id: number }>;
}

export const UPDATE_ITEMS_INDEX = gql`
  mutation update_items_index(
    $objects: [qat_template_theme_item_insert_input!]!
  ) {
    insert_qat_template_theme_item(
      objects: $objects
      on_conflict: {
        constraint: template_theme_item_pkey
        update_columns: [index]
      }
    ) {
      returning {
        id
      }
    }
  }
`;

export interface reactivate_template_theme_item_update_process_response {
  update_qat_template_theme_item_by_pk: {
    id: number
  },
  delete_qat_template_theme_item_by_pk: {
    id: number
  }
}

export interface reactivate_template_theme_item_update_process_variables {
  id: number,
  object: {
    creation_date?: Date;
    information_fr: string;
    information_nl: string;
    last_updated_date?: Date;
    deleted_at?: string | Date | null;
    norm_code: string;
    not_application_option: boolean;
    page_pdf_fr: number;
    page_pdf_nl: number;
    template_sub_theme_id?: number;
    template_theme_id?: number;
    title_fr: string;
    title_nl: string;
    index: number;
  },
  id_to_delete: number
}

export const REACTIVATE_ITEM_FOR_UPDATE_PROCESS = gql`
mutation reactivate_template_theme_item_update_process (
  $id: Int!
  $object: qat_template_theme_item_set_input
  $id_to_delete: Int!
){
  update_qat_template_theme_item_by_pk(pk_columns:{id: $id }, _set: $object){
    id
  }
  delete_qat_template_theme_item_by_pk(id: $id_to_delete){
    id
  }
}
`
