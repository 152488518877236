import { useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { AuditFormData } from '../../../components/Audit/AuditForm/AuditFormPresenter';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { isContraintViolationError } from '../../../graphql/utils';
import {
  READ_ALL_DONE_AUDITS_SORTED,
  READ_CURRENT_AUDITS,
} from '../ReadService/AuditReadQuery';
import { useCheckUniqueAudit } from '../ReadService/AuditReadService';
import { UPDATE_AUDIT } from './AuditUpdateMutation';

/**
 * Description - service to update audit
 */
export const useUpdateAudit = () => {
  const { setNotification, username } = useContext(MainAppContext);

  const { checkUniqueAudit } = useCheckUniqueAudit();

  const [updateAudit] = useMutation(UPDATE_AUDIT, {
    refetchQueries: [
      {
        query: READ_ALL_DONE_AUDITS_SORTED,
        variables: { order_by: { last_updated_date: 'desc' } },
      },
      { query: READ_CURRENT_AUDITS },
    ],
    awaitRefetchQueries: true,
    onCompleted({ createdAudit }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      if (isContraintViolationError(error))
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      else
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
    },
  });

  const onUpdate = useCallback(
    async (id: number, auditFormData: AuditFormData): Promise<any> =>
      new Promise(resolve => {
        const { entity_id, location_id, audit_date } = auditFormData;
        checkUniqueAudit({
          entity_id: entity_id,
          location_id: location_id,
          user_id: username,
          audit_date: audit_date,
          id,
        }).then(isUnique => {
          if (isUnique) {
            const { audit_buildings, ...rest } = auditFormData;
            const qat_audit_set_input = {
              last_updated_date: new Date(),
              ...rest,
            };
            updateAudit({
              variables: { id, qat_audit_set_input },
            }).then(response => {  resolve(response);})
          } else {
            setNotification({
              key: 'audit:auditValidationError',
              type: NotificationType.ERROR,
            });
          }
        });
      }),
    [updateAudit, checkUniqueAudit, username, setNotification],
  );

  return { onUpdate };
};
