import { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import MainAppContext from '../../AppContext';

const NotificationType = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  DEFAULT: 'default',
};

/**
 * This component is responsible for all notifications in the application
 * @param props contains the notification
 */
const NotificationComponent = props => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { setNotification } = useContext(MainAppContext);

  useEffect(() => {
    if (props.notification) {
      const key: string = props.notification.key
        ? props.notification.key
        : 'common:generalError';

      enqueueSnackbar(t(key), {
        variant: props.notification.type,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        
      });
    }
    setNotification(null);
  });

  return null;
};

export { NotificationComponent, NotificationType };
