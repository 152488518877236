import { Button, Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import ChecklistReportInterviewComponent from '../../ChecklistReportInterview/ChecklistReportInterviewComponent';
import ChecklistReportNotApplicableComponent from '../../ChecklistReportNotApplicable/ChecklistReportNotApplicableComponent';
import ChecklistReportNotConformComponent from '../../ChecklistReportNotConform/ChecklistReportNotConformComponent';
import ChecklistReportPhotoComponent from '../../ChecklistReportPhoto/ChecklistReportPhotoComponent/ChecklistReportPhotoComponent';
import AdminDocumentDialog from '../../AdminDocument/AdminDocumentRead/AdminDocumentDialog'
import { useDocumentDialogService } from '../../AdminDocument/AdminDocumentRead/AdminDocumentService'
import { AdminocumentServiceContextProvider } from "../../AdminDocument/AdminDocumentRead/AdminDocumentServiceContext"
import { useBrowserLocationState } from '../../Shared/BrowserLocationState/BrowserLocationState';


export const testIds = {
  buttonToDuplicate: 'checklist-theme-section-button-to-duplicate',
};

export interface ChecklistReportViewProps {
  isReadOnlyMode?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  tabRoot: {
    minWidth: 10,
  },
}));

const ChecklistReportView: React.FC<ChecklistReportViewProps> = ({ isReadOnlyMode }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  
  const currentLocationState = useBrowserLocationState<{audit: any}>({keysRequired: ["audit"]})

  const audit = useMemo(() => {
    return currentLocationState?.audit ? currentLocationState?.audit : undefined;

  }, [currentLocationState])

  const { pageNumber, onSelectPage, open, onCloseDialog, onNextPage, onPreviousPage } = useDocumentDialogService()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  return (
    <>
      <AdminocumentServiceContextProvider
        value={{
          onCloseDialog,
          open,
          onSelectPage,
          pageNumber,
          onNextPage,
          onPreviousPage
        }}
      >
        <AdminDocumentDialog
          open={open}
          pageNumber={pageNumber}
          onCloseDialog={onCloseDialog}
        />
        <Grid container>
          <Grid lg={12} xs={12} item>
            <div className={classes.root}>
              <AppBar position="sticky" color="default">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="full width tabs example"
                  variant="scrollable"
                  scrollButtons="on"
                >
                  <Tab
                    classes={{ root: classes.tabRoot }}
                    label={t('report:interview')}
                    {...a11yProps(0)}
                  />
                  <Tab
                    classes={{ root: classes.tabRoot }}
                    label={t('report:not_applicable')}
                    {...a11yProps(1)}
                  />
                  <Tab
                    classes={{ root: classes.tabRoot }}
                    label={t('report:not_conform')}
                    {...a11yProps(2)}
                  />
                  <Tab
                    classes={{ root: classes.tabRoot }}
                    label={t('report:photo')}
                    {...a11yProps(3)}
                  />
                  <Tab
                    classes={{ root: classes.tabRoot }}
                    label={t('report:export')}
                    {...a11yProps(4)}
                  />
                </Tabs>
              </AppBar>
              <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
              >
                <TabPanel value={value} index={0} dir={theme.direction}>
                  <ChecklistReportInterviewComponent
                    audit_id={currentLocationState?.audit?.id}
                    isReadOnlyMode={isReadOnlyMode}
                  />
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                  <ChecklistReportNotApplicableComponent audit={audit} />
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                  <ChecklistReportNotConformComponent
                    audit={audit}
                  />
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                  <ChecklistReportPhotoComponent audit_id={audit ? audit.id : -1} />
                </TabPanel>
                <TabPanel value={value} index={4} dir={theme.direction}>
                  <Button></Button>
                </TabPanel>
              </SwipeableViews>
            </div>
          </Grid>

        </Grid>
      </AdminocumentServiceContextProvider>

    </>
  );
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={'div'}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default ChecklistReportView;
