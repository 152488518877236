import { Paper, Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BlockIcon from '@material-ui/icons/Block';
import { Link } from 'react-router-dom';
import routes from '../../../routes';
import { useStyles } from '.';
/**
 * Description - Page when the user is not authorized
 */
const NotAuthorizationPageComponent = () => {
  const { t } = useTranslation();

  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Grid container>
        <Grid className={classes.item} item sm={12}>
          <BlockIcon className={classes.icon} color="error" fontSize="large" />
        </Grid>
        <Grid className={classes.item} item sm={12}>
          <Typography variant="h4">{t('common:roleNotAuthorized')}</Typography>
        </Grid>
        <Grid className={classes.item} item sm={12}>
          <Button
            className={classes.button}
            component={Link}
            to={routes.currentAudits.path}
          >
            {t('common:roleNotAuthorizedBack')}
          </Button>
        </Grid>
      </Grid>
      <div></div>
    </Paper>
  );
};

export default NotAuthorizationPageComponent;
