import React from 'react';
import { useTemplateChecklistService } from '../TemplateChecklistService';
import TemplateChecklistView from '../TemplateChecklistView';

/**
 * Description - Component to manage template checklist
 */
const TemplateChecklistComponent = () => {
  useTemplateChecklistService();
  return <TemplateChecklistView />;
};

export default TemplateChecklistComponent;
