import { gql } from '@apollo/client';
import { checklist_theme } from './CheckistReadModel'

export interface get_checklist_by_pk_data_response {
  qat_checklist_by_pk: get_checklist_by_pk_data;
}

export interface get_checklist_by_pk_sub_section_data {
  id: number;
  no_application: boolean;
  name_fr: string;
  name_nl: string;
  index: number;
  not_applicable_option: boolean;
  checklist_theme_items: get_checklist_by_pk_item_data[];
  sub_theme_template_checklist: {
    id: number;
    name_fr: string;
    name_nl: string;
    index: number;
    not_applicable_option: boolean;
  };
}

export interface get_checklist_by_pk_section_data {
  id: number;
  name_fr: string;
  name_nl: string;
  specification: string;
  checklist_theme_id: number;
  theme_template_checklist_id: number;
  no_application: boolean;
  theme_template_checklist: {
    id: number;
    short_name_nl: string;
    long_name_nl: string;
    short_name_fr: string;
    long_name_fr: string;
    not_applicable_option: boolean;
    duplicable: boolean;
    index: number;
  };
  checklist_theme_sub_sections: get_checklist_by_pk_sub_section_data[];
  checklist_theme_items: get_checklist_by_pk_item_data[];
  checklist_theme?: checklist_theme;
}

export interface get_checklist_by_pk_theme_data {
  id: number;
  comment: string;
  not_applicable: boolean;
  not_applicable_option: boolean;
  short_name_fr: string;
  short_name_nl: string;
  long_name_fr: string;
  long_name_nl: string;
  checklist_theme_sections: get_checklist_by_pk_section_data[];
  duplicate_section_number: number;
}

export interface get_checklist_by_pk_data {
  id: number;
  template_checklist: {
    sub_category_location_id: number;
  };
  comment: string;
  checklist_themes: get_checklist_by_pk_theme_data[];
}

export interface get_checklist_by_pk_item_data {
  id: number;
  not_application: boolean;
  has_interview: boolean;
  comment: string;
  is_conform: boolean | undefined;
  name_fr: string;
  name_nl: string;
  template_theme_item_id: number;
  norm_code: string;
  page_pdf_nl: number;
  page_pdf_fr: number;
  checklist_theme_item_remarks: {
    id: number;
    template_theme_item_remark_id: number;
    checked: boolean;
    name_fr: string;
    name_nl: string;
    index: number;
  }[];
  template_theme_item: {
    id: number;
    title_nl: string;
    title_fr: string;
    information_nl: string;
    information_fr: string;
    norm_code: string;
    not_application_option: boolean;
    index: number;
    page_pdf_fr: number;
    page_pdf_nl: number;
    template_theme_item_remarks: {
      id: number;
      remark_text_nl: string;
      remark_text_fr: string;
      index: number;
    }[];
  };
}

/**
 * Description - graphql query to get a specific checklist by id
 */
export const GET_CHECKLIST_BY_PK = gql`
  query qat_checklist_by_pk($id: Int!) {
    qat_checklist_by_pk(id: $id) {
      id
      template_checklist {
        sub_category_location_id
      }
      comment
      checklist_themes(order_by: { id: asc }) {
        id
        comment
        not_applicable
        not_applicable_option
        short_name_fr
        short_name_nl
        long_name_fr
        long_name_nl
        duplicate_section_number
        checklist_theme_sections(order_by: { id: asc }) {
          id
          name_fr
          name_nl
          specification
          checklist_theme_id 
          theme_template_checklist_id
          no_application
          checklist_theme {
            not_applicable
          }
          theme_template_checklist {
            id
            short_name_nl
            long_name_nl
            short_name_fr
            long_name_fr
            not_applicable_option
            duplicable
            index
          }
          checklist_theme_sub_sections(order_by: { index: asc }) {
            id
            no_application
            name_fr
            name_nl
            not_applicable_option
            index
            checklist_theme_items(order_by: { id: asc }) {
              id
              not_application
              has_interview
              comment
              is_conform
              name_fr
              name_nl
              template_theme_item_id
              norm_code
              page_pdf_nl
              page_pdf_fr
              checklist_theme_item_remarks(order_by: { index: asc }) {
                id
                name_fr
                name_nl
                index
                template_theme_item_remark_id
                checked
              }
              template_theme_item {
                id
                title_nl
                title_fr
                information_nl
                information_fr
                norm_code
                not_application_option
                index
                page_pdf_fr
                page_pdf_nl
                template_theme_item_remarks(
                  where: { deleted_at: { _is_null: true } }
                  order_by: { id: asc }
                ) {
                  id
                  remark_text_nl
                  remark_text_fr
                  index
                }
              }
            }
            sub_theme_template_checklist {
              id
              name_fr
              name_nl
              index
              not_applicable_option
            }
          }
          checklist_theme_items(order_by: { id: asc }) {
            id
            not_application
            has_interview
            comment
            is_conform
            name_fr
            name_nl
            norm_code
            page_pdf_nl
            page_pdf_fr
            template_theme_item_id
            checklist_theme_item_remarks(order_by: { index: asc }) {
              id
              name_fr
              name_nl
              index
              template_theme_item_remark_id
              checked
            }
            template_theme_item {
              id
              title_nl
              title_fr
              information_nl
              information_fr
              norm_code
              not_application_option
              index
              page_pdf_fr
              page_pdf_nl
              template_theme_item_remarks(
                where: { deleted_at: { _is_null: true } }
                order_by: { index: asc }
              ) {
                id
                remark_text_nl
                remark_text_fr
                index
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * Description - graphql query to get a specific checklist by template, audit and building
 */
export const GET_CHECKLIST_BY_TEMPLATE_ID_AND_AUDIT_ID_AND_BUILDING_ID = gql`
  query qat_checklist($template_id: Int!, $audit_id: Int!, $building_id: Int!) {
    qat_checklist(
      where: {
        template_id: { _eq: $template_id }
        audit_id: { _eq: $audit_id }
        building_id: { _eq: $building_id }
      }
    ) {
      id
      comment
      checklist_themes(order_by: { theme_template_checklist: { index: asc } }) {
        id
        comment
        checklist_theme_sections {
          id
          specification
          theme_template_checklist(
            where: { deleted_date: { _is_null: true } }
          ) {
            id
            short_name_nl
            long_name_nl
            short_name_fr
            long_name_fr
            not_applicable_option
            duplicable
          }
        }
      }
    }
  }
`;
