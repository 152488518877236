import React from 'react';
import { Switch, Route } from 'react-router-dom';
import routes from '../../../routes';
import { Rule } from '../../Authorization/AuthorizationComponent/Permissions';
import AuthorizationRouteComponent from '../../Authorization/AuthorizationRouteComponent';
import ChecklistReportComponent from '../../ChecklistReport/ChecklistReportComponent';
import AuditCreateComponent from '../AuditCreate/AuditCreateComponent/AuditCreateComponent';
import AuditReadAllComponent from '../AuditRead/AuditReadAll/AuditReadAllComponent';
import AuditReadComponent from '../AuditRead/AuditReadCurrent/AuditReadCurrentComponent/AuditReadCurrentComponent';
import AuditUpdateComponent from '../AuditUpdate/AuditUpdateComponent';

export interface AuditViewProps {
  onUpdate: (id: number) => void;
  onDelete: (id: number) => void;
  onClose: (id: number) => void;
  audit_id: number | undefined;
}

export const testIds = {
  editAuditComponent: 'edit-audit-component',
  createAuditComponent: 'create-audit-component',
  listAuditComponent: 'list-audit-component',
};

const AuditView = ({ onUpdate, onDelete, audit_id, onClose }) => (
  <Switch>
    <Route path={routes.currentAudits.path}>
      <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_AUDIT_READ]}>
        <AuditReadComponent
          onUpdate={onUpdate}
          onDelete={onDelete}
          onClose={onClose}
        />
      </AuthorizationRouteComponent>
    </Route>
    <Route path={routes.listAudit.path}>
      <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_AUDIT_READ]}>
        <AuditReadAllComponent onUpdate={onUpdate} />
      </AuthorizationRouteComponent>
    </Route>
    <Route path={routes.createAudit.path}>
      <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_AUDIT_CRUD]}>
        <AuditCreateComponent />
      </AuthorizationRouteComponent>
    </Route>

    <Route path={routes.editAudit.path}>
      <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_AUDIT_CRUD]}>
        <AuditUpdateComponent />
      </AuthorizationRouteComponent>
    </Route>

    <Route path={routes.checklistReport.path}>
      <AuthorizationRouteComponent rules={[Rule.CAN_VIEW_AUDIT_CRUD]}>
        <ChecklistReportComponent />
      </AuthorizationRouteComponent>
    </Route>
  </Switch>
);

export default AuditView;
