import { gql } from '@apollo/client';

/**
 * Description - graphql query to delete an building (soft delete)
 */
export const DELETE_BUILDING = gql`
  mutation update_qat_building_by_pk($id: Int!, $deletedDate: timestamptz!) {
    update_qat_building_by_pk(
      pk_columns: { id: $id }
      _set: { deleted_date: $deletedDate }
    ) {
      id
    }
  }
`;

/**
 * Description - graphql query to delete all buildings by audit id (hard delete)
 */
export const DELETE_BUILDING_BY_AUDIT = gql`
  mutation delete_qat_audit_building($id: Int!) {
    delete_qat_audit_building(where: { audit_id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
