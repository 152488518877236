export const address = {
  address: 'Adresse',
  addAddress: 'Créer une adresse',  
  createAddress: 'Ajouter une adresse',
  street: 'Rue',
  houseNumber: 'Numéro de maison',
  boxNumber: 'Numéro de boîte',
  city: 'Ville',
  postalCode: 'Code postal',
  streetError: 'La rue est un champ obligatoire',
  houseNumberError: 'Le numéro de maison est un champ obligatoire',
  cityError: 'La ville est un champ obligatoire',
  postalCodeError: 'Code postal est un champ obligatoire',
  addressValidationError: "L'adresse que vous avez spécifiée existe déjà",
  updateAddress: 'Modifier une addresse',
  addressTableTitle: 'Liste des adresses pour la localité "{{0}}" de l\'entité "{{1}}" ',
  validateAdd: 'Ajouter une autre adresse',
  LoactionAddresWithBoxUniqueError: 'Il existe déjà une adresse avec la rue "{{0}}" ,numnéro de rue "{{1}}" avec la boîte potale "{{2}}" pour la localité "{{3}}"' ,
  LoactionAddresWithoutBoxUniqueError: 'Il existe déjà une adresse avec la rue "{{0}}" et le numnéro de rue "{{1}}"  pour la localité "{{3}}"'  ,
  entityName:"Entité",
  locationName:"Localité",
  updateAddressTitle:"Modifier une adresse",
  updateAddressMsg:"Cette adresse est déjà utilisée dans des audits. Si vous la modifiez, vous changez également l'adresse dans ces audits. Êtes-vous certain de continuer?"
};
