import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { isContraintViolationError } from '../../../graphql/utils';
import { READ_ALL_ENTITIES, READ_ALL_ENTITIES_SORTED } from '../../Entity/ReadService/EntityReadQuery';
import { READ_ALL_LOCATIONS_BY_ENTITY_ID } from '../ReadService/LocationReadQuery';
import { INSERT_LOCATION } from './LocationWriteMutation';
export interface LocationCreateFormData {
  name: string;
  entity_id?: string | number;
  category_id:number;
  specification?:string;
}

export const useCreateLocation = (entity_id) => {
  const { setNotification } = useContext(MainAppContext);

  const [createLocation] = useMutation(INSERT_LOCATION, {
    refetchQueries: [{
      query: READ_ALL_LOCATIONS_BY_ENTITY_ID,
      variables: { entity_id }
    },
    {query: READ_ALL_ENTITIES},
    {
      query: READ_ALL_ENTITIES_SORTED,
      variables:{order_by:{label: "asc"}}
    }
  
  ],    
    awaitRefetchQueries: true,
    onCompleted({ createdLocation }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {      
      if (isContraintViolationError(error))
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      else
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
    },
  });

  const onCreate = async (data: LocationCreateFormData) => {
    await createLocation({ variables: { object: data } });
  };

  return { onCreate };
};
