import { useState, useMemo } from 'react';

export interface PaginateItemsServiceProps {
  items: Array<any>;
}

/**
 * Description - service to paginate data
 */
export const usePaginateItems = ({ items }: PaginateItemsServiceProps) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value));
    setPage(0);
  };

  const paginateItems: Array<typeof items> = useMemo(
    () => items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
    [items, rowsPerPage, page],
  );

  return {
    rows: paginateItems,
    rowsPerPage,
    page,
    onChangePage: handleChangePage,
    onChangeRowsPerPage: handleChangeRowsPerPage,
  };
};
