import { TablePagination } from '@material-ui/core';
import React, { Fragment, useCallback, useMemo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { usePaginateItems } from '../PaginationService/PaginationService';

export interface PaginationComponentProps {
  children: ReactElement;
  items: Array<any>;
  itemsPropsName?: string | number | symbol | any;
  dataTestId?: string;
}

export const testId = 'component-pagination';

/**
 * Description - Component UI to render table children with pagination
 */
const PaginationComponent: React.FC<PaginationComponentProps> = ({
  children,
  dataTestId,
  itemsPropsName,
  items,
}) => {
  const {
    rows,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
  } = usePaginateItems({ items });
  const { t } = useTranslation();

  const ChildrenComponent = useCallback(() => {
    const itemsChildrenPropsToChange = {
      [itemsPropsName ? itemsPropsName : 'items']: rows,
    };
    return React.cloneElement(children, itemsChildrenPropsToChange);
  }, [children, rows, itemsPropsName]);

  const componentDataTestId = useMemo(
    () => (dataTestId ? dataTestId : testId),
    [dataTestId],
  );

  return (
    <Fragment>
      <ChildrenComponent />
      <TablePagination
        data-testid={componentDataTestId}
        rowsPerPage={rowsPerPage}
        component="div"
        onPageChange={onChangePage}
        count={items.length}
        page={page}
        onRowsPerPageChange={onChangeRowsPerPage}
        labelRowsPerPage={t('common:rowsPerPage')}    
      />
    </Fragment>
  );
};

export default PaginationComponent;
