import React from 'react';
import useEntityFormPresenter, { EntityFormData } from '../EntityFormPresenter';
import { EntityProperty } from '../../EntityModel';
import { Control, DeepMap, FieldError } from 'react-hook-form';
import EntityFormView from '../EntityFormView';

export interface EntityFormComponentProps {
  onSubmit: (entity: EntityFormData) => void;
  entity?: EntityFormData;
  title: string;
  submitButtonLabel: string;
}

export type ValidationRulesType = { [key in EntityProperty]?: any };

export interface EntityFormViewProps {
  errors: DeepMap<Record<string, any>, FieldError>;
  onSubmit: (Event) => void;
  control: Control<EntityFormData> | any;
  title: string;
  submitButtonLabel: string;
}

const EntityFormComponent: React.FC<EntityFormComponentProps> = ({
  onSubmit,
  entity,
  title,
  submitButtonLabel,
}) => {
  const props = useEntityFormPresenter({
    onSubmit,
    entity,
    title,
    submitButtonLabel,
  });
  return <EntityFormView {...props} />;
};

export default EntityFormComponent;
