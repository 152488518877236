import React from 'react';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.success.main,
    margin: 10,
    '&:hover': {
       background: theme.palette.success.dark,
    },
  },
  label: {
    color: 'white',
  },
}));

export interface AddButtonComponentProps {
  label: string;
  onClick?: () => void;
  linkPath?: string | { pathname: string; state: any };
  isDisabled?: boolean;
}

/**
 * Description - This is a shared add button component
 */
const AddButtonComponent: React.FC<AddButtonComponentProps> = ({
  label,
  onClick,
  linkPath,
  isDisabled,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return linkPath ? (
    <Button
      component={Link}
      to={linkPath}
      variant="contained"
      classes={classes}
      startIcon={<AddIcon />}
      onClick={onClick}
      disabled={isDisabled}
    >
      {t(label)}
    </Button>
  ) : (
    <Button
      variant="contained"
      classes={classes}
      startIcon={<AddIcon />}
      onClick={onClick}
      disabled={isDisabled}
    >
      {t(label)}
    </Button>
  );
};

export default AddButtonComponent;
