import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  languageButtons: {
    padding: theme.spacing(1),
  },
  languageButton: {
    marginRight: theme.spacing(1),
    minWidth: 50,
    color: '#ffffff',
    background: '#cf012c',
    border: 0,
    borderRadius: '0%',
    '&:hover': {
      backgroundColor: '#141414',
      color: '#ffffff',
      border: 0,
      borderRadius: '0%',
    },
  },
}));

export { useStyles };
