export const profile = {
  profile: 'Profil',
  changePassword: 'Changer le mot de passe',
  email: 'Email',
  currentPassword: 'Mot de passe actuel',
  newPassword: 'Nouveau mot de passe',
  confirmationPassword: 'Confirmer votre mot de passe',
  emailError: "L'adresse e-mail ne peut pas être récupérée",
  currentPasswordRequired: 'Le mot de passe actuel est un champ obligatoire',
  newPasswordError:
    'Le nouveau mot de passe est un champ obligatoire et doit contenir un minimum de 8 à 16 caractères, avec au moins 1 capital, 1 valeur numérique, 1 symbole et doit être différent du mot de passe actuel',
  confirmationPasswordError:
    'La confirmation de la nouvelle mot de passe est un champs obligatoire et doit correspondre au nouveau mot de passe.',
  passwordSuccess: 'Votre mot de passe à été modifié avec succès',
  NotAuthorizedException:
    'Vous ne pouvez pas modifier votre mot de passe car votre mot de passe actuel est incorrect',
  LimitExceededException:
    'Limite de tentatives dépassée, réessayez après un certain temps',
  InvalidParameterException:
    "Erreur de validation: le nouveau mot de passe ne correspond pas à l'expression régulière: ^ [\\ S] +. * [\\ S] + $",
};
