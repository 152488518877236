import { useMutation } from '@apollo/client';
import { useContext, useCallback } from 'react';
import MainAppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { READ_ALL_ADDRESSES, READ_ALL_ADDRESSES_BY_LOCATION_ID, READ_ALL_ADDRESSES_SOFT_DELETE_BY_LOCATION_ID } from '../ReadService/AddressReadQuery';
import { useGetAllAddressIncludeDeleted } from '../ReadService/AddressReadService';
import { INSERT_ADDRESS } from './AddressWriteMutation';
import { useTranslation } from 'react-i18next';
import { address_location_data } from '../ReadService/AddressReadModel';
import { UPDATE_ADDRESS } from '../UpdateService/AddressUpdateMutation';
import { isContraintViolationError } from '../../../graphql/utils';

export interface AddressCreateFormData {
  street: string;
  house_number: string;
  box_number?: string;
  city: string;
  location_entity_id: string | number;
}
export interface AddressUniqueErrorResponse {
  sameAddressExist?: string;   
  createActivateAddressId?: number;
  createAddress?:string;
}

export const useCheckAddressUnique = () => {
  const { setNotification } = useContext(MainAppContext);
  const { getLocationAddresses } = useGetAllAddressIncludeDeleted()

  const { t } = useTranslation()

  const onCheckAddressUnique = useCallback((
    addressData: AddressCreateFormData
  ) => {
    return new Promise<AddressUniqueErrorResponse | void>(resolve => {
      const results: AddressUniqueErrorResponse = {}
      const location_entity_id  = addressData.location_entity_id
      getLocationAddresses({location_entity_id }).then(addresses => {
        let doubleAddress: address_location_data | undefined = undefined      
              doubleAddress = addresses.find((address) =>
              address.street?.trim().toLowerCase() === addressData.street?.trim().toLowerCase() &&
              address.city?.trim().toLowerCase() === addressData.city?.trim().toLowerCase() &&
              address.house_number?.trim().toLowerCase()  === addressData.house_number?.trim().toLowerCase()  &&
              address.box_number?.trim().toLowerCase() === addressData.box_number?.trim().toLowerCase() &&
              address.location_entity_id === addressData.location_entity_id)
                      
        if (doubleAddress && doubleAddress?.deleted_date === null   ) {   
          const msg= t("address:addressValidationError")
          results.sameAddressExist = msg   
          results.createAddress = undefined
          results.createActivateAddressId = undefined
        } else if (doubleAddress && doubleAddress?.deleted_date !== null   ) {          
          results.sameAddressExist = undefined   
          results.createAddress = undefined
          results.createActivateAddressId = doubleAddress?.id    
        }else{
          const msg= t("address:addressCreatedAddress")
          results.sameAddressExist = undefined   
          results.createAddress = msg
          results.createActivateAddressId = undefined       
        }

        if (Object.keys(results).length) {
          resolve(results)
        } else {
          setNotification({
            key: 'common:generalError',
            type: NotificationType.ERROR,
          });
          resolve()
        }
      })
    })

  },[t, getLocationAddresses, setNotification])

  return { onCheckAddressUnique }
}

export const useCreateAddress = ({location_entity_id}:{location_entity_id: number}) => {
  const { setNotification } = useContext(MainAppContext);
  const { onCheckAddressUnique } = useCheckAddressUnique();

  const [createAddress] = useMutation(INSERT_ADDRESS, {
    refetchQueries: [
      { query: READ_ALL_ADDRESSES },
      { query: READ_ALL_ADDRESSES_BY_LOCATION_ID, variables:{location_entity_id}},
      { query: READ_ALL_ADDRESSES_SOFT_DELETE_BY_LOCATION_ID, variables:{location_entity_id}}
      
    ],
    awaitRefetchQueries: true,
    onCompleted({ createdAddress }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      if (isContraintViolationError(error))
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      else
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
    },
  });

   const [updateAddress] = useMutation(UPDATE_ADDRESS, {
    refetchQueries: [
      { query: READ_ALL_ADDRESSES },
      { query: READ_ALL_ADDRESSES_BY_LOCATION_ID, variables:{location_entity_id}},
      { query: READ_ALL_ADDRESSES_SOFT_DELETE_BY_LOCATION_ID, variables:{location_entity_id}}
      
    ],
    awaitRefetchQueries: true,
    onCompleted() {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      if (isContraintViolationError(error))
        setNotification({
          key: 'item:itemValidationError',
          type: NotificationType.WARNING,
        });
      else
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
    },
  }); 

  const onCreate = async (addressData: AddressCreateFormData): Promise<any> => {
    return new Promise(resolve => {      
      onCheckAddressUnique(
        addressData
        )
      .then(results => {
        if (results["createAddress"]) {
          createAddress({ variables: { object: addressData } }).then(response =>
            resolve(response),
          );
        } else if(results["createActivateAddressId"] && results["createActivateAddressId"] !== 0) {
          const qat_address_set_input = {
            last_updated_date: new Date(),
            deleted_date: null,
            ...addressData,
          };
          const id =  results["createActivateAddressId"]
          updateAddress({ variables: { id, qat_address_set_input } }).then(
            response => {
              resolve(response);
            },
          );
        } else {
          setNotification({
            key: 'address:addressValidationError',
            type: NotificationType.WARNING,
          });
          resolve(null);
        }
      });
    });
   
  };
  return { onCreate };
};
