import { gql } from '@apollo/client';

/**
 * Description - graphql query to get all sub themes for a theme
 *
 */
export const READ_SUB_THEME_TEMPLATE_CHECKLIST_BY_THEME_ID = gql`
  query list_sub_theme_template_checklist_by_template_id($themeId: Int!) {
    qat_sub_theme_template_checklist(
      where: {
        _and: [
          { deleted_date: { _is_null: true } }
          { theme_template_checklist_id: { _eq: $themeId } }
        ]
      }
      order_by: { index: asc }
    ) {
      id
      name_fr
      name_nl
      index
      not_applicable_option
      theme_template_checklist_id
      template_theme_items {
        id
        deleted_at
      }
    }
  }
`;

export const GET_SUB_THEME_INCLUDE_SOFT_DELETE_BY_THEME = gql`
  query list_sub_theme_template_checklist_include_soft_delete_by_template_id(
    $theme_template_checklist_id: Int!
  ) {
    qat_sub_theme_template_checklist(
      where: {
        theme_template_checklist_id: { _eq: $theme_template_checklist_id }
      }
      order_by: { index: asc }
    ) {
      id
      name_fr
      name_nl
      index
      theme_template_checklist_id
      deleted_date
    }
  }
`;
