import { useQuery, useApolloClient } from '@apollo/client';
import { useContext, useMemo, useCallback } from 'react';
import {
  READ_ALL_TEMPLATE_CHECKLIST,
  template_checklist_data_from_list,
  template_checklist_data_list,
} from '.';
import MainAppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import {
  qat_template_checklist_data,
  template_by_pk_response,
} from './TemplateCheckistReadModel';
import {
  GET_TEMPLATE_BY_CATEGORY_ID_AND_SUB_CATEGORY_ID,
  GET_TEMPLATE_BY_PK,
} from './TemplateChecklistReadQuery';

/**
 * Description - Service to get all template checklist
 */
export const useGetAllTemplateChecklist = () => {
  const { setNotification } = useContext(MainAppContext);

  const { data, loading, error } = useQuery<template_checklist_data_list>(
    READ_ALL_TEMPLATE_CHECKLIST,
    {
      onError(errorResponse) {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    },
  );

  const templateChecklistData: template_checklist_data_from_list[] = useMemo(() => {
    return !loading && data?.qat_template_checklist
      ? data?.qat_template_checklist
      : [];
  }, [data, loading]);

  return { data: templateChecklistData, loading, error };
};

/**
 * Description - service to get a template by id
 */
export const useGetTemplateById = id => {
  const { setNotification } = useContext(MainAppContext);

  const { data, loading, error } = useQuery<template_by_pk_response>(
    GET_TEMPLATE_BY_PK,
    {
      variables: { id },
      onError(errorResponse) {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
    },
  );

  return { data, loading, error };
};

/**
 * Description - service to get a template by category and sub category id
 */
export const useGetTemplateByCategoryIdAndSubCategoryId = () => {
  const { setNotification } = useContext(MainAppContext);
  const client = useApolloClient();

  const getTemplateByCategoryIdAndSubCategoryId = useCallback(
    (
      category_id: number,
      sub_category_id?: number,
    ): Promise<qat_template_checklist_data> =>
      new Promise(resolve => {
        client
          .query({
            query: GET_TEMPLATE_BY_CATEGORY_ID_AND_SUB_CATEGORY_ID,
            variables: { category_id, sub_category_id },
            fetchPolicy: 'network-only',
          })
          .then(response => {
            resolve(response.data.qat_template_checklist[0]);
          })
          .catch(errorResponse => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      }),
    [client, setNotification],
  );

  return { getTemplateByCategoryIdAndSubCategoryId };
};
