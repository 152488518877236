import React from 'react';
import { useChecklistReportNotConformPresenter } from '../ChecklistReportNotConformPresenter/ChecklistReportNotConformPresenter';
import ChecklistReportView from '../ChecklistReportNotConformView/ChecklistReportNotConformView';
import { audit_data } from '../../../services/Audit/AuditDataModel';
export interface ChecklistReportNotConformComponentProps {
  audit?:audit_data;
}

const ChecklistReportNotConformComponent: React.FC<ChecklistReportNotConformComponentProps> = ({
  audit
}) => {
  const { overviewDetails, isLoading } = useChecklistReportNotConformPresenter(
    audit
  );

  return (
    <ChecklistReportView
      overviewDetails={overviewDetails}
      isLoading={isLoading}
    />
  );
};

export default ChecklistReportNotConformComponent;
