import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import routes from '../../../routes';
import TemplateChecklistReadComponent from '../TemplateChecklistRead/TemplateChecklistReadComponent';

const TemplateChecklistView = () => (
  <Switch>
    <Route path={routes.allTemplate.path}>
      <TemplateChecklistReadComponent onDelete={id => {}} onUpdate={id => {}} />
    </Route>
    <Route path={routes.createTemplate.path}>Create Template Component</Route>
    <Route path={routes.editTemplate.path}>Update Template Component</Route>
    <Route>
      <Redirect to={routes.allTemplate.path} />
    </Route>
  </Switch>
);

export default TemplateChecklistView;
