import { gql } from '@apollo/client';

/**
 * Description - graphql query to create an address
 */
export const UPDATE_ADDRESS = gql`
  mutation update_qat_address_by_pk(
    $id: Int!
    $qat_address_set_input: qat_address_set_input!
  ) {
    update_qat_address_by_pk(
      pk_columns: { id: $id }
      _set: $qat_address_set_input
    ) {
      id
    }
  }
`;
