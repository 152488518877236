import { InMemoryCache, makeVar } from '@apollo/client';
import { ItemDisplayData } from './ItemDisplayData';

export const openCreateItemVar = makeVar(false);
export const openUpdateItemVar = makeVar(false);
export const openItemTableVar = makeVar(false);
export const itemLastIndexVar = makeVar(-1);

export const currentThemeId = makeVar(-1);

export const defaultItem: ItemDisplayData = {
  information_fr: '',
  information_nl: '',
  norm_code: '',
  not_application_option: false,
  page_pdf_fr: '',
  page_pdf_nl: '',
  title_fr: '',
  title_nl: '',
  index: -1,
};

export const itemVar = makeVar(defaultItem);

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        read() {
          return (
            openCreateItemVar(),
            openUpdateItemVar(),
            openItemTableVar(),
            itemLastIndexVar(),
            itemVar()
          );
        },
      },
    },
  },
});
