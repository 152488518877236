import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { READ_ALL_ENTITIES, READ_ALL_ENTITIES_SORTED } from '../../Entity/ReadService/EntityReadQuery';
import { READ_ALL_LOCATIONS_BY_ENTITY_ID } from '../ReadService/LocationReadQuery';
import { DELETE_LOCATION } from './LocationDeleteMutation';

export const useDeleteLocation = (entity_id) => {
  const { setNotification } = useContext(MainAppContext);

  const [deleteLocation] = useMutation(DELETE_LOCATION, {
    refetchQueries: [{
      query: READ_ALL_LOCATIONS_BY_ENTITY_ID,
      variables: { entity_id }
    },
    {query: READ_ALL_ENTITIES},
    {
      query: READ_ALL_ENTITIES_SORTED,
      variables:{order_by:{label: "asc"}}
    }
  ],
    awaitRefetchQueries: true,
    
    onCompleted({ deletedLocation }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const onDelete = async (id: number) => {
    deleteLocation({
      variables: { id: id, deletedDate: new Date() },
    });
  };

  return { onDelete };
};
