import React from 'react';
import { AuditFormViewProps } from '../AuditFormComponent/AuditFormComponent';

import { Switch, Route, Redirect } from 'react-router-dom';
import routes from '../../../../routes';
import BuildingCreateComponent from '../../../Building/BuildingCreate/BuildingCreateComponent/BuildingCreateComponent';
import AddressCreateComponent from '../../../Address/AddressCreate/AddressCreateComponent/AddressCreateComponent';
import BuildingUpdateComponent from '../../../Building/BuildingUpdate/BuildingUpdateComponent';
import AuditFormMainSectionComponent from '../AuditFormMainInfo/AuditFormMainSectionComponent/AuditFormMainSectionComponent';
import AddressUpdateComponent from '../../../Address/AddressCreate/AddressUpdateComponent/AddressUpdateComponent';
import FormContainer from '../../../Shared/Container/FormContainer';

export const testIds = {
  buttonValidate: 'audit-button-validation',
  editBuildingComponent: 'edit-building-component',
};

/**
 * Description - Form to create a audit
 */
const AuditFormView: React.FC<AuditFormViewProps> = ({
  audit,
  validation,
  onSubmit,
  onCreateBuildingClick,
  title,
  submitButtonLabel,
  displayBuildingSection,
  parentPath,
  fromCreateAuditComponent
}) => {
  return (
    <FormContainer>
      <Switch>
        <Route path={`${parentPath}${routes.editAuditMainInfo.path}`}>
          <AuditFormMainSectionComponent
            validation={validation}
            onSubmit={onSubmit}
            onCreateBuildingClick={onCreateBuildingClick}
            title={title}
            submitButtonLabel={submitButtonLabel}
            audit={audit}
            displayBuildingSection={displayBuildingSection}
            parentPath={parentPath}
            fromCreateAuditComponent={fromCreateAuditComponent}
          />
        </Route>
        <Route path={`${parentPath}${routes.createBuilding.path}`}>
          <BuildingCreateComponent parentPath={parentPath} />
        </Route>
        <Route path={`${parentPath}${routes.editBuilding.path}`}>
          <div data-testid={testIds.editBuildingComponent}>
            <BuildingUpdateComponent parentPath={parentPath} />
          </div>
        </Route>
        <Route path={`${parentPath}${routes.createAddress.path}`}>
          <AddressCreateComponent parentPath={parentPath} />
        </Route>
        <Route path={`${parentPath}${routes.editAddress.path}`}>
          <AddressUpdateComponent parentPath={parentPath} />
        </Route>
        <Route exact>
          <Redirect to={`${parentPath}${routes.editAuditMainInfo.path}`} />
        </Route>
      </Switch>
    </FormContainer>
  );
};

export default AuditFormView;
