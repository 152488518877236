import ChecklistThemeSectionView, {
  ChecklistThemeSectionViewProps,
  testIds,
} from './ChecklistThemeSectionView';

import { useStyles } from './ChecklistThemeSectionViewStyle';

export default ChecklistThemeSectionView;

export { testIds, useStyles };

export type { ChecklistThemeSectionViewProps };
