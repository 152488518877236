import {
  Button,
  CircularProgress,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React, { Fragment, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from './BuildingFormStyle';
import { Controller } from 'react-hook-form';
import { BuildingFormViewProps } from '../BuildingFormComponent';
import { translate } from '../../../../services/Translation/TranslationService';
import AddButtonComponent from '../../../Shared/Button/AddButtonComponent';
import routes from '../../../../routes';
import AppContext from '../../../../AppContext';
import EditIcon from '@material-ui/icons/Edit';
import { Link } from 'react-router-dom';
import ConfirmDialog from '../../../Shared/Dialog/ConfirmDialog/ConfirmDialogComponent/ConfirmDialogComponent';

export const testIds = {
  addressIdInput: 'building-address-id-input',
  subCategoryIdInput: 'building-sub-category-id-input',
  buttonValidate: 'building-button-validation',
  confirmDialogSubCategoryChange: 'confirm-dialog-sub-category-change',
  buttonValidateAdd: 'building-button-validate-add',
  cancelUpdateBtn: 'building-button-cancel',
};

/**
 * Description - Form to create building
 */
const BuildingFormView: React.FC<BuildingFormViewProps> = ({
  getValues,
  audit,
  building,
  errors,
  handleSubmit,
  handleValidateAdd,
  onSubmitForm,
  addresses,
  loadingAddress,
  subCategoryOptions,
  formConfig,
  control,
  title,
  submitButtonLabel,
  parentPath,
  onConfirmSubCategoryChange,
  onCloseConfirmSubCategoryChange,
  openConfirmSubCategoryChange,
  fromCreateBuilding
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { language } = useContext(AppContext);

  const categoryLocationFormFieldConfigName = (category_location_form_field_config) => {
    return language.substring(0, 2) === 'fr' ? category_location_form_field_config?.name_fr : category_location_form_field_config?.name_nl
  }

  return (
    <Fragment>
      <h1>{t(title)}</h1>
      <Grid
        container
        direction="column"
        justifyContent="space-evenly"
        alignItems="center"
        spacing={3}
      >
        {addresses && addresses?.length > 0 && (
          <Grid item className={classes.control}>
            <InputLabel id="addressLabel" className={(errors.address_id) ? classes.outlineErrorColor : ''} >{t('address:address')}</InputLabel>
            {loadingAddress ? (
              <CircularProgress />
            ) : (
              <Controller
                as={
                  <Select
                    labelId="addressSelect"
                    id="address_id"
                    fullWidth
                    data-testid={testIds.addressIdInput}
                    error={
                      errors.address_id !== undefined ||
                      errors.address_id === null
                    }
                    native={false}
                    renderValue={id =>
                      addresses &&
                      `
                                                    ${addresses?.find(
                        el => el.id === id,
                      ).street
                      } 
                                                    ${addresses?.find(
                        el => el.id === id,
                      ).house_number
                      } 
                                                    ${addresses?.find(
                        el => el.id === id,
                      ).box_number
                        ? ' / ' + addresses?.find(
                          el => el.id === id,
                        ).box_number
                        : ''
                      } 
                                                    -
                                                    ${addresses?.find(
                        el => el.id === id,
                      ).city
                      }
                                                    `
                    }
                  >
                    <MenuItem>
                      <AddButtonComponent
                        label={t('address:createAddress')}
                        linkPath={{
                          pathname: `${parentPath}${routes.createAddress.path}`,
                          state: {
                            audit,
                            building_id: building?.id ? building.id : undefined,
                          },
                        }}
                      />
                    </MenuItem>
                    {addresses &&
                      addresses?.map(address => {
                        return (
                          <MenuItem key={address.id} value={address.id}>
                            <Grid container className={classes.root}>
                              <Grid item xs={11}>
                                {`${address.street} ${address.house_number} ${address.box_number ? ' / ' + address.box_number : ''
                                  } - ${address.city}`}
                              </Grid>
                              <Grid item xs={1}>
                                <IconButton
                                  className={classes.editAddressButton}
                                  component={Link}
                                  onClick={(
                                    e: React.ChangeEvent<HTMLInputElement>,
                                  ) => e.stopPropagation()}
                                  to={{
                                    pathname: `${parentPath}${routes.editAddress.path}`,
                                    state: {
                                      audit,
                                      building: building
                                        ? building
                                        : getValues(),
                                      address: address,
                                    },
                                  }}
                                >
                                  <EditIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </MenuItem>
                        );
                      })}
                  </Select>
                }
                rules={{ validate: value => value !== '' && value !== undefined }}
                name="address_id"
                control={control}
                defaultValue=""
              />
            )}

            <FormHelperText error>
              {errors.address_id && t('building:addressError')}
            </FormHelperText>
          </Grid>
        )}

        {(!addresses || addresses?.length === 0) && (
          <Grid item className={classes.control}>
            <AddButtonComponent
              label={t('address:createAddress')}
              linkPath={{
                pathname: `${parentPath}${routes.createAddress.path}`,
                state: { audit, building },
              }}
            />
            <FormHelperText error>
              {errors.address_id && t('building:addressError')}
            </FormHelperText>
          </Grid>
        )}

        {subCategoryOptions.length > 0 && (
          <Grid item className={classes.control}>
            <InputLabel id="subCategoryLabel" className={errors.sub_category_location_id ? classes.outlineErrorColor : ''}>
              {t('building:housing')}
            </InputLabel>
            <Controller
              render={({ onBlur, value, name, onChange }) => (
                <Select
                  labelId="subCategorySelect"
                  id="sub_category_location_id"
                  fullWidth
                  data-testid={testIds.subCategoryIdInput}
                  error={
                    errors.sub_category_location_id !== undefined ||
                    errors.sub_category_location_id === null
                  }
                  onChange={onChange}
                  value={value ? value : ''}
                  name={name}
                  onBlur={onBlur}
                >
                  {subCategoryOptions.map(sub_category_location => {
                    return (
                      <MenuItem
                        key={sub_category_location.id}
                        value={sub_category_location.id}
                      >
                        {translate(sub_category_location, language)}
                      </MenuItem>
                    );
                  })}
                </Select>
              )}
              rules={{ validate: value => value !== '' }}
              name="sub_category_location_id"
              control={control}
            />

            <FormHelperText error>
              {errors.sub_category_location_id && t('building:housingError')}
            </FormHelperText>
          </Grid>
        )}

        {formConfig.map(category_location_form_field_config => {
          return (
            <Grid
              item
              className={classes.control}
              key={category_location_form_field_config?.id}
            >
              <InputLabel id="label" className={errors &&
                Object.keys(errors).length > 0 &&
                errors?.category_location_form_field_configs &&
                errors?.category_location_form_field_configs[
                category_location_form_field_config.id
                ] ? classes.outlineErrorColor : ''} >
                {translate(category_location_form_field_config, language)}
              </InputLabel>

              {category_location_form_field_config.is_required && (
                <Controller
                  as={Input}

                  fullWidth
                  control={control}
                  name={
                    'category_location_form_field_configs[' +
                    category_location_form_field_config.id +
                    ']'
                  }
                  type={category_location_form_field_config.form_field_type}
                  rules={{ validate: value => value !== '' }}
                  defaultValue=""
                  error={
                    (errors?.category_location_form_field_configs &&
                      errors?.category_location_form_field_configs[
                      category_location_form_field_config.id
                      ] !== undefined) ||
                    (errors?.category_location_form_field_configs &&
                      errors.category_location_form_field_configs[
                      category_location_form_field_config.id
                      ] === null)
                  }
                />
              )}

              {!category_location_form_field_config.is_required && (
                <Controller
                  as={Input}
                  fullWidth
                  control={control}
                  name={
                    'category_location_form_field_configs[' +
                    category_location_form_field_config.id +
                    ']'
                  }
                  type={category_location_form_field_config.form_field_type}
                  defaultValue=""
                />
              )}

              <FormHelperText error>
                {errors &&
                  Object.keys(errors).length > 0 &&
                  errors?.category_location_form_field_configs &&
                  errors?.category_location_form_field_configs[
                  category_location_form_field_config.id
                  ] && (t('building:formFieldError', {
                    0: categoryLocationFormFieldConfigName(category_location_form_field_config)
                  }))}
              </FormHelperText>
            </Grid>
          );
        })}

        <Grid item className={classes.control}>
          {fromCreateBuilding && (
            <>
              <Button
                href=""
                id="validate"
                data-testid={testIds.buttonValidateAdd}
                className={classes.buttonValidateAdd}
                variant="contained"
                fullWidth
                onClick={handleSubmit((data => handleValidateAdd(data)))}
              >
                {t('building:validateAdd')}
              </Button>
            </>
          )}

          <Button
            href=""
            id="validate"
            data-testid={testIds.buttonValidate}
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleSubmit((data => onSubmitForm(data)))}
          >
            {t(submitButtonLabel)}
          </Button>
        </Grid>
      </Grid>
      <ConfirmDialog
        dataTestId={testIds.confirmDialogSubCategoryChange}
        open={openConfirmSubCategoryChange}
        onConfirm={onConfirmSubCategoryChange}
        onClose={onCloseConfirmSubCategoryChange}
        title={t('building:buildingConfirmSubCategoryChangeTitle')}
        text={t('building:buildingConfirmSubCategoryChangeText')}
      />
    </Fragment>
  );
};

export default BuildingFormView;
