import { makeStyles, Paper } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    padding: theme.spacing(4),
  },
}));

/**
 * Description - Container to contain form
 */
const FormContainer = ({ children }) => {
  const classes = useStyles();

  return <Paper className={classes.root}>{children}</Paper>;
};

export default FormContainer;
