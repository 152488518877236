import { useMutation } from '@apollo/client';
import { useContext } from 'react';
import MainAppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import { READ_ALL_ENTITIES } from '../ReadService/EntityReadQuery';
import { DELETE_ENTITY } from './EntityDeleteMutation';

export const useDeleteEntity = () => {
  const { setNotification } = useContext(MainAppContext);

  const [deleteEntity] = useMutation(DELETE_ENTITY, {
    refetchQueries: [{ query: READ_ALL_ENTITIES }],
    awaitRefetchQueries: true,
    onCompleted({ deletedEntity }) {
      setNotification({
        key: 'common:savedSuccess',
        type: NotificationType.SUCCESS,
      });
    },
    onError(error) {
      setNotification({
        key: 'common:generalError',
        type: NotificationType.ERROR,
      });
    },
  });

  const myDelete = async (id: number) => {
    deleteEntity({
      variables: { id: id, deletedDate: new Date() },
    });
  };

  return { myDelete };
};
