export const item = {
  normeCode: 'Norm code',
  notApplicable: 'Optie n.v.t',
  pagePdf: 'Pagina PDF',
  information: 'Informatie',
  title: 'Titel',
  listOfItems: 'Item lijst',
  addItem: 'Item toevoegen',
  item: 'Item',
  createItem: 'Item toevoegen',
  updateItem: 'Bewerk een item',
  theme_name: 'Thema',
  title_fr: 'Franse titel',
  title_nl: 'Nederlandse titel',
  norm_code: 'Standaardcode',
  page_pdf_fr: 'Franse Standaardpagina in pdf',
  page_pdf_nl: 'Nederlandse Standaardpagina in pdf',
  information_fr: 'Informatie in het Frans',
  information_nl: 'Informatie in het Nederlands',
  not_application_option:
    'Voeg een knop toe om aan te geven dat het item mogelijk niet van toepassing is',
  titleFrError: 'De titel in het Frans is een verplicht veld',
  titleNlError: 'De titel in het Nederlands is een verplicht veld',
  normCodeError: 'De standaardcode is een verplicht veld',
  pagePdfFrError: 'Het Franse pdf-paginanummer is een verplicht veld',
  pagePdfNlError: 'Nederlands pdf-paginanummer is een verplicht veld',
  itemTableTitle: 'Liste des Items du thème " {{0}}" de la CheckList "{{1}}"',
  itemTableTitleWithSubtheme:
    'Liste des Items du sous-thème " {{0}}" du thème "{{1}}" de la CheckList "{{2}}"',
  validateAdd: 'Een ander item toevoegen',
  remark: 'Opmerking',
  titleFrUniqueError: "Er is al een item met deze Franse titel",
  titleNlUniqueError: "Er is al een item met deze Nederlandse titel",
  informationFrUniqueError: "Er is al een item met deze Franse informatie",
  informationNlUniqueError: "Er is al een item met deze Nederlandse informatie" 
};
