import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useReactiveVar } from '@apollo/client';
import AuthorizationComponent from '../Authorization/AuthorizationComponent/AuthorizationComponent';
import { Rule } from '../Authorization/AuthorizationComponent/Permissions';
import ConfirmDialog from '../Shared/Dialog/ConfirmDialog/ConfirmDialogComponent/ConfirmDialogComponent';
import AddButtonComponent from '../Shared/Button/AddButtonComponent';
import SearchField from '../Shared/Field/SearchField/SearchField';
import { LinearProgress, Typography } from '@material-ui/core';
import AppContext from '../../AppContext';
import PageContainer from '../Shared/Container/PageContainer';
import { useStyles } from '../Shared/Styles/CommonFormSyles';
import { useThemeDeleteService } from '../../services/Theme/ThemeDeleteService';
import {
  useGetThemeByIdService,
  /* useGetThemeFromTemplateChecklistWithSameCategoryLocation, */
} from '../../services/Theme/ThemeReadService/ThemeReadService';
import ThemeTableComponent from './ThemeTableComponent';
import ThemeCreateDialogComponent from './ThemeDialogComponent/ThemeCreateDialogComponent';
import ThemeUpdateDialogComponent from './ThemeDialogComponent/ThemeUpdateDialogComponent';
import {
  lastThemeIndexVar,
  openCreateThemeVar,
  openUpdateThemeVar,
  themeVar,
} from './ThemeCache';
import { theme_template_checklist_data_from_list } from '../../services/Theme/ThemeReadService';
import {
  /* useCreateThemesService, */
  useUpdateThemesIndex,
} from '../../services/Theme/ThemeWriteService/ThemeWriteService';
import { useBrowserLocationState } from '../Shared/BrowserLocationState/BrowserLocationState';

export const mainTestIds = {
  buttonOnCreate: 'button-create-theme',
  dialogConfirmDelete: 'dialog-confirm-delete-theme',
  searchFieldComponent: 'search-field-component',
  paginationComponent: 'subTheme-pagination-component',
};

const ThemeComponentDialog = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { email } = useContext(AppContext);

  const currentLocationState = useBrowserLocationState<{template: any, categoryLocationId: number, subCategoryLocationId?: number}>({keysRequired:["template", "categoryLocationId"]})

  const currentTemplate = useMemo(
    () =>
      currentLocationState && currentLocationState.template
        ? currentLocationState.template
        : undefined,
    [currentLocationState],
  );

 /*  const categoryLocationId = useMemo(
    () => currentLocationState && currentLocationState.categoryLocationId,
    [currentLocationState],
  );
  const subCategoryLocationId = useMemo(
    () => currentLocationState && currentLocationState.subCategoryLocationId,
    [currentLocationState],
  ); */

  const isOpenCreateTheme = useReactiveVar(openCreateThemeVar);
  const isOpenUpdateTheme = useReactiveVar(openUpdateThemeVar);

  const { data, loading } = useGetThemeByIdService({
    templateChecklistId: currentTemplate ? currentTemplate.id : -1,
  });

  const [themes, setThemes] = useState<
    theme_template_checklist_data_from_list[]
  >([]);
  const [loadingTheme, setIsLoadingTheme] = useState<boolean>();

  const [listThemesFiltered, setListThemesFiltered] = useState<
    Array<theme_template_checklist_data_from_list>
  >(themes);

  const [
    openDeleteConfirmDialog,
    setOpenDeleteConfirmDialog,
  ] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(-1);

  const { onDelete } = useThemeDeleteService({
    templateChecklistId: currentTemplate ? currentTemplate.id : -1,
  });

  const { onUpdateThemesIndex } = useUpdateThemesIndex({
    template_checklist_id: currentTemplate ? currentTemplate.id : -1,
  });
 /*  const {
    getThemeFromTemplateChecklist,
  } = useGetThemeFromTemplateChecklistWithSameCategoryLocation();

  const { onAddThemesAndReOrderThemes } = useCreateThemesService({
    templateChecklistId: currentTemplate?.id,
  }); */

  const context = useContext(AppContext);

  useEffect(() => {
    // setTheme from Template
    if (data && data.length > 0 && !loading) {
      setThemes(data);
      setIsLoadingTheme(loading);
    }
  }, [data, loading]);

  const handleCreateTheme = () => {
    openCreateThemeVar(!isOpenCreateTheme);
    lastThemeIndexVar(themes.length + 1);
  };

  const handleDeleteClicked = (
    row: theme_template_checklist_data_from_list,
  ) => {
    if (row.id) {
      setOpenDeleteConfirmDialog(true);
      setDeleteId(row.id);
    }
  };

  const openUpdateDialog = (row: theme_template_checklist_data_from_list) => {
    if (row.id && row.template_checklist_id) {
      openUpdateThemeVar(!isOpenUpdateTheme);
      themeVar({
        ...themeVar(),
        id: row.id,
        long_name_fr: row.long_name_fr,
        long_name_nl: row.long_name_nl,
        short_name_fr: row.short_name_fr,
        short_name_nl: row.short_name_nl,
        duplicable: row.duplicable,
        not_applicable_option: row.not_applicable_option,
        index: row.index,
      });
    }
  };

  const onConfirmedToDelete = useCallback(() => {
    if (deleteId !== -1) {
      onDelete(deleteId);
      setOpenDeleteConfirmDialog(false);
      setDeleteId(-1);
    } else {
      throw new Error("the subtheme id to delete doesn't exist");
    }
  }, [deleteId, onDelete]);

  const handleDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }
      const themeToUpdate = themes.find(
        ({ id }) => parseInt(result?.draggableId) === id,
      );

      if (themeToUpdate) {
        const { id } = themeToUpdate;
        const newIndex =
          themes.length === 1
            ? result.source.index
            : themes[result.destination.index].index;

        onUpdateThemesIndex({ id, index: newIndex });
      } else {
        console.warn("draggableId doesn't exist in theme list");
      }
    },
    [themes, onUpdateThemesIndex],
  );

  const handleSearch = useCallback(
    (filter: string) => {
      const newFilter = filter.toLowerCase();

      if (newFilter && newFilter !== '') {
        let lang = context?.language.substring(0, 2);
        setListThemesFiltered(() =>
          themes.filter(theme => {
            if (lang === 'fr')
              return (
                theme?.index.toString().includes(newFilter) ||
                theme.long_name_fr.toLowerCase().includes(newFilter) ||
                theme.short_name_fr.toLowerCase().includes(newFilter) ||
                theme.duplicable.toString().includes(newFilter) ||
                theme.not_applicable_option.toString().includes(newFilter)
              );
            if (lang === 'nl')
              return (
                theme?.index.toString().includes(newFilter) ||
                theme.long_name_nl.toLowerCase().includes(newFilter) ||
                theme.short_name_nl.toLowerCase().includes(newFilter) ||
                theme.duplicable.toString().includes(newFilter) ||
                theme.not_applicable_option.toString().includes(newFilter)
              );
            return false;
          }),
        );
      } else setListThemesFiltered(themes);
    },
    [context, themes],
  );

  const { language } = useContext(AppContext);
  const translateTitle = useMemo((): string => {
    const currentTemplateName =
      language.substring(0, 2) === 'fr'
        ? currentTemplate?.name_fr
        : currentTemplate?.name_nl;

    return t('theme:themeTableTitle', {
      0: currentTemplateName,
    });
  }, [currentTemplate, language, t]);

 /*  const duplicateThemes = useCallback(() => {
    getThemeFromTemplateChecklist({
      category_location_id: categoryLocationId ? categoryLocationId : -1,
      sub_category_location_id: subCategoryLocationId,
    }).then(themeData => {
      onAddThemesAndReOrderThemes(themeData, themes);
    });
  }, [
    getThemeFromTemplateChecklist,
    onAddThemesAndReOrderThemes,
    categoryLocationId,
    subCategoryLocationId,
    themes,
  ]);
 */
  return (
    <AuthorizationComponent rules={[Rule.CAN_VIEW_THEME_CRUD]} email={email}>
      {loading ? (
        <LinearProgress />
      ) : (
        <PageContainer>
          <div className={classes.itemTitle}>
            <Typography variant="h6" gutterBottom>
              {translateTitle}
            </Typography>
          </div>
          <div className={classes.tableHeader}>
            <AuthorizationComponent
              rules={[Rule.CAN_VIEW_ITEM_CRUD]}
              message={'readOnly'}
            >
              <AddButtonComponent
                label={t('theme:createTheme')}
                data-testid={mainTestIds.buttonOnCreate}
                onClick={handleCreateTheme}
              />
              {// duplicate theme from another template is disable
              /*subCategoryLocationId && subCategoryLocationId !== -1 ? (
                <AddButtonComponent
                  label="theme:duplicateThemeTemplate"
                  onClick={duplicateThemes}
                />
              ) : null*/}
            </AuthorizationComponent>
            <div className={classes.search}>
              {' '}
              <SearchField onChange={handleSearch} />{' '}
            </div>
          </div>
          <ThemeTableComponent
            isLoading={loadingTheme}
            themes={listThemesFiltered}
            handleDeleteClicked={handleDeleteClicked}
            openUpdateDialog={openUpdateDialog}
            onDragEnd={handleDragEnd}
          />
          <ThemeCreateDialogComponent />
          <ThemeUpdateDialogComponent />

          <ConfirmDialog
            dataTestId={mainTestIds.dialogConfirmDelete}
            open={openDeleteConfirmDialog}
            onClose={() => setOpenDeleteConfirmDialog(false)}
            onConfirm={onConfirmedToDelete}
            title={t('common:delete')}
            text={t('common:confirmDelete')}
          />
        </PageContainer>
      )}
    </AuthorizationComponent>
  );
};

export default ThemeComponentDialog;
