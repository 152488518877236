import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useContext,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllItemRemarksFromItem } from '../../../services/Item/Remark/ReadService/ItemRemarkReadService';
import ItemRemarkUpdateDialog from './ItemRemarkDialogComponent/ItemRemarkUpdateDialogComponent';
import ItemRemarkCreateDialogComponent from './ItemRemarkDialogComponent/ItemRemarkCreateDialogComponent';
import AuthorizationComponent from '../../Authorization/AuthorizationComponent/AuthorizationComponent';
import { Rule } from '../../Authorization/AuthorizationComponent/Permissions';
import ConfirmDialog from '../../Shared/Dialog/ConfirmDialog/ConfirmDialogComponent/ConfirmDialogComponent';
import { useDeleteItemRemark } from '../../../services/Item/Remark/DeleteService/ItemRemarkDeleteService';
import { useUpdateIndexItemRemarkFromItem } from '../../../services/Item/Remark/UpdateService/ItemRemarkUpdateService';
import { ItemRemarkFormData } from '../../../services/Item/Remark/ItemRemarkFormData';
import AddButtonComponent from '../../Shared/Button/AddButtonComponent';
import SearchField from '../../Shared/Field/SearchField/SearchField';
import { LinearProgress, Typography } from '@material-ui/core';
import AppContext from '../../../AppContext';
import { useStyles } from '../../Shared/Styles/CommonFormSyles';
import { ItemData } from '../../../services/Item/ItemDataModel';
import PageContainer from '../../Shared/Container/PageContainer';
import ItemRemarkTable from './ItemRemarkTableComponent';
import {
  itemRemarkVar,
  openCreateItemRemarkVar,
  openUpdateItemRemarkVar,
  remarkLastIndexVar,
} from './ItemRemarkCache';
import { useReactiveVar } from '@apollo/client';
import { useBrowserLocationState } from '../../Shared/BrowserLocationState/BrowserLocationState';

export const mainTestIds = {
  buttonOnCreate: 'button-create-item-remark',
  dialogConfirmDelete: 'dialog-confirm-delete-item-remark',
  searchFieldComponent: 'search-field-component',
  paginationComponent: 'entity-pagination-component',
};

const ItemRemarkComponent = () => {
  const { t } = useTranslation(); 
  const classes = useStyles();
  const { language } = useContext(AppContext);
  const { email } = useContext(AppContext);

  const currentLocationState = useBrowserLocationState<{item: any, currentTheme: any, currentSubTheme?: any, currentTemplate: any}>({keysRequired: ["item", "currentTheme", "currentTemplate"]})

  const currentItem: ItemData = useMemo(
    () =>
      currentLocationState && currentLocationState.item
        ? currentLocationState.item
        : null,
    [currentLocationState],
  );

  const currentItemId = useMemo(() => (currentItem ? currentItem.id : -1), [
    currentItem,
  ]);
  const [itemRemarks, setItemRemarks] = useState<ItemRemarkFormData[]>([]);
  const [loading, setIsLoading] = useState<boolean>();
  const [
    openDeleteConfirmDialog,
    setOpenDeleteConfirmDialog,
  ] = useState<boolean>(false);

  const [deleteId, setDeleteId] = useState<number>(-1);
  const { myDeleteItemRemark } = useDeleteItemRemark(currentItemId);
  const context = useContext(AppContext);
  const [listItemRemarksFiltered, setListItemRemarksFiltered] = useState<
    Array<ItemRemarkFormData>
  >(itemRemarks);

  const isOpenUpdateItemRemark: boolean = useReactiveVar(
    openUpdateItemRemarkVar,
  );

  const {
    data: itemRemarkData,
    loading: itemRemarkLoading,
  } = useGetAllItemRemarksFromItem(currentItemId);

  const translateTitle = useMemo((): string => {
    const currentItemName =
      language.substring(0, 2) === 'fr'
        ? currentLocationState?.item?.title_fr
        : currentLocationState?.item?.title_nl;
    const currentThemeName =
      language.substring(0, 2) === 'fr'
        ? currentLocationState?.currentSubTheme
          ? currentLocationState?.currentSubTheme?.name_fr
          : currentLocationState?.currentTheme?.short_name_fr
        : currentLocationState?.currentSubTheme
        ? currentLocationState?.currentSubTheme?.name_nl
        : currentLocationState?.currentTheme?.short_name_nl;

    const currentTemplateName =
      language.substring(0, 2) === 'fr'
        ? currentLocationState?.currentTemplate?.name_fr
        : currentLocationState?.currentTemplate?.name_nl;
    return t('item_remark:itemRemarkTitle', {
      0: currentItemName,
      1: currentThemeName,
      2: currentTemplateName,
    });
  }, [currentLocationState, language, t]);

  const { onUpdateIndexItemRemark } = useUpdateIndexItemRemarkFromItem({
    template_theme_item_id: currentItemId,
  });

  useEffect(() => {
    //setItemRemark from Item
    if (itemRemarkData && itemRemarkData.length > 0 && !itemRemarkLoading) {
      setItemRemarks(itemRemarkData);
      setIsLoading(itemRemarkLoading);
    } else setItemRemarks([]);
  }, [itemRemarkData, itemRemarkLoading]);

  const handleCreateItemRemark = () => {
    openCreateItemRemarkVar(true);
    remarkLastIndexVar(itemRemarks.length + 1);
  };

  const handleDeleteClicked = (row: ItemRemarkFormData) => {
    if (row.id) {
      setOpenDeleteConfirmDialog(true);
      setDeleteId(row.id);
    }
  };

  const openUpdateDialog = (row: ItemRemarkFormData) => {
    if (row.id) {
      openUpdateItemRemarkVar(!isOpenUpdateItemRemark);
      itemRemarkVar({
        ...itemRemarkVar(),
        id: row.id,
        remark_text_fr: row.remark_text_fr,
        remark_text_nl: row.remark_text_nl,
        template_theme_item_id: row.template_theme_item_id,
        index: row.index,
      });
    }
  };

  const onConfirmedToDelete = useCallback(() => {
    if (deleteId !== -1) {
      myDeleteItemRemark(deleteId);
      setOpenDeleteConfirmDialog(false);
      setDeleteId(-1);
    } else {
      throw new Error("the remark id to delete doesn't exist");
    }
  }, [deleteId, myDeleteItemRemark]);

  const handleDragEnd = useCallback(
    result => {
      if (!result.destination) {
        return;
      }
      const itemRemarkToUpdate = itemRemarks.find(
        ({ id }) => parseInt(result?.draggableId) === id,
      );
      if (itemRemarkToUpdate && itemRemarkToUpdate.id !== undefined) {
        const { id } = itemRemarkToUpdate;
        const newIndex: number =
          itemRemarks.length === 1
            ? result.source.index
            : itemRemarks[result.destination.index].index;
        onUpdateIndexItemRemark({ id, index: newIndex });
      } else {
        console.warn("draggableId doesn't exist in item list");
      }
    },
    [onUpdateIndexItemRemark, itemRemarks],
  );

  const handleSearch = useCallback(
    (filter: string) => {
      const newFilter = filter.toLowerCase();

      if (newFilter && newFilter !== '') {
        let lang = context?.language.substring(0, 2);
        setListItemRemarksFiltered(() =>
          itemRemarks.filter(itemRemark => {
            if (lang === 'fr')
              return (
                itemRemark.index.toString().includes(newFilter) ||
                itemRemark.remark_text_fr.toLowerCase().includes(newFilter) ||
                itemRemark.remark_text_nl.toLowerCase().includes(newFilter)
              );
            if (lang === 'nl')
              return (
                itemRemark.index.toString().includes(newFilter) ||
                itemRemark.remark_text_fr.toLowerCase().includes(newFilter) ||
                itemRemark.remark_text_nl.toLowerCase().includes(newFilter)
              );
            else return true;
          }),
        );
      } else setListItemRemarksFiltered(itemRemarks);
    },
    [context, itemRemarks],
  );

  return (
    <AuthorizationComponent
      rules={[Rule.CAN_VIEW_ITEM_REMARK_CRUD]}
      email={email}
    >
      {loading ? (
        <LinearProgress />
      ) : (
        <PageContainer>
          <div className={classes.itemTitle}>
            <Typography variant="h6" gutterBottom>
              {translateTitle}
            </Typography>
          </div>
          <div className={classes.tableHeader}>
            <AuthorizationComponent
              rules={[Rule.CAN_VIEW_ITEM_CRUD]}
              message={'readOnly'}
            >
              <AddButtonComponent
                label={t('item_remark:addItemRemark')}
                data-testid={mainTestIds.buttonOnCreate}
                onClick={handleCreateItemRemark}
              />
            </AuthorizationComponent>
            <div className={classes.search}>
              {' '}
              <SearchField onChange={handleSearch} />{' '}
            </div>
          </div>

          <ItemRemarkTable
            isLoading={loading}
            itemRemarks={listItemRemarksFiltered}
            handleDeleteClicked={handleDeleteClicked}
            openUpdateDialog={openUpdateDialog}
            onDragEnd={handleDragEnd}
          />
          <ItemRemarkCreateDialogComponent />
          <ItemRemarkUpdateDialog />
          <ConfirmDialog
            dataTestId={mainTestIds.dialogConfirmDelete}
            open={openDeleteConfirmDialog}
            onClose={() => setOpenDeleteConfirmDialog(false)}
            onConfirm={onConfirmedToDelete}
            title={t('common:delete')}
            text={t('common:confirmDelete')}
          />
        </PageContainer>
      )}
      ;
    </AuthorizationComponent>
  );
};

export default ItemRemarkComponent;
