import { ApolloError, useApolloClient, useQuery } from '@apollo/client';
import { useCallback, useContext, useMemo, useState } from 'react';
import MainAppContext from '../../../AppContext';
import { NotificationType } from '../../../components/Notification/NotificationComponent';
import {
  get_all_theme_include_soft_delete_in_template_data,
  get_all_theme_include_soft_delete_in_template_response,
  get_all_theme_include_soft_delete_in_template_variables,
  READ_THEME_TEMPLATE_CHECKLIST_BY_TEMPLATE_ID,
  theme_template_checklist_data_from_list,
  theme_template_checklist_data_list,
} from '.';
import {
  GET_ALL_THEME_INCLUDE_SOFT_DELETE_IN_TEMPLATE,
  GET_THEME_FROM_TEMPLATE_CHECKLIST_WITH_SAME_CATEGORY_LOCATION,
} from './ThemeReadQuery';
import {
  theme_template_from_template_with_same_category_service_result,
  theme_template_from_template_with_same_category_variable,
} from './ThemeReadModel';
import { ThemeFormData } from '../../../components/Theme/ThemeFormModel';
import { useTranslation } from 'react-i18next';

/**
 * Description - service to get all theme template checklist by template checklist id
 */
export const useGetThemeByIdService = ({ templateChecklistId }) => {
  const { setNotification } = useContext(MainAppContext);

  const { data, loading, error } = useQuery<theme_template_checklist_data_list>(
    READ_THEME_TEMPLATE_CHECKLIST_BY_TEMPLATE_ID,
    {
      variables: { templateChecklistId },
      fetchPolicy: 'network-only',
      onError(errorResponse) {
        setNotification({
          key: 'common:generalError',
          type: NotificationType.ERROR,
        });
      },
      skip: templateChecklistId === -1,
    },
  );

  const themes: theme_template_checklist_data_from_list[] = useMemo(() => {
    return !loading && data?.qat_theme_template_checklist
      ? data?.qat_theme_template_checklist
      : [];
  }, [data, loading]);

  return { data: themes, loading, error };
};

export interface ThemeUniqueResponse {
  short_name_fr?: string;
  short_name_nl?: string;
  long_name_fr?: string;
  long_name_nl?: string;
}
export const useCheckThemeUnique = () => {
  const {getAllThemeIncludeSoftDeleteByTemplate} = useGetAllThemeIncludeSoftDeleteByTemplate()
  const {t} = useTranslation()
  const onCheckThemeUnique = useCallback((data: ThemeFormData, {template_checklist_id}: {template_checklist_id: number}) => {
    return new Promise<ThemeUniqueResponse | void>(resolve => {
      getAllThemeIncludeSoftDeleteByTemplate({
        template_checklist_id
      }).then(themes => {
        const doubleShortNameFr = 
        !data.id
        ? themes.find(theme => theme.short_name_fr === data.short_name_fr && theme.deleted_date === null)
        : themes.find(theme => theme.short_name_fr === data.short_name_fr && theme.id !== data.id && theme.deleted_date === null)
       
        const doubleShortNameNl = 
        !data.id
        ? themes.find(theme => theme.short_name_nl === data.short_name_nl && theme.deleted_date === null)
        : themes.find(theme => theme.short_name_nl === data.short_name_nl && theme.id !== data.id && theme.deleted_date === null)
       
        const doubleLongNameFr = 
        !data.id
        ? themes.find(theme => theme.long_name_fr === data.long_name_fr && theme.deleted_date === null)
        : themes.find(theme => theme.long_name_fr === data.long_name_fr && theme.id !== data.id && theme.deleted_date === null)
       
        const doubleLongNameNl = 
        !data.id
        ? themes.find(theme => theme.long_name_nl === data.long_name_nl && theme.deleted_date === null)
        : themes.find(theme => theme.long_name_nl === data.long_name_nl && theme.id !== data.id && theme.deleted_date === null)
       
        const errorMessage: ThemeUniqueResponse = {}
  
        if(doubleShortNameFr){
          errorMessage.short_name_fr = t( "theme:shortNameFrUniqueError")
        }
  
        if(doubleShortNameNl){
          errorMessage.short_name_nl = t( "theme:shortNameNlUniqueError")
        }
  
        if(doubleLongNameFr){
          errorMessage.long_name_fr = t( "theme:longNameFrUniqueError")
        }
  
        if(doubleLongNameNl){
          errorMessage.long_name_nl = t( "theme:longNameNlUniqueError")
        }
  
        if(Object.keys(errorMessage).length){
          resolve(errorMessage)
        } else {
          resolve()
        }
      })
    })
  },[getAllThemeIncludeSoftDeleteByTemplate, t])

  return {onCheckThemeUnique}
}

export const useGetAllThemeIncludeSoftDeleteByTemplate = () => {
  const { setNotification } = useContext(MainAppContext);
  const client = useApolloClient();

  const getAllThemeIncludeSoftDeleteByTemplate = useCallback(
    ({
      template_checklist_id,
    }: get_all_theme_include_soft_delete_in_template_variables) => {
      return new Promise<get_all_theme_include_soft_delete_in_template_data[]>(
        resolve => {
          client
            .query<
              get_all_theme_include_soft_delete_in_template_response,
              get_all_theme_include_soft_delete_in_template_variables
            >({
              query: GET_ALL_THEME_INCLUDE_SOFT_DELETE_IN_TEMPLATE,
              variables: { template_checklist_id },
              fetchPolicy: 'network-only',
            })
            .then(response => {
              resolve(response.data.qat_theme_template_checklist);
            })
            .catch(() => {
              setNotification({
                key: 'common:generalError',
                type: NotificationType.ERROR,
              });
            });
        },
      );
    },
    [client, setNotification],
  );

  return { getAllThemeIncludeSoftDeleteByTemplate };
};

/**
 * Service to fetch template themes with same category for duplication
 */
export const useGetThemeFromTemplateChecklistWithSameCategoryLocation = (): theme_template_from_template_with_same_category_service_result => {
  const { setNotification } = useContext(MainAppContext);
  const client = useApolloClient();
  const [errorQuery, setErrorQuery] = useState<ApolloError | undefined>();

  const getThemeFromTemplateChecklist = useCallback(
    ({
      category_location_id,
      sub_category_location_id,
    }: theme_template_from_template_with_same_category_variable): Promise<
      theme_template_checklist_data_from_list[]
    > =>
      new Promise(resolve => {
        client
          .query({
            query: GET_THEME_FROM_TEMPLATE_CHECKLIST_WITH_SAME_CATEGORY_LOCATION,
            variables: { category_location_id, sub_category_location_id },
          })
          .then(({ data, error }) => {
            if (error) {
              setNotification({
                key: 'generalError',
                type: NotificationType.ERROR,
              });
              setErrorQuery(error);
            }
            resolve(data.qat_theme_template_checklist);
          }).catch(error => {
            setNotification({
              key: 'common:generalError',
              type: NotificationType.ERROR,
            });
          });
      }),
    [client, setNotification],
  );

  return { getThemeFromTemplateChecklist, error: errorQuery };
};
