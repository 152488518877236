import { ApolloError } from '@apollo/client';
import { useMemo } from 'react';
import { audit_data } from '../../../../../services/Audit/AuditDataModel';

import { useGetCurrentAudit } from '../../../../../services/Audit/ReadService/AuditReadService';

export interface AuditReadCurrentPresenterResult {
  currentAudits: Array<audit_data>;
  loading: boolean;
  error: ApolloError | undefined;
}

/**
 * Description - Presenter of AuditReadComponent
 */
export const useAuditReadCurrentPresenter = (): AuditReadCurrentPresenterResult => {
  const { data, loading, error } = useGetCurrentAudit();
  const currentAudits = useMemo(
    () => (!error && !loading && data?.qat_audit ? data?.qat_audit : []),
    [data, loading, error],
  );

  return { currentAudits, loading, error };
};
