import { gql } from '@apollo/client';

/**
 * Description - graphql query to create an entity
 */
export const UPDATE_ENTITY = gql`
  mutation update_qat_entity_by_pk(
    $id: Int!
    $qat_entity_set_input: qat_entity_set_input!
  ) {
    update_qat_entity_by_pk(
      pk_columns: { id: $id }
      _set: $qat_entity_set_input
    ) {
      id
      label
      abbreviation
    }
  }
`;
