import React from 'react';
import { audit_data } from '../../../../../services/Audit/AuditDataModel';
import { useAuditFormMainInfoPresenter } from '../AuditFormMainInfoPresenter';
import AuditFormMainInfoView from '../AuditFormMainInfoView';

export interface AuditFormMainInfoComponentProps {
  audit?: audit_data;
  isReadOnlyMode?: boolean;
}
/**
 * Description - form to insert basic information of audit
 */
const AuditFormMainInfoComponent: React.FC<AuditFormMainInfoComponentProps> = ({
  audit,
  isReadOnlyMode,
}) => {
  const props = useAuditFormMainInfoPresenter({ audit, isReadOnlyMode });
  return <AuditFormMainInfoView {...props} />;
};

export default AuditFormMainInfoComponent;
