import { useEffect, useMemo, useState } from 'react';
import { useGetItemsOverviewByAuditId } from '../../../services/NotConformOverview/NotConformItemService';
import { useHistory } from 'react-router-dom';
import routes from '../../../routes';
import _ from 'lodash';
import { sortAlphaNum } from '../../Shared/utils/order.utils';

export const useChecklistReportNotConformPresenter = (receivedAudit) => {
  const audit = useMemo(() => {
    if (receivedAudit){
      return receivedAudit
    } else {
      console.error("the auditIt is required")
      return ""
    }
  }, [receivedAudit])
  const history = useHistory();
  const [overviewDetails, setOverviewDetails] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { getItemsOverviewByAuditId } = useGetItemsOverviewByAuditId(audit?audit.id:-1);

  useEffect(() => {
    if (!audit) history.push({ pathname: routes.currentAudits.path });
  }, [audit, history]);

  useEffect(() => {
    let _overviewDetails: any = {};

    getItemsOverviewByAuditId().then(async _response => {
      let sectionItems = _response?.qat_checklist_theme_item;
      let subSectionItems = _response?.sub_section_items;
      setIsLoading(true);
      _overviewDetails.audit =
        sectionItems[0]?.checklist_theme_section?.checklist_theme?.checklist
          ?.audit ||
        subSectionItems[0]?.checklist_theme_sub_section?.checklist_theme_section
          ?.checklist_theme?.checklist?.audit || audit;
      _overviewDetails.items = sectionItems?.concat(subSectionItems).sort((a,b) => sortAlphaNum(a,b, "norm_code"))
      setOverviewDetails(_overviewDetails);
      mapBuildings(_overviewDetails).then(_overviewDetailsVar => {
        setOverviewDetails(_overviewDetailsVar);
        setIsLoading(false);
      });
    });
    // eslint-disable-next-line
  }, [audit]);

  const mapBuildings = _overviewDetails => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const groupByItems = _.values(
          _.groupBy(_overviewDetails?.items, 'name_nl'),
        );
        _overviewDetails.items = groupByItems?.map(group_items => {
          const groupByBuildings = _.values(
            _.groupBy(
              group_items,
              group_item =>
                group_item?.checklist_theme_section?.checklist_theme?.checklist
                  ?.building?.id ||
                group_item?.checklist_theme_sub_section?.checklist_theme_section
                  ?.checklist_theme?.checklist?.building?.id,
            ),
          );
          return groupByBuildings;
        });

        //TODO: waiting for aws/cognito configuration by Martin
        // _overviewDetails.items?.map((_item => {
        //   return _item?.map((_item => {
        //     return _item?.map((_item => {
        //       let signedUrl
        //       onGetSignedUrl(language).then(result => signedUrl = result)
        //       return { ..._item, normes_pdf_url: signedUrl }
        //     }))
        //   }))
        // }))

        resolve(_overviewDetails);
      });
    });
  };

  return { overviewDetails, isLoading };
};
