import React, { useMemo } from 'react';
import {
  get_audit_report_interview_by_audit_id_item_data,
  get_audit_report_interview_by_audit_id_section_data,
} from '../../../services/Audit/ReadService/AuditReadModel';
import { checklist_data } from '../../../services/Checklist/ChecklistReadService';
import ChecklistReportItemComponent from '../../ChecklistReportItem/ChecklistReportIItemComponent';

export interface ChecklistReportSectionComponentProps {
  audit_id: number;
  checklist_theme_section: get_audit_report_interview_by_audit_id_section_data;
  checklist: checklist_data;
  isReadOnlyMode?: boolean
}

const ChecklistReportSectionComponent = ({
  audit_id,
  checklist_theme_section,
  checklist,
  isReadOnlyMode
}) => {
  const checklist_theme_items_view: get_audit_report_interview_by_audit_id_item_data[] = useMemo(() => {
    const itemsStream: get_audit_report_interview_by_audit_id_item_data[] = [];
    checklist_theme_section.checklist_theme_items.forEach(item => {
      itemsStream.push(item);
    });
    checklist_theme_section.checklist_theme_sub_sections.forEach(
      sub_section => {
        sub_section.checklist_theme_items.forEach(item => {
          itemsStream.push(item);
        });
      },
    );
    return itemsStream;
  }, [checklist_theme_section]);

  return (
    <>
      {checklist_theme_items_view.map((checklist_theme_item, index) => (
        <ChecklistReportItemComponent
          audit_id={audit_id}
          checklist_theme_item={checklist_theme_item}
          key={checklist_theme_item?.id}
          index={index}
          checklist={checklist}
          isReadOnlyMode={isReadOnlyMode}
        />
      ))}
    </>
  );
};

export default ChecklistReportSectionComponent;
