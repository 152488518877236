import React from "react"
import { useGetContentService } from "../../../../services/ChecklistDocument/ChecklistDocumentRead/ChecklistDocumentRead"
import { ChecklistDocumentUploadFormInputs } from "../ChecklistDocumentUpload/ChecklistDocumentUploadService"
import { useStyles } from './ChecklistDocumentUploadPreviewStyle'


export interface ChecklistDocumentUploadPreviewProps {
    url: string | null
    values: ChecklistDocumentUploadFormInputs
}
export const testIds = {
    documentContent: "checklist_document_upload_preview_document_content"
}
const ChecklistDocumentUploadPreview = ({ url, values }: ChecklistDocumentUploadPreviewProps) => {
    const classes = useStyles()

    const { content } = useGetContentService({ url })

    let imgPreview;
    if (values?.file) {
        imgPreview = URL.createObjectURL(new Blob([values.file], { type: "application/pdf" }))
    }

    return (
         <div> 
            {
                (content || imgPreview) &&
                <div
                    className={classes.contentContainer}
                    data-testid={testIds.documentContent}
                >
                    {content && imgPreview && <iframe
                        width={1500}
                        height={900}
                        title="checklist_document_preview"
                        src={imgPreview}

                    />}
                    {content && !imgPreview && <iframe
                        width={1500}
                        height={900}
                        title="checklist_document_preview"
                        src={content ? content : ""}

                    />}
                    {imgPreview && !content && <iframe
                        width={1500}
                        height={900}
                        title="checklist_document_preview"
                        src={imgPreview}

                    />}
                </div>
             }
        </div> 
    )

}

export default ChecklistDocumentUploadPreview