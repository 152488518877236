import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import routes from '../../../routes';
import clsx from 'clsx';
import MainApp from '../../MainApp/MainAppComponent';
import { useStyles } from '../NavigationStyle';
import { isMobile } from 'react-device-detect';
import { Rule } from '../../Authorization/AuthorizationComponent/Permissions';
import DrawerComponent from '../Drawer/DrawerComponent/DrawerComponent';
import AppBarComponent from '../AppBar/AppBarComponent/AppBarComponent';

export const MENU_ITEMS: Array<MenuItem> = [
  {
    id: 'audit:audit',
    rules: [Rule.CAN_VIEW_AUDIT_READ],
    isAllowed: false,
    childs: [
      {
        id: 'audit:listAudit',
        link: routes.listAudit.path,
        rules: [Rule.CAN_VIEW_AUDIT_READ],
        isAllowed: false,
      },
      {
        id: 'audit:currentAudit',
        link: routes.currentAudits.path,
        rules: [Rule.CAN_VIEW_AUDIT_READ],
        isAllowed: false,
      },
      {
        id: 'audit:createAudit',
        link: `${routes.createAudit.path}${routes.editAuditMainInfo.path}`,
        rules: [Rule.CAN_VIEW_AUDIT_CRUD],
        isAllowed: false,
      },
    ],
  },
  {
    id: 'common:management',
    childs: [
      {
        id: 'entity:entity',
        link: routes.allEntities.path,
        rules: [Rule.CAN_VIEW_ENTITY_CRUD],
        isAllowed: false,
      },
      {
        id: 'template:template',
        link: routes.allTemplate.path,
        rules: [Rule.CAN_VIEW_TEMPLATE_CRUD],
        isAllowed: false,
      },
      {
        id: routes.uploadDocument.id,
        link: routes.uploadDocument.path,
        rules:[Rule.CAN_VIEW_UPLOAD_ADMIN_DOCUMENT],
        isAllowed: false
      }
    ],
    rules: [
      Rule.CAN_VIEW_ENTITY_CRUD,
      Rule.CAN_VIEW_TEMPLATE_CRUD,
      Rule.CAN_VIEW_THEME_CRUD,
      Rule.CAN_VIEW_ITEM_CRUD,
    ],
    isAllowed: false,
  },
  {
    id: 'normes:normes',
    link: routes.normesMinimal.path,
    rules: [Rule.CAN_VIEW_NORME],
    isAllowed: false,
  },
  /*{
    id: 'profile:profile',
    childs: [
      {
        id: routes.changePassword.id,
        link: routes.changePassword.path,
        rules: [Rule.CAN_VIEW_PROFILE],
        isAllowed: false,
      },
    ],
    rules: [Rule.CAN_VIEW_PROFILE],
    isAllowed: false,
  },*/
];

export interface MenuItem {
  id: string;
  link?: string;
  open?: boolean;
  active?: boolean;
  childs?: MenuItem[];
  rules: string[];
  isAllowed: boolean;
}

export interface NavigationProps {
  classes: any;
  navigationService: any;
}

/**
 * This component is responsible for the navigation and is used as a wrapper class for all navigation components
 */
function NavigationComponent() {
  const classes = useStyles();
  const [openInMobile, setOpenInMobile] = React.useState(!isMobile);

  const handleDrawerOpen = () => {
    setOpenInMobile(true);
  };

  const handleDrawerClose = () => {
    setOpenInMobile(false);
  };

  const open = isMobile ? openInMobile : true;

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBarComponent open={open} setOpen={handleDrawerOpen} />
      <DrawerComponent
        onClosed={handleDrawerClose}
        open={open}
        menuItems={MENU_ITEMS}
      />
      <main
        onClick={handleDrawerClose}
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <div className={classes.drawerHeader} />
        <MainApp />
      </main>
    </div>
  );
}

export default NavigationComponent;
