import TemplateChecklistReadView, {
  TemplateChecklistReadViewProps,
  testIds,
} from './TemplateChecklistReadView';
import { useStyles } from './TemplateChecklistReadViewStyle';

export default TemplateChecklistReadView;

export { testIds, useStyles };

export type { TemplateChecklistReadViewProps };
