import React, { useMemo, useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import {  useHistory } from 'react-router-dom';
import ItemRemarkFormDialog from './ItemRemarkFormDialogComponent';
import { itemRemarkVar, openCreateItemRemarkVar } from '../ItemRemarkCache';
import { useCreateItemRemark } from '../../../../services/Item/Remark/WriteService/ItemRemarkWriteService';
import { ItemRemarkFormData } from '../../../../services/Item/Remark/ItemRemarkFormData';
import routes from '../../../../routes';
import { useGetAllItemRemarksFromItem } from '../../../../services/Item/Remark/ReadService/ItemRemarkReadService';
import { useBrowserLocationState } from '../../../Shared/BrowserLocationState/BrowserLocationState';

export interface RemarkFormProps {
  isOpenCreate: boolean;
  onIsCreateChange: any;
}
const ItemRemarkCreateDialogComponent: React.FC = () => {
  const history = useHistory();
  const newItemRemark: ItemRemarkFormData = useReactiveVar(itemRemarkVar);
  const isOpenCreateForm: boolean = useReactiveVar(openCreateItemRemarkVar);

  const currentLocationState = useBrowserLocationState<{item: any, currentTheme: any, currentSubTheme?: any, currentTemplate: any}>({keysRequired: ["item", "currentTheme", "currentTemplate"]})

  const currentTemplate = useMemo(
    () =>
      currentLocationState?.currentTemplate
        ? currentLocationState?.currentTemplate
        : undefined,
    [currentLocationState],
  );
  const currentTheme = useMemo(
    () =>
      currentLocationState?.currentTheme ? currentLocationState?.currentTheme : undefined,
    [currentLocationState],
  );
  const currentSubTheme = useMemo(
    () =>
      currentLocationState?.currentSubTheme
        ? currentLocationState?.currentSubTheme
        : undefined,
    [currentLocationState],
  );
  const item = currentLocationState?.item ? currentLocationState?.item : undefined;
  let currentItemId: number = currentLocationState?.item
    ? currentLocationState?.item?.id
    : -1;
  const {
    data
  } = useGetAllItemRemarksFromItem(currentItemId? currentItemId : -1);

  const { onCreateItemRemark } = useCreateItemRemark(currentItemId);

  const defaultItemRemark: ItemRemarkFormData = {
    id: -1,
    remark_text_fr: '',
    remark_text_nl: '',
    template_theme_item_id: -1,
    index: -1,
  };

  function itemRemarkFormValue() {
    let itemRemarkForm: ItemRemarkFormData;

    itemRemarkForm = {
      remark_text_fr: newItemRemark.remark_text_fr,
      remark_text_nl: newItemRemark.remark_text_nl,
      template_theme_item_id: currentItemId,
      index: -1,
    };
    return itemRemarkForm;
  }

  const handleCreateItemRemark = () => {
    let itemForm: ItemRemarkFormData = itemRemarkFormValue();
    onCreateItemRemark(itemForm)
    openCreateItemRemarkVar(false);
    itemRemarkVar(defaultItemRemark);
    history.push({
      pathname: routes.allItemRemarks.path,
      state: {
        item,
        currentTemplate,
        currentTheme,
        currentSubTheme,
      },
    });
  };

  const handleCreateAddItemRemark = () => {
    let itemForm: ItemRemarkFormData = itemRemarkFormValue();
    onCreateItemRemark(itemForm)
    itemRemarkVar(defaultItemRemark);
  };
  
  const handleClose = useCallback(() => {
    openCreateItemRemarkVar(false);
    itemRemarkVar(defaultItemRemark);
    if(data && data.length === 0){
      history.goBack();
    }
  }, [defaultItemRemark, history, data]);
 
  return (
    <ItemRemarkFormDialog
      isOpenForm={isOpenCreateForm}
      handleClose={handleClose}
      handleFormSubmit={handleCreateItemRemark}
      handleCreateAddItemRemark={handleCreateAddItemRemark}
      isOpenCreateForm={true}
      templateThemeItemId={currentItemId}
    />
  );
};

export default ItemRemarkCreateDialogComponent;
