import React, { useContext } from 'react';
import { isAllowed } from './Auth';
import MainAppContext from '../../../AppContext';
import BlockIcon from '@material-ui/icons/Block';

/**
 * This component is responsible to check if a user role has access to a given component (children),
 * either the component renders or an unauthorized message will be displayed
 * @param rules the rules that need to be authorized
 * @param children the component(s) inside AuthorizationComponent
 */
const AuthorizationComponent: any = ({ children, rules, message, email }) => {
  const { role, email: currentUserEmail } = useContext(MainAppContext);

  return isAllowed(rules, role) &&
    (!email || (email && currentUserEmail === email)) ? (
    children
  ) : (
    <BlockIcon color="disabled" />
  );
};

export default AuthorizationComponent;
