import React, { useCallback, useContext, useMemo } from 'react';
import {
  IconButton,
  Table,
  Paper,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import VisibilityIcon from '@material-ui/icons/Visibility';
import AddRounded from '@material-ui/icons/AddRounded';
import { CreateRounded, DeleteRounded } from '@material-ui/icons';
import AppContext from '../../AppContext';
import TableFalseValueComponent from '../Shared/Table/TableFalseValueComponent';
import TableHelpIconWithToolTip from '../Shared/Table/TableHelpIconWithToolTip.component';
import TableTrueValueComponent from '../Shared/Table/TableTrueValueComponent';
import { ItemData } from '../../services/Item/ItemDataModel';
import { useHistory } from 'react-router-dom';
import routes from '../../routes';
import {
  openCreateItemRemarkVar,
  openUpdateItemRemarkVar,
  remarkLastIndexVar,
} from './Remark/ItemRemarkCache';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useStyles } from '../Shared/Styles/CommonFormSyles';
import {
  translateformPagePDF,
  translateInformation,
  translateTitle,
} from '../../services/Translation/TranslationService';
import { useDocumentDialogService } from '../AdminDocument/AdminDocumentRead/AdminDocumentService';
import AdminDocumentDialog from '../AdminDocument/AdminDocumentRead/AdminDocumentDialog';
import { useBrowserLocationState } from '../Shared/BrowserLocationState/BrowserLocationState';

export const tableTestIds = {
  buttonOnUpdate: 'button-table-update-item',
  buttonOnDelete: 'button-table-delete-item',
  buttonOnRemarksVisibility: 'button-table-visibility-item',
  paginationComponent: 'pagination-component',
  tableEntityId: 'table-id',
};

export interface ItemTableProps {
  isLoading?: boolean;
  items: ItemData[];
  handleDeleteClicked: (row: ItemData) => void;
  openUpdateDialog: (row: ItemData) => void;
  onDragEnd: (result) => void;
}

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,
  ...(isDragging && {
    background: 'rgb(235,235,235)',
  }),
});

const ItemTableComponent: React.FC<ItemTableProps> = ({
  items,
  handleDeleteClicked,
  openUpdateDialog,
  onDragEnd,
}: ItemTableProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  
  const currentLocationState = useBrowserLocationState<{theme: any, subTheme?: any, template: any}>({keysRequired: ["theme", "template"]})

  const currentTemplate = useMemo(
    () => (currentLocationState?.template ? currentLocationState?.template : undefined),
    [currentLocationState],
  );
  const currentTheme = useMemo(
    () => (currentLocationState?.theme ? currentLocationState?.theme : undefined),
    [currentLocationState],
  );
  const currentSubTheme = useMemo(
    () => (currentLocationState?.subTheme ? currentLocationState?.subTheme : undefined),
    [currentLocationState],
  );
  const history = useHistory();
  const handleOpenUpdateDialog = useCallback(
    (rowData: ItemData) =>
      openUpdateDialog(Array.isArray(rowData) ? rowData[0] : rowData),

    [openUpdateDialog],
  );

  const onDeleteClicked = useCallback(
    (rowData: ItemData) => {
      handleDeleteClicked(Array.isArray(rowData) ? rowData[0] : rowData);
    },
    [handleDeleteClicked],
  );

  const {onSelectPage, pageNumber, onCloseDialog, open} = useDocumentDialogService()

  const handleCreateItemRemark = (item: ItemData) => {
    openCreateItemRemarkVar(true);
    remarkLastIndexVar(1);
    history.push({
      pathname: routes.createItemRemark.path,
      state: {
        item,
        currentTemplate,
        currentTheme,
        currentSubTheme,
      },
    });
  };

  const handOpenListOfItemRemark = (item: ItemData) => {
    openUpdateItemRemarkVar(false);
    openCreateItemRemarkVar(false);
    history.push({
      pathname: routes.allItemRemarks.path,
      state: {
        item,
        currentTemplate,
        currentTheme,
        currentSubTheme,
      },
    });
  };

  return (
    <>
      <AdminDocumentDialog
        pageNumber={pageNumber}
        onCloseDialog={onCloseDialog}
        open={open}
      />
      <Paper className={classes.paper}>
      <TableContainer className={classes.tableContainer}>
      <Table stickyHeader className={classes.table} aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableRow} align="center">
              {t('common:order')}
            </TableCell>
            <TableCell className={`${classes.tableLargeCellSize} ${classes.tableRow}`}>
              {t('item:title')}
            </TableCell>
            <TableCell className={classes.tableRow} align="center">{t('item:information')}</TableCell>
            <TableCell className={classes.tableRow} align="center">{t('item:normeCode')}</TableCell>
            <TableCell className={classes.tableRow} align="center">{t('item:pagePdf')}</TableCell>
            <TableCell className={classes.tableRow} align="center">
              {t('item:notApplicable')}
            </TableCell>
            <TableCell className={classes.tableRow} align="center">{t('item:remark')}</TableCell>
            <TableCell className={classes.tableRow} align="center">{t('common:edit')}</TableCell>
            <TableCell className={classes.tableRow} align="center">{t('common:delete')}</TableCell>
          </TableRow>
        </TableHead>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId={'1'} direction="vertical">
            {provided => {
              return (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {items.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id.toString()}
                      index={index}
                    >
                      {(provided_var, snapshot) => (
                        <TableRow
                          ref={provided_var.innerRef}
                          {...provided_var.draggableProps}
                          {...provided_var.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided_var.draggableProps.style,
                          )}
                        >
                          <TableRowItem
                            item={item}
                            index={index}
                            onDeleteClicked={onDeleteClicked}
                            onCreateItemRemark={handleCreateItemRemark}
                            onOpenUpdateDialog={handleOpenUpdateDialog}
                            onOpenListOfItemRemark={handOpenListOfItemRemark}
                            onSelectNorm={onSelectPage}
                          />
                        </TableRow>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              );
            }}
          </Droppable>
        </DragDropContext>
    </Table>
    </TableContainer>
    </Paper>
    </>
  );
};

export interface TableRowItemProps {
  item: ItemData;
  index: number;
  onDeleteClicked: (item: ItemData) => void;
  onCreateItemRemark: (item: ItemData) => void;
  onOpenUpdateDialog: (item: ItemData) => void;
  onOpenListOfItemRemark: (item: ItemData) => void;
  onSelectNorm: (number) => void
}

const TableRowItem: React.FC<TableRowItemProps> = ({
  item,
  index,
  onDeleteClicked,
  onCreateItemRemark,
  onOpenUpdateDialog,
  onOpenListOfItemRemark,
  onSelectNorm
}) => {
  const classes = useStyles();
  const { language } = useContext(AppContext);

  return (
    <React.Fragment>
      <TableCell align="center" className={classes.index}>
        {index + 1}
      </TableCell>
      <TableCell className={'itemTableLargeCellSize'}>
        {translateTitle(item, language)}
      </TableCell>
      <TableCell align="center">
        {' '}
        {translateInformation(item, language) ? (
          <TableHelpIconWithToolTip
            helpInfo={translateInformation(item, language)}
          />
        ) : (
          ''
        )}
      </TableCell>
      <TableCell align="center" onClick={() => onSelectNorm(translateformPagePDF(item, language))}>{item.norm_code}</TableCell>
      <TableCell align="center">
        {' '}
        {translateformPagePDF(item, language)}
      </TableCell>
      <TableCell className={'itemTableSmallCellSize'} align="center">
        {item.not_application_option ? (
          <TableTrueValueComponent />
        ) : (
          <TableFalseValueComponent />
        )}
      </TableCell>
      <TableCell align="center">
        {item &&
        item.template_theme_item_remarks &&
        item.template_theme_item_remarks.length > 0 ? (
          //exist remarks
          <IconButton
            data-testid={tableTestIds.buttonOnRemarksVisibility}
            onClick={() => onOpenListOfItemRemark(item)}
          >
            <VisibilityIcon />
          </IconButton>
        ) : (
          <IconButton
            data-testid={tableTestIds.buttonOnRemarksVisibility}
            onClick={() => onCreateItemRemark(item)}
          >
            <AddRounded />
          </IconButton>
        )}
      </TableCell>
      <TableCell align="center">
        <IconButton
          data-testid={tableTestIds.buttonOnUpdate}
          onClick={() => onOpenUpdateDialog(item)}
        >
          <CreateRounded />
        </IconButton>
      </TableCell>
      <TableCell align="center">
        <IconButton
          data-testid={tableTestIds.buttonOnDelete}
          onClick={() => onDeleteClicked(item)}
        >
          <DeleteRounded />
        </IconButton>
      </TableCell>
    </React.Fragment>
  );
};

export default ItemTableComponent;
