import React from 'react';
import { audit_data } from '../../../services/Audit/AuditDataModel';
import { useChecklistReportNotApplicablePresenter } from '../ChecklistReportNotApplicablePresenter/ChecklistReportNotApplicablePresenter';
import ChecklistReportNotApplicableView from '../ChecklistReportNotApplicableView/ChecklistReportNotApplicableView';
export interface ChecklistReportNotApplicableComponentProps {
  audit?: audit_data;
}

const ChecklistReportNotApplicableComponent: React.FC<ChecklistReportNotApplicableComponentProps> = ({ audit}) => {
  const { isLoading, noAppOverviewDetails } = useChecklistReportNotApplicablePresenter( audit);

  return <ChecklistReportNotApplicableView   isLoading={isLoading} noAppOverviewDetails={noAppOverviewDetails}/>;
};

export default ChecklistReportNotApplicableComponent;
