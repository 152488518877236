import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { translation as NL } from './nl/translation';
import { translation as FR } from './fr/translation';

const languagedetector = new LanguageDetector();

/**
 * Description - Translation configuration
 *
 * Remark
 * - the system get browser language by default
 * - if the system don't find this language in translation, the default language will be nederland
 */
i18n
  .use(languagedetector) // detect from browser
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    detection: { order: ['navigator'] },
    resources: {
      nl: NL,
      fr: FR,
    },
    keySeparator: ':', // we do not use keys in form messages.welcome
    fallbackLng: () => 'nl', // if don't find lng, he return nl
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
