export interface Label {
  name_nl: string;
  name_fr: string;
}

export const translate = (object, language): string => {
  return language.substring(0, 2) === 'nl' ? object?.name_nl : object?.name_fr;
};
export const translateCategoryLocation = (object, language): string => {
  return language.substring(0, 2) === 'nl' ? object?.name_nl : object?.name_fr;
};

export const translateShortName = (object, language): string => {
  return language.substring(0, 2) === 'nl'
    ? object.short_name_nl
    : object.short_name_fr;
};

export const translateLongName = (object, language): string => {
  return language?.substring(0, 2) === 'nl'
    ? object?.long_name_nl
    : object?.long_name_fr;
};

export const translateTitle = (object, language): string => {
  return language.substring(0, 2) === 'nl' ? object.title_nl : object.title_fr;
};

export const translateRemark = (object, language): string => {
  return language.substring(0, 2) === 'nl'
    ? object?.remark_text_nl
    : object?.remark_text_fr;
};

export const translateInformation = (object, language): string => {
  return language.substring(0, 2) === 'nl'
    ? object.information_nl
    : object.information_fr;
};

export const translateformPagePDF = (object, language): number => {
  return language.substring(0, 2) === 'nl'
    ? object.page_pdf_nl
    : object.page_pdf_fr;
};


